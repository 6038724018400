import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Button,

  colors,
} from "@material-ui/core";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import CountrySelect from 'src/components/CountrySelect';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
}));

const schema = {
  employerName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
  }
};

function CreateEmployerForm({ onSubmit, consultancyId}) {
  const classes = useStyles();

  const [t] = useTranslation(["common","validation"]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      consultancyId,
      country:{}
    },
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState.values);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields} style={{width:'100%'}}>
        <TextField
          fullWidth
          label={t('common:employerName')}
          name="employerName"
          variant="outlined"
          required
          onChange={handleChange}
          error={hasError("employerName")}
          value={formState.values.employerName || ""}
          helperText={
            hasError("employerName") ? t(`validation:${formState.errors.employerName[0]}`) : null
          }
        />
        <CountrySelect handleCountryChange={handleCountryChange} value={formState.values.country}/>
      </div>
      <Button
        className={classes.submitButton}
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        disabled={!formState.isValid}
      >
        {t("common:create")}
      </Button>
    </form>
  );
}
export default CreateEmployerForm;
