import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
  Modal,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    justifyContent: "center",
  },
  container: {
    padding: "0px 10px",
    outline: "none",
    display: "flex",
  },
  box: {
    marginTop: 50,
    marginBottom: 50,
    width: "100%",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  closeButton: {
    minWidth: "32px",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  linearProgressContainer: {
    width: "100%",
  },
  textContainer: {
    textAlign: "center",
    marginTop: "10px",
  },
}));

export const LoadingModal = ({ open, percent }) => {
  const classes = useStyles();
  const [t] = useTranslation(["common"]);

  return (
    <Modal
      open={open}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalOverflow}
      percent={percent}
    >
      {open ? (
        <Container className={classes.container} tabIndex="" maxWidth="sm">
          <Box display="flex" alignItems="center" className={classes.box}>
            <Box display="column" width="100%">
              <Card className={classes.modalCard}>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <div className={classes.linearProgressContainer}>
                        <Box display="flex" alignItems="center">
                          <Box width="100%" mr={1}>
                            <LinearProgress
                              variant="determinate"
                              value={percent}
                            />
                          </Box>
                        </Box>
                      </div>
                      {percent < 100 && (
                        <div className={classes.textContainer}>
                          <Typography variant="h3" color="primary">
                            {t("common:uploading")}...
                          </Typography>
                        </div>
                      )}
                      {open && percent === 100 && (
                        <div className={classes.textContainer}>
                          <Typography variant="h3" color="primary">
                            {t("common:saving")}...
                          </Typography>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Container>
      ) : null}
    </Modal>
  );
};
