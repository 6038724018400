export const CREATE_TALENT_ALERT = 'CREATE_TALENT_ALERT';
export const SET_TALENT_ALERTS = 'SET_TALENT_ALERTS';
export const SET_TALENT_ALERT = 'SET_TALENT_ALERT'
export const UPDATE_TALENT_ALERT_CONTACTS = 'UPDATE_TALENT_ALERT_CONTACTS';
export const UPDATE_UNINVITED_TALENT_ALERT_CONTACTS = 'UPDATE_UNINVITED_TALENT_ALERT_CONTACTS';
export const UPDATE_TALENT_ALERT = 'UPDATE_TALENT_ALERT';
export const UPDATE_TALENT_ALERT_CANDIDATES = 'UPDATE_TALENT_ALERT_CANDIDATES';
export const UPDATE_TALENT_ALERT_UNINVITED_CANDIDATES = 'UPDATE_TALENT_ALERT_UNINVITED_CANDIDATES';
export const SET_TALENT_ALERT_CANDIDATES = 'SET_TALENT_ALERT_CANDIDATES';
export const UPDATE_TALENT_ALERT_TCS = 'UPDATE_TALENT_ALERT_TCS';
export const UPDATE_TALENT_ALERT_STATUS = 'UPDATE_TALENT_ALERT_STATUS';
export const CREATE_TALENT_ALERT_KCQS = 'CREATE_TALENT_ALERT_KCQS';
export const UPDATE_TALENT_ALERT_KCQS = 'UPDATE_TALENT_ALERT_KCQS';
export const DELETE_TALENT_ALERT_KCQS = 'DELETE_TALENT_ALERT_KCQS';
export const ADD_TALENT_ALERT_REQUIRED_DOCUMENT = 'ADD_TALENT_ALERT_REQUIRED_DOCUMENT';
export const UPDATE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT = 'UPDATE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT';
export const REMOVE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT = 'REMOVE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT';
export const UPDATE_TALENT_ALERT_MANAGER = 'UPDATE_TALENT_ALERT_MANAGER';
export const PREVIEW_TALENT_ALERT_AS_AUTHENTICATED_CONTACT = 'PREVIEW_TALENT_ALERT_AS_AUTHENTICATED_CONTACT';
export const PREVIEW_TALENT_ALERT_AS_UNAUTHENTICATED_USER = 'PREVIEW_TALENT_ALERT_AS_UNAUTHENTICATED_USER';
export const SET_TALENT_ALERT_CAMPAIGN_TYPE = 'SET_TALENT_ALERT_CAMPAIGN_TYPE';
export const SET_TALENT_ALERT_KCQ = 'SET_TALENT_ALERT_KCQ';
export const DELETE_TALENT_ALERT_KCQ = 'SET_TALENT_ALERT_KCQ';
export const UPDATE_TALENT_ALERT_INTRODUCTION = 'UPDATE_TALENT_ALERT_INTRODUCTION';
export const UPDATE_TALENT_ALERT_CANDIDATE = 'UPDATE_TALENT_ALERT_CANDIDATE';
