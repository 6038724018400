import React, { useEffect, useState } from "react";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { useSearch } from "src/components/Filter/Search";
import { useSort } from "src/components/Filter/Sort";
import { usePagination } from "src/components/Filter/Pagination";
import CreateConsultancyModal from "./CreateConsultancyModal";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { useTranslation } from "react-i18next";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import Results from "./Results";

function Consultancies() {
  const [t] = useTranslation(["common"]);

  const [open, setOpen] = useState(false);
  const [consultancies, setConsultancies] = useState([]);

  const searchTerm = "";
  const showItems = 12;
  const sortOrder = t("common:ascending");

  const [isLoading, setIsLoading] = useState(true);

  const getConsultancies = function() {
    return SystemAdminApi.getAllConsultancies()
      .then((response) => {
        setConsultancies(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //This function will be changed at some point to allow for filters and return more data.
  //Just here for demo purposes atm.
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getConsultancies();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let filteredResults = useSearch(consultancies, searchTerm, [
    "consultancyName",
  ]).searchResults();

  const { currentData } = usePagination(
    filteredResults,
    showItems
  );

  let paginatedData = currentData();
  useSort(paginatedData, sortOrder, "consultancyName").sortData();

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle
        pageTitle={t("common:allConsultancies")}
        btnLabel={t("common:createConsultancy")}
        btnFunc={handleOpen}
      />
      <ContentWrapper loading={isLoading}>
        <Results consultancies={consultancies} />
      </ContentWrapper>
      <CreateConsultancyModal open={open} handleClose={handleClose} />
    </div>
  );
}

export default Consultancies;
