import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Grid, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import DefaultDocuments from './DefaultDocuments';
import DWISettings from './DWISettings';
import EmailSettings from './EmailSettings';
import HotjarSettings from './HotjarSettings';
import KCQSettings from './KCQSettings';

import { TabPanel } from './../../../components/TabPanel';

import * as roles from './../../../constants/roles';
import * as subscriptionPlansIds from './../../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

const ConsultancySettings = ({ consultancyId }) => {

  const classes = useStyles();
  const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'settings']);

  const [currentTab, setCurrentTab] = useState(0);

  const currentUserRoleType = useSelector((state) => state.session.roleType);
  const isConsultancyWithFullSubscriptionPlan = useSelector((state) => {
    if (state.session.consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
      return true;
    }

    return false;
  });

  const tabs = [
    { value: 0, label: t('settings:emailSettings') },
    { value: 1, label: t('settings:generalSettings') }
  ];

  if (isConsultancyWithFullSubscriptionPlan) {
    tabs.push({ value: 2, label: t('settings:dwiSettings') });
  }

  tabs.push({ value: 3, label: t('settings:kcqSettings') });

  if (currentUserRoleType === roles.SYSTEMADMIN) {
    tabs.push({ value: 4, label: t('settings:hotjarSettings') });
  }

  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={3} lg={2}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          textColor="primary"
          value={currentTab}
          onChange={handleTabChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}>
          {
            tabs.map((tab) => (
              <Tab
                key={tab.value}
                icon={tab.icon}
                label={t(`campaigns:${tab.label}`)}
                value={tab.value}
                onClick={(event) => handleTabChange(event, tab.value)}/>
            ))
          }
        </Tabs>
      </Grid>

      <Grid item xs={12} sm={9} lg={10}>
        <TabPanel value={currentTab} index={0}>
          <EmailSettings consultancyId={consultancyId} />
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <DefaultDocuments consultancyId={consultancyId} />
        </TabPanel>

        {
          isConsultancyWithFullSubscriptionPlan && (
            <TabPanel value={currentTab} index={2}>
              <DWISettings consultancyId={consultancyId} />
            </TabPanel>
          )
        }

        <TabPanel value={currentTab} index={3}>
          <KCQSettings consultancyId={consultancyId} />
        </TabPanel>

        {
          currentUserRoleType === roles.SYSTEMADMIN && (
            <>
              <TabPanel value={ currentTab } index={ 4 } >
                <HotjarSettings consultancyId={ consultancyId } />
              </TabPanel>
            </>
          )
        }
      </Grid>
    </Grid>
  );

};

export default ConsultancySettings;
