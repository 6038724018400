import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TabPanel } from "src/components/TabPanel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmailTemplateLocale from "./EmailTemplateLocale";

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  subjectInput: {
    marginBottom: "20px",
  },
  updateButton: {
    marginTop: "20px",
  },
  tagContainer: {
    marginBottom: "20px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  scrollButtons: {
    color: "black",
  },
}));

const tabs = [
  { value: 1, label: "English UK" },
  { value: 2, label: "German" },
  { value: 3, label: "French" },
  { value: 4, label: "Spanish" },
  { value: 5, label: "Dutch" },
  { value: 6, label: "English US" },
  { value: 7, label: "Czech" },
  { value: 8, label: "Romanian" },
];

const EmailTemplate = ({ emailTemplate, consultancyId }) => {
  const [t] = useTranslation([
    "common",
    "snackbar",
    "validation",
    "notifications",
    "vacancyNotifications",
    "languages",
  ]);
  const classes = useStyles();

  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const [currentTab, setCurrentTab] = useState(emailTemplate.currentTab);

  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h4">
            {t(`${notificationsPrefix}:${emailTemplate.templateName}`)}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <AppBar position="static" className={classes.appBar}>
                <Tabs
                  value={currentTab}
                  variant="scrollable"
                  textColor="primary"
                  scrollButtons="auto"
                  classes={{ scrollButtons: classes.scrollButtons }}
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      icon={tab.icon}
                      label={t(`languages:${tab.label}`)}
                      value={tab.value}
                      onClick={(event) => handleTabChange(event, tab.value)}
                    />
                  ))}
                </Tabs>
              </AppBar>
            </Grid>
            <Grid item xs={12}>
              {tabs?.map((tab) => (
                <TabPanel value={currentTab} index={tab.value}>
                  <EmailTemplateLocale
                    email={emailTemplate.emailTemplateLocale.find(
                      (emailTemplateLocale) =>
                        emailTemplateLocale.localeId === tab.value
                    )}
                    emailTags={emailTemplate.emailTags}
                    consultancyId={consultancyId}
                  />
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </>
  );
};

export default EmailTemplate;
