import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Card, CardContent, FormControl, Grid, OutlinedInput, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as candidateApi from './../../api/candidateApi';
import * as contactApi from './../../api/contactApi';

import { BlockHeader } from './../LayoutComponents/BlockHeader';

const useStyles = makeStyles((theme) => ({
    card: {
        padding: '16px',
        paddingBottom: '24px',
        marginTop: '16px'
    },
    formControl: {
        width: '100%'
    },
    typography: {
        marginBottom: '4px'
    },
    button: {
        marginTop: '16px'
    },
}));

const ChangeEmail = ({ changeEmail, consultancyId, candidateId, contactId }) => {

    const [t] = useTranslation(['changeEmail']);
    const classes = useStyles();

    const [email, setEmail] = useState('');

    useEffect(() => {
        let request;

        if (candidateId) {
            request = candidateApi.getCandidate({ consultancyId, candidateId });
        } else {
            request = contactApi.getContactById(contactId, consultancyId);
        }

        request
            .then((response) => {
                setEmail(response.data.user.email);
            })
            .catch((err) => {
                console.log(err);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const changeEmailHandler = () => {
        changeEmail(email);
    };

    return (
        <>
            <Card className={ classes.card }>
                <BlockHeader card header={ t("changeEmail:header") } />

                <CardContent>
                    <Grid container>
                        <FormControl variant="outlined" className={ classes.formControl }>
                            <Typography variant="h5" className={ classes.typography }>
                                { t("changeEmail:emailLabel") }
                            </Typography>

                            <OutlinedInput
                                value={ email }
                                onChange={ handleChange }
                                label={ t("changeEmail:emailLabel") } />
                        </FormControl>
                    </Grid>

                    <Button
                        color="primary"
                        fullWidth
                        variant="contained"
                        size="large"
                        className={ classes.button }
                        onClick={ changeEmailHandler } >
                        { t("changeEmail:submitBtn") }
                    </Button>
                </CardContent>
            </Card>
        </>
    );

};

export default ChangeEmail;
