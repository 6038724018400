import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Tabs, Tab } from '@material-ui/core';

const CustomTabs = ({ tabs, onChange, tabClassName }) => {

  const history = useHistory();

  const [currentTab, setCurrentTab] = useState(0);

  const { adminContactPreview, adminContactPreviewPrevState } = useSelector(state => state.campaign);

  useEffect(() => {
    if (adminContactPreview !== adminContactPreviewPrevState) {
      history.push(history.location.pathname);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(history.location.search);

    if (queryParams.get('currentTab')) {
      const currentTabValue = parseInt(queryParams.get('currentTab'));
      setCurrentTab(currentTabValue);
      onChange(currentTabValue);

      return;
    }

    setCurrentTab(0);
    onChange(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleChange = (event, value) => {
    const pathname = value === 0 ?
      history.location.pathname :
      `${history.location.pathname}?currentTab=${value}`;
    history.push(pathname);

    setCurrentTab(value);
    onChange(value);
  };

  const getTab = (tab) => {
    if (tab.icon && tabClassName) {
      return (
        <Tab
          key={ tab.value }
          className={ tabClassName }
          label={ tab.label }
          value={ tab.value }
          icon={ tab.icon } />
      );
    }

    if (tab.icon) {
      return (
        <Tab
          key={ tab.value }
          label={ tab.label }
          value={ tab.value }
          icon={ tab.icon } />
      );
    }

    if (tabClassName) {
      return (
        <Tab
          key={ tab.value }
          className={ tabClassName }
          label={ tab.label }
          value={ tab.value } />
      );
    }

    return (
      <Tab
        key={ tab.value }
        label={ tab.label }
        value={ tab.value } />
    );
  };

  return (
    <>
      <Tabs
        variant="scrollable"
        textColor="primary"
        value={ currentTab }
        onChange={ handleChange } >
        { tabs.map(getTab) }
      </Tabs>
    </>
  );

};

export default CustomTabs;
