import React, { useState, useEffect } from "react";
import { AppBar, Grid, Tab, Tabs } from "@material-ui/core";
import { TabPanel } from "src/components/TabPanel";
import { ToggleCard } from "src/components/Cards/ToggleCard";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import { useTranslation } from "react-i18next";
import * as api from "../../api/SystemAdminApi";
import * as consultancyApi from "../../api/consultancyApi";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
	appBar: {
		boxShadow: "none",
		backgroundColor: "transparent",
		marginTop: "2rem",
		marginBottom: "-4rem",
	},
}));

const SysAdminConsultancyModuleManagement = ({ consultancyId }) => {
	const classes = useStyles();
	const [t] = useTranslation(["settings"]);

	const moduleTabs = [
		{ value: 0, label: "availableModules" },
		{ value: 1, label: "activeModules" },
		{ value: 2, label: "inactiveModules" },
	];

	const idArr = [];

	const [availableModules, setAvailableModules] = useState([]);
	const [activeModules, setActiveModules] = useState([]);
	const [inactiveModules, setInactiveModules] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [searchTerm, setSearchTerm] = useState("");

	const getActiveModules = () => {
		return consultancyApi
			.getModulesForConsultancy(consultancyId)
			.then((response) => {
				const data = response.data;
				const activeModulesArr = data.map((module) => {
					return {
						id: module.id,
						moduleId: module.moduleId,
						moduleName: module.moduleName,
						isActive: true,
					};
				});

				setActiveModules(activeModulesArr);

				for (const e of data) {
					idArr.push(e.moduleId);
				}

				getAvailableModules();
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	const getAvailableModules = () => {
		return api
			.getAvailableModules(true)
			.then((response) => {
				const data = response.data;
				const availableModulesArr = data.map((module) => {
					return {
						moduleId: module.id,
						moduleName: module.moduleName,
						isActive: idArr.includes(module.id),
					};
				});
				setAvailableModules(availableModulesArr);
				const inactive = availableModulesArr.filter(
					(e) => e.isActive === false
				);
				setInactiveModules(inactive);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	useEffect(() => {
		getActiveModules();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultancyId]);

	const handleInputChange = (newValue) => {
		setSearchTerm(newValue);
	};

	const handleTabChange = (event, value) => {
		setCurrentTab(value);
	};

	const handleEnableModule = (consultancyId, moduleId, moduleName) => {
		return api
			.createModuleForConsultancy(consultancyId, moduleId, moduleName)
			.then((response) => {
				if (response) {
					getActiveModules();
				}
			})
			.catch((error) => {
				throw error;
			});
	};

	const handleDisableModule = (id) => {
		return api.deleteModuleForConsultancy(id).then((response) => {
			if (response) {
				getActiveModules();
			}
		});
	};

	const handleGetIdAndDisable = (id) => {
		const moduleId = activeModules.filter((e) => e.moduleId === id);
		return api.deleteModuleForConsultancy(moduleId[0].id).then((response) => {
			if (response) {
				getActiveModules();
			}
		});
	};

	let filteredResults = useSearch(
		currentTab === 0
			? availableModules
			: currentTab === 1
			? activeModules
			: inactiveModules,
		searchTerm,
		["moduleName"]
	).searchResults();

	return (
		<>
			<Grid
				container
				xs={12}
				display="flex"
				alignItems="center"
				flexDirection="row-reverse"
			>
				<Searchbar onChange={handleInputChange} />
			</Grid>
			<Grid container spacing={5}>
				<Grid item xs={12} className={classes.gridRow}>
					<AppBar position="static" className={classes.appBar}>
						<Tabs
							value={currentTab}
							onChange={handleTabChange}
							variant="scrollable"
							textColor="primary"
						>
							{moduleTabs.map((tab) => (
								<Tab
									key={tab.value}
									label={t(`settings:${tab.label}`)}
									value={tab.value}
								/>
							))}
						</Tabs>
					</AppBar>
				</Grid>
				<Grid item xs={12} className={classes.gridRow}>
					<TabPanel value={currentTab} index={0}>
						<Grid container spacing={3}>
							{filteredResults.map((module) => (
								<ToggleCard
									key={module.id}
									name={module.moduleName}
									detail={module.detail}
									enabled={module.isActive}
									handleSwitch={() => {
										module.isActive
											? handleGetIdAndDisable(module.moduleId)
											: handleEnableModule(
													consultancyId,
													module.moduleId,
													module.moduleName
											);
									}}
								/>
							))}
						</Grid>
					</TabPanel>
					<TabPanel value={currentTab} index={1}>
						<Grid container spacing={3}>
							{filteredResults.map((module) => (
								<ToggleCard
									key={module.id}
									name={module.moduleName}
									detail={module.detail}
									enabled={module.isActive}
									handleSwitch={() => {
										handleDisableModule(module.id);
									}}
								/>
							))}
						</Grid>
					</TabPanel>
					<TabPanel value={currentTab} index={2}>
						<Grid container spacing={3}>
							{filteredResults.map((module) => (
								<ToggleCard
									hideControls
									key={module.id}
									name={module.moduleName}
									detail={module.detail}
									enabled={module.isActive}
									handleSwitch={() => {
										handleEnableModule(
											consultancyId,
											module.moduleId,
											module.moduleName
										);
									}}
								/>
							))}
						</Grid>
					</TabPanel>
				</Grid>
			</Grid>
		</>
	);
};

export default SysAdminConsultancyModuleManagement;
