import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as CandidateApi from 'src/api/candidateApi';
import * as ContactApi from 'src/api/contactApi';
import { CANDIDATE, CONTACT } from 'src/constants/roles';
import WelcomeMessage from './WelcomeMessage';
import { Grid } from '@material-ui/core';


function CreateWelcomeMessagesCard() {
  const [messages, setMessages] = useState([]);

  const roleType = useSelector((state) =>state.session.roleType);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const [isLoading, setIsLoading] = useState(true);

  const getWelcomeMessages = () => {
    if(roleType === CANDIDATE){
      return CandidateApi.getCandidateWelcomeMessages(consultancyId)
      .then((response)=>{
        setMessages(response.data);
      })
      .catch((error)=>{
        console.error(error);
      })
      .then(()=>{
        setIsLoading(false);
      })
    } else if (roleType === CONTACT){
      return ContactApi.getContactWelcomeMessages(consultancyId)
      .then((response)=>{
        setMessages(response.data);
      })
      .catch((error)=>{
        console.error(error);
      })
      .then(()=>{
        setIsLoading(false);
      })
    }
  };

  const dismissWelcomeMessage = (event, messageId) => {
    if(roleType === CANDIDATE){
      return CandidateApi.dismissCandidateWelcomeMessage(consultancyId, messageId)
      .then((response)=>{
        setMessages(response.data);
      })
      .catch((error)=>{
        console.error(error)
      })
    } else if (roleType === CONTACT){
      return ContactApi.dismissContactWelcomeMessage(consultancyId, messageId)
      .then((response)=>{
        setMessages(response.data);
      })
      .catch((error)=>{
        console.error(error)
      })
    }
  }

  useEffect(() => {
    getWelcomeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? null
    : (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {messages?.map((message) => (
            <WelcomeMessage message={message} dismissWelcomeMessage={(event) => dismissWelcomeMessage(event, message.id)}/>
          ))}
        </Grid>
      </Grid>
      );
}
export default CreateWelcomeMessagesCard;
