import React, { useState } from "react";
import CreateSystemAdminForm from "./CreateSystemAdminForm";
import { ShowSystemAdmins } from "./ShowSystemAdmins";
import { CardGrid } from "src/components/LayoutComponents/CardGrid";
import { useTranslation } from "react-i18next";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";

function SystemAdmin() {
	const [openState, setOpenState] = useState(false);

	const openModal = () => {
		setOpenState(true);
	};

	const closeModal = () => {
		setOpenState(false);
	};

	const [t] = useTranslation(["users"]);

	return (
		<>
			<PageTitle
				pageTitle={t("users:systemAdmin")}
				btnLabel={t("users:createSystemAdmin")}
				btnFunc={openModal}
			/>
			<CardGrid>
				<ShowSystemAdmins />
			</CardGrid>
			<CreateSystemAdminForm open={openState} handleClose={closeModal} />
		</>
	);
}

export default SystemAdmin;
