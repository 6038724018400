import axios from "src/utils/axios";

export const getDWICategories = (consultancyId) => {
  return axios.get("/DWICategories/getDWICategories", {
    params: {
      consultancyId
    }
  })
}

export const updateDWICategory = (consultancyId, category) => {
  return axios.put("/DWICategories/updateDWICategory",category, {
    params: {
      consultancyId
    }
  })
}

export const createDWICategory = (consultancyId, newCategory) => {
  return axios.post("/DWICategories/createDWICategory", newCategory, {
    params: {
      consultancyId
    }
  })
}

export const deleteDWICategory = (consultancyId, categoryId) => {
  return axios.delete('/DWICategories/deleteDWICategory', { 
    params: { 
      categoryId, 
      consultancyId 
    } 
  })
}

export const getGlobalDWICategories = () => {
  return axios.get("/DWICategories/getGlobalDWICategories")
}

export const updateGlobalDWICategory = (category) => {
  return axios.put("/DWICategories/updateGlobalDWICategory",category)
}

export const createGlobalDWICategory = (newCategory) => {
  return axios.post("/DWICategories/createGlobalDWICategory", newCategory)
}

export const deleteGlobalDWICategory = (categoryId) => {
  return axios.delete('/DWICategories/deleteGlobalDWICategory', { 
    params: { 
      categoryId
    } 
  })
}

export const getKCQCategories = (consultancyId) => {
  return axios.get("/KCQCategories/getKCQCategories", {
    params: {
      consultancyId
    }
  })
}

export const updateKCQCategory = (consultancyId, category) => {
  return axios.put("/KCQCategories/updateKCQCategory",category, {
    params: {
      consultancyId
    }
  })
}

export const createKCQCategory = (consultancyId, newCategory) => {
  return axios.post("/KCQCategories/createKCQCategory", newCategory, {
    params: {
      consultancyId
    }
  })
}

export const deleteKCQCategory = (consultancyId, categoryId) => {
  return axios.delete('/KCQCategories/deleteKCQCategory', { 
    params: { 
      categoryId, 
      consultancyId 
    } 
  })
}

export const getGlobalKCQCategories = () => {
  return axios.get("/KCQCategories/getGlobalKCQCategories")
}

export const updateGlobalKCQCategory = (category) => {
  return axios.put("/KCQCategories/updateGlobalKCQCategory",category)
}

export const createGlobalKCQCategory = (newCategory) => {
  return axios.post("/KCQCategories/createGlobalKCQCategory", newCategory)
}

export const deleteGlobalKCQCategory = (categoryId) => {
  return axios.delete('/KCQCategories/deleteGlobalKCQCategory', { 
    params: { 
      categoryId,
    } 
  })
}

export const getQuestionSets = () => {
  return axios.get("/KCQSets")
}

export const updateQuestionSet = (consultancyId, questionSet) => {
  return axios.put("/QuestionSets/updateQuestionSet",questionSet, {
    params: {
      consultancyId
    }
  })
}

export const createQuestionSet = (consultancyId, newQuestionSet) => {
  return axios.post("/KCQSets", newQuestionSet, {
    params: {
      consultancyId
    }
  })
}


export const createPersonalQuestionSet = (consultancyId, newQuestionSet) => {
  return axios.post("/UserKCQSets", newQuestionSet, {
    params: {
      consultancyId
    }
  })
}

export const updatePersonalQuestionSet = (consultancyId, newQuestionSet) => {
  return axios.post("/UserKCQSets/replaceOrCreate", newQuestionSet, {
    params: {
      consultancyId
    }
  })
}


export const getPersonalQuestionSets = (userId, consultancyId) => {  
  return axios.get('/UserKCQSets/getKCQSets', {
    params: {
      userId,
      consultancyId
    }
  });
}



export const deleteQuestionSet = (consultancyId, questionSetId) => {
  return axios.delete('/QuestionSets/deleteQuestionSet', { 
    params: { 
      questionSetId, 
      consultancyId 
    } 
  })
}

export const deletePersonalQuestionSet = (consultancyId, questionSetId) => {
  return axios.delete('/UserKCQSets/'+questionSetId)
}

export const getGlobalQuestionSets = () => {
  return axios.get("/KCQSets")
}

export const updateGlobalQuestionSet = (questionSet) => {
  return axios.put("/QuestionSets/updateGlobalQuestionSet",questionSet)
}

export const createGlobalQuestionSet = (questionSet) => {
  return axios.post("/QuestionSets/createGlobalQuestionSet", questionSet)
}

export const deleteGlobalQuestionSet = (id) => {
  return axios.delete('/KCQSets/'+id)
}

