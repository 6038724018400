//if no date provided, cookie never expires. 
//As the ttl for accessToken is 30 days, probs best to set the exp days to 29
//Probably could do with some sort of backend check that return a specific error code if the accesstoken is no longer valid so we can remove the cookie for it
//Each of these cookies are subdomain bound.
export const setCookie = (cookieName, cookieValue, expDays) => {
  var expires;
  if(expDays){
    var date = new Date();
    date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
    expires = "expires="+date.toUTCString();
  }else{
    expires = "";
  }
  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/;";
}

export const getCookie = (cookieName) => {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const deleteCookie = (cookieName) => {
  var today = new Date();
  var expires = new Date(today.getDate() - 2);
  document.cookie = cookieName + "=; expires=" + expires + "; path=/;";
}