/* eslint-disable max-len */
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import FilterListIcon from "@material-ui/icons/FilterList";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Card,
  Checkbox,
  Divider,
  InputAdornment,
  OutlinedInput,
  ListItemText,
  Link,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  MenuItem,
  Collapse,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import {
  ArrowRight as ArrowRightIcon,
  Search as SearchIcon,
} from "react-feather";
import getInitials from "src/utils/getInitials";
import moment from "moment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function applyFilters(users, query, filters) {
  return users.filter((user) => {
    let matches = true;

    if (query) {
      const properties = ["email", "name"];
      let containsQuery = false;

      properties.forEach((property) => {
        if (user[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && user[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
}

function applyPagination(users, page, limit) {
  return users.slice(page * limit, page * limit + limit);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySort(users, sort) {
  const [orderBy, order] = sort.split("|");
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = users.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    // eslint-disable-next-line no-shadow
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  queryField: {
    width: 500,
  },
  bulkOperations: {
    position: "relative",
  },
  bulkActions: {
    paddingLeft: 4,
    paddingRight: 4,
    marginTop: 6,
    width: "100%",
    zIndex: 2,
    backgroundColor: theme.palette.background.paper,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1),
  },
  formControl: {
    marginBottom: "10px",
  },
  pagination: {
    marginRight: theme.spacing(2),
  },
}));

function Results({ className, users, consultancies, ...rest }) {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(12);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [t] = useTranslation(["campaigns", "common", "snackbar"]);
  const { enqueueSnackbar } = useSnackbar();

  const sortOptions = [
    {
      value: "createdAt|desc",
      label: `${t("common:createdAt")} (${t("common:latest")})`,
    },
    {
      value: "createdAt|asc",
      label: `${t("common:createdAt")} (${t("common:oldest")})`,
    },
    {
      value: "name|asc",
      label: `${t("common:user")} ${t("common:name")} (A - Z)`,
    },
    {
      value: "name|desc",
      label: `${t("common:user")} ${t("common:name")} (Z - A)`,
    },
    {
      value: "consultancyName|asc",
      label: `${t("common:consultancyLabel")} ${t("common:name")} (A - Z)`,
    },
    {
      value: "consultancyName|desc",
      label: `${t("common:consultancyLabel")} ${t("common:name")} (Z - A)`,
    },
  ];

  const [sort, setSort] = useState(sortOptions[0].value);

  const roles = [
    {
      id: 3,
      name: "System Admin",
    },
    {
      id: 4,
      name: "Admin",
    },
    {
      id: 5,
      name: "Consultant",
    },
    {
      id: 6,
      name: "Candidate",
    },
    {
      id: 7,
      name: "Contact",
    },
    {
      id: 9,
      name: "Core Admin",
    },
  ];
  const [filters, setFilters] = useState({
    consultancyId: null,
    role: null,
    activated: null,
  });

  const handleFilterChange = (event) => {
    setFilters((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };
  const handleFilterReset = (event) => {
    setFilters({
      consultancyId: null,
      role: null,
      activated: null,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleSortChange = (event) => {
    event.persist();
    setSort(event.target.value);
  };

  const handleSelectAllUsers = (event) => {
    setSelectedUsers(event.target.checked ? users.map((user) => user.id) : []);
  };

  const handleSelectOneUser = (event, userId) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers((prevSelected) => [...prevSelected, userId]);
    } else {
      setSelectedUsers((prevSelected) =>
        prevSelected.filter((id) => id !== userId)
      );
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const resendActiationEmails = () => {
    return SystemAdminApi.resendAccountActivationEmails(selectedUsers)
      .then(() => {
        enqueueSnackbar(t("snackbar:activationEmailSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:activationEmailFailure"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const disableAccounts = () => {
    return SystemAdminApi.disableAccounts(selectedUsers)
      .then(() => {
        enqueueSnackbar(t("snackbar:disabledAccountsSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:disabledAccountsFailure"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const enableAccounts = () => {
    return SystemAdminApi.enableAccounts(selectedUsers)
      .then(() => {
        enqueueSnackbar(t("snackbar:enabledAccountsSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:enabledAccountsFailure"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const goToUserDetails = (
    role,
    userId,
    consultancyId,
    employerId,
    contactId,
    candidateId
  ) => {
    switch (role) {
      case 4:
      case 5:
        return `/dashboard/consultancies/${consultancyId}/users/${userId}`;
      case 6:
        return `/dashboard/consultancy/${consultancyId}/candidates/${candidateId}`;
      case 7:
        return `/dashboard/consultancy/${consultancyId}/employers/${employerId}/contacts/${contactId}`;
      default:
        return `/dashboard/system-admins`;
    }
  };
  const filteredUsers = applyFilters(users, query, filters);
  const sortedUsers = applySort(filteredUsers, sort);
  const paginatedUsers = applyPagination(sortedUsers, page, limit);
  const enableBulkOperations = selectedUsers.length > 0;
  const selectedSomeUsers =
    selectedUsers.length > 0 && selectedUsers.length < users.length;
  const selectedAllUsers = selectedUsers.length === users.length;

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <Divider />
        <Box p={2} minHeight={56} display="flex" alignItems="center">
          <TextField
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            onChange={handleQueryChange}
            placeholder={`${t("common:search")}...`}
            value={query}
            variant="outlined"
          />

          <Box flexGrow={1} />
          <IconButton
            color="primary"
            style={{ margin: "0px 20px" }}
            onClick={handleClickOpen}
          >
            <FilterListIcon style={{ fontSize: "32px" }} />
            <Typography variant="h3" style={{ fontWeight: "500" }}>
              {t("common:filterUsers")}
            </Typography>
          </IconButton>
          <TextField
            label={t("common:sortBy")}
            name="sort"
            onChange={handleSortChange}
            select
            SelectProps={{ native: true }}
            value={sort}
            variant="outlined"
          >
            {sortOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Box>
        <Collapse in={enableBulkOperations}>
          <div className={classes.bulkOperations}>
            <div className={classes.bulkActions}>
              <span>
                <div style={{ textAlign: "right", margin: 8 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.bulkAction}
                    onClick={disableAccounts}
                  >
                    {t("common:disableAccounts")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.bulkAction}
                    onClick={enableAccounts}
                  >
                    {t("common:enableAccouts")}
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.bulkAction}
                    onClick={resendActiationEmails}
                  >
                    {t("common:resendAccountActivationEmail")}
                  </Button>
                </div>
              </span>
            </div>
          </div>
        </Collapse>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAllUsers}
                      indeterminate={selectedSomeUsers}
                      onChange={handleSelectAllUsers}
                    />
                  </TableCell>
                  <TableCell>{t("common:name")}</TableCell>
                  <TableCell>{t("common:role")}</TableCell>
                  <TableCell>{t("common:consultancy")}</TableCell>
                  <TableCell>{t("common:dateCreated")}</TableCell>
                  <TableCell>{t("common:activated")}</TableCell>
                  <TableCell align="right">{t("common:actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => {
                  const isUserSelected = selectedUsers.includes(user.id);
                  return (
                    <TableRow hover key={user.id} selected={isUserSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isUserSelected}
                          onChange={(event) =>
                            handleSelectOneUser(event, user.id)
                          }
                          value={isUserSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Box display="flex" alignItems="center">
                          <Avatar className={classes.avatar} src={user.avatar}>
                            {getInitials(user.name)}
                          </Avatar>
                          <div>
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={goToUserDetails(
                                user.role,
                                user.id,
                                user.consultancyId,
                                user.employerId,
                                user.contactId,
                                user.candidateId
                              )}
                              variant="h5"
                            >
                              {user.name}
                            </Link>
                            <Typography variant="body2" color="textSecondary">
                              {user.email}
                            </Typography>
                          </div>
                        </Box>
                      </TableCell>
                      <TableCell>
                        {roles.find((role) => role.id === user.role).name}
                      </TableCell>
                      <TableCell>{user.consultancyName}</TableCell>
                      <TableCell>
                        {moment(user.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>
                        <CheckCircleIcon
                          style={{
                            fontSize: "32px",
                            color: user.activated
                              ? theme.palette.primary.main
                              : "grey",
                          }}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          component={RouterLink}
                          to={goToUserDetails(
                            user.role,
                            user.id,
                            user.consultancyId,
                            user.employerId,
                            user.contactId,
                            user.candidateId
                          )}
                        >
                          <SvgIcon fontSize="small">
                            <ArrowRightIcon />
                          </SvgIcon>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              className={classes.pagination}
              component="div"
              count={filteredUsers.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[12, 24, 36, 48]}
              labelRowsPerPage={t("common:rowsPerPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${t("common:of")} ${count}`
              }
            />
          </Box>
        </PerfectScrollbar>
      </Card>

      <ModalFormWrapper
        formTitle={"Filter User List"}
        handleOpen={open}
        handleClose={handleClose}
        maxWidth="xs"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel
                id="contactLabel"
                style={{ backgroundColor: "white" }}
              >
                {t("campaigns:selectConsultancy")}
              </InputLabel>
              <Select
                labelId="contactLabel"
                name={"consultancyId"}
                value={filters.consultancyId}
                onChange={handleFilterChange}
                renderValue={(selected) =>
                  consultancies.find(
                    (consultancy) => consultancy.id === selected
                  ).name
                }
                input={<OutlinedInput />}
              >
                {consultancies?.map((consultancy) => (
                  <MenuItem key={consultancy.id} value={consultancy.id}>
                    <Checkbox
                      checked={filters.consultancyId === consultancy.id}
                    ></Checkbox>
                    <ListItemText primary={consultancy.name}></ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <InputLabel
                id="contactLabel"
                style={{ backgroundColor: "white" }}
              >
                {t("campaigns:selectRole")}
              </InputLabel>
              <Select
                labelId="contactLabel"
                name={"role"}
                value={filters.role}
                onChange={handleFilterChange}
                renderValue={(selected) =>
                  roles.find((role) => role.id === selected).name
                }
                input={<OutlinedInput />}
              >
                {roles?.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    <Checkbox checked={filters.role === role.id}></Checkbox>
                    <ListItemText primary={role.name}></ListItemText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                onClick={handleFilterReset}
              >
                {t("common:resetFilters")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </ModalFormWrapper>
    </>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array,
  consultancies: PropTypes.array,
};

Results.defaultProps = {
  users: [],
  consultancies: [],
};

export default Results;
