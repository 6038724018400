import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Container,
	FormLabel,
	Grid,
	TextField,
	useMediaQuery,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 0,
		marginTop: "2rem",
		marginBottom: "2rem",
	},
	input: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: 400,
		marginBottom: "2rem",
	},
}));

export const InputAndButton = ({
	value,
	onChange,
	formLabel,
	placeholder,
	btnLabel,
	btnFunc,
	btnVariant,
	btnLabel2,
	btnFunc2,
	btnVariant2,
}) => {
	const classes = useStyles();
	const btn = btnLabel != null;
	const btn2 = btn && btnLabel2 != null ? true : false;
	const desktop = useMediaQuery("(min-width:1280px)");

	return (
		<Container className={classes.container} maxWidth="xl">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={12} xl={4} sm={6}>
							<form>
								{formLabel && <FormLabel>{formLabel}</FormLabel>}
								<Box display="flex">
									<TextField
										variant="outlined"
										name="header"
										placeholder={placeholder || "Block header"}
										value={value}
										onChange={onChange}
										style={{
											marginBottom: ".25rem",
											backgroundColor: "white",
											width: "100%",
										}}
									/>
								</Box>
							</form>
						</Grid>
						{btn ? (
							<>
								{desktop ? <Grid item lg={3} xl={6}></Grid> : null}
								<Grid item xs={12} xl={2} lg={3} sm={6}>
									<Box display="flex" alignItems="center" height="100%">
										<Button
											color="primary"
											size="large"
											type="submit"
											variant={btnVariant || "outlined"}
											fullWidth
											onClick={btnFunc}
										>
											{btnLabel || "CTA"}
										</Button>
									</Box>
								</Grid>
							</>
						) : null}
						{btn2 ? (
							<Grid item xs={12} xl={2} lg={3} sm={6}>
								<Button
									color="primary"
									size="large"
									type="submit"
									variant={btnVariant2 || "outlined"}
									fullWidth
									onClick={btnFunc2}
								>
									{btnLabel2}
								</Button>
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};
