import axios from "../utils/axios";

export * from "./sessionApi";
export * from "./SystemAdminApi";

export const getCompanyPageData = (host, route) => {
  return axios.get("Consultancies/getConsultancyData", {
    params: {
      host,
      route
    }
  });
};

export const contact = (formData) => {
  return axios.post("/Consultancies/contact", formData, {
    params: {
      domain: window.location.host
    }
  });
};

