import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from './../../api/talentAlertApi';

import { copyToClipboard } from './../../utils/copyToClipboard';

import { campaignShareInstructionsType, candidateShareInstructionsType } from './index';

const useStyles = makeStyles(() => ({
    boxSelect: {
        marginBottom: '12px'
    },
    boxInstructions: {
        marginBottom: '12px'
    },
    boxActions: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    textfield: {
        width: '75%'
    },
    button: {
        width: '15%'
    },
    textArea: {
        display: 'none'
    }
}));

const ShareInstructions = ({ campaignId, candidateId, isCampaign, selectedShareInstructionsType, changeSelectedInstructionsType }) => {

    const classes = useStyles();

    const [t] = useTranslation(['shareWidget']);

    const { enqueueSnackbar } = useSnackbar();

    const [shareLink, setShareLink] = useState('');
    const [HTMLSnippet, setHTMLSnippet] = useState('');
    const [gifSrc, setGifSrc] = useState('');

    const { consultancyId } = useSelector((state) => state.session);

    const shareInstructionsType = isCampaign ? campaignShareInstructionsType : candidateShareInstructionsType;

    useEffect(() => {
        handleShareLink();

        if (isCampaign) {
            handleCampaignHTMLSnippet();
        } else {
            handleCandidateHTMLSnippetAndGifSrc();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId, candidateId, consultancyId]);

    const handleShareLink = () => {
        const domain = process.env.REACT_APP_API_URL.replace('/api/', '/share/tap');
        let link = `${domain}/${consultancyId}/${campaignId}`;

        if (candidateId) {
            link += `/${candidateId}`;
        }

        setShareLink(link);
    };

    const handleCampaignHTMLSnippet = () => {
        talentAlertApi.getCampaignHTMLSnippet(consultancyId, campaignId)
            .then((response) => {
                setHTMLSnippet(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCandidateHTMLSnippetAndGifSrc = () => {
        const candidateHTMLSnippetPromise = talentAlertApi.getCandidateHTMLSnippet(candidateId, campaignId, consultancyId);
        const candidateHiddenTalentAlertForSharePromise = talentAlertApi.getCandidateHiddenTalentAlertForShare(candidateId, campaignId, consultancyId);

        Promise.all([candidateHTMLSnippetPromise, candidateHiddenTalentAlertForSharePromise])
            .then(([candidateHTMLSnippetResponse, candidateHiddenTalentAlertForShareResponse]) => {
                setHTMLSnippet(candidateHTMLSnippetResponse.data);
                setGifSrc(candidateHiddenTalentAlertForShareResponse.data.profilePicture);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const copyFn = (event) => {
        event.preventDefault();

        switch (selectedShareInstructionsType) {
            case shareInstructionsType.LINK:
                copyToClipboard(shareLink);
                break;
            case shareInstructionsType.HTML:
                copyToClipboard(HTMLSnippet);
                break;
            default:
                copyToClipboard(gifSrc);
        }

        enqueueSnackbar(t('shareWidget:shareWidgetInstructiosnModalNotificationCopySuccess'), { variant: 'success' });
    };

    const shareInstructionChange = (event) => {
        changeSelectedInstructionsType(event.target.value);
    };

    const getCopyToClipboardTextFieldValue = () => {
        switch (selectedShareInstructionsType) {
            case shareInstructionsType.LINK:
                return shareLink;
            case shareInstructionsType.HTML:
                return t('shareWidget:shareWidgetInstructionsHTMLSnippetValue');
            default:
                return gifSrc;
        }
    };

    return (
        <>
            <Box className={ classes.boxSelect }>
                <Select
                    fullWidth
                    variant="outlined"
                    value={ selectedShareInstructionsType }
                    onChange={ shareInstructionChange } >
                    {
                        Object.values(shareInstructionsType).map((type, index) => (
                            <MenuItem key={ `${type}-${index}` } value={ type }>
                                { t(`shareWidget:shareWidgetInstructionsInstructionsTypeOption${type}`) }
                            </MenuItem>
                        ))
                    }
                </Select>
            </Box>

            <Box className={ classes.boxInstructions }>
                <Typography>
                    { t(`shareWidget:${isCampaign ? 'shareWidgetInstructionsInstructionsCampaignHeader' : `shareWidgetInstructionsInstructionsCandidateHeader-${selectedShareInstructionsType}`}`) }
                </Typography>
            </Box>

            {
                (selectedShareInstructionsType === shareInstructionsType.LINK || selectedShareInstructionsType === shareInstructionsType.HTML) && (
                    <>
                        <Box className={ classes.boxInstructions }>
                            <Typography>
                                { t(`shareWidget:${isCampaign ? 'shareWidgetInstructionsInstructionsCampaignBodyStep1' : `shareWidgetInstructionsInstructionsCandidateBodyStep1-${selectedShareInstructionsType}`}`) }
                            </Typography>
            
                            <Typography>
                                { t(`shareWidget:${isCampaign ? 'shareWidgetInstructionsInstructionsCampaignBodyStep2' : `shareWidgetInstructionsInstructionsCandidateBodyStep2-${selectedShareInstructionsType}`}`) }
                            </Typography>
            
                            <Typography>
                                { t(`shareWidget:${isCampaign ? 'shareWidgetInstructionsInstructionsCampaignBodyStep3' : `shareWidgetInstructionsInstructionsCandidateBodyStep3-${selectedShareInstructionsType}`}`) }
                            </Typography>
            
                            <Typography>
                                { t(`shareWidget:${isCampaign ? 'shareWidgetInstructionsInstructionsCampaignBodyStep4' : `shareWidgetInstructionsInstructionsCandidateBodyStep4-${selectedShareInstructionsType}`}`) }
                            </Typography>
            
                            {
                                selectedShareInstructionsType === shareInstructionsType.HTML && (
                                    <>
                                        <Typography>
                                            { t(`shareWidget:shareWidgetInstructionsInstructionsCandidateBodyStep5-${selectedShareInstructionsType}`) }
                                        </Typography>
                                    </>
                                )
                            }
                        </Box>
                    </>
                )
            }

            <Box className={ classes.boxActions }>
                <TextField className={ classes.textfield } value={ getCopyToClipboardTextFieldValue() } disabled={ true } />

                <Button
                    color="primary"
                    variant="contained"
                    onClick={ copyFn } >
                    { t("shareWidget:shareWidgetInstructionsCopyLinkBtn") }
                </Button>
            </Box>
        </>
    );

};

export default ShareInstructions;
