import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Tabs,
  Tab,
  AppBar,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import { TabPanel } from "src/components/TabPanel";
import { useTranslation } from "react-i18next";
import CandidateList from "./CandidateList";
import * as candidateStatusIds from "src/constants/candidateStatusIds";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0% 5%",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  addCandidateButton: {
    margin: theme.spacing(2),
    width: "auto",
  },
  appBar: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "10px",
  },
}));

function ContactCandidateStageView({ contactView }) {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const classes = useStyles();
  const [t] = useTranslation(["campaigns", "candidates", "vacancies"]);

  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    { value: 0, candidateStatusId: candidateStatusIds.ALL },
    { value: 1, candidateStatusId: candidateStatusIds.SHORTLIST },
    { value: 2, candidateStatusId: candidateStatusIds.FIRST_INTERVIEW },
    { value: 3, candidateStatusId: candidateStatusIds.FURTHER_INTERVIEW },
    { value: 4, candidateStatusId: candidateStatusIds.REJECTED },
    { value: 5, candidateStatusId: candidateStatusIds.OFFER_MADE },
  ];

  const handleChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Card>
        <BlockHeader
          headerTooltip={t("tooltips:campaignCandidates")}
          card
          padding
          header={t(`${campaignsPrefix}:campaignCandidates`)}
        />
        <CardContent className={classes.cardContent}>
          <AppBar
            position="static"
            color="transparent"
            className={classes.appBar}
          >
            <Tabs
              value={currentTab}
              onChange={handleChange}
              variant="scrollable"
              textColor="primary"
              scrollButtons="auto"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={t(`campaigns:candidateStatus${tab.candidateStatusId}`)}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </AppBar>
          {tabs.map((tab) => (
            <TabPanel value={currentTab} index={tab.value}>
              <CandidateList
                contactView={contactView}
                candidateStatus={tab.candidateStatusId}
              />
            </TabPanel>
          ))}
          <Divider />
        </CardContent>
      </Card>
    </>
  );
}

export default ContactCandidateStageView;
