import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import VideoRecorder from 'react-video-recorder';

import { Box, Button, CircularProgress, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import Actions from '../../VideoRecorderCommon/Actions';
import DisconnectedView from '../../VideoRecorderCommon/DisconnectedView';
import LoadingView from '../../VideoRecorderCommon/LoadingView';
import UnsupportedView from '../../VideoRecorderCommon/UnsupportedView';

const styles = {
  wrapper: {
    height: '420px',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  questionWrapper: {
    width: '45%'
  },
  videoWrapper: {
    height: '80%',
    width: '45%'
  }
};

const useStyles = makeStyles((theme) => ({
  wrapper: styles.wrapper,
  wrapperMobile: {
    ...styles.wrapper,
    height: 'auto',
    flexDirection: 'column'
  },
  questionWrapper: styles.questionWrapper,
  questionWrapperMobile: {
    ...styles.questionWrapper,
    width: '100%',
    textAlign: 'center',
    marginBottom: '40px'
  },
  typographyHeader: {
    fontWeight: 'bold'
  },
  typography: {
    marginTop: '20px'
  },
  videoWrapper: styles.videoWrapper,
  videoWrapperMobile: {
    ...styles.videoWrapper,
    height: 'auto',
    width: '100%'
  },
  video: {
    position: 'relative',
    width: '100%',
    height: '100%',
    border: '2px dashed #90A0B3',
    padding: '4px'
  },
  videoError: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e4ebf1',
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: '20px'
  },
  autostartTimerWrapper: {
    position: 'absolute',
    left: '20px',
    bottom: '20px',
    alignItems: 'center',
    color: '#fff'
  },
  autostartTimerCounter: {
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    fontSize: '20px',
    padding: '12px',
    borderRadius: '50%',
    marginRight: '12px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px'
  }
}));

const VideoAssessmentQuestion = ({ question, questionNum, preparationTime, isFinalQuestion, submitAnswer, nextQuestion }) => {

  const classes = useStyles();
  const [t] = useTranslation(['videoAssessment']);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width:767px)');

  const [props, setProps] = useState();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTimerOn, setIsTimerOn] = useState(true);
  const [doStartVideoTimer, setDoStartVideoTimer] = useState(false);
  const [isVideoTimerOn, setIsVideoTimerOn] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [didRecord, setDidRecord] = useState(false);

  const [autoStartTimer, setAutoStartTimer] = useState(preparationTime);
  const [videoTimer, setVideoTimer] = useState(0);

  const timerIntervalRef = useRef();
  const videoTimerIntervalRef = useRef();

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 1000);
  }, []);
  
  useEffect(() => {
    let timer = preparationTime;

    timerIntervalRef.current = setInterval(() => {
      if (timer === 0) {
        startRecording();
      }

      timer -= 1;
      setAutoStartTimer((prevState) => {
        return prevState - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (!doStartVideoTimer) {
      return;
    }

    setIsVideoTimerOn(true);
    let timer = 0;

    videoTimerIntervalRef.current = setInterval(() => {
      if (timer === question.timeLimit) {
        stopRecording();
      }

      timer += 1;
      setVideoTimer((prevState) => {
        return prevState + 1;
      });
    }, 1000);

    return () => {
      clearInterval(videoTimerIntervalRef.current);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doStartVideoTimer]);

  const renderActions = (actionProps) => {
    if (!props) {
      setProps(actionProps);
    }

    return <Actions props={ actionProps } />
  };

  const startRecording = () => {
    clearInterval(timerIntervalRef.current);

    setIsTimerOn(false);
    setIsRecording(true);
    setDoStartVideoTimer(true);

    props.onStartRecording();
  };

  const stopRecording = () => {
    clearInterval(videoTimerIntervalRef.current);

    setIsVideoTimerOn(false);
    setIsRecording(false);
    setDidRecord(true);

    props.onStopRecording();
  };

  const onRecordingComplete = (videoBlob) => {
    setIsLoading(true);

    submitAnswer(videoBlob)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorSubmitAnswer'), { variant: 'error' });
        setHasError(true);

        setTimeout(() => {
          window.location.reload();
        }, 7000)
      });
  };

  return (
    <>
      <Grid className={ isMobile ? classes.wrapperMobile : classes.wrapper } >
        <Grid className={ isMobile ? classes.questionWrapperMobile : classes.questionWrapper } >
          {
            !isMobile && (
              <>
                <Typography variant='h2' className={ classes.typographyHeader } >
                  { t("videoAssessment:videoAssessmentQuestionHeader", { questionNum }) }
                </Typography>
              </>
            )
          }

          <Typography variant='h3' className={ classes.typography } >
            {
              isMobile ? (
                <>
                  { t("videoAssessment:videoAssessmentQuestionHeader", { questionNum }) } { question.text }
                </>
              ) : (
                <>
                  { question.text }
                </>
              )
            }
          </Typography>

          <Typography className={ classes.typography } >
            { t("videoAssessment:videoAssessmentQuestionInstructions", { time: question.timeLimit }) }
          </Typography>
        </Grid>

        <Grid className={ isMobile ? classes.videoWrapperMobile : classes.videoWrapper } >
          <Box className={ classes.video } >
            {
              hasError ?
              (
                <Box className={ classes.videoError }>
                  <Typography>
                    { t("videoAssessment:videoAssessmentQuestionSubmitError") }
                  </Typography>
                </Box>
              ) : (
                <>
                  <VideoRecorder
                    showReplayControls
                    replayVideoAutoplayAndLoopOff
                    countdownTime={ 0 }
                    renderActions={ renderActions }
                    renderDisconnectedView={ () => <DisconnectedView /> }
                    renderLoadingView={ () => <LoadingView /> }
                    renderUnsupportedView={ () => <UnsupportedView /> }
                    onRecordingComplete={ onRecordingComplete } />
      
                  <Box className={ classes.autostartTimerWrapper } style={{ display: isTimerOn ? 'flex' : 'none' }} >
                    <Typography className={ classes.autostartTimerCounter } >
                      { autoStartTimer }
                    </Typography>
      
                    <Typography>
                      { t("videoAssessment:videoAssessmentQuestionTimerLabel") }
                    </Typography>
                  </Box>
      
                  <Box className={ classes.autostartTimerWrapper } style={{ display: isVideoTimerOn ? 'flex' : 'none' }} >
                    <Typography className={ classes.autostartTimerCounter } >
                      { videoTimer }
                    </Typography>
                  </Box>
                </>
              )
            }
          </Box>

          <Box className={ classes.actions } >
            {
              isLoading ? (
                <>
                  <CircularProgress />
                </>
              ) : isRecording ? (
                <>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={ stopRecording } >
                    { t("videoAssessment:videoAssessmentQuestionStopRecordingButton") }
                  </Button>
                </>
              ) : didRecord ? (
                <>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={ nextQuestion } >
                    {
                      isFinalQuestion ?
                        t("videoAssessment:videoAssessmentQuestionFinalQuestionButton") :
                        t("videoAssessment:videoAssessmentQuestionNextQuestionButton")
                    }
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    onClick={ startRecording } >
                    { t("videoAssessment:videoAssessmentQuestionStartRecordingButton") }
                  </Button>
                </>
              )
            }
          </Box>
        </Grid>
      </Grid>
    </>
  );

};

export default VideoAssessmentQuestion;
