import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Grid, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  grid: {
    padding: '8px'
  },
  span: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      textDecorationColor: theme.palette.primary.main
    }
  },
  arrowBackIcon: {
    color: theme.palette.primary.main,
    marginRight: '8px'
  },
  typography: {
    color: theme.palette.primary.main
  }
}));

const PageBackButton = ({ goBackFn }) => {

  const [t] = useTranslation(['common']);
  const history = useHistory();
  const classes = useStyles();

  const goBack = () => {
    if (goBackFn) {
      goBackFn();
      return;
    }

    history.goBack();
  };

  return (
    <>
      <Grid container className={ classes.grid }>
        <span
          className={ classes.span }
          onClick={ goBack } >
          <ArrowBackIcon className={ classes.arrowBackIcon } />

          <Typography variant="body1" className={ classes.typography } >
            { t("common:goBack") }
          </Typography>
        </span>
      </Grid>
    </>
  );

};

export default PageBackButton;
