import React from "react";
import { Spring } from "react-spring/renderprops";

export function FadeIn({ children }) {
	return (
		<Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
			{(props) => <div style={props}>{children}</div>}
		</Spring>
	);
}
