import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ModalFormWrapper } from './../ModalFormWrapper';
import ShareInstructions from './ShareInstructions';
import PreviewShare from './PreviewShare';

export const campaignShareInstructionsType = {
    LINK: 0,
    HTML: 1
};

export const candidateShareInstructionsType = {
    LINK: 0,
    HTML: 1,
    GIF: 2
};

const styles = {
    boxContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '12px'
    },
    boxInstructions: {
        width: '50%',
        paddingTop: '20px'
    },
    boxPreview: {
        width: '40%'
    }  
};

const useStyles = makeStyles(() => ({
    boxContainer: styles.boxContainer,
    boxContainerMobile: {
        ...styles.boxContainer,
        flexDirection: 'column'
    },
    boxInstructions: styles.boxInstructions,
    boxInstructionsMobile: {
        width: '100%',
        marginBottom: '20px'
    },
    boxPreview: styles.boxPreview,
    boxPreviewMobile: {
        width: '100%'
    }
}));

const ShareWidget = ({ isModalOpen, toggleModal, candidateId = null, isCampaign = false }) => {

    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:767px)');
    const [t] = useTranslation(['shareWidget']);

    const { id } = useSelector((state) => state.talentAlert);

    const [selectedShareInstructionsType, setSelectedShareInstructionsType] = useState(isCampaign ? campaignShareInstructionsType.LINK : candidateShareInstructionsType.LINK);

    const changeSelectedInstructionsType = (shareInstructionsType) => {
        setSelectedShareInstructionsType(shareInstructionsType);
    };

    const handleClose = (event) => {
        event.preventDefault();
        toggleModal();
    };

    return (
        <>
            <ModalFormWrapper
                formTitle={ t(`shareWidget:${isCampaign ? "shareWidgetCardHeaderCampaign" : "shareWidgetCardHeaderCandidate"}`) }
                formTooltip={ t(`shareWidget:${isCampaign ? "shareWidgetModalCampaignTooltip" : "shareWidgetModalCandidateTooltip"}`) }
                maxWidth="lg"
                handleOpen={ isModalOpen }
                handleClose={ handleClose }>
                    <Box className={ isMobile ? classes.boxContainerMobile : classes.boxContainer }>
                        <Box className={ isMobile ? classes.boxInstructionsMobile : classes.boxInstructions }>
                            <ShareInstructions
                                campaignId={ id }
                                candidateId={ candidateId }
                                isCampaign={ isCampaign }
                                selectedShareInstructionsType={ selectedShareInstructionsType }
                                changeSelectedInstructionsType={ changeSelectedInstructionsType } />
                        </Box>

                        <Box className={ isMobile ? classes.boxPreviewMobile : classes.boxPreview }>
                            <PreviewShare
                                campaignId={ id }
                                candidateId={ candidateId }
                                isCampaign={ isCampaign }
                                selectedShareInstructionsType={ selectedShareInstructionsType } />
                        </Box>
                    </Box>
            </ModalFormWrapper>
        </>
    );
};

export default ShareWidget;
