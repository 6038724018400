import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { makeStyles } from "@material-ui/styles";
import {
  Container,
  Box,
  Typography,
  LinearProgress,
  CardHeader,
  Card,
  CardContent
} from "@material-ui/core";
import Page from "src/components/Page";
import { retrieveConsultancyDetails } from '../../actions';
import ActivateAccountForm from './ActivateAccountForm';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    width: '95%',
    marginTop: 50,
    marginBottom: 50,
    paddingLeft: 0,
    paddingRight: 0
  },
  loginContainer: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover'
  },
  loginCard: {
    borderRadius: 20,
    padding: 20
  },
  progress: {
    width: '50%'
  },
  header: {
    textAlign: 'center'
  },
  headerImage: {
    height: "auto",
    width: 600,
    maxWidth: '100%'
  },
  headerText: {
    fontSize: 60,
    textAlign: 'center',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 32
    },
    marginTop: -50,
    marginBottom: 70,
    marginLeft: 10,
    marginRight: 10,
    lineHeight: 'normal'
  },
  slides: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    height: '100%',
    paddingTop: 120,
    paddingBottom: 120,
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingTop: 120,
      paddingBottom: 120
    }
  }
}));

function ActivateAccount({activation_token}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { consultancyDetails } = useSelector(state => state.consultancy);
  const [t] = useTranslation(['common', 'users', 'groups']);
  const [loading, setLoading] = useState(false);

  //TODO: UPDATE THE BACKEND STRUCTURE TO GET LANDING PAGE DATA IN A GOOD WAY
  const [testData, setTestData] = useState({});


  useEffect(() => {
    const fetchDetails = () => {

      setLoading(true);
      retrieveConsultancyDetails(dispatch, { host: window.location.hostname, route: "login" })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          setTestData({
            ...testData,
          })
          setLoading(false);
        });
    };

    if (!consultancyDetails) {
      fetchDetails();
    } else {
      setTestData({
        ...testData,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);



  if (loading) {
    return (
      <Page
        className={classes.root}
        title={t('common:activateAccount')}
      >
        <LinearProgress className={classes.progress} />
      </Page>
    )
  };

  if (!consultancyDetails) {
    return (
      <Page
        className={classes.root}
        title={t('common:activateAccount')}
      >
        <div className={classes.loginContainer}>
          <Container maxWidth="sm" className={classes.container}>
            <Box className={classes.header}>
              <Card>
                <CardHeader title={(<Typography variant="h3">{t('common:activateAccount')}</Typography>)}/>
                <CardContent>
                  <ActivateAccountForm activation_token={activation_token} />
                </CardContent>
              </Card>
            </Box>
          </Container>
        </div>
      </Page>
    );
  }


  return (
    <Page
      className={classes.root}
      title={t('common:activateAccount')}
    >
      <div
        className={classes.loginContainer}
        style={{backgroundImage: `url(${consultancyDetails.login_background ? consultancyDetails.login_background.value : null})`}}
      >
        <Container maxWidth="sm" className={classes.container}>
          <Box className={classes.header}>
            <Card>
              <CardHeader title={(<Typography variant="h3">{t('common:activateAccount')}</Typography>)}/>
              <CardContent>
                <ActivateAccountForm activation_token={activation_token}/>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    </Page>
  );

}

export default ActivateAccount;
