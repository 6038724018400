import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import * as ConsultancyApi from 'src/api/consultancyApi';
import { ContentWrapper } from 'src/components/LayoutComponents/ContentWrapper';
import { CampaignCard } from 'src/components/Cards/CampaignCard';
import { PageTitle } from 'src/components/LayoutComponents/PageTitle';

function GroupDetails({ consultancyId, groupId }) {
  const history = useHistory();

  const [t] = useTranslation(['common', 'users', 'groups', 'campaigns']);

  const [groupDetails, setGroupDetails] = useState([]);

  const goToCampaignDetails = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/campaigns/${campaignId}`);
  };

  const getGroupCampaigns = function(groupId, consultancyId) {
    return ConsultancyApi.getGroupCampaigns(groupId, consultancyId).then(
      (response) => {
        setGroupDetails(response.data);
      }
    );
  };

  function createData(name, data) {
    return { name, data };
  }

  const getCampaingOwnerName = (owner) =>
    owner
      ? ` ${owner.profile.salutation ? owner.profile.salutation : ''} ${
          owner.profile.firstName
        } ${owner.profile.lastName} `
      : 'TBC';
  const getOwner = (admin, consultant, managedByRole) =>
    consultant && managedByRole === 'consultant' ? consultant : admin;

  const getCampaignDetails = (campaign) => {
    const date = new Date(campaign.createdAt);
    const formattedDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}`;
    const employerName = campaign.assignmentCampaign
      ? campaign.assignmentCampaign.employer.employerName
      : '';
    const campaignStatus = campaign.campaignStatus
      ? campaign.campaignStatus.status
      : 'draft';
    const campaignCandidates =
      campaign.candidates && campaign.candidates.length
        ? campaign.candidates.length
        : 0;

    const assignedTo = getCampaingOwnerName(
      getOwner(campaign.admin, campaign.consultant, campaign.managedByRole)
    );
    const campaignDetails = [
      createData(t(`campaigns:employerLabel`), employerName),
      createData(t(`campaigns:campaignStatusLabel`), campaignStatus),
      createData(t(`campaigns:dateStarted`), formattedDate),
      createData(t(`campaigns:candidatesLabel`), campaignCandidates),
      createData(t(`campaigns:assignedTo`), assignedTo),
    ];
    return campaignDetails;
  };

  useEffect(() => {
    getGroupCampaigns(groupId, consultancyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return groupDetails.groupName ? (
    <>
      <PageTitle
        pageTitle={`${groupDetails.groupName} ${t('campaigns:campaigns')}`}
      />
      <ContentWrapper loading={groupDetails ? false : true}>
        {groupDetails?.campaigns?.length ? (
          <Grid container spacing={3}>
            {groupDetails?.campaigns?.map((campaign, key) => {
              return (
                <Grid key={campaign.id} item md={4} sm={6} xs={12}>
                  <CampaignCard
                    campaignDetails={getCampaignDetails(campaign)}
                    name={campaign.campaignTitle}
                    btnLabel={t('common:viewMore')}
                    btnFunc={(event) => {
                      goToCampaignDetails(event, campaign.id);
                    }}
                    campaignLogo={campaign.campaignLogo}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography align='center'>
                {t('groups:groupHasNoCampaigns')}
              </Typography>
            </Grid>
          </Grid>
        )}
      </ContentWrapper>
    </>
  ) : null;
}

export default GroupDetails;
