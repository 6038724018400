/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { Redirect, useHistory } from "react-router";
import validate from "validate.js";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	TextField,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import * as SessionApi from "src/api/sessionApi";

const schema = {
	password: {
		presence: { allowEmpty: false, message: "is required" },
		format: /^(?=.*\d).{6,15}$/,
	},
	retypedPassword: {
		presence: { allowEmpty: false, message: "is required" },
		equality: "password",
		format: /^(?=.*\d).{6,15}$/,
	},
};

const useStyles = makeStyles((theme) => ({
	root: {},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		width: 200,
		padding: 5,
		borderRadius: 5,
	},
	actionSection: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginTop: theme.spacing(2),
	},
	link: {
		marginLeft: 16,
		color: "blue",
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
	cardTitle: {
		padding: "20px",
	},
}));

function ActivateAccountForm({ activation_token, className, ...rest }) {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(["validation", "common", "snackbar"]);
	const [loading, setLoading] = useState(false);
	const [isValidToken, setToken] = useState(null);
	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		return SessionApi.activateAccount(
			formState.values.password,
			activation_token
		)
			.then(() => {
				history.push("/");
				enqueueSnackbar(t("snackbar:accountActivatedSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				history.push("/");
				enqueueSnackbar(t("snackbar:accountActivatedError"), {
					variant: "error",
				});
			});
	};

	const hasError = (field) => {
		if (formState.touched[field] && formState.errors[field]) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		async function makeRequest() {
			const isValidtoke = await SessionApi.validateToken(activation_token);
			setToken(isValidtoke);
		}
		makeRequest();

		const errors = validate(formState.values, schema);
		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.values]);

	if (isValidToken === false) {
		return <Redirect to="/" />;
	}

	return (
		<form
			{...rest}
			className={clsx(classes.root, className)}
			onSubmit={handleSubmit}
		>
			<div className={classes.fields}>
				<TextField
					error={hasError("password")}
					fullWidth
					helperText={
						hasError("password") ? formState.errors.password[0] : null
					}
					label={t("common:passwordLabel")}
					name="password"
					onChange={handleChange}
					type="password"
					value={formState.values.password || ""}
					variant="outlined"
				/>
				<TextField
					error={hasError("retypedPassword")}
					fullWidth
					helperText={
						hasError("retypedPassword")
							? formState.errors.retypedPassword[0]
							: null
					}
					label={t("common:confirmPasswordLabel")}
					name="retypedPassword"
					onChange={handleChange}
					type="password"
					value={formState.values.retypedPassword || ""}
					variant="outlined"
				/>
				<Typography variant="h5" style={{ textAlign: "left" }}>
					{t("validation:weakPassword")}
				</Typography>
			</div>
			<div className={classes.actionSection}>
				<Button
					className={classes.submitButton}
					style={{
						padding: 5,
						borderRadius: 5,
					}}
					color="secondary"
					disabled={!formState.isValid}
					size="large"
					type="submit"
					variant="contained"
				>
					{loading ? (
						<CircularProgress className={classes.progressCircle} size={25} />
					) : (
						`${t("common:activateAccount")}`
					)}
				</Button>
			</div>
		</form>
	);
}

ActivateAccountForm.propTypes = {
	className: PropTypes.string,
};

export default ActivateAccountForm;
