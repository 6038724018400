import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Grid,
	Card,
	CardContent,
	Button,
	Typography,
	FormControl,
	InputAdornment,
	OutlinedInput,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	createButton: {
		margin: "12px",
		marginRight: "0px",
	},
	gridRow: {
		marginTop: "20px",
	},
	consultancyLogo: {
		height: "50px",
		width: "auto",
	},
	userRow: {
		backgroundColor: `${theme.palette.primary.light}`,
		borderRadius: "5px",
		margin: "12px",
	},
	userRowContainer: {
		alignItems: "center",
	},
	headerRow: {
		textAlign: "right",
		padding: "0px 12px",
	},
	alignItemsCenter: {
		alignItems: "center",
	},
	noTopBottomPadding: {
		padding: "0px 4px",
	},
	textAlignRight: {
		textAlign: "right",
	},
	resendEmailButtonText: {
		color: "white",
	},
	noButtonPadding: {
		marginBottom: theme.spacing(4.5),
	},
	headerAccent: {
		height: 20,
		width: 4,
		backgroundColor: theme.palette.primary.main,
		marginRight: 6,
	},
}));

function UsersandGroups({ title, btnLabel, btnFunc, children, handleSearch }) {
	const classes = useStyles();

	return (
		<Grid item xs={12} md={6}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Grid container spacing={3} className={classes.alignItemsCenter}>
						<Grid item xs={5} md={7}>
							<Box style={{ display: "flex", alignItems: "center" }}>
								<div className={classes.headerAccent} />
								<Typography variant="h3" className={classes.noTopBottomPadding}>
									{title}
								</Typography>
							</Box>
						</Grid>
						<Grid
							item
							xs={7}
							md={5}
							className={(classes.noTopBottomPadding, classes.textAlignRight)}
						>
							{btnLabel && btnFunc ? (
								<Button
									color="primary"
									size="medium"
									type="submit"
									variant="contained"
									onClick={btnFunc}
								>
									{btnLabel}
								</Button>
							) : (
								<Grid className={classes.noButtonPadding} />
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Card>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<FormControl
										variant="outlined"
										fullWidth
										className={classes.formControl}
									>
										<OutlinedInput
											id={`${title}`}
											startAdornment={
												<InputAdornment position="start">
													<SearchIcon />
												</InputAdornment>
											}
											onChange={handleSearch}
										/>
									</FormControl>
								</Grid>
								{children}
							</Grid>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default UsersandGroups;
