import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import validate from "validate.js";
import {
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  CircularProgress,
  Collapse,
  Tooltip,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,

} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import SetsKCQSelect from "./SetsKCQSelect";
import InfoIcon from "@material-ui/icons/Info";
import { BlockHeader } from "../../../../components/LayoutComponents/BlockHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


const useStyles = makeStyles((theme) => ({
  fields: {
    padding: theme.spacing(1),
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
  predefinedSetsTitle: {
    marginTop: theme.spacing(1)
  },
  button: {
    marginTop: theme.spacing(2),
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
  menuBox: {
    marginBottom: theme.spacing(2)
  },
  setContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'flexDirection',
    lineHeight: 2.5
  },
  collapse: {
    width: '100%'
  }
}));

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

function AddKCQFromSetModal({ onSuccess, open, handleClose }) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common", "modues", "tooltips"]);

  const campaign = useSelector((state) => state.campaign);
  const personalQuestionSets = useSelector((state) => state.kcq.personalQuestionSets);
  const { isTalentAlertCampaign } = useSelector((state) => state.talentAlert);
  const [personalQuestionsSelected, setPersonalQuestionsSelected] = useState({});
  const [selectedSetsIds, setSelectedSetsIds] = useState([]);
  let defaultState = {
    isValid: false,
    values: {
      inviteContacts: !isTalentAlertCampaign,
      selectedContact: {
        email: "",
      },
      questionSetTitle: "",
      selectedQuestions: [],
      createContact: false,
    },
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);

  const menu = 'personal';

  const resetForm = () => {
    setFormState(defaultState);
    handleClose();
  };

  const handleInviteContactChange = () => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        inviteContacts: !prevValues.values.inviteContacts,
      },
    }));
  };

  const autoCompleteContactSelectChange = (event, value) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedContact: {
          ...value,
        },
        createContact: false,
      },
    }));
  };

  const emailInputChange = (event) => {
    event.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedContact: {
          email: event.target.value,
        },
      },
    }));
  };

  const setToggle = (index) => {
    const innerIndex = selectedSetsIds.indexOf(index);

    if (innerIndex === -1) {
      setSelectedSetsIds([...selectedSetsIds, index]);
    }
    else {
      const newSets = [...selectedSetsIds]
      newSets.splice(newSets.indexOf(index), 1)
      setSelectedSetsIds([...newSets])
    }
  }


  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const schema = {
    jobTitle: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 32,
      },
    },
    firstName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 32,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 32,
      },
    },
    salutation: {
      length: {
        maximum: 10,
      },
    },
    email: {
      presence: { allowEmpty: false, message: "is required" },
      email: true,
      length: {
        maximum: 64,
      },
    },
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const formHasNoErros = () => {
    if (
      formState.values.firstName &&
      formState.values.lastName &&
      formState.values.email &&
      !hasError("email") &&
      formState.values.jobTitle
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (
      formState.values.selectedQuestions.length ||
      !formState.values.inviteContacts ||
      (formState.values.inviteContacts &&
        (!formState.values.createContact ||
          (formState.values.createContact && formHasNoErros())) &&
        emailRegex.test(formState.values.selectedContact.email))
    ) {
      setFormState((prevValues) => ({
        ...prevValues,
        isValid: true,
      }));
    } else {
      setFormState((prevValues) => ({
        ...prevValues,
        isValid: false,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);


  const handleSubmit = () => {
    setIsLoading(true);
    const questionArr = [];
    Object.keys(personalQuestionsSelected).forEach((pSet) => {
      Object.keys(personalQuestionsSelected[pSet]).forEach((pCategory) => {
        const category = { ...personalQuestionSets[pSet].questions[pCategory] }
        category.questions = [];
        Object.keys(personalQuestionsSelected[pSet][pCategory]).forEach((pQuestion) => {
          const questionIndex = personalQuestionsSelected[pSet][pCategory][pQuestion];
          if (questionIndex) {
            category.questions.push(personalQuestionSets[pSet].questions[pCategory].questions[pQuestion])
          }
        })
        questionArr.push(category)
      })
    })

    onSuccess({ ...formState.values, selectedQuestions: questionArr, contactPreselection: selectedSetsIds }, true).then(() => {
      setIsLoading(false);
      setFormState({
        isValid: false,
        values: {
          inviteContacts: true,
          useCustomSet: false,
          selectedContact: {
            email: "",
          },
          questionSetTitle: "",
          selectedQuestions: [],
          createContact: true,
        },
        touched: {},
        errors: {},
      });
      resetForm();
    });
  };

  return (
    <ModalFormWrapper
      formTitle={t("campaigns:addKCQTitle")}
      handleOpen={open}
      handleClose={resetForm}
      maxWidth={"sm"}
    >
      <Grid container spacing={3}>
        {
          !isTalentAlertCampaign && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.values.inviteContacts}
                    onChange={handleInviteContactChange}
                    name="inviteContactToggle"
                    color="primary"
                  />
                }
                label={
                  <div style={{ display: "flex" }}>
                    <Typography variant="h5">{t("modules:kcqInvite")}</Typography>
                    <Tooltip
                      enterTouchDelay={1}
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      style={{ marginLeft: "10px" }}
                      title={t("tooltips:selectKCQQuestions")}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                }
              />
            </Grid>
          )
        }

        {formState.values.inviteContacts && (
          <Collapse
            in={formState.values.inviteContacts}
            style={{ width: "100%" }}
          >
            <Grid item xs={12}>
              <div className={classes.fields}>
                <Autocomplete
                  id="contactAutoComplete"
                  options={campaign.campaignContacts || []}
                  getOptionLabel={(campaignContact) => {
                    if (
                      campaignContact &&
                      campaignContact.jobTitle &&
                      campaignContact.firstName &&
                      campaignContact.lastName
                    ) {
                      return `${campaignContact.firstName} ${campaignContact.lastName} ${campaignContact.jobTitle} `;
                    } else {
                      return `${campaignContact.email}`;
                    }
                  }}
                  value={formState.selectedContact}
                  style={{ width: "100%" }}
                  onChange={autoCompleteContactSelectChange}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label={t("campaigns:selectContact")}
                        variant="outlined"
                      />
                    );
                  }}
                />
                <TextField
                  fullWidth
                  className={classes.emailInput}
                  label={t("common:emailLabel")}
                  name="email"
                  variant="outlined"
                  required
                  onChange={emailInputChange}
                  value={formState.values.selectedContact.email || ""}
                />
              </div>

              <BlockHeader
                card
                padding
                header={'Choose KCQ Set for Contact to select'}
              />

              <Grid item xs={12}>

                {personalQuestionSets?.map((questionSet, index) => (
                  <ExpansionPanel
                    className={classes.questionSet}
                    key={"questionSets" + index}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h5" align="center" className={classes.setContent}>
                        <Checkbox
                          onChange={() => { setToggle(questionSet.id) }}
                        >
                        </Checkbox>
                        <span>
                          {questionSet.name}
                        </span>
                      </Typography>

                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid xs={12}>
                        <Grid container style={{ marginBottom: "20px" }}></Grid>
                        <Grid xs={12}>

                          {questionSet.questions?.map((category, catIndex) => (
                            <>
                              <ExpansionPanel
                                key={"questionSets" + index + "cat" + catIndex}
                              >
                                <ExpansionPanelSummary
                                  expandIcon={<ExpandMoreIcon />}
                                >
                                  <Typography variant="h5" align="center">

                                    {category.name}
                                  </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                  <Grid className={classes.questionGrid} item xs={12}>
                                    {category.questions?.map(
                                      (question, questionIndex) => {
                                        return (
                                          <Grid className={classes.row}>
                                            <Typography
                                              className={classes.question}
                                              variant="h5"
                                            >
                                              {question}
                                            </Typography>
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                </ExpansionPanelDetails>
                              </ExpansionPanel>
                            </>
                          ))}
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </Grid>
              <Button
                className={classes.button}
                color="primary"
                size="large"
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={!formState.values.selectedContact.email || selectedSetsIds.length <= 0 || isLoading}
              >
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size={16} />
                  </div>
                ) : (
                  <>
                    {t("campaigns:sendInvite")}
                  </>
                )}
              </Button>
            </Grid>
          </Collapse>
        )}

        {!formState.values.inviteContacts && (
          <>
            <Collapse in={!formState.values.inviteContacts} className={ classes.collapse }>
              {
                menu === 'personal' && <SetsKCQSelect
                  personalQuestionSets={personalQuestionSets}
                  parentPropStateChange={setPersonalQuestionsSelected}
                />
              }

              <Button
                className={classes.button}
                color="primary"
                size="large"
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleSubmit}
                disabled={!formState.isValid || isLoading}
              >
                {isLoading ? (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size={16} />
                  </div>
                ) : (
                  <>
                    {t("campaigns:addToCampaign")}
                  </>
                )}
              </Button>
            </Collapse>

          </>
        )}
      </Grid>
    </ModalFormWrapper>
  );
}
export default AddKCQFromSetModal;
