import React from "react";
import { Box, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const SelectMenu = (props) => {
  const [t] = useTranslation(["newTranslations"]);

  //defaults if no arguments passed
  const label = t("newTranslations:itemsPerPage");
  const values = [10, 24, 36, 48, 100];

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <Box style={{ padding: "16px", display: "flex" }}>
      <TextField
        label={label}
        name="sort"
        onChange={handleChange}
        select
        SelectProps={{ native: true, style: { minWidth: "110px" } }}
        value={props.value}
        variant="outlined"
      >
        {props.items
          ? props.items.map((item) => (
              <option
                key={item.key ? item.key : item}
                value={item.key ? item.key : item}
              >
                {item.label ? item.label : item}
              </option>
            ))
          : values.map((item, index) => (
              <option
                key={item.key ? item.key : item}
                value={item.key ? item.key : item}
              >
                {item.label ? item.label : item}
              </option>
            ))}
      </TextField>
    </Box>
  );
};
