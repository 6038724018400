import React from 'react';

const BullhornRedirectUri = () => {

  return (
    <>
      This is Bullhorn redirect uri
    </>
  );

};

export default BullhornRedirectUri;
