import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Button, Container, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 0,
		marginTop: "2rem",
		marginBottom: "2rem",
	},
}));

export const ReturnButton = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [t] = useTranslation(["common"]);
	const [scrollRequired, setScrollRequired] = useState(false);

	window.onscroll = function() {
		if (window.scrollY > 100) {
			setScrollRequired(true);
		} else {
			setScrollRequired(false);
		}
	};

	const handleScrollUp = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<>
			{scrollRequired ? (
				<Container className={classes.container} maxWidth="xl">
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Grid container spacing={3}>
								<Grid item xs={0} xl={10} lg={9} md={8}></Grid>

								<Grid item xs={12} xl={2} lg={3} md={4}>
									<Button
										fullWidth
										startIcon={<ArrowUpwardIcon />}
										color={
											theme.palette.button?.main || theme.palette.primary.main
										}
										variant="text"
										onClick={handleScrollUp}
									>
										{t("common:returnTop")}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			) : null}
		</>
	);
};
