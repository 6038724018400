import { Typography, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import * as CampaignApi from "src/api/campaignApi";
import AddKCQOptionModal from "./AddKCQOptionModal";
import ExistingCampaignKCQModal from "./ExistingCampaignKCQModal";
import GuestViewModal from "./GuestViewModal";
import {
  DELETE_CAMPAIGN_KCQ,
  CREATE_CAMPAIGN_KCQS,
  UPDATE_CAMPAIGN_CONTACTS,
} from "src/actions/campaignActions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import IconListItem from "src/components/LayoutComponents/IconListItem";
import * as campaignActions from "src/actions/campaignActions";

import * as talentAlertApi from './../../../../api/talentAlertApi';
import * as talentAlertActions from './../../../../actions/talentAlertActions';

function CampaignKeyCompetencyCard({ guestView }) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation([
    "validation",
    "campaigns",
    "snackbar",
    "tooltips",
  ]);
  const dispatch = useDispatch();

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaign = useSelector((state) => state.campaign);
  const talentAlert = useSelector((state) => state.talentAlert);

  const campaignId = campaign.id ? campaign.id : talentAlert.id;

  const [kcqModalOpen, setKcqModalOpen] = useState(false);
  const [guestKcqModalOpen, setGuestKcqModalOpen] = useState(false);
  const [currentCampaignKcq, setCurrentCampaignKcq] = useState([]);

  useEffect(() => {
    if (talentAlert.isTalentAlertCampaign) {
      setCurrentCampaignKcq(talentAlert.currentTalentAlertKCQ);
      return;
    }

    setCurrentCampaignKcq(campaign.currentCampaignKcq);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.currentCampaignKcq, talentAlert.currentTalentAlertKCQ]);

  const handleKCQModalOpen = () => {
    setKcqModalOpen(true);
  };
  const handleKCQModalClose = () => {
    setKcqModalOpen(false);
  };

  const handleGuestKCQModalOpen = () => {
    setGuestKcqModalOpen(true);
  };
  const handleGuestKCQModalClose = () => {
    setGuestKcqModalOpen(false);
  };

  //TODO:Prevent admin or consultant from deleting KCQ if they've already been answered by the candidate

  const onKCQSuccess = (kcqData) => {
    if (kcqData?.selectedQuestions?.length) {
      let request;

      if (talentAlert.isTalentAlertCampaign) {
        request = talentAlertApi.assignKCQToCampaign(kcqData, consultancyId, campaignId);
      } else {
        request = CampaignApi.assignKCQToCampaign(kcqData, consultancyId, campaignId);
      }

      return request
        .then((response) => {
          if (kcqData.inviteContacts) {
            enqueueSnackbar(t("snackbar:kcqSentToContact"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t("snackbar:kcqAddedToCampaign"), {
              variant: "success",
            });
          }

          let dispatchObj;

          if (talentAlert.isTalentAlertCampaign) {
            dispatchObj = {
              type: talentAlertActions.SET_TALENT_ALERT_KCQ,
              currentTalentAlertKCQ: {
                name: response.data.name,
                questions: response.data.questions
              }
            }
          } else {
            dispatchObj = {
              type: CREATE_CAMPAIGN_KCQS,
              currentCampaignKcq: {
                name: response.data.name,
                questions: response.data.questions
              }
            }
          }

          dispatch(dispatchObj);

          setCurrentCampaignKcq({
            name: response.data.name,
            questions: response.data.questions
          });

          if (response.data.contact) {
            let campaignContacts = [...campaign.campaignContacts];
            campaignContacts.push({ ...response.data.contact });
            let uninvitedContacts = [...campaign.uninvitedContacts];
            uninvitedContacts.push({ ...response.data.contact });
            dispatch({
              type: UPDATE_CAMPAIGN_CONTACTS,
              campaignContacts,
              uninvitedContacts,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t("snackbar:kcqUpdateFailed"), { variant: "error" });
        });
    } else {
      return CampaignApi.inviteContactToSelectKCQs(
        kcqData,
        consultancyId,
        campaignId
      ).then(() =>
        CampaignApi.getCampaignDetails(consultancyId, campaignId).then(
          (response) => {
            dispatch({
              type: campaignActions.UPDATE_CAMPAIGN,
              campaign: response.data,
            });
          }
        )
      );
    }
  };

  const [
    existingCampaignKCQModalOpen,
    setExistingCampaignKCQModalOpen,
  ] = useState(false);

  const handleExistingCampaignKCQModalOpen = () => {
    setExistingCampaignKCQModalOpen(true);
  };
  const handleExistingCampaignKCQModalClose = () => {
    setExistingCampaignKCQModalOpen(false);
  };

  const onUpdateKCQs = (questions) => {
    let request;

    if (talentAlert.isTalentAlertCampaign) {
      request = talentAlertApi.updateTalentAlertCampaignKCQ(campaignId, consultancyId, questions);
    } else {
      request = CampaignApi.updateCampaignKCQ(campaignId, consultancyId, questions);
    }

    return request
      .then(() => {
        enqueueSnackbar(t("snackbar:kcqUpdated"), { variant: "success" });

        const dispatchObj = {
          type: '',
          kcqs: questions
        };

        if (talentAlert.isTalentAlertCampaign) {
          dispatchObj.type = talentAlertActions.UPDATE_TALENT_ALERT_KCQS;
        } else {
          dispatchObj.type = campaignActions.UPDATE_CAMPAIGN_KCQ;
        }

        dispatch(dispatchObj);
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:kcqUpdatedFailed"), { variant: "error" });
      });
  };

  const startOver = () => {
    let request;

    if (talentAlert.isTalentAlertCampaign) {
      request = talentAlertApi.deleteTalentAlertCampaignKCQ(campaignId, consultancyId);
    } else {
      request = CampaignApi.deleteCampaignKCQ(campaignId, consultancyId);
    }

    return request
      .then(() => {
        enqueueSnackbar(t("snackbar:kcqDeleteSuccess"), { variant: "success" });
        dispatch({ type: DELETE_CAMPAIGN_KCQ });
        dispatch({ type: talentAlertActions.DELETE_TALENT_ALERT_KCQ });
        handleExistingCampaignKCQModalClose();
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:kcqDeleteFailed"), { variant: "error" });
      });
  };

  const deleteCampaignKCQ = (event) => {
    event.preventDefault();

    let request;

    if (talentAlert.isTalentAlertCampaign) {
      request = talentAlertApi.deleteTalentAlertCampaignKCQ(campaignId, consultancyId);
    } else {
      request = CampaignApi.deleteCampaignKCQ(campaignId, consultancyId);
    }

    return request
      .then(() => {
        enqueueSnackbar(t("snackbar:kcqDeleteSuccess"), { variant: "success" });
        dispatch({ type: DELETE_CAMPAIGN_KCQ });
        dispatch({ type: talentAlertActions.DELETE_TALENT_ALERT_KCQ });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:kcqDeleteFailed"), { variant: "error" });
      });
  };

  return (
    <div>
      <Grid container spacing={0}>
        <BlockHeader
          card
          header={t("campaigns:kcqTitle")}
          icon={!currentCampaignKcq && !guestView && !guestView ? <AddIcon /> : null}
          btnFunc={handleKCQModalOpen}
          headerTooltip={t("tooltips:selectKeyCompetencyQuestions")}
        />
      </Grid>
      {currentCampaignKcq ? (
        <IconListItem
          text={t("campaigns:kcqTitle")}
          endIcon={<EditIcon />}
          endFunc={
            !guestView
              ? handleExistingCampaignKCQModalOpen
              : handleGuestKCQModalOpen
          }
          startIcon={!guestView ? <DeleteIcon /> : null}
          startFunc={deleteCampaignKCQ}
          startTip={t("common:removeKCQs")}
          endTip={t("common:editKCQs")}
        />
      ) : (
        <Typography variant="h5" style={{ marginTop: 16 }}>
          {t("campaigns:noKcqs")}
        </Typography>
      )}
      <GuestViewModal
        open={guestKcqModalOpen}
        handleClose={handleGuestKCQModalClose}
      />
      <ExistingCampaignKCQModal
        open={existingCampaignKCQModalOpen}
        handleClose={handleExistingCampaignKCQModalClose}
        onUpdateKCQs={onUpdateKCQs}
        startOver={startOver}
        onSuccess={onKCQSuccess}
      />
      <AddKCQOptionModal
        open={kcqModalOpen}
        handleClose={handleKCQModalClose}
        onSuccess={onKCQSuccess}
      />
    </div>
  );
}
export default CampaignKeyCompetencyCard;
