import React, { useState, useEffect } from "react";
import Header from "./Header";
import Results from "./Results";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";

function EmployerListView() {
  const [employers, setEmployers] = useState(null);
  const [consultancies, setConsultancies] = useState([]);

  const getAllEmployers = () => {
    return SystemAdminApi.getAllEmployers()
      .then((response) => {
        setEmployers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getConsultancies = () => {
    return SystemAdminApi.getConsultancies()
      .then((response) => {
        setConsultancies(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllEmployers();
    getConsultancies();
  }, []);

  return (
    <>
      <Header consultancies={consultancies} />
      <ContentWrapper>
        {employers ? (
          <Results employers={employers} consultancies={consultancies} />
        ) : null}
      </ContentWrapper>{" "}
    </>
  );
}

export default EmployerListView;
