import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Menu,
  SvgIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Calendar as CalendarIcon } from 'react-feather';
import DatePicker from './DatePicker';
import Consultancyfilter from './ConsultancyFilter';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  menu: { margin: '10px', width: '285px' },
}));

function Header({
  className,
  dateSelect,
  title,
  consultancySelect,
  activeConsultancies,
}) {
  const classes = useStyles();
  const actionRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const dateFrom = useSelector((state) => state.analytics.dateFrom);
  const dateTo = useSelector((state) => state.analytics.dateTo);
  const displayDateA = new Date(dateFrom).toLocaleDateString('en-gb');
  const displayDateB = new Date(dateTo).toLocaleDateString('en-gb');
  const [t] = useTranslation(['common']);

  return (
    <Grid
      container
      spacing={3}
      justify='space-between'
      className={clsx(classes.root, className)}
    >
      <Grid item>
        <Typography
          style={{ marginTop: '8px' }}
          variant={
            title && title !== t('common:totalCustomersLabel') ? 'h6' : 'h4'
          }
          color='textPrimary'
          align='center'
        >
          {title
            ? title
            : `${t(
                'common:analyticsforSelectedDateRangeLabel'
              )} (${displayDateA} - ${displayDateB})`}
        </Typography>
      </Grid>
      {consultancySelect && (
        <Grid item>
          <Consultancyfilter activeConsultancies={activeConsultancies} />
        </Grid>
      )}

      {dateSelect && (
        <Grid item>
          <Button ref={actionRef} onClick={() => setMenuOpen(true)}>
            <SvgIcon fontSize='small' className={classes.actionIcon}>
              <CalendarIcon />
            </SvgIcon>
            {t('common:changeDateRangeLabel')}
          </Button>
          <Menu
            className={classes.menu}
            anchorEl={actionRef.current}
            onClose={() => setMenuOpen(false)}
            open={isMenuOpen}
            PaperProps={{ className: classes.menu }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <DatePicker />
          </Menu>
        </Grid>
      )}
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
