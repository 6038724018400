export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE';
export const TOGGLE_CONTACT_VIEW = 'TOGGLE_CONTACT_VIEW';
export const UPDATE_BREADCRUMBS = 'UPDATE_BREADCRUMBS';
export const UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE';

export const login = () => dispatch =>
  dispatch({
    type: SESSION_LOGIN,
  });

export const logout = () => dispatch =>
  dispatch({
    type: SESSION_LOGOUT,
  });
