import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Container, LinearProgress, useMediaQuery } from "@material-ui/core";
import Page from "src/components/Page";

import { retrieveConsultancyDetails } from "../../actions";
import VideoBlock from "./VideoBlock";
import LoginHero from "./LoginHero";
import LandingCards from "./LandingCards";
import ContactForm from "./ContactForm";
import LandingFooter from "./LandingFooter";
import CardCarousel from "./Components/CardCarousel";
import * as api from "../../api/landingApi";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    height: "100%",
    width: "95%",
    marginTop: 50,
    marginBottom: 50,
    paddingLeft: 0,
    paddingRight: 0,
  },
  loginContainer: {
    width: "100%",
    height: "100vh",
    backgroundSize: "cover",
  },
  bgImgContainer: {
    backgroundImage:
      "url(https://images.unsplash.com/photo-1552664730-d307ca884978?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80)",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "100vh",
    zIndex: -10,
    position: "absolute",
    top: 0,
    left: 0,
  },
  loginCard: {
    padding: 20,
    maxWidth: "400px",
    zIndex: 1,
  },
  progress: {
    width: "50%",
  },

  header: {
    textAlign: "center",
  },
  headerImage: {
    height: "auto",
    width: 600,
    maxWidth: "100%",
  },
  headerText: {
    fontSize: 60,
    textAlign: "center",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
    },
    marginTop: -50,
    marginBottom: 70,
    marginLeft: 10,
    marginRight: 10,
    lineHeight: "normal",
  },
  slides: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    height: "100%",
    paddingTop: 120,
    paddingBottom: 120,
    padding: theme.spacing(3, 6),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 120,
      paddingBottom: 120,
    },
  },
}));

const testingData = {
  benefits: false,
  benefits_caption: "Measurably Better Recruitment",
  benefits_tabs: [
    {
      image: require("../../assets/images/icons/mark.png"),
      title: "Make sure you find the right managers",
      subtitle:
        "Almost 100% of the executies recruited using i-intro® still work for the company after 12 months and more",
    },
    {
      image: require("../../assets/images/icons/stopwatch.png"),
      title: "Does not matter when. No matter where.",
      subtitle:
        "You can easily and securely access the candidates for your search request at any time from any internet-enabled device.",
    },
    {
      image: require("../../assets/images/icons/degrees.png"),
      title: "Make well-informed decisions",
      subtitle:
        "Personality profiles, competence-based questions, references and candidate videos ensure a thorough 360 ° evaluation of all applicants.",
    },
  ],
  slides: false,
  slides_caption: "Comprehensive Candidate Profiles",
  slides_list: [
    {
      image:
        "https://iintro-v3.s3.eu-west-2.amazonaws.com/organisations/166/image/17042020131308-21022020150350-phone-1.png",
      title: "Compare Shortlist",
      subtitle:
        "The abilities and expected salaries of the shortlisted candidates can be easily compared, facilitating faster and more accurate decision-making",
    },
    {
      image:
        "https://iintro-v3.s3.eu-west-2.amazonaws.com/organisations/166/image/17042020131308-21022020150350-phone-1.png",
      title: "Secure Online Access",
      subtitle:
        "Employers and candidates are provided with their own secure account so information can be easily uploaded and viewed.",
    },
    {
      image:
        "https://iintro-v3.s3.eu-west-2.amazonaws.com/organisations/166/image/17042020131308-21022020150350-phone-1.png",
      title: "Candidate Focused",
      subtitle:
        "Candidates create and upload their own content so they can highlight the elements of their skills and experience they feel are most important.",
    },
    {
      image:
        "https://iintro-v3.s3.eu-west-2.amazonaws.com/organisations/166/image/17042020131308-21022020150350-phone-1.png",
      title: "Video Introductions",
      subtitle:
        "From simple introductions to interview-style Q&As, video technology allow employers to “meet” the candidates remotely.",
    },
    {
      image:
        "https://iintro-v3.s3.eu-west-2.amazonaws.com/organisations/166/image/17042020131308-21022020150350-phone-1.png",
      title: "Online Messaging",
      subtitle:
        "Employers can ask the recruitment consultant questions and discuss options with their colleagues using the private messaging system.",
    },
  ],
};

function Home() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { consultancyDetails } = useSelector((state) => state.consultancy);
  const landingPageId = consultancyDetails?.landing.id;

  const [loading, setLoading] = useState(false);
  const [assetUrls, setAssetUrls] = useState([]);
  const [playing, setPlaying] = useState(false);

  const mobile = useMediaQuery("(max-width:1279px)");

  //TODO: UPDATE THE BACKEND STRUCTURE TO GET LANDING PAGE DATA IN A GOOD WAY
  const [testData, setTestData] = useState({});
  const [trigger, setTrigger] = useState(false);
  const consultancyColour = useSelector(
    (state) =>
      state.consultancy.consultancyDetails?.theme.themeObject.palette.primary
        .main
  );

  const getUrls = () => {
    if (landingPageId) {
      return api.getAssetUrls(landingPageId).then((response) => {
        setAssetUrls(response.data);
      });
    }
  };

  useEffect(() => {
    const fetchDetails = () => {
      setLoading(true);
      retrieveConsultancyDetails(dispatch, {
        host: window.location.hostname,
        route: "login",
      })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          setLoading(false);
        });
    };

    if (!consultancyDetails) {
      fetchDetails();
      setTrigger(true);
    } else {
      setTestData({
        ...testData,
        ...testingData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    getUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageId]);

  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  if (loading) {
    return (
      <Page className={classes.root} title="Login">
        <LinearProgress
          className={classes.progress}
          // style={{ backgroundColor: "#a9a9a9" }} //TODO MAKE A FIXED COLOUR
        />
      </Page>
    );
  }

  if (!consultancyDetails?.landing.consultancyId) {
    return (
      <Page className={classes.root} title="Login">
        <Container
          disableGutters
          maxWidth="100%"
          style={{ padding: "0px", backgroundColor: "white" }}
        >
          <LoginHero
            trigger={trigger}
            mobile={mobile}
            consultancyDetails={consultancyDetails}
            consultancyColour={consultancyColour}
            assetUrl={assetUrls.login_image}
          />
          {/* <VideoBlock />
				<LandingCards mobile={mobile} consultancyDetails={false} />
				<CardCarousel consultancyDetails={false} />
				<ContactForm consultancyDetails={false} />
				<LandingFooter consultancyDetails={false} /> */}
        </Container>
      </Page>
    );
  }

  return (
    <>
      <Page className={classes.root} title="Login"></Page>
      <Container
        disableGutters
        maxWidth="100%"
        style={{ padding: "0px", backgroundColor: "white" }}
      >
        <LoginHero
          trigger={trigger}
          mobile={mobile}
          consultancyDetails={consultancyDetails}
          consultancyColour={consultancyColour}
          assetUrl={assetUrls.login_image}
        />

        {consultancyDetails.landing.display_video ? (
          <div style={{ position: "relative" }}>
            {!playing && !isSafari && (
              <PlayCircleFilledWhiteIcon
                onClick={() => setPlaying(true)}
                style={{
                  position: "absolute",
                  left: `50%`,
                  top: `50%`,
                  width: "50px",
                  height: "50px",
                  color: "white",
                  zIndex: "1000",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
            <VideoBlock
              play={{ playing, setPlaying }}
              assetUrl={assetUrls.video}
            />
          </div>
        ) : null}
        {consultancyDetails.landing.display_cards ? (
          <LandingCards
            mobile={mobile}
            consultancyDetails={consultancyDetails}
            consultancyColour={consultancyColour}
            assetUrls={assetUrls}
          />
        ) : null}
        {consultancyDetails.landing.display_slides ? (
          <CardCarousel
            consultancyDetails={consultancyDetails}
            consultancyColour={consultancyColour}
            assetUrls={assetUrls}
          />
        ) : null}
        {consultancyDetails.landing.display_contact ? (
          <ContactForm
            consultancyDetails={consultancyDetails}
            consultancyColour={consultancyColour}
          />
        ) : null}
        {consultancyDetails.landing.display_footer ? (
          <LandingFooter consultancyDetails={consultancyDetails} />
        ) : null}
      </Container>
    </>
  );
}

export default Home;
