import * as actionTypes from "src/actions";
import * as CookieActions from "../actions/cookieActions";

const initialState = {
  loggedIn: CookieActions.getCookie("accessToken") ? true : false,
  userId: CookieActions.getCookie("userId"),
  roleType: CookieActions.getCookie("role") || null,
  consultancyId: Number(CookieActions.getCookie("consultancyId")) || null,
  //Storing the url in cookies doesn't work as it converts it to a string and the url becomes invalid
  //Storing in localStorage works nicely
  consultancyLogo: localStorage.getItem("consultancyLogo") || null,
  consultancySecondaryLogo: localStorage.getItem("consultancySecondaryLogo") || null,
  profilePicture: localStorage.getItem("profilePicture") || null,
  name: CookieActions.getCookie("name") || null,
  language: CookieActions.getCookie("language") || null,
  consultancyName: CookieActions.getCookie("consultancyName") || null,
  breadcrumbs: [],
  consultancySubscriptionPlanId: Number(CookieActions.getCookie("consultancySubscriptionPlanId"))
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state,
        loggedIn: true,
        userId: action.userId,
        profilePicture: action.profilePicture,
        roleType: action.role,
        consultancyId: action.consultancyId,
        consultancyLogo: action.consultancyLogo,
        consultancySecondaryLogo: action.consultancySecondaryLogo,
        name: action.name,
        consultancyName: action.consultancyName,
        language: action.language,
        consultancySubscriptionPlanId: action.consultancySubscriptionPlanId
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        loggedIn: false,
        userId: null,
        roleType: null,
        consultancyId: null,
        consultancyLogo: null,
        consultancySecondaryLogo: null,
        name: null,
        profilePicture: null,
        consultancySubscriptionPlanId: null
      };
    }

    case actionTypes.LANGUAGE_CHANGE: {
      return {
        ...state,
        language: action.language,
      };
    }

    case actionTypes.TOGGLE_CONTACT_VIEW: {
      return { ...state, contactView: !state.contactView };
    }

    case actionTypes.UPDATE_CONSULTANCY_LOGO: {
      return {
        ...state,
        consultancyLogo: action.consultancyLogo,
      };
    }

    case actionTypes.UPDATE_CONSULTANCY_SECONDARY_LOGO: {
      return {
        ...state,
        consultancySecondaryLogo: action.consultancySecondaryLogo,
      };
    }

    case actionTypes.UPDATE_BREADCRUMBS: {
      return {
        ...state,
        breadcrumbs: action.breadcrumbs,
      };
    }

    case actionTypes.UPDATE_PROFILE_PICTURE: {
      localStorage.setItem("profilePicture", action.profilePicture);
      return {
        ...state,
        profilePicture: action.profilePicture,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
