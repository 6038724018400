import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { makeStyles } from '@material-ui/styles';

const styles = {
  wrapper: {
    height: '420px',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  questionWrapper: {
    width: '45%'
  },
  videoWrapper: {
    height: '100%',
    width: '45%'
  },
  video: {
    maxHeight: '100%',
    maxWidth: '100%'
  }
};

const useStyles = makeStyles((theme) => ({
  wrapper: styles.wrapper,
  wrapperMobile: {
    ...styles.wrapper,
    height: 'auto',
    flexDirection: 'column'
  },
  questionWrapper: styles.questionWrapper,
  questionWrapperMobile: {
    ...styles.questionWrapper,
    height: '280px',
    width: '100%',
    textAlign: 'center',
    marginBottom: '40px'
  },
  list: {
    maxHeight: '100%',
    overflow: 'scroll'
  },
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  listItemSelected: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  icon: {
    width: '24px',
    height: '24px',
    color: theme.palette.primary.main
  },
  videoWrapper: styles.videoWrapper,
  videoWrapperMobile: {
    ...styles.videoWrapper,
    maxHeight: '320px',
    height: 'auto',
    width: '100%'
  },
  box: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  instructionsHeader: {
    fontWeight: 'bold',
    marginBottom: '12px'
  },
  video: styles.video,
  videoMobile: {
    ...styles.video,
    maxHeight: '320px'
  }
}));

const VideoAssessmentReviewQuestionsAndAnswers = ({ questionsAndAnswers }) => {

  const classes = useStyles();
  const [t] = useTranslation(['videoAssessment']);
  const isMobile = useMediaQuery('(max-width:767px)');

  const [selectedIndex, setSelectedIndex] = useState(0);

  const listItemClickHandler = (index) => {
    setSelectedIndex(index);
  };

  return (
    <>
      <Grid className={ isMobile ? classes.wrapperMobile : classes.wrapper } >
        <Grid className={ isMobile ? classes.questionWrapperMobile : classes.questionWrapper } >
          <List className={ classes.list } >
            {
              questionsAndAnswers.map((qna, i) => (
                <>
                  <ListItem
                    className={ `${classes.listItem} ${selectedIndex === i ? classes.listItemSelected : ""}` }
                    onClick={ () => { listItemClickHandler(i) } } >
                    <ListItemIcon>
                      <HelpIcon className={ classes.icon } />
                    </ListItemIcon>

                    <ListItemText
                      primary={ qna.question.text }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="primary" >
                            { t("videoAssessment:videoAssessmentReviewQuestionsAndAnswersQuestionInfo", { time: qna.question.timeLimit }) }
                          </Typography>
                        </>
                      } />
                  </ListItem>

                  {
                    i + 1 !== questionsAndAnswers.length && (
                      <>
                        <Divider variant="inset" component="li" />
                      </>
                    )
                  }
                </>
              ))
            }
          </List>
        </Grid>

        <Grid className={ isMobile ? classes.videoWrapperMobile : classes.videoWrapper } >
          <Box className={ classes.box } >
            <video src={ questionsAndAnswers[selectedIndex].video.value } className={ isMobile ? classes.videoMobile : classes.video } controls />
          </Box>
        </Grid>
      </Grid>
    </>
  );

};

export default VideoAssessmentReviewQuestionsAndAnswers;
