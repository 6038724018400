import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
	footerContainer: {
		width: "100%",
		padding: "4rem 1rem",
		backgroundColor: "#EAEDED",
	},
	footerText: {
		textAlign: "center",
		[theme.breakpoints.down("lg")]: {
			"& h3": {
				fontWeight: "normal",
				fontSize: "14px",
				marginBottom: "12px",
			},
			"& p": {
				fontSize: "12px",
				marginBottom: "6px",
			},
		},
		[theme.breakpoints.up("lg")]: {
			"& h3": {
				fontWeight: "normal",
			},
		},
	},
}));

const LandingFooter = ({ consultancyDetails, languageKey }) => {
	const classes = useStyles();
	const { locale } = consultancyDetails;
	const { currentLanguage } = useSelector((state) => state.session);
	const footerContent =
		consultancyDetails.landing?.footer[0][currentLanguage] ||
		consultancyDetails.landing?.footer[0][locale] ||
		consultancyDetails.landing?.footer[0][languageKey];

	return (
		<Box className={classes.footerContainer}>
			<div className={classes.footerText}>
				<div>{parse(footerContent || "")}</div>
			</div>
		</Box>
	);
};

export default LandingFooter;
