import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import parse from 'html-react-parser';

import { Grid, Typography, Avatar, Button, useMediaQuery } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import { makeStyles } from '@material-ui/styles';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { retrieveConsultancyDetails } from './../../../actions';
import { SET_CANDIDATE_CAMPAIGN } from './../../../actions/candidateCampaignActions';

import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';
import { ContentWrapper } from './../../../components/LayoutComponents/ContentWrapper';
import { PageTitle } from './../../../components/LayoutComponents/PageTitle';

import CandidateThumbnail from './../TalentAlertContactCandidateProfile/CandidateThumbnail';
import ContactRecruiterModal from './../../../components/ContactRecruiterModal';
import Overview from './../TalentAlertContactCandidateProfile/Overview';
import KeyFacts from './../TalentAlertContactCandidateProfile/KeyFacts';
import SignInModal from './SignInModal';
import ContactCandidateKCQ from './../../ContactCandidateProfile/ContactCandidateKCQ';

const styles = {
  playButton: {
    color: '#ffffff',
    width: '100%'
  },
  videoPreview: {
    width: 'auto',
    height: '360px'
  }
}

const useStyles = makeStyles((theme) => ({
  headerImg: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '40vh',
    // boxShadow: 'inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    maxWidth: '270px'
  },
  details: {
    margin: '20px',
    marginTop: '-50px'
  },
  detailsMobile: {
    marginTop: '-20px'
  },
  avatar: {
    width: 'auto',
    height: '200px',
    '&:hover': {
      cursor: 'pointer'
    },
    marginBottom: '10px'
  },
  paper: {
    width: '100%',
    backgroundColor: '#ffffff',
    boxShadow: 'rgba(0, 0, 0, 0.30) 0px 5px 15px',
    marginBottom: '30px',
    padding: '20px',
    borderRadius: '3px'
  },
  paperSticky: {
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100vw',
    marginBottom: '0',
    zIndex: '999'
  },
  paperImgBackground: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden'
  },
  imgBackground: {
    position: 'absolute',
    backgroundSize: 'cover',
    top: '0px',
    left: '0px',
    bottom: '0px',
    right: '0px',
    opacity: '0.5',
    filter: 'blur(10px)'
  },
  videoPreview: styles.videoPreview,
  videoPreviewMobile: {
    ...styles.videoPreview,
    height: '220px'
  },
  button: {
    margin: 'auto',
    display: 'block',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginTop: '10px',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8'
    },
    maxHeight: '40px'
  },
  intro: {
    display: 'flex',
    flexDirection: 'row'
  },
  name: {
    fontSize: '2rem'
  },
  nameAndJob: {
    textAlign: 'left',
    margin: 'auto 0px auto 30px',
    display: 'inline'
  },
  headerSmall: {
    fontSize: '1.3rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    width: '40%',
    display: 'inline'
  },
  cta: {
    fontSize: '1.3rem',
    textAlign: 'center'
  },
  candidates: {
    display: 'flex',
    justifyContent: 'center'
  },
  playItems: {
    left: '0%',
    position: 'absolute',
    right: '0%',
    top: '-1%',
    bottom: '0%',
    margin: 'auto',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    '&:hover': {
      opacity: '0.8',
      cursor: 'pointer'
    },
    color: '#ffffff',
    opacity: '1',
    textAlign: 'center'
  },
  playButton: styles.playButton,
  playButtonMobile: {
    ...styles.playButton,
    hight: '220px'
  },
  wrapper: {
    display: 'table',
    width: 'auto',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  fakeDocument: {
    backgroundImage: 'url(https://res.cloudinary.com/dhiwlvpg7/image/upload/v1647007626/CV_opzjwd.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      opacity: '0.8',
      cursor: 'pointer'
    }
  },
  fakeDocSeparator: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
    margin: '12px 0'
  },
  fakeDocButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8'
    },
    maxHeight: '40px',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  truncate: {
    zIndex: '9',
    background: 'linear-gradient(rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)',
    width: '100%',
    height: '150px',
    position: 'absolute',
    top: '0',
    left: '0',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  tWrapper: {
    width: '100%',
    position: 'relative',
    height: '150px'
  },
  box: {
    width: '100%',
    height: '150px',
    position: 'absolute',
    top: '0',
    left: '0',
    overflowY: 'hidden',
    textOverflow: 'ellipsis'
  },
  doc: {
    padding: '10px'
  },
  hr: {
    width: '60%',
    margin: '10px 0'
  },
  locationOn: {
    display: 'inline'
  }
}));

const UnAuthTalentAlertContactCandidateProfile = ({ candidateId, campaignId, loggedIn, isPreview, cancelPreview }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation(['newTranslations', 'common', 'countries']);
  const isMobile = useMediaQuery('(max-width:767px)');

  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [shortExecutiveSummary, setShortExecutiveSummary] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [header, setHeader] = useState();
  const [campaignLogo, setCampaignLogo] = useState('');
  const [candidateAvatar, setCandidateAvatar] = useState('');
  const [campaignTitle, setCampaignTitle] = useState('');
  const [consultancyId, setConsultancyId] = useState();

  const { consultancyDetails } = useSelector(state => state.consultancy);
  const candidateCampaign = useSelector((state) => state.candidateCampaign.candidateProfile);

  useEffect(() => {
    const fetchDetails = () => {
      retrieveConsultancyDetails(dispatch, { host: window.location.hostname, route: "" });
    };
    if (!consultancyDetails) {
      fetchDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (loggedIn) {
      const pushOptions = {
        pathname: `/dashboard/talent-alerts/${campaignId}/candidates/${candidateId}`
      };

      if (location.state && location.state.previousPath) {
        pushOptions.state = {
          previousPath: location.state.previousPath
        };
      }

      history.push(pushOptions);
      return;
    }

    setIsLoading(true);

    let scopedConsultancyId;
    talentAlertApi.getConsultancyId(window.location.hostname)
      .then((response) => {
        setConsultancyId(response.data);
        scopedConsultancyId = response.data;
        return talentAlertApi.getHiddenTalentAlertCandidateProfile(scopedConsultancyId, candidateId, campaignId, isPreview);
      })
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: { candidateProfile: response.data }
        });

        return talentAlertApi.getContactHiddenTalentAlertById(campaignId, scopedConsultancyId);
      })
      .then((response) => {
        setCandidates(response.data.campaignCandidates);
        setHeader(response.data.headerImg);
        setCampaignLogo(response.data.campaignLogoImg);
        setIsLoading(false);
      })
      .catch(() => {
        history.push('/');
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId]);

  useEffect(() => {
    if (candidateCampaign?.candidateProfilePicture) {
      setCandidateAvatar(candidateCampaign.candidateProfilePicture);
    }
  }, [candidateCampaign]);

  const toggleSignInModal = (doOpenContactModal = false) => {
    if (isPreview) {
      return;
    }

    setSignInModalOpen(prevState => !prevState);

    if (doOpenContactModal) {
      toggleContactModal();
    }
  };

  const toggleContactModal = () => { 
    if (isPreview) {
      return;
    }

    setContactModalOpen(prevState => !prevState);
  };

  const goToShortlist = () => {
    history.push(`/talent-alert/${campaignId}/`); 
  };

  const cancelPreviewHandler = () => {
    // Return candidate state for recruiters
    talentAlertApi.getCandidateProfile(consultancyId, candidateId, campaignId, isPreview)
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: { candidateProfile: response.data }
        });

        cancelPreview();
      });
  };

  if (candidateCampaign?.campaignTitle && !campaignTitle) {
    setCampaignTitle(candidateCampaign.campaignTitle);
  }

  const getHeaderLogoSrc = () => {
    if (!campaignLogo) {
      return;
    }

    return campaignLogo;
  };

  const getCandidateVideo = () => {
    if (candidateCampaign?.candidateVideoGif) {
      return (
        <>
          <img src={ candidateCampaign.candidateVideoGif } alt="" className={ isMobile ? classes.videoPreviewMobile : classes.videoPreview } />
        </>
      );
    }

    return (
      <>
        <div className={ classes.playItems } >
          <img src="https://res.cloudinary.com/dhiwlvpg7/image/upload/v1649248807/home/000_-_237_ht3qki.png" alt="" className={ isMobile ? classes.playButtonMobile : classes.playButton } />
        </div>

        <img src={ candidateAvatar } alt="" className={ isMobile ? classes.videoPreviewMobile : classes.videoPreview } />
      </>
    );
  };

  const shortExecSummary = () => (
    <>
      <div class={classes.tWrapper}>
        <div className={classes.box}>
          <Typography>
            { parse(candidateCampaign?.candidateExecutiveSummary || '') }
          </Typography>
        </div>

        <div className={classes.truncate} onClick={() => { setShortExecutiveSummary(false) }}></div>
      </div>

      <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => { setShortExecutiveSummary(false) }}>
        {t("newTranslations:viewMore")}
      </div>
    </>
  );

  const longExecSummary = () => (
    <>
      <Typography>
        { parse(candidateCampaign?.candidateExecutiveSummary || '') }
      </Typography>

      <div style={{ textAlign: "center", cursor: "pointer" }} onClick={() => { setShortExecutiveSummary(true) }}>
        {t("newTranslations:viewMore")}
      </div>
    </>
  );

  return (
    <>
      <Helmet>
        <title>{`${t("newTranslations:talentAlert")} - ${candidateCampaign?.candidateJobTitle}`}</title>
        <meta property="og:image" content={candidateAvatar} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="2292" />
      </Helmet>

      <ContentWrapper loading={isLoading} removeGutters>
        {
          isPreview && (
            <PageTitle
              pageTitle={ `${campaignTitle} - ${t("newTranslations:recruiterPreviewTalentAlertAsContactPreviewHeaderAdditionalLabel")}` }
              btnLabel={ t("newTranslations:recruiterPreviewTalentAlertAsContactBtnCancelPreview") }
              btnFunc={ cancelPreviewHandler }
              btnVariant="outlined" />
          )
        }

        <Grid
          container
          className={classes.root}>
          <Grid
            item
            className={classes.headerImg}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundImage: `url(${header})` }} >
            <img src={ getHeaderLogoSrc() } alt="" className={classes.logo} />
          </Grid>
          
          <Grid container xs={12} justify="center">
            <Grid item xs={11} md={7} className={classes.details}>
              <Grid container className={`${classes.paper} ${classes.intro}`}>
                <Grid item xs={12} md={3}>
                  <Avatar
                    src={candidateAvatar ? candidateAvatar : 'https://res.cloudinary.com/dhiwlvpg7/image/upload/v1656671143/placeholder_ukjx7i.png'}
                    variant="rounded"
                    className={classes.avatar}
                    onClick={ toggleContactModal } />
                </Grid>
                
                <Grid container item xs={12} md={9}>
                  <div className={classes.nameAndJob} onClick={ toggleContactModal }>
                  {
                    candidateCampaign?.referenceNumber && (
                      <>
                        <Typography className={ classes.headerSmall }>
                          ID: { candidateCampaign.referenceNumber }
                        </Typography>
                        <hr className={ classes.hr } />
                      </>
                    )
                  }

                    <Typography className={classes.name}>
                      { candidateCampaign?.candidateJobTitle }
                    </Typography>

                    <hr className={classes.hr} />

                    <LocationOn className={classes.locationOn} />

                    <Typography className={classes.headerSmall}>
                      {
                        candidateCampaign?.city ?
                        `${candidateCampaign.city}, ${t(`countries:${candidateCampaign?.country?.name}`)}` :
                        t(`countries:${candidateCampaign?.country?.name}`)
                      }
                    </Typography>
                  </div>
                </Grid>
              </Grid>
              
              <Grid
                item
                className={ `${classes.paper} ${classes.paperImgBackground}` } >
                <div
                  className={ classes.imgBackground }
                  style={{ backgroundImage: `url(${ candidateCampaign?.candidateVideoGif ? candidateCampaign.candidateVideoGif : candidateAvatar })` }} ></div>

                <div className={classes.wrapper} onClick={ toggleContactModal }>
                  { getCandidateVideo() }
                </div>
              </Grid>

              {
                candidateCampaign?.candidateExecutiveSummary && (
                  <>
                    <Grid item className={classes.paper}>
                      <BlockHeader header={t("common:executiveSummary")} />
      
                      { shortExecutiveSummary ? shortExecSummary() : longExecSummary() }
                    </Grid>
                  </>
                )
              }

              {
                candidateCampaign?.candidateKeyFacts && Object.keys(candidateCampaign?.candidateKeyFacts).length && (
                  <>
                    <Grid item className={classes.paper}>
                      <BlockHeader header={t("newTranslations:keyFacts")} />
      
                      <KeyFacts
                        keyFacts={candidateCampaign?.candidateKeyFacts}
                        loggedIn={loggedIn} />
                    </Grid>
                  </>
                )
              }

              {
                candidateCampaign?.candidateOverviews && candidateCampaign.candidateOverviews.length > 0 && (
                  <>
                    <Grid item className={classes.paper}>
                      <Overview
                        overviews={candidateCampaign?.candidateOverviews}
                        loggedIn={loggedIn} />
                    </Grid>
                  </>
                )
              }

              <ContactCandidateKCQ candidateCampaign={ candidateCampaign } areAnswersHidden={ true } expandCollapseClick={ toggleContactModal } />
            </Grid>
            
            <Grid item xs={11} md={2} className={`${classes.details} ${isMobile ? classes.detailsMobile : ""}`}>
              <Grid item className={ `${classes.paper} ${isMobile ? classes.paperSticky : ""}` }>
                <Typography className={classes.cta}>
                  { t("newTranslations:interestedInThisCandidate") }
                </Typography>
                
                <Button className={classes.button} onClick={() => toggleSignInModal()}>{ t("newTranslations:signInToViewMore") }</Button>
                
                <Button className={classes.button} onClick={toggleContactModal}>{ t("newTranslations:taLoginRequestAccessToCampaignBtn") }</Button>
              </Grid>
              
              {
                candidateCampaign?.campaignRequiredDocuments.length > 0 && (
                  <>
                    <Grid item className={classes.paper}>
                      <BlockHeader header={t("newTranslations:documents")} />
                      {
                        candidateCampaign.campaignRequiredDocuments.map((crd, i) => (
                          <>
                            <div className={classes.fakeDocument} onClick={ toggleContactModal }>
                              <Button className={classes.fakeDocButton}>
                                { `${t("common:view")} ${crd}` }
                              </Button>
                            </div>
                          
                            {
                              i !== candidateCampaign.campaignRequiredDocuments.length - 1 && (
                                <>
                                  <div className={ classes.fakeDocSeparator }></div>
                                </>
                              )
                            }
                          </>
                        ))
                      }
                    </Grid>
                  </>
                )
              }
              
              {
                candidates.length > 1 && (
                  <Grid item container className={`${classes.paper} ${classes.candidates}`}>
                    <Grid item>
                      <BlockHeader header={t("newTranslations:moreTalent")} />
                    </Grid>
                    
                    {
                      candidates
                        .filter(candidate => candidate.id !== candidateId)
                        .map((candidate) => (
                          <CandidateThumbnail
                            key={candidate.id}
                            image={candidate.avatar}
                            campaignId={campaignId}
                            id={candidate.id}
                            jobTitle={candidate.jobTitle} />
                        )
                      )
                    }

                    <Button className={classes.button} onClick={goToShortlist}>
                      {t("newTranslations:viewAllCandidates")}
                    </Button>
                  </Grid>
                )
              }

            </Grid>
          </Grid>

          <SignInModal
            open={signInModalOpen}
            handleClose={toggleSignInModal}
            campaignId={campaignId}
            candidateId={candidateId} />
          
          <ContactRecruiterModal
            open={contactModalOpen}
            handleClose={toggleContactModal}
            candidate={candidateCampaign}
            campaignId={campaignId}
            isTalentAlertCampaign={true}
            loggedIn={loggedIn} />
        </Grid>
      </ContentWrapper >
    </>
  );

};

export default UnAuthTalentAlertContactCandidateProfile;
