import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  colors,
} from '@material-ui/core';
import validate from 'validate.js';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import EmployerSelect from 'src/components/EmployerSelect';
import CountrySelect from 'src/components/CountrySelect';
import * as EmployerApi from 'src/api/employerApi';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: '0px 10px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%',
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${10}%)`,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  modalOverflow: {
    overflowY: 'scroll',
  },
  formControl: {
    width: '100%',
  },
}));

const existingEmployerSchema = {
  employer: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const newEmployerSchema = {
  employerName: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  country: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

function SelectOrCreateEmployer({ onSubmit }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['snackbar', 'common', 'campaigns']);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      employerAssignment: 'PleaseSelect',
    },
    touched: {},
    errors: {},
  });

  const [employers, setEmployers] = useState([]);
  const consultancyId = useSelector((state) => state.session.consultancyId);

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleStepChange = (event) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        employerAssignment: event.target.value,
      },
    }));
  };
  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };
  const handleEmployerChange = (event, value) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        employer: value,
      },
      touched: {
        ...prevFormState.touched,
        employer: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let schema;
    switch (formState.values.employerAssignment) {
      case 'ExistingEmployer':
        schema = existingEmployerSchema;
        break;
      case 'NewEmployer':
        schema = newEmployerSchema;
        break;
      default:
    }
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      employers?.includes(
        formState?.values?.employerName &&
          formState?.values?.employerName?.toLowerCase().trim()
      )
    ) {
      enqueueSnackbar(`${t('snackbar:employerAlreadyExists')}`, {
        variant: 'error',
      });
    } else {
      onSubmit(formState.values);
    }
  };

  const getEmployers = function(consultancyId) {
    return EmployerApi.getEmployers(consultancyId)
      .then((response) => {
        const employerNames = response.data.map((employer) =>
          employer.employerName.toLowerCase()
        );
        setEmployers(employerNames);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getEmployers(consultancyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit} name='assignEmployerForm' noValidate>
      <div className={classes.fields}>
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel
            required
            id='employerSelectionLabel'
            style={{ backgroundColor: 'white' }}
          >
            {t('campaigns:newOrExistingEmployer')}
          </InputLabel>
          <Select
            labelId='employerSelectionLabel'
            value={formState.values.employerAssignment}
            onChange={handleStepChange}
          >
            <MenuItem value={'PleaseSelect'}>
              {`${t('campaigns:pleaseSelect')} *`}
            </MenuItem>
            <MenuItem value={'ExistingEmployer'}>
              {t('campaigns:employerSelectionExisting')}
            </MenuItem>
            <MenuItem value={'NewEmployer'}>
              {t('campaigns:employerSelectionNew')}
            </MenuItem>
          </Select>
        </FormControl>
        {formState.values.employerAssignment === 'ExistingEmployer' && (
          <EmployerSelect
            disabled={
              formState.values.employerAssignment !== 'ExistingEmployer'
            }
            handleChange={handleEmployerChange}
            value={formState.values.employer}
          />
        )}
        {formState.values.employerAssignment === 'NewEmployer' && (
          <>
            <TextField
              fullWidth
              label={t('common:employerName')}
              name='employerName'
              variant='outlined'
              required
              onChange={handleChange}
              error={hasError('employerName')}
              value={formState.values.employerName || ''}
              helperText={
                hasError('employerName')
                  ? t(`validation:${formState.errors.employerName[0]}`)
                  : null
              }
              disabled={formState.values.employerAssignment !== 'NewEmployer'}
            />

            <CountrySelect
              disabled={formState.values.employerAssignment !== 'NewEmployer'}
              handleCountryChange={handleCountryChange}
              value={formState.values.country}
            />
          </>
        )}
      </div>

      <div style={{ textAlign: 'right' }}>
        <Button
          className={classes.submitButton}
          color='primary'
          size='medium'
          type='submit'
          variant='contained'
          fullWidth
          disabled={
            !formState.isValid ||
            formState.values.employerAssignment === 'PleaseSelect'
          }
        >
          {t('common:next')}
        </Button>
      </div>
    </form>
  );
}
export default SelectOrCreateEmployer;
