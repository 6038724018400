import React, { memo } from 'react';
import FileTile from './FileTile';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'inline-block',
    cursor: 'grabbing',
    border: 'lightgrey thin solid'
  },
}));

const FileTilePreview = memo(({asset}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FileTile asset={asset} />
    </div>

  );
});

FileTilePreview.propTypes = {
  
};

export default FileTilePreview;