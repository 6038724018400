import React, { useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Grid, Button, Typography } from "@material-ui/core";
import DeleteUserGroupModal from "./DeleteUserGroupModal";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	userRow: {
		border: `1px solid ${theme.palette.primary.light}`,
		borderRadius: 2,
		margin: "12px",
	},
	userRowContainer: {
		alignItems: "center",
	},
	resendEmailButtonText: {
		color: "white",
	},
	linkPress: {
		cursor: "pointer",
	},
}));

function GroupListCard({ groupArray }) {
	const classes = useStyles();
	const history = useHistory();
	const [t] = useTranslation(["common", "users", "groups"]);
	const [open, setOpen] = useState(false);
	const [selectedGroup, setGroup] = useState({
		consultancyId: null,
		id: null,
		name: null,
	});

	const [groupsforRender, setGroupsforRender] = useState(groupArray);

	const setGroups = () => {
		const newArray = groupsforRender.filter(
			(group) => group.id !== selectedGroup.id
		);
		setGroupsforRender(newArray);
	};

	const handleOpen = (group) => {
		setGroup({
			consultancyId: group.consultancyId,
			name: group.name,
			id: group.id,
		});
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setGroup({
			consultancyId: null,
			name: null,
			id: null,
		});
	};

	const roleType = useSelector((state) => {
		return state.session.roleType;
	});

	const goToGroup = (event, consultancyId, groupId) => {
		event.preventDefault();
		if (roleType === "CoreAdmin" || roleType === "SystemAdmin") {
			history.push(
				`/dashboard/consultancies/${consultancyId}/groups/${groupId}`
			);
		} else {
			history.push(`/dashboard/groups/${groupId}`);
		}
	};

	return (
		<>
			{groupsforRender.map((group, key) => (
				<Grid key={key} item xs={12} className={classes.userRow}>
					<Grid container spacing={1} className={classes.userRowContainer}>
						<Grid item xs={3} sm={2} md={2} lg={2}>
							{/* change to cardInfo.avatarSrc when available */}
							<Avatar
								src={
									"https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg"
								}
							/>
						</Grid>
						<Grid item xs={9} sm={8} md={7} lg={7}>
							<Typography
								className={classes.linkPress}
								variant="h5"
								onClick={(event) => {
									goToGroup(event, group.consultancyId, group.id);
								}}
							>
								{group.name}
							</Typography>
						</Grid>
						<Grid item xs={12} sm={1} md={3} lg={3}>
							<Button
								fullWidth
								color="primary"
								size="small"
								type="submit"
								variant="contained"
								onClick={() => {
									handleOpen(group);
								}}
							>
								<Typography
									variant="body2"
									className={classes.resendEmailButtonText}
								>
									{t("common:delete")}
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<DeleteUserGroupModal
						onSuccess={() => setGroups()}
						open={open}
						handleClose={handleClose}
						userGroup={selectedGroup}
					/>
				</Grid>
			))}
		</>
	);
}

export default GroupListCard;
