import React, { useEffect, useState } from "react";
import { Box, Card, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as ConsultancyApi from "src/api/consultancyApi";
import { useSnackbar } from "notistack";
import CreateCandidateModal from "src/components/CreateCandidateModal";
import BatchCreateCandidateModal from "./BatchCreateCandidateModal";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { ProfileCard } from "src/components/ProfileCard";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import { SortMenu, useSort } from "src/components/Filter/Sort";
import { SelectMenu } from "src/components/Filter/Select";
import { Paginator, usePagination } from "src/components/Filter/Pagination";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";

const useStyles = makeStyles((theme) => ({
  filterCard: {
    marginBottom: theme.spacing(3),
    width: "100%",
    padding: 0,
  },
  filterCard_box: {
    padding: 0,
  },
}));

function Candidates({ ...props }) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common"]);
  const history = useHistory();
  const desktop = useMediaQuery("(min-width:960px)");
  const [open, setOpen] = useState(false);
  const [batchOpen, setBatchOpen] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState(t("common:ascending"));
  const [showItems, setShowItems] = useState(12);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleBatchOpen = () => {
    setBatchOpen(true);
  };
  const handleBatchClose = () => {
    setBatchOpen(false);
  };

  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const handleShowItems = (newValue) => {
    setShowItems(newValue);
  };

  const handleSortItems = (newValue) => {
    setSortOrder(newValue);
  };

  const handlePageChange = (newValue) => {
    jump(newValue.selected + 1);
  };

  const getCandidates = function(consultancyId) {
    return ConsultancyApi.getCandidates(consultancyId)
      .then((response) => {
        setCandidates(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCandidates(consultancyId);
    }
    return () => {
      mounted = false;
    };
  }, [consultancyId]);

  const onSuccess = (candidateDetails) => {
    return ConsultancyApi.createConsultancyCandidate(candidateDetails)
      .then((response) => {
        enqueueSnackbar(t("candidates:candidateCreateSuccess"), {
          variant: "success",
        });
        setCandidates((prevCandidates) => [...prevCandidates, response.data]);
      })
      .catch(() => {
        enqueueSnackbar(t("candidates:candidateCreateError"), {
          variant: "error",
        });
      })
      .then(() => {
        handleClose();
      });
  };

  const onBatchCreationSuccess = (newCandidates) => {
    let tempCandidatesArray = [...candidates];
    newCandidates.forEach((candidate) => {
      tempCandidatesArray.push({
        name: candidate.firstName + " " + candidate.lastName,
        email: candidate.email,
      });
    });
    setCandidates(tempCandidatesArray);
  };

  let filteredResults = useSearch(candidates, searchTerm, [
    "name",
    "email",
  ]).searchResults();

  const { currentData, jump, maxPage } = usePagination(
    filteredResults,
    showItems
  );

  let paginatedData = currentData();
  useSort(paginatedData, sortOrder, "name", true, "candidates").sortData();

  const goToCandidateDetails = (event, id) => {
    event.preventDefault();
    history.push(`/dashboard/candidates/${id}`);
  };

  return (
    <>
      <PageTitle
        pageTitle={t("candidates:candidates")}
        btnLabel={t("candidates:createCandidate")}
        btnFunc={handleOpen}
        btnLabel2={t("candidates:createBatchCandidate")}
        btnFunc2={handleBatchOpen}
      />
      <ContentWrapper loading={isLoading}>
        <Card className={classes.filterCard}>
          <Box
            p={2}
            minHeight={56}
            display="flex"
            alignItems="center"
            className={classes.filterCard_box}
          >
            <Searchbar onChange={handleInputChange} />
            {desktop ? (
              <>
                <Box flexGrow={1} />
                <SelectMenu value={showItems} onChange={handleShowItems} />
                <SortMenu value={sortOrder} onChange={handleSortItems} />
              </>
            ) : null}
          </Box>
        </Card>
        <>
          {paginatedData.map((candidate) => (
            <Grid item md={4} sm={6} xs={12}>
              <ProfileCard
                name={candidate.name}
                email={candidate.email}
                btnFunc={(event) => goToCandidateDetails(event, candidate.id)}
                btnLabel={t("common:viewMore")}
                profileImage={candidate.profilePicture}
              />
            </Grid>
          ))}
        </>
      </ContentWrapper>
      <Paginator pageCount={maxPage} onPageChange={handlePageChange} />

      <CreateCandidateModal
        onSuccess={onSuccess}
        consultancyId={consultancyId}
        open={open}
        handleClose={handleClose}
      />

      <BatchCreateCandidateModal
        onSuccess={onBatchCreationSuccess}
        consultancyId={consultancyId}
        open={batchOpen}
        handleClose={handleBatchClose}
      />
    </>
  );
}

export default Candidates;
