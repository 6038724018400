import {
  Tooltip,
  Typography,
  Grid,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment-timezone";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FwdBackButtons } from "src/components/LayoutComponents/FwdBackButtons";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  timeSlot: {
    display: "inline",
    whiteSpace: "nowrap",
    padding: "10px",
    margin: "0px 5px",
    border: `1px solid ${theme.palette.primary.main}`,
    cursor: "pointer",
  },
  availableDate: {
    overflowX: "scroll",
    width: "100%",
    padding: "10px 0px",
    marginTop: "10px",
  },
  timeSelectionTooltip: { marginLeft: "10px" },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function TimeSlotSelection({
  selectedDates,
  setCurrentTab,
  selectTimeSlot,
  selectedTimeSlots,
  selectedInterviewDuration,
  deselectTimeSlot,
  timeZone,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation(["campaigns", "common", "snackbar", "tooltips"]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  moment.tz.setDefault(timeZone);

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentTab(3);
  };

  const selectTime = (timeSlot) => {
    if (timeSlot.available) {
      selectTimeSlot(timeSlot.time);
    } else if (timeSlot.selected) {
      deselectTimeSlot(timeSlot.time);
    }
  };

  const getTimeSlot = (selectedTime, hours, minutes) => {
    let time = moment(selectedTime.start)
      .hours(hours)
      .minutes(minutes);
    let selected = false;
    let unavailableTimeSlots = selectedTimeSlots.map((timeSlot) => {
      if (time.isSame(moment(timeSlot))) {
        selected = true;
      }

      //-30 removes bug with extra 30 mins added to each side of interview slot on top of interview duration.
      let unavailablePreInterviewDuration = moment(timeSlot).subtract(
        selectedInterviewDuration - 30,
        "m"
      );
      let unavailablePostInterviewDuration = moment(timeSlot).add(
        selectedInterviewDuration - 30,
        "m"
      );
      return {
        from: unavailablePreInterviewDuration,
        to: unavailablePostInterviewDuration,
      };
    });
    let available = true;
    unavailableTimeSlots.forEach((unavailableTimeSlot) => {
      if (
        time.isSameOrAfter(unavailableTimeSlot.from) &&
        time.isSameOrBefore(unavailableTimeSlot.to)
      ) {
        available = false;
      }
    });
    return {
      time,
      available,
      selected,
    };
  };

  const configureTimeSlots = () => {
    let timeSlots = selectedDates.map((selectedDate) => {
      let date = moment(selectedDate.start);
      let times = [];
      for (let i = 8; i < 18; i++) {
        let hour = getTimeSlot(selectedDate, i, 0);
        times.push(hour);
        let hour30 = getTimeSlot(selectedDate, i, 30);
        times.push(hour30);
      }
      return {
        date,
        times,
      };
    });
    setAvailableTimeSlots(timeSlots);
  };

  const goBack = (event) => {
    event.preventDefault();
    setCurrentTab(1);
  };

  useEffect(() => {
    configureTimeSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates, selectedTimeSlots]);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        {availableTimeSlots?.map((availableTimeSlot) => (
          <Grid item xs={12}>
            <div style={{ display: "flex" }}>
              <Typography variant="h4">
                {availableTimeSlot.date.format("dddd, MMMM Do YYYY")}
              </Typography>
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                className={classes.timeSelectionTooltip}
                title={t("tooltips:selectStartTimeForInterviewSlot")}
              >
                <InfoIcon />
              </Tooltip>
            </div>
            <div className={classes.availableDate}>
              {availableTimeSlot.times?.map((timeSlot) => (
                <Typography
                  variant="h5"
                  className={classes.timeSlot}
                  style={
                    timeSlot.available
                      ? {}
                      : timeSlot.selected
                      ? {
                          backgroundColor: `${theme.palette.primary.main}`,
                          color: `${theme.palette.primary.contrastText}`,
                        }
                      : { backgroundColor: "#d8d8d8" }
                  }
                  onClick={(event) => selectTime(timeSlot)}
                >
                  {moment(timeSlot.time).format("LT")}
                </Typography>
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
      <FwdBackButtons
        fwd={selectedTimeSlots.length ? true : false}
        backFunc={goBack}
      />
    </form>
  );
}
export default TimeSlotSelection;
