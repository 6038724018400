import React from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

const useStyles = makeStyles((theme) => ({
	home: {
		fontSize: 16,
		marginBottom: -1, //? why 1px off at 1x zoom, but fine > 100% ?
		fontWeight: 100,
		padding: "5px 10px",
		textTransform: "none",
	},
	homeIcon: {
		fontSize: 18,
	},
}));

export const HomeButton = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const url = useSelector(
		(state) =>
			state.consultancy.consultancyDetails?.landing?.header[0]?.homepage
	);

	const [t] = useTranslation(["common"]);

	if (url) {
		return (
			<Button
				startIcon={<HomeOutlinedIcon />}
				className={classes.home}
				classes={{
					startIcon: classes.homeIcon,
				}}
				href={url}
				style={{
					color: theme.palette.landing?.overlay
						? theme.palette.getContrastText(theme.palette.landing.overlay)
						: theme.palette.getContrastText(theme.palette.primary.main),
				}}
			>
				{t("common:home")}
			</Button>
		);
	} else {
		return null;
	}
};
