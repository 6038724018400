import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card, CardContent, Grid, CircularProgress } from "@material-ui/core";
import QuillEditor from "src/components/QuillEditor";
import * as CandidateApi from "src/api/candidateApi";
import { useSnackbar } from "notistack";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
}));

const CampaignCandidateCareerHighlights = ({
  candidateId,
  campaignId,
  careerHighlights,
  handleCareerHighlightsChange,
}) => {
  const classes = useStyles();
  const [t] = useTranslation(["common", "snackbar", "tooltips"]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const onSuccess = (highlights) => {
    return CandidateApi.updateCandidateCareerHighlights(
      candidateId,
      consultancyId,
      campaignId,
      highlights
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:succesfullUpdatedCareerHighlights"), {
            variant: "success",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:failedToUpdateCareerHighlights"), {
          variant: "error",
        });
      });
  };

  const onSubmit = function(event) {
    event.preventDefault();
    setLoading(true);
    onSuccess(careerHighlights).then(() => {
      setLoading(false);
    });
  };

  return careerHighlights !== undefined ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <form onSubmit={onSubmit}>
          <Card>
            <BlockHeader
              headerTooltip={t("tooltips:writeCareerHighlightsForCandidate")}
              card
              padding
              header={t(`common:careerHighlights`)}
            />
            <CardContent>
              <QuillEditor
                value={careerHighlights}
                handleChange={handleCareerHighlightsChange}
                toolbarOptions={["link"]}
              />
              <CardButton save loading={loading} />
            </CardContent>
          </Card>
        </form>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress className={classes.progressCircle} size={25} />
  );
};

export default CampaignCandidateCareerHighlights;
