import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/styles';
import { Container, Box, LinearProgress, CardHeader, Card, CardContent } from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import ActivateAccountForm from './ActivateAccountForm';

import { retrieveConsultancyDetails } from './../../actions';

import Page from './../../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    width: '95%',
    marginTop: 50,
    marginBottom: 50,
    paddingLeft: 0,
    paddingRight: 0
  },
  loginContainer: {
    width: '100%',
    height: '100vh',
    backgroundSize: 'cover'
  },
  progress: {
    width: '50%'
  },
  header: {
    textAlign: 'center'
  }
}));

const TalentAlertActivateAccount = ({ activationToken }) => {

  const classes = useStyles();

  const dispatch = useDispatch();

  const { consultancyDetails } = useSelector(state => state.consultancy);

  const [t] = useTranslation(['common']);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!consultancyDetails) {
      setLoading(true);
      retrieveConsultancyDetails(dispatch, { host: window.location.hostname, route: 'login' })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <Page className={classes.root} title={t("common:activateAccount")}>
        <LinearProgress className={classes.progress} />
      </Page>
    )
  };

  if (!consultancyDetails) {
    return (
      <Page className={classes.root} title={t("common:activateAccount")}>
        <div className={classes.loginContainer}>
          <Container maxWidth="sm" className={classes.container}>
            <Box className={classes.header}>
              <Card>
                <CardHeader title={t("common:activateAccount")} />
                
                <CardContent>
                  <ActivateAccountForm activationToken={activationToken} />
                </CardContent>
              </Card>
            </Box>
          </Container>
        </div>
      </Page>
    );
  }

  return (
    <Page
      className={classes.root}
      title={t("common:activateAccount")}
    >
      <div
        className={classes.loginContainer}
      >
        <Container maxWidth="sm" className={classes.container}>
          <Box className={classes.header}>
            <Card>
              <CardHeader title={t("common:activateAccount")} />
              
              <CardContent>
                <ActivateAccountForm activationToken={activationToken} />
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    </Page>
  );

}

export default TalentAlertActivateAccount;
