import React, { useEffect, useState } from 'react';

import { useFormik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import { Button, FormControl, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import { ModalFormWrapper } from './../../../components/ModalFormWrapper';

import * as talentAlertApi from './../../../api/talentAlertApi';

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        width: '100%'
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: '100%'
    }
}));

const AddExistingCandidateModal = ({ onSuccess, open, handleClose, consultancyId, campaignId }) => {

    const classes = useStyles();
    const [t] = useTranslation(['candidates', 'common']);

    const [availableCandidates, setAvailableCandidates] = useState([]);

    const validationSchema = Yup.object().shape({
        selectedCandidates: Yup.array().required(t('newTranslations:selectCandidates'))
    });

    const formik = useFormik({
        initialValues: {
        selectedCandidates: []
        },
        validateOnMount: true,
        validationSchema,
        onSubmit: (values) => {
            onSuccess(values.selectedCandidates)
                .then(() => {
                    getCandidates();
                });
        }
    });

    useEffect(() => {
        getCandidates();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCandidates = () => {
        talentAlertApi.getAvailableCandidates(consultancyId, campaignId)
            .then((response) => {
                setAvailableCandidates(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const autocompleteGetOptionLabel = (option) => {
        return `${option.profile.firstName} ${option.profile.lastName} - ${option.user.email}`
    };

    const autocompleteOnChange = (event, value) => {
        formik.setValues({
            ...formik.values,
            selectedCandidates: [value]
        });
    };

    const autocompleteRenderInput = (params) => {
        return (
            <TextField
                fullWidth
                variant="outlined"
                label={ t("candidates:selectCandidates") }
                { ...params } />
        );
    };

    return (
        <ModalFormWrapper
            maxWidth="md"
            formTitle={ t("candidates:addCandidateToCampaign") }
            handleOpen={ open }
            handleClose={ handleClose }>
            <form onSubmit={ formik.handleSubmit }>
                <div>
                    <FormControl fullWidth variant="outlined">
                        <Autocomplete
                            id="talentAlertCandidateAutoComplete"
                            className={ classes.autocomplete }
                            options={ availableCandidates }
                            getOptionLabel={ autocompleteGetOptionLabel }
                            onChange={ autocompleteOnChange }
                            renderInput={ autocompleteRenderInput } />
                    </FormControl>
                </div>

                <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    className={ classes.submitButton }
                    disabled={ !formik.isValid }>
                    { t("candidates:addToCampaign") }
                </Button>
            </form>
        </ModalFormWrapper>
    );

};

export default AddExistingCandidateModal;
