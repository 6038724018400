import {
  Button,
  Card,
  CardContent,
  Grid,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_CANDIDATE_STATUS } from "src/actions/candidateCampaignActions";
import * as ContactApi from "src/api/contactApi";
import {
  FIRST_INTERVIEW,
  FURTHER_INTERVIEW,
  OFFER_MADE,
  REJECTED,
} from "src/constants/candidateStatusIds";
import ContactCandidateDWI from "./DWI";
import ContactCandidateExecutiveSummary from "./ContactCandidateExecutiveSummary";
import ContactCandidateCareerHighlights from "./ContactCandidateCareerHighlights";
import ViewVideoModal from "./ViewVideoModal";
import VideoAssessmentReview from './../../components/VideoAssessment/VideoAssessmentReview';
import RequiredCandidateDocuments from "src/views/Campaigns/CampaignDetail/CampaignDocuments/RequiredCandidateDocuments";
import ContactCandidateKCQ from "./ContactCandidateKCQ";
import CandidateMcQuaig from "src/views/CandidateProfile/CandidateMcQuaig";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import VideoBlock from "src/views/LandingPage/VideoBlock";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import ContactRecruiterModal from './../../components/ContactRecruiterModal';

import { TA_PRO } from './../../constants/subscriptionPlansIds';
import { DWI_ID, VIDEO_INTRODUCTION_ID } from './../../constants/moduleIds';

import * as videoAssessmentApi from './../../api/videoAssessmentApi';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
  questionsAndAnswers: {
    padding: "10px 0px",
  },
  playIcon: {
    fontSize: 80,
    marginBottom: 16,
    width: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  avatar: {
    height: "300px",
    width: "300px",
  },
  nameAndStatus: {
    margin: "10px 0px",
  },
}));

export default function () {
  const classes = useStyles();
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const [t] = useTranslation([
    "common",
    "validation",
    "snackbar",
    "candidates",
    "campaigns",
    "vacancies",
    "newTranslations",
  ]);
  const { campaignId, candidateId } = useSelector((state) => state.candidateCampaign.candidateProfile);
  const candidateCampaign = useSelector((state) => state.candidateCampaign);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { consultancyId, consultancySubscriptionPlanId } = useSelector((state) => state.session);
  const [modal, setModal] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [isUserPrimaryContact, setIsUserPrimaryContact] = useState(false);
  const [videoAssessment, setVideoAssessment] = useState();
  const [videoAssessmentInterview, setVideoAssessmentInterview] = useState();
  const [contactModalOpen, setContactModalOpen] = useState(false);

  useEffect(() => {
    ContactApi.isPrimaryContact(consultancyId, candidateCampaign.candidateProfile.campaignId)
      .then(response => {
        setIsUserPrimaryContact(response.data);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    videoAssessmentApi.getVideoAssessmentByCampaignId(campaignId)
      .then((response) => {
        setVideoAssessment(response.data);
        return videoAssessmentApi.getVideoAssessmentInterviewByCandidate(candidateId, response.data.id);
      })
      .then((response) => {
        setVideoAssessmentInterview(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [campaignId, candidateId]);

  const inviteCandidateToFirstInterview = (event) => {
    event.preventDefault();
    return ContactApi.changeCandidateStatus(
      candidateCampaign.candidateProfile.campaignId,
      consultancyId,
      candidateCampaign.candidateProfile.candidateId,
      FIRST_INTERVIEW
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:candidateFirstInterviewSuccess"), {
          variant: "success",
        });
        dispatch({ type: UPDATE_CANDIDATE_STATUS, status: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:candidateFirstInterviewFailure"), {
          variant: "error",
        });
      });
  };
  const inviteCandidateToFurtherInterview = (event) => {
    event.preventDefault();
    return ContactApi.changeCandidateStatus(
      candidateCampaign.candidateProfile.campaignId,
      consultancyId,
      candidateCampaign.candidateProfile.candidateId,
      FURTHER_INTERVIEW
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:candidateFurtherInterviewSuccess"), {
          variant: "success",
        });
        dispatch({ type: UPDATE_CANDIDATE_STATUS, status: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:candidateFurtherInterviewFailure"), {
          variant: "error",
        });
      });
  };
  const makeOfferToCandidate = (event) => {
    event.preventDefault();
    return ContactApi.changeCandidateStatus(
      candidateCampaign.candidateProfile.campaignId,
      consultancyId,
      candidateCampaign.candidateProfile.candidateId,
      OFFER_MADE
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:candidateOfferMadeSuccess"), {
          variant: "success",
        });
        dispatch({ type: UPDATE_CANDIDATE_STATUS, status: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:candidateOfferMadeFailure"), {
          variant: "error",
        });
      });
  };
  const rejectCandidate = (event) => {
    event.preventDefault();
    return ContactApi.changeCandidateStatus(
      candidateCampaign.candidateProfile.campaignId,
      consultancyId,
      candidateCampaign.candidateProfile.candidateId,
      REJECTED
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:rejectCandidateSuccess"), {
          variant: "success",
        });
        dispatch({ type: UPDATE_CANDIDATE_STATUS, status: response.data });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:rejectCandidateFailure"), {
          variant: "error",
        });
      });
  };

  const handleModalClose = () => setModal(false);
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
      (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  const toggleContactModal = () => {
    setContactModalOpen((prevState) => !prevState);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <Grid container spacing={3}>
          <Grid item xs={12}>

            <Grid container spacing={3}>
              <Grid item xs={12} style={{ textAlign: "-webkit-center" }}>
                <Avatar
                  className={classes.avatar}
                  src={
                    candidateCampaign.candidateProfile
                      .candidateProfilePicture ||
                    "https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg"
                  }
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={12}>
            <Card className={classes.gridRow}>
              <BlockHeader card padding defeatMarginBottom header={t("newTranslations:interestedInThisCandidate")}/>

              <CardContent>
                <Button color="primary" size="large" variant="contained" fullWidth onClick={toggleContactModal}>
                  {t("newTranslations:contactRecruiter")}
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {
            candidateCampaign.candidateProfile.campaignModuleIds.includes(VIDEO_INTRODUCTION_ID) &&
            candidateCampaign.candidateProfile.candidateProfileVideo.url && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <BlockHeader
                      card
                      header={t(`${campaignsPrefix}:uploadAVideo`)}
                    />
                    <div style={{ position: "relative" }}>
                      {!playing && !isSafari && (
                        <PlayCircleFilledWhiteIcon
                          onClick={() => setPlaying(true)}
                          style={{
                            position: "absolute",
                            left: `50%`,
                            top: `50%`,
                            width: "50px",
                            height: "50px",
                            color: "white",
                            zIndex: "1000",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )}
                      <VideoBlock
                        padding={"0rem"}
                        assetUrl={
                          candidateCampaign.candidateProfile.candidateProfileVideo
                            .url
                        }
                        size={12}
                        play={{ setPlaying, playing }}
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )
          }
          {
            videoAssessment && videoAssessmentInterview && videoAssessmentInterview.completedAt && (
              <Grid item xs={12}>
                <Card className={classes.gridRow}>
                  <CardContent>
                    <VideoAssessmentReview videoAssessmentInteview={ videoAssessmentInterview } />
                  </CardContent>
                </Card>
              </Grid>
            )
          }

          <Grid item xs={12}>
            <Card className={classes.gridRow}>
              <CardContent>
                <RequiredCandidateDocuments
                  campaignRequiredDocuments={
                    candidateCampaign.candidateProfile.candidateDocuments
                  }
                  candidateView={true}
                />
              </CardContent>
            </Card>
          </Grid>

          {
            isUserPrimaryContact && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    {candidateCampaign.candidateProfile.candidateStatus !==
                      FIRST_INTERVIEW &&
                      candidateCampaign.candidateProfile.candidateStatus !==
                      FURTHER_INTERVIEW &&
                      candidateCampaign.candidateProfile.candidateStatus !==
                      OFFER_MADE && (
                        <Button
                          color="primary"
                          size="large"
                          variant="outlined"
                          onClick={inviteCandidateToFirstInterview}
                          fullWidth
                          style={{ marginBottom: 8 }}
                        >
                          {t("candidates:inviteToFirstInterview")}
                        </Button>
                      )}
                    {candidateCampaign.candidateProfile.candidateStatus !==
                      FURTHER_INTERVIEW &&
                      candidateCampaign.candidateProfile.candidateStatus !==
                      OFFER_MADE && (
                        <Button
                          color="primary"
                          size="large"
                          variant="outlined"
                          fullWidth
                          onClick={inviteCandidateToFurtherInterview}
                          style={{ marginBottom: 8 }}
                        >
                          {t("candidates:inviteToFurtherInterview")}
                        </Button>
                      )}
                    {candidateCampaign.candidateProfile.candidateStatus !==
                      OFFER_MADE && (
                        <Button
                          color="primary"
                          size="large"
                          variant="outlined"
                          fullWidth
                          onClick={makeOfferToCandidate}
                          style={{ marginBottom: 8 }}
                        >
                          {t("candidates:makeOffer")}
                        </Button>
                      )}
                    <Button
                      color="primary"
                      size="large"
                      variant="outlined"
                      fullWidth
                      onClick={rejectCandidate}
                    >
                      {t("candidates:reject")}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            )
          }
        </Grid>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Grid container spacing={3}>
          {candidateCampaign.candidateProfile.candidateExecutiveSummary && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ContactCandidateExecutiveSummary
                    candidateExecutiveSummary={
                      candidateCampaign.candidateProfile
                        .candidateExecutiveSummary
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {candidateCampaign.candidateProfile.candidateCareerHighlights && (
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ContactCandidateCareerHighlights
                    candidateCareerHighlights={
                      candidateCampaign.candidateProfile
                        .candidateCareerHighlights
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          )}

          <ContactCandidateKCQ candidateCampaign={candidateCampaign} />

          {candidateCampaign.candidateProfile.campaignMcQuaig !==
            "disabled" && (
              <Grid item xs={12}>
                <CandidateMcQuaig />
              </Grid>
            )}

          {
            candidateCampaign.candidateProfile.teamSuitabilityScores.categories.length !== 0 &&
            candidateCampaign.candidateProfile.campaignModuleIds.includes(DWI_ID) && (
              <>
                <Grid item xs={12}>
                  <Card>
                    <BlockHeader
                      card
                      padding
                      header={t("campaigns:suitabilityScore")}
                      headerTooltip={t("tooltips:suitabilityScore")}
                    />
                    <CardContent>
                      <ContactCandidateDWI />
                    </CardContent>
                  </Card>
                </Grid>
              </>
            )
          }
        </Grid>
        <ViewVideoModal
          open={modal}
          video={candidateCampaign.candidateProfile.candidateProfileVideo}
          onClose={handleModalClose}
          campaignId={candidateCampaign.candidateProfile.campaignId}
          candidateStatus={candidateCampaign.candidateProfile.candidateStatus}
          candidateId={candidateCampaign.candidateProfile.candidateId}
        />

        <ContactRecruiterModal
          open={contactModalOpen}
          handleClose={toggleContactModal}
          candidate={candidateCampaign.candidateProfile}
          campaignId={campaignId}
          isTalentAlertCampaign={false}
          authenticated={true} />
      </Grid>
    </Grid>
  );
}
