export const localeOptions = [
  {
    id: 1,
    language: "English (UK)",
    languageKey: "en_gb",
  },
  {
    id: 7,
    language: "Čeština",
    languageKey: "cs",
  },
  {
    id: 2,
    language: "Deutsch",
    languageKey: "de",
  },
  {
    id: 6,
    language: "English (US)",
    languageKey: "en_us",
  },
  {
    id: 4,
    language: "Español",
    languageKey: "es",
  },
  {
    id: 3,
    language: "Français",
    languageKey: "fr",
  },
  {
    id: 5,
    language: "Nederlands",
    languageKey: "nl",
  },
  {
    id: 8,
    language: "Romȃneşte",
    languageKey: "ro",
  },
];
