import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import CreateCampaignForm from './CreateCampaignForm';
import SelectOrCreateEmployer from './SelectOrCreateEmployer';
import SelectOrCreateContact from './SelectOrCreateContact';
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

function CreateCampaignModal({ onSuccess, open, handleClose }) {
  const [contactExists, setContactExists] = useState(false);

  const [t] = useTranslation(["campaigns", "common", "vacancies"]);
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const [campaignDetails, setCampaignDetails] = useState({
    currentStep: 0,
  });

  const handleSetContactExists = (value) => {
    setContactExists(value);
  };

  const onStepOneSubmit = function(values) {
    setCampaignDetails((prevCampaignDetails) => ({
      ...prevCampaignDetails,
      currentStep: 1,
      campaign: {
        ...values,
      },
    }));
  };
  const onStepTwoSubmit = function(values) {
    setCampaignDetails((prevCampaignDetails) => ({
      ...prevCampaignDetails,
      currentStep: 2,
      employer: values.employer
        ? {
            ...values.employer,
            employerAssignment: values.employerAssignment,
          }
        : {
            ...values,
          },
    }));
  };

  const onStepThreeSubmit = function(values) {
    let campaignData = {
      ...campaignDetails,
      contact: values.contact
        ? {
            ...values.contact,
            contactAssignment: values.contactAssignment,
          }
        : {
            ...values,
          },
    };

    onSuccess(campaignData)
      .then(() => {
        setCampaignDetails({
          currentStep: 0,
        });
      })
      .catch((error) => {
        console.error({ error });
        if (error.response.data.error.code === "ERR_CONTACT_EXISTS") {
          setContactExists(true);
        }
      });
  };

  const handleModalClose = () => {
    setCampaignDetails({
      currentStep: 0,
    });
    handleClose();
  };

  const renderForm = () => {
    switch (campaignDetails.currentStep) {
      case 0:
      default:
        return (
          <CreateCampaignForm
            onSubmit={onStepOneSubmit}
            style={{ width: "100%" }}
          />
        );
      case 1:
        return <SelectOrCreateEmployer onSubmit={onStepTwoSubmit} />;
      case 2:
        return (
          <SelectOrCreateContact
            contactExists={contactExists}
            handleSetContactExists={handleSetContactExists}
            onSubmit={onStepThreeSubmit}
            employerDetails={campaignDetails.employer}
          />
        );
    }
  };

  return (
    <ModalFormWrapper
      formTitle={t(`${campaignsPrefix}:createCampaign`)}
      handleOpen={open}
      handleClose={handleModalClose}
      maxWidth={"sm"}
    >
      {renderForm()}
    </ModalFormWrapper>
  );
}
export default CreateCampaignModal;
