import React from 'react';
import UserCard from './UserCard';

function UserListCard({ listCardAction, userArray }) {
  return (
    <>
      {userArray?.map((user, key) => {
        const userIsActivated =
          user.activated || !user.hasOwnProperty('activated');
        return (
          <UserCard
            key={key}
            user={user}
            userIsActivated={userIsActivated}
            listCardAction={listCardAction}
          />
        );
      })}
    </>
  );
}

export default UserListCard;
