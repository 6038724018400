import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Button,
  colors,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import CountrySelect from "src/components/CountrySelect";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { useSnackbar } from "notistack";
import * as employerApi from "src/api/employerApi";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
}));

function CreateEmployerModal({ open, handleClose, consultancies }) {
  const classes = useStyles();

  const [t] = useTranslation(["campaigns", "common", "snackbar"]);
  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [employers, setEmployers] = useState([]);

  const schema = {
    employerName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 32,
      },
    },
    consultancyId: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = function(event) {
    event.preventDefault();
    if (
      formState.values.employerName &&
      employers.includes(formState.values.employerName.toLowerCase().trim())
    ) {
      enqueueSnackbar(
        `${t("snackbar:employerAlreadyExistsforThisConsultancy")}`,
        {
          variant: "error",
        }
      );
    } else {
      return SystemAdminApi.createEmployer({
        ...formState.values,
        countryId: formState.values.country.id,
      })
        .then((response) => {
          enqueueSnackbar(t("snackbar:createEmployerSuccess"), {
            variant: "success",
          });
        })
        .catch((error) => {
          enqueueSnackbar(t("snackbar:createEmployerFailure"), {
            variant: "error",
          });
        })
        .then(() => {
          setFormState({
            isValid: false,
            values: {},
            touched: {},
            errors: {},
          });
          handleClose();
        });
    }
  };

  const getEmployers = function(consultancyId) {
    return employerApi
      .getEmployers(consultancyId)
      .then((response) => {
        const employerNames = response.data.map((employer) =>
          employer.employerName.toLowerCase()
        );
        setEmployers(employerNames);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    if (formState.values.consultancyId) {
      getEmployers(formState.values.consultancyId);
    }
  }, [formState.values.consultancyId]);

  return (
    <ModalFormWrapper
      formTitle={t("common:createEmployer")}
      handleOpen={open}
      handleClose={handleClose}
    >
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <InputLabel
              id="consultancyLabel"
              style={{ backgroundColor: "white" }}
            >
              {t("campaigns:selectConsultancy")}
            </InputLabel>
            <Select
              labelId="consultancyLabel"
              name={"consultancyId"}
              value={formState.values.consultancyId}
              onChange={handleChange}
              renderValue={(selected) =>
                consultancies.find((consultancy) => consultancy.id === selected)
                  .name
              }
              input={<OutlinedInput />}
            >
              {consultancies?.map((consultancy) => (
                <MenuItem key={consultancy.id} value={consultancy.id}>
                  <Checkbox
                    checked={formState.values.consultancyId === consultancy.id}
                  ></Checkbox>
                  <ListItemText primary={consultancy.name}></ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label={t("common:employerName")}
            name="employerName"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("employerName")}
            value={formState.values.employerName || ""}
            helperText={
              hasError("employerName") ? formState.errors.employerName[0] : null
            }
          />
          <CountrySelect
            handleCountryChange={handleCountryChange}
            value={formState.values.country || null}
          />
        </div>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          {t("common:create")}
        </Button>
      </form>
    </ModalFormWrapper>
  );
}
export default CreateEmployerModal;
