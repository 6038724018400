import {
  Button,
  Checkbox,
  CircularProgress,
  colors,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import validate from "validate.js";
import * as CampaignApi from "src/api/campaignApi";
import { UPDATE_CAMPAIGN_SCHEDULER } from "src/actions";
import moment from "moment-timezone";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  cancelButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  fileField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  assetDropdown: {
    flex: 2,
    marginRight: 10,
  },
  fileDropzone: {
    flex: 1,
  },
  subheadingText: {
    width: "100%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  deleteProgress: {
    color: theme.palette.primary.main,
  },
  removeCandidate: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "right",
  },
  icon: {
    fontSize: 22,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
    fontSize: 22,
  },
}));

function EditModal({ event, onCancel, open, mode, timeZone }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(["campaigns", "common", "snackbar"]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const campaign = useSelector((state) => state.campaign);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaignContact = campaign.campaignContacts || [];
  const campaignCandidates = campaign.campaignCandidates || [];

  moment.tz.setDefault(timeZone);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...event,
    },
    touched: {},
    errors: {},
  });

  const selectedCandidateObject = campaignCandidates.filter(
    (candidate) => candidate.campaignProfileId === event?.candidate?.id
  );

  const schema = {
    title: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    selectedContactIds: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    start: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    end: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const handleChange = (event, value) => {
    event.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleCandidateChange = (event, value) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedCandidateId: value?.campaignProfileId,
      },
    }));
  };

  const handleDateChange = (date, type) => {
    let time = moment(date)
      .clone()
      .tz(timeZone)
      .toDate();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        [type]: time,
      },
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    //Submitting file so needs to be form data
    if (mode === "edit") {
      return CampaignApi.updateCalendarEntry(
        consultancyId,
        campaign.id,
        formState.values
      )
        .then((response) => {
          dispatch({
            type: UPDATE_CAMPAIGN_SCHEDULER,
            campaignEvents: response.data,
          });
          enqueueSnackbar(t("snackbar:calendarEntryUpdateSuccess"), {
            variant: "success",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(t("snackbar:calendarEntryUpdateFailure"), {
            variant: "error",
          });
        })
        .then(() => {
          onCancel();
        });
    } else if (mode === "create") {
      return CampaignApi.createSingleCalendarEntry(
        consultancyId,
        campaign.id,
        formState.values
      )
        .then((response) => {
          dispatch({
            type: UPDATE_CAMPAIGN_SCHEDULER,
            campaignEvents: response.data,
          });
          enqueueSnackbar(t("snackbar:calendarEntryCreateSuccess"), {
            variant: "success",
          });
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(t("snackbar:calendarEntryCreateFailure"), {
            variant: "error",
          });
        })
        .then(() => {
          onCancel();
        });
    }
  };

  const handleDelete = (event) => {
    setDeleting(true);
    return CampaignApi.cancelCalendarEntry(
      consultancyId,
      campaign.id,
      formState.values.id
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_SCHEDULER,
          campaignEvents: response.data,
        });
        enqueueSnackbar(t("snackbar:calendarEntryUpdateSuccess"), {
          variant: "success",
        });
        setDeleting(false);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:calendarEntryUpdateFailure"), {
          variant: "error",
        });
      })
      .then(() => {
        onCancel();
      });
  };

  const removeCandidate = () => {
    return CampaignApi.removeCandidateFromEntry(
      consultancyId,
      campaign.id,
      formState.values.id
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_SCHEDULER,
          campaignEvents: response.data,
        });
        enqueueSnackbar(t("snackbar:calendarEntryUpdateSuccess"), {
          variant: "success",
        });
        event.candidate = null;
        setFormState((prevValues) => ({
          ...prevValues,
          values: {
            ...prevValues.values,
            candidate: null,
            selectedCandidateId: null,
          },
        }));
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:calendarEntryUpdateFailure"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...event,
        selectedContactIds: event?.contacts.map((contact) => contact.id),
        selectedCandidateId: event?.candidate?.id,
      },
    }));
  }, [event]);

  return (
    <ModalFormWrapper
      formTitle={`${
        mode === "edit"
          ? `${t("common:update")} ${formState.values.title}`
          : `${t("campaigns:createEvent")}`
      }`}
      handleOpen={open}
      handleClose={onCancel}
      maxWidth="sm"
    >
      <form onSubmit={handleSubmit}>
        <div className={classes.fields}>
          <TextField
            fullWidth
            label={t("common:titleLabel")}
            name="title"
            onChange={handleChange}
            value={formState.values.title}
            variant="outlined"
            required
          />
          <TextField
            fullWidth
            label={t("common:moreInfoLabel")}
            name="description"
            multiline
            rows={10}
            onChange={handleChange}
            value={formState.values.description}
            variant="outlined"
          />
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
            required
          >
            <InputLabel id="contactLabel" style={{ backgroundColor: "white" }}>
              {t("campaigns:selectClients")}
            </InputLabel>
            <Select
              labelId="contactLabel"
              name={"selectedContactIds"}
              required
              multiple
              value={formState.values.selectedContactIds}
              renderValue={(selected) =>
                campaign.campaignContacts

                  .filter((contact) => selected.includes(contact.id))

                  .map(
                    (filteredCandidate) =>
                      filteredCandidate.firstName +
                      " " +
                      filteredCandidate.lastName
                  )

                  .join(", ")
              }
              onChange={handleChange}
              input={<OutlinedInput />}
            >
              {campaignContact.map((contact) => (
                <MenuItem key={contact.id} value={contact.id}>
                  <Checkbox
                    checked={formState.values.selectedContactIds?.includes(
                      contact.id
                    )}
                  />

                  <ListItemText
                    primary={contact.firstName + " " + contact.lastName}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <Autocomplete
              options={campaignCandidates?.sort((a, b) => {
                let nameA =
                  `${a?.lastName?.toUpperCase()} ${a?.firstName?.toUpperCase()}` ||
                  "";
                let nameB =
                  `${b?.lastName?.toUpperCase()} ${b?.firstName?.toUpperCase()}` ||
                  "";

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              value={selectedCandidateObject[0]}
              required
              onChange={handleCandidateChange}
              style={{ width: "100%" }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    label={t("campaigns:selectCandidate")}
                    variant="outlined"
                  />
                );
              }}
            />
          </FormControl>
          {event && event.candidate ? (
            <Grid container>
              <Grid item xs={0} md={9}></Grid>
              <Grid item xs={12} md={3}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  className={classes.removeCandidate}
                  onClick={removeCandidate}
                >
                  {t("campaigns:removeCandidate")}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          <DateTimePicker
            fullWidth
            inputVariant="outlined"
            label={t("common:startTime")}
            onChange={(date) => handleDateChange(date, "start")}
            name="start"
            time
            value={formState.values.start}
          />
          <DateTimePicker
            fullWidth
            inputVariant="outlined"
            label={t("common:endTime")}
            onChange={(date) => handleDateChange(date, "end")}
            name="end"
            value={formState.values.end}
          />
        </div>

        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSubmit}
          disabled={!formState.isValid || deleting}
        >
          {loading ? (
            <CircularProgress className={classes.progressCircle} size={25} />
          ) : (
            `${
              mode === "edit"
                ? `${t("common:update")}`
                : `${t("common:create")}`
            }`
          )}
        </Button>
        {mode !== "create" ? (
          <Button
            className={classes.cancelButton}
            size="large"
            variant="text"
            onClick={handleDelete}
            disabled={loading}
          >
            {deleting ? (
              <CircularProgress className={classes.deleteProgress} size={25} />
            ) : (
              `${t("campaigns:cancelInterview")}`
            )}
          </Button>
        ) : null}
      </form>
    </ModalFormWrapper>
  );
}
export default EditModal;
