import * as actionTypes from "../../../actions";

const initialState = {
    kcqCategories: [],
    questionSets: [],
};

const dwiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_KCQ_CATEGORIES: {
            return {
                ...state,
                kcqCategories: action.categories,
            }
        }
        case actionTypes.UPDATE_KCQ_CATEGORIES: {
            return {
                ...state,
                kcqCategories: [...action.kcqCategories]
            }
        }
        case actionTypes.SET_QUESTION_SETS: {
            return {
                ...state,
                questionSets: action.questionSets,
            }
        }
        case actionTypes.UPDATE_QUESTION_SETS: {
            return {
                ...state,
                questionSets: [...action.questionSets]
            }
        }
        case actionTypes.SET_PERSONAL_QUESTION_SETS: {
            return {
                ...state,
                personalQuestionSets: action.personalQuestionSets,
            }
        }
        case actionTypes.UPDATE_PERSONAL_QUESTION_SETS: {
            const personalQuestionSets = state.personalQuestionSets ?
                [...state.personalQuestionSets, ...action.personalQuestionSets] :
                [...action.personalQuestionSets];

            return {
                ...state,
                personalQuestionSets
            }
        }
        default: {
            return state;
        }
    }
}

export default dwiReducer;
