import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as assetApi from 'src/api/assetApi';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

function AssetAutocomplete({
  className,
  label,
  value,
  disabled,
  onChange,
  ...rest
}) {
  const classes = useStyles();
  const contexts = useSelector(state => state.mediaDrawer.contexts);
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetsLoading, setAssetsLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setAssetsLoading(true);
      Promise.all(
        contexts.map(contextObject => {
          switch (contextObject.context) {
            case 'Personal':
              return assetApi.getPersonalAssets();
            case 'Candidate':
            case 'Contact':
            case 'Consultant':
            case 'Campaign':
            default:
              console.error('Invalid Context Provided');
              return [];
          }
        })
      )
        .then(assetArrays => {
          let assetsWithContext = assetArrays
            .map((assetArray, index) => {
              return assetArray.map(asset => {
                return {
                  context: contexts[index].context,
                  ...asset,
                };
              });
            })
            .flat();

          setAssets(assetsWithContext);
        })
        .catch(error => {
          console.error(error);
        })
        .then(() => {
          setAssetsLoading(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [contexts]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setSelectedAsset(value && assets.find(asset => asset.id === value.id));
    }
    return () => {
      mounted = false;
    };
  }, [value, assets]);
  return (
    <Autocomplete
      {...rest}
      disabled={disabled}
      className={className ? className : classes.root}
      style={{ width: '100%' }}
      id="asset-select"
      loading={assetsLoading}
      options={assets}
      value={selectedAsset}
      onChange={onChange}
      groupBy={option => option.context}
      getOptionLabel={option => option.fileName}
      renderInput={params => (
        <TextField {...params} fullWidth label={label} variant="outlined" />
      )}
    />
  );
}

AssetAutocomplete.propTypes = {};

export default AssetAutocomplete;
