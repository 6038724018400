import {
  AppBar,
  Card,
  CardContent,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { TabPanel } from "src/components/TabPanel";
import EmailTemplate from "./EmailTemplate";

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    marginTop: theme.spacing(2),
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  subjectInput: {
    margin: "20px 0px",
  },
  scrollButtons: {
    color: "black",
  },
}));

const EmailSettings = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const [t] = useTranslation([
    "sysadmin",
    "common",
    "snackbar",
    "languages",
    "notifications",
    "vacancyNotifications",
  ]);
  const classes = useStyles();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const tabs = [
    { value: 1, label: "EN UK" },
    { value: 2, label: "DE" },
    { value: 3, label: "FR" },
    { value: 4, label: "ES" },
    { value: 5, label: "NL" },
    { value: 6, label: "EN US" },
    { value: 7, label: "CZ" },
    { value: 8, label: "RO" },
  ];

  const handleTabChange = (event, value, emailTemplateIndex) => {
    let tempEmailTemplates = [...emailTemplates];
    tempEmailTemplates[emailTemplateIndex].currentTab = value;

    setEmailTemplates((prevVales) => tempEmailTemplates);
  };

  const updateEmailTemplate = (event, emailTemplate) => {
    event.preventDefault();
    return SystemAdminApi.updateGlobalEmailTemplate(emailTemplate)
      .then((response) => {
        let tempEmailTemplates = emailTemplates;
        let emailTemplateIndex = emailTemplates.findIndex(
          (emailTemplate) => emailTemplate.id === response.data.emailTemplateId
        );
        let emailTemplateLocaleIndex = emailTemplates[
          emailTemplateIndex
        ].emailTemplateLocale.findIndex(
          (emailTemplateLocale) => emailTemplateLocale.id === emailTemplate.id
        );
        tempEmailTemplates[emailTemplateIndex].emailTemplateLocale[
          emailTemplateLocaleIndex
        ].contentHTML = response.data.contentHTML;
        tempEmailTemplates[emailTemplateIndex].emailTemplateLocale[
          emailTemplateLocaleIndex
        ].subjectHTML = response.data.subjectHTML;
        setEmailTemplates([...tempEmailTemplates]);
        enqueueSnackbar(t("snackbar:emailTemplateUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:emailTemplateUpdateFailure"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const getGlobalEmailSettings = () => {
    return SystemAdminApi.getGlobalEmailTemplates()
      .then((response) => {
        let tempEmailTemplates = response.data?.map((emailTemplate) => {
          return {
            ...emailTemplate,
            currentTab: 1,
            emailTemplateLocale: emailTemplate.emailTemplateLocale.map(
              (emailTemplateLocale) => {
                return {
                  ...emailTemplateLocale,
                  isValid: true,
                  formattedEmailContent: emailTemplateLocale.contentHTML,
                  formattedSubject: emailTemplateLocale.subjectHTML,
                };
              }
            ),
          };
        });
        setEmailTemplates([...tempEmailTemplates]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {}, [emailTemplates]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getGlobalEmailSettings();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Grid item xs={12}>
      <Card style={{ marginTop: "15px" }}>
        <CardContent>
          {emailTemplates?.map((emailTemplate, emailTemplateIndex) => (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h4">
                  {t(`${notificationsPrefix}:${emailTemplate.templateName}`)}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <AppBar position="static" className={classes.appBar}>
                      <Tabs
                        value={emailTemplate.currentTab}
                        variant="scrollable"
                        textColor="primary"
                        scrollButtons="auto"
                        classes={{ scrollButtons: classes.scrollButtons }}
                      >
                        {tabs.map((tab) => (
                          <Tab
                            key={tab.value}
                            icon={tab.icon}
                            label={t(`languages:${tab.label}`)}
                            value={tab.value}
                            onClick={(event) =>
                              handleTabChange(
                                event,
                                tab.value,
                                emailTemplateIndex
                              )
                            }
                          />
                        ))}
                      </Tabs>
                    </AppBar>
                  </Grid>
                  <Grid item xs={12}>
                    {tabs?.map((tab, emailTemplateLocaleIndex) => (
                      <TabPanel
                        value={emailTemplate.currentTab}
                        index={tab.value}
                      >
                        <EmailTemplate
                          email={
                            emailTemplates[emailTemplateIndex]
                              .emailTemplateLocale.filter(emailLocale => emailLocale.localeId === tab.value)[0]
                          }
                          emailTags={
                            emailTemplates[emailTemplateIndex].emailTags
                          }
                          updateEmailTemplate={updateEmailTemplate}
                        />
                      </TabPanel>
                    ))}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default EmailSettings;
