import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import validate from "validate.js";
import { useSnackbar } from "notistack";

import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import CountrySelect from "src/components/CountrySelect";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import LocaleSelect from "src/components/LocaleSelect";
import { ReturnButton } from "src/components/LayoutComponents/ReturnButton";
import { TabPanel } from "src/components/TabPanel";
import QuillEditor from "src/components/QuillEditor";

import CustomDomainModal from "src/views/ConsultancyManagement/CustomDomainModal"

import * as ConsultancyApi from "src/api/consultancyApi";
import * as SystemAdminApi from "src/api/SystemAdminApi";

import { LANDING_PAGE_LOGO, NAVBAR_LOGO } from "src/constants/consultancyLogo";
import { SYSTEMADMIN, COREADMIN } from "src/constants/roles";
import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';
import * as imagesConstants from './../../constants/images';

import { UPDATE_CONSULTANCY_LOGO, UPDATE_CONSULTANCY_SECONDARY_LOGO } from "src/actions";

import iintroLogo from "src/assets/images/logos/i-intro_logo.png";
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    marginTop: "20px",
  },
  tab: {
    color: theme.palette.primary.main,
  },
}));

function ConsultancyDetails({ consultancyId }) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [t] = useTranslation([
    "common",
    "storage",
    "validation",
    "consultancy",
    "labels",
    "tooltips",
  ]);
  const role = useSelector((state) => state.session.roleType);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);
  const doShowCampaignTCs = useSelector((state) => {
    if (state.session.consultancySubscriptionPlanId === subscriptionPlansIds.TA) {
      return false;
    }

    return true;
  });

  const [loading, setloading] = useState(false);

  const schema = {
    systemName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    consultancyName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    telephone: {
      length: {
        maximum: 20,
      },
    },
    city: {
      length: {
        maximum: 32,
      },
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    language: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const [consultancyState, setConsultancyState] = useState(false);
  const [customDomainModalOpen, setCustomDomainModalOpen] = useState(false);
  const [details, setDetails] = useState({
    consultancyLogo: null,
    consultancySecondaryLogo: null,
    consultancyHeader: null,
    active: false,
    detailsForm: {
      isValid: false,
      values: {
        systemName: null,
        consultancyName: null,
        domainName: null,
        telephone: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        postalCode: null,
        country: null,
        language: null,
        campaignCandidateTCs: null,
        campaignContactTCs: null,
        talentAlertCandidateTCs: null,
        talentAlertContactTCs: null,
        consultancySubscriptionPlanId: null
      },
      touched: {},
      errors: {},
    },
  });

  const [currentCampaignTCTab, setCurrentCampaignTCTab] = useState(0);
  const tabs = [
    { value: 0, label: t("common:Candidate") },
    { value: 1, label: t("common:Contact") },
  ];

  const handleCampaignTCTabChange = (event, value) => {
    setCurrentCampaignTCTab(value);
  };

  const [currentTalentAlertTCTab, setCurrentTalentAlertTCTab] = useState(0);
  const [consultancySubscriptionPlans, setConsultancySubscriptionPlans] = useState([]);

  const handleTalentAlertTCTabChange = (event, value) => {
    setCurrentTalentAlertTCTab(value);
  };

  const getDetails = () => {
    return ConsultancyApi.getConsultancyDetails(consultancyId)
      .then((details) => {
        setConsultancyState(details.active);

        setDetails((prevFormState) => ({
          ...prevFormState,
          consultancyLogo: details.consultancyLogo,
          consultancySecondaryLogo: details.consultancySecondaryLogo,
          consultancyHeader: details.headerImage,
          active: true, //For launch button
          detailsForm: {
            ...prevFormState.detailsForm,
            values: {
              systemName: details.systemName,
              consultancyName: details.consultancyName,
              domainName: details.domainName,
              customDomain: details.customDomain,
              telephone: details.telephone,
              addressLine1: details.addressLine1,
              addressLine2: details.addressLine2,
              city: details.city,
              postalCode: details.postalCode,
              country: details.country,
              language: details.language.id,
              campaignCandidateTCs:
                details.campaignCandidateTCs?.termsAndConditions,
              campaignContactTCs:
                details.campaignContactTCs?.termsAndConditions,
              talentAlertCandidateTCs:
                details.talentAlertCandidateTCs?.termsAndConditions,
              talentAlertContactTCs:
                details.talentAlertContactTCs?.termsAndConditions,
              consultancySubscriptionPlanId: details.consultancySubscriptionPlanId
            },
          },
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleChange = (event) => {
    event.persist();

    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...prevFormState.touched,
          [event.target.name]: true,
        },
      },
    }));
  };

  const handleCampaignCandidateTCChange = (event) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          campaignCandidateTCs: event,
        },
        touched: {
          ...prevFormState.touched,
          campaignCandidateTCs: true,
        },
      },
    }));
  };

  const handleCampaignContactTCChange = (event) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          campaignContactTCs: event,
        },
        touched: {
          ...prevFormState.touched,
          campaignContactTCs: true,
        },
      },
    }));
  };

  const handleTalentAlertCandidateTCChange = (event) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          talentAlertCandidateTCs: event,
        },
        touched: {
          ...prevFormState.touched,
          talentAlertCandidateTCs: true,
        },
      },
    }));
  };

  const handleTalentAlertContactTCChange = (event) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          talentAlertContactTCs: event,
        },
        touched: {
          ...prevFormState.touched,
          talentAlertContactTCs: true,
        },
      },
    }));
  };

  const hasError = (field) => {
    if (
      details.detailsForm.touched[field] &&
      details.detailsForm.errors[field]
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCountryChange = (value) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          country: value,
        },
        touched: {
          ...prevFormState.touched,
          country: true,
        },
      },
    }));
  };

  const handleLocaleChange = (value) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          language: value.props.value,
        },
        touched: {
          ...prevFormState.touched,
          language: true,
        },
      },
    }));
  };

  const handleUploadConsultancyLogo = (value, consultancyLogoType) => {
    return ConsultancyApi.uploadConsultancyLogo(consultancyId, value, consultancyLogoType)
      .then((response) => {
        if (response) {
          enqueueSnackbar(t("common:consultancyLogoUpdated"), {
            variant: "success",
          });

          if (![SYSTEMADMIN, COREADMIN].includes(role)) {
            if (consultancyLogoType === LANDING_PAGE_LOGO) {
              localStorage.setItem("consultancyLogo", response);

              dispatch({
                type: UPDATE_CONSULTANCY_LOGO,
                consultancyLogo: response,
              });
            } else {
              localStorage.setItem("consultancySecondaryLogo", response);

              dispatch({
                type: UPDATE_CONSULTANCY_SECONDARY_LOGO,
                consultancySecondaryLogo: response,
              });
            }
          }

          getDetails();
        } else {
          throw Error("Failed to update logo");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("common:consultancyLogoUpdatedError"), {
          variant: "error",
        });

        console.error(error);
      });
  };

  const handleUploadConsultancyHeader = (value) => {
    return ConsultancyApi.uploadConsultancyHeaderImage(consultancyId, value)
      .then(() => {
        enqueueSnackbar(t('common:consultancyHeaderUpdated'), { variant: 'success' });
      })
      .catch((error) => {
        enqueueSnackbar(t('common:consultancyHeaderUpdatedError'), { variant: 'error' });
        console.error(error);
      });
  };

  const saveDetails = (event) => {
    event.preventDefault();
    let updatedConsultancyDetails = details.detailsForm.values;
    updatedConsultancyDetails["consultancyId"] = consultancyId;
    return SystemAdminApi.updateConsultancyDetails(updatedConsultancyDetails)
      .then((response) => {
        enqueueSnackbar(t("common:consultancyUpdated"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(t("common:consultanctUpdateError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const roleType = useSelector((state) => {
    return state.session.roleType;
  });

  const openCustomDomainModal = () => {
    setCustomDomainModalOpen(true);
  };

  const closeCustomDomainModal = () => {
    setCustomDomainModalOpen(false);
  };

  const validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  const onSuccess = (event) => {
    let formValues = event.formValues;
    if (validURL(formValues)) {
      ConsultancyApi.setOrUpdateCustomDomain(
        consultancyId,
        formValues,
        formValues.isSubDomain
      )
        .then((response) => {
          enqueueSnackbar(t("snackbar:setCustomDomain"), {
            variant: "success",
          });
          getDetails().then(() => {
            setCustomDomainModalOpen(false);
          });

          return response.data;
        })
        .catch((error) => {
          enqueueSnackbar(t("snackbar:setCustomDomainFail"), {
            variant: "error",
          });
        });
    } else {
      ConsultancyApi.setOrUpdateCustomDomain(
        consultancyId,
        formValues,
        formValues.isSubDomain
      )
        .then((response) => {
          enqueueSnackbar(t("snackbar:setCustomDomain"), {
            variant: "success",
          });
          getDetails().then(() => {
            setCustomDomainModalOpen(false);
          });
          return response.data;
        })
        .catch((error) => {
          enqueueSnackbar(t("snackbar:setCustomDomainFail"), {
            variant: "error",
          });
        });
    }
  };

  const handleConsultancyStatus = (event) => {
    Swal.fire({
      title: t("snackbar:areYouSure"),
      text: consultancyState
        ? t("snackbar:areYouSureYouWantToDisableTheConsultancy")
        : t("snackbar:areYouSureYouWantToEnableTheConsultancy"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("snackbar:yes"),
      cancelButtonText: t("snackbar:cancel"),
      confirmButtonColor: theme.palette.warning?.main
        ? theme.palette.warning.main
        : theme.palette.primary.main,
    }).then((result) => {
      if (result.value) {
        return SystemAdminApi.disableOrEnableConsultancy(consultancyId)
          .then((response) => {
            setConsultancyState(response.data.active);

            if (response.data.active) {
              enqueueSnackbar(t("snackbar:consultancyActiveSuccess"), {
                variant: "success",
              });
            } else {
              enqueueSnackbar(t("snackbar:consultancyInactiveSuccess"), {
                variant: "success",
              });
            }
          })
          .catch((error) => {
            enqueueSnackbar(t("snackbar:errorConsultancyState"), {
              variant: "error",
            });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const handleConsultancySubscriptionPlanChange = (event, value) => {
    event.persist();

    setDetails((prevState) => ({
      ...prevState,
      detailsForm: {
        ...prevState.detailsForm,
        values: {
          ...prevState.detailsForm.values,
          consultancySubscriptionPlanId: value.id
        }
      }
    }));
  };

  /* UseEffects */

  useEffect(() => {
    SystemAdminApi.getConsultancySubscriptionPlans()
      .then((response) => {
        setConsultancySubscriptionPlans(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Retrieve initial form values
  useEffect(() => {
    setloading(true);
    getDetails()
      .then(() => {
        setloading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId]);

  // Validate form when changes are made
  useEffect(() => {
    const errors = validate(details.detailsForm.values, schema);

    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        isValid: !errors,
        errors: errors || {},
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.detailsForm.values]);

  const getLogo = () => {
    if (details.consultancyLogo) {
      return details.consultancyLogo;
    }

    switch (details.detailsForm.values.consultancySubscriptionPlanId) {
      case subscriptionPlansIds.TA:
        return taLogo;
      case subscriptionPlansIds.TA_PRO:
        return taProLogo;
      default:
        return iintroLogo;
    }
  };

  const getHeader = () => {
    if (details.consultancyHeader) {
      return details.consultancyHeader;
    }

    return imagesConstants.CAMPAIGN_HEADER;
  }

  return (
    <ContentWrapper removeGutters loading={loading}>
      <Grid item xs={12} className={classes.headerRow}>
        <ButtonWrapper
          btnLabel={t("common:saveEditingQuestion")}
          btnFunc={saveDetails}
          btnDisabled={!details.detailsForm.isValid}
          btnVariant="contained"
        />
      </Grid>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <BlockHeader
                headerTooltip={t("tooltips:setConsultancyLogo")}
                card
                padding
                header={ t("newTranslations:manageDefaultLogo") }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <CustomFileDropzone
                      logo
                      type="single"
                      format="image"
                      actionText={t("storage:uploadLogo")}
                      uploadFunction={event => { handleUploadConsultancyLogo(event, LANDING_PAGE_LOGO) }}
                      placeHolderImage={ getLogo() }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <BlockHeader
                headerTooltip={ t("newTranslations:setConsultancySecondaryLogo") }
                card
                padding
                header={ t("newTranslations:manageNavbarLogo") }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <CustomFileDropzone
                      logo
                      type="single"
                      format="image"
                      actionText={t("storage:uploadHeader")}
                      uploadFunction={event => { handleUploadConsultancyLogo(event, NAVBAR_LOGO) }}
                      placeHolderImage={ getLogo() }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <BlockHeader
                headerTooltip={ t("newTranslations:manageHeaderTooltip") }
                card
                padding
                header={ t("newTranslations:manageHeader") }
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <CustomFileDropzone
                      logo
                      type="single"
                      format="image"
                      actionText={t("storage:uploadLogo")}
                      uploadFunction={ handleUploadConsultancyHeader }
                      placeHolderImage={ getHeader() }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {(roleType === "CoreAdmin" || roleType === "SystemAdmin") && (
            <>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant="contained"
                      fullWidth
                      onClick={handleConsultancyStatus}
                    >
                      {!consultancyState
                        ? t("common:activate")
                        : t("common:deactivate")}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12}>
                        <Typography variant="h5">System Default</Typography>
                        <TextField
                          fullWidth
                          name="domainName"
                          variant="outlined"
                          onChange={handleChange}
                          disabled={true}
                          error={hasError("domainName")}
                          value={details.detailsForm.values.domainName || ""}
                          helperText={
                            hasError("domainName")
                              ? t(`${details.detailsForm.errors.domainName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {t("common:customDomain")}
                        </Typography>
                        <TextField
                          fullWidth
                          name="domainName"
                          variant="outlined"
                          onChange={handleChange}
                          disabled={true}
                          error={hasError("domainName")}
                          value={details.detailsForm.values.customDomain || ""}
                          helperText={
                            hasError("domainName")
                              ? t(
                                  `${details.detailsForm.errors.customDomain[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          size="large"
                          type="submit"
                          variant="contained"
                          onClick={openCustomDomainModal}
                          fullWidth
                        >
                          Customise Domain
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BlockHeader
                      headerTooltip={t("tooltips:editConsultancyDetails")}
                      reduceMargin
                      header={t("consultancy:consultancyDetails")}
                    />
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:systemNameLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="systemName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("systemName")}
                          value={details.detailsForm.values.systemName || ""}
                          helperText={
                            hasError("systemName")
                              ? t(
                                  `validation:${details.detailsForm.errors.systemName[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:consultancyNameLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="consultancyName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("consultancyName")}
                          value={
                            details.detailsForm.values.consultancyName || ""
                          }
                          helperText={
                            hasError("consultancyName")
                              ? t(
                                  `validation:${details.detailsForm.errors.consultancyName[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>

                    {
                      (roleType === COREADMIN || roleType === SYSTEMADMIN) && (
                        <Grid container spacing={ 3 } className={ classes.inputRow }>
                          <Grid item xs={ 12 } md={ 3 }>
                            <Typography variant="h5">
                              { t("common:consultancySubscriptionPlanLabel") }
                            </Typography>
                          </Grid>
    
                          <Grid item xs={ 12 } md={ 9 }>
                            <Autocomplete
                              disableClearable
                              value={ consultancySubscriptionPlans.find(csp => csp.id === details.detailsForm.values.consultancySubscriptionPlanId) }
                              options={ consultancySubscriptionPlans }
                              getOptionLabel={ (option) => t(`consultancy:subcriptionPlans${option.id}`) }
                              onChange={ handleConsultancySubscriptionPlanChange }
                              renderInput={(params) => (
                                <TextField
                                  { ...params }
                                  style={{ width: '100%' }}
                                  variant='outlined' />
                              )} />
                          </Grid>
                        </Grid>
                      )
                    }

                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:telephoneLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="telephone"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("telephone")}
                          value={details.detailsForm.values.telephone || ""}
                          helperText={
                            hasError("telephone")
                              ? t(
                                  `validation:${details.detailsForm.errors.telephone[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:addressLabel1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine1"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine1")}
                          value={details.detailsForm.values.addressLine1 || ""}
                          helperText={
                            hasError("addressLine1")
                              ? t(
                                  `validation:${details.detailsForm.errors.addressLine1[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:addressLabel2")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine2"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine2")}
                          value={details.detailsForm.values.addressLine2 || ""}
                          helperText={
                            hasError("addressLine2")
                              ? t(
                                  `validation:${details.detailsForm.errors.addressLine2[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:cityLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="city"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("city")}
                          value={details.detailsForm.values.city || ""}
                          helperText={
                            hasError("city")
                              ? t(
                                  `validation:${details.detailsForm.errors.city[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="h5">
                          {t("common:postcodeLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          name="postalCode"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("postalCode")}
                          value={details.detailsForm.values.postalCode || ""}
                          helperText={
                            hasError("postalCode")
                              ? t(
                                  `validation:${details.detailsForm.errors.postalCode[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:countryLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <CountrySelect
                          handleCountryChange={handleCountryChange}
                          value={details.detailsForm.values.country}
                          consultancyId={consultancyId}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:languageLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <LocaleSelect
                          handleLocaleChange={handleLocaleChange}
                          value={details.detailsForm.values.language}
                          consultancyId={consultancyId}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          
          {
            doShowCampaignTCs && (
              <Grid item xs={12}>
                <Card>
                  <CardContent style={{ height: "550px" }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <BlockHeader
                          headerTooltip={t("tooltips:campaignTermsAndCond")}
                          reduceMargin
                          header={t(`common:${consultancySubscriptionPlanId === subscriptionPlansIds.TA_PRO ? "consultancyVacancyT&CLabel" : "consultancyCampaignT&CLabel"}`)}
                        />
                        <AppBar position="static" className={classes.appBar}>
                          <Tabs
                            value={currentCampaignTCTab}
                            onChange={handleCampaignTCTabChange}
                            variant="scrollable"
                            textColor="primary"
                          >
                            {tabs.map((tab) => (
                              <Tab
                                key={tab.value}
                                label={t(`common:${tab.label}`)}
                                value={tab.value}
                              />
                            ))}
                          </Tabs>
                        </AppBar>
                        <TabPanel value={currentCampaignTCTab} index={0}>
                          <QuillEditor
                            value={details.detailsForm.values.campaignCandidateTCs}
                            handleChange={handleCampaignCandidateTCChange}
                            toolbarOptions={["link"]}
                          />
                        </TabPanel>
                        <TabPanel value={currentCampaignTCTab} index={1}>
                          <QuillEditor
                            value={details.detailsForm.values.campaignContactTCs}
                            handleChange={handleCampaignContactTCChange}
                            toolbarOptions={["link"]}
                          />
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )
          }

          <Grid item xs={12}>
            <Card>
              <CardContent style={{ height: "550px" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <BlockHeader
                      headerTooltip={t("tooltips:talentAlertTermsAndCond")}
                      reduceMargin
                      header={t("common:consultancyTalentAlertT&CLabel")}
                    />
                    <AppBar position="static" className={classes.appBar}>
                      <Tabs
                        value={currentTalentAlertTCTab}
                        onChange={handleTalentAlertTCTabChange}
                        variant="scrollable"
                        textColor="primary"
                      >
                        {tabs.map((tab) => (
                          <Tab
                            key={tab.value}
                            label={t(`common:${tab.label}`)}
                            value={tab.value}
                          />
                        ))}
                      </Tabs>
                    </AppBar>
                    <TabPanel value={currentTalentAlertTCTab} index={0}>
                      <QuillEditor
                        value={
                          details.detailsForm.values.talentAlertCandidateTCs
                        }
                        handleChange={handleTalentAlertCandidateTCChange}
                        toolbarOptions={["link"]}
                      />
                    </TabPanel>
                    <TabPanel value={currentTalentAlertTCTab} index={1}>
                      <QuillEditor
                        value={details.detailsForm.values.talentAlertContactTCs}
                        handleChange={handleTalentAlertContactTCChange}
                        toolbarOptions={["link"]}
                      />
                    </TabPanel>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <ReturnButton />
      <CustomDomainModal
        title="Set a custom domain"
        open={customDomainModalOpen}
        buttonText="Save Settings"
        handleClose={closeCustomDomainModal}
        onSuccess={onSuccess}
        customDomain={details.detailsForm.values.customDomain}
        isSubDomain={details.detailsForm.values.isSubDomain}
      />
    </ContentWrapper>
  );
}

export default ConsultancyDetails;
