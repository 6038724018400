import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@material-ui/core';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  centeredText: {
    textAlign: 'center'
  },
  gridHeader: {
    marginBottom: '40px'
  },
  header: {
    fontWeight: 'bold'
  },
  gridInstructions: {
    marginBottom: '40px'
  },
  listItemMobile: {
    textAlign: 'center',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.primary.main}`
    }
  },
  gridAdditionalInstructions: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
    border: `1px solid ${theme.palette.primary.main}`,
    marginBottom: '40px'
  },
  additionalInstructions: {
    fontWeight: 'bold',
    marginTop: '12px'
  },
  gridActions: {
    marginTop: '40px'
  }
}));

const VideoAssessmentInstructions = ({ instructions, preparationTime, questionsCount, startAssessment }) => {

  const classes = useStyles();
  const [t] = useTranslation(['videoAssessment']);
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <>
      <Grid className={ classes.gridHeader } >
        <Typography variant={ isMobile ? "h3" : "h2" } className={ isMobile ? `${classes.header} ${classes.centeredText}` : classes.header } >
          { t("videoAssessment:videoAssessmentInstructionsHeader") }
        </Typography>
      </Grid>

      <Grid className={ isMobile ? `${classes.gridInstructions} ${classes.centeredText}` : classes.gridInstructions } >
        <Typography>
          { t("videoAssessment:videoAssessmentInstructionsSubHeader", { questionsCount }) }
        </Typography>

        <List>
          <ListItem className={ isMobile ? classes.listItemMobile : "" } >
            {
              !isMobile && (
                <>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                </>
              )
            }

            <ListItemText>
              { t("videoAssessment:videoAssessmentInstructionsRules1", { preparationTime }) }
            </ListItemText>
          </ListItem>

          <ListItem className={ isMobile ? classes.listItemMobile : "" } >
            {
              !isMobile && (
                <>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                </>
              )
            }

            <ListItemText>
              { t("videoAssessment:videoAssessmentInstructionsRules2") }
            </ListItemText>
          </ListItem>

          <ListItem className={ isMobile ? classes.listItemMobile : "" } >
            {
              !isMobile && (
                <>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                </>
              )
            }

            <ListItemText>
              { t("videoAssessment:videoAssessmentInstructionsRules3") }
            </ListItemText>
          </ListItem>

          <ListItem className={ isMobile ? classes.listItemMobile : "" } >
            {
              !isMobile && (
                <>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                </>
              )
            }

            <ListItemText>
              { t("videoAssessment:videoAssessmentInstructionsRules4") }
            </ListItemText>
          </ListItem>

          <ListItem className={ isMobile ? classes.listItemMobile : "" } >
            {
              !isMobile && (
                <>
                  <ListItemIcon>
                    <FiberManualRecordOutlinedIcon />
                  </ListItemIcon>
                </>
              )
            }

            <ListItemText>
              { t("videoAssessment:videoAssessmentInstructionsRules5") }
            </ListItemText>
          </ListItem>
        </List>
      </Grid>

      <Grid className={ classes.gridAdditionalInstructions } >
        <Typography>
          { t("videoAssessment:videoAssessmentInstructionsAdditionalInstructions") }
        </Typography>

        <Typography className={ classes.additionalInstructions } dangerouslySetInnerHTML={{ __html: instructions }} ></Typography>
      </Grid>

      <Grid>
        <Typography className={ isMobile ? classes.centeredText : "" } >
          { t("videoAssessment:videoAssessmentInstructionsWish") }
        </Typography>
      </Grid>

      <Grid className={ classes.gridActions } >
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="medium"
          onClick={ startAssessment } >
           { t("videoAssessment:videoAssessmentInstructionsStartButton") }
        </Button>
      </Grid>
    </>
  );

};

export default VideoAssessmentInstructions;
