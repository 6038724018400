import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';

function AssignTo({ handleManagedByChange, value, consultancy }) {
  let users = [];

  if (consultancy.details) {
    users = consultancy.details.consultancyUsers;
  }
  const [t] = useTranslation(['common']);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    return handleManagedByChange(newValue);
  };

  const options = [...users];

  return (
    <Autocomplete
      id='assignToAutocomplete'
      options={options}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      value={value}
      onChange={handleChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            label={`${t('common:selectManagedBy')}`}
            variant='outlined'
          />
        );
      }}
    />
  );
}
export default AssignTo;
