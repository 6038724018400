import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import Page from "src/components/Page";
import Breadcrumbs from "src/components/NavBreadCrumbs";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0% 5%",
  },
  header: {
    marginBottom: 25,
  },
  gridRow: {
    marginTop: "20px",
  },
}));

export const CardGrid = ({
  pageTitle,
  btnLabel,
  btnFunc,
  btnLabel2,
  btnFunc2,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const btn = btnLabel != null;
  const btn2 = btn && btnLabel2 != null ? true : false;

  return (
    <Page className={classes.root}>
      <Container className={classes.container} maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                xl={btn2 ? 8 : btn ? 10 : 12}
                lg={btn2 ? 6 : btn ? 9 : 12}
                md={btn2 ? 12 : btn ? 8 : 12}
              >
                {rest.navBreadcrumbs ? (
                  <Breadcrumbs navBreadcrumbs={rest.navBreadcrumbs} />
                ) : (
                  <Typography variant="h1">{pageTitle}</Typography>
                )}
              </Grid>
              {btn ? (
                <Grid
                  item
                  xs={12}
                  xl={2}
                  lg={3}
                  md={btn2 ? 6 : 4}
                  sm={btn2 ? 6 : 12}
                >
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={btnFunc}
                  >
                    {btnLabel || "CTA"}
                  </Button>
                </Grid>
              ) : null}
              {btn2 ? (
                <Grid item xs={12} xl={2} lg={3} sm={6}>
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    fullWidth
                    onClick={btnFunc2}
                  >
                    {btnLabel2}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {children ? (
            children.length > 0 ? (
              children.map((child) => <>{child}</>)
            ) : (
              <>{children}</>
            )
          ) : null}
        </Grid>
      </Container>
    </Page>
  );
};
