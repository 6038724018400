import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getAllUserNotifications,
  setAllNotificationsRead,
  deleteNotification,
  setNotificationRead,
  deleteAllNotifications,
} from 'src/api/notificationApi';
import * as notificationActions from 'src/actions/notificationActions';
import Notification from './Notification';
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Grid } from '@material-ui/core';

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '30px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '10px 0',
    },
  },
  button: {
    margin: '0 15px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0',
      display: 'flex',
      flexDirection: 'column',
    },
  },
}));

const UserNotifications = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [t] = useTranslation(['notifications', 'vacancyNotifications']);

  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const setNotificationState = () => {
    return getAllUserNotifications().then((response) => {
      setNotifications(response.data);
    });
  };

  const markAllAsRead = () => {
    return setAllNotificationsRead()
      .then((response) => {
        if (response.status === 200) {
          setNotificationState();
          dispatch({ type: notificationActions.SET_NOTIFICATIONS, data: [] });
          dispatch({ type: notificationActions.CLEAR_NOTIFICATIONS_COUNT });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const markAsRead = useCallback(
    (notificationId) => {
      return setNotificationRead(notificationId)
        .then((response) => {
          if (response.status === 200) {
            let updatedNotifications = notifications;
            let index = updatedNotifications.findIndex(
              (notification) => notification.id === notificationId
            );
            updatedNotifications[index].read = true;
            dispatch({
              type: notificationActions.SET_NOTIFICATIONS,
              data: [...updatedNotifications],
            });

            dispatch({
              type: notificationActions.DECREMENT_NOTIFICATIONS_COUNT
            });

            setNotificationState();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const handleDelete = useCallback(
    (notificationId) => {
      return deleteNotification(notificationId).then((response) => {
        if (response.status === 200) {
          let stateNotifications = notifications;
          const newNotifications = stateNotifications.filter(
            (notification) => notification.id !== notificationId
          );
          dispatch({
            type: notificationActions.SET_NOTIFICATIONS,
            data: [...newNotifications],
          });

          dispatch({
            type: notificationActions.DECREMENT_NOTIFICATIONS_COUNT
          });

          setNotifications([...newNotifications]);
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const deleteAll = () => {
    return deleteAllNotifications().then((response) => {
      if (response.status === 200) {
        setNotifications([]);
        dispatch({ type: notificationActions.SET_NOTIFICATIONS, data: [] });
        dispatch({ type: notificationActions.CLEAR_NOTIFICATIONS_COUNT });
      }
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setNotificationState();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Container>
      <Grid className={classes.controls}>
        <div>
          <Button
            color='primary'
            onClick={() => setFiltered(false)}
            variant='contained'
          >
            {t(`${notificationsPrefix}:viewAll`)}
          </Button>
          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            onClick={() => setFiltered(true)}
          >
            {t(`${notificationsPrefix}:viewUnread`)}
          </Button>
        </div>
        <div>
          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            onClick={deleteAll}
          >
            {t(`${notificationsPrefix}:deleteAll`)}
          </Button>
          <Button color='primary' variant='contained' onClick={markAllAsRead}>
            {t(`${notifications}:markAllRead`)}
          </Button>
        </div>
      </Grid>
      {filtered
        ? notifications
            .filter((notification) => !notification.read)
            .map((notification) => (
              <Notification
                key={notification.id}
                handleDelete={handleDelete}
                markAsRead={markAsRead}
                notification={notification}
              />
            ))
        : notifications.map((notification) => (
            <Notification
              key={notification.id}
              handleDelete={handleDelete}
              markAsRead={markAsRead}
              notification={notification}
            />
          ))}
    </Container>
  );
};

export default UserNotifications;
