import React, { memo } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Box
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
const useStyles = makeStyles(() => ({
  asset: {
    background: "white",
    width: 260,
    opacity: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: 10
  },
  image: {
    flex: 1,
    width: 50
  },
  name: {
    flex: 3,
    display: 'flex',
    alignItems: 'center'
  },
  nameText: {
    wordBreak: 'break-word',
    textAlign: 'left'
  }
}));

const FileTile = memo(({asset, ...rest}) => {
  const classes = useStyles();
  const {url, type, fileName} = asset;

  let image;
  switch(type) {
    case "image": 
      image = <img className={classes.image} alt="fileName" src={url} />;
      break;
    case "file": 
    default: 
      image = <FileCopyIcon />
  }

  return (
    <Box className={classes.asset}>
      <Box  className={classes.image}>
        {image}
      </Box>
      <Box className={classes.name}>
        <Typography className={classes.nameText}>{fileName}</Typography>
      </Box>
    </Box>
  );
});

export default FileTile;