import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as api from "../../api/themeApi";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

const getFaviconEl = () => {
	return document.getElementById("favicon");
};

export const StylesWrapper = ({ children }) => {
	const consultancyId = useSelector(
		(state) =>
			state.session.consultancyId || state.consultancy.consultancyDetails?.id
	);
	const reduxTheme = useSelector((state) => state.activeTheme);

	const handleFavicon = (consultancyId) => {
		return api.getConsultancyFavicon(consultancyId).then((response) => {
			const favicon = getFaviconEl();
			favicon.href = response?.data;
		});
	};

	const [activeTheme, setActiveTheme] = useState(reduxTheme);

	const getBackgroundImage = (consultancyId) => {
		return api.getConsultancyBackground(consultancyId).then((response) => {
			const imageObject = {
				"@global": {
					body: {
						backgroundImage: `url(${response?.data})`,
						height: "100%",
						backgroundAttachment: "fixed",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					},
				},
			};
			getActiveTheme(consultancyId, imageObject);
		});
	};

	const getActiveTheme = (consultancyId, imageObject) => {
		return api
			.getActiveTheme(consultancyId)
			.then((response) => {
				const themeFromDB = response.data.themeObject;
				// theme functions not stored in db copy of theme. added back in here..
				const themePaletteWithFunctions = {
					...activeTheme.palette,
					...themeFromDB.palette,
				};
				const themeTypographyWithFunctions = {
					...activeTheme.typography,
					...themeFromDB.typography,
				};
				themeFromDB.breakpoints = activeTheme.breakpoints;
				themeFromDB.spacing = activeTheme.spacing;
				themeFromDB.transitions = activeTheme.transitions;
				themeFromDB.palette = themePaletteWithFunctions;
				themeFromDB.typography = themeTypographyWithFunctions;
				themeFromDB.overrides.MuiCssBaseline = imageObject;

				const themeObject = { ...themeFromDB };
				localStorage.setItem("theme", JSON.stringify(themeObject));
				setActiveTheme(themeObject);
			})
			.catch((err) => {
				throw err;
			});
	};

	useEffect(() => {
		if (consultancyId) {
			handleFavicon(consultancyId);
			getBackgroundImage(consultancyId);
			getActiveTheme(consultancyId);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reduxTheme, consultancyId]);

	// Theme provider currently updates via api call when new theme is dispatched in Theme Management
	// Should pull theme straight from redux state

	return (
		<ThemeProvider theme={activeTheme}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	);
};
