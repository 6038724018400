import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Card,
	CardMedia,
	CardContent,
	Container,
	Grid,
	Slide,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
	card: {
		width: "100%",
		maxWidth: 480,
		height: 400,
	},
	media: {
		height: 200,
	},
	body: {
		marginTop: "1rem",
	},
}));

const LandingCards = ({ consultancyDetails, consultancyColour, assetUrls }) => {
	const classes = useStyles();
	const mobile = useMediaQuery("(max-width:1280px)");
	const desktop = useMediaQuery("(min-width:1920px)");
	const { ref, inView } = useInView({
		threshold: mobile ? 0.1 : 0.5,
		triggerOnce: true,
	});

	const [images, setImages] = useState([
		{
			1: "",
		},
		{
			2: "",
		},
		{
			3: "",
		},
	]);

	const data = consultancyDetails?.landing?.cards[0];
	const content = data ? Object.values(data) : null;

	useEffect(() => {
		if (assetUrls) {
			setImages([
				{
					1: assetUrls.card_1,
				},
				{
					2: assetUrls.card_2,
				},
				{
					3: assetUrls.card_3,
				},
			]);
		}
	}, [assetUrls]);

	return (
		<Box
			className={classes.container}
			width="100%"
			paddingX={desktop ? "8rem" : "2rem"}
			justifyContent="center"
			style={{ backgroundColor: "white" }}
		>
			<Container maxWidth="xl" disableGutters>
				<SectionHeader
					colour={
						consultancyDetails
							? consultancyDetails.theme.heading
							: consultancyColour
					}
				>
					{content ? content[0].header : "Measurably Better Recruitment"}
				</SectionHeader>
				<div ref={ref}>
					<Slide direction={mobile ? "right" : "up"} in={inView}>
						<Grid container spacing={6} justify="center">
							{content?.slice(1).map(({ title, body, id }, i) => (
								<Grid item xs={12} lg={4}>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
									>
										<Card className={classes.card}>
											<CardMedia
												className={classes.media}
												image={images[i][i + 1]}
											/>
											<CardContent>
												<Typography variant="h2">{title}</Typography>
												<Typography
													className={classes.body}
													variant="body1"
													color="textSeconday"
												>
													{body}
												</Typography>
											</CardContent>
										</Card>
									</Box>
								</Grid>
							))}
						</Grid>
					</Slide>
				</div>
			</Container>
		</Box>
	);
};

export default LandingCards;
