import React, { useState, useEffect } from 'react';

import { Button, Card, CircularProgress, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { useSnackbar } from 'notistack';

import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';

import * as talentAlertActions from './../../../actions/talentAlertActions';

import * as talentAlertApi from './../../../api/talentAlertApi';

import AddExistingCandidateModal from './AddExistingCandidateModal';
import CandidateList from './CandidateList';
import CreateCandidateModel from './../../../components/CreateCandidateModal';
import InviteUsersModal from './../../../components/InviteUsersModal';

import { useCampaignOrVacancyTranslationPrefix, useCandidatesOrVacancyCandidatesTranslationPrefix } from './../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px 10px',
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  button: {
    width: '70%',
    marginBottom: '15px'
  },
  icon: {
    marginRight: '5px'
  },
  buttons: {
    textAlign: 'center',
    paddingTop: '20px',
    paddingBottom: '20px'
  }
}));

const TalentAlertCandidatePool = ({ candidates }) => {

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const candidatesPrefix = useCandidatesOrVacancyCandidatesTranslationPrefix();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['campaigns', 'candidates', 'vacancies', 'vacancyCandidates']);
  const theme = useTheme();

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const talentAlert = useSelector((state) => state.talentAlert);
  const campaignId = talentAlert.id;

  const [loading, setLoading] = useState(true)
  const [newCandidateModalOpen, setNewCandidateModalOpen] = useState(false);
  const [existingCandidateModalOpen, setExistingCandidateModalOpen] = useState(false);
  const [inviteCandidateModalOpen, setInviteCandidateModalOpen] = useState(false);
  const [campaignCandidates, setCampaignCandidates] = useState(candidates);

  useEffect(() => {
    setCampaignCandidates(candidates);
    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleNewCandidateModal = () => {
    setNewCandidateModalOpen((prevState) => !prevState);
  };

  const toggleExistingCandidateModal = () => {
    setExistingCandidateModalOpen((prevState) => !prevState);
  };

  const toggleInviteCandidateModal = () => {
    setInviteCandidateModalOpen((prevState) => !prevState);
  };

  const onNewCandidateSuccess = (candidateDetails) => {
    setLoading(true);

    candidateDetails.campaignId = talentAlert.id;

    talentAlertApi.createAndAssignConsultancyCandidate(candidateDetails)
      .then((response) => {
        enqueueSnackbar(t('candidates:candidateCreateSuccess'), { variant: 'success' });
        
        dispatch({
          type: talentAlertActions.UPDATE_TALENT_ALERT_CANDIDATES,
          payload: response.data,
        });

        return talentAlertApi.getTalentAlertCampaign(consultancyId, campaignId);
      })
      .then((response) => {
        setCampaignCandidates(response.data.campaignCandidates);
      })
      .catch(() => {
        enqueueSnackbar(t('candidates:candidateCreateError'), { variant: 'error' });

        setLoading(false);
      })
      .then(() => {
        toggleNewCandidateModal();

        setLoading(false);
      });
  };

  const onExistingCandidateSuccess = (candidateDetails) => new Promise((resolve, reject) => {
    setLoading(true);

    const campaignId = talentAlert.id;
    const selectedCandidates = candidateDetails;

    const details = { campaignId, consultancyId, selectedCandidates };

    talentAlertApi.assignExistingCandidateToCampaign(details)
      .then((response) => {
        enqueueSnackbar(t('candidates:candidateCreateSuccess'), { variant: 'success' });
        dispatch({
          type: talentAlertActions.UPDATE_TALENT_ALERT_CANDIDATES,
          payload: response.data
        });
        
        return talentAlertApi.getTalentAlertCampaign(consultancyId, campaignId);
      })
      .then((response) => {
        setCampaignCandidates(response.data.campaignCandidates);
      })
      .catch(() => {
        enqueueSnackbar(t('candidates:candidateCreateError'), { variant: 'error' });
        reject();
      })
      .then(() => {
        toggleExistingCandidateModal();
        setLoading(false);
        resolve();
      });
  });

  const onInviteCandidateSuccess = (invitationDetails) => {
    const talentAlertInvitationForCandidateData = {
      emailSubject: invitationDetails.emailSubject,
      emailContent: invitationDetails.emailContent,
      selectedCandidates: invitationDetails.selectedUsers
    };

    return talentAlertApi.sendTalentAlertInvitationForCandidate(talentAlertInvitationForCandidateData, consultancyId, talentAlert.id)
      .then(() => {
        enqueueSnackbar(t('candidates:candidateInviteSuccess'), { variant: 'success' });
      });
  };

  const handleRemove = (candidateId) => {
    setLoading(true);

    Swal.fire({
      title: `${t('common:areYouSure')}`,
      text: `${t('campaigns:Are you sure that you want to delete this Candidate from the Campaign?')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${t('common:yes')}`,
      cancelButtonText: `${t('common:no')}`,
      confirmButtonColor: theme.palette.warning?.main ? theme.palette.warning.main : theme.palette.primary.main
    })
      .then((response) => {
        if (response.isConfirmed) {
          return talentAlertApi.removeCandidate(consultancyId, candidateId, campaignId);
        }
      })
      .then((response) => {
        enqueueSnackbar(t('snackbar:candidateRemoveSuccess'), { variant: 'success' });

        setCampaignCandidates(response.data);
      })
      .catch((error) => {
        console.log(error);

        enqueueSnackbar(t('snackbar:candidateRemoveFailure'), { variant: 'error' });
      })
      .then(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Grid container>
        <Grid item lg={9} spacing={3}>
          {
            loading ? (
              <CircularProgress size={25} />
            ) : (
              <CandidateList
                candidates={campaignCandidates}
                handleRemove={handleRemove} />
            )
          }
        </Grid>
        
        <Grid item lg={3} spacing={3}>
          <Card className={classes.buttons}>
            <>
              <Button
                color="primary"
                size="small"
                type="submit"
                variant="contained"
                onClick={toggleExistingCandidateModal}
                className={classes.button} >
                <AddCircleOutlineIcon className={classes.icon} />
                
                { t("campaigns:addExistingCandidates") }
              </Button>
              
              <Button
                color="primary"
                size="small"
                type="submit"
                variant="contained"
                onClick={toggleNewCandidateModal}
                className={classes.button} >
                <CreateNewFolderOutlinedIcon className={classes.icon} />
                
                { t("campaigns:createNewCandidate") }
              </Button>
              {
                talentAlert.uninvitedCandidates?.length > 0 && (
                  <Button
                    color="primary"
                    size="small"
                    type="submit"
                    variant="contained"
                    onClick={toggleInviteCandidateModal}
                    className={classes.button} >
                    <MailOutlineIcon className={classes.icon} />
                    
                    { t("candidates:inviteCandidates") }
                  </Button>
                )
              }
            </>
          </Card>
        </Grid>
      </Grid>
      
      <AddExistingCandidateModal
        onSuccess={onExistingCandidateSuccess}
        consultancyId={consultancyId}
        campaignId={ campaignId }
        open={existingCandidateModalOpen}
        handleClose={toggleExistingCandidateModal} />
      
      <CreateCandidateModel
        onSuccess={onNewCandidateSuccess}
        consultancyId={consultancyId}
        open={newCandidateModalOpen}
        handleClose={toggleNewCandidateModal} />
      
      <InviteUsersModal
        onSuccess={onInviteCandidateSuccess}
        uninvitedUsers={talentAlert.uninvitedCandidates}
        emailContent={talentAlert.candidateInvitationEmail}
        emailSubject={talentAlert.candidateInvitationEmailSubject}
        emailTags={talentAlert.candidateInvitationEmailTags}
        open={inviteCandidateModalOpen}
        title={t(`${candidatesPrefix}:inviteCandidatesToCampaignLabel`)}
        buttonText={t(`${campaignsPrefix}:inviteToCampaign`)}
        handleClose={toggleInviteCandidateModal}
        talentAlert />
    </>
  );

};

export default TalentAlertCandidatePool;
