import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Collapse,
	Dialog,
	Divider,
	Grid,
	Slide,
	Switch,
	Tab,
	Tabs,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../api/landingApi";
import { useSnackbar } from "notistack";
import HtmlEditor from "./HtmlEditor";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import LandingFooter from "src/views/LandingPage/LandingFooter";

const useStyles = makeStyles((theme) => ({
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const ManageFooter = ({ landingPageData }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [currentTab, setCurrentTab] = useState(null);
	const [expand, setExpand] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [showPreview, setShowPreview] = useState(false);

	const currentData = landingPageData.footer[0];
	const consultancyId = landingPageData.consultancyId;

	const [footerContent, setFooterContent] = useState({
		en_gb: "",
		de: "",
		es: "",
		fr: "",
		nl: "",
		en_us: "",
	});

	const languageKeys = Object.keys(footerContent);

	const exampleData = {
		landing: { footer: [footerContent] },
	};

	useEffect(() => {
		if (currentData) {
			setFooterContent(currentData);
			setCurrentTab(0);
			setEnabled(landingPageData.display_footer);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentData]);

	const toggleContentState = (fieldName) => {
		setEnabled(!enabled);
		return api
			.toggleDisplayContent(consultancyId, fieldName)
			.then((response) => {
				if (response.status === 200 && !enabled) {
					enqueueSnackbar("Footer enabled", {
						variant: "success",
					});
				} else if (response.status === 200 && enabled) {
					enqueueSnackbar("Footer disabled", {
						variant: "info",
					});
				}
			})
			.catch(() => {
				setEnabled(enabled);
				enqueueSnackbar("Could not be enabled at this time", {
					variant: "error",
				});
			});
	};

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const handleContentChange = (event, language) => {
		const formattedContent = event.editor
			.getData()
			.replace(/&gt;/g, ">")
			.replace(/&lt;/g, "<");

		const footer = { ...footerContent, [language]: formattedContent };
		setFooterContent(footer);
	};

	const applyChanges = () => {
		return api
			.updateContentBlock(consultancyId, "footer", footerContent)
			.then((response) => {
				if (response) {
					enqueueSnackbar("Content updated", { variant: "success" });
				}
			})
			.catch(() => {
				enqueueSnackbar("Unable to apply changes", { variant: "error" });
			});
	};

	return (
		<>
			<Grid container style={{ paddingTop: ".4rem" }}>
				<Grid item xs={6}>
					<Button
						variant="text"
						endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						onClick={() => {
							setExpand(!expand);
						}}
					>
						Footer
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Slide in={expand} direction="left">
						<Box display="flex" flexDirection="row-reverse">
							<Switch
								checked={enabled}
								color="primary"
								style={{ marginLeft: "auto" }}
								onChange={() => toggleContentState("display_footer")}
							/>
							<Button
								variant="text"
								onClick={() => toggleContentState("display_footer")}
							>
								Enable Content Block
							</Button>
						</Box>
					</Slide>
				</Grid>
			</Grid>
			<Collapse in={expand}>
				<ButtonWrapper
					btnLabel="Preview"
					btnFunc={() => {
						setShowPreview(true);
					}}
				/>
				<Grid container>
					<Grid item xs={4} lg={2}>
						<Box display="flex" alignItems="center">
							<Tabs
								orientation="vertical"
								value={currentTab}
								onChange={handleTabChange}
								variant="scrollable"
								className={classes.tabs}
							>
								<Tab label="EN" />
								<Tab label="DE" />
								<Tab label="ES" />
								<Tab label="FR" />
								<Tab label="NL" />
								<Tab label="US" />
							</Tabs>
						</Box>
					</Grid>
					<Grid item xs={8} lg={10}>
						<TabPanel value={currentTab} index={0}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								width="100%"
							>
								<HtmlEditor
									height="100%"
									value={footerContent.en_gb}
									handleChange={(event) => handleContentChange(event, "en_gb")}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={1}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								width="100%"
							>
								<HtmlEditor
									height="100%"
									value={footerContent.de}
									handleChange={(event) => handleContentChange(event, "de")}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={2}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								width="100%"
							>
								<HtmlEditor
									height="100%"
									value={footerContent.es}
									handleChange={(event) => handleContentChange(event, "es")}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={3}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								width="100%"
							>
								<HtmlEditor
									height="100%"
									value={footerContent.fr}
									handleChange={(event) => handleContentChange(event, "fr")}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={4}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								width="100%"
							>
								<HtmlEditor
									height="100%"
									value={footerContent.nl}
									handleChange={(event) => handleContentChange(event, "nl")}
								/>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={5}>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="center"
								width="100%"
							>
								<HtmlEditor
									height="100%"
									value={footerContent.en_us}
									handleChange={(event) => handleContentChange(event, "en_us")}
								/>
							</Box>
						</TabPanel>
					</Grid>
				</Grid>
				<ButtonWrapper btnLabel="Apply Changes" btnFunc={applyChanges} />
			</Collapse>
			<Divider />
			<Dialog
				fullWidth
				maxWidth="xl"
				open={showPreview}
				onClose={() => setShowPreview(false)}
			>
				<Box display="flex" flexDirection="row-reverse">
					<Button
						variant="text"
						onClick={() => {
							setShowPreview(false);
						}}
					>
						Close
					</Button>
				</Box>
				<LandingFooter
					consultancyDetails={exampleData}
					languageKey={languageKeys[currentTab]}
				/>
			</Dialog>
		</>
	);
};

export default ManageFooter;
