import React from 'react';

import { TableRow, TableCell } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme) => ({
  tCell: {
    textAlign: 'left'
  },
  singleContact: props => ({
    '&:nth-of-type(2n)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    '&:nth-of-type(2n+1)': {
      backgroundColor: fade(theme.palette.primary.main, 0.03)
    },
    padding: '10px 20px'
  }),
  check: {
    color: '#28a745',
    fontSize: '20px'
  },
  centerText: {
    textAlign: 'center'
  }
}));

const SingleFact = ({ fact, key }) => {

  const classes = useStyles();

  return (
    <TableRow
      hover
      role="checkbox"
      key={key}
      tabIndex={-1}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      className={classes.singleContact} >
      <TableCell className={classes.centerText}>
        <CheckCircle className={classes.check} />
      </TableCell>

      <TableCell component="th" scope="row" className={classes.tCell}>
        {fact}
      </TableCell>
    </TableRow>
  );

};

export default SingleFact;
