import React, { useEffect, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useLocation } from 'react-router-dom';

import { Button, Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import { SET_CANDIDATE_CAMPAIGN } from 'src/actions/candidateCampaignActions';
import { getCandidateProfile } from '../../../../api/talentAlertApi';

import * as talentAlertActions from './../../../../actions/talentAlertActions';

import { PageTitle } from '../../../../components/LayoutComponents/PageTitle';
import PageBackButton from './../../../../components/LayoutComponents/PageBackButton';
import Page from '../../../../components/Page';
import { ModalFormWrapper } from './../../../../components/ModalFormWrapper';
import ProfileDetails from './ProfileDetails';
import TalentAlertContactCandidateProfile from './../../../TalentAlertContacts/TalentAlertContactCandidateProfile';
import UnAuthTalentAlertContactCandidateProfile from './../../../TalentAlertContacts/UnAuthTalentAlertContactCandidateProfile';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3)
	},
	button: {
		marginLeft: '40px'
	},
	previewModalGrid: {
	  display: 'flex',
	  flexDirection: 'row',
	  justifyContent: 'space-around',
	  alignItems: 'center'
	}
}));

function TalentAlertCandidateProfile({ candidateId, campaignId }) {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'candidates']);
	const dispatch = useDispatch();


	const candidateCampaign = useSelector(
		(state) => state.candidateCampaign.candidateProfile
	);
	const consultancyId = useSelector((state) => state.session.consultancyId);
	const { previewingAsAuthenticatedContact, previewingAsUnauthenticatedUser } = useSelector((state) => state.talentAlert)

	const [executiveSummary, setExecutiveSummary] = useState();
	const [keyFacts, setKeyFacts] = useState({
		fact1: '', fact2: '', fact3: ''
	});
	const [overviews, setOverviews] = useState({
		overviews: [
			{
				point: '',
				pointValue: ''
			},
		],
	});
	const [isLoading, setIsLoading] = useState(true);

	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
	const [isPreviewAsAuthenticatedContact, setIsPreviewAsAuthenticatedContact] = useState(previewingAsAuthenticatedContact);
	const [isPreviewAsUnauthenticatedUser, setIsPreviewAsUnauthenticatedUser] = useState(previewingAsUnauthenticatedUser);

	const timer = useRef(null);

	const handleSummaryChange = (event) => {
		timer.current && clearTimeout(timer.current);
		timer.current = setTimeout(() => setExecutiveSummary(event), 500);
	};

	const handleKeyFactsChange = (event) => {
		event.persist();
		setKeyFacts((prevFormState) => ({
			...prevFormState,
			[event.target.name]: event.target.value
		}));
	};

	const getCandidateTalentAlertProfile = (consultancyId, candidateId, campaignId) => {
		setIsLoading(true);
		return getCandidateProfile(
			consultancyId,
			candidateId,
			campaignId
		)
			.then((response) => {
				dispatch({
					type: SET_CANDIDATE_CAMPAIGN,
					candidateCampaign: {
						candidateProfile: response.data,
					},
				});
				setExecutiveSummary(response.data.candidateExecutiveSummary);
				setKeyFacts(response.data.candidateKeyFacts);
				setOverviews(response.data.candidateOverviews);
			})
			.catch((error) => {
				history.push('/dashboard');
			})
			.then(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		getCandidateTalentAlertProfile(consultancyId, candidateId, campaignId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultancyId, candidateId, campaignId]);

	const togglePreview = () => {
		setIsPreviewModalOpen((prevState) => !prevState);
	};

	const toggleAuthenticatedContactPreview = () => {
		const prevState = isPreviewAsAuthenticatedContact;
	
		setIsPreviewAsAuthenticatedContact(!prevState);
	
		dispatch({
		  type: talentAlertActions.PREVIEW_TALENT_ALERT_AS_AUTHENTICATED_CONTACT,
		  previewingAsAuthenticatedContact: !prevState
		});

		if (location.state && location.state.previousPath) {
			history.push(location.state.previousPath);
		}
	};

	const toggleUnauthenticatedUserPreview = () => {
		const prevState = isPreviewAsUnauthenticatedUser;
	
		setIsPreviewAsUnauthenticatedUser(!prevState);
	
		dispatch({
		  type: talentAlertActions.PREVIEW_TALENT_ALERT_AS_UNAUTHENTICATED_USER,
		  previewingAsUnauthenticatedUser: !prevState
		});

		if (location.state && location.state.previousPath) {
			history.push(location.state.previousPath);
		}
	};

	const goBack = () => {
		history.push(`/dashboard/talent-alerts/${campaignId}?currentTab=1`);
	};

	if (isPreviewAsAuthenticatedContact) {
		return (
			<TalentAlertContactCandidateProfile
				loggedIn={ true }
				campaignId={ campaignId }
				candidateId={ candidateId }
				consultancyId={ consultancyId }
				isPreview={ true }
				cancelPreview={ toggleAuthenticatedContactPreview } />
		);
	}

	if (isPreviewAsUnauthenticatedUser) {
		return (
			<UnAuthTalentAlertContactCandidateProfile
				loggedIn={ false }
				campaignId={ campaignId }
				candidateId={ candidateId }
				consultancyId={ consultancyId }
				isPreview={ true }
				cancelPreview={ toggleUnauthenticatedUserPreview } />
		);
	}

	return (
		<Page
			className={classes.root}
		>
			<PageTitle
				pageTitle={
					candidateCampaign && candidateCampaign.firstName
						? candidateCampaign.firstName + " " + candidateCampaign.lastName + " - Talent Alert Profile"
						: ""
				}
				btnLabel={t("newTranslations:previewCandidate")}
				btnFunc={togglePreview}
			/>

			<div className={ classes.button } >
				<PageBackButton goBackFn={ goBack } />
			</div>

			<ProfileDetails
				campaignId={campaignId}
				candidateId={candidateId}
				consultancyId={consultancyId}
				candidateCampaign={candidateCampaign}
				isLoading={isLoading}
				handleSummaryChange={handleSummaryChange}
				executiveSummary={executiveSummary}
				handleKeyFactsChange={handleKeyFactsChange}
				keyFacts={keyFacts}
				overviews={overviews}
				talentAlert
			/>

			<ModalFormWrapper
			  formTitle={ t("newTranslations:recruiterPreviewTalentAlertAsContactTitle") }
			  formTooltip={ t("newTranslations:recruiterPreviewTalentAlertAsContactTooltip") }
			  handleOpen={ isPreviewModalOpen }
			  handleClose={ togglePreview }
			  maxWidth="sm" >
			  <Grid className={ classes.previewModalGrid }>
				<Button color="primary" size="large" variant="outlined" onClick={ toggleAuthenticatedContactPreview }>
				  { t("newTranslations:recruiterPreviewTalentAlertAsContactBtnAsAuthContact") }
				</Button>
	  
				<Button color="primary" size="large" variant="contained" onClick={ toggleUnauthenticatedUserPreview }>
				{ t("newTranslations:recruiterPreviewTalentAlertAsContactBtnAsUnauthContact") }
				</Button>
			  </Grid>
			</ModalFormWrapper>
		</Page>
	);
}

export default TalentAlertCandidateProfile;
