import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import CountrySelect from "src/components/CountrySelect";
import iintroLogo from "src/assets/images/logos/i-intro_logo.png";
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

import AssignTo from "./AssignTo";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(1),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    marginTop: "20px",
  },
  tab: {
    fontSize: "18px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    textTransform: "none",
  },
  assignSave: {
    height: "56px",
  },
}));

function EmployerProfile({
  consultancyId,
  handleManagedByChange,
  managedByUser,
  saveManagedBy,
  assignTo,
  details,
  saveDetails,
  handleUploadEmployerLogo,
  handleChange,
  hasError,
  handleCountryChange,
}) {
  const classes = useStyles();
  const [t] = useTranslation([
    "common",
    "storage",
    "validation",
    "labels",
    "tooltips",
  ]);

  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const getLogo = () => {
    if (details.employerLogo) {
      return details.employerLogo;
    }

    if (consultancySubscriptionPlanId === subscriptionPlansIds.TA) {
      return taLogo;
    }

    if (consultancySubscriptionPlanId === subscriptionPlansIds.TA_PRO) {
      return taProLogo;
    }

    return iintroLogo;
  };

  if (!details) {
    return (
      <Grid item xs={12} className={classes.loadingIndicator}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item xs={12} lg={4}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{ textAlign: "left" }}>
                      <BlockHeader card header={t("labels:manageLogo")} />
                      <CustomFileDropzone
                        type="single"
                        format="image"
                        actionText={t("storage:uploadLogo")}
                        uploadFunction={handleUploadEmployerLogo}
                        placeHolderImage={ getLogo() }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} className={classes.inputRow}>
                    <Grid item lg={12} sm={11} xs={12}>
                      <BlockHeader
                        card
                        headerTooltip={t("tooltips:manageAssignee")}
                        header={t("labels:manageAssignee")}
                      />
                      <AssignTo
                        handleManagedByChange={handleManagedByChange}
                        value={managedByUser}
                        consultancyId={consultancyId}
                        consultancy={details}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CardButton
                        save
                        btnFunc={saveManagedBy}
                        btnDisabled={!assignTo.touched}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BlockHeader
                        card
                        headerTooltip={t("tooltips:manageCompanyDetails")}
                        header={t("labels:manageDetails")}
                      />
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3}>
                          <Typography variant="h5">
                            {t("common:employerNameLabel")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            fullWidth
                            name="employerName"
                            variant="outlined"
                            onChange={handleChange}
                            value={
                              details.detailsForm.values.employerName || ""
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3}>
                          <Typography variant="h5">
                            {t("common:telephoneLabel")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            fullWidth
                            name="telephone"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("telephone")}
                            value={details.detailsForm.values.telephone || ""}
                            helperText={
                              hasError("telephone")
                                ? t(
                                    `validation:${details.detailsForm.errors.telephone[0]}`
                                  )
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3}>
                          <Typography variant="h5">
                            {t("common:addressLabel1")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            fullWidth
                            name="addressLine1"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("addressLine1")}
                            value={
                              details.detailsForm.values.addressLine1 || ""
                            }
                            helperText={
                              hasError("addressLine1")
                                ? t(
                                    `validation:${details.detailsForm.errors.addressLine1[0]}`
                                  )
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3}>
                          <Typography variant="h5">
                            {t("common:addressLabel2")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <TextField
                            fullWidth
                            name="addressLine2"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("addressLine2")}
                            value={
                              details.detailsForm.values.addressLine2 || ""
                            }
                            helperText={
                              hasError("addressLine2")
                                ? t(
                                    `validation:${details.detailsForm.errors.addressLine2[0]}`
                                  )
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3}>
                          <Typography variant="h5">
                            {t("common:cityLabel")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextField
                            fullWidth
                            name="city"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("city")}
                            value={details.detailsForm.values.city || ""}
                            helperText={
                              hasError("city")
                                ? t(
                                    `validation:${details.detailsForm.errors.city[0]}`
                                  )
                                : null
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography variant="h5">
                            {t("common:postcodeLabel")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            fullWidth
                            name="postalCode"
                            variant="outlined"
                            onChange={handleChange}
                            error={hasError("postalCode")}
                            value={details.detailsForm.values.postalCode || ""}
                            helperText={
                              hasError("postalCode")
                                ? t(
                                    `validation:${details.detailsForm.errors.postalCode[0]}`
                                  )
                                : null
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} className={classes.inputRow}>
                        <Grid item xs={12} md={3}>
                          <Typography variant="h5">
                            {t("common:countryLabel")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <CountrySelect
                            handleCountryChange={handleCountryChange}
                            value={details.detailsForm.values.country}
                            consultancyId={consultancyId}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <CardButton
                    save
                    btnFunc={saveDetails}
                    btnDdisabled={!details.detailsForm.isValid}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default EmployerProfile;
