import React from 'react';

import { Table, TableBody } from '@material-ui/core';

import SingleFact from './SingleFact';

const KeyFacts = ({ keyFacts }) => {

  if (!keyFacts || !Object.keys(keyFacts).length) {
    return <></>;
  }

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableBody>
        <>
          {
            Object.keys(keyFacts).map((k, i) => (
              <>
                {
                  keyFacts[k] && (
                    <>
                      <SingleFact fact={keyFacts[k]} key={i + 1} />
                    </>
                  )
                }
              </>
            ))
          }
        </>
      </TableBody>
    </Table>
  );

};

export default KeyFacts;
