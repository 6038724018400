import axios from '../utils/axios';

export const createOrUpdateAccessToken = (consultancyId, accessToken) => {
    return axios.post('/SignalHireAccessToken/createOrUpdate', {
        consultancyId,
        accessToken
    });
};

export const getAccessTokenByConsultancyId = consultancyId => {
    return axios.get(`/SignalHireAccessToken/getByConsultancyId/${consultancyId}`);
};

export const getRemainingCreditsByConsultancyId = consultancyId => {
    return axios.get(`/SignalHireAccessToken/getRemainingCredits/${consultancyId}`);
};

export const getPersonData = (consultancyId, userId) => {
    return axios.get(`/CandidateSignalHire/getPersonData/${consultancyId}/${userId}`);
};

export const requestPersonData = (consultancyId, userId, items) => {
    return axios.post('/CandidateSignalHire/requestPersonData', {
        consultancyId,
        userId,
        items
    });
};
