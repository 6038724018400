import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import * as signalHireApi from './../../../../api/signalHireApi';

import { BlockHeader } from './../../../../components/LayoutComponents/BlockHeader';
import { ModalFormWrapper } from './../../../../components/ModalFormWrapper';

import { decodeSignalHireData } from './../../../../utils/object-util';

const useStyles = makeStyles((theme) => ({
  dataItem: {
    display: 'flex',
    alignItems: 'center'
  },
  listItem: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  typography: {
    paddingLeft: '16px'
  },
  divider: {
    margin: '20px 0'
  },
  form: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
}));

function SignalHireData({ consultancyId, userId }) {

  const classes = useStyles();

  const [t] = useTranslation(['newTranslations']);

  const [remainingCredits, setRemainingCredits] = useState(0);
  const [signalHirePersonData, setSignalHirePersonData] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalFormState, setModalFormState] = useState({
    values: {
      linkedin: "",
      phoneNumber: "",
      email: ""
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    getSignalHireData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSignalHireData = () => {
    signalHireApi.getRemainingCreditsByConsultancyId(consultancyId)
      .then((json = {}) => json.data)
      .then(data => {
        setRemainingCredits(data.remainingCredits);
      });

    signalHireApi.getPersonData(consultancyId, userId)
      .then((json = {}) => json.data)
      .then(data => {
        const decodedData = decodeSignalHireData(data);
        setSignalHirePersonData(decodedData);
      });
  };

  const toggleModal = () => {
    setModalOpen(prevState => !prevState);
  };

  const modalChange = event => {
    event.persist();

    setModalFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevState.touched,
        [event.target.name]: true
      }
    }));
  };

  const modalSubmit = event => {
    event.preventDefault();

    const items = [];

    for (const value of Object.values(modalFormState.values)) {
      if (value) {
        items.push(value);
      }
    }

    if (!items.length) return;

    signalHireApi.requestPersonData(consultancyId, userId, items)
      .then(() => {
        getSignalHireData();
        setModalOpen(false);
      });
  };

  const getExperince = e => {
    const primary = `${e.position} at ${e.company}`
    let secondary = e.started;

    if (e.ended) {
      secondary = `From ${e.started} to ${e.ended}`;
    }

    return (
      <>
        <ListItemText primary={primary} secondary={secondary} />

        {e.location && (
          <ListItemText secondary={e.location} />
        )}

        <ListItemText secondary={e.summary} />
      </>
    );
  }

  const renderPersonData = () => {
    if (!signalHirePersonData) {
      return (
        <Grid item xs={12} lg={9}>
          <Card>
            <CardContent>
              <BlockHeader header={ t("newTranslations:signalHireRequestNoData") } />
            </CardContent>
          </Card>
        </Grid>
      );
    }

    if (signalHirePersonData.signalHireStatus === 'sent') {
      return (
        <Grid item xs={12} lg={9}>
          <Card>
            <CardContent>
              <BlockHeader header={ t("newTranslations:signalHireRequestWaitingResponse") } />
            </CardContent>
          </Card>
        </Grid>
      );
    }

    if (signalHirePersonData.signalHireStatus === 'declined') {
      return (
        <Grid item xs={12} lg={9}>
          <Card>
            <CardContent>
              <BlockHeader header={ t("newTranslations:signalHireRequestDeclined") } />
            </CardContent>
          </Card>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} lg={9}>
        <Card>
          <CardContent>
            <BlockHeader reduceMargin header={ t("newTranslations:signalHireDataHeader") } />

            <Grid container spacing={3} className={classes.dataItem}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5" className={classes.typography}>
                  { t("newTranslations:signalHireDataNameLabel") }
                </Typography>
              </Grid>

              <Grid item xs={12} md={9}>
                <Typography outlined className={classes.typography}>
                  {signalHirePersonData.fullName}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            {signalHirePersonData.locations.length > 0 && (
              <Grid container spacing={3} className={classes.dataItem}>
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" className={classes.typography}>
                    { t("newTranslations:signalHireDataLocationsLabel") }
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <List>
                    {signalHirePersonData.locations.map(l => (
                      <ListItem variant="h5" className={classes.listItem}>
                        <ListItemText primary={l.name} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            <Divider className={classes.divider} />

            {signalHirePersonData.skills.length > 0 && (
              <Grid container spacing={3} className={classes.dataItem}>
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" className={classes.typography}>
                    { t("newTranslations:signalHireDataSkillsLabel") }
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <List>
                    {signalHirePersonData.skills.map(s => (
                      <ListItem variant="h5" className={classes.listItem}>
                        <ListItemText primary={s} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            <Divider className={classes.divider} />

            {signalHirePersonData.education.length > 0 && (
              <Grid container spacing={3} className={classes.dataItem}>
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" className={classes.typography}>
                    { t("newTranslations:signalHireDataEducationLabel") }
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <List>
                    {signalHirePersonData.education.map(e => (
                      <ListItem variant="h5" className={classes.listItem}>
                        {
                          e.endedYear ?
                            <ListItemText
                              primary={`${e.university} - ${e.faculty}`}
                              secondary={`From ${e.startedYear} to ${e.endedYear}`}
                            />
                            :
                            <ListItemText
                              primary={`${e.university} - ${e.faculty}`}
                              secondary={e.startedYear}
                            />
                        }

                        {e.degree.length > 0 && (
                          <>
                            {e.degree.map(d => (
                              <ListItemText secondary={d} />
                            ))}
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            <Divider className={classes.divider} />

            {signalHirePersonData.experience.length > 0 && (
              <Grid container spacing={3} className={classes.dataItem}>
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" className={classes.typography}>
                    { t("newTranslations:signalHireDataExperienceLabel") }
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <List>
                    {signalHirePersonData.experience.map(e => (
                      <ListItem variant="h5" className={classes.listItem}>
                        {getExperince(e)}
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            <Divider className={classes.divider} />

            <Grid container spacing={3} className={classes.dataItem}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5" className={classes.typography}>
                  { t("newTranslations:signalHireDataHeadLineLabel") }
                </Typography>
              </Grid>

              <Grid item xs={12} md={9}>
                <Typography outlined className={classes.typography}>
                  {signalHirePersonData.headLine}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Grid container spacing={3} className={classes.dataItem}>
              <Grid item xs={12} md={3}>
                <Typography variant="h5" className={classes.typography}>
                  { t("newTranslations:signalHireDataSummaryLabel") }
                </Typography>
              </Grid>

              <Grid item xs={12} md={9}>
                <Typography outlined className={classes.typography}>
                  {signalHirePersonData.summary}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            {signalHirePersonData.contacts.length > 0 && (
              <Grid container spacing={3} className={classes.dataItem}>
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" className={classes.typography}>
                    { t("newTranslations:signalHireDataContactsLabel") }
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <List>
                    {signalHirePersonData.contacts.map(c => (
                      <ListItem variant="h5" className={classes.listItem}>
                        <ListItemText primary={`${c.type} - ${c.value}`} secondary={`Rating: ${c.rating}`} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}

            <Divider className={classes.divider} />

            {signalHirePersonData.social.length > 0 && (
              <Grid container spacing={3} className={classes.dataItem}>
                <Grid item xs={12} md={3}>
                  <Typography variant="h5" className={classes.typography}>
                    { t("newTranslations:signalHireDataSocialLabel") }
                  </Typography>
                </Grid>

                <Grid item xs={12} md={9}>
                  <List>
                    {signalHirePersonData.social.map(s => (
                      <ListItem button component="a" href={s.link} variant="h5" className={classes.listItem}>
                        <ListItemText primary={`Social media - ${s.type}`} secondary={`Rating: ${s.rating}`} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 16 }}>
        <Grid item xs={12} lg={3}>
          <Card>
            <CardContent>
              <BlockHeader
                reduceMargin
                header={ t("newTranslations:signalHireInfoRemainingCreditsLabel").replace('$credits$', remainingCredits) } />

              <Button
                color="primary"
                size="small"
                variant="outlined"
                onClick={toggleModal}>
                { t("newTranslations:signalHireInfoRequestDataBtn") }
              </Button>
            </CardContent>
          </Card>
        </Grid>

        { renderPersonData() }
      </Grid>

      <ModalFormWrapper
        formTitle={ t("newTranslations:signalHireInfoRequestModalHeader") }
        handleOpen={modalOpen}
        handleClose={toggleModal}>
        <form className={classes.form} onSubmit={modalSubmit}>
          <Typography variant="h4" className={classes.typography}>
            { t("newTranslations:signalHireInfoRequestModalLinkedinLabel") }
          </Typography>

          <TextField
            fullWidth
            name="linkedin"
            variant="outlined"
            onChange={modalChange}
            value={modalFormState.values.linkedin}
          />

          <Typography variant="h4" className={classes.typography}>
            { t("newTranslations:signalHireInfoRequestModalPhoneNumberLabel") }
          </Typography>

          <TextField
            fullWidth
            name="phoneNumber"
            variant="outlined"
            onChange={modalChange}
            value={modalFormState.values.phoneNumber}
          />

          <Typography variant="h4" className={classes.typography}>
            { t("newTranslations:signalHireInfoRequestModalEmailLabel") }
          </Typography>

          <TextField
            fullWidth
            name="email"
            variant="outlined"
            onChange={modalChange}
            value={modalFormState.values.email}
          />

          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained">
            { t("newTranslations:signalHireInfoRequestModalSubmitBtn") }
          </Button>
        </form>
      </ModalFormWrapper>
    </>
  )

}

export default SignalHireData;
