import React from "react";
import { Grid, Divider } from "@material-ui/core";
import AnalyticsCard from "./AnalyticsCard";
import CampaignsPerMonth from "./CampaignsPerMonth";
import Header from "./Header";
import LoginTracker from './LoginTracker';
import { useTranslation } from "react-i18next";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";

function Dashboard({
	analyticsData,
	customersData,
	campaignsPerMonth,
	campaignsCompleted,
	activeConsultancies,
}) {
	const [t] = useTranslation(["common"]);
	return (
		<>
			<PageTitle pageTitle={t("common:Analytics")} />
			<ContentWrapper>
				<Grid item sm={ 12 } lg={ 12 } >
					<LoginTracker />
				</Grid>

				<Grid item sm={ 12 } lg={ 12 } >
					<Divider />
				</Grid>
				
				<Grid item sm={12} lg={12}>
					<Header
						activeConsultancies={activeConsultancies}
						dateSelect={false}
						consultancySelect={true}
						title={`${t("common:totalCustomersLabel")}`}
					/>
				</Grid>
				{customersData &&
					customersData.map((element, key) => (
						<Grid key={key} item lg={4} md={4} sm={6} xs={12}>
							<AnalyticsCard data={element} />
						</Grid>
					))}
				<Grid item sm={12} lg={12}>
					<Divider />
				</Grid>
				<Grid item sm={12} lg={12}>
					<Header dateSelect={true} />
				</Grid>
				<Grid item sm={12} lg={12}>
					<Header
						dateSelect={false}
						title={`${t("common:completionAnalyticsLabel")}`}
					/>
				</Grid>
				{campaignsCompleted &&
					campaignsCompleted.map((element, key) => (
						<Grid key={key} item lg={4} md={4} sm={6} xs={12}>
							<AnalyticsCard data={element} />
						</Grid>
					))}
				<Grid item sm={12} lg={12}>
					<Header
						dateSelect={false}
						title={`${t("common:campaignsStartedLabel")}`}
					/>
				</Grid>
				{analyticsData &&
					analyticsData.map((element, key) => (
						<Grid key={key} item lg={4} md={4} sm={6} xs={12}>
							<AnalyticsCard data={element} />
						</Grid>
					))}
				<Grid item lg={12} xs={12}>
					<CampaignsPerMonth campaignsPerMonth={campaignsPerMonth} />
				</Grid>
			</ContentWrapper>
		</>
	);
}

export default Dashboard;
