import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button, CardContent, Card } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { UPDATE_TALENT_ALERT_INTRODUCTION } from './../../../actions/talentAlertActions';

import CustomFileDropzone from './../../../components/CustomFileDropzone';
import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  fields: {
    margin: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
        flexGrow: 1,
        margin: theme.spacing(1)
    }
  },
  submitButton: {
    margin: theme.spacing(2),
    width: '33%'
  }
}));

const IntroductionInformation = ({ introductionDetails, campaignId, consultancyId, headerImg, campaignLogoImg }) => {

  const classes = useStyles();
  const [t] = useTranslation(['common', 'validation', 'snackbar', 'tooltips', 'newTranslations']);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [header, setHeader] = useState(headerImg);
  const [campaignLogo, setCampaignLogo] = useState(campaignLogoImg);

  const validationSchema = Yup.object().shape({
    greetingCaption: Yup.string().required(t('newTranslations:talentAlertGreetingCaptionInputErrorRequired')),
    introductionText: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      greetingCaption: '',
      introductionText: ''
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      talentAlertApi.updateIntroduction(campaignId, consultancyId, values)
        .then(() => {
          dispatch({
            type: UPDATE_TALENT_ALERT_INTRODUCTION,
            introduction: {
              greetingCaption: values.greetingCaption,
              introductionText: values.introductionText
            }
          });

          enqueueSnackbar(t('snackbar:successfullyUpdatedIntroduction'), { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar(t('snackbar:failedToUpdateIntroduction'), { variant: 'error' });
        });
    }
  });

  useEffect(() => {
    formik.setValues({
      greetingCaption: introductionDetails?.greetingCaption,
      introductionText: introductionDetails?.introductionText
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getError = () => {
    if (formik.errors.greetingCaption && formik.touched.greetingCaption) {
      return formik.errors.greetingCaption;
    }

    return '';
  };

  const handleUploadHeaderImg = (value) => {
    talentAlertApi.uploadHeaderImage(campaignId, value)
      .then(() => {
        return talentAlertApi.getHeaderImg(campaignId);
      })
      .then((response) => {
        setHeader(response.data);

        enqueueSnackbar(t('snackbar:successfullyUpdatedHeaderImage'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('snackbar:failedToUpdateHeaderImage'), { variant: 'error' });
      });
  };

  const handleUploadCampaignLogoImage = (value) => {
    talentAlertApi.uploadCampaignLogoImage(campaignId, value)
      .then((response) => {
        setCampaignLogo(response.data);
        enqueueSnackbar(t('snackbar:successfullyUpdatedCampaignLogo'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('snackbar:failedToUpdateCampaignLogo'), { variant: 'error' });
      });
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <BlockHeader
          card
          header={t("newTranslations:landingPageInformation")}
          headerTooltip={t("tooltips:landingPageInformationTooltip")} />

        <form onSubmit={formik.handleSubmit}>
          <div className={classes.fields}>
            <TextField
              fullWidth
              label={t("newTranslations:mainHeading")}
              name="greetingCaption"
              variant="outlined"
              required
              value={formik.values.greetingCaption}
              onChange={formik.handleChange}
              placeholder={t("newTranslations:mainHeadingPlaceholder")}
              error={!!getError()}
              helperText={getError()} />
          </div>

          <div className={classes.fields}>
            <TextField
              fullWidth
              label={t("newTranslations:subHeading")}
              name="introductionText"
              variant="outlined"
              multiline
              rows={5}
              value={formik.values.introductionText}
              onChange={formik.handleChange} />
          </div>

          <div>
            <Button
              className={classes.submitButton}
              color="primary"
              size="small"
              type="submit"
              variant="contained"
              fullWidth >
              { t("common:update") }
            </Button>
          </div>
            
          <div className={classes.fields}>
            <BlockHeader 
              card 
              header={t("newTranslations:headerImage")}
              headerTooltip={t("tooltips:headerImgTooltip")} />
            
            <CardContent>
              <CustomFileDropzone
                type="single"
                format="image"
                uploadFunction={handleUploadHeaderImg}
                placeHolderImage={header} />
            </CardContent>
          </div>

          <div className={ classes.fields }>
            <BlockHeader
              card
              header={ t("newTranslations:uploadCampaignLogoHeader") }
              headerTooltip={ t("tooltips:uploadCampaignLogoImgTooltip") } />

            <CardContent>
              <CustomFileDropzone
                type="single"
                format="image"
                placeHolderImage={ campaignLogo }
                uploadFunction={ handleUploadCampaignLogoImage } />
            </CardContent>
          </div>
        </form>
      </CardContent>
    </Card>
  );

};

export default IntroductionInformation;
