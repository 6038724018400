import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Dashboard from './Dashboard';
import * as ConsultancyApi from 'src/api/consultancyApi';
import { useTranslation } from 'react-i18next';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import FolderOpenIcon from '@material-ui/icons/FolderOpenOutlined';
import PeopleIcon from '@material-ui/icons/People';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BusinessIcon from '@material-ui/icons/Business';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    overflow: 'hidden',
  },
}));

const SystemAdminAnalytics = () => {
  const classes = useStyles();
  const [analytics, setAnalytics] = useState({});
  const [t] = useTranslation(['campaigns', 'common']);
  const dateFrom = useSelector((state) => state.analytics.dateFrom);
  const dateTo = useSelector((state) => state.analytics.dateTo);
  const consultancyId = useSelector(
    (state) => state.analytics.selectedConsultancy.id
  );

  const getAnalytics = () => {
    return ConsultancyApi.getSystemAdminAnalytics(
      dateFrom,
      dateTo,
      consultancyId
    )
      .then((response) => {
        if (response) {
          const data = response.data;
          setAnalytics(data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const customersData = [
    {
      name: t('common:usersLabel'),
      value: analytics.totalUsers,
      icon: PeopleIcon,
    },
    {
      name: t('campaigns:numberOfEmployersLabel'),
      value: analytics.totalEmployers,
      icon: PeopleIcon,
    },
    {
      name: t('campaigns:numberOfCandidatesLabel'),
      value: analytics.totalCandidates,
      icon: PeopleIcon,
    },
    {
      name: t('common:activeConsultanciesLabel'),
      value: analytics.activeConsultancies,
      icon: BusinessIcon,
    },
  ];

  const campaignsCompleted = [
    {
      name: t('campaigns:numberOfCompletedCampaignsLabel'),
      value: analytics.completedCampaigns,
      icon: FolderOpenIcon,
    },
    {
      name: t('campaigns:averageDaysToCompletionLabel'),
      value: analytics.averageDaysToCompletion,
      icon: DateRangeIcon,
    },
  ];

  const analyticsData = [
    {
      name: t('campaigns:numberOfCampaignsLabel'),
      value: analytics.totalCampaigns,
      icon: FolderOpenIcon,
    },
    {
      name: t('campaigns:numberOfLiveCampaignsLabel'),
      value: analytics.liveCampaigns,
      icon: FolderOpenIcon,
    },
    {
      name: t('campaigns:talentAlerts'),
      value: analytics.talentAlerts,
      icon: FolderOpenIcon,
    },

    {
      name: t('campaigns:averageCampaignSalaryLabel'),
      value: analytics.averageSalary,
      icon: MoneyIcon,
    },
    {
      name: t('campaigns:averageCampaignFeeLabel'),
      value: analytics.averageFee,
      icon: MoneyIcon,
    },
  ];

  const campaignsPerMonth = analytics.campaignsPerMonth;
  useEffect(() => {
    getAnalytics(consultancyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId, dateFrom, dateTo]);
  return (
    <Grid container className={classes.root} spacing={3}>
      {analytics && campaignsPerMonth && (
        <Dashboard
          campaignsPerMonth={campaignsPerMonth}
          analyticsData={analyticsData}
          customersData={customersData}
          campaignsCompleted={campaignsCompleted}
          activeConsultancies={analytics.activeConsultanciesNamesAndIds}
        />
      )}
    </Grid>
  );
};

export default SystemAdminAnalytics;
