import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Grid,
	TextField,
	CircularProgress,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import QuillEditor from "src/components/QuillEditor";

import { useCampaignOrVacancyTranslationPrefix } from './../utils/useSubscriptionPrefix';

let ejs = require("ejs");

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	container: {
		padding: "0px 10px",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "33%",
	},
	formControl: {
		marginBottom: theme.spacing(4),
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
	invUsers: {
		height: "200px",
		overflowY: "scroll",
		border: "0.5px solid grey",
		padding: "15px"
	}
}));

function InviteUsersModal({
	onSuccess,
	open,
	handleClose,
	uninvitedUsers,
	availableCandidates,
	emailContent,
	emailTags,
	emailSubject,
	title,
	buttonText,
	talentAlert
}) {
	const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

	const classes = useStyles();
	const [t] = useTranslation(["campaigns", "common", "vacancies"]);

	const [isValid, setIsValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [invitationEmailSubject, setInvitationEmailSubject] = useState(
		emailSubject
	);
	const [formattedEmailContent, setFormattedEmailContent] = useState(
		emailContent
	);

	const [selectedUsers, setSelectedUsers] = useState(
		uninvitedUsers?.map((uninvitedUser) => {
      return {
        id: uninvitedUser.id,
        email: uninvitedUser.email,
        checked: false
      };
		})
	);

	const [selectedCandidates, setSelectedCandidates] = useState([]);

	const [
		userTagsFormattedEmailContent,
		setUserTagsFormattedEmailContent,
	] = useState(formattedEmailContent?.match(/<%=(.*?)%>/g));

	const [
		userTagsInvitationEmailSubject,
		setUserTagsInvitationEmailSubject,
	] = useState(invitationEmailSubject?.match(/<%=(.*?)%>/g));
	const [
		invalidTagsFormattedEmailContent,
		setInvalidTagsFormattedEmailContent,
	] = useState(
		userTagsFormattedEmailContent?.filter((tag) => !emailTags?.includes(tag))
	);

	const [
		invalidTagsInvitationEmailSubject,
		setInvalidTagsInvitationEmailSubject,
	] = useState(
		userTagsInvitationEmailSubject?.filter((tag) => !emailTags?.includes(tag))
	);

	const incorrectTagsString = `${invalidTagsInvitationEmailSubject?.join(
		" "
	)} ${invalidTagsFormattedEmailContent?.join(" ")}`;

	const convertAvailableTagsToObject = (tags) => {
		const tagsObject = tags.reduce((acc, cv) => {
			const extractedTagName = cv.slice(3, cv.length - 2);
			acc[extractedTagName] = `test`;
			return acc;
		}, {});

		return tagsObject;
	};

	const ejsCanRender = () => {
		const fullTemplate =
			invitationEmailSubject +
			formattedEmailContent?.replace(/&gt;/g, ">")?.replace(/&lt;/g, "<");
		try {
			return ejs.render(fullTemplate, convertAvailableTagsToObject(emailTags));
		} catch {
			return false;
		}
	};

	const onSubmit = function (event) {
		event.preventDefault();
		setLoading(true);

		const selectedCandidatesForReq = selectedCandidates?.filter(sc => sc.checked);

		onSuccess({
			emailSubject: invitationEmailSubject,
			emailContent: formattedEmailContent,
			selectedUsers,
			selectedCandidates: selectedCandidatesForReq
		})
      .then(() => {
        setIsValid(false);
        setSelectedUsers(
          uninvitedUsers?.map((uninvitedUser) => {
            return {
              id: uninvitedUser.id,
              email: uninvitedUser.contact?.profile.user.email,
              checked: false,
            };
          })
        );
        
        setSelectedCandidates(prevSelectedCandidates => {
          return prevSelectedCandidates.map(sc => ({
            ...sc,
            checked: false
          }));
        });

        setLoading(false);

        handleClose();
      });
	};

	//validation checks
	const isSelectedUsersValid = () => {
		return selectedUsers?.some((selectedUser) => selectedUser.checked === true);
	};

	//Commented out to allow for email to be sent without tags

	// const doesEmailContentHaveTags = () => {
	//   let isValid = true;
	//   if (emailTags)
	//     emailTags.forEach((tag) => {
	//       if (!formattedEmailContent.includes(tag)) {
	//         isValid = false;
	//       }
	//     });
	//   return isValid;
	// };

	//kinda hacky. The returned data from the editor converts all internal < and > to &gt; and &lt;
	//This isn't ideal as it'll cause issues with the backend rendering as well as checking for required tags here
	//There may be a better solution but this is the best I could do for now
	const handleEmailContentChange = (event) => {
		const formattedText = event.replace(/&gt;/g, ">").replace(/&lt;/g, "<");
		const emailContentUserTags = formattedText.match(/<%=(.*?)%>/g);
		if (emailContentUserTags?.length) {
			const invalidTags = emailContentUserTags?.filter(
				(tag) => !emailTags.includes(tag)
			);
			setUserTagsFormattedEmailContent(emailContentUserTags);
			setInvalidTagsFormattedEmailContent(invalidTags);
		} else {
			setUserTagsFormattedEmailContent([]);
			setInvalidTagsFormattedEmailContent([]);
		}

		setFormattedEmailContent(formattedText);
	};

	const handleSelectedUserChange = (event, users, setFn) => {
		const updatedUsers = users.map(u => {
			if (u.id.toString() === event.target.name) {
				u.checked = event.target.checked;
			}

			return u;
		});

		setFn(updatedUsers);
	};

	const handleSubjectChange = (event) => {
		event.persist();
		const subjectUserTags = event.target.value.match(/<%=(.*?)%>/g);
		if (subjectUserTags) {
			const invalidTags = subjectUserTags?.filter(
				(tag) => !emailTags.includes(tag)
			);
			setUserTagsInvitationEmailSubject(subjectUserTags);
			setInvalidTagsInvitationEmailSubject(invalidTags);
		} else {
			setUserTagsInvitationEmailSubject([]);
			setInvalidTagsInvitationEmailSubject([]);
		}
		setInvitationEmailSubject(event.target.value);
	};

	useEffect(() => {
		if (
			// doesEmailContentHaveTags() && (Commented out to allow for email without tags to be set out)
			isSelectedUsersValid() &&
			ejsCanRender() &&
			invitationEmailSubject?.length > 0 &&
			!invalidTagsFormattedEmailContent?.length &&
			!invalidTagsInvitationEmailSubject?.length
		) {
			setIsValid(true);
		} else {
			setIsValid(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formattedEmailContent, selectedUsers, invitationEmailSubject]);

	useEffect(() => {
		setSelectedUsers(
			uninvitedUsers?.map((uninvitedUser) => {
				let inviteAppend = uninvitedUser.invited ? " - Invited" : "";
				
        return {
          id: uninvitedUser.id,
          email: uninvitedUser.contact ? uninvitedUser.contact?.profile.user.email + inviteAppend : uninvitedUser.email + inviteAppend,
          checked: false,
        };
			})
		);
	}, [uninvitedUsers]);

	useEffect(() => {
		if (talentAlert && availableCandidates) {
			const candidates = availableCandidates.map(ac => ({
				id: ac.campaignProfileId,
				label: `${ac.firstName} ${ac.lastName} - ${ac.email}`,
				email: ac.email,
				checked: false
			}));

			setSelectedCandidates(candidates);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [availableCandidates]);

	return (
		<ModalFormWrapper
			formTitle={title}
			handleOpen={open}
			handleClose={handleClose}
			maxWidth="md"
		>
			<form onSubmit={onSubmit}>
				<div className={classes.fields}>
					<Typography variant="subtitle1">
						{t("campaigns:emailSubjectLabel")}
					</Typography>
					<TextField
						fullWidth
						name="invitationEmailSubject"
						onChange={handleSubjectChange}
						value={invitationEmailSubject || ""}
						variant="outlined"
					/>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">
								{t(`${campaignsPrefix}:selectCampaignRecipientLabel`)}
							</Typography>
							<FormGroup>
								<div className={classes.invUsers}>
									{selectedUsers?.length > 0 ? (
										<>
											{selectedUsers?.map((user) => (
												<div style={{ display: "block", width: "100%" }}>
													<FormControlLabel
														control={
															<Checkbox
																onChange={ (e) => handleSelectedUserChange(e, selectedUsers, setSelectedUsers) }
																name={user.id}
																checked={user.checked}
															/>
														}
														label={user.email}
													/>
												</div>
											))}
										</>
									) : null}
								</div>
							</FormGroup>
						</Grid>

						{
							talentAlert && selectedCandidates?.length > 0 && (
								<Grid item xs={12} md={6}>
									<Typography variant="subtitle1">
										{t("campaigns:availableCampaignCandidatesLabel")}
									</Typography>
									<FormGroup>
										<div className={classes.invUsers}>
											{
												selectedCandidates.map((candidate) => (
													<div style={{ display: "block", width: "100%" }}>
														<FormControlLabel
															control={
																<Checkbox
																	onChange={ (e) => handleSelectedUserChange(e, selectedCandidates, setSelectedCandidates) }
																	name={candidate.id}
																	checked={candidate.checked} />
															}
															label={`${candidate.label}`}
														/>
													</div>
												))
											}
										</div>
									</FormGroup>
								</Grid>
							)
						}

						<Grid item xs={12} md={6}>
							<Typography variant="subtitle1">
								{t("campaigns:availableEmailTags")}
							</Typography>
							<div className={classes.invUsers}>
								{emailTags?.length > 0 ? (
									<>
										{emailTags?.map((emailTag) => (
											<Typography variant="body1">{emailTag}</Typography>
										))}
									</>
								) : null}
							</div>
						</Grid>
					</Grid>
				</div>
				<QuillEditor
					value={emailContent}
					handleChange={handleEmailContentChange}
					toolbarOptions={["link"]}
				/>
				<div style={{ textAlign: "center" }}>
					{invalidTagsInvitationEmailSubject?.length ||
						invalidTagsFormattedEmailContent?.length ? (
						<Typography style={{ color: "red" }}>
							{t("common:pleaseMatchYourUsedTagsExaclyWithAvailableTags")}.
							{t("common:notAvailable")}:{incorrectTagsString}
						</Typography>
					) : null}
					<Button
						className={classes.submitButton}
						color="primary"
						size="large"
						type="submit"
						variant="contained"
						disabled={!isValid}
					>
						{loading ? (
							<CircularProgress className={classes.progressCircle} size={25} />
						) : (
							`${buttonText}`
						)}
					</Button>
				</div>
			</form>
		</ModalFormWrapper>
	);
}
export default InviteUsersModal;
