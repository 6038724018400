import React from "react";
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 38,
    width: 38,
    fontSize: 20,
    borderRadius: 2,
    backgroundColor: theme.palette.button.main || theme.palette.primary.main,
    color: theme.palette.getContrastText(
      theme.palette.button.main || theme.palette.primary.main
    ),
    "&:hover": {
      borderRadius: 2,
      backgroundColor: theme.palette.button.main || theme.palette.primary.main,
    },
  },
  noFillButton: {
    height: 38,
    width: 38,
    fontSize: 20,
    color: theme.palette.button.main || theme.palette.primary.main,
    transition: "border-radius 0.2s ease",
    "&:hover": {
      borderRadius: 2,
      transition: "border-radius 0.1s linear",
    },
  },
  spinner: {
    color: theme.palette.getContrastText(
      theme.palette.button.main || theme.palette.primary.main
    ),
  },
  noFillSpinner: {
    color: theme.palette.button.main || theme.palette.primary.main,
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

export const ThemeIconButton = ({
  icon,
  btnFunc,
  disabled,
  closeButton,
  loading,
  marginRight,
  fullHeight,
  noFill,
  tooltip,
}) => {
  const classes = useStyles();

  if (tooltip)
    return (
      <Tooltip
        enterTouchDelay={1}
        classes={{
          tooltip: classes.tooltip,
        }}
        title={tooltip}
      >
        <IconButton
          className={
            noFill ? classes.noFillButton : closeButton ? null : classes.button
          }
          disabled={disabled ? true : false}
          onClick={btnFunc}
          style={
            marginRight
              ? { marginRight: 8 }
              : fullHeight
              ? { height: "100%" }
              : null
          }
        >
          {loading ? (
            <CircularProgress
              size={16}
              className={noFill ? classes.noFillSpinner : classes.spinner}
            />
          ) : (
            icon || <CloseIcon />
          )}
        </IconButton>
      </Tooltip>
    );

  return (
    <IconButton
      className={
        noFill ? classes.noFillButton : closeButton ? null : classes.button
      }
      disabled={disabled ? true : false}
      onClick={btnFunc}
      style={
        marginRight
          ? { marginRight: 8 }
          : fullHeight
          ? { height: "100%" }
          : null
      }
    >
      {loading ? (
        <CircularProgress
          size={16}
          className={noFill ? classes.noFillSpinner : classes.spinner}
        />
      ) : (
        icon || <CloseIcon />
      )}
    </IconButton>
  );
};
