import axios from "axios";
import * as CookieActions from "../actions/cookieActions";

import { configureStore } from "../store";

export const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

const accessToken = CookieActions.getCookie("accessToken");

if (accessToken) {
	instance.defaults.headers.common["Authorization"] = accessToken;
}

instance.interceptors.response.use(
	function(response) {
		//return response;
		return Promise.resolve(response);
	},
	function(error) {
		if (error && error.response && error.response.status && error.response.status === 401) {
			window.location = '/'
			CookieActions.deleteCookie('accessToken');
      CookieActions.deleteCookie('userId');
			CookieActions.deleteCookie('role');
			CookieActions.deleteCookie('consultancyId');
			CookieActions.deleteCookie('consultancyLogo');
			CookieActions.deleteCookie('consultancySecondaryLogo');
			localStorage.removeItem('consultancyLogo');
			localStorage.removeItem('consultancySecondaryLogo');
			CookieActions.deleteCookie('name');
			CookieActions.deleteCookie('avatar');
			axios.defaults.headers.common['Authorization'] = null;
			configureStore();
		}
		return Promise.reject(error);
	}
);

export default instance;
