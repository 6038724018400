import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import {
  Button,
  Typography,
  SvgIcon,
  TextField,
  colors,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import * as ConsultancyApi from "src/api/consultancyApi";
import { useTranslation } from "react-i18next";
import csv from "csv";
import FilesDropzone from "src/components/FilesDropzone";
import DeleteIcon from "@material-ui/icons/Delete";
import validate from "validate.js";

const useStyles = makeStyles((theme) => ({
  root: {},
  instructions: {
    padding: "10px 0",
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: "18px",
    outline: "none",
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    alignItems: "center",
    "&:hover": {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: "pointer",
    },
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5,
  },
  image: {
    width: "auto",
    height: "100px",
  },
  info: {
    marginTop: theme.spacing(1),
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
  dataTable: {
    overflowY: "scroll",
    width: "1600px",
  },
  input: {
    fontSize: "14px",
  },
  smallCell: {
    width: "5%",
  },
  mediumCell: {
    width: "8%",
  },
  largeCell: {
    width: "15%",
  },
  tableRow: {
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  tableCell: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  salutation: {
    length: {
      maximum: 10,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  telephone: {
    length: {
      maximum: 20,
    },
  },
  addressLine1: {},
  city: {
    length: {
      maximum: 32,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function BatchCreateCandidateModal({
  onSuccess,
  consultancyId,
  open,
  handleClose,
  className,
  ...rest
}) {
  const classes = useStyles();
  const [candidates, setCandidates] = useState([]);
  const [countries, setCountries] = useState([]);
  const [candidateErrors, setCandidateErrors] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [t] = useTranslation([
    "candidates",
    "common",
    "countries",
    "snackbar",
  ]);

  const uploadFiles = (file) => {
    let reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err, data) => {
        if (data) {
          let parsedCandidates = data.map((candidateRow) => {
            return {
              salutation: candidateRow[0],
              firstName: candidateRow[1],
              lastName: candidateRow[2],
              email: candidateRow[3],
              telephone: candidateRow[4],
              addressLine1: candidateRow[5],
              addressLine2: candidateRow[6],
              city: candidateRow[7],
              postalCode: candidateRow[8],
              country: countries.find(
                (country) => country.name === candidateRow[9]
              ),
            };
          });
          let candidateErrors = data.map(() => {
            return {
              errors: {},
            };
          });
          setCandidateErrors(candidateErrors);
          setCandidates(parsedCandidates);
          enqueueSnackbar(t("snackbar:candidateImportSuccess"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:candidateImportFailure"), {
            variant: "error",
          });
        }
      });
    };
    reader.readAsBinaryString(file);
  };

  const createCandidates = function() {
    return ConsultancyApi.batchCreateCandidate({ candidates, consultancyId })
      .then(() => {
        onSuccess(candidates);
        enqueueSnackbar(t("snackbar:bulkCandidateCreationSuccess"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:bulkCandidateCreationFailure"), {
          variant: "error",
        });
      })
      .then(() => {
        setCandidates([]);
        handleClose();
      });
  };

  const handleChange = function(event, index) {
    event.persist();
    let tempCandidateData = [...candidates];
    tempCandidateData[index][event.target.name] = event.target.value;
    setCandidates(tempCandidateData);
  };

  const handleCountryChange = (value, index, event) => {
    event.preventDefault();
    let tempCandidateData = [...candidates];
    tempCandidateData[index].country = value;
    setCandidates(tempCandidateData);
  };

  const deleteCandidate = (event, index) => {
    event.preventDefault();
    let tempCandidateData = [...candidates];
    tempCandidateData.splice(index, 1);
    setCandidates(tempCandidateData);
  };

  const [validCandidates, setValidCandidates] = useState(false);

  const hasError = (field, index) => {
    if (candidateErrors[index].errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const getConsultancyCountries = () => {
    return ConsultancyApi.getCountries(consultancyId)
      .then((response) => {
        setCountries(response.data.countries);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let errors = false;
    let tempCandidateArray = candidates.map((candidate) => {
      let candidateErrors = validate(candidate, schema);
      if (candidateErrors) {
        errors = true;
      }
      return {
        isValid: !candidateErrors,
        errors: candidateErrors || {},
      };
    });
    setCandidateErrors(tempCandidateArray);
    setValidCandidates(errors);
  }, [candidates]);

  const handleBatchCandidateModelClose = () => {
    handleClose();
    setCandidates([]);
  };

  useEffect(() => {
    getConsultancyCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalFormWrapper
      formTitle={t("candidates:createBatchCandidate")}
      handleOpen={open}
      handleClose={handleBatchCandidateModelClose}
      maxWidth="xl"
    >
      <div className={classes.instructions}>
        <Typography>{t(`candidates:toolBatchUpload`)}
        </Typography>
        <Typography>1. {t(`candidates:toImport`)}</Typography>
        <Typography>{t(`candidates:csvFormat`)}</Typography>
        <Typography>
          2. {t(`candidates:doNotIncludeHeaders`)}
        </Typography>
        <Typography>3. {t(`candidates:addNewCandidateRow`)}</Typography>
        <Typography>4. {t(`candidates:validCsv`)}</Typography>
      </div>
      {candidates && candidates.length > 0 ? (
        <div style={{ overflowY: "scroll" }}>
          <Table aria-label="customized table" className={classes.dataTable}>
            <TableHead>
              <TableRow>
                <TableCell className={(classes.smallCell, classes.tableCell)}>
                  {t("common:salutationLabel")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("common:firstNameLabel")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("common:lastNameLabel")}
                </TableCell>
                <TableCell className={(classes.largeCell, classes.tableCell)}>
                  {t("common:emailLabel")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("common:telephoneLabel")}
                </TableCell>
                <TableCell className={(classes.largeCell, classes.tableCell)}>
                  {t("common:addressLabel1")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("common:addressLabel2")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("common:cityLabel")}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {t("common:postcodeLabel")}
                </TableCell>
                <TableCell className={(classes.largeCell, classes.tableCell)}>
                  {t("common:countryLabel")}
                </TableCell>
                <TableCell className={(classes.smallCell, classes.tableCell)}>
                  {t("common:actions")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates.map((candidate, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.smallCell}>
                    <TextField
                      fullWidth
                      name="salutation"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.salutation || ""}
                      error={hasError("salutation", index)}
                      helperText={
                        hasError("salutation", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.salutation[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="firstName"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.firstName || ""}
                      error={hasError("firstName", index)}
                      helperText={
                        hasError("firstName", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.firstName[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="lastName"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.lastName || ""}
                      error={hasError("lastName", index)}
                      helperText={
                        hasError("lastName", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.lastName[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="email"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.email || ""}
                      error={hasError("email", index)}
                      helperText={
                        hasError("email", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.email[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="telephone"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.telephone || ""}
                      error={hasError("telephone", index)}
                      helperText={
                        hasError("telephone", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.telephone[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.largeCell}>
                    <TextField
                      fullWidth
                      name="addressLine1"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.addressLine1 || ""}
                      error={hasError("addressLine1", index)}
                      helperText={
                        hasError("addressLine1", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.addressLine1[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="addressLine2"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.addressLine2 || ""}
                      error={hasError("addressLine2", index)}
                      helperText={
                        hasError("addressLine2", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.addressLine2[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="city"
                      required
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.city || ""}
                      error={hasError("city", index)}
                      helperText={
                        hasError("city", index)
                          ? t(
                              `validation:${candidateErrors[index].errors.city[0]}`
                            )
                          : null
                      }
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name="postalCode"
                      onChange={(event) => {
                        handleChange(event, index);
                      }}
                      value={candidate.postalCode || ""}
                      InputProps={{
                        className: classes.input,
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.largeCell}>
                    <Autocomplete
                      id="country_select_combobox"
                      options={countries}
                      getOptionLabel={(option) => t(`countries:${option.name}`)}
                      value={candidate.country}
                      onChange={(event, value) => {
                        handleCountryChange(value, index, event);
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => {
                        return (
                          <TextField {...params} fullWidth variant="standard" />
                        );
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.smallCell}>
                    <Button
                      color="error"
                      size="small"
                      variant="contained"
                      onClick={(event) => {
                        deleteCandidate(event, index);
                      }}
                    >
                      <SvgIcon fontSize="small">
                        <DeleteIcon />
                      </SvgIcon>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.actions}>
            <Button
              onClick={() => {
                setCandidates([]);
              }}
              size="small"
            >
              {t("common:cancel")}
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={createCandidates}
              disabled={validCandidates}
            >
              {t("candidates:createCandidates")}
            </Button>
          </div>
        </div>
      ) : (
        <FilesDropzone uploadFunction={uploadFiles} />
      )}
    </ModalFormWrapper>
  );
}

BatchCreateCandidateModal.propTypes = {
  className: PropTypes.string,
};

export default BatchCreateCandidateModal;
