import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Container
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 16,
		padding: 0,
	},
	buttonBox: {
		display: "flex",
		flexDirection: "row",
	},
}));

export const FwdBackButtons = ({
	backFunc,
	fwd,
	fwdFunc = () => {},
	submit,
	loading,
}) => {
	const classes = useStyles();
	const [t] = useTranslation(["common"]);

	// const mobileDevice = useMediaQuery("(max-width:599px)");

	return (
		<Container className={classes.container}>
			<Box className={classes.buttonBox}>
				<Button
					color="primary"
					size="large"
					startIcon={<ArrowBackIcon />}
					variant="text"
					disabled={backFunc ? false : true}
					onClick={backFunc}
				>
					{t("common:back")}
				</Button>
				<div style={{ flexGrow: 1 }} />
				<Button
					size="large"
					type="submit"
					variant="text"
					endIcon={<ArrowForwardIcon />}
					disabled={fwd || submit ? false : true}
					onClick={fwdFunc}
					color="primary"
				>
					{submit ? t("common:submit") : t("common:next")}
				</Button>
			</Box>
		</Container>
	);
};
