import React from 'react';
import { Redirect } from 'react-router';

import { FULL, TA, TA_PRO } from './../constants/subscriptionPlansIds';

import CampaignDetail from './../views/Campaigns/CampaignDetail';
import CampaignList from './../views/Campaigns/CampaignList';
import Candidates from './../views/Candidates';
import CandidateDetails from './../views/Candidates/CandidateDetails';
import CandidateProfile from './../views/CandidateProfile';
import ConsultancyEmployers from './../views/ConsultancyEmployers';
import ConsultancyEmployerDetails from './../views/ConsultancyEmployerDetails';
import ContactManagement from './../views/ContactManagement';
import GroupDetails from './../views/MyProfile/Groups/GroupDetails';
import TalentAlertCandidateProfile from './../views/TalentAlertConsultant/TalentAlertCandidatePool/TalentAlertCandidateProfile';
import TalentAlertConsultant from './../views/TalentAlertConsultant';
import TalentAlertDetail from './../views/TalentAlertConsultant/TalentAlertDetail';

const ConsultantRoutes = (store) => {

    const fullSubscriptionPlanRoutes = [
        {
            path: '/dashboard',
            exact: true,
            component: () => {
                if (store.getState().session.consultancySubscriptionPlanId !== FULL) {
                    return <Redirect to="/dashboard/talent-alerts" />;
                }

                return <CampaignList />;
            }
        },
        {
            path: '/dashboard/campaigns/:campaignId',
            exact: true,
            component: (props) => <CampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/campaigns/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <CandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/groups/:groupId/details',
            exact: true,
            component: (props) => <GroupDetails consultancyId={ Number(store.getState().session.consultancyId) } groupId={ Number(props.match.params.groupId) } />
        }
    ];

    const talentAlertSubscriptionPlanRoutes = [
        {
            path: '/dashboard/talent-alerts',
            exact: true,
            component: () => <TalentAlertConsultant />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId',
            exact: true,
            component: (props) => <TalentAlertDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/candidates',
            exact: true,
            component: () => <Candidates />
        },
        {
            path: '/dashboard/candidates/:candidateId',
            exact: true,
            component: (props) => <CandidateDetails candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <TalentAlertCandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/employers',
            exact: true,
            component: (props) => <ConsultancyEmployers candidateId={ Number(props.match.params.candidateId) } />
        },
        {
            path: '/dashboard/employers/:employerId',
            exact: true,
            component: (props) => <ConsultancyEmployerDetails employerId={ Number(props.match.params.employerId) } consultancyId={ Number(store.getState().session.consultancyId) } />
        },
        {
            path: '/dashboard/employers/:employerId/contacts/:contactId',
            exact: true,
            component: (props) => (
                <ContactManagement contactId={ Number(props.match.params.contactId) } consultancyId={ Number(store.getState().session.consultancyId) } employerId={ Number(props.match.params.employerId) } />
            )
        }
    ];

    if (store.getState().session.consultancySubscriptionPlanId === TA || store.getState().session.consultancySubscriptionPlanId === TA_PRO) {
        return talentAlertSubscriptionPlanRoutes;
    }

    return [
        ...fullSubscriptionPlanRoutes,
        ...talentAlertSubscriptionPlanRoutes
    ];

};

export default ConsultantRoutes;
