import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { FormGroup, FormControlLabel, Switch, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';

import * as Yup from 'yup';

import CountrySelect from './../CountrySelect';
import { CardButton } from './../LayoutComponents/CardButton';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1)
		}
  },
  autocompleteTextField: {
    width: '100%'
  }
}));

const CreateContactForm = ({ employerId = null, employerOptions = [], onSubmit }) => {

  const classes = useStyles();

  const [t] = useTranslation(['campaigns', 'common', 'validation']);

  const [shouldCreateEmployer, setShouldCreateEmployer] = useState(false);
  const [selectedEmployerId, setSelectedEmployerId] = useState(null);

  const employertDetailsSchema = Yup.object({
    employerName: Yup.string().required(t('validation:employerNameIsRequired')).max(64),
    country: Yup.object().required(t('validation:countryIsRequired'))
  });

  const contactDetailsSchema = Yup.object({
    jobTitle: Yup.string().required(t('validation:jobTitleIsRequired')).max(32),
    firstName: Yup.string().required(t('validation:firstNameIsRequired')).max(32),
    lastName: Yup.string().required(t('validation:lastNameIsRequired')).max(32),
    email: Yup.string().email().required(t('validation:emailIsRequired')).max(64),
    salutation: Yup.string().max(10),
    telephoneNumber: Yup.string()
  });

  const employerDetailsForm = useFormik({
    initialValues: {
      employerName: '',
      country: null
    },
    validationSchema: employertDetailsSchema
  });

  const contactDetailsForm = useFormik({
    initialValues: {
      jobTitle: '',
      firstName: '',
      lastName: '',
      email: '',
      salutation: '',
      telephoneNumber: ''
    },
    validationSchema: contactDetailsSchema,
    onSubmit: (values) => {
      const employerDetails = {
        employerName: employerDetailsForm.values.employerName,
        countryId: employerDetailsForm.values.country?.id
      };

      const contactDetails = {
        jobTitle: values.jobTitle,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email
      };

      if (values.salutation) {
        contactDetails.salutation = values.salutation;
      }

      if (values.telephoneNumber) {
        contactDetails.telephoneNumber = values.telephoneNumber;
      }

      if (!shouldCreateEmployer) {
        contactDetails.employerId = selectedEmployerId;
      }

      const data = {
        contactDetails,
        shouldCreateEmployer
      };

      if (shouldCreateEmployer) {
        data.employerDetails = employerDetails;
      }

      onSubmit(data);
    }
  });

  useEffect(() => {
    if (employerId) {
      setSelectedEmployerId(employerId);
    }
  }, [employerId]);

  const handleCountryChange = (country) => {
    employerDetailsForm.setValues({
      ...employerDetailsForm.values,
      country: country
    });
  };

  const employerIdSelect = (event, value) => {
    setSelectedEmployerId(value.id);
  };

  return (
    <>
      {
        !employerId && (
          <>
            <div className={ classes.wrapper }>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={ shouldCreateEmployer }
                      onChange={ (e) => setShouldCreateEmployer(e.target.checked) } />
                  }
                  label={
                    <Typography variant="h5">
                      { t("common:createEmployer") }
                    </Typography>
                  } />
              </FormGroup>
            </div>
          </>
        )
      }

      {
        !employerId && shouldCreateEmployer && (
          <>
            <form>
              <div className={ classes.wrapper }>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="employerName"
                  label={ t("common:employerName") }
                  value={ employerDetailsForm.values.employerName }
                  error={  employerDetailsForm.touched.employerName && !!employerDetailsForm.errors.employerName }
                  helperText={ employerDetailsForm.touched.employerName ? employerDetailsForm.errors.employerName : null }
                  onChange={ employerDetailsForm.handleChange }
                  onBlur={ employerDetailsForm.handleBlur } />

                <CountrySelect value={ employerDetailsForm.values.country } handleCountryChange={ handleCountryChange } />
              </div>
            </form>
          </>
        )
      }

      <form onSubmit={ contactDetailsForm.handleSubmit }>
        <div className={ classes.wrapper }>
          {
            !employerId && !shouldCreateEmployer && (
              <>
                <Autocomplete
                  id="selectEmployerIdAutocomplete"
                  options={ employerOptions }
                  getOptionLabel={ (o) => o.employerName }
                  onChange={ employerIdSelect }
                  renderInput={ (params) => (
                    <TextField
                      { ...params }
                      variant="outlined"
                      className={ classes.autocompleteTextField }
                      label={ t("campaigns:selectEmployer") } />
                  ) } />
              </>
            )
          }

          <TextField
            fullWidth
            variant="outlined"
            name="jobTitle"
            label={ t("common:jobTitleLabel") }
            value={ contactDetailsForm.values.jobTitle }
            error={  contactDetailsForm.touched.jobTitle && !!contactDetailsForm.errors.jobTitle }
            helperText={ contactDetailsForm.touched.jobTitle ? contactDetailsForm.errors.jobTitle : null }
            onChange={ contactDetailsForm.handleChange }
            onBlur={ contactDetailsForm.handleBlur } />

          <TextField
            fullWidth
            variant="outlined"
            name="firstName"
            label={ t("common:firstNameLabel") }
            value={ contactDetailsForm.values.firstName }
            error={ contactDetailsForm.touched.firstName && !!contactDetailsForm.errors.firstName }
            helperText={ contactDetailsForm.touched.firstName ? contactDetailsForm.errors.firstName : null }
            onChange={ contactDetailsForm.handleChange }
            onBlur={ contactDetailsForm.handleBlur } />

          <TextField
            fullWidth
            variant="outlined"
            name="lastName"
            label={ t("common:lastNameLabel") }
            value={ contactDetailsForm.values.lastName }
            error={ contactDetailsForm.touched.lastName && !!contactDetailsForm.errors.lastName }
            helperText={ contactDetailsForm.touched.lastName ? contactDetailsForm.errors.lastName : null }
            onChange={ contactDetailsForm.handleChange }
            onBlur={ contactDetailsForm.handleBlur } />

          <TextField
            fullWidth
            variant="outlined"
            name="salutation"
            label={ t("common:salutationLabel") }
            value={ contactDetailsForm.values.salutation }
            error={ contactDetailsForm.touched.salutation && !!contactDetailsForm.errors.salutation }
            helperText={ contactDetailsForm.touched.salutation ? contactDetailsForm.errors.salutation : null }
            onChange={ contactDetailsForm.handleChange }
            onBlur={ contactDetailsForm.handleBlur } />

          <TextField
            fullWidth
            variant="outlined"
            name="email"
            label={ t("common:emailLabel") }
            value={ contactDetailsForm.values.email }
            error={ contactDetailsForm.touched.email && !!contactDetailsForm.errors.email }
            helperText={ contactDetailsForm.touched.email ? contactDetailsForm.errors.email : null }
            onChange={ contactDetailsForm.handleChange }
            onBlur={ contactDetailsForm.handleBlur } />

          <TextField
            fullWidth
            variant="outlined"
            name="telephoneNumber"
            label={ t("common:telephoneLabel") }
            value={ contactDetailsForm.values.telephoneNumber }
            error={ contactDetailsForm.touched.telephoneNumber && !!contactDetailsForm.errors.telephoneNumber }
            helperText={ contactDetailsForm.touched.telephoneNumber ? contactDetailsForm.errors.telephoneNumber : null }
            onChange={ contactDetailsForm.handleChange }
            onBlur={ contactDetailsForm.handleBlur } />
        </div>

        <CardButton
          addMargin
          btnVariant="contained"
          btnLabel={ t("common:create&add") }
          btnDisabled={
            shouldCreateEmployer ?
            !contactDetailsForm.isValid || !employerDetailsForm.isValid || !contactDetailsForm.touched.jobTitle || !employerDetailsForm.touched.employerName :
            !contactDetailsForm.isValid || !contactDetailsForm.touched.jobTitle || !selectedEmployerId
          } />
      </form>
    </>
  );
};

export default CreateContactForm;
