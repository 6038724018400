import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { TextField, Button, colors } from "@material-ui/core";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import CountrySelect from "src/components/CountrySelect";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
}));

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function CreateCandidateModal({ onSuccess, consultancyId, open, handleClose }) {
  const classes = useStyles();

  const [t] = useTranslation(["candidates", "common"]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      consultancyId,
      country: {},
    },
    touched: {},
    errors: {},
  });
  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const clearState = () => {
    setFormState(() => ({
      isValid: false,
      values: {
        consultancyId,
        country: {},
      },
      touched: {},
      errors: {},
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    
    onSuccess(formState.values);

    clearState();
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return (
    <ModalFormWrapper
      formTitle={t("candidates:createCandidate")}
      handleOpen={open}
      handleClose={handleClose}
    >
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <TextField
            fullWidth
            label={t("common:firstNameLabel")}
            name="firstName"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("firstName")}
            value={formState.values.firstName || ""}
            helperText={
              hasError("firstName") ? formState.errors.firstName[0] : null
            }
          />
          <TextField
            fullWidth
            label={t("common:lastNameLabel")}
            name="lastName"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("lastName")}
            value={formState.values.lastName || ""}
            helperText={
              hasError("lastName") ? formState.errors.lastName[0] : null
            }
          />
          <TextField
            fullWidth
            label={t("common:emailLabel")}
            name="email"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("email")}
            value={formState.values.email || ""}
            helperText={hasError("email") ? formState.errors.email[0] : null}
          />
          <TextField
            fullWidth
            label={t("common:postcodeLabel")}
            name="postalCode"
            variant="outlined"
            onChange={handleChange}
            error={hasError("postalCode")}
            value={formState.values.postalCode || ""}
            helperText={
              hasError("postalCode") ? formState.errors.postalCode[0] : null
            }
          />
          <CountrySelect
            handleCountryChange={handleCountryChange}
            value={formState.values.country}
          />
        </div>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          {t("common:create")}
        </Button>
      </form>
    </ModalFormWrapper>
  );
}
export default CreateCandidateModal;
