import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "src/actions";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as campaignApi from "src/api/campaignApi";
import { useTranslation } from "react-i18next";
import AddCampaignDocumentModal from "./AddCampaignDocumentModal";
import DocumentListCard from "./DocumentListCard";
import AddIcon from "@material-ui/icons/Add";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CONTACT, CANDIDATE } from "src/constants/roles";
import DraggableCampaignDocuments from "./DraggableCampaignDocuments";
import { useCampaignOrVacancyTranslationPrefix } from '../../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "20px",
  },
  action: {
    alignSelf: "center",
  },
  documentList: {
    marginTop: theme.spacing(1),
  },
  addDocumentButton: {
    minWidth: 25,
    padding: 2,
    float: "right",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  header: {
    display: "inline",
  },
  topPadding: {
    paddingTop: 10,
  },
  documentsOrderButton: {
    marginTop: 20,
  },
}));

const CampaignDocumentsCard = ({ contactView, candidateView, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(["common", "validation", "campaigns", "tooltips", "vacancies"]);
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const role = useSelector((state) => state.session.roleType);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const { id: campaignId } = useSelector((state) => state.campaign);
  const campaign = useSelector((state) => state.campaign);
  const [documents, setDocuments] = useState(
    rest.document || campaign.campaignDocuments
  );
  const adminContactPreviewMode = useSelector(
    (state) => state?.campaign?.adminContactPreview
  );
  const doShowAddIcon = role !== CANDIDATE && ((!rest?.candidateView || rest?.isSubmitted) && !rest?.guestView);

  const documentsWithUrlPreviewMode = documents?.filter((document) => {
    if (adminContactPreviewMode) {
      return document.url && document?.visibility?.includes("contact"); //this filter required for admin in client preview mode to show only client visible docs
    } else return document;
  });
  const [editDocument, setEditDocument] = useState({});
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);

  const onOpenModal = () => {
    setAddDocumentModalOpen(true);
  };

  const onCloseModal = () => {
    setAddDocumentModalOpen(false);
    setEditDocument({});
  };

  const handleRemove = (id) => {
    return campaignApi
      .deleteCampaignDocument({
        campaignId: campaign.id,
        documentId: id,
        consultancyId,
      })
      .then((result) => {
        if (result)
          dispatch({
            type: actionTypes.REMOVE_SINGLE_CAMPAIGN_DOCUMENT,
            id,
          });
      });
  };

  const onDragEnd = (result) => {
    // return if item was dropped outside
    if (!result.destination) return;
    // return if the item was dropped in the same place
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;

    // get the items array
    const newItems = documents;

    // get the draggedItems
    const draggedItem = newItems[result.source.index];

    // delete the item from source position and insert it to the destination positon
    newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, draggedItem);
    //assign priority to all items
    newItems.forEach((item, index) => (item.priority = index + 1));

    // update state
    setDocuments(newItems);
  };

  const saveDocumentsOrder = () => {
    return campaignApi
      .saveCampaignDocumentsPriorityOrder(consultancyId, campaignId, documents)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: actionTypes.UPDATE_CAMPAIGN_DOCUMENTS_PRIORITY,
            documents: documents,
          });
          enqueueSnackbar(t("snackbar:succesfullySavedDocumentsOrder"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        if (error) {
          enqueueSnackbar(t("snackbar:unableToSaveDocumentsOrder"), {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    let campaignDocuments = rest.documents || campaign.campaignDocuments;
    setDocuments(campaignDocuments);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  return (
    <div className={classes.root}>
      <div>
        <BlockHeader
          headerTooltip={
            candidateView && !contactView
              ? t("tooltips:candidateCampaignDoc")
              : contactView
              ? t("tooltips:contactCampaignDocuments")
              : t("tooltips:addDeleteReorderCampaignDocuments")
          }
          card
          header={t(`${campaignsPrefix}:campaignDocumentsLabel`)}
          icon={ doShowAddIcon && <AddIcon /> }
          btnFunc={onOpenModal}
          tooltip={t("common:addDocument")}
        />
      </div>
      {document.url && (
        <Typography className={classes.topPadding} variant="h5">
          {t(`${campaignsPrefix}:pleaseReadDocuments`)}
        </Typography>
      )}
      <div className={classes.documentList}>
        {documents?.length > 0 ? (
          role !== CONTACT && role !== CANDIDATE && !adminContactPreviewMode ? (
            <DraggableCampaignDocuments
              handleRemove={handleRemove}
              setMethods={{ setEditDocument, setAddDocumentModalOpen }}
              consultancyId={consultancyId}
              documents={documents}
              saveDocumentsOrder={saveDocumentsOrder}
              onDragEnd={onDragEnd}
            />
          ) : !documentsWithUrlPreviewMode?.length ? (
            <Typography variant="h5">
              {t(`${campaignsPrefix}:noCampaignDocumentsLabel`)}
            </Typography>
          ) : (
            documents
              .filter((document) => {
                if (adminContactPreviewMode) {
                  return (
                    document.url && document?.visibility?.includes("contact")
                  ); //this filter required for admin in client preview mode to show only client visible docs
                }

                return document;
              })
              .map((document) => {
                if ((rest.candidateView && document.url) || !rest.candidateView) {
                  return (
                    <DocumentListCard
                      key={document.id}
                      document={document}
                      onRemove={handleRemove}
                      setMethods={{ setEditDocument, setAddDocumentModalOpen }}
                      consultancyId={consultancyId}
                    />
                  );
                }

                return null;
              })
          )
        ) : (
          <Typography variant="h5">
            {t(`${campaignsPrefix}:noCampaignDocumentsLabel`)}
          </Typography>
        )}
      </div>
      <AddCampaignDocumentModal
        open={addDocumentModalOpen}
        onClose={onCloseModal}
        document={editDocument}
        campaignId={campaignId}
        handleAddDoc={rest.addDocument}
        consultancyId={parseInt(consultancyId)}
        onRemove={handleRemove}
        candidateView={rest.candidateView}
        guestView={rest?.guestView}
      />
    </div>
  );
};

export default CampaignDocumentsCard;
