import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import { Drawer, Divider, List, ListSubheader, Hidden, IconButton, colors, useMediaQuery } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import PropTypes from 'prop-types';

import NavItem from './../../components/NavItem';
import getNavConfig from './navConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: '70px',
    height: 'calc(100% - 64px)'
  },
  drawerClose: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  navigation: {
    overflow: 'auto',
    padding: theme.spacing(0, 2, 2, 2),
    flexGrow: 1
  },
  profile: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  badge: {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
  },
  badgeDot: {
    height: 9,
    minWidth: 9
  },
  onlineBadge: {
    backgroundColor: colors.green[600]
  },
  awayBadge: {
    backgroundColor: colors.orange[600]
  },
  busyBadge: {
    backgroundColor: colors.red[600]
  },
  offlineBadge: {
    backgroundColor: colors.grey[300]
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40
  },
  details: {
    marginLeft: theme.spacing(2)
  },
  moreButton: {
    marginLeft: 'auto',
    color: colors.blueGrey[200]
  },
  talentAlertHide: {
    display: 'none'
  }
}));

const renderNavItems = ({ items, subheader, key, ...rest }) => {
  return (
    <List key={key}>
      {
        subheader && (
          <ListSubheader disableSticky>{subheader}</ListSubheader>
        )
      }

      {
        items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
          []
        )
      }
    </List>
  );
}

const reduceChildRoutes = ({ acc, pathname, item, depth = 0 }) => {
  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.title}>
        {
          renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
          })
        }
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.href}
        label={item.label}
        title={item.title}/>
    );
  }

  return acc;
}

const NavBar = ({ openMobile, onMobileClose, openDesktop, onDesktopClose, className, ...rest }) => {

  const classes = useStyles();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:1600px)');
  const [t] = useTranslation(['newTranslations']);

  const session = useSelector((state) => state.session);

  const navConfig = getNavConfig(session);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    if (openDesktop && !isDesktop) {
      onDesktopClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {
          navConfig.map((list, index) => {
            // Creating translations
            for (const item of list?.items) {
              item.title = t(`newTranslations:${item.translationName}`);
            }

            return renderNavItems({
              items: list.items,
              pathname: location.pathname,
              key: "list_" + index,
            });
          })
        }
      </nav>

      <Divider className={classes.divider} />
    </div>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={ onMobileClose }
          open={ openMobile }
          variant="temporary">
          { content }
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open={ openDesktop }
          variant="persistent">
          <div className={ classes.drawerClose }>
            <IconButton onClick={ onDesktopClose } style={{ fontSize: "20px" }}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

          <Divider />

          { content }
        </Drawer>
      </Hidden>
    </>
  );

}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
