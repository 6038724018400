const isObj = obj => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return true;
  }

  return false;
};

const decodeObj = obj => {
  const decodedObj = { };

  for (const key of Object.keys(obj)) {
    if (Array.isArray(obj[key])) {
      decodedObj[key] = obj[key].map(decodeURI);
      continue;
    }

    decodedObj[key] = decodeURI(obj[key]);
  }

  return decodedObj;
};

const decodeSignalHireData = obj => {
  const decodedObj = { };

  for (const key of Object.keys(obj)) {
    // Handle objects
    if (isObj(obj[key])) {
      decodedObj[key] = decodeObj(obj[key]);
      continue;
    }

    // Handle arrays
    if (Array.isArray(obj[key])) {
      decodedObj[key] = obj[key].map(x => {
        if (isObj(x)) {
          return decodeObj(x);
        }

        return decodeURI(x);
      });

      continue;
    }

    // Handle strings/numbers
    decodedObj[key] = decodeURI(obj[key]);
  }

  return decodedObj;
};

module.exports = { decodeSignalHireData };
