import {
    Card,
    CardContent,
    CircularProgress,
    Typography,
    IconButton,
    TextField,
    Grid,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "src/components/Page";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as ContactApi from "src/api/contactApi";
import {
    CREATE_CAMPAIGN_KCQS,
} from "src/actions/campaignActions";
import SetsKCQSelect from "src/views/Campaigns/CampaignDetail/CampaignKeyCompetencyQuestions/SetsKCQSelect";
import AddToCampaignButton from "src/components/LayoutComponents/AddToCampaignButton";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(6, 2),
        flexGrow: 1,
    },
    card: {
        width: theme.breakpoints.values.md,
        maxWidth: "100%",
        overflow: "visible",
        display: "flex",
        position: "relative",
        "& > *": {
            flexGrow: 1,
            flexBasis: "50%",
            width: "50%",
        },
    },
    content: {
        padding: theme.spacing(4, 4, 3, 4),
    },
    question: {
        margin: "10px 0px",
        marginLeft: theme.spacing(1),
        display: "inline",
    },
    questionEdit: {
        marginRight: theme.spacing(2),
    },
    questionWrapper: {
        padding: "10px 5px",
        backgroundColor: "#f5f5f5",
    },
    input: {
        fontSize: "0.83em",
        width: "100%",
        fontWeight: 400,
        lineHeight: 1.7,
        letterSpacing: "0em",
        padding: 0,
    },
    questionButtons: {
        padding: "0px 10px",
    },
    titleHeader: {
        marginBottom: "20px",
    },
    submitButtonContainer: {
        textAlign: "center",
        marginTop: "20px",
    },
    newQuestionWrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        marginBottom: theme.spacing(2)
    },
    categoryTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 2,
        padding: "10px 5px",
        marginTop: "60px",
    },
    icon: {
        height: "20px",
        width: "20px",
    },
    categoryWrapper: {
        padding: "0px 10px",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "5px",
    },
    buttons: {
        textAlign: "center",
        marginTop: "20px",
    },
}));

function ContactCampaignKCQSelection({ contact_access_token }) {
    const classes = useStyles();
    const [t] = useTranslation(["campaigns", "snackbar"]);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();
    const campaign = useSelector((state) => state.campaign);
    const [personalQuestionSetsValues, setPersonalQuestionSetsValues] = useState([]);
    const [newCategoryName, setNewCategoryName] = useState("");
    const [customCategories, setCustomCategories] = useState([]);
    const [newQuestions, setNewQuestions] = useState([]);

    const [personalKCQSets, setPersonalKCQSets] = useState([]);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            categories: campaign.currentCampaignKcq?.questions?.map((category) => {
                return {
                    ...category,
                    newQuestion: "",
                    questions: category.questions.map((question) => {
                        return {
                            originalQuestion: question,
                            newQuestion: question,
                            editing: false,
                        };
                    }),
                };
            }),
        },
        touched: {},
        errors: {},
    });

    const handleSubmitNewTemplatedQuestions = (event) => {
        event.preventDefault();

        const questionArr = [];

        Object.keys(personalQuestionSetsValues).forEach((pSet) => {
            Object.keys(personalQuestionSetsValues[pSet]).forEach((pCategory) => {
                const category = { ...personalKCQSets[pSet].questions[pCategory] }
                category.questions = [];
                Object.keys(personalQuestionSetsValues[pSet][pCategory]).forEach((pQuestion) => {
                    const questionIndex = personalQuestionSetsValues[pSet][pCategory][pQuestion];
                    if (questionIndex) {
                        category.questions.push(personalKCQSets[pSet].questions[pCategory].questions[pQuestion])
                    }
                })
                questionArr.push(category)
            })
        })
        questionArr.push(...customCategories)
        const sendObj = {
            selectFromTemplate: false,
            isValid: false,
            values: {
                questionSetTitle: "",
                selectedQuestions: questionArr,
                inviteContacts: false,
                selectedContact: {
                    email: "",
                },
                createContact: false,
            },
        }
        return ContactApi.assignKCQToCampaign(contact_access_token, sendObj.values)
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar(t("snackbar:kcqAddedToCampaign"), {
                        variant: "success",
                    });
                } else {
                    enqueueSnackbar(t("snackbar:kcqUpdateFailed"), { variant: "error" });
                }
                dispatch({
                    type: CREATE_CAMPAIGN_KCQS,
                    currentCampaignKcq: {
                        name: response.data.name,
                        questions: response.data.questions,
                    },
                });
            })
            .catch((error) => {
                enqueueSnackbar(t("snackbar:kcqUpdateFailed"), { variant: "error" });
            });
    };

    const cancelEditing = (event, categoryIndex, questionIndex) => {
        event.preventDefault();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions[questionIndex].newQuestion =
            tempCategories[categoryIndex].questions[questionIndex].originalQuestion;
        tempCategories[categoryIndex].questions[questionIndex].editing = false;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };

    const handleQuestionChange = (event, categoryIndex, questionIndex) => {
        event.persist();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions[questionIndex].newQuestion =
            event.target.value;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };


    const saveCampaignKCQ = (event, categoryIndex, questionIndex) => {
        event.preventDefault();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions[questionIndex].originalQuestion =
            tempCategories[categoryIndex].questions[questionIndex].newQuestion;
        tempCategories[categoryIndex].questions[questionIndex].editing = false;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };



    const getCampaignKCQSets = (contact_access_token) => {
        return ContactApi.getCampaignSets(contact_access_token)
            .then((response) => {
                // dispatch({
                //     type: CREATE_CAMPAIGN_KCQS,
                //     currentCampaignKcq: response.data.campaignKCQ,
                // });
                const pKCQSets = response.data.personalKcqSets.filter((el) => {
                    return el.contactPreselection;
                })
                setPersonalKCQSets(pKCQSets);
            })
            .catch(() => {
                history.push("/");
                enqueueSnackbar(t("campaigns:invalidContactAccessToken"), {
                    variant: "error",
                });
            });
    };

    useEffect(() => {
        ContactApi.getCampaignKCQ(contact_access_token)
            .then((response) => {
                dispatch({
                    type: CREATE_CAMPAIGN_KCQS,
                    currentCampaignKcq: response.data.campaignKCQ,
                });

            })
            .catch(() => {
                history.push("/");
                enqueueSnackbar(t("campaigns:invalidContactAccessToken"), {
                    variant: "error",
                });
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFormState({
            isValid: false,
            values: {
                categories: campaign.currentCampaignKcq?.questions.map((category) => {
                    return {
                        ...category,
                        newQuestion: "",
                        questions: category.questions.map((question) => {
                            return {
                                originalQuestion: question,
                                newQuestion: question,
                                editing: false,
                            };
                        }),
                    };
                }),
            },
            touched: {},
            errors: {},
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.currentCampaignKcq?.questions]);

    useEffect(() => {
        getCampaignKCQSets(contact_access_token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!personalKCQSets.length) {
        return (
            <div style={{ textAlign: "center", width: "100%", marginTop: "200px" }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <Page
            className={classes.root}
            title={t("campaigns:keyCompetencyQuestionsSelection")}
        >
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    {!formState.values?.categories && (
                        <>
                            <Typography
                                variant="h3"
                                color="primary"
                                className={classes.titleHeader}
                            >

                                {t("campaigns:keyCompetencyQuestionsSelection")}
                            </Typography>
                            <SetsKCQSelect
                                personalQuestionSets={personalKCQSets}
                                parentPropStateChange={setPersonalQuestionSetsValues}
                            />
                            <div className={classes.newQuestionWrapper}>
                                <TextField
                                    fullWidth={true}
                                    multiline
                                    variant="outlined"
                                    placeholder={"Add a new Category"}
                                    value={newCategoryName}
                                    className={classes.questionEdit}
                                    InputProps={{
                                        input: classes.input,
                                    }}
                                    onChange={(textName) => {
                                        setNewCategoryName(textName.currentTarget.value);
                                    }}

                                    inputProps={{ disableUnderline: true }}
                                />
                                <div style={{ float: "right", marginTop: 8 }}>
                                    <ThemeIconButton
                                        disabled={newCategoryName === ''}
                                        icon={<SaveIcon />}
                                        btnFunc={() => {
                                            setCustomCategories([
                                                ...customCategories,
                                                {
                                                    name: newCategoryName,
                                                    questions: []
                                                }
                                            ])
                                            setNewCategoryName('');
                                        }}
                                    />
                                </div>
                            </div>

                            <Grid xs={12}>

                                {customCategories?.map((category, catIndex) => (
                                    <>
                                        <ExpansionPanel
                                            key={"customCat" + catIndex}
                                        >
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                            >
                                                <Typography variant="h5" align="center">

                                                    {category.name}
                                                </Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid className={classes.questionGrid} item xs={12}>
                                                    {category.questions?.map(
                                                        (question, questionIndex) => {
                                                            return (
                                                                <Grid className={classes.row}>

                                                                    <Typography
                                                                        className={classes.question}
                                                                        variant="h5"
                                                                    >
                                                                        {question}
                                                                    </Typography>
                                                                </Grid>
                                                            );
                                                        }
                                                    )}

                                                    <div className={classes.newQuestionWrapper}>
                                                        <TextField
                                                            fullWidth={true}
                                                            multiline
                                                            variant="outlined"
                                                            placeholder={"Add a new Question"}
                                                            value={newQuestions[catIndex]}
                                                            className={classes.questionEdit}
                                                            InputProps={{
                                                                input: classes.input,
                                                            }}
                                                            onChange={(textName) => {
                                                                const cleanQuestions = JSON.parse(JSON.stringify(newQuestions));
                                                                cleanQuestions[catIndex] = textName.currentTarget.value;
                                                                setNewQuestions(cleanQuestions)
                                                            }}

                                                            inputProps={{ disableUnderline: true }}
                                                        />
                                                        <div style={{ float: "right", marginTop: 8 }}>
                                                            <ThemeIconButton
                                                                disabled={(newQuestions[catIndex] === '' || newQuestions[catIndex]===null || newQuestions[catIndex] === undefined)}
                                                                icon={<SaveIcon />}
                                                                btnFunc={() => {
                                                                    category.questions.push(newQuestions[catIndex])
                                                                    const copyCustomCats = JSON.parse(JSON.stringify(customCategories));
                                                                    copyCustomCats[catIndex] = category;
                                                                    setCustomCategories(copyCustomCats);

                                                                    const cleanQuestions = JSON.parse(JSON.stringify(newQuestions));
                                                                    cleanQuestions[catIndex] = "";
                                                                    setNewQuestions(cleanQuestions)
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </>
                                ))}
                            </Grid>
                            <div className={classes.buttons}>

                                <AddToCampaignButton
                                    loading={false}
                                    btnDisabled={personalQuestionSetsValues.length === 0 && (customCategories.length === 0 || customCategories[0]?.questions.length === 0)}
                                    btnFunc={handleSubmitNewTemplatedQuestions}
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                    {formState.values?.categories && (
                        <>
                            <Typography
                                variant="h3"
                                color="primary"
                                className={classes.titleHeader}
                            >

                                {t("campaigns:questionsAddedToCampaign")}
                            </Typography>
                            {formState.values?.categories?.map((category, categoryIndex) => (
                                <>
                                    <Typography variant="h4" className={classes.categoryTitle}>
                                        {category.name}
                                    </Typography>

                                    {category?.questions.map((question, questionIndex) => (
                                        <>
                                            <div className={classes.questionWrapper}>

                                                {!question.editing ? (
                                                    <>
                                                        <Typography variant="h5" className={classes.question}>

                                                            {question.originalQuestion}
                                                        </Typography>
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row-reverse",
                                                            }}
                                                        >
                                                        </span>
                                                    </>
                                                ) : (
                                                    <div style={{ background: "white" }}>
                                                        <TextField
                                                            multiline
                                                            id={questionIndex}
                                                            value={question.newQuestion}
                                                            className={classes.questionEdit}
                                                            InputProps={{
                                                                className: classes.input,
                                                                disableUnderline: true,
                                                            }}
                                                            onChange={(event) =>
                                                                handleQuestionChange(
                                                                    event,
                                                                    categoryIndex,
                                                                    questionIndex
                                                                )
                                                            }
                                                        />
                                                        <span
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "row-reverse",
                                                            }}
                                                        >
                                                            <ThemeIconButton
                                                                icon={<SaveIcon />}
                                                                btnFunc={(event) =>
                                                                    saveCampaignKCQ(
                                                                        event,
                                                                        categoryIndex,
                                                                        questionIndex
                                                                    )
                                                                }
                                                            />
                                                            <IconButton
                                                                className={classes.questionButtons}
                                                                color="primary"
                                                                onClick={(event) =>
                                                                    cancelEditing(event, categoryIndex, questionIndex)
                                                                }
                                                            >
                                                                <ThemeIconButton
                                                                    icon={<ClearIcon />}
                                                                ></ThemeIconButton>
                                                            </IconButton>
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ))}
                                </>
                            ))}
                        </>
                    )}

                </CardContent>
            </Card>
        </Page>
    );
}

export default ContactCampaignKCQSelection;
