import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Grid,
  CardContent,
  Card,
  Typography,
} from "@material-ui/core";
import validate from "validate.js";
import { updateCampaignInformationBlocks } from "src/api/campaignApi";
import { useTranslation } from "react-i18next";
import DocumentVisibilityCheckBox from "./CampaignDocuments/DocumentVisibilityCheckBox";
import { useSnackbar } from "notistack";
import QuillEditor from "src/components/QuillEditor";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { UPDATE_CAMPAIGN_INFORMATION_BLOCKS } from "src/actions";
import parse from "html-react-parser";
import { CANDIDATE } from "src/constants/roles";
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "33%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const schema = {
  title: {
    presence: { allowEmpty: false, message: "is required" },
  },
  content: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function CampaignInformationBlocks({ guestView }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation([
    "common",
    "validation",
    "campaigns",
    "tooltips",
    "vacancies"
  ]);
  const roleType = useSelector((state) => state.session.roleType);
  const { consultancyId } = useSelector((state) => state.session);
  const { campaign, candidateCampaign } = useSelector((state) => state);

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const { enqueueSnackbar } = useSnackbar();

  const campaignInformationBlocks =
    campaign.campaignInformationBlocks ||
    candidateCampaign.campaignInformationBlocks;

  const [formState, setFormState] = useState({
    isValid: false,
    values: [],
    touched: [],
    errors: {},
  });

  const handleChange = (event, index) => {
    let updatedBlocks = formState.values;
    let updatedTouched = formState.touched;
    if (!event.target) {
      updatedBlocks[index].content = event;
      updatedTouched[index].content = true;
    } else {
      updatedBlocks[index][event.target.name] = event.target.value;
      updatedTouched[index][event.target.name] = true;
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: [...updatedBlocks],
      touched: [...updatedTouched],
    }));
  };

  const hasError = (field, index) => {
    if (formState.touched[index]?.[field] && formState.errors[index]?.[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let errors = formState.values.map((block) => {
      return validate(block, schema);
    });
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors.some((error) => typeof error === "object"),
      errors: errors || [],
    }));
  }, [formState.values]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateCampaignInformationBlocks(
      consultancyId,
      campaign.id,
      formState.values
    )
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_CAMPAIGN_INFORMATION_BLOCKS,
            campaignInformationBlocks: formState.values,
          });
          enqueueSnackbar(t("snackbar:updateInformationBlocks"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:failedUpdateInformationBlocks"), {
          variant: "error",
        });
      });
  };

  const handleVisibilityChange = (state) => {
    let { id, visibility } = state;
    let index = id?.split("_")[0];
    let tmpValues = formState.values;
    if (index) tmpValues[index].visibility = visibility;
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: [...tmpValues],
    }));
  };

  const addNewInformationBlock = (event) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: [
        ...prevFormState.values,
        {
          title: "",
          content: "",
          visibility: ["private"],
        },
      ],
      touched: [
        ...prevFormState.touched,
        { title: false, content: false, visibility: false },
      ],
      isValid: false,
    }));
  };

  const removeInformationBlock = (event, index) => {
    event.persist();
    let updatedBlocks = formState.values;
    let updatedTouched = formState.touched;
    updatedBlocks.splice(index, 1);
    updatedTouched.splice(index, 1);

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: updatedBlocks,
      touched: [...updatedTouched],
    }));
  };

  useEffect(() => {
    let touched = [];

    if (campaignInformationBlocks) {
      touched = campaignInformationBlocks.map(() => ({
        title: false, content: false, visibility: false
      }))
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: [...(campaignInformationBlocks || [])],
      touched,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <BlockHeader
        headerTooltip={
          roleType === CANDIDATE
            ? null
            : t("tooltips:manageCampaignInformationBlocks")
        }
        card
        padding
        header={t(`${campaignsPrefix}:campaignInformationBlocks`)}
      />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            {formState.values.map((informationBlock, index) =>
              guestView ? (
                <div>
                  <Typography variant="h3">{informationBlock.title}</Typography>
                  <div
                    class="ql-editor"
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                  >
                    {parse(informationBlock.content || "")}
                  </div>
                </div>
              ) : (
                <Card>
                  <CardContent>
                    <div className={classes.fields}>
                      <TextField
                        fullWidth
                        label={t("campaigns:blockTitle")}
                        name="title"
                        variant="outlined"
                        required
                        value={informationBlock.title}
                        onChange={(event) => handleChange(event, index)}
                        error={hasError("title", index)}
                        helperText={
                          hasError("title")
                            ? t(
                                `validation:${formState.errors[index].title[0]}`
                              )
                            : null
                        }
                        disabled={guestView}
                      />
                      <Grid xs={12}>
                        <QuillEditor
                          value={informationBlock.content}
                          handleChange={(event) => handleChange(event, index)}
                          toolbarOptions={["link"]}
                          disabled={guestView}
                        />
                      </Grid>
                      {!guestView && (
                        <>
                          <Grid xs={12}>
                            <Typography color="primary" variant="h4">
                              {t(
                                "campaigns:campaignDocumentVisibilitySelection"
                              )}
                            </Typography>
                            <DocumentVisibilityCheckBox
                              id={`${index}_visibility`}
                              visibility={informationBlock.visibility}
                              onChange={handleVisibilityChange}
                              itemId={informationBlock.id}
                            />
                          </Grid>
                          <CardButton
                            btnLabel={t("common:remove")}
                            btnFunc={(event) =>
                              removeInformationBlock(event, index)
                            }
                          />
                        </>
                      )}
                    </div>
                  </CardContent>
                </Card>
              )
            )}
          </div>
          {!guestView && (
            <CardButton
              btnLabel={t("common:saveChanges")}
              btnVariant="contained"
              btnLabel2={t("common:add")}
              btnVariant2="outlined"
              btnFunc2={addNewInformationBlock}
            />
          )}
        </form>
      </CardContent>
    </Card>
  );
}
export default CampaignInformationBlocks;
