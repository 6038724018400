import React from "react";
import { Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { localeOptions } from "src/constants/localeSelection";

function LocaleSelect({
	disabled,
	handleLocaleChange,
	consultancyId,
	value,
	...rest
}) {
	const [t] = useTranslation(["languages", "common"]);

	const handleChange = (event, newValue) => {
		event.preventDefault();
		handleLocaleChange(newValue);
	};

	return (
		<>
			<Select
				disabled={disabled}
				variant="outlined"
				id="language"
				label="Language"
				value={value || ""}
				onChange={handleChange}
				style={{
					width: "100%",
				}}
			>
				{localeOptions.map((locale) => (
					<MenuItem value={locale.id}>
						{t(`languages:${locale.language}`)}
					</MenuItem>
				))}
			</Select>
		</>
	);
}
export default LocaleSelect;
