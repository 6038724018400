export const SET_CANDIDATE_CAMPAIGN = "SET_CANDIDATE_CAMPAIGN";
export const UPDATE_CANDIDATE_PROFILE = "UPDATE_CANDIDATE_PROFILE";
export const UPDATE_CANDIDATE_STATUS = "UPDATE_CANDIDATE_STATUS";
export const UPDATE_CAMPAIGN_SCHEDULER = "UPDATE_CAMPAIGN_SCHEDULER";
export const UPDATE_EXECUTIVE_SUMMARY = "UPDATE_EXECUTIVE_SUMMARY";
export const UPDATE_CAREER_HIGHLIGHTS = "UPDATE_CAREER_HIGHLIGHTS";
export const UPDATE_PROFILE_VIDEO = "UPDATE_PROFILE_VIDEO";
export const UPDATE_CANDIDATE_PROFILE_PICTURE =
  "UPDATE_CANDIDATE_PROFILE_PICTURE";
export const UPDATE_CANDIDATE_DWI = "UPDATE_CANDIDATE_DWI";
export const UPDATE_VIDEO_PROCCESSING = "UPDATE_VIDEO_PROCCESSING";
