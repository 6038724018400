import axios from "../utils/axios";

export const getPersonalAssets = () => {
  return axios.get("/Assets/getPersonalAssets")
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error("Failed to retrieve personal files");
      }
    })
    
};

export const deleteAsset = (id) => {
  return axios.delete("/Assets/deleteAsset", {
      params: {
        id
      }
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error("Failed to delete asset");
      }
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.message
      ) {
        switch (error.response.data.error.message.split("__")[0]) {
          case "Cannot delete asset being used as consultancy logo":
            let customError = Error(error.response.data.error.message.split("__")[0]);
            customError.location = error.response.data.error.message.split("__")[1]
            throw customError ;
          default:
            throw error.response.data.error.message;
        }
      }
      console.error(error);
      throw new Error("Network Error");
    });
}
