import {
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
  Tooltip,
  Switch,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";
import DefaultKCQSelect from "./DefaultKCQSelect";
import AddToCampaignButton from "src/components/LayoutComponents/AddToCampaignButton";

import InfoIcon from "@material-ui/icons/Info";

import * as modulesApi from './../../../../api/modulesApi';

const useStyles = makeStyles((theme) => ({
  question: {
    margin: "10px 0px",
    display: "inline",
  },
  questionEdit: {
    margin: "10px 0px",
    display: "inline",
  },
  questionWrapper: {
    backgroundColor: "#f5f5f5",
    padding: "10px 5px",
    marginTop: theme.spacing(1),
  },
  input: {
    fontSize: "0.83em",
    width: "100%",
    fontWeight: 400,
    lineHeight: 1.7,
    letterSpacing: "0em",
    padding: 0,
  },
  questionButtons: {
    padding: "0px 10px",
  },
  newQuestionWrapper: {
    margin: 0,
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
  },
  newQuestion: {
    display: "inline",
    width: "100%",
  },
  categoryTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 2,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  buttons: {
    textAlign: "center",
    marginTop: "20px",
  },
  tooltip: { marginLeft: "10px", marginTop: "2px" },
  tooltipText: {
    textAlign: "center",
    padding: 0,
    margin: 0,
  },
}));

function ExistingCampaignKCQModal({
  startOver,
  onUpdateKCQs,
  open,
  handleClose,
  onSuccess,
}) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common", "tooltips"]);
  const campaign = useSelector((state) => state.campaign);
  const { consultancyId } = useSelector((state) => state.session);
  const talentAlert = useSelector((state) => state.talentAlert);
  const [deletable, setDeletable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  //TODO: Disable the ability to edit or delete questions if a candidate has already answered, currently blocked as candidate profile kcq isn't setup yet

  const [formState, setFormState] = useState({ });

  let addNewTemplatedKCQsDefaultState = {
    selectFromTemplate: false,
    isValid: false,
    values: {
      questionSetTitle: "",
      selectedQuestions: [],
      inviteContacts: false,
      selectedContact: {
        email: "",
      },
      createContact: false,
    },
    touched: false,
  };

  const [newTemplatedKCQs, setNewTemplatedKCQs] = useState(
    addNewTemplatedKCQsDefaultState
  );

  const [questionEditMode, setQuestionEditMode] = useState(false);
  const [kcqCategories, setKcqCategories] = useState([]);

  useEffect(() => {
    if (talentAlert.isTalentAlertCampaign) {
      const values = {
        categories: talentAlert.currentTalentAlertKCQ?.questions.map((category) => {
          return {
            ...category,
            newQuestion: '',
            questions: category.questions.map((question) => {
              return {
                originalQuestion: question,
                newQuestion: question,
                editing: false
              };
            })
          };
        })
      };

      setFormState({
        isValid: false,
        values,
        touched: {},
        errors: {}
      });

      addNewTemplatedKCQsDefaultState.values.selectedQuestions = values;

      modulesApi.getKCQCategories(consultancyId)
        .then((response) => {
          setKcqCategories(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }

    setKcqCategories(campaign.kcqCategories);

    const categories = campaign.currentCampaignKcq?.questions.map((category) => {
      return {
        ...category,
        newQuestion: "",
        questions: category.questions.map((question) => {
          return {
            originalQuestion: question,
            newQuestion: question,
            editing: false
          };
        })
      };
    });

    setFormState({
      isValid: false,
      values: {
        categories
      },
      touched: {},
      errors: {}
    });

    addNewTemplatedKCQsDefaultState.values.selectedQuestions = categories;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = () => {
    setNewTemplatedKCQs(addNewTemplatedKCQsDefaultState);
    handleClose();
  };

  const handleSelectTemplateChange = () => {
    setNewTemplatedKCQs((prevValues) => ({
      ...prevValues,
      selectFromTemplate: !prevValues.selectFromTemplate,
    }));
  };

  const handleEditQuestion = (event, categoryIndex, questionIndex) => {
    setQuestionEditMode(true);
    event.preventDefault();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].questions[questionIndex].editing = true;
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        categories: [...tempCategories],
      },
    }));
  };

  const cancelEditing = (event, categoryIndex, questionIndex) => {
    setQuestionEditMode(false);
    event.preventDefault();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].questions[questionIndex].newQuestion =
      tempCategories[categoryIndex].questions[questionIndex].originalQuestion;
    tempCategories[categoryIndex].questions[questionIndex].editing = false;
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        categories: [...tempCategories],
      },
    }));
  };

  const handleQuestionChange = (event, categoryIndex, questionIndex) => {
    event.persist();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].questions[questionIndex].newQuestion =
      event.target.value;
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        categories: [...tempCategories],
      },
    }));
  };

  const handleNewQuestionInput = (event, categoryIndex) => {
    event.persist();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].newQuestion = event.target.value;
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        categories: [...tempCategories],
      },
    }));
  };

  const saveCampaignKCQ = (event, categoryIndex, questionIndex) => {
    setQuestionEditMode(false);
    event.preventDefault();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].questions[questionIndex].editing = false;
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        categories: [...tempCategories],
      },
    }));
    let categories = tempCategories.map((tempCategory) => {
      return {
        ...tempCategory,
        questions: tempCategory.questions.map((question) => {
          return question.newQuestion;
        }),
      };
    });
    onUpdateKCQs(categories);
  };

  const saveNewCampaignKCQ = (event, categoryIndex) => {
    setIsLoading(true);
    event.preventDefault();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].questions.push({
      newQuestion: tempCategories[categoryIndex].newQuestion,
    });
    let categories = tempCategories.map((tempCategory) => {
      return {
        ...tempCategory,
        questions: tempCategory.questions.map((question) => {
          return question.newQuestion;
        }),
      };
    });

    onUpdateKCQs(categories).then(() => setIsLoading(false));
  };

  const deleteCampaignKCQ = (event, categoryIndex, questionIndex) => {
    event.preventDefault();
    let tempCategories = [...formState.values.categories];
    tempCategories[categoryIndex].questions.splice(questionIndex, 1);

    if (!tempCategories[categoryIndex].questions.length) {
      tempCategories.splice(categoryIndex, 1);
    }

    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        categories: [...tempCategories],
      },
    }));
    let categories = tempCategories.map((tempCategory) => {
      return {
        ...tempCategory,
        questions: tempCategory.questions.map((question) => {
          return question.newQuestion;
        }),
      };
    });
    onUpdateKCQs(categories);
  };

  useEffect(() => {
    if (talentAlert.isTalentAlertCampaign) {
      setFormState({
        isValid: false,
        values: {
          categories: talentAlert.currentTalentAlertKCQ?.questions.map((category) => ({
            ...category,
            newQuestion: '',
            questions: category.questions.map((question) => ({
              originalQuestion: question,
              newQuestion: question,
              editing: false
            }))
          }))
        },
        touched: { },
        errors: { }
      });

      return;
    }

    setFormState({
      isValid: false,
      values: {
        categories: campaign.currentCampaignKcq?.questions.map((category) => {
          return {
            ...category,
            newQuestion: "",
            questions: category.questions.map((question) => {
              return {
                originalQuestion: question,
                newQuestion: question,
                editing: false,
              };
            }),
          };
        }),
      },
      touched: {},
      errors: {},
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign.currentCampaignKcq?.questions, talentAlert.currentTalentAlertKCQ?.questions]);

  useEffect(() => {
    if (formState.values && formState.values.questions?.length <= 3) {
      setDeletable(false);
    } else {
      setDeletable(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const handleStartOver = (event) => {
    startOver();
  };

  const handleAddNewTemplatedQuestions = (selectedQuestionSet) => {
    setNewTemplatedKCQs((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedQuestions: [...selectedQuestionSet],
      },
      touched: true,
    }));
  };

  const handleSubmitNewTemplatedQuestions = (event) => {
    event.preventDefault();
    setIsLoading(true);
    return onSuccess(newTemplatedKCQs.values).then(() => {
      setIsLoading(false);
      setNewTemplatedKCQs({
        isValid: false,
        values: {
          inviteContacts: false,
          selectedContact: {
            email: "",
          },
          questionSetTitle: "",
          selectedQuestions: [],
          createContact: false,
        },
        touched: {},
      });
      resetForm();
    });
  };

  useEffect(() => {
    if (newTemplatedKCQs.values.selectedQuestions?.length) {
      setNewTemplatedKCQs((prevValues) => ({
        ...prevValues,
        isValid: true,
      }));
    } else {
      setNewTemplatedKCQs((prevValues) => ({
        ...prevValues,
        isValid: false,
      }));
    }
  }, [newTemplatedKCQs.values]);

  return (
    <ModalFormWrapper
      formTitle={t("campaigns:campaignKcqTitle")}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth={"sm"}
    >
      {formState.values?.categories?.map((category, categoryIndex) => (
        <>
          <Typography variant="h4" className={classes.categoryTitle}>
            {category.name}
          </Typography>
          {category?.questions.map((question, questionIndex) => (
            <>
              <div className={classes.questionWrapper}>
                {!question.editing ? (
                  <>
                    <Typography variant="h5" className={classes.question}>
                      {question.originalQuestion}
                    </Typography>
                    <span
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <div>
                        <ThemeIconButton
                          marginRight
                          icon={<EditIcon />}
                          btnFunc={(event) =>
                            handleEditQuestion(
                              event,
                              categoryIndex,
                              questionIndex
                            )
                          }
                        />
                        <ThemeIconButton
                          icon={<DeleteIcon />}
                          btnFunc={(event) =>
                            deleteCampaignKCQ(
                              event,
                              categoryIndex,
                              questionIndex
                            )
                          }
                          btnDisabled={!deletable}
                        />
                      </div>
                    </span>
                    {/* <IconButton
												onClick={(event) =>
													handleEditQuestion(
														event,
														categoryIndex,
														questionIndex
													)
												}
												className={classes.questionButtons}
												color="primary"
											>
												<EditIcon  />
											</IconButton> */}
                    {/* <IconButton
												onClick={(event) =>
													deleteCampaignKCQ(event, categoryIndex, questionIndex)
												}
												className={classes.questionButtons}
												color="primary"
												disabled={!deletable}
											>
												<DeleteIcon  />
											</IconButton> */}
                    {/* </div> */}
                  </>
                ) : (
                  <div style={{ background: "white" }}>
                    <TextField
                      multiline
                      id={questionIndex}
                      value={question.newQuestion}
                      className={classes.questionEdit}
                      InputProps={{
                        className: classes.input,
                        disableUnderline: true,
                      }}
                      onChange={(event) =>
                        handleQuestionChange(
                          event,
                          categoryIndex,
                          questionIndex
                        )
                      }
                    />
                    <span
                      style={{ display: "flex", flexDirection: "row-reverse" }}
                    >
                      <ThemeIconButton
                        icon={<SaveIcon />}
                        btnFunc={(event) =>
                          saveCampaignKCQ(event, categoryIndex, questionIndex)
                        }
                      />
                      <IconButton
                        className={classes.questionButtons}
                        color="primary"
                        onClick={(event) =>
                          cancelEditing(event, categoryIndex, questionIndex)
                        }
                      >
                        <ThemeIconButton icon={<ClearIcon />}></ThemeIconButton>
                      </IconButton>
                    </span>
                  </div>
                )}
              </div>
            </>
          ))}
          {!newTemplatedKCQs.selectFromTemplate && !questionEditMode && (
            <div className={classes.newQuestionWrapper}>
              <div className={classes.newQuestion}>
                <TextField
                  multiline
                  fullWidth={true}
                  variant="outlined"
                  placeholder={t("campaigns:kcqAddNewQuestion")}
                  value={category.newQuestion}
                  className={classes.questionEdit}
                  InputProps={{
                    input: classes.input,
                  }}
                  onChange={(event) =>
                    handleNewQuestionInput(event, categoryIndex)
                  }
                  inputProps={{ disableUnderline: true }}
                />
                <div className={classes.buttons}>
                  <AddToCampaignButton
                    loading={isLoading && category.newQuestion}
                    btnFunc={(event) =>
                      saveNewCampaignKCQ(event, categoryIndex)
                    }
                    btnDisabled={!category.newQuestion || isLoading}
                    fullWidth
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
      {!questionEditMode && (
        <>
          <FormControlLabel
            style={{ margin: "10px 0" }}
            control={
              <Switch
                checked={newTemplatedKCQs.selectFromTemplate}
                onChange={handleSelectTemplateChange}
                name="selectFromTemplate"
                color="primary"
              />
            }
            label={
              <Typography variant="h5">
                {t("modules:selectFromTemplateKcqs")}
              </Typography>
            }
          />

          <Tooltip
            enterTouchDelay={1}
            classes={{
              tooltip: classes.tooltipText,
            }}
            className={classes.tooltip}
            title={t(`tooltips:addFromLibrary`)}
          >
            <InfoIcon />
          </Tooltip>
        </>
      )}

      {newTemplatedKCQs.selectFromTemplate && !questionEditMode && (
        <Grid item xs={12}>
          <DefaultKCQSelect
            padding={false}
            categories={kcqCategories}
            handleQuestionChange={handleAddNewTemplatedQuestions}
          />
          <div className={classes.buttons}>
            <AddToCampaignButton
              loading={isLoading}
              btnFunc={handleSubmitNewTemplatedQuestions}
              btnDisabled={!newTemplatedKCQs.touched || isLoading}
              fullWidth
            />
          </div>
        </Grid>
      )}
      <CardButton
        btnFunc={handleStartOver}
        btnLabel={t("campaigns:startOver")}
      />
    </ModalFormWrapper>
  );
}
export default ExistingCampaignKCQModal;
