import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const ContactCandidateExecutiveSummary = ({ candidateExecutiveSummary }) => {
	const [t] = useTranslation(["candidates"]);

	return candidateExecutiveSummary ? (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<BlockHeader card header={t("candidates:candidateExecutiveSummary")} />
			</Grid>

			<Grid item xs={12}>
				<div class="ql-editor" style={{ backgroundColor: "#f5f5f5" }}>
					{parse(candidateExecutiveSummary || "")}
				</div>
			</Grid>
		</Grid>
	) : (
		<Grid item xs={12}>
			<Typography variant="h5">
				{t("candidates:candidateExecutiveSummaryNotAvailable")}
			</Typography>
		</Grid>
	);
};

export default ContactCandidateExecutiveSummary;
