import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Button,
  Grid,
  Menu,
  MenuItem,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import { Filter as FilterIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { SELECTED_CONSULTANCY } from 'src/actions';

const useStyles = makeStyles((theme) => ({
  root: {},
  actionIcon: {
    marginRight: theme.spacing(1),
  },
}));

function ConsultancyFilter({ className, activeConsultancies }) {
  const dispatch = useDispatch();
  const [t] = useTranslation(['common']);
  const stateConsultancy = useSelector(
    (state) => state.analytics.selectedConsultancy
  );
  const selectAllConsultanciesOption = {
    name: t('common:allConsultanciesLabel'),
  };
  const consultancyFilterOptions = [
    selectAllConsultanciesOption,
    ...activeConsultancies,
  ];

  const classes = useStyles();
  const actionRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [selectedConsultancy, setSelectedConsultancy] = useState(
    stateConsultancy
  );

  const setConsultancy = (consultancy) => {
    dispatch({
      type: SELECTED_CONSULTANCY,
      payload: consultancy,
    });
  };

  useEffect(() => {
    setConsultancy(selectedConsultancy);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConsultancy]);

  return (
    <Grid
      container
      spacing={3}
      justify='space-between'
      className={clsx(classes.root, className)}
    >
      <Grid item>
        <Button ref={actionRef} onClick={() => setMenuOpen(true)}>
          <SvgIcon fontSize='small' className={classes.actionIcon}>
            <FilterIcon />
          </SvgIcon>
          {selectedConsultancy.name}
        </Button>
        <Menu
          anchorEl={actionRef.current}
          onClose={() => setMenuOpen(false)}
          open={isMenuOpen}
          PaperProps={{ className: classes.menu }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {consultancyFilterOptions?.map((consultancy) => (
            <MenuItem
              key={`${consultancy.id}${consultancy.name}`}
              onClick={() => setSelectedConsultancy(consultancy)}
            >
              {consultancy.name}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
}

ConsultancyFilter.propTypes = {
  className: PropTypes.string,
};

export default ConsultancyFilter;
