import * as actionTypes from 'src/actions';

const today = new Date();
const priorDate = new Date().setDate(today.getDate() - 30);

const initialState = {
  consultancyDetails: null,
  dateFrom: new Date(priorDate),
  dateTo: today,
};

const consultancyReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.UPDATE_CONSULTANCY: {
      return {
        ...state,
        consultancyDetails: action.consultancyDetails
      }
    }
    case actionTypes.UPDATE_DATE_FROM: {
      return {
        ...state,
        dateFrom: action.payload,
      };
    }
    case actionTypes.UPDATE_DATE_TO: {
      return {
        ...state,
        dateTo: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default consultancyReducer;
