import { makeStyles, useTheme } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { UPDATE_CANDIDATE_DWI } from "src/actions/candidateCampaignActions";
import * as ContactApi from "src/api/contactApi";
import {
  Typography,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Slider,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  markerLabel: {
    fontSize: "0.7em",
    marginTop: "7px",
  },
}));
const mobileSliderMarks = [
  {
    value: 0,
    label: "Inadequate",
  },
  {
    value: 2,
    label: "",
  },
  {
    value: 4,
    label: "",
  },
  {
    value: 6,
    label: "",
  },
  {
    value: 8,
    label: "",
  },
  {
    value: 10,
    label: "Outstanding",
  },
];

const desktopSliderMarks = [
  {
    value: 0,
    label: "Inadequate",
  },
  {
    value: 2,
    label: "Poor",
  },
  {
    value: 4,
    label: "Good",
  },
  {
    value: 6,
    label: "Very Good",
  },
  {
    value: 8,
    label: "Excellent",
  },
  {
    value: 10,
    label: "Outstanding",
  },
];

function ContactCandidateDWI() {
  const classes = useStyles();
  const [t] = useTranslation(["common", "candidates", "snackbar"]);
  const candidateCampaign = useSelector((state) => state.candidateCampaign);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const theme = useTheme();
  const smallScreenSize = useMediaQuery(theme.breakpoints.down("md"));
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...candidateCampaign.candidateProfile.candidateSuitabilityScores,
    },
    touched: {},
    errors: {},
  });

  const onSliderChange = (event, value, dwiCategoryIndex, subcategoryIndex) => {
    let tempCategories = [...formState.values.dwiScores];
    tempCategories[dwiCategoryIndex].subcategories[
      subcategoryIndex
    ].score = value;
    let tempValue = 0;
    tempCategories.forEach((dwiCategory) => {
      let totalSubCategoryWeightedScores = 0;
      dwiCategory.subcategories.forEach((subcategory) => {
        let score = Number(subcategory.score);
        let weight = Number(subcategory.weight / 10);
        let calculateSubCategoryScore = score * weight;
        totalSubCategoryWeightedScores += calculateSubCategoryScore;
      });
      let categoryWeight = Number(dwiCategory.weight / 100);
      let calculatedCategoryScore =
        totalSubCategoryWeightedScores * categoryWeight;
      tempValue += calculatedCategoryScore;
    });
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        dwiScores: tempCategories,
        totalScore: tempValue.toFixed(2),
      },
    }));
  };

  const isDesktop = useMediaQuery("(min-width:1600px)");

  const onCandidateDWIScoresSubmitted = (event) => {
    event.preventDefault();
    return ContactApi.submitCandidateDWIScores(
      formState.values,
      consultancyId,
      candidateCampaign.candidateProfile.campaignId,
      candidateCampaign.candidateProfile.candidateId
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:candidateDWISuccess"), {
          variant: "success",
        });

        dispatch({
          type: UPDATE_CANDIDATE_DWI,
          teamSuitabilityScores: response.data,
        });

      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:candidateDWIFailure"), {
          variant: "success",
        });
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t("candidates:candidateSuitabilityScore")}:{" "}
          {formState.values.totalScore}%
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {formState.values.dwiScores?.map((dwiScore, dwiCategoryIndex) => (
          <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h5">{dwiScore.name}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container spacing={3}>
                {dwiScore.subcategories?.map(
                  (subcategory, subcategoryIndex) => (
                    <>
                      <Grid item xs={4} style={{ margin: "20px 0px" }}>
                        <Typography variant="subtitle1">
                          {subcategory.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={8}
                        style={{ padding: "0px 40px", margin: "20px 0px" }}
                      >
                        <Slider
                          classes={{
                            markLabel: classes.markerLabel,
                            markLabelActive: classes.markLabelActive,
                          }}
                          defaultValue={subcategory.score}
                          step={1}
                          max={10}
                          color="primary"
                          onChangeCommitted={(event, value) => {
                            onSliderChange(
                              event,
                              value,
                              dwiCategoryIndex,
                              subcategoryIndex
                            );
                          }}
                          marks={
                            smallScreenSize || !isDesktop
                              ? mobileSliderMarks
                              : desktopSliderMarks
                          }
                          valueLabelDisplay="on"
                        />
                      </Grid>
                    </>
                  )
                )}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))}
      </Grid>
      {formState.values.dwiScores.length ? (
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onCandidateDWIScoresSubmitted}
          >
            {t("candidates:saveCandidateScores")}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default ContactCandidateDWI;
