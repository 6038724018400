import axios from '../utils/axios';

import { availableWordLimits } from './../constants/aiExecutiveSummaryAvailableWordLimits';

export const getCandidateCampaigns = (consultancyId) => {
  return axios.get('/Candidates/getCandidateCampaigns', {
    params: {
      consultancyId,
    },
  });
};

export const acceptCampaignTermsAndConditions = (consultancyId, campaignId) => {
  return axios.post('/Candidates/acceptCampaignTermsAndConditions', {
    consultancyId,
    campaignId,
  });
};

export const getDefaultCandidateProfile = (consultancyId) => {
  return axios.get('/DefaultCandidateProfiles/getDefaultCandidateProfile', {
    params: {
      consultancyId: consultancyId.consultancyId,
    },
  });
};

export const updateDefaultCandidateProfile = (
  {
    salutation,
    firstName,
    lastName,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    country,
  },
  consultancyId
) => {
  return axios.patch(
    '/DefaultCandidateProfiles/updateDefaultCandidateProfile',
    {
      salutation,
      firstName,
      lastName,
      telephone,
      addressLine1,
      addressLine2,
      city,
      postalCode,
      countryId: country.id,
    },
    {
      params: {
        consultancyId,
      },
    }
  );
};

export const getCandidateProfile = (consultancyId, candidateId, campaignId) => {
  return axios.get('/CandidateProfiles/getCandidateProfile', {
    params: {
      consultancyId,
      candidateId,
      campaignId,
    },
  });
};



export const updateCandidateProfile = (
  {
    salutation,
    firstName,
    lastName,
    jobTitle,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    country,
    candidateStatus,
  },
  consultancyId,
  candidateId,
  campaignId
) => {
  return axios.patch(
    '/CandidateProfiles/updateCandidateProfileDetails',
    {
      salutation,
      firstName,
      lastName,
      jobTitle,
      telephone,
      addressLine1,
      addressLine2,
      city,
      postalCode,
      countryId: country.id,
      candidateStatus,
    },
    {
      params: {
        consultancyId,
        candidateId,
        campaignId,
      },
    }
  );
};

export const submitProfile = (consultancyId, candidateId, campaignId) => {
  return axios.patch('/CandidateProfiles/submitProfile', null, {
    params: {
      consultancyId,
      candidateId,
      campaignId,
    },
  });
};

export const updateCandidateKCQAnswers = (
  consultancyId,
  candidateId,
  campaignId,
  questions
) => {
  return axios.patch(
    '/CandidateProfiles/updateCandidateKCQAnswers',
    questions,
    {
      params: {
        consultancyId,
        candidateId,
        campaignId,
      },
    }
  );
};

export const saveNewCandidateComment = (
  consultancyId,
  campaignId,
  candidateProfileId,
  comment
) => {
  return axios.post(
    '/CampaignCandidateComments/createCampaignCandidateComments',
    {
      consultancyId,
      campaignId,
      candidateProfileId,
      comment,
    }
  );
};

export const updateCandidateComment = (
  commentId,
  candidateProfileId,
  consultancyId,
  campaignId,
  updatedComment
) => {
  return axios.put(
    '/CampaignCandidateComments/updateCampaignCandidateComments',
    {
      commentId,
      candidateProfileId,
      consultancyId,
      campaignId,
      updatedComment,
    }
  );
};

export const getCandidateCampaignDetails = (campaignId, consultancyId) => {
  return axios.get('/Candidates/getCandidateCampaignDetails', {
    params: {
      campaignId,
      consultancyId,
    },
  });
};

export const getCandidateTalentAlertDetails = (campaignId, consultancyId) => {
  return axios.get('/CandidateProfiles/getCandidateProfile', {
    params: {
      campaignId,
      consultancyId,
    },
  });
};

export const saveCandidateCampaignDocument = (
  campaignId,
  candidateDocumentId,
  candidateProfileId,
  formData
) => {
  return axios.post(
    '/CandidateProfileDocuments/saveCandidateDocument',
    formData,
    {
      params: {
        campaignId,
        candidateDocumentId,
        candidateProfileId,
      },
    }
  );
};

export const setWelcomeMessagesStatusToRead = () => {
  return axios.post('/Candidates/updateWelcomeMessageStatusRead', {});
};

export const setCandidateWelcomeMessagesStatusToNotRead = (consultancyId) => {
  return axios.post('/Candidates/updateWelcomeMessageStatusNotRead', {
    consultancyId,
  });
};

export const getWelcomeMessagesStatus = () => {
  return axios.get('/Candidates/getWelcomeMessagesStatus', {});
};
export const saveCandidateMcQuaig = ({
  consultancyId,
  campaignId,
  candidateProfileId,
  mcQuaigId,
  mcQuaig,
}) => {
  return axios.post('/CandidateMcQuaigs/saveCandidateMcQuaig', {
    consultancyId,
    campaignId,
    candidateProfileId,
    mcQuaigId,
    mcQuaig,
  });
};

export const getCandidate = ({ consultancyId, candidateId }) => {
  return axios.get('/Candidates/getCandidateDetails', {
    params: {
      consultancyId,
      candidateId,
    },
  });
};

export const uploadDefaultProfilePicture = (
  consultancyId,
  candidateId,
  formData
) => {
  return axios.post(
    '/DefaultCandidateProfiles/uploadDefaultProfilePicture',
    formData,
    {
      params: { consultancyId, candidateId },
    }
  );
};

export const uploadCandidateProfilePicture = (
  campaignId,
  candidateId,
  formData
) => {
  return axios.post(
    '/CandidateProfiles/uploadCandidateProfilePicture',
    formData,
    {
      params: {
        campaignId,
        candidateId,
      },
    }
  );
};

export const uploadProfileVideo = (body, uploadOptions) => {
  return axios
    .post('/CandidateProfiles/uploadProfileVideo', body, uploadOptions)
    .then((resp) => {
      return resp;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const cloudinaryWidgetUploadProfileVideo = (data, uploadOptions) => {
  return axios.post('/CandidateProfiles/cloudinaryWidgetUploadProfileVideo', data, uploadOptions);
};

export const updateVideoRotation = (campaignId, candidateId, rotation) => {
  return axios.put('/CandidateProfiles/updateVideoRotation', {
    campaignId,
    candidateId,
    rotation,
  });
};

export const updateCandidateExecutiveSummary = (
  candidateId,
  consultancyId,
  campaignId,
  summary
) => {
  return axios.post(
    '/CandidateProfiles/updateExecutiveSummary',
    { summary },
    {
      params: { candidateId, consultancyId, campaignId },
    }
  );
};

export const updateDefaultCandidateExecutiveSummary = (
  candidateId,
  consultancyId,
  summary
) => {
  return axios.post(
    '/DefaultCandidateProfiles/updateDefaultExecutiveSummary',
    { summary },
    {
      params: { candidateId, consultancyId },
    }
  );
};

export const updateCandidateCareerHighlights = (
  candidateId,
  consultancyId,
  campaignId,
  highlights
) => {
  return axios.post(
    '/CandidateProfiles/updateCareerHighlights',
    { highlights },
    {
      params: { candidateId, consultancyId, campaignId },
    }
  );
};

export const saveCandidateProfileDetails = (
  campaignId,
  consultancyId,
  candidateProfileDetails
) => {
  return axios.post(
    '/Candidates/saveCandidateProfileDetails',
    candidateProfileDetails,
    {
      params: { campaignId, consultancyId },
    }
  );
};

export const updateDefaultCandidateCareerHighlights = (
  candidateId,
  consultancyId,
  highlights
) => {
  return axios.post(
    '/DefaultCandidateProfiles/updateDefaultCareerHighlights',
    { highlights },
    {
      params: { candidateId, consultancyId },
    }
  );
};

export const removeCandidateFromCampaign = (
  candidateId,
  campaignId,
  consultancyId
) => {
  return axios.delete('/CandidateProfiles/removeCandidateFromCampaign', {
    params: {
      candidateId,
      campaignId,
      consultancyId,
    },
  });
};

export const uploadDefaultCandidateProfilePictureByCandidateId = (
  consultancyId,
  candidateId,
  formData
) => {
  return axios.post(
    '/DefaultCandidateProfiles/uploadDefaultCandidateProfilePictureByCandidateId',
    formData,
    {
      params: { consultancyId, candidateId },
    }
  );
};

export const updateDefaultCandidateProfileByUserId = (
  {
    salutation,
    firstName,
    lastName,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    country,
  },
  consultancyId,
  userId
) => {
  return axios.patch(
    '/DefaultCandidateProfiles/updateDefaultCandidateProfileByUserId',
    {
      salutation,
      firstName,
      lastName,
      telephone,
      addressLine1,
      addressLine2,
      city,
      postalCode,
      countryId: country.id,
    },
    {
      params: {
        consultancyId,
        userId,
      },
    }
  );
};

export const getCandidateWelcomeMessages = (consultancyId) => {
  return axios.get('/DefaultWelcomeMessages/getCandidateWelcomeMessages', {
    params: {
      consultancyId,
    },
  });
};

export const dismissCandidateWelcomeMessage = (consultancyId, messageId) => {
  return axios.post(
    '/DefaultWelcomeMessages/dismissCandidateWelcomeMessage',
    null,
    {
      params: {
        consultancyId,
        messageId,
      },
    }
  );
};

export const changePassword = (candidateId, consultancyId, password) => {
  return axios.post(
    '/Candidates/changePassword',
    { password },
    {
      params: {
        candidateId,
        consultancyId
      }
    }
  );
};

export const isCandidateActivated = (candidateId) => {
  return axios.get(
    '/Candidates/isCandidateActivated',
    {
      params: { candidateId }
    }
  );
};

export const changeEmail = (candidateId, email) => {
  return axios.post(
    '/Candidates/changeEmail',
    { email },
    {
      params: { candidateId }
    }
  );
};

export const updateCandidateProfileStatus = (consultancyId, candidateId, campaignId, candidateStatusId) => {
  return axios.patch(
    '/CandidateProfiles/updateCandidateProfileStatus',
    { candidateStatusId },
    {
      params: {
        consultancyId,
        candidateId,
        campaignId
      }
    }
  )
};

export const generateAIExecutiveSummary = (candidateId, campaignId, consultancyId, isRegenerate = false, wordLimit = availableWordLimits.DEFAULT, anonymize = false) => {
  return axios.get('/CandidateProfiles/generateAIExecutiveSummary', {
    params: {
      candidateId,
      campaignId,
      consultancyId,
      isRegenerate,
      wordLimit,
      anonymize,
    }
  });
};
