import { getCompanyPageData, login, logout } from "../api";
import axios from "../utils/axios";
import * as CookieActions from "./cookieActions";
import { LANGUAGE_CHANGE } from "./sessionActions";
export * from "./notificationActions";
export * from "./sessionActions";
export * from "./campaignActions";
export * from "./mediaDrawerActions";
export * from "./candidateCampaignActions";
export * from "./candidateActions";
export * from "./ConsultancyModules/dwi/dwiActions";
export * from "./ConsultancyModules/kcq/kcqActions";
export * from "./employerActions";
export * from "./talentAlertActions";
export * from "./analyticsActions";
export * from "./themeActions";
export * from "./notificationSettingsActions";
export const UPDATE_CONSULTANCY = "UPDATE_CONSULTANCY";
export const SESSION_LOGIN = "SESSION_LOGIN";
export const SESSION_LOGOUT = "SESSION_LOGOUT";
export const UPDATE_CONSULTANCY_LOGO = "UPDATE_CONSULTANCY_LOGO";
export const UPDATE_CONSULTANCY_SECONDARY_LOGO = "UPDATE_CONSULTANCY_SECONDARY_LOGO";

export const retrieveConsultancyDetails = (dispatch, { host, route }) => {
  return getCompanyPageData(host, route)
    .then((response) => {
      dispatch({ type: LANGUAGE_CHANGE, language: response.data.locale });
      return dispatch({
        type: UPDATE_CONSULTANCY,
        consultancyDetails: response.data,
      });
    })
    .catch((error) => {
      throw error;
    });
};

export const loginAndCreateSession = (dispatch, { email, password, host }) => {
  return login({ email, password, host })
    .then((response) => {
      CookieActions.setCookie("userId", response.data.userId);
      CookieActions.setCookie("accessToken", response.data.id);
      CookieActions.setCookie("role", response.data.role);
      CookieActions.setCookie("consultancyId", response.data.consultancyId);

      if (response.data.consultancyLogo) {
        localStorage.setItem("consultancyLogo", response.data.consultancyLogo);
      }

      if (response.data.consultancySecondaryLogo) {
        localStorage.setItem("consultancySecondaryLogo", response.data.consultancySecondaryLogo);
      }

      if (response.data.profilePicture) {
        localStorage.setItem("profilePicture", response.data.profilePicture);
      }

      CookieActions.setCookie("name", response.data.name);
      CookieActions.setCookie("language", response.data.language);
      CookieActions.setCookie("consultancyName", response.data.consultancyName);
      CookieActions.setCookie("consultancySubscriptionPlanId", response.data.consultancySubscriptionPlanId);

      axios.defaults.headers.common["Authorization"] = response.data.id;

      dispatch({
        type: SESSION_LOGIN,
        userId: response.data.userId,
        consultancyId: response.data.consultancyId,
        profilePicture: response.data.profilePicture,
        consultancyLogo: response.data.consultancyLogo,
        consultancySecondaryLogo: response.data.consultancySecondaryLogo,
        role: response.data.role,
        name: response.data.name,
        consultancyName: response.data.consultancyName,
        language: response.data.language,
        consultancySubscriptionPlanId: response.data.consultancySubscriptionPlanId
      });

      return response.data.consultancySubscriptionPlanId;
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.response &&
        error.response.data.response.message
      ) {
        switch (error.response.data.response.message) {
          case "The consultancy has been disabled.":
          case "Incorrect Email or Password":
          case "Account Deactivated":
            throw new Error(error.response.data.response.message);
          default:
        }
      }

      throw new Error("Network Error");
    });
};

export const logoutAndDeleteSession = (dispatch) => {
  return logout()
    .then((response) => {
      CookieActions.deleteCookie("accessToken");
      CookieActions.deleteCookie("userId");
      CookieActions.deleteCookie("role");
      CookieActions.deleteCookie("consultancyId");
      localStorage.removeItem("consultancyLogo");
      localStorage.removeItem("consultancySecondaryLogo");
      localStorage.removeItem("profilePicture");
      CookieActions.deleteCookie("name");
      CookieActions.deleteCookie("avatar");
      CookieActions.deleteCookie("consultancySubscriptionPlanId");
      axios.defaults.headers.common["Authorization"] = null;
      dispatch({ type: SESSION_LOGOUT });
    })
    .catch((error) => {
      throw error;
    });
};
