import * as actionTypes from "../../../actions";

const initialState = {
    dwiCategories: [],
};

const dwiReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DWI_CATEGORIES: {
            return {
                ...state,
                dwiCategories: action.categories,
            }
        }
        case actionTypes.UPDATE_DWI_CATEGORIES: {
            return {
                ...state,
                dwiCategories: [...action.dwiCategories]
            }
        }
        default: {
            return state;
        }
    }
}

export default dwiReducer;
