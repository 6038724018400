import axios from './../utils/axios';
import * as xss from './../utils/xss';

export const getVideoAssessmentByCampaignId = (campaignId) => {
  return axios.get('/VideoAssessments/getByCampaign', {
    params: {
      campaignId
    }
  });
};

export const createVideoAssessmentWithQuestions = (campaignId, preparationTime, instructions, questions) => {
  const body = {
    campaignId,
    preparationTime,
    instructions: xss.replace(instructions),
    questions
  };

  return axios.post('/VideoAssessments/createWithQuestions', body);
};

export const updateVideoAssessment = (id, preparationTime, instructions, questions) => {
  const body = {
    preparationTime,
    instructions: xss.replace(instructions),
    questions
  };

  return axios.post(`/VideoAssessments/updateVideoAssessment/${id}`, body);
};

export const getVideoAssessmentInterviewByCandidate = (candidateId, videoAssessmentId) => {
  return axios.get('/VideoAssessmentInterviews/getByCandidate', {
    params: {
      candidateId,
      videoAssessmentId
    }
  });
};

export const createVideoAssessmentInterview = (videoAssessmentId, campaignId, candidateId) => {
  const body = {
    videoAssessmentId,
    campaignId,
    candidateId
  };

  return axios.post('/VideoAssessmentInterviews/createInterview', body);
};

export const completeVideoAssessmentInterview = (id) => {
  return axios.post(`/VideoAssessmentInterviews/completeInterview/${id}`);
};

export const removeVideoAssessmentQuestion = (questionId, videoAssessmentId) => {
  return axios.delete('/VideoAssessmentQuestions/removeQuestion', {
    params: {
      id: questionId,
      videoAssessmentId
    }
  });
};

export const createInterviewAnswer = (formData, consultancyId, campaignId) => {
  return axios.post(
    '/VideoAssessmentInterviewAnswers/createInterviewAnswer',
    formData,
    {
      params: {
        campaignId,
        consultancyId
      }
    }
  );
};

export const resetInterview = (campaignId, candidateId, consultancyId) => {
  return axios.delete('/VideoAssessmentInterviews/resetInterview', {
    params: {
      campaignId,
      candidateId,
      consultancyId
    }
  });
};
