import React from 'react';
import { useTranslation } from 'react-i18next';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';

import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import RequiredTalentAlertDocumentListCard from './RequiredTalentAlertDocumentListCard';

import { REQUIRED_CANDIDATE_DOCUMENTS_CONTAINER_ID } from './../../../../constants/dragDropContainerIds';

const useStyles = makeStyles((theme) => ({
  documentList: {
    marginBottom: theme.spacing(4)
  },
  documentsOrderButton: {
    marginTop: 20
  }
}));

const DraggableRequiredCandidateTalentAlertDocuments = ({
  onDragEnd,
  requiredDocuments,
  handleRemove,
  setEditDocument,
  setAddDocumentModalOpen,
  setTextModal,
  consultancyId,
  candidateView,
  isSubmitted,
  saveDocumentsOrder
}) => {

  const [t] = useTranslation(['campaigns']);
  const classes = useStyles();

  const documents = () => {
    return(
      (provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>

          {
            requiredDocuments?.length > 0 && requiredDocuments.map((document, index) => {
                return (
                  <Draggable draggableId={document.id} index={index}>
                    
                    {
                      (provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <RequiredTalentAlertDocumentListCard
                            key={index.toString()}
                            document={document}
                            onRemove={handleRemove}
                            setMethods={{ setEditDocument, setAddDocumentModalOpen, setTextModal }}
                            consultancyId={consultancyId}
                            candidateView={candidateView}
                            isSubmitted={isSubmitted} />
                        </div>
                      )
                    }

                  </Draggable>
                );
              })
            }

          { provided.placeholder }
        </div>
      )
    );
  };
  
  return (
    <div className={classes.documentList}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={REQUIRED_CANDIDATE_DOCUMENTS_CONTAINER_ID}>
          
          {
            documents()
          }

        </Droppable>
      </DragDropContext>
      
      <Button
        className={classes.documentsOrderButton}
        color="primary"
        size="large"
        onClick={saveDocumentsOrder}
        variant="contained"
        fullWidth >
        { t("campaigns:saveDocumentsOrder") }
      </Button>
    </div>
  );

};

export default DraggableRequiredCandidateTalentAlertDocuments;
