import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  colors,
} from "@material-ui/core";
import { saveCandidateCampaignDocument } from "src/api/candidateApi";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import FormFileDropzone from "src/components/FormFileDropzone";
import AssetSelect from "src/components/AssetSelect";
import { useSnackbar } from "notistack";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  fileField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  assetDropdown: {
    flex: 2,
    marginRight: 10,
  },
  fileDropzone: {
    flex: 1,
  },
  subheadingText: {
    width: "100%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
}));

function CandidateDocumentUploadModal({
  profileId,
  campaignId,
  document = {},
  consultancyId,
  onSubmit,
  open,
  onClose,
  ...rest
}) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common"]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const type = "single";
  const [formState, setFormState] = useState({
    values: {
      name: document.name,
      file: [],
      asset: {},
      text: "",
    },
  });

  const candidateCampaign = useSelector((state) => state?.candidateCampaign);

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        id: document.id || "",
        file: document.files || [],
        asset: document.document || {},
        text: document.text || "",
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAssetChange = useCallback((event, asset) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        asset: asset,
      },
      touched: {
        ...prevFormState.touched,
        asset: true,
      },
    }));
  }, []);

  const handleFileChange = useCallback((files) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        file: files,
      },
    }));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    let formData = new FormData();
    if (formState.values.file.length > 0) {
      formData.append("file", formState.values.file[0]);
    } else if (formState.values.asset) {
      formData.append("asset", JSON.stringify(formState.values.asset));
    }
    formData.append("text", formState.values.text);
    saveCandidateCampaignDocument(
      campaignId || candidateCampaign?.campaignId, //extra condition is required for when candidate logged in
      document.id,
      profileId || candidateCampaign?.candidateProfile?.profileId, //extra condition is required for when candidate logged in
      formData
    )
      .then((response) => {
        if (response.data) {
          let { data } = response;
          enqueueSnackbar("Updated document", { variant: "success" });
          onClose();
          setLoading(false);
          data.update = true;
          data.candidateDocument = true;
          onSubmit(data);
        }
      })
      .catch(() => {
        enqueueSnackbar("Error saving document", { variant: "error" });
      });
  };

  return (
    <ModalFormWrapper
      formTitle={document.name}
      handleOpen={open}
      handleClose={onClose}
      editDocument={true}
    >
      <form onSubmit={handleSubmit}>
        <div className={classes.fileField}>
          <AssetSelect
            className={classes.assetDropdown}
            name="asset"
            label={t("campaigns:existingAsset")}
            value={formState.values.asset}
            disabled={
              formState.values.file?.length > 0 ||
              formState.values.text.length > 0
            }
            onChange={handleAssetChange}
          />
          <FormFileDropzone
            className={classes.fileDropzone}
            value={
              formState.values.file.length > 0
                ? formState.values.file
                : formState.values.asset
            }
            type={type}
            edit={document.documentTitle}
            onChange={handleFileChange}
            disabled={formState.values.text.length > 0}
          />
        </div>
        <CardButton
          loading={loading}
          btnLabel={t("campaigns:saveCampaignDocumentLabel")}
          btnFunc={handleSubmit}
          btnDisabled={
            !formState.values.file.length > 0 &&
            !formState.values.asset?.fileName &&
            !formState.values.text.length > 0
          }
        />
      </form>
    </ModalFormWrapper>
  );
}
export default CandidateDocumentUploadModal;
