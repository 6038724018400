import React from "react";
import { makeStyles } from "@material-ui/styles";
import CampaignDocumentsCard from "./CampaignDocumentsCard";
import CampaignKeyCompetencyCard from "../CampaignKeyCompetencyQuestions/CampaignKeyCompetencyCard";
import RequiredCandidateDocuments from "./RequiredCandidateDocuments";
import { Divider } from "@material-ui/core";

import { useSelector } from 'react-redux';

import { KCQ_ID } from './../../../../constants/moduleIds';
import { TA_PRO } from './../../../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
	documentList: {
		marginBottom: theme.spacing(4),
	},
}));

function CampaignDocuments({
	campaignRequiredDocuments,
	campaignModuleIds,
	guestView,
}) {

	const classes = useStyles();

	const { consultancySubscriptionPlanId } = useSelector((state) => state.session);

	return (
		<>
			<div className={classes.documentList}>
				<CampaignDocumentsCard guestView={guestView} />
				<Divider />
			</div>
			<div className={classes.documentList}>
				<RequiredCandidateDocuments
					campaignRequiredDocuments={campaignRequiredDocuments}
					guestView={guestView}
				/>

				{
					campaignModuleIds?.includes(KCQ_ID) && (
						<Divider />
					)
				}
			</div>
			<div className={classes.documentList}>
				{
					campaignModuleIds?.includes(KCQ_ID) && (
						<CampaignKeyCompetencyCard guestView={guestView} />
					)
				}
			</div>
		</>
	);

}
export default CampaignDocuments;
