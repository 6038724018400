import React from 'react';
import { Redirect } from 'react-router';

import { FULL, TA, TA_PRO } from './../constants/subscriptionPlansIds';

import CandidateCampaignDetail from './../views/CandidateCampaignDetail';
import CandidateDashboard from './../views/CandidateDashboard';
import CandidateProfileTalentAlert from './../views/TalentAlertCandidates/CandidateProfileTalentAlert';
import TalentAlertCandidates from './../views/TalentAlertCandidates';
import TalentAlertCandidateProfile from './../views/TalentAlertConsultant/TalentAlertCandidatePool/TalentAlertCandidateProfile';

const CandidateRoutes = (store) => {

    const fullSubscriptionPlanRoutes = [
        {
            path: '/dashboard',
            exact: true,
            component: () => {
                if (store.getState().session.consultancySubscriptionPlanId === TA) {
                    return <Redirect to="/dashboard/talent-alerts" />;
                }

                return <CandidateDashboard />;
            }
        },
        {
            path: '/dashboard/campaigns/:campaignId',
            exact: true,
            component: (props) => <CandidateCampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/candidate-profile',
            exact: true,
            component: (props) => <TalentAlertCandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        }
    ];

    const talentAlertSubscriptionPlanRoutes = [
        {
            path: '/dashboard/talent-alerts',
            exact: true,
            component: () => <TalentAlertCandidates />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId',
            exact: true,
            component: (props) => <CandidateProfileTalentAlert campaignId={ Number(props.match.params.campaignId) } consultancyId={ Number(store.getState().session.consultancyId) } />
        }
    ];

    const talentAlertProSubscriptionPlanRoutes = [
        {
            path: '/dashboard',
            exact: true,
            component: () => <CandidateDashboard />
        },
        {
            path: '/dashboard/vacancies',
            exact: true,
            component: () => <Redirect to='/dashboard' />
        },
        {
            path: '/dashboard/vacancies/:campaignId',
            exact: true,
            component: (props) => <CandidateCampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/talent-alerts',
            exact: true,
            component: () => <Redirect to='/dashboard' />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId',
            exact: true,
            component: (props) => <CandidateProfileTalentAlert campaignId={ Number(props.match.params.campaignId) } consultancyId={ Number(store.getState().session.consultancyId) } />
        },
    ];

    if (store.getState().session.consultancySubscriptionPlanId === TA) {
        return talentAlertSubscriptionPlanRoutes;
    }

    if (store.getState().session.consultancySubscriptionPlanId === TA_PRO) {
        return talentAlertProSubscriptionPlanRoutes;
    }

    return [
        ...fullSubscriptionPlanRoutes,
        ...talentAlertSubscriptionPlanRoutes
    ];

};

export default CandidateRoutes;
