import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Avatar, Badge, Button, Grid, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import UserTypeAvatar from "./UserTypeAvatar";
import * as api from "../../api/SystemAdminApi";
import UserEnableButton from "./UserEnableButton";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	userRow: {
		border: `1px solid ${theme.palette.primary.light}`,
		borderRadius: 2,
		margin: "12px",
	},

	disabledUserRow: {
		background: "lightGrey",
		border: `1px solid lightGrey`,
		borderRadius: 2,
		margin: "12px",
	},
	userRowContainer: {
		alignItems: "center",
	},
	resendEmailButtonText: {
		color: "white",
	},
	linkPress: {
		cursor: "pointer",
	},
}));

const UserCard = ({ user, userIsActivated, listCardAction }) => {
	const [t] = useTranslation(["common", "users", "groups", "snackbar"]);
	const [enable, setEnable] = useState(user.enabled);

	function handleStatusChange(enabled, id) {
		setEnable(enabled);
		return api
			.toggleConsultancyUserEnabled({ enabled, id }, user.consultancyId)
			.then((response) => {
				if (response.status === 200) {
					if (response.data.enabled) {
						enqueueSnackbar(t(`snackbar:succesfullyEnabledUser`), {
							variant: "success",
						});
					} else {
						enqueueSnackbar(t(`snackbar:succesfullyDisabledUser`), {
							variant: "success",
						});
					}
				} else {
					enqueueSnackbar(t("snackbar:updateFailed"), {
						variant: "error",
					});
				}
			})
			.catch((error) => error);
	}
	const classes = useStyles();
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();

	const roleType = useSelector((state) => {
		return state.session.roleType;
	});

	const goToUser = (event, consultancyId, userId) => {
		event.preventDefault();
		if (roleType === "CoreAdmin" || roleType === "SystemAdmin") {
			history.push(`/dashboard/consultancies/${consultancyId}/users/${userId}`);
		} else {
			history.push(`/dashboard/users/${userId}`);
		}
	};

	return (
		<Grid
			item
			xs={12}
			className={enable ? classes.userRow : classes.disabledUserRow}
		>
			<Grid container spacing={1} className={classes.userRowContainer}>
				<Grid item xs={3} sm={2} md={2} lg={2}>
					{/* change to cardInfo.avatarSrc when available */}
					<Badge
						overlap="circle"
						anchorOrigin={{
							vertical: "bottom",
							horizontal: "right",
						}}
						badgeContent={<UserTypeAvatar role={user.role} />}
					>
						<Avatar
							src={
								user.profilePicture
									? user.profilePicture
									: "https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg"
							}
						/>
					</Badge>
				</Grid>
				<Grid item xs={9} sm={6} md={6} lg={6}>
					<Typography
						className={classes.linkPress}
						variant="h5"
						onClick={(event) => {
							goToUser(event, user.consultancyId, user.userId);
						}}
					>
						{user.name}
					</Typography>
				</Grid>
				<Grid
					container
					direction="column"
					justify="center"
					alignItems="stretch"
					spacing={1}
					item
					xs={12}
					sm={4}
					md={4}
					lg={4}
				>
					{userIsActivated ? null : (
						<Grid item>
							<Button
								fullWidth
								color="primary"
								size="small"
								type="submit"
								variant="contained"
								onClick={(event) => {
									listCardAction(event, user.userId);
								}}
							>
								<Typography
									variant="body2"
									className={classes.resendEmailButtonText}
								>
									{t("users:resendActivation")}
								</Typography>
							</Button>
						</Grid>
					)}
					<Grid item>
						<UserEnableButton
							enable={enable}
							handleStatusChange={handleStatusChange}
							user={user}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default UserCard;
