import * as actionTypes from 'src/actions';

const initialState = {
  open: false,
  contexts: [
    {
      context: "Personal"
    }
  ]
};

const mediaDrawerReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.OPEN_DRAWER: {
      return {
        ...state,
        open: true
      }
    }
    case actionTypes.CLOSE_DRAWER: {
      return {
        ...state,
        open: false
      }
    }
    case actionTypes.TOGGLE_DRAWER: {
      return {
        ...state,
        open: !state.open
      }
    }
    default: {
      return state;
    }
  }
};

export default mediaDrawerReducer;
