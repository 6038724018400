import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  CardContent,
  Card,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import * as CampaignApi from "src/api/campaignApi";
import validate from "validate.js";
import { UPDATE_SALARY_DETAILS } from "src/actions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  updateButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contactListHeader: {
    display: "flex",
    margin: 5,
    marginBottom: 10,
    justifyContent: "space-between",
  },
  contactListHeaderButtons: {
    justifyContent: "space-between",
  },
  addContactButton: {
    minWidth: 24,
    padding: 2,
    marginLeft: 10,
  },
  inviteContactButton: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
}));

const schema = {
  percentage_charged: {
    presence: { allowEmpty: false, message: "is required" },
  },
  salary: {
    presence: { allowEmpty: false, message: "is required" },
  },
  fee: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const currencies = [
  {
    value: "GBP",
    name: "poundSterling",
    symbol: "£",
  },
  {
    value: "EUR",
    name: "euro",
    symbol: "€",
  },
  {
    value: "USD",
    name: "unitedStatesDollar",
    symbol: "$",
  },
  {
    value: "AUD",
    name: "australianDollar",
    symbol: "$",
  },
  {
    value: "CAD",
    name: "canadianDollar",
    symbol: "$",
  },
  {
    value: "BGN",
    name: "bulgarianLev",
    symbol: "лв",
  },
  {
    value: "PHP",
    name: "philippinePeso",
    symbol: "₱",
  },
  {
    value: "THB",
    name: "thaiBaht",
    symbol: "฿",
  },
  {
    value: "JPY",
    name: "japaneseYen",
    symbol: "¥",
  },
  {
    value: "CNY",
    name: "chineseYuan",
    symbol: "¥",
  },
  {
    value: "ZAR",
    name: "southAfricanRand",
    symbol: "R",
  },
  {
    value: "DKK",
    name: "danishKrone",
    symbol: "kr",
  },
  {
    value: "NZD",
    name: "newZealandDollar",
    symbol: "$",
  },
  {
    value: "CZK",
    name: "czechKoruna",
    symbol: "Kč",
  },
  {
    value: "RON",
    name: "romanianLeu",
    symbol: "lei",
  },
  {
    value: "PLN",
    name: "polishZloty",
    symbol: "zł",
  },
  {
    value: "HKD",
    name: "hongKongDollar",
    symbol: "HK$",
  }
];

const FeesCard = ({ guestView }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation([
    "common",
    "validation",
    "campaigns",
    "snackbar",
    "tooltips",
    "newTranslations",
  ]);

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaign = useSelector((state) => state.campaign);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      salary: campaign.salary,
      fee: campaign.fee,
      percentage_charged: campaign.percent,
      currency: campaign.currency,
    },
    touched: {},
    errors: {},
  });

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const handlePercentageChange = (event) => {
    event.persist();
    if (event.target.validity.valid) {
      let percentage = event.target.value;
      let salary = formState.values.salary;
      let fee = (salary / 100) * percentage;

      setFormState((prevState) => ({
        ...prevState,
        values: {
          ...prevState.values,
          salary: parseFloat(salary),
          fee: parseFloat(fee),
          percentage_charged: parseFloat(percentage),
        },
      }));
    }
  };

  const handleFeeChange = (event) => {
    event.persist();
    let salary = formState.values.salary;
    let fee = event.target.value;
    let percentage = ((fee / salary) * 100).toFixed(2);

    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        salary: parseFloat(salary),
        fee: parseFloat(fee),
        percentage_charged: parseFloat(percentage),
      },
    }));
  };

  const handleSalaryChange = (event) => {
    event.persist();
    let percentage = formState.values.percentage_charged;
    let salary = event.target.value;
    let fee = (salary / 100) * percentage;

    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        salary: parseFloat(salary),
        fee: parseFloat(fee),
        percentage_charged: parseFloat(percentage),
      },
    }));
  };

  const handleCurrencyChange = (event) => {
    setFormState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        currency: event.target.value,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = () => {
    return CampaignApi.renumerations(
      campaign.id,
      consultancyId,
      formState.values
    )
      .then(() => {
        dispatch({
          type: UPDATE_SALARY_DETAILS,
          salaryDetails: formState.values,
        });
        enqueueSnackbar(t("snackbar:salaryFeeSuccess"), { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:salaryFeeFailure"), { variant: "error" });
        console.error(error);
      });
  };

  return (
    <Card>
      <BlockHeader
        headerTooltip={t("tooltips:inputSalary")}
        card
        padding
        header={t("campaigns:feesAndRemunerations")}
      />
      <CardContent>
        <form className={classes.root} onSubmit={handleSubmit}>
          <div className={classes.fields}>
            {guestView ? null : (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>{t("common:currencyLabel")}</InputLabel>
                <Select
                  value={formState.values.currency}
                  onChange={handleCurrencyChange}
                  label="Currency"
                >
                  <MenuItem value="">
                    <em>{t("common:none")}</em>
                  </MenuItem>
                  {currencies.map((currency) => (
                    <MenuItem value={currency.value}>
                      {currency.symbol} {t(`newTranslations:${currency.name}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormControl
              variant="outlined"
              fullWidth
              className={classes.formControl}
            >
              <TextField
                fullWidth
                name="salary"
                label={t("common:salary")}
                variant="outlined"
                type="number"
                onChange={handleSalaryChange}
                error={hasError("salary")}
                value={formState.values.salary}
                disabled={guestView}
                helperText={
                  hasError("salary")
                    ? t(`validation:${formState.errors.salary[0]}`)
                    : null
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencies &&
                        currencies.find(
                          (currency) =>
                            currency?.value === formState?.values?.currency
                        )?.symbol}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {guestView ? null : (
              <>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <TextField
                    fullWidth
                    name="percentage_charged"
                    variant="outlined"
                    label={t("common:percentage")}
                    onChange={handlePercentageChange}
                    error={hasError("percentage")}
                    value={formState.values.percentage_charged}
                    type="number"
                    helperText={
                      hasError("percentage")
                        ? t(
                            `validation:${formState.errors.percentage_charged[0]}`
                          )
                        : null
                    }
                    InputProps={{
                      inputProps: { min: 0, max: 100, step: 0.01 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  className={classes.formControl}
                >
                  <TextField
                    fullWidth
                    name="fee"
                    variant="outlined"
                    label={t("common:fee")}
                    onChange={handleFeeChange}
                    error={hasError("fee")}
                    value={formState.values.fee}
                    type="number"
                    helperText={
                      hasError("fee")
                        ? t(`validation:${formState.errors.fee[0]}`)
                        : null
                    }
                    InputProps={{
                      inputProps: { min: 0, max: 100000, step: 0 },
                      startAdornment: (
                        <InputAdornment position="start">
                          {
                            currencies?.find(
                              (currency) =>
                                currency?.value === formState?.values?.currency
                            )?.symbol
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </>
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            {!guestView && (
              <Button
                className={classes.updateButton}
                color="primary"
                size="large"
                onClick={handleSubmit}
                variant="contained"
                fullWidth
                disabled={!formState.isValid}
              >
                { t("common:update") }
              </Button>
            )}
          </div>
        </form>
      </CardContent>
    </Card>
  );
};

export default FeesCard;
