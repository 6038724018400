import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { ThemeIconButton } from '../../../components/LayoutComponents/ThemeIconButton';

const useStyles = makeStyles(() => ({
  listCard: {
    marginTop: 8,
    backgroundColor: '#f5f5f5',
    height: 39,
    padding: 0,
    paddingLeft: 8
  },
  contactListCard: {
    display: 'flex',
    alignItems: 'center',
    padding: 0
  },
  contactText: {
    flex: 2,
    minWidth: 60,
    textAlign: 'left',
    fontWeight: 400,
    marginLeft: '20px'
  },
  addButton: {
    marginLeft: 16
  }
}));

const ContactListCard = ({ contact, campaignId, onAdd, ...rest }) => {

  const classes = useStyles();
  const [t] = useTranslation(['campaigns']);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const addTalentAlertContact = () => {
    setIsLoading(true);

    talentAlertApi.addContact(campaignId, contact.id)
      .then((contact) => {
        if (!contact) {
          throw new Error('Failed to add contact');
        }

        enqueueSnackbar(t('campaigns:contactAddSuccess'), { variant: 'success' });
        onAdd();
      })
      .catch(() => {
        enqueueSnackbar(t('campaigns:contactUpdateFailure'), { variant: 'error' });
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  return (
    <Card
      className={classes.listCard}
      {...rest}
      variant="outlined"
      elevation={0} >
      <CardContent className={classes.contactListCard}>
        <div className={classes.addButton}>
          <ThemeIconButton
            icon={<AddIcon />}
            btnFunc={addTalentAlertContact}
            loading={isLoading} />
        </div>

        <Typography className={classes.contactText}>
          { contact?.profile.firstName } { contact?.profile.lastName } - { contact?.profile.user.email }
        </Typography>
      </CardContent>
    </Card>
  );

};

export default ContactListCard;
