import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as ContactApi from "src/api/contactApi";
import { useTranslation } from "react-i18next";

function ContactSelect({ handleChange, disabled, value, employerId }) {
  const [t] = useTranslation(["common"]);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [contacts, setContacts] = useState([]);

  const getContacts = function(consultancyId, employerId) {
    return ContactApi.getContacts(consultancyId, employerId)
      .then((response) => {
        setContacts(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (employerId) {
      getContacts(consultancyId, employerId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedContacts =
    contacts && contacts.length
      ? contacts.sort((a, b) => {
          var nameA = a.contactName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.contactName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      : contacts;

  return (
    <>
      <Autocomplete
        disabled={disabled}
        id="contactAutoComplete"
        options={sortedContacts}
        getOptionLabel={(option) => option.contactName}
        value={value}
        onChange={handleChange}
        style={{ width: "100%" }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              fullWidth
              label={t("common:selectContact")}
              variant="outlined"
            />
          );
        }}
      />
    </>
  );
}
export default ContactSelect;
