import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Card,
  CardContent,
  Container,
  Modal,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { BlockHeader } from "./LayoutComponents/BlockHeader";
import { useSelector } from "react-redux";
import { CANDIDATE } from "src/constants/roles";

import { useCandidatesOrVacancyCandidatesTranslationPrefix } from './../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    justifyContent: "center",
  },
  container: {
    padding: "0px 10px",
    outline: "none",
    display: "flex",
  },
  box: {
    marginTop: 50,
    marginBottom: 50,
    width: "100%",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  closeButton: {
    height: 39,
    width: 39,
    borderRadius: 0,
    backgroundColor: theme.palette.button.main,
  },
  modalOverflow: {
    overflowY: "scroll",
    marginTop: "70px",
  },
}));

export const ModalFormWrapper = ({
  children,
  formTitle,
  formTooltip,
  handleOpen,
  handleClose,
  maxWidth,
  height,
  editDocument,
  closeButton = true
}) => {
  const candidatesPrefix = useCandidatesOrVacancyCandidatesTranslationPrefix();

  const classes = useStyles();
  const [t] = useTranslation([
    "common",
    "candidates",
    "campaigns",
    "launchpad",
    "dwi",
    "modules",
    "users",
    "groups",
    "vacancyCandidates",
  ]);

  const titles = [
    t("candidates:createBatchCandidate"),
    t("common:createEvents"),
    t("campaigns:createCampaign"),
    t("campaigns:mcQuaigWordSurvey"),
    t("campaigns:addKCQTitle"),
    t("campaigns:campaignKcqTitle"),
    t("campaigns:update"),
    t("launchpad:videoAssessment"),
    t("launchpad:editLaunchpadInterview"),
    t("candidates:createCandidate"),
    t("common:createEmployer"),
    t("campaigns:addContacts"),
    t("campaigns:uploadAVideo"),
    t("candidates:addCandidateToCampaign"),
    t("campaigns:mcQuaigInstituteJobSurvey"),
    t("campaigns:updateTermsAndConditionsLabel"),
    t(`${candidatesPrefix}:inviteCandidatesToCampaignLabel`),
    t("dwi:updateCategory"),
    t("modules:kcqCreateModalTitle1"),
    t("modules:kcqUpdateModalTitle1"),
    t("users:createNewUser"),
    t("groups:createGroup"),
    t("campaigns:inviteCandidatesToCampaignLabel"),
  ];

  const roleType = useSelector((state) => {
    return state.session.roleType;
  });

  const getTooltipValue = () => {
    if (formTooltip) {
      return t(formTooltip);
    }

    if (titles.includes(formTitle) && formTitle === t("candidates:createBatchCandidate")) {
      return t("tooltips:importCandidates");
    }

    if (roleType !== CANDIDATE && !editDocument) {
      return t("tooltips:modalConfirmEvent");
    }

    return null;
  };

  return (
    <Modal
      open={handleOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalOverflow}
    >
      <Container
        className={classes.container}
        tabIndex=""
        maxWidth={maxWidth ? maxWidth : "md"}
      >
        <Box display="flex" alignItems="center" className={classes.box}>
          <Box display="column" width="100%">
            <Card
              className={classes.modalCard}
              style={{ height: height ? height : "" }}
            >
              <BlockHeader
                card
                padding
                closeButton={ closeButton }
                header={formTitle}
                btnFunc={handleClose}
                headerTooltip={ getTooltipValue() }
              />
              <CardContent>{children}</CardContent>
            </Card>
          </Box>
        </Box>
      </Container>
    </Modal>
  );
};
