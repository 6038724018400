import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";

import moment from "moment";

import { saveNewCandidateComment } from "./../api/candidateApi";

const DATE_TIME_FORMAT = "DD/MM/YYYY HH:mm";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    height: '85vh'
  },
  headBG: {
    backgroundColor: '#e0e0e0'
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0'
  },
  messageArea: {
    height: '71vh',
    overflowY: 'auto',
  },
  commentText: {
    borderRadius: "25px 25px 0px 25px",
    backgroundColor: "#2383D1",
    color: "#FFFFFF",
    padding: "15px",
    whiteSpace: "pre-wrap"

  },
  commentOwn: {
    minWidth: "100px",
    maxWidth: "60%",
    marginLeft: "auto",
  },
  commentOther: {
    maxWidth: "60%",
    minWidth: "100px",
  },
  commentOtherText: {
    padding: "15px",
    backgroundColor: "#f5f5f5",
    borderRadius: "0px 25px 25px 25px",
    whiteSpace: "pre-wrap"
  },
  commentNameLeft: {
    fontSize: "0.8rem",
    color: "#808080",
    marginLeft: "1%"
  },
  commentNameRight: {
    fontSize: "0.8rem",
    color: "#808080",
    marginRight: "10px",
    textAlign: "right"
  },
  commentAuthor: {
    color: theme.palette.primary.main,
  },
  msgBox: {
    padding: "20px",
    marginLeft: "5%",
  },
  msgBoxBtn: {
    marginLeft: "5px"
  },
  sendIcon: {
    width: "40px",
    height: "40px",
    color: "#666666",
    marginTop: "1%"
  },
  commentBox: {
    paddingLeft: "7%",
    paddingRight: "7%"
  },
  timeCreatedRight: {
    color: "#808080",
    marginRight: "1%"
  },
  timeCreatedLeft: {
    color: "#808080",
    marginLeft: "1%"
  },
  notchedOutline: {
    borderWidth: "0.5px",
    borderColor: "#666666 !important",
    borderRadius: "7px"
  },
  textLabel: {
    color: "#666666 !important"
  },
  progressCircle: {
    color: "#666666"
  }
}));

const CandidateComments = ({
  candidateCampaignId,
  consultancyId,
  campaignId,
  comments,
}) => {
  const [t] = useTranslation(["common", "candidates", "tooltips"]);
  const [savingComment, setSavingComment] = useState(false);
  const [candidateComments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const classes = useStyles();



  const handleChange = (event, index) => {
    let allComments = candidateComments;
    let singleComment = allComments[index];
    singleComment.comment = event.target.value;
    setComments([...allComments]);
  };

  const resetNewComment = () => {
    setNewComment("");
  };

  const handleNewComment = (event) => {
    setNewComment(event.target.value);
  };

  const saveNewComment = () => {
    setSavingComment(true);
    saveNewCandidateComment(
      parseInt(consultancyId),
      parseInt(campaignId),
      candidateCampaignId,
      newComment
    ).then((comment) => {
      if (comment) {
        setSavingComment(false);
      }
      let allComments = comments;
      allComments.unshift(comment.data);
      setComments([...allComments]);
      resetNewComment();
    });
  };

  useEffect(() => {
    setComments(comments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid container className={classes.msgBox}>
          <Grid item xs={10}>
            <TextField
              label="Leave a comment"
              fullWidth
              multiline
              name="salutation"
              onChange={handleNewComment}
              value={newComment}
              variant="outlined"
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              InputLabelProps={{
                classes: {
                  root: classes.textLabel,
                  focused: classes.textLabel,
                },
              }}
            />
          </Grid>
          <Grid xs={1} className={classes.msgBoxBtn}>
            <Button aria-label="add" onClick={saveNewComment} type="submit" className={classes.sendBtn}>
              {savingComment ? (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                />
              ) : (
                <SendIcon className={classes.sendIcon} />
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
          <List className={classes.messageArea}>
            {candidateComments.map((comment, index) => (
              <ListItem>
                <Grid container className={classes.commentBox}>
                  {comment.isAuthor ? (
                    <div className={classes.commentOwn}>
                      <Grid item xs={12}>
                        <ListItemText>
                          <Typography className={classes.commentNameRight}>
                            {t("common:You")}
                          </Typography>
                        </ListItemText>
                      </Grid>
                      <Grid className={classes.commentText}>
                        <Grid item xs={12}>
                          <ListItemText>
                            <Typography
                              multiline
                              fullWidth
                              name="salutation"
                              variant="outlined"
                              onChange={(event) => handleChange(event, index)}
                            >
                              {comment.comment}
                            </Typography>
                          </ListItemText>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemText align="right">
                          <Typography variant={"subtitle2"} className={classes.timeCreatedRight}>
                            {moment(comment.createdAt).format(DATE_TIME_FORMAT)}
                          </Typography>
                        </ListItemText>
                      </Grid>
                    </div>
                  ) : (
                    <div className={classes.commentOther}>
                      <Grid item xs={12}>
                        <ListItemText align="left">
                          <Typography className={classes.commentNameLeft}>
                            {`${comment.author?.profile?.firstName} ${comment.author?.profile?.lastName}`}
                          </Typography>
                        </ListItemText>
                      </Grid>
                      <Grid className={classes.commentOtherText}>
                        <Grid item xs={12}>
                          <ListItemText align="left" >
                            <Typography
                              multiline
                              fullWidth
                              name="salutation"
                              variant="outlined"
                              onChange={(event) => handleChange(event, index)}
                            >
                              {comment.comment}
                            </Typography>
                          </ListItemText>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <ListItemText align="left">
                          <Typography variant={"subtitle2"} className={classes.timeCreatedLeft}>
                            {moment(comment.createdAt).format(DATE_TIME_FORMAT)}
                          </Typography>
                        </ListItemText>
                      </Grid>
                    </div>
                  )}
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div >
  )
}

export default CandidateComments;
