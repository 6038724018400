import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, InputAdornment, TextField, SvgIcon } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  queryField: {
    width: 500,
  },
}));

export const useSearch = (data, value, fields) => {
  const searchResults = () => {
    if (!data) return [];
    const filteredResults = data.filter((e) => {
      let filterFields = "";
      fields.forEach((field) => (filterFields = filterFields + e[field] + " "));
      return filterFields.toLowerCase().includes(value.toLowerCase());
    });
    return filteredResults;
  };
  return { searchResults };
};

export const Searchbar = (props) => {
  const classes = useStyles();
  const [t] = useTranslation(["common"]);
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <Box
      p={2}
      minHeight={56}
      display="flex"
      alignItems="center"
      className={classes.root}
    >
      <TextField
        className={classes.queryField}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </InputAdornment>
          ),
        }}
        onChange={handleChange}
        placeholder={`${t("common:search")}...`}
        value={props.value}
        variant="outlined"
      />
    </Box>
  );
};
