import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { Avatar, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: '200px',
    height: '200px',
    '&:hover': {
      cursor: 'pointer'
    },
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  button: {
    margin: 'auto',
    display: 'block',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginTop: '10px',
    marginBottom: '50px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      opacity: '0.8'
    },
    maxHeight: '40px'
  },
  job: {
    marginTop: '10px',
    textAlign: 'center',
    display: 'block'
  },
  candidate: {
    width: '100%'
  }
}));

const CandidateThumbnail = ({ id, image, campaignId, jobTitle, loggedIn }) => {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation(['newTranslations']);

  const goToCandidate = () => {
    const previousPath = location.state && location.state?.previousPath;

    if (loggedIn) {
      history.push({
        pathname: `/dashboard/talent-alerts/${campaignId}/candidates/${id}`,
        state: {
          previousPath: previousPath ? previousPath : history.location.pathname
        }
      });
      return;
    }

    const pushOptions = {
      pathname: `/talent-alert/${campaignId}/${id}`,
      state: {
        previousPath: history.location.pathname
      }
    };

    if (previousPath) {
      pushOptions.state = {
        previousPath
      }
    }

    history.push(pushOptions);
  };

  return (
    <div className={classes.candidate}>
      <Avatar
        src={image}
        variant="rounded"
        className={classes.avatar}
        onClick={goToCandidate} />

      <Typography className={classes.job}>
        { jobTitle }
      </Typography>

      <Button className={classes.button} onClick={goToCandidate}>
        { t("newTranslations:viewCandidate") }
        </Button>
    </div>
  );

};

export default CandidateThumbnail;
