import React from 'react';
import { Redirect } from 'react-router';

import { TA, TA_PRO } from './../constants/subscriptionPlansIds';

import ContactCandidateProfile from './../views/ContactCandidateProfile';
import ContactCampaignDetail from './../views/ContactCampaignDetail';
import ContactDashboard from './../views/ContactDashboard';
import TalentAlertContactCandidateProfile from './../views/TalentAlertContacts/TalentAlertContactCandidateProfile';
import TalentAlertContactDashboard from './../views/TalentAlertContacts/TalentAlertContactDashboard';

const ContactRoutes = (store) => {

    const fullSubscriptionPlanRoutes = [
        {
            path: '/dashboard',
            exact: true,
            component: () => {
                if (store.getState().session.consultancySubscriptionPlanId === TA) {
                    return <Redirect to="/dashboard/talent-alerts" />;
                }

                return <ContactDashboard />;
            }
        },
        {
            path: '/dashboard/campaigns/:campaignId',
            exact: true,
            component: (props) => <ContactCampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/campaigns/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <ContactCandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        }
    ];

    const talentAlertSubscriptionPlanRoutes = [
        {
            path: '/dashboard/talent-alerts',
            exact: true,
            component: () => <Redirect to="/dashboard" />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId',
            exact: true,
            component: (props) => <TalentAlertContactDashboard loggedIn={ true } campaignId={ Number(props.match.params.campaignId) } consultancyId={ Number(store.getState().session.consultancyId) } />
        },
        {
            path: '/dashboard/talent-alerts/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <TalentAlertContactCandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } loggedIn={ true } consultancyId={ Number(store.getState().session.consultancyId) } />
        }
    ];

    const talentAlertProSubscriptionPlanRoutes = [
        {
            path: '/dashboard',
            exact: true,
            component: () => <ContactDashboard />
        },
        {
            path: '/dashboard/vacancies',
            exact: true,
            component: () => <ContactDashboard />
        },
        {
            path: '/dashboard/vacancies/:campaignId',
            exact: true,
            component: (props) => <ContactCampaignDetail campaignId={ Number(props.match.params.campaignId) } />
        },
        {
            path: '/dashboard/vacancies/:campaignId/candidates/:candidateId',
            exact: true,
            component: (props) => <ContactCandidateProfile campaignId={ Number(props.match.params.campaignId) } candidateId={ Number(props.match.params.candidateId) } />
        },
        ...talentAlertSubscriptionPlanRoutes,
    ];

    if (store.getState().session.consultancySubscriptionPlanId === TA) {
        return talentAlertSubscriptionPlanRoutes;
    }

    if (store.getState().session.consultancySubscriptionPlanId === TA_PRO) {
        return talentAlertProSubscriptionPlanRoutes;
    }

    return [
        ...fullSubscriptionPlanRoutes,
        ...talentAlertSubscriptionPlanRoutes
    ];

};

export default ContactRoutes;
