import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Avatar,
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	card: {
		transition: "300ms",
		maxWidth: "360px",
		overflow: "visible",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: theme.spacing(12),
	},
	card_disabled: {
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	name: {
		transition: "300ms",
	},
	name_disabled: {
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	avatar: {
		backgroundColor: theme.palette.primary.main,
		width: 120,
		height: 120,
		boxShadow: "0 0.5rem 1rem #C2C2C2",
		transform: "translateY(-70px)",
		marginBottom: "-2rem",
	},
	initials: {
		color: theme.palette.primary.contrastText,
	},
	email: {
		textDecoration: "underline",
		textDecorationColor: theme.palette.text.secondary,
		color: theme.palette.text.secondary,
		fontSize: ".9rem",
	},
}));

export const ProfileCard = ({
	name,
	email,
	btnLabel,
	btnFunc,
	disableBtn,
	enabled = true,
	profileImage,
}) => {
	const classes = useStyles();

	const getInitials = () => {
		const initials = name
			.split(/\s|\-|\'/g) // eslint-disable-line no-useless-escape
			.map((e) => e[0])
			.join("")
			.toUpperCase();

		if (initials.length > 3) return initials[0] + initials[initials.length - 1];

		return initials;
	};

	return (
		<Card className={enabled ? classes.card : classes.card_disabled}>
			<CardContent>
				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					textAlign="center"
				>
					<Avatar className={classes.avatar} src={profileImage}>
						<Typography className={classes.initials} variant="h1">
							{getInitials()}
						</Typography>
					</Avatar>
					<Box>
						<Typography
							className={enabled ? classes.name : classes.name_disabled}
							gutterBottom
							variant="h3"
							color="textPrimary"
						>
							{name}
						</Typography>
						<a href={`mailto:${email}`} className={classes.email}>
							{email}
						</a>
					</Box>
				</Box>
			</CardContent>
			<CardActions>
				{btnLabel ? (
					<Button
						fullWidth
						variant="text"
						color="primary"
						onClick={btnFunc}
						disabled={disableBtn ? true : false}
					>
						{btnLabel}
					</Button>
				) : null}
			</CardActions>
		</Card>
	);
};
