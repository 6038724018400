import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';

import { makeStyles } from '@material-ui/styles';
import { Typography, TextField, colors } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import {
  ADD_CAMPAIGN_REQUIRED_DOCUMENT,
  UPDATE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT
} from './../../../../actions/campaignActions';

import { saveRequiredCandidateDocument } from './../../../../api/campaignApi';

import { CardButton } from './../../../../components/LayoutComponents/CardButton';
import { ModalFormWrapper } from './../../../../components/ModalFormWrapper';
import DocumentTypeRadioButtons from './DocumentTypeRadioButtons';
import DocumentVisibilityCheckBox from './DocumentVisibilityCheckBox';

import * as candidateRequiredDocumentTypeIds from './../../../../constants/candidateRequiredDocumentTypeIds';

//-TODO: Translations

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    padding: '0px 10px'
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${10}%)`
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${10}%)`
    },
    '&:focus': {
      outline: 'none'
    }
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400]
    },
    color: theme.palette.common.white,
    textTransform: 'none'
  },
  modalOverflow: {
    overflowY: 'scroll'
  },
  fileField: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  assetDropdown: {
    flex: 2,
    marginRight: 10
  },
  fileDropzone: {
    flex: 1
  },
  subheadingText: {
    width: '100%'
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    '&>button': {
      width: '45%'
    }
  }
}));

function CandidateDocumentModal({
  campaignId,
  document = {},
  consultancyId,
  onSubmit,
  open,
  onClose,
  text,
  onRemove,
}) {

  const classes = useStyles();
  const [t] = useTranslation(['candidates', 'common']);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      name: document.name,
      visibility: ['private'],
      documentType: document.documentType || candidateRequiredDocumentTypeIds.DOCUMENT
    },
  });

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        id: document.id || '',
        name: document.name || '',
        visibility: document.visibility || ['private'],
        documentType: document.documentType || candidateRequiredDocumentTypeIds.DOCUMENT,
        priority: document.priority || 0
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getFormTitle = () => {
    if (text) {
      return document.name;
    }

    if (document.name) {
      return `${t('campaigns:editCampaignDocumentLabel')} ${document.name}`;
    }

    return t('campaigns:addCampaignDocumentLabel');
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        name: event.target.value
      }
    }));
  };

  const handleVisibilityChange = useCallback((visibility) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        visibility: visibility
      }
    }));
  }, []);

  const handleDocumentTypeChange = (documentType) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        documentType
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    saveRequiredCandidateDocument(consultancyId, campaignId, formState.values)
      .then((response) => {
        if (response.data) {
          const { data } = response;

          if (!formState.values.id) {
            onSubmit({
              id: data.id,
              name: data.name,
              visibility: data.visibility,
              priority: data.priority
            });

            dispatch({
              type: ADD_CAMPAIGN_REQUIRED_DOCUMENT,
              document: data
            });

            enqueueSnackbar(t('candidates:documentAdded'), { variant: 'success' });
          } else {
            onSubmit({
              id: data.id,
              name: data.name,
              visibility: data.visibility,
              priority: data.priority,
              update: true
            });

            dispatch({
              type: UPDATE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT,
              document: data
            });

            enqueueSnackbar(t('candidates:documentUploaded'), { variant: 'success' });
          }

          setLoading(false);
          onClose();
        }
      })
      .catch(() => {
        enqueueSnackbar(t('candidates:documentError'), { variant: 'error' });
      });
  };

  const handleRemove = () => {
    setLoading(true);
    onRemove(document.id).then(() => setLoading(false));
  };

  return (
    <ModalFormWrapper
      formTitle={ getFormTitle() }
      handleOpen={ open }
      handleClose={ onClose }
      editDocument={ true } >
      {
        text ? (
          <div class="ql-editor">{ parse(text || "") }</div>
        ) : (
          <form onSubmit={ handleSubmit }>
            <div className={ classes.fields }>
              <TextField
                fullWidth
                variant="outlined"
                label={ t("campaigns:documentNameLabel") }
                value={ formState.values.name }
                onChange={ handleChange } />

              <Typography className={classes.subheadingText} variant="h5">
                { t("campaigns:campaignDocumentVisibilitySelection") }
              </Typography>

              <DocumentVisibilityCheckBox
                visibility={ formState.values.visibility }
                onChange={ handleVisibilityChange } />

              <DocumentTypeRadioButtons
                active={ true }
                documentType={ formState.values.documentType }
                onChange={ handleDocumentTypeChange } />
            </div>

            <CardButton
              btnVariant="contained"
              btnVariant2="outlined"
              btnLabel={
                document.name ?
                t("campaigns:saveCampaignDocumentLabel") :
                t("campaigns:addDocumentLabel")
              }
              btnLabel2={ t("campaigns:removeDocumentLabel") }
              btnDisabled2={ !document.id } 
              loading={ loading }
              loading2={ loading }
              btnFunc={ handleSubmit }
              btnFunc2={ handleRemove }/>
          </form>
        )
      }
    </ModalFormWrapper>
  );

}

export default CandidateDocumentModal;
