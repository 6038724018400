import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Typography, Button, useTheme, useMediaQuery } from "@material-ui/core";
import Page from "src/components/Page";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3),
		paddingTop: "10vh",
		display: "flex",
		flexDirection: "column",
		alignContent: "center",
	},
	imageContainer: {
		marginTop: theme.spacing(6),
		display: "flex",
		justifyContent: "center",
	},
	image: {
		maxWidth: "100%",
		width: 560,
		maxHeight: 300,
		height: "auto",
	},
	buttonContainer: {
		marginTop: theme.spacing(6),
		display: "flex",
		justifyContent: "center",
	},
}));

function Error404() {
	const classes = useStyles();
	const theme = useTheme();
	const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Page className={classes.root} title="Error 404">
			<Typography align="center" variant={mobileDevice ? "h4" : "h1"}>
				404: The page you are looking for isn’t here
			</Typography>
			<Typography align="center" variant="h5">
				You either tried some shady route or you came here by mistake. Whichever
				it is, try using the navigation
			</Typography>
			<div className={classes.imageContainer}>
				<img
					alt="Under development"
					className={classes.image}
					src="/images/undraw_page_not_found_su7k.svg"
				/>
			</div>
			<div className={classes.buttonContainer}>
				<Button
					color="primary"
					component={RouterLink}
					to="/"
					variant="outlined"
				>
					Back to home
				</Button>
			</div>
		</Page>
	);
}

export default Error404;
