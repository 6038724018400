import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  AppBar,
  useMediaQuery,
} from "@material-ui/core";
import * as EmployerApi from "src/api/employerApi";
import CreateEmployerModal from "./CreateEmployerModal";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { EmployerCard } from "src/components/Cards/EmployerCard";
import { TabPanel } from "src/components/TabPanel";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import { SortMenu, useSort } from "src/components/Filter/Sort";
import { SelectMenu } from "src/components/Filter/Select";
import { Paginator, usePagination } from "src/components/Filter/Pagination";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import CustomTabs from './../../components/LayoutComponents/CustomTabs';

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

import iintroLogo from './../../assets/images/logos/i-intro_logo.png';
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0% 5%",
  },
  filterCard: {
    marginBottom: theme.spacing(3),
    width: "100%",
    padding: 0,
  },
  filterCard_box: {
    padding: 0,
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  createCampaignButtonText: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  companyLogo: {
    height: "80px",
    width: "auto",
    objectFit: "contain",
  },
  candidateTitle: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  candidateEmail: {
    textAlign: "center",
    marginTop: "10px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    zIndex: 1,
  },
}));

function Employers() {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common"]);
  const desktop = useMediaQuery("(min-width:960px)");
  const [open, setOpen] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [myEmployers, setMyEmployers] = useState([]);
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showItems, setShowItems] = useState(12);
  const [sortOrder, setSortOrder] = useState(t("common:ascending"));

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const tabs = [
    { value: 0, label: t('common:My Employers') },
    { value: 1, label: t('common:All Employers') },
  ];

  const [isLoading, setIsLoading] = useState(true);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const handleSortItems = (newValue) => {
    setSortOrder(newValue);
  };

  const handleShowItems = (newValue) => {
    setShowItems(newValue);
  };

  const handlePageChange = (newValue) => {
    jump(newValue.selected + 1);
  };

  const getEmployers = function(consultancyId) {
    return EmployerApi.getEmployers(consultancyId)
      .then((response) => {
        setEmployers(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getMyEmployers = function(consultancyId) {
    return EmployerApi.getMyEmployers(consultancyId)
      .then((response) => {
        setMyEmployers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getEmployers(consultancyId);
    getMyEmployers(consultancyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (employerDetails) => {
    myEmployers.push(employerDetails);
  };

  const goToEmployerDetails = (event, employerId) => {
    event.preventDefault();
    history.push(`/dashboard/employers/${employerId}`);
  };

  let myEmployersFiltered = useSearch(myEmployers, searchTerm, [
    "employerName",
    "managedBy",
  ]).searchResults();

  let employersFiltered = useSearch(employers, searchTerm, [
    "employerName",
    "managedBy",
  ]).searchResults();

  const { currentData, jump, maxPage } = usePagination(
    currentTab === 0 ? myEmployersFiltered : employersFiltered,
    showItems
  );

  let paginatedData = currentData();

  useSort(paginatedData, sortOrder, "employerName").sortData();

  const getLogo = (employer) => {
    if (employer.employerLogo) {
      return employer.employerLogo;
    }

    switch (consultancySubscriptionPlanId) {
      case subscriptionPlansIds.TA:
        return taLogo;
      case subscriptionPlansIds.TA_PRO:
        return taProLogo;
      default:
        return iintroLogo;
    }
  };

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle
        pageTitle={t("common:employersLabel")}
        btnLabel={t("common:createEmployer")}
        btnFunc={handleOpen}
      />
      <ContentWrapper loading={isLoading}>
        <Card className={classes.filterCard}>
          <Box
            p={2}
            minHeight={56}
            display="flex"
            alignItems="center"
            className={classes.filterCard_box}
          >
            <Searchbar onChange={handleInputChange} />
            {desktop ? (
              <>
                <Box flexGrow={1} />
                <SelectMenu value={showItems} onChange={handleShowItems} />
                <SortMenu value={sortOrder} onChange={handleSortItems} />
              </>
            ) : null}
          </Box>
        </Card>
        <AppBar position="static" className={classes.appBar}>
          <CustomTabs tabs={ tabs } onChange={ handleChange } tabClassName={ classes.tab } />
        </AppBar>
        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={3}>
            {paginatedData.map((employer) => (
              <Grid item md={4} sm={6} xs={12}>
                <EmployerCard
                  name={employer.employerName}
                  email={employer.managedBy}
                  btnLabel={t("common:viewMore")}
                  btnFunc={(event) => {
                    goToEmployerDetails(event, employer.id);
                  }}
                  logo={ getLogo(employer) }
                />
              </Grid>
            ))}
          </Grid>
          <Paginator pageCount={maxPage} onPageChange={handlePageChange} />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={3}>
            {paginatedData.map((employer) => (
              <Grid item md={4} sm={6} xs={12}>
                <EmployerCard
                  name={employer.employerName}
                  email={employer.managedBy}
                  btnLabel={t("common:viewMore")}
                  btnFunc={(event) => {
                    goToEmployerDetails(event, employer.id);
                  }}
                  logo={ getLogo(employer) }
                />
              </Grid>
            ))}
          </Grid>
          <Paginator pageCount={maxPage} onPageChange={handlePageChange} />
        </TabPanel>
      </ContentWrapper>

      <CreateEmployerModal
        onSuccess={onSuccess}
        consultancyId={consultancyId}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}

export default Employers;
