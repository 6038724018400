import React, { useState, useEffect } from "react";
import { Card, CardContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  saveConsultancyDefaultWelcomeMessage,
  removeConsultancyDefaultWelcomeMessage,
} from "src/api/consultancyApi";
import { setCandidateWelcomeMessagesStatusToNotRead } from "src/api/candidateApi";
import { setContactWelcomeMessagesStatusToNotRead } from "src/api/contactApi";
import DefaultMessagesList from "src/components/DefaultMessagesList";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const DefaultWelcomeMessages = ({ consultancyId, defaultMessages }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(["consultancy", "common", "snackbar", "tooltips"]);
  const [messages, setMessages] = useState([]);

  const saveWelcomeMessage = (message, index) => {
    return saveConsultancyDefaultWelcomeMessage(consultancyId, message)
      .then((response) => {
        if (response.status === 200) {
          let tempMessages = messages;
          tempMessages[index] = response.data;
          setMessages([...tempMessages]);
          const messageSetToViewByUser = (user) =>
            response.data.visibility.includes(user);

          if (
            messageSetToViewByUser("candidate") &&
            messageSetToViewByUser("contact")
          ) {
            return Promise.all([
              setCandidateWelcomeMessagesStatusToNotRead(consultancyId),
              setContactWelcomeMessagesStatusToNotRead(consultancyId),
            ]).then(([candidateResponse, contactResponse]) => {
              if (
                candidateResponse.status === 200 &&
                contactResponse.status === 200
              ) {
                enqueueSnackbar(t("snackbar:savedDefaultMessage"), {
                  variant: "success",
                });
              } else
                enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
                  variant: "error",
                });
            });
          }

          if (messageSetToViewByUser("candidate")) {
            return setCandidateWelcomeMessagesStatusToNotRead(consultancyId)
              .then((response) => {
                if (response.status === 200) {
                  enqueueSnackbar(t("snackbar:savedDefaultMessage"), {
                    variant: "success",
                  });
                } else
                  enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
                    variant: "error",
                  });
              })
              .catch((error) => error);
          }
          if (messageSetToViewByUser("contact")) {
            return setContactWelcomeMessagesStatusToNotRead(consultancyId)
              .then((response) => {
                if (response.status === 200) {
                  enqueueSnackbar(t("snackbar:savedDefaultMessage"), {
                    variant: "success",
                  });
                } else
                  enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
                    variant: "error",
                  });
              })
              .catch((error) => error);
          }
        } else
          enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
            variant: "error",
          });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
          variant: "error",
        });
      });
  };

  const newMessage = () => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        name: "",
        message: "",
        visibility: "private",
      },
    ]);
  };

  const removeMessage = (message, index) => {
    if (message.id) {
      removeConsultancyDefaultWelcomeMessage(consultancyId, message.id)
        .then((response) => {
          if (response.status === 200) {
            let tempMessages = messages;
            tempMessages.splice(index, 1);
            setMessages([...tempMessages]);
            enqueueSnackbar(t("snackbar:deletedMessage"), {
              variant: "success",
            });
          } else
            enqueueSnackbar(t("snackbar:errorDeletingMessage"), {
              variant: "error",
            });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(t("snackbar:errorDeletingMessage"), {
            variant: "error",
          });
        });
    } else {
      let tempMessages = messages;
      tempMessages.splice(index, 1);
      setMessages([...tempMessages]);
      enqueueSnackbar(t("snackbar:deletedMessage"), {
        variant: "success",
      });
    }
  };

  const handleTitleChange = (event, index) => {
    event.persist();
    let tempMessages = messages;
    tempMessages[index].name = event.target.value;
    tempMessages[index].updatedAt = new Date();
    setMessages([...tempMessages]);
  };

  const handleMessageChange = (event, index) => {
    event.persist();
    let tempMessages = messages;
    tempMessages[index].message = event.target.value;
    tempMessages[index].updatedAt = new Date();
    setMessages([...tempMessages]);
  };

  const handleVisibilityChange = (state) => {
    let { id, visibility } = state;
    let index = id?.split("_")[0];
    let tempMessages = messages;
    if (index) {
      tempMessages[index].visibility = visibility;
      tempMessages[index].updatedAt = new Date();
    }

    setMessages([...tempMessages]);
  };

  useEffect(() => {
    setMessages(defaultMessages);
  }, [defaultMessages]);

  return (
    <Card style={{ marginTop: "15px" }}>
      <CardContent>
        <BlockHeader
          headerTooltip={t("tooltips:manageDefaultMessages")}
          reduceMargin
          header={t("consultancy:defaultMessagesLabel")}
        />
        <DefaultMessagesList
          messages={messages}
          functions={{
            handleVisibilityChange,
            onNew: newMessage,
            onSave: saveWelcomeMessage,
            removeMessage,
            handleTitleChange,
            handleMessageChange,
          }}
        />
      </CardContent>
    </Card>
  );
};

export default DefaultWelcomeMessages;
