import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import { Button, Container, Grid, AppBar, CircularProgress } from '@material-ui/core';
import { PersonRounded, DetailsRounded, Settings, Lock } from '@material-ui/icons';

import * as talentAlertActions from './../../../actions/talentAlertActions';

import * as talentAlertApi from './../../../api/talentAlertApi';

import Page from './../../../components/Page';
import CustomTabs from './../../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../../components/LayoutComponents/PageTitle';
import { TabPanel } from './../../../components/TabPanel';
import { ModalFormWrapper } from './../../../components/ModalFormWrapper';
import ShareWidget from './../../../components/ShareWidget';

import TalentAlertCandidatePool from './../TalentAlertCandidatePool';
import TalentAlertContactDashboard from './../../TalentAlertContacts/TalentAlertContactDashboard';
import TalentAlertTargetList from './../TalentAlertTargetList'
import TalentAlertVisibilitySettings from './../TalentAlertVisibilitySettings';
import TalentAlertDetails from './TalentAlertDetails';
import UnAuthTalentAlertContactDashboard from './../../TalentAlertContacts/UnAuthTalentAlertContactDashboard';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    padding: '0px 20px'
  },
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  loading: {
    display: 'block',
    margin: 'auto',
    marginTop: '50px'
  },
  previewModalGrid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
}));

const TalentAlertDetail = ({ campaignId }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [t] = useTranslation(['candidates', 'common', 'campaigns', 'newTranslations', 'shareWidget']);

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const talentAlert = useSelector((state) => state.talentAlert);

  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isPreviewAsAuthenticatedContact, setIsPreviewAsAuthenticatedContact] = useState(talentAlert.previewingAsAuthenticatedContact);
  const [isPreviewAsUnauthenticatedUser, setIsPreviewAsUnauthenticatedUser] = useState(talentAlert.previewingAsUnauthenticatedUser);
  const [isShareWidgetOpen, setIsShareWidgetOpen] = useState(false);

  const tabs = [
    { value: 0, label: t('campaigns:talentAlertDetail'), icon: <DetailsRounded /> },
    { value: 1, label: t('campaigns:candidateDetailLabel'), icon: <PersonRounded /> },
    { value: 2, label: t('campaigns:manage Access'), icon: <Lock /> },
    { value: 3, label: t('campaigns:settings'), icon: <Settings /> },
  ];

  useEffect(() => {
    dispatch({
      type: talentAlertActions.SET_TALENT_ALERT_CAMPAIGN_TYPE,
      isTalentAlertCampaign: true
    });

    talentAlertApi.getTalentAlertCampaignKeyCompetencyQuestionSet(consultancyId, campaignId)
      .then((response) => {
        dispatch({
          type: talentAlertActions.SET_TALENT_ALERT_KCQ,
          currentTalentAlertKCQ: response.data
        });
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      dispatch({
        type: talentAlertActions.SET_TALENT_ALERT_CAMPAIGN_TYPE,
        isTalentAlertCampaign: false
      });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    talentAlertApi.getTalentAlertCampaign(consultancyId, campaignId)
      .then((response) => {
        dispatch({
          type: talentAlertActions.SET_TALENT_ALERT,
          talentAlert: response.data
        });

        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        history.push('/dashboard');
      });
  }, [consultancyId, campaignId, dispatch, history]);

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const togglePreview = () => {
    setIsPreviewModalOpen((prevState) => !prevState);
  };

  const toggleAuthenticatedContactPreview = () => {
    const prevState = isPreviewAsAuthenticatedContact;

    setIsPreviewAsAuthenticatedContact(!prevState);

    dispatch({
      type: talentAlertActions.PREVIEW_TALENT_ALERT_AS_AUTHENTICATED_CONTACT,
      previewingAsAuthenticatedContact: !prevState
    });
  };

  const toggleUnauthenticatedUserPreview = () => {
    const prevState = isPreviewAsUnauthenticatedUser;

    setIsPreviewAsUnauthenticatedUser(!prevState);

    dispatch({
      type: talentAlertActions.PREVIEW_TALENT_ALERT_AS_UNAUTHENTICATED_USER,
      previewingAsUnauthenticatedUser: !prevState
    });
  };

  const toggleShareWidgetModal = () => {
    setIsShareWidgetOpen(!isShareWidgetOpen);
  };

  if (isPreviewAsAuthenticatedContact) {
    return (
      <TalentAlertContactDashboard
        loggedIn={ true }
        campaignId={ campaignId }
        consultancyId={ consultancyId }
        isPreview={ true }
        cancelPreview={ toggleAuthenticatedContactPreview } />
    );
  }

  if (isPreviewAsUnauthenticatedUser) {
    return (
      <UnAuthTalentAlertContactDashboard
        loggedIn={ false }
        campaignId={ campaignId }
        consultancyId={ consultancyId }
        isPreview={ true }
        cancelPreview={ toggleUnauthenticatedUserPreview } />
    );
  }

  return (
    <Page className={classes.root}>
      <Container className={classes.container} maxWidth="xl">
        <PageTitle
          pageTitle={talentAlert.campaignTitle}
          btnLabel={t("shareWidget:shareWidgetOpenWidgetButton")}
          btnFunc={ toggleShareWidgetModal }
          btnVariant="outlined"
          btnLabel2={t("newTranslations:previewTalentAlert")}
          btnFunc2={togglePreview} />
        
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridRow}>
            <AppBar position="static" className={classes.appBar}>
              <CustomTabs tabs={ tabs } onChange={ handleChange } />
            </AppBar>
          </Grid>
          
          {
            loading ? (
              <CircularProgress className={classes.loading} />
            ) : (
              <Grid item xs={12}>
                <TabPanel value={currentTab} index={0}>
                  <TalentAlertDetails />
                </TabPanel>
                
                <TabPanel value={currentTab} index={1}>
                  <TalentAlertCandidatePool candidates={talentAlert.campaignCandidates} />
                </TabPanel>
                
                <TabPanel value={currentTab} index={2}>
                  <TalentAlertTargetList />
                </TabPanel>
                
                <TabPanel value={currentTab} index={3}>
                  <TalentAlertVisibilitySettings campaignId={ campaignId } />
                </TabPanel>
              </Grid>
            )
          }
        </Grid>
      </Container>

      <ModalFormWrapper
        formTitle={ t("newTranslations:recruiterPreviewTalentAlertAsContactTitle") }
        formTooltip={ t("newTranslations:recruiterPreviewTalentAlertAsContactTooltip") }
        handleOpen={ isPreviewModalOpen }
        handleClose={ togglePreview }
        maxWidth="sm" >
        <Grid className={ classes.previewModalGrid }>
          <Button color="primary" size="large" variant="outlined" onClick={ toggleAuthenticatedContactPreview }>
            { t("newTranslations:recruiterPreviewTalentAlertAsContactBtnAsAuthContact") }
          </Button>

          <Button color="primary" size="large" variant="contained" onClick={ toggleUnauthenticatedUserPreview }>
          { t("newTranslations:recruiterPreviewTalentAlertAsContactBtnAsUnauthContact") }
          </Button>
        </Grid>
      </ModalFormWrapper>

      <ShareWidget isModalOpen={ isShareWidgetOpen } toggleModal={ toggleShareWidgetModal } isCampaign={ true } />
    </Page>
  );

};

export default TalentAlertDetail;
