import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";
import { ToggleCard } from "src/components/Cards/ToggleCard";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import * as campaignActions from "src/actions/campaignActions";
import * as api from "../../../api/campaignApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const CampaignModuleManagement = () => {
  const [t] = useTranslation([
    "common",
    "campaigns",
    "snackbar",
    "newTranslations",
  ]);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const { campaign } = useSelector((state) => state);
  const [campaignModules, setCampaignModules] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const getCampaignModules = () => {
    return api
      .getModulesForCampaign(consultancyId, campaign.id)
      .then((response) => {
        const data = response.data;
        setCampaignModules(data);
      });
  };

  useEffect(() => {
    getCampaignModules();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleStatusChange(id, enabled) {
    return api
      .toggleCampaignModule(id, parseInt(consultancyId), campaign.id, enabled)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:moduleSettingsUpdateSuccess"), {
            variant: "success",
          });
          getCampaignModules();
          return api
            .getCampaignDetails(consultancyId, campaign.id)
            .then((response) => {
              dispatch({
                type: campaignActions.UPDATE_CAMPAIGN,
                campaign: response.data,
              });
            });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:moduleSettingsUpdateFailure"), {
          variant: "error",
        });
      });
  }

  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
  };

  let filteredResults = useSearch(campaignModules, searchTerm, [
    "moduleName",
  ]).searchResults();

  return (
    <>
      <Grid container xs={12} display="flex" alignItems="center">
        <Searchbar onChange={handleInputChange} />
      </Grid>
      {campaign.campaignStatusId ? (
        <Grid container spacing={3}>
          {filteredResults.map((module) => (
            <ToggleCard
              name={t(
                `newTranslations:${module?.moduleName?.charAt(0).toLowerCase() +
                  module?.moduleName.replace(/\s/g, "").slice(1)}`
              )}
              detail={module.detail}
              enabled={module.isActive}
              handleSwitch={() => {
                handleStatusChange(module.id, !module.isActive);
              }}
            />
          ))}
        </Grid>
      ) : null}
    </>
  );
};
