import React, { useState } from "react";
import PropTypes from "prop-types";
import CreateUserModal from "./CreateUserModal";
import { useTranslation } from "react-i18next";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";

function Header({ className, consultancies, ...rest }) {
	const [t] = useTranslation(["campaigns", "common", "snackbar"]);

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<PageTitle
				pageTitle={t("common:allUsers")}
				btnLabel={t("common:newUser")}
				btnFunc={handleOpen}
			/>

			{/* Leaving in as we may want the export option at some point in the future */}
			{/* <Grid item>
         <Box mt={2}>
          <Button className={classes.action}>
            <SvgIcon
              fontSize="small"
              className={classes.actionIcon}
            >
              <DownloadIcon />
            </SvgIcon>
            Export
          </Button>
        </Box> 
        </Grid> */}
			<CreateUserModal
				open={open}
				handleClose={handleClose}
				consultancies={consultancies}
			/>
		</>
	);
}

Header.propTypes = {
	className: PropTypes.string,
};

export default Header;
