import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  CircularProgress,
  Card,
  CardContent,
} from "@material-ui/core";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {},
  submitButton: {
    marginTop: theme.spacing(2),
    width: "33%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  buttonContainer: {
    textAlign: "center",
  },
  cardContent: {
    height: "500px",
    overflowY: "auto",
  },
}));

function TermsAndConditionsAcceptanceModal({
  onSuccess,
  open,
  handleClose,
  campaignTC,
}) {
  const classes = useStyles();
  const [t] = useTranslation(["campaigns", "common"]);

  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const [loading, setLoading] = useState(false);

  const onSubmit = function(event) {
    event.preventDefault();
    setLoading(true);
    onSuccess().then(() => {
      setLoading(false);
      handleClose();
    });
  };

  return (
    <ModalFormWrapper
      formTitle={t(`common:${consultancySubscriptionPlanId === TA_PRO ? "consultancyVacancyT&CLabel" : "consultancyCampaignT&CLabel"}`)}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth="sm"
    >
      <Card>
        <CardContent className={classes.cardContent}>
          <div class="ql-editor">{parse(campaignTC || "")}</div>
        </CardContent>
      </Card>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          onClick={onSubmit}
          variant="contained"
        >
          {loading ? (
            <CircularProgress className={classes.progressCircle} size={25} />
          ) : (
            t("common:accept")
          )}
        </Button>
      </div>
    </ModalFormWrapper>
  );
}
export default TermsAndConditionsAcceptanceModal;
