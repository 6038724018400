import {
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import * as SysApi from 'src/api/SystemAdminApi';
import validate from 'validate.js';
import { logoutAndDeleteSession } from 'src/actions';
import { BlockHeader } from 'src/components/LayoutComponents/BlockHeader';
import { CardButton } from 'src/components/LayoutComponents/CardButton';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  inputRow: {
    alignItems: 'center',
  },
  headerRow: {
    textAlign: 'right',
    padding: '0px 25px',
  },
}));

function UserSecurity({ profileData }) {
  const [t] = useTranslation(['common', 'validation', 'snackbar']);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...profileData,
    },
    touched: {},
    errors: {},
  });

  const [passwordResetFormState, setPasswordResetFormState] = useState({
    isValid: false,
    values: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    touched: {},
    errors: {},
  });

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const schema = {
    email: {
      presence: { allowEmpty: false, message: t('common:required') },
      email: true,
      length: {
        maximum: 64,
      },
    },
  };

  const passwordSchema = {
    currentPassword: {
      presence: { allowEmpty: false, message: t('common:required') },
    },
    newPassword: {
      presence: { allowEmpty: false, message: t('common:required') },
      length: {
        minimum: 6,
      },
    },
    confirmPassword: {
      presence: true,
      equality: {
        attribute: 'newPassword',
        message: t('common:passwordMismatch'),
      },
    },
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    const errors = validate(passwordResetFormState.values, passwordSchema);

    setPasswordResetFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordResetFormState.values]);

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const hasPasswordError = (field) => {
    if (
      passwordResetFormState.touched[field] &&
      passwordResetFormState.errors[field]
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePasswordChange = (event) => {
    event.persist();
    setPasswordResetFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleLogout = async () => {
    history.push('/');
    try {
      await logoutAndDeleteSession(dispatch);
      window.location.href = '/';
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = (event) => {
    return SysApi.updateUserEmail(formState.values.email, consultancyId)
      .then(() => {
        handleLogout().then(() => {
          enqueueSnackbar(t('snackbar:updateEmail'), { variant: 'success' });
        });
      })
      .catch((error) => {
        enqueueSnackbar(t('snackbar:updateEmailError'), { variant: 'error' });
      });
  };

  const handlePasswordUpdateSubmit = (event) => {
    return SysApi.updateUserPassword(
      passwordResetFormState.values,
      consultancyId
    )
      .then(() => {
        handleLogout().then(() => {
          enqueueSnackbar(t('snackbar:updatePassword'), { variant: 'success' });
        });
      })
      .catch((error) => {
        enqueueSnackbar(t(`snackbar:${error.response.data.error.message}`), {
          variant: 'error',
        });
      });
  };

  return (
    <Grid container spacing={3} alignContent='space-around'>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3} className={classes.inputRow}>
              <Grid item xs={12}>
                <BlockHeader
                  reduceMargin
                  header={t('common:updateEmailAddress')}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} className={classes.inputRow}>
                <Grid item xs={12} md={3}>
                  <Typography variant='h5'>
                    {t('common:currentEmail')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <TextField
                    fullWidth
                    name='email'
                    type='email'
                    variant='outlined'
                    onChange={handleChange}
                    error={hasError('email')}
                    value={formState.values.email || ''}
                    helperText={
                      hasError('email')
                        ? t(`validation:${formState.errors.email[0]}`)
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CardButton
                    btnLabel={t('common:updateEmailAddress')}
                    btnFunc={handleSubmit}
                    btnVariant='contained'
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Grid container spacing={3} className={classes.inputRow}>
              <Grid item xs={12}>
                <BlockHeader reduceMargin header={t('common:changePassword')} />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.inputRow}>
              <Grid item xs={12} md={3}>
                <Typography variant='h5'>
                  {t('common:currentPassword')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  type='password'
                  fullWidth
                  name='currentPassword'
                  variant='outlined'
                  onChange={handlePasswordChange}
                  error={hasPasswordError('currentPassword')}
                  value={passwordResetFormState.values.currentPassword}
                  helperText={
                    hasPasswordError('currentPassword')
                      ? t(
                          `validation:${passwordResetFormState.errors.currentPassword[0]}`
                        )
                      : null
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.inputRow}>
              <Grid item xs={12} md={3}>
                <Typography variant='h5'>{t('common:newPassword')}</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  type='password'
                  fullWidth
                  name='newPassword'
                  variant='outlined'
                  onChange={handlePasswordChange}
                  error={hasPasswordError('newPassword')}
                  value={passwordResetFormState.values.newPassword}
                  helperText={
                    hasPasswordError('newPassword')
                      ? t(
                          `validation:${passwordResetFormState.errors.newPassword[0]}`
                        )
                      : null
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.inputRow}>
              <Grid item xs={12} md={3}>
                <Typography variant='h5'>
                  {t('common:confirmPasswordLabel')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  type='password'
                  fullWidth
                  name='confirmPassword'
                  variant='outlined'
                  onChange={handlePasswordChange}
                  error={hasPasswordError('confirmPassword')}
                  value={passwordResetFormState.values.confirmPassword}
                  helperText={
                    hasPasswordError('confirmPassword')
                      ? t(
                          `validation:${passwordResetFormState.errors.confirmPassword[0]}`
                        )
                      : null
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <CardButton
                  btnLabel={t('common:updatePassword')}
                  btnFunc={handlePasswordUpdateSubmit}
                  btnDisabled={!passwordResetFormState.isValid}
                  btnVariant='contained'
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
export default UserSecurity;
