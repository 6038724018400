import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useSelector } from "react-redux";

import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { FwdBackButtons } from "src/components/LayoutComponents/FwdBackButtons";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
	gridContainer: {
		height: "500px",
		overflowY: "auto",
	},
	infoWrapper: {
		display: "flex",
		alignItems: "flex-end",
		backgroundColor: "#f5f5f5",
		minHeight: 39,
		padding: 0,
		width: "100%",
		marginTop: 32,
	},
	infoMsg: {
		margin: 8,
	},
}));

function Review({ values, onSubmit, setCurrentTab }) {
	const classes = useStyles();
	const [t] = useTranslation([
		"campaigns",
		"common",
		"snackbar",
		"campaignCandidates",
	]);

	const campaign = useSelector((state) => state.campaign);
	const goBack = (event) => {
		event.preventDefault();
		setCurrentTab(3);
	};
	return (
		<form onSubmit={onSubmit}>
			<Grid container spacing={3} className={classes.gridContainer}>
				{values.selectedTimeSlots?.map((selectedTimeSlot) => (
					<Grid item xs={12}>
						<Card elevation={0} style={{ backgroundColor: "#f5f5f5" }}>
							<CardContent>
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<Typography variant="h2">
											{selectedTimeSlot.format("LT")} -{" "}
											{moment(selectedTimeSlot.toDate())
												.add(values.selectedInterviewDuration, "m")
												.format("LT")}
											, {selectedTimeSlot.format("dddd, MMMM Do YYYY")}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="h4">{values.description}</Typography>
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h4">
											{t("campaigns:invitedContacts")}:{" "}
										</Typography>
										{campaign.campaignContacts?.map((contact) => (
											<>
												{values.selectedContacts.includes(contact.id) ? (
													<Typography variant="h4">
														{contact.firstName} {contact.lastName} (
														{contact.jobTitle})
													</Typography>
												) : null}
											</>
										))}
									</Grid>
									<Grid item xs={6}>
										<Typography variant="h4">
											{t("campaigns:invitedCandidates")}:{" "}
										</Typography>
										{campaign.campaignCandidates?.map((candidate) => (
											<>
												{values.selectedCandidates.includes(candidate.id) ? (
													<Typography variant="h4">
														{candidate.firstName} {candidate.lastName}
													</Typography>
												) : null}
											</>
										))}
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				))}
			</Grid>
			<span className={classes.infoWrapper}>
				<InfoOutlinedIcon style={{ height: 38, width: 38 }} />
				<Typography className={classes.infoMsg}>
					{t("campaignCandidates:invitesWillBeSent")}
				</Typography>
			</span>
			<FwdBackButtons submit backFunc={goBack} />
		</form>
	);
}
export default Review;
