import React from 'react';

import { useTranslation } from 'react-i18next';

import LoginForm from './LoginForm'
import { ModalFormWrapper } from './../../../components/ModalFormWrapper';

const SignInModal = ({ open, handleClose, campaignId, candidateId }) => {

  const [t] = useTranslation(['newTranslations', 'tooltips']);

  const closeLoginForm = (doOpenContactModal = false) => {
    handleClose(doOpenContactModal);
  };

  return (
    <ModalFormWrapper
      formTitle={t("newTranslations:signInToViewMore")}
      handleOpen={open}
      handleClose={() => closeLoginForm()}
      maxWidth={"sm"}
      formTooltip={ t("tooltips:taUnauthContactRequestAccess") } >
        <LoginForm
          campaignId={campaignId}
          candidateId={candidateId}
          closeForm={closeLoginForm} />
    </ModalFormWrapper>
  );

};

export default SignInModal;
