import React, { useState, useEffect } from "react";
import { AppBar, Grid, Tab, Tabs, Tooltip } from "@material-ui/core";
import { TabPanel } from "src/components/TabPanel";
import { ToggleCard } from "src/components/Cards/ToggleCard";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import { useTranslation } from "react-i18next";
import * as consultancyApi from "../../api/consultancyApi";

import { Typography, TextField, Button } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from "@material-ui/core/styles";

import validate from 'validate.js';

import * as signalHireApi from '../../api/signalHireApi';

import { ModalFormWrapper } from "../../components/ModalFormWrapper";

import { SIGNAL_HIRE_ID } from "../../constants/moduleIds";

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  tooltipWrapper: { marginLeft: "10px", color: "black", marginTop: "15px" },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
  form: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
}));

const SystemAdminConsultancyModuleManagement = ({ consultancyId }) => {
  const classes = useStyles();
  const [t] = useTranslation(["settings", "tooltips", "newTranslations"]);

  const moduleTabs = [
    { value: 0, label: "availableModules" },
    { value: 1, label: "activeModules" },
    { value: 2, label: "inactiveModules" },
  ];

  const [availableModules, setAvailableModules] = useState([]);
  const [activeModules, setActiveModules] = useState([]);
  const [inactiveModules, setInactiveModules] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [signalHireModalOpen, setSignalHireModalOpen] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { apiKey: "" },
    touched: {},
    errors: {}
  });

  const validFormSchema = {
    apiKey: {
      presence: {
        allowEmpty: false,
        message: t("newTranslations:signalHireApiKeyModalInputErrorRequired")
      }
    }
  };

  const getConsultancyModules = () => {
    return consultancyApi
      .getModulesForConsultancy(consultancyId)
      .then((response) => {
        const data = response.data;
        setAvailableModules(data);

        const active = data.filter((module) => module.activeByDefault === true);

        const inactive = data.filter(
          (module) => module.activeByDefault === false
        );

        setInactiveModules(inactive);
        setActiveModules(active);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getConsultancyModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId]);

  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleDefaultState = (id, activeByDefault, consultancyId) => {
    return consultancyApi
      .toggleDefaultModuleState(id, activeByDefault, consultancyId)
      .then((response) => {
        if (response) {
          getConsultancyModules();
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    signalHireApi.getAccessTokenByConsultancyId(consultancyId)
      .then((json = {}) => json.data)
      .then(apiKey => {
        setFormState(prevState => ({
          ...prevState,
          isValid: true,
          values: {
            ...prevState.values,
            apiKey
          },
          touched: {
            ...prevState.touched,
            apiKey: true
          }
        }));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (signalHireModalOpen) {
      const errors = validate(formState.values, validFormSchema);

      setFormState(prevState => ({
        ...prevState,
        isValid: !errors,
        errors: errors || {}
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const toggleSignalHireModal = () => {
    setSignalHireModalOpen(prevState => !prevState);
  };

  const handleModalChange = event => {
    event.persist();

    setFormState(prevState => ({
      ...prevState,
      values: {
        ...prevState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevState.touched,
        [event.target.name]: true
      }
    }));
  };

  const modalHasError = field => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    }

    return false;
  };

  const modalSubmit = event => {
    event.preventDefault();

    signalHireApi.createOrUpdateAccessToken(consultancyId, formState.values.apiKey)
      .then(() => {
        setSignalHireModalOpen(false);
      });
  };

  let filteredResults = useSearch(
    currentTab === 0
      ? availableModules
      : currentTab === 1
      ? activeModules
      : inactiveModules,
    searchTerm,
    ["moduleName"]
  ).searchResults();

  return (
    <>
      <Grid
        container
        xs={12}
        display="flex"
        alignItems="center"
        flexDirection="row-reverse"
      >
        <Searchbar onChange={handleInputChange} />
      </Grid>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          textColor="primary"
        >
          {moduleTabs.map((tab) => (
            <Tab
              key={tab.value}
              label={t(`settings:${tab.label}`)}
              value={tab.value}
            />
          ))}
          <Tooltip
            enterTouchDelay={1}
            classes={{
              tooltip: classes.tooltip,
            }}
            title={t("tooltips:switchModules")}
          >
            <InfoIcon className={classes.tooltipWrapper} />
          </Tooltip>
        </Tabs>
      </AppBar>
      <TabPanel value={currentTab} index={0}>
        <Grid container spacing={3}>
          {filteredResults.map((module) => (
            <ToggleCard
              name={t(
                `newTranslations:${module?.moduleName?.charAt(0).toLowerCase() +
                  module?.moduleName.replace(/\s/g, "").slice(1)}`
              )}
              detail={module.detail}
              enabled={module.activeByDefault}
              handleSwitch={() => {
                handleDefaultState(
                  module.id,
                  !module.activeByDefault,
                  consultancyId
                );
              }}
              addOptionsBtn={module.moduleId === SIGNAL_HIRE_ID}
              optionsBtnClick={toggleSignalHireModal}
            />
          ))}
        </Grid>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <Grid container spacing={3}>
          {filteredResults.map((module) => (
            <ToggleCard
              name={t(
                `newTranslations:${module?.moduleName?.charAt(0).toLowerCase() +
                  module?.moduleName.replace(/\s/g, "").slice(1)}`
              )}
              detail={module.detail}
              enabled={module.activeByDefault}
              handleSwitch={() => {
                handleDefaultState(
                  module.id,
                  !module.activeByDefault,
                  consultancyId
                );
              }}
              addOptionsBtn={module.moduleId === SIGNAL_HIRE_ID}
              optionsBtnClick={toggleSignalHireModal}
            />
          ))}
        </Grid>
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <Grid container spacing={3}>
          {filteredResults.map((module) => (
            <ToggleCard
              name={t(
                `newTranslations:${module?.moduleName?.charAt(0).toLowerCase() +
                  module?.moduleName.replace(/\s/g, "").slice(1)}`
              )}
              detail={module.detail}
              enabled={module.activeByDefault}
              handleSwitch={() => {
                handleDefaultState(
                  module.id,
                  !module.activeByDefault,
                  consultancyId
                );
              }}
              addOptionsBtn={module.moduleId === SIGNAL_HIRE_ID}
              optionsBtnClick={toggleSignalHireModal}
            />
          ))}
        </Grid>
      </TabPanel>

      <ModalFormWrapper
        formTitle={ t("newTranslations:signalHireApiKeyModalHeader") }
        formTooltip={ t("newTranslations:signalHireApiKeyModalTooltip") }
        handleOpen={signalHireModalOpen}
        handleClose={toggleSignalHireModal}>
          <form className={classes.form} onSubmit={modalSubmit}>
            <Typography variant="h4" className={classes.categoriesTitle}>
              { t("newTranslations:signalHireApiKeyModalInputLabel") }
            </Typography>

            <TextField
              fullWidth
              name="apiKey"
              variant="outlined"
              required
              onChange={handleModalChange}
              error={modalHasError("apiKey")}
              value={formState.values.apiKey}
              helperText={modalHasError("apiKey") ? formState.errors.apiKey[0] : null} />

            <Button
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}>
              { t("newTranslations:signalHireApiKeyModalSubmitBtn") }
            </Button>
          </form>
      </ModalFormWrapper>
    </>
  );
};

export default SystemAdminConsultancyModuleManagement;
