import React from 'react';

import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import {
    Grid,
    Card,
    TextField,
    Typography
} from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

import { CardButton } from '../../../../components/LayoutComponents/CardButton';
import { BlockHeader } from '../../../../components/LayoutComponents/BlockHeader';

import { updateCandidateKeyFacts } from '../../../../api/talentAlertApi';

const useStyles = makeStyles((theme) => ({
    inputRow: {
        alignItems: 'center'
    },
    keyFacts: {
        padding: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    }
}));

function CandidateKeyFacts({ candidateId, consultancyId, campaignId, handleKeyFactsChange, keyFacts }) {
    const classes = useStyles();
    const [t] = useTranslation([
        'common',
        'validation',
        'snackbar',
        'labels',
        'campaignCandidates',
        'tooltips'
    ]);
    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = (event) => {
        event.preventDefault();
        return updateCandidateKeyFacts(
            candidateId,
            consultancyId,
            campaignId,
            keyFacts
        )
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar(t('snackbar:successfullyUpdatedKeyFacts'), {
                        variant: 'success'
                    });
                }
            })
            .catch(() => {
                enqueueSnackbar(t('snackbar:failedToUpdateKeyFacts'), {
                    variant: 'error'
                });
            });
    };

    return (
        <Card className={classes.keyFacts}>
            <BlockHeader
                headerTooltip={t("tooltips:writeKeyFactsForCandidate")}
                card
                padding
                header={t("newTranslations:candidateKeyFacts")}
            />
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3} className={classes.inputRow}>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            {t("common:Fact One")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            name="fact1"
                            variant="outlined"
                            onChange={handleKeyFactsChange}
                            value={keyFacts?.fact1}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.inputRow}>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            {t(`common:Fact Two`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            name="fact2"
                            variant="outlined"
                            onChange={handleKeyFactsChange}
                            value={keyFacts?.fact2}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.inputRow}>
                    <Grid item xs={12} md={3}>
                        <Typography>
                            {t(`common:Fact Three`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <TextField
                            fullWidth
                            name="fact3"
                            variant="outlined"
                            onChange={handleKeyFactsChange}
                            value={keyFacts?.fact3}
                        />
                    </Grid>
                </Grid>
                <CardButton save type="submit" value="Submit" />
            </form>
        </Card>
    )
}

export default CandidateKeyFacts;