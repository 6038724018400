import React from "react";
import ReactPlayer from "react-player";
import { Box, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  viedoWrapper: {
    position: "relative",
  },
  video: {
    position: "absolute",
    top: 0,
    left: 100,
    marginY: "4rem",
  },
}));

const VideoBlock = ({ assetUrl, padding, size, play }) => {
  const { playing, setPlaying } = play;
  const classes = useStyles();

  return (
    <Box
      flex="display"
      alignItems="center"
      paddingY={padding ? padding : "5.65rem"}
    >
      <Grid direction="column" alignItems="center" justify="center" container>
        <Grid item xs={12} lg={size ? size : 8}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <div className={classes.videoWrapper}>
              <ReactPlayer
                className="react-player"
                url={assetUrl}
                width="100%"
                height="auto"
                controls="true"
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                playing={playing ? playing : false}
              />
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VideoBlock;
