import DeleteIcon from "@material-ui/icons/Delete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CANDIDATE, CONTACT } from "src/constants/roles";
import { useSelector } from "react-redux";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import IconListItem from "src/components/LayoutComponents/IconListItem";
import CheckIcon from "@material-ui/icons/Check";

function DocumentListCard({ document, onRemove, setMethods, ...rest }) {
  const { setEditDocument, setAddDocumentModalOpen } = setMethods;
  const [removeLoading, setRemoveLoading] = useState(false);
  const [t] = useTranslation(["common", "validation", "campaigns"]);
  const roleType = useSelector((state) => state.session.roleType);
  const readOnly = roleType === CONTACT || roleType === CANDIDATE;
  const adminContactPreviewMode = useSelector(
    (state) => state?.campaign?.adminContactPreview
  );

  const openEditModal = () => {
    setAddDocumentModalOpen(true);
    setEditDocument(document);
  };

  const handleRemove = () => {
    setRemoveLoading(true);
    onRemove(document.id || document.documentTitle);
  };

  return (
    <IconListItem
      text={document.documentTitle}
      noFill
      startIcon={
        document.url &&
        (readOnly || rest?.guestView || adminContactPreviewMode) ? (
          <DescriptionIcon />
        ) : (
          <DeleteIcon />
        )
      }
      startFunc={
        document.url && (readOnly || rest?.guestView || adminContactPreviewMode)
          ? null
          : handleRemove
      }
      startTip={
        document.url && (readOnly || rest?.guestView || adminContactPreviewMode)
          ? t("common:viewDocument")
          : t("common:removeDocument")
      }
      startLoading={removeLoading}
      endIcon={
        (readOnly && document.url) ||
        rest?.guestView ||
        adminContactPreviewMode ? null : (
          <EditIcon />
        )
      }
      middleIcon={
        !document.url ||
        readOnly ||
        rest?.guestView ||
        adminContactPreviewMode ? null : document.url ? (
          <CheckIcon />
        ) : null
      }
      endFunc={openEditModal}
      endTip={t("common:editDocument")}
      href={document.url}
      newWindow
      middleTip={t('common:viewDocument')}
    />
  );
}
export default DocumentListCard;
