import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  colors,
} from "@material-ui/core";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "&:focus": {
      outline: "none",
    },
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
}));

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  roleType: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function CreateUserModal({ onSuccess, consultancyId, open, handleClose }) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(["common", "users", "groups"]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = function(event) {
    event.preventDefault();
    return SystemAdminApi.createConsultancyUser(
      formState.values,
      Number(consultancyId)
    )
      .then((response) => {
        onSuccess(response.data);
        handleClose();
        enqueueSnackbar(`New ${formState.values.roleType} created.`, {
          variant: "success",
        });
      })
      .catch(() => {
        handleClose();
        enqueueSnackbar(
          `Could not create ${formState.values.roleType}, please try again later`,
          { variant: "error" }
        );
      });
  };
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const children = (
    <Grid item xs={12}>
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <TextField
            fullWidth
            label={t("common:firstNameLabel")}
            name="firstName"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("firstName")}
            value={formState.values.firstName || ""}
            helperText={
              hasError("firstName") ? formState.errors.firstName[0] : null
            }
          />
          <TextField
            fullWidth
            label={t("common:lastNameLabel")}
            name="lastName"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("lastName")}
            value={formState.values.lastName || ""}
            helperText={
              hasError("lastName") ? formState.errors.lastName[0] : null
            }
          />
          <TextField
            fullWidth
            label={t("common:emailLabel")}
            name="email"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("email")}
            value={formState.values.email || ""}
            helperText={hasError("email") ? formState.errors.email[0] : null}
          />
          <FormControl
            required
            variant="outlined"
            className={classes.formControl}
          >
            <InputLabel id="demo-simple-select-required-label">
              {t("users:selectRoleType")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              name="roleType"
              onChange={handleChange}
            >
              <MenuItem key={2} value="Admin">
                {t("users:adminLabel")}
              </MenuItem>
              <MenuItem key={1} value="Consultant">
                {t("users:consultantLabel")}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          {t("users:createNewUser")}
        </Button>
      </form>
    </Grid>
  );

  const formTitle = t("users:createNewUser");

  return (
    <ModalFormWrapper
      children={children}
      formTitle={formTitle}
      handleOpen={open}
      handleClose={handleClose}
    />
  );
}
export default CreateUserModal;
