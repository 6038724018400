import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Button,
  Grid,
  Menu,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Calendar as CalendarIcon } from "react-feather";
import DatePicker from "./DatePicker";
import Consultancyfilter from './ConsultancyFilter';
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  headerAccent: {
    height: 20,
    width: 4,
    backgroundColor: theme.palette.primary.main,
    marginRight: 6,
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  menu: { margin: "10px", width: "285px" },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function Header({ 
  className, 
  dateSelect, 
  title,
  elementType,
  defeatMargin,
  headerAccent,
  consultancySelect,
  activeConsultancies,
  consultanciesTree,
 }) {
  const classes = useStyles();
  const actionRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [t] = useTranslation(["common", "tooltips"]);

  return (
    <Grid
      container
      spacing={3}
      justify="space-between"
      className={clsx(classes.root, className)}
    >
      <Grid item style={{ display: "flex" }}>
        <Box style={{ display: "flex", alignItems: "center", height: "100%" }}>
          {headerAccent ? <div className={classes.headerAccent} /> : null}
          <Typography
            style={defeatMargin ? null : { marginTop: "8px" }}
            variant={ elementType }
            align="center"
          >
            {title
              ? title
              : null}
          </Typography>
        </Box>
        
        {dateSelect && (
          <Tooltip
            enterTouchDelay={1}
            classes={{
              tooltip: classes.tooltip,
            }}
            style={{ marginTop: "10px", marginLeft: "10px" }}
            title={t("tooltips:analyticsInformation")}
          >
            <InfoIcon />
          </Tooltip>
        )}
      </Grid>
      {consultancySelect && (
        <Grid item>
          <Consultancyfilter activeConsultancies={activeConsultancies} consultanciesTree={consultanciesTree} />
        </Grid>
      )}
      {dateSelect && (
        <Grid item>
          <Button ref={actionRef} onClick={() => setMenuOpen(true)}>
            <SvgIcon fontSize="small" className={classes.actionIcon}>
              <CalendarIcon />
            </SvgIcon>
            {t("common:changeDateRangeLabel")}
          </Button>
          <Menu
            className={classes.menu}
            anchorEl={actionRef.current}
            onClose={() => setMenuOpen(false)}
            open={isMenuOpen}
            PaperProps={{ className: classes.menu }}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <DatePicker />
          </Menu>
        </Grid>
      )}
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
