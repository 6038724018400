import React, { useState } from 'react';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CandidateListItem from './CandidateListItem';
import ShareWidget from './../../../components/ShareWidget';

const useStyles = makeStyles(() => ({
  details: {
    marginLeft: '20px'
  },
  tableContainer: {
    padding: '10px',
    paddingTop: '0px !important',
    height: '65vh',
    overflow: 'auto'
  },
  tHead: {
    backgroundColor: '#ffffff',
    textAlign: 'center'
  },
  button: {
    width: '70%',
    marginBottom: '15px',
    textAlign: 'left !important'
  },
  container: {
    padding: '10px'
  }
}));

const CandidateList = ({ candidates, handleRemove }) => {

  const classes = useStyles();

  const [isShareWidgetOpen, setIsShareWidgetOpen] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(0);

  const columns = [
    { id: 'image', label: '' },
    { id: 'name', label: 'Details' },
    { id: 'status', label: 'Status' },
    { id: 'share', label: 'Share' },
    { id: 'action', label: 'Action' }
  ];

  const toggleShareWidget = (candidateId = 0) => {
    setSelectedCandidateId(candidateId);
    setIsShareWidgetOpen(!isShareWidgetOpen);
  };

  return (
    <>
      <Card className={classes.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {
                columns.map((column) => (
                  <TableCell
                    className={classes.tHead}
                    key={column.id} >
                    { column.label }
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          
          <TableBody className={classes.contactList}>
            {
              candidates?.map((candidate, index) => (
                <>
                  <CandidateListItem candidate={ candidate } index={ index } handleRemove={ handleRemove } toggleShareWidget={ toggleShareWidget } />
                </>
              ))
            }
          </TableBody>
        </Table>
      </Card >

      <ShareWidget isModalOpen={ isShareWidgetOpen } toggleModal={ toggleShareWidget } candidateId={ selectedCandidateId } />
    </>
  );

};

export default CandidateList;
