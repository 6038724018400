import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Create from '@material-ui/icons/Create';
import { Select, MenuItem, Typography, TextField, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import moment from 'moment';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { UPDATE_TALENT_ALERT_STATUS } from './../../../actions/talentAlertActions';

import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';

const useStyles = makeStyles(() => ({
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px'
  }
}));

const TalentAlertStatus = () => {

  const [t] = useTranslation(['campaigns', 'common', 'tooltips', 'newTranslations']);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const talentAlert = useSelector((state) => state.talentAlert);
  const consultancyId = useSelector((state) => state.session.consultancyId);

  const { id: campaignId, completedAt } = talentAlert;

  const [statuses, setStatuses] = useState([]);
  const [campaignCompleted, setCompleted] = useState({ date: completedAt, touched: false });
  const [defaultCompleted] = useState(completedAt);
  const [editComplete, setEditComplete] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState({});

  useEffect(() => {
    const talentAlertStatusesData = {
      campaignId,
      consultancyId
    };

    talentAlertApi.getTalentAlertStatuses(talentAlertStatusesData)
      .then((response) => {
        setStatuses(response.data);
        const campaignStatus = response.data.find((st) => st.id === talentAlert.campaignStatusId);
        setCampaignStatus(campaignStatus);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Failed to get campaign statuses', { variant: 'error' });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setCampaignStatus(event.target.value);

    dispatch({
      type: UPDATE_TALENT_ALERT_STATUS,
      status: event.target.value
    });

    talentAlertApi.updateTalentAlertStatus({ campaignId, consultancyId, statusId: event.target.value.id })
      .then(() => {
        enqueueSnackbar('Campaign Status Updated', { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar('Status Update Failed', { variant: 'error' });
      });
  };

  const handleDateChange = (event) => {
    setCompleted({ date: moment(event.target.value).format('YYYY-MM-DD'), touched: true });
  };

  const cancelDateChange = () => {
    setCompleted({ date: moment(defaultCompleted).format('YYYY-MM-DD'), touched: false });
    setEditComplete(false);
  };

  return (
    <>
      <BlockHeader
        headerTooltip={t("tooltips:campaignStatusSelection")}
        card
        header={t("newTranslations:talentAlertStatus")} />
      
      <Select
        fullWidth
        variant="outlined"
        name={t("campaigns:campaignStatus")}
        onChange={handleChange}
        value={campaignStatus} >
        {
          statuses.map((status) => (
            <MenuItem key={status.id} value={status}>
              {t(`newTranslations:${(status?.status).toLowerCase()}`)}
            </MenuItem>
          ))
        }
      </Select>
      
      {
        campaignStatus.id === 4 && (
          <Grid
            className={classes.grid} >
            {
              editComplete ? (
                <>
                  <TextField
                    type="date"
                    defaultValue={moment(campaignCompleted.date).format("YYYY-MM-DD").toString()}
                    onChange={handleDateChange} />
                  
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={!campaignCompleted.touched} >
                    {t("common:save")}
                  </Button>
                  
                  <Button onClick={cancelDateChange}>
                    {t("common:cancel")}
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h5" display="inline">
                    { t("campaigns:campaignCompletedOn") }{ " " }
                    { moment(campaignCompleted.date).format("DD/MM/YYYY") }
                  </Typography>
                  
                  <Create onClick={() => setEditComplete(true)} />
                </>
              )
            }
          </Grid>
        )
      }
      </>
  );

};

export default TalentAlertStatus;
