import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Button,
} from "@material-ui/core";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import AddKCQModal from "./AddKCQModal";
import AddKCQFromSetModal from "./AddKCQFromSetModal";
import * as modulesApi from "src/api/modulesApi";
import { useDispatch, useSelector } from "react-redux";
import { SET_PERSONAL_QUESTION_SETS, SET_QUESTION_SETS } from "src/actions/ConsultancyModules/kcq/kcqActions";
import * as CampaignApi from 'src/api/campaignApi';
import {
  CREATE_CAMPAIGN_KCQS,
  UPDATE_CAMPAIGN_CONTACTS,
  UPDATE_CAMPAIGN
} from "src/actions/campaignActions";
import { SET_TALENT_ALERT_KCQ } from './../../../../actions/talentAlertActions';
import { useSnackbar } from "notistack";

import * as talentAlertApi from './../../../../api/talentAlertApi';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginLeft: '0px'
  }
}));


function AddKCQOptionModal({ open, handleClose }) {
  const [t] = useTranslation(["candidates", "common", "modues", "tooltips"]);
  const classes = useStyles();
  const [state, setState] = useState({
    addFromCategories: false,
    addFromSets: false
  })
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userId = useSelector((state) => state.session.userId)
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaign = useSelector((state) => state.campaign);
  const talentAlert = useSelector((state) => state.talentAlert);

  const campaignId = campaign.id ? campaign.id : talentAlert.id;

  const getKCQModal = function () {
    setState({
      addFromSets: false,
      addFromCategories: true
    })
    // handleClose()
  }

  const getSetsModal = function () {
    setState({
      addFromSets: true,
      addFromCategories: false
    })
  }

  const getQuestionSets = () => {
    return modulesApi.getGlobalQuestionSets().then((response) => {
      dispatch({ type: SET_QUESTION_SETS, questionSets: response.data });
    });
  };

  const getKCQPersonalSets = () => {
    return modulesApi.getPersonalQuestionSets(userId, consultancyId).then((response) => {
      dispatch({ type: SET_PERSONAL_QUESTION_SETS, personalQuestionSets: response.data });
    });
  };


  const onKCQSuccess = (kcqData, fromSets) => {
    if (kcqData?.selectedQuestions?.length) {
      let request;

      if (talentAlert && talentAlert.isTalentAlertCampaign) {
        request = talentAlertApi.assignKCQToCampaign(kcqData, consultancyId, campaignId);
      } else {
        request = CampaignApi.assignKCQToCampaign(kcqData, consultancyId, campaignId);
      }

      return request
        .then((response) => {
          if (kcqData.inviteContacts) {
            enqueueSnackbar(t("snackbar:kcqSentToContact"), {
              variant: "success",
            });
          } else {
            enqueueSnackbar(t("snackbar:kcqAddedToCampaign"), {
              variant: "success",
            });
          }

          dispatch({
            type: CREATE_CAMPAIGN_KCQS,
            currentCampaignKcq: {
              name: response.data.name,
              questions: response.data.questions,
            },
          });

          dispatch({
            type: SET_TALENT_ALERT_KCQ,
            currentTalentAlertKCQ: {
              name: response.data.name,
              questions: response.data.questions
            }
          });

          if (response.data.contact) {
            let campaignContacts = [...campaign.campaignContacts];
            campaignContacts.push({ ...response.data.contact });
            let uninvitedContacts = [...campaign.uninvitedContacts];
            uninvitedContacts.push({ ...response.data.contact });
            dispatch({
              type: UPDATE_CAMPAIGN_CONTACTS,
              campaignContacts,
              uninvitedContacts,
            });
          }
        })
        .catch((error) => {
          enqueueSnackbar(t("snackbar:kcqUpdateFailed"), { variant: "error" });
        });
    } else {
      if (!fromSets) {
        return CampaignApi.inviteContactToSelectKCQs(
          kcqData,
          consultancyId,
          campaignId
        ).then(() =>
          CampaignApi.getCampaignDetails(consultancyId, campaignId).then(
            (response) => {
              dispatch({
                type: UPDATE_CAMPAIGN,
                campaign: response.data,
              });
            }
          )
        );
      }
      else {

        return CampaignApi.inviteContactToSelectKCQSets(
          kcqData,
          consultancyId,
          campaignId
        ).then(() =>
          CampaignApi.getCampaignDetails(consultancyId, campaignId).then(
            (response) => {
              dispatch({
                type: UPDATE_CAMPAIGN,
                campaign: response.data,
              });
            }
          )
        );
      }
    }
  };

  useEffect(() => {
    getQuestionSets();
    getKCQPersonalSets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ModalFormWrapper
      formTitle={t("campaigns:addKCQTitle")}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth={"sm"}
    >
      <Grid container spacing={3} className={classes.mainGrid} justify="space-between" xs={12}>
        <Button color="primary"
          size="large"
          type="submit"
          variant="contained" onClick={getSetsModal}>
          Choose from personal set
        </Button>

        <Button color="primary"
          size="large"
          type="submit"
          variant="contained" onClick={getKCQModal}>
          Choose from default library
        </Button>
      </Grid>

      <AddKCQModal open={state.addFromCategories}
        onSuccess={onKCQSuccess}
        handleClose={() => {
          setState({
            addFromCategories: false,
            addFromSets: false
          })
          handleClose();
        }}></AddKCQModal>
      <AddKCQFromSetModal open={state.addFromSets}
        onSuccess={onKCQSuccess}
        handleClose={() => {
          setState({
            addFromCategories: false,
            addFromSets: false
          })
          handleClose();
        }}></AddKCQFromSetModal>
    </ModalFormWrapper>
  );
}
export default AddKCQOptionModal;
