import {
  Grid,
  Typography,
  Button
} from '@material-ui/core';
import React from 'react';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';

function SubmissionConfirmationModal({ video, open, onClose }) {
  return (
    <ModalFormWrapper handleOpen={open} handleClose={onClose} formTitle={"Are You Sure?"} maxWidth={"sm"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="body2">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.
          </Typography>
        </Grid>
        {/* DISPLAY A LIST OF ITEMS THAT THE USER HASN'T COMPLETED, I.E. A MISSING REQUIRED DOCUMENT, A KCQ ANSWER, VIDEO ASSESSMENT, ETX */}
        <Grid item xs={12}>
          <Typography variant="h2" color="textPrimary">You're missing the following:</Typography>
          <Typography variant="body2" color="textPrimary">KCQ</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary">Submit</Button>
        </Grid>
      </Grid>
    </ModalFormWrapper>
  );
}
export default SubmissionConfirmationModal;
