import React, { useState } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import Create from "@material-ui/icons/Create";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import DocumentVisibilityCheckBox from "src/views/Campaigns/CampaignDetail/CampaignDocuments/DocumentVisibilityCheckBox";
import DocumentTypeRadioButtons from './../views/Campaigns/CampaignDetail/CampaignDocuments/DocumentTypeRadioButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    marginTop: theme.spacing(2),
  },
  documentList: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "no-wrap",
      flexDirection: "column",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  buttonGroup: {
    marginLeft: "15px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      marginTop: "10px",
    },
  },
  controlRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

const DocumentList = ({ documents, functions }) => {
  const [active, setActive] = useState(null);
  const {
    handleChange,
    onSave,
    removeDocument,
    onNew,
    handleVisibilityChange,
    handleDocumentTypeChange
  } = functions;

  const saveDocument = (document, index) => {
    onSave(document, index);
    setActive(null);
  };

  const newDocument = () => {
    onNew();
    setActive(documents.length);
  };

  const classes = useStyles();
  const [t] = useTranslation(["sysadmin", "common", "snackbar"]);
  return (
    <Grid className={classes.documentList}>
      {documents?.map((document, index) => (
        <Grid
          key={index.toString()}
          style={{ padding: "30px 0" }}
          xs={12}
          md={10}
          xl={6}
        >
          <Grid className={classes.row}>
            <Grid xs={12}>
              <TextField
                fullWidth
                variant="outlined"
                value={document.name}
                disabled={active !== index}
                onChange={(event) => handleChange(event, index)}
                autoFocus
              />
            </Grid>
            <Grid xs={12} md={6} className={classes.buttonGroup}>
              {active !== index && <Create onClick={() => setActive(index)} />}
            </Grid>
          </Grid>
          <Grid xs={12} md={8}>
            <Grid className={classes.controlRow}>
              <Grid>
                <Typography
                  className={classes.subHeader}
                  color="primary"
                  variant="h4"
                >
                  {t("campaigns:campaignDocumentVisibilitySelection")}
                </Typography>

                <DocumentVisibilityCheckBox
                  id={`${index}_visibility`}
                  visibility={document.visibility}
                  onChange={handleVisibilityChange}
                  active={active !== index}
                />

                {
                  handleDocumentTypeChange && (
                    <>
                      <Typography
                        className={classes.subHeader}
                        color="primary"
                        variant="h4"
                      >
                        {t("campaigns:campaignDocumentTypeSelection")}
                      </Typography>

                      <DocumentTypeRadioButtons
                        index={ index }
                        active={ active === index }
                        documentType={ document.documentType }
                        onChange={ handleDocumentTypeChange } />
                    </>
                  )
                }
              </Grid>

              {active === index ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => saveDocument(document, index)}
                  >
                    {t("common:save")}
                  </Button>
                  <Button color="primary" onClick={() => setActive(false)}>
                    {t("common:cancel")}
                  </Button>
                </>
              ) : (
                <Button
                  color="primary"
                  onClick={() => removeDocument(document, index)}
                >
                  {t("common:remove")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid xs={12} style={{ marginTop: "15px" }}>
        <Button color="primary" variant="contained" onClick={newDocument}>
          {t("common:add")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DocumentList;
