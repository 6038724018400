import React, { useState, useEffect } from "react";
import Results from "./Results";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";

function CampaignListView() {
  const [campaigns, setCampaigns] = useState(null);
  const [consultancies, setConsultancies] = useState([]);
  const [t] = useTranslation(["common"]);

  const getAllCampaigns = () => {
    return SystemAdminApi.getAllCampaigns()
      .then((response) => {
        setCampaigns(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getConsultancies = () => {
    return SystemAdminApi.getConsultancies()
      .then((response) => {
        setConsultancies(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getAllCampaigns();
      getConsultancies();
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <PageTitle pageTitle={t("common:allCampaigns")} />
      <ContentWrapper>
        {campaigns ? (
          <Results campaigns={campaigns} consultancies={consultancies} />
        ) : null}
      </ContentWrapper>
    </>
  );
}

export default CampaignListView;
