import axios from '../utils/axios';

export const getAllUserNotifications = () => {
  return axios
    .get('/Notifications/fetchAllUserNotifications')
    .catch((error) => {
      throw error;
    });
};

export const fetchUserNotifications = (take) => {
  return axios
    .get('/Notifications/fetchUserNotifications', {
      params: { take }
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const setNotificationRead = (notificationId) => {
  return axios
    .put('/Notifications/setNotificationRead', { notificationId })
    .catch((error) => {
      throw error;
    });
};

export const setAllNotificationsRead = () => {
  return axios.put('/Notifications/setAllNotificationsRead').catch((error) => {
    throw error;
  });
};

export const deleteNotification = (notificationId) => {
  return axios
    .delete('/Notifications/deleteNotification', {
      params: {
        notificationId
      }
    })
    .catch((error) => {
      throw error;
    });
};

export const deleteAllNotifications = () => {
  return axios
    .delete('/Notifications/deleteAllNotifications')
    .catch((error) => {
      throw error;
    });
};

export const getUserNotificationSettings = () => {
  return axios
    .get('/UserNotificationSettings/getUserNotificationSettings')
    .catch((error) => {
      throw error;
    });
};

export const saveUserNotificationSettings = (settings) => {
  return axios
    .post('UserNotificationSettings/saveUserNotificationSettings', {
      settings,
    })
    .catch((error) => {
      throw error;
    });
};

export const getNotificationTypes = (roleType, consultancySubscriptionPlanId) => {
  return axios
    .get('/NotificationTypes/getNotificationTypes', {
      params: { roleType, consultancySubscriptionPlanId },
    })
    .catch((error) => {
      throw error;
    });
};
