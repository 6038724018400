import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { DeleteRounded, EditRounded, DescriptionRounded, Check } from '@material-ui/icons';

import IconListItem from './../../../../components/LayoutComponents/IconListItem';

import { ADMIN, CONSULTANT, CANDIDATE, CONTACT } from './../../../../constants/roles';

const RequiredTalentAlertDocumentListCard = ({ document, onRemove, setMethods }) => {

  const [t] = useTranslation(['candidates']);

  const role = useSelector((state) => state.session.roleType);

  const [removeLoading, setRemoveLoading] = useState(false);

  const { setEditDocument, setAddDocumentModalOpen } = setMethods;

  const openEditModal = (text) => {
    setAddDocumentModalOpen(true);

    if (text) {
      setEditDocument(document, true);
    } else {
      setEditDocument(document);
    }
  };

  const handleRemove = () => {
    setRemoveLoading(true);
    onRemove(document.id)
      .then(() => {
        setRemoveLoading(false);
      });
  };

  if (role === CONTACT) {
    return (
      <IconListItem
        startIcon={<DescriptionRounded />}
        href={document.url}
        text={document.name}
        startTip={t("common:viewDocument")}
        newWindow={true} />
    );
  }

  if (role === ADMIN || role === CONSULTANT || role === CANDIDATE) {
    return (
      <IconListItem
        newWindow={true}
        href={document.url}
        text={document.name}
        startIcon={document.url ? (<DescriptionRounded />) : (<DescriptionRounded color="disabled" />)}
        startTip={document.url ? t("common:viewDocument") : null}
        endIcon={<EditRounded />}
        endFunc={openEditModal}
        endTip={t("common:editDocument")}
        startDisabled={document.url ? false : true}
        middleIcon={document.url ? <Check /> : null}
        middleTip={t("common:viewDocument")} />
    );
  }

  if ((role === ADMIN || role === CONSULTANT) && !window.location.href.includes("candidates")) {
    return (
      <IconListItem
        href={document.url}
        text={document.name}
        startIcon={<DeleteRounded />}
        startFunc={handleRemove}
        startTip={t("common:removeDocument")}
        startLoading={removeLoading}
        endIcon={<EditRounded />}
        endFunc={openEditModal}
        endTip={t("common:editDocument")} />
    );
  }

  return (
    <IconListItem
      startIcon={document.url ? (<DescriptionRounded />) : (<DescriptionRounded color="disabled" />)}
      href={document.url}
      text={document.name}
      startTip={document.url ? t("common:viewDocument") : null}
      newWindow={true}
      startDisabled={document.url ? false : true} />
  );

};

export default RequiredTalentAlertDocumentListCard;
