/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router';
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import validate from "validate.js";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Button,
	TextField,
	Typography,
	Link,
	CircularProgress,
	Box,
} from "@material-ui/core";
import { loginAndCreateSession } from "src/actions";

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

const schema = {
	email: {
		presence: { allowEmpty: false, message: "is required" },
		email: true,
	},
	password: {
		presence: { allowEmpty: false, message: "is required" },
	},
};

const useStyles = makeStyles((theme) => ({
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		width: "50%",
		height: "3rem",
		margin: "1rem auto 0.5rem",
		borderRadius: 5,
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
}));

function LoginForm({
	toggleForgottenPassword,
	buttonColour,
	className,
	...rest
}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const [t, i18n] = useTranslation([
		"common",
		"login",
		"validation",
		"snackbar",
	]);
	const { enqueueSnackbar } = useSnackbar();

	const location = useLocation();

	const [loading, setLoading] = useState(false);
	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});
	const [redirectPathname, setRedirectPathname] = useState('');

	useEffect(() => {
		if (location.state && location.state.redirectPahtname) {
			setRedirectPathname(location.state.redirectPahtname);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);

		const loginData = {
			...formState.values,
			host: window.location.hostname
		};

		loginAndCreateSession(dispatch, loginData)
			.then((consultancySubscriptionPlanId) => {
				if (redirectPathname) {
					window.location.href = redirectPathname;
					return;
				}
				
				if (consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
					window.location.href = '/dashboard';
					return;
				}

				window.location.href = '/dashboard/talent-alerts';
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(
					`${t("validation:Login Failed")}: ${t(`snackbar:${error.message}`)}`,
					{ variant: "error" }
				);
				setLoading(false);
			});
	};

	const hasError = (field) => {
		if (formState.touched[field] && formState.errors[field]) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
			const errors = validate(formState.values, schema);
			setFormState((prevFormState) => ({
				...prevFormState,
				isValid: !errors,
				errors: errors || {},
			}));
	}, [formState.values]);

	return (
		<form
			{...rest}
			className={clsx(classes.root, className)}
			onSubmit={handleSubmit}
		>
			<div className={classes.fields}>
				<TextField
					error={hasError("email")}
					fullWidth
					helperText={
						hasError("email")
							? t(`validation:${formState.errors.email[0]}`)
							: null
					}
					label={t("common:emailAddressLabel")}
					name="email"
					onChange={handleChange}
					value={formState.values.email || ""}
					variant="outlined"
				/>
				<TextField
					error={hasError("password")}
					fullWidth
					helperText={
						hasError("password")
							? t(`validation:${formState.errors.password[0]}`)
							: null
					}
					label={t("common:passwordLabel")}
					name="password"
					onChange={handleChange}
					type="password"
					value={formState.values.password || ""}
					variant="outlined"
				/>
			</div>
			<Box display="flex" flexDirection="column" justifyContent="center">
				<Button
					className={classes.submitButton}
					disabled={!formState.isValid}
					size="large"
					type="submit"
					variant="contained"
					style={{
						backgroundColor: buttonColour,
						color: theme.palette.getContrastText(
							buttonColour ? buttonColour : theme.palette.primary.main
						),
					}}
				>
					{loading ? (
						<CircularProgress className={classes.progressCircle} size={25} />
					) : (
						t("login:login")
					)}
				</Button>
				<Typography
					variant="body1"
					align="center"
					style={{
						cursor: "pointer",
						color: buttonColour ? buttonColour : theme.palette.primary.main,
					}}
					onClick={toggleForgottenPassword}
				>
					{t("login:forgottenPassword")}
				</Typography>
			</Box>
		</form>
	);
}

LoginForm.propTypes = {
	className: PropTypes.string,
};

export default LoginForm;
