import React, { useEffect, useState, useCallback } from "react";

import { useSelector } from "react-redux";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";

import { profileMatches, mcQuaigScales } from "src/constants/mcQuaig";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { CONTACT } from "src/constants/roles";
import { saveCandidateMcQuaig } from "src/api/candidateApi";
import McQuaigChart from "src/components/McQuaigChart";
import McQuaigModal from "src/components/McQuaigModal";

const useStyles = makeStyles((theme) => ({
  graphRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  bold: {
    fontWeight: "bold",
  },
  echarts: {
    width: "100%",
  },
  scales: {
    display: "flex",
    flexDirection: "column",
  },
  bScales: {
    margin: "15px 0",
    "&:before": {
      float: "left",
      content: '""',
      width: "100%",
      height: "20px",
      background: "#000",
      marginRight: "20px",
      margin: "10px 0 15px 0",
    },
  },
  bScalesRed: {
    "&:before": {
      background: "#c44826",
    },
  },
  bScalesGreen: {
    "&:before": {
      background: "#009e4b",
    },
  },
  bScalesBlue: {
    "&:before": {
      background: "#0076bc",
    },
  },
  bScalesYellow: {
    "&:before": {
      background: "#ffd24f",
    },
  },
}));

const CandidateMcQuaig = ({ ...rest }) => {
  const [mcQuaigModal, setMcQuaigModal] = useState(false);
  const [mcQuaig, setMcQuaig] = useState({});
  const { consultancyId } = useSelector((state) => state.session);
  const classes = useStyles();
  const {
    campaignId,
    campaignTitle,
    candidateMcQuaig,
    campaignMcQuaig,
    candidateProfileId,
    firstName,
    lastName
  } = useSelector((state) => state.candidateCampaign.candidateProfile);
  const adminContactPreviewMode = useSelector(
    (state) => state?.campaign?.adminContactPreview
  );
    
  const role = useSelector((state) => state.session.roleType);

  const [t] = useTranslation([
    "candidates",
    "tooltips",
    "campaigns",
    "newTranslations",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setMcQuaigModal(true);
  };
  const handleClose = () => {
    setMcQuaigModal(false);
  };

  const handleSubmit = useCallback((updateMcQuaig) => {
    return saveCandidateMcQuaig({
      consultancyId,
      campaignId,
      candidateProfileId,
      mcQuaigId: candidateMcQuaig?.id,
      mcQuaig: updateMcQuaig,
    }).then((response) => {
      if (response.data && response.status === 200) {
        setMcQuaig(response.data);
        enqueueSnackbar("Saved McQuaig", { variant: "success" });
        handleClose();
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderScales = () => {
    return mcQuaigScales.map((scale) => {
      let color = "bScales" + scale.color;
      return (
        <Grid>
          <Grid
            xs={12}
            sm={1}
            lg={2}
            className={`${classes[color]} ${classes.bScales}`}
          ></Grid>
          <Grid xs>
            <Typography display="inline" variant="h4" className={classes.bold}>
              {t(`newTranslations:${scale.title}`).slice(0, 2)}
            </Typography>
            <Typography display="inline" variant="h4">
              {t(`newTranslations:${scale.title}`).slice(2)} &nbsp;↔&nbsp;&nbsp;
            </Typography>
            <Typography display="inline" variant="h4" className={classes.bold}>
              {t(`newTranslations:${scale.title2}`).slice(0, 2)}
            </Typography>
            <Typography display="inline" variant="h4">
              {t(`newTranslations:${scale.title2}`).slice(2)}
            </Typography>
          </Grid>
          <Typography variant="caption">
            ({t(`newTranslations:${scale.desc}`)})
          </Typography>
        </Grid>
      );
    });
  };

  useEffect(() => {
    setMcQuaig(candidateMcQuaig);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let profile = profileMatches.find(
    (type) => type.value === mcQuaig?.candidateMatch
  );

  const roleType = useSelector((state) => state.session.roleType);

  return (
    <>
      <Card style={{ marginTop: "15px" }}>
        <BlockHeader
          headerTooltip={
            roleType === CONTACT || adminContactPreviewMode
              ? null
              : t("tooltips:inputCandidatesMcQuaig")
          }
          card
          padding
          header={t("campaigns:mcQuaigWordSurvey")}
        />
        <CardContent>
          <Grid xs={12} style={{ marginBottom: "15px" }}>
            <Typography style={{ margin: "15px 0" }} variant="h4">
              {t("candidates:mcQuaigJobFit")} :{" "}
              <span style={{ fontWeight: "bold", fontSize: "1.3em" }}>
                {t(`newTranslations:${profile?.name}`)}
              </span>
            </Typography>
            <div style={{ paddingBottom: "25px" }} class="ql-editor">
              {parse(mcQuaig?.summary || "")}
            </div>
            <Grid xs={12} className={classes.graphRow}>
              {mcQuaig && (
                <>
                  <Grid xs={12} lg={6}>
                    <McQuaigChart
                      data={{
                        scores: { ...campaignMcQuaig?.scores },
                        title: `${campaignTitle} Profile`,
                      }}
                    />
                  </Grid>
                  <Grid xs={12} lg={6}>
                    <McQuaigChart
                      data={{
                        scores: { ...mcQuaig?.realScores },
                        title: `${firstName} ${lastName} Profile`,
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid xs={12} style={{ margin: "0 auto" }}>
              <Grid xs={12}>{renderScales()}</Grid>
            </Grid>
          </Grid>
          {!rest?.guestView && role !== CONTACT && !adminContactPreviewMode && (
            <CardButton
              btnLabel={t("candidates:mcQuaigEdit")}
              btnFunc={handleOpen}
              btnVariant="contained"
            />
          )}
        </CardContent>
      </Card>
      <McQuaigModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        mcQuaig={mcQuaig}
        open={mcQuaigModal}
        candidate={true}
        headerTitle={t("campaigns:mcQuaigWordSurvey")}
        passedTooltip={t("tooltips:insertMcQuaigWordSurveyScores")}
      />
    </>
  );
};

export default CandidateMcQuaig;
