import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Avatar, Divider, ListItem, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import FaceIcon from "@material-ui/icons/Face";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  blackH5: {
    color: "#8a8a8a",
  },
}));

function SearchResultListItem({ searchResult, handleClick }) {
  const [t] = useTranslation(["common"]);
  const classes = useStyles();

  const handleRouterClick = (event, index) => {
    handleClick({
      result: index,
    });
  };

  return (
    <ListItem
      button
      onClick={(event) => handleRouterClick(event, searchResult)}
    >
      <ListItemAvatar>
        <Avatar>
          {searchResult._index === "campaigns" ? (
            <AssignmentIndIcon />
          ) : searchResult._index === "employers" ? (
            <BusinessIcon />
          ) : searchResult._index === "contacts" ? (
            <PersonIcon />
          ) : searchResult._index === "talentalerts" ? (
            <PeopleIcon />
          ) : searchResult._index === "candidates" ? (
            <FaceIcon />
          ) : (
            ""
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="h4">{searchResult._source.title}</Typography>
        }
        secondary={
          searchResult._index === "campaigns" ? (
            <Typography className={classes.blackH5} variant="h5">
              {t("common:campaign")}
            </Typography>
          ) : searchResult._index === "employers" ? (
            <Typography className={classes.blackH5} variant="h5">
              {t("common:employer")}
            </Typography>
          ) : searchResult._index === "contacts" ? (
            <Typography className={classes.blackH5} variant="h5">
              {t("common:contact")}
            </Typography>
          ) : searchResult._index === "talentalerts" ? (
            <Typography className={classes.blackH5} variant="h5">
              {t("common:talentAlert")}
            </Typography>
          ) : searchResult._index === "candidates" ? (
            <Typography className={classes.blackH5} variant="h5">
              {t("common:candidate")}
            </Typography>
          ) : (
            ""
          )
        }
      />
      <Divider />
    </ListItem>
  );
}

export default SearchResultListItem;
