import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { useSnackbar } from 'notistack';

import * as actionTypes from './../../../../actions';

import * as talentAlertApi from './../../../../api/talentAlertApi';

import { BlockHeader } from './../../../../components/LayoutComponents/BlockHeader';
import DraggableRequiredCandidateTalentAlertDocuments from './DraggableRequiredCandidateTalentAlertDocuments';
import RequiredTalentAlertDocumentListCard from './RequiredTalentAlertDocumentListCard';
import CandidateTalentAlertDocumentModal from './CandidateTalentAlertDocumentModal';
import CandidateDocumentUploadModal from './../../../CandidateCampaignDetail/CandidateDetails/CandidateDocumentUploadModal';

import { CONTACT, CANDIDATE } from './../../../../constants/roles';
import { NOT_SUBMITTED } from './../../../../constants/candidateStatusIds';

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    alignItems: 'center'
  },
  action: {
    alignSelf: 'center'
  },
  documentList: {
    marginBottom: theme.spacing(4)
  },
  topPadding: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const RequiredCandidateTalentAlertDocuments = ({ candidateView, campaignRequiredDocuments }) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'tooltips']);
  const classes = useStyles();

  const { id: profileId, candidateStatus } = useSelector((state) => state?.candidateCampaign?.candidateProfile || {});
  const role = useSelector((state) => state.session.roleType);
  const campaignId = useSelector((state) => {
    if (state.talentAlert.id) {
      return state.talentAlert.id;
    } else if (state.candidateCampaign?.campaignId) {
      return;
    } else {
      return state.candidateCampaign.candidateProfile.campaignId;
    }
  });
  const { consultancyId } = useSelector((state) => state.session);

  const [isSubmitted, setIsSubmitted] = useState(candidateStatus === NOT_SUBMITTED);
  const [requiredDocuments, setRequiredDocuments] = useState();
  const [editDocument, setEditDocument] = useState({});
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);
  const [textModal, setTextModal] = useState(null);

  const documentsWithUrl = requiredDocuments?.filter((document) => role === CONTACT && document.url && document.visibility.includes('contact'));
  const header = role === CONTACT ? `${t("campaigns:Candidate Documents")}` : `${t("campaigns:Candidate Documents")}`;
  const filteredRequiredDocuments = requiredDocuments?.filter((document) => document.url && document?.visibility?.includes('contact'));

  useEffect(() => {
    setIsSubmitted(candidateStatus !== NOT_SUBMITTED);
  }, [candidateStatus]);

  useEffect(() => {
    setRequiredDocuments([...campaignRequiredDocuments]);
  }, [campaignRequiredDocuments]);

  const onOpenModal = () => {
    setAddDocumentModalOpen(true);
  };

  const onCloseModal = () => {
    setAddDocumentModalOpen(false);
    setTextModal(null);
    setEditDocument({});
  };

  const handleRemove = (id) => {
    talentAlertApi.removeCandidateRequiredDocument(consultancyId, campaignId, id)
      .then(() => {
        const index = requiredDocuments.indexOf(requiredDocuments.find((document) => document.id === id));

        if (index < 0) {
          return;
        }

        dispatch({ type: actionTypes.REMOVE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT, id });
        setRequiredDocuments((prevDocuments) => [...prevDocuments.filter((item, i) => i !== index)]);
        enqueueSnackbar(t('common:documentDeleted'), { variant: 'success' });
        setEditDocument(false);
        onCloseModal();
      })
      .catch(() => {
        enqueueSnackbar(t('common:documentDeletedError'), { variant: 'error' });
      });
  };

  const addDocument = (document) => {
    if (!document.update) {
      setRequiredDocuments((prevDocuments) => [...prevDocuments, document]);
      return;
    }

    const index = requiredDocuments.indexOf(requiredDocuments.find((doc) => doc.id === document.id));

    if (!document.candidateDocument) {
      delete document.update;
      requiredDocuments[index] = document;
      return;
    }

    delete document.update;
    delete document.candidateDocument;

    if (document.text) {
      requiredDocuments[index].text = document.text;
      requiredDocuments[index].document = {};
      requiredDocuments[index].url = '';
    } else {
      requiredDocuments[index].document = document.document;
      requiredDocuments[index].url = document.url;
      requiredDocuments[index].text = '';
    }

    setRequiredDocuments([...requiredDocuments]);
  };

  const onDragEnd = (result) => {
    // return if item was dropped outside
    if (!result.destination) return;
    // return if the item was dropped in the same place
    if (result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) return;

    // get the items array
    const newItems = requiredDocuments;

    // get the draggedItems
    const draggedItem = newItems[result.source.index];

    // delete the item from source position and insert it to the destination positon
    newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, draggedItem);
    //assign priority to all items
    newItems.forEach((item, index) => (item.priority = index + 1));

    // update state
    setRequiredDocuments(newItems);
  };

  const saveDocumentsOrder = () => {
    talentAlertApi.saveCandidateDocumentsPriorityOrder(consultancyId, campaignId, requiredDocuments)
      .then(() => {
        dispatch({
          type: actionTypes.UPDATE_CAMPAIGN_REQUIRED_DOCUMENTS_PRIORITY,
          documents: requiredDocuments
        });

        enqueueSnackbar(t('snackbar:succesfullySavedDocumentsOrder'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('snackbar:unableToSaveDocumentsOrder'), { variant: 'error' });
      });
  };

  return (
    <>
      <Grid container spacing={0} className={classes.gridContainer}>
        <BlockHeader
          card
          header={header}
          icon={!candidateView && (<AddIcon />)}
          btnFunc={onOpenModal}
          tooltip={t("common:addDocument")}
          headerTooltip={role === CANDIDATE || role === CONTACT ? t("tooltips:reviewCandidateDocs") : t("tooltips:addDeleteReorderCandidateDocumentsBlock")} />
        {
          role === CONTACT && !documentsWithUrl?.length && (
            <Typography variant='h5'>
              {`${ t("campaigns:noDocumentsProvided") }`}
            </Typography>
          )
        }

        {
          role === CANDIDATE && (
            <Grid item xs={12}>
              <Typography className={classes.topPadding} variant="h5">
                { t("campaigns:candidateRequiredDocsInstructions") }
              </Typography>
            </Grid>
          )
        }
      </Grid>

      {
        !candidateView && role !== CONTACT ? (
          <DraggableRequiredCandidateTalentAlertDocuments
            onDragEnd={onDragEnd}
            requiredDocuments={requiredDocuments}
            handleRemove={handleRemove}
            setEditDocument={setEditDocument}
            setAddDocumentModalOpen={setAddDocumentModalOpen}
            setTextModal={setTextModal}
            consultancyId={consultancyId}
            candidateView={candidateView}
            isSubmitted={isSubmitted}
            saveDocumentsOrder={saveDocumentsOrder} />
        ) : (
          <div className={classes.documentList}>
            {
              requiredDocuments?.length > 0 && filteredRequiredDocuments.map((document, index) => {
                if (role === CONTACT && document.url) {
                  return (
                    <RequiredTalentAlertDocumentListCard
                      key={index.toString()}
                      document={document}
                      onRemove={handleRemove}
                      setMethods={{ setEditDocument, setAddDocumentModalOpen, setTextModal }}
                      consultancyId={consultancyId}
                      candidateView={candidateView}
                      isSubmitted={isSubmitted} />
                  )
                }

                return null;
              })
            }
          </div>
        )
      }

      {
        candidateView ? (
          <CandidateDocumentUploadModal
            profileId={profileId}
            open={addDocumentModalOpen}
            onClose={onCloseModal}
            document={editDocument}
            campaignId={campaignId}
            onSubmit={addDocument}
            candidateView={candidateView}
            isSubmitted={isSubmitted} />
        ) : (
          <CandidateTalentAlertDocumentModal
            open={addDocumentModalOpen}
            onClose={onCloseModal}
            document={editDocument}
            campaignId={campaignId}
            onSubmit={addDocument}
            consultancyId={parseInt(consultancyId)}
            candidateView={candidateView}
            text={textModal}
            onRemove={handleRemove} />
        )
      }
    </>
  );

};

export default RequiredCandidateTalentAlertDocuments;
