import axios from '../utils/axios';

export const getActiveTheme = (consultancyId) => {
  return axios.get('/CustomThemes/getActiveTheme', {
    params: {
      consultancyId,
    },
  });
};

export const getThemesForConsultancy = (consultancyId) => {
  return axios.get('CustomThemes/getThemesForConsultancy', {
    params: {
      consultancyId,
    },
  });
};

export const createNewTheme = (
  {
    name,
    primary,
    nav,
    accent,
    title,
    heading,
    button,
    success,
    warning,
    error,
    info,
    themeObject,
  },
  consultancyId
) => {
  return axios.post(
    'CustomThemes/createNewTheme',
    {
      name,
      primary,
      nav,
      accent,
      title,
      heading,
      button,
      success,
      warning,
      error,
      info,
      themeObject,
    },
    {
      params: {
        consultancyId,
      },
    }
  );
};

export const setActiveThemeForConsultancy = (consultancyId, id) => {
  return axios.put('CustomThemes/setActiveThemeForConsultacy', null, {
    params: {
      consultancyId,
      id,
    },
  });
};

export const updateThemeForConsultancy = (
  consultancyId,
  id,
  {
    name,
    primary,
    nav,
    accent,
    title,
    heading,
    button,
    success,
    warning,
    error,
    info,
    themeObject,
  }
) => {
  return axios.put(
    'CustomThemes/updateThemeForConsultancy',
    {
      name,
      primary,
      nav,
      accent,
      title,
      heading,
      button,
      success,
      warning,
      error,
      info,
      themeObject,
    },
    {
      params: {
        consultancyId,
        id,
      },
    }
  );
};

export const deleteThemeForConsultancy = (consultancyId, id) => {
  return axios.delete('CustomThemes/deleteThemeForConsultancy', {
    params: {
      consultancyId,
      id,
    },
  });
};

export const getConsultancyFavicon = (consultancyId) => {
  return axios.get('Favicons/getConsultancyFavicon', {
    params: {
      consultancyId,
    },
  });
};

export const uploadConsultancyFavicon = (consultancyId, favicon) => {
  return axios
    .post('/Favicons/uploadConsultancyFavicon', favicon, {
      params: {
        consultancyId,
      },
    })
    .then((response) => {
      if (response.data !== null && response.data !== undefined) {
        return response.data;
      } else {
        throw Error('Failed to upload favicon');
      }
    });
};

export const getConsultancyBackground = (consultancyId) => {
  return axios.get('BackgroundImages/getConsultancyBackground', {
    params: {
      consultancyId,
    },
  });
};

export const uploadConsultancyBackground = (consultancyId, image) => {
  return axios
    .post('/BackgroundImages/uploadBackgroundImage', image, {
      params: {
        consultancyId,
      },
    })
    .then((response) => {
      if (response.data !== null && response.data !== undefined) {
        return response.data;
      } else {
        throw Error('Failed to upload background image');
      }
    });
};

export const deleteConsultancyBackgroundImage = (consultancyId) => {
  return axios.post('BackgroundImages/deleteConsultancyBackgroundImage', {
    params: {
      consultancyId,
    },
  });
};
