import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  UPDATE_CAMPAIGN_DOCUMENTS,
  UPDATE_SINGLE_CAMPAIGN_DOCUMENT,
} from "src/actions/campaignActions";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Typography,
  colors,
} from "@material-ui/core";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import DocumentVisibilityCheckBox from "./DocumentVisibilityCheckBox";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import AssetSelect from "src/components/AssetSelect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormFileDropzone from "src/components/FormFileDropzone";
import { useSnackbar } from "notistack";
import { saveCampaignDocument } from "src/api/campaignApi";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { useCampaignOrVacancyTranslationPrefix } from '../../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  fileField: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  assetDropdown: {
    flex: 2,
    marginRight: 10,
  },
  fileDropzone: {
    flex: 1,
  },
  subheadingText: {
    width: "100%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  assetNewOrExisting: {
    width: "100%",
  },
}));

const schema = {
  documentTitle: {
    presence: { allowEmpty: false, message: "is required" },
  },
  asset: function(value, attributes, attributeName, options, constraints) {
    if (attributes.file && !attributes.file.length > 0) {
      return {
        presence: { allowEmpty: false, message: "^Must select an asset" },
      };
    } else {
      return {
        presence: false,
      };
    }
  },
  file: function(value, attributes, attributeName, options, constraints) {
    if (attributes.asset && !attributes.asset.id) {
      return {
        presence: { allowEmpty: false, message: "^Must upload a file" },
      };
    } else {
      return {
        presence: false,
      };
    }
  },
};

function AddCampaignDocumentModal({
  campaignId,
  document = {},
  consultancyId,
  open,
  handleAddDoc,
  onClose,
  onRemove,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(["candidates", "common", "snackbar", "vacancies"]);
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const { enqueueSnackbar } = useSnackbar();
  const [removeLoading, setRemoveLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const type = "single";
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      documentTitle: "",
      file: [],
      asset: {},
      visibility: ["private"],
    },
    touched: {},
    errors: {},
  });
  const [assetNewOrExisitng, setAssetNewOrExisitng] = useState(
    t(`${campaignsPrefix}:newFile`)
  );

  const assetOptions = [t(`${campaignsPrefix}:newFile`), t(`${campaignsPrefix}:existingFile`)];

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        documentTitle: document.documentTitle || "",
        file: document.file || [],
        asset: document.asset || {},
        visibility: document.visibility || ["private"],
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleAssetChange = useCallback((event, asset) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        asset: asset,
      },
      touched: {
        ...prevFormState.touched,
        asset: true,
      },
    }));
  }, []);

  const handleFileChange = useCallback((files) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        file: files,
      },
      touched: {
        ...prevFormState.touched,
        file: true,
      },
    }));
  }, []);

  const handleVisibilityChange = useCallback((visibility) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        visibility: visibility,
      },
      touched: {
        ...prevFormState.touched,
        visibility: true,
      },
    }));
  }, []);

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = (event) => {
    //Submitting file so needs to be form data
    event.preventDefault();
    let formData = new FormData();
    formData.append("documentTitle", formState.values.documentTitle);
    formData.append("visibility", formState.values.visibility);
    if (formState.values.file.length > 0) {
      formData.append("file", formState.values.file[0]);
    } else if (formState.values.asset) {
      formData.append("asset", JSON.stringify(formState.values.asset));
    } else {
      console.error("No new file provided");
    }
    setSaveLoading(true);
    //- If this modal is being used to create a campaign with default documents
    if (handleAddDoc) {
      let { documentTitle, visibility, asset, file } = formState.values;
      handleAddDoc({
        documentTitle,
        visibility,
        asset,
        file,
        formData,
        update: document.documentTitle ? true : false,
      });
      onClose();
      setSaveLoading(false);
      return;
    }
    saveCampaignDocument({
      campaignId,
      consultancyId,
      documentId: document.id,
      formData,
    })
      .then((savedDocument) => {
        if (document.id) {
          dispatch({
            type: UPDATE_SINGLE_CAMPAIGN_DOCUMENT,
            document: savedDocument,
          });
          enqueueSnackbar(t("snackbar:campaignDocumentUpdateSuccess"), {
            variant: "success",
          });
        } else {
          dispatch({
            type: UPDATE_CAMPAIGN_DOCUMENTS,
            document: savedDocument,
          });
          enqueueSnackbar(t("snackbar:campaignDocumentAddedSuccess"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:campaignDocumentSaveFailed"), {
          variant: "error",
        });
      })
      .then(() => {
        onClose();
        setSaveLoading(false);
        setRemoveLoading(false);
      });
  };

  const handleRemove = () => {
    setRemoveLoading(true);
    onRemove(document.id);
    setRemoveLoading(false);
    onClose();
  };

  return (
    <ModalFormWrapper
      formTitle={
        document.documentTitle
          ? `${t(`${campaignsPrefix}:editCampaignDocumentLabel`)} ${
              document.documentTitle
            }`
          : t(`${campaignsPrefix}:addCampaignDocumentLabel`)
      }
      handleOpen={open}
      handleClose={onClose}
      editDocument={true}
    >
      <form onSubmit={handleSubmit}>
        <div className={classes.fields}>
          {!document.documentTitle && (
            <TextField
              fullWidth
              label={t(`${campaignsPrefix}:documentNameLabel`)}
              name="documentTitle"
              variant="outlined"
              required
              onChange={handleChange}
              error={hasError("documentTitle")}
              value={formState.values.documentTitle || ""}
              disabled={document.documentTitle}
              helperText={
                hasError("documentTitle")
                  ? t(`validation:${formState.errors.documentTitle[0]}`)
                  : null
              }
            />
          )}
          <Autocomplete
            value={assetNewOrExisitng}
            className={classes.assetNewOrExisting}
            id="combo-box-demo"
            options={assetOptions}
            onChange={(event, newValue) => {
              setAssetNewOrExisitng(newValue);
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={t(`${campaignsPrefix}:fileSelection`)}
                margin="normal"
                variant="outlined"
              />
            )}
          />
          <div>
            {assetNewOrExisitng === t(`${campaignsPrefix}:existingFile`) && (
              <AssetSelect
                className={classes.assetDropdown}
                name="asset"
                label={t(`${campaignsPrefix}:selectFromExistingFiles`)}
                value={formState.values.asset}
                onChange={handleAssetChange}
              />
            )}
            {assetNewOrExisitng === t(`${campaignsPrefix}:newFile`) && (
              <FormFileDropzone
                className={classes.fileDropzone}
                value={
                  formState.values.file.length > 0
                    ? formState.values.file
                    : formState.values.asset
                }
                type={type}
                edit={document.documentTitle}
                onChange={handleFileChange}
              />
            )}
          </div>
          <Typography className={classes.subheadingText} variant="h5">
            {t(`${campaignsPrefix}:campaignDocumentVisibilitySelection`)}
          </Typography>
          <DocumentVisibilityCheckBox
            visibility={formState.values.visibility}
            onChange={handleVisibilityChange}
          />
        </div>
        <CardButton
          btnLabel2={t(`${campaignsPrefix}:removeDocumentLabel`)}
          btnFunc2={handleRemove}
          btnVariant2="outlined"
          btnLabel={
            document.documentTitle
              ? t(`${campaignsPrefix}:saveCampaignDocumentLabel`)
              : t(`${campaignsPrefix}:addDocumentLabel`)
          }
          btnFunc={handleSubmit}
          btnDisabled={!formState.isValid}
          btnVariant="contained"
          loading={saveLoading}
          loading2={removeLoading}
        />
      </form>
    </ModalFormWrapper>
  );
}
export default AddCampaignDocumentModal;
