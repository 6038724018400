import { makeStyles } from "@material-ui/styles";
import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Select,
  FormControl,
  Box,
  InputLabel,
  MenuItem,
  OutlinedInput,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import FullCalendar from "@fullcalendar/react";
import moment from "moment-timezone";
import Toolbar from "src/components/calendar/CalendarView/Toolbar";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timelinePlugin from "@fullcalendar/timeline";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { FwdBackButtons } from "src/components/LayoutComponents/FwdBackButtons";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  calendar: {
    "& .fc-unthemed th": {
      borderColor: theme.palette.divider,
    },
    "& .fc-unthemed td": {
      borderColor: theme.palette.divider,
    },
    "& .fc-unthemed td.fc-today": {
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-head": {
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-body": {
      backgroundColor: theme.palette.background.default,
    },
    "& .fc-axis": {
      ...theme.typography.body2,
    },
    "& .fc-list-item-time": {
      ...theme.typography.body2,
    },
    "& .fc-list-item-title": {
      ...theme.typography.body1,
    },
    "& .fc-list-heading-main": {
      ...theme.typography.h6,
    },
    "& .fc-list-heading-alt": {
      ...theme.typography.h6,
    },
    "& .fc th": {
      borderColor: theme.palette.divider,
    },
    "& .fc-day-header": {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-day-top": {
      ...theme.typography.body2,
    },
    "& .fc-highlight": {
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-event": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: 2,
      borderStyle: "none",
      padding: 8,
      opacity: 0.9,
      "& .fc-time": {
        ...theme.typography.h6,
        color: "inherit",
      },
      "& .fc-title": {
        ...theme.typography.body1,
        color: "inherit",
      },
    },
    "& .fc-list-empty": {
      ...theme.typography.subtitle1,
    },
  },
}));

function DurationSelection({
  handleInterviewDurationChange,
  selectedInterviewDuration,
  selectedDates,
  handleSelectedDateChange,
  handleNewDateSelection,
  setCurrentTab,
  timeZone,
}) {
  moment.tz.setDefault(timeZone);
  const classes = useStyles();
  const calendarRef = useRef(null);
  const [t] = useTranslation(["campaigns", "common", "labels"]);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [date, setDate] = useState(moment().toDate());

  const view = mobileDevice ? "dayGridMonth" : "dayGridMonth";

  const interviewDurations = [
    {
      key: 30,
      label: `30 ${t("common:minutes")}`,
    },
    {
      key: 60,
      label: `1 ${t("common:hour")}`,
    },
    {
      key: 90,
      label: `1 ${t("common:hour")} 30 ${t("common:minutes")}`,
    },
    {
      key: 120,
      label: `2 ${t("common:hours")}`,
    },
    {
      key: 150,
      label: `2 ${t("common:hours")} 30 ${t("common:minutes")}`,
    },
    {
      key: 180,
      label: `3 ${t("common:hours")}`,
    },
    {
      key: 210,
      label: `3 ${t("common:hours")} 30 ${t("common:minutes")}`,
    },
    {
      key: 240,
      label: `4 ${t("common:hours")}`,
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    setCurrentTab(2);
  };

  const handleEventSelect = (arg) => {
    if (
      selectedDates.length > 0 &&
      selectedDates.find(
        (selectedDate) =>
          new Date(selectedDate.start).getTime() ===
          new Date(arg.event.start).getTime()
      )
    ) {
      handleSelectedDateChange(arg.event.start);
    }
  };

  const handleSlotsSelect = (arg) => {
    let todaysDate = new Date().getTime();
    let eventStartDate = new Date(arg.start).getTime();
    if (
      (!selectedDates.length > 0 ||
        !selectedDates.find(
          (selectedDate) =>
            new Date(selectedDate.start).getTime() ===
            new Date(arg.start).getTime()
        )) &&
      todaysDate < eventStartDate
    ) {
      let event = {
        title: t("labels:selected"),
        allDay: true,
        end: arg.start,
        start: arg.start,
      };
      handleNewDateSelection(event);
    }
  };

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const goBack = (event) => {
    event.preventDefault();
    setCurrentTab(0);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="contactLabel" style={{ backgroundColor: "white" }}>
            {t("campaigns:selectInterviewDuration")}
          </InputLabel>
          <Select
            style={mobileDevice ? { width: "88vw" } : { width: 300 }}
            labelId="contactLabel"
            name={"selectedInterviewDuration"}
            value={selectedInterviewDuration}
            onChange={handleInterviewDurationChange}
            input={<OutlinedInput />}
            renderValue={(selected) =>
              interviewDurations.find((duration) => duration.key === selected)
                ? interviewDurations.find(
                    (duration) => duration.key === selected
                  ).label
                : ""
            }
          >
            {interviewDurations.map((duration) => (
              <MenuItem key={duration.key} value={duration.key}>
                <Checkbox checked={selectedInterviewDuration === duration.key} />
                <ListItemText primary={`${duration.label}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Toolbar
          date={date}
          onDateNext={handleDateNext}
          onDatePrev={handleDatePrev}
          onDateToday={handleDateToday}
          view={view}
          headerTooltip={true}
        />
        <Box className={classes.calendar}>
          <FullCalendar
            timeZone={timeZone}
            allDayMaintainDuration
            defaultDate={date}
            defaultView="dayGridMonth"
            eventClick={handleEventSelect}
            eventLimit
            events={selectedDates}
            header={false}
            height={800}
            ref={calendarRef}
            rerenderDelay={10}
            select={handleSlotsSelect}
            selectable
            selectLongPressDelay={1}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              timelinePlugin,
              momentTimezonePlugin,
            ]}
          />
        </Box>
      </div>
      <FwdBackButtons
        fwd={selectedDates.length ? true : false}
        backFunc={goBack}
      />
    </form>
  );
}
export default DurationSelection;
