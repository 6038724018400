import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Grid, Slide, Paper, Typography } from "@material-ui/core";

import { Reveal } from "src/views/LandingPage/Components/Reveal";
import defaultLogo from "src/assets/images/logos/i-intro_logo_light.png";
import { getConsultancyLogo } from "src/api/consultancyApi";

import LoginForm from "./LoginForm";
import ForgottenPasswordForm from "./ForgottenPasswordForm";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		height: "100%",
		width: "95%",
		marginTop: 50,
		marginBottom: 50,
		paddingLeft: 0,
		paddingRight: 0,
	},
	loginContainer: {
		width: "100%",
		height: "100vh",
		backgroundSize: "cover",
	},
	bgImgContainer: {
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		width: "100%",
		height: "100vh",
		zIndex: 1,
		position: "absolute",
		top: 0,
		left: "50%",
		transform: "translate(-50%)",
	},
	loginCard: {
		padding: 20,
		// maxHeight: "380px",
		maxWidth: "360px",
		zIndex: 1,
		boxShadow: "0 15px 15px -5px",
	},
	bgImgDefault: {
		backgroundImage:
			"url(https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260)",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		width: "100%",
		height: "100vh",
		zIndex: 1,
		position: "absolute",
		top: 0,
		left: "50%",
		transform: "translate(-50%)",
	},
	loginOverlay: {
		position: "absolute",
		height: "100%",
		width: "100%",
		opacity: 0.93,
		zIndex: 1,
	},
	logo: {
		maxWidth: "200px",
		height: "auto",
		maxHeight: "100px",
		padding: "1rem",
		marginBottom: 20,
	},
	giveLogo__container: {
		position: "relative",
		alignItems: "center",
	},
	giveLogo__circle: {
		height: "100px",
		width: "100px",
		borderRadius: "50%",
		borderTop: `5px solid ${theme.palette.primary.main}`,
		borderBottom: `5px solid ${theme.palette.primary.main}`,
		borderLeft: `5px solid ${theme.palette.primary.main}`,
		borderRight: "5px solid transparent",
		marginTop: "1.5rem",
		marginLeft: "-30px",
	},
	giveLogo__name: {
		marginTop: "-65px",
		marginRight: "-30px",
		fontWeight: 400,
		fontSize: 18,
	},
}));

const LoginHero = ({
	trigger,
	mobile,
	consultancyDetails,
	consultancyColour,
	assetUrl,
}) => {
	const classes = useStyles();
	const landingPageData = consultancyDetails?.landing;
	const learnMoreColour =
		consultancyDetails?.landing?.header?.[0]?.learn_more_colour;
	const [consultancyLogo, setConsultancyLogo] = useState("");
	const [logoLoaded, setLogoLoaded] = useState(false);
	const useDefault = consultancyDetails?.landing.consultancyId === null;
	const [showForgottenPasswordForm, setShowForgottenPasswordForm] = useState(
		false
	);

	const [t] = useTranslation(["labels"]);

	const fetchConsultancyLogo = () => {
		return getConsultancyLogo(landingPageData?.consultancyId)
			.then((response) => {
				if (Object.keys(response.data).length !== 0) {
					setConsultancyLogo(response.data.consultancyLogo);
				} else if (!useDefault) {
					setLogoLoaded(true);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		if (landingPageData?.consultancyId) {
			fetchConsultancyLogo();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [landingPageData?.consultancyId]);

	const checkContent = () => {
		if (landingPageData) {
			const {
				display_cards,
				display_contact,
				display_footer,
				display_slides,
				display_video,
			} = landingPageData;

			const contentArr = [
				display_cards,
				display_contact,
				display_footer,
				display_slides,
				display_video,
			];
			return contentArr.includes(true);
		}
	};

	const handleScrollDown = () => {
		window.scrollTo({
			top: window.innerHeight,
			left: 0,
			behavior: "smooth",
		});
	};

	const toggleForgottenPassword = () => {
		setShowForgottenPasswordForm(!showForgottenPasswordForm);
	};

	return (
		<>
			<div
				className={
					consultancyDetails ? classes.bgImgContainer : classes.bgImgDefault
				}
				style={
					assetUrl
						? {
								backgroundImage: `url(${assetUrl})`,
						  }
						: null
				}
			/>
			<Grid container >
				{mobile ? null : (
					<Grid item lg={8}>
						<Box
							height="100vh"
							paddingLeft="75px"
							paddingBottom="50px"
							display="flex"
							alignItems="flex-end"
						>
							<div>
								<Reveal consultancyDetails={consultancyDetails} />
							</div>
						</Box>
					</Grid>
				)}

				<Grid item xs={12} lg={4}>
					<Slide in={logoLoaded} direction="left" timeout={600}>
						<Box
							display="flex"
							height="100vh"
							width="auto"
							alignItems="center"
							justifyContent="center"
							position="relative"
						>
							<div
								className={classes.loginOverlay}
								style={{
									backgroundColor:
										landingPageData?.header[0]?.overlay_colour ||
										consultancyColour,
								}}
							/>
							<Paper
								className={classes.loginCard}
								style={
									!mobile
										? {
												minWidth: "360px",
										  }
										: null
								}
							>
								<Box display="flex" justifyContent="center">
									{useDefault ? (
										<img
											src={defaultLogo}
											alt=""
											className={classes.logo}
											onLoad={() => {
												setLogoLoaded(true);
											}}
										/>
									) : consultancyLogo ? (
										<img
											src={consultancyLogo}
											alt=""
											className={classes.logo}
											onLoad={() => {
												setLogoLoaded(true);
											}}
										/>
									) : (
										<Box height="148px">
											<div className={classes.giveLogo__container}>
												<div className={classes.giveLogo__circle} />
												<Typography
													className={classes.giveLogo__name}
													style={{ color: "#222222" }}
												>
													{consultancyDetails?.name || ""}
												</Typography>
											</div>
										</Box>
									)}
								</Box>
								{showForgottenPasswordForm ? (
									<ForgottenPasswordForm
										toggleForgottenPassword={toggleForgottenPassword}
										buttonColour={
											landingPageData?.header[0]?.button_colour ||
											consultancyColour
										}
									/>
								) : (
									<LoginForm
										toggleForgottenPassword={toggleForgottenPassword}
										buttonColour={
											landingPageData?.header[0]?.button_colour ||
											consultancyColour
										}
									/>
								)}
							</Paper>
							{checkContent() ? (
								<Box
									position="absolute"
									style={{
										top: "90vh",
										left: "50%",
										transform: "translate(-50%)",
										zIndex: 5,
									}}
								>
									<Button
										onClick={handleScrollDown}
										style={{
											border: `${learnMoreColour} 1px solid`,
											padding: "20px",
											color: learnMoreColour,
										}}
									>
										{t("labels:learnMore")}
									</Button>
								</Box>
							) : null}
						</Box>
					</Slide>
				</Grid>
			</Grid>
		</>
	);
};

export default LoginHero;
