import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';
import { useSelector, useDispatch } from 'react-redux';
import {
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_EMAIL_SETTINGS,
} from 'src/actions';

export default function ToggleButton({ notificationId, disabled, settings }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(!disabled);

  const notificationIds = useSelector(
    (state) => state?.notificationSettings?.notificationSettings
  );

  const emailNotificationIds = useSelector(
    (state) => state?.notificationSettings?.emailSettings
  );

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (settings === 'notifications') {
      let newSettings = notificationIds;

      if (checked) {
        newSettings = [
          ...notificationIds.filter((id) => id !== 0),
          Number(event.target.value),
        ];

        dispatch({
          type: UPDATE_NOTIFICATION_SETTINGS,
          payload: newSettings,
        });
      } else {
        newSettings = newSettings.filter(
          (setting) => setting !== notificationId
        );
        if (!newSettings.length) {
          newSettings = [0];
        }

        dispatch({
          type: UPDATE_NOTIFICATION_SETTINGS,
          payload: newSettings,
        });
      }
    }
    if (settings === 'emails') {
      let newSettings = emailNotificationIds;

      if (checked) {
        newSettings = [
          ...emailNotificationIds.filter((id) => id !== 0),
          Number(event.target.value),
        ];

        dispatch({
          type: UPDATE_EMAIL_SETTINGS,
          payload: newSettings,
        });
      } else {
        newSettings = newSettings.filter(
          (setting) => setting !== notificationId
        );

        if (!newSettings.length) {
          newSettings = [0];
        }

        dispatch({
          type: UPDATE_EMAIL_SETTINGS,
          payload: newSettings,
        });
      }
    }
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      color='primary'
      name='checkedB'
      inputProps={{ 'aria-label': 'primary checkbox' }}
      value={notificationId}
    />
  );
}
