import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Avatar, Button, FormControl, MenuItem, Select, TableCell, TableRow, Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as candidateApi from './../../../api/candidateApi';

import * as talentAlertActions from './../../../actions/talentAlertActions';

import { NOT_SUBMITTED } from './../../../constants/candidateStatusIds';

const useStyles = makeStyles((theme) => ({
  singleCandidate: () => ({
    '&:nth-of-type(2n)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    '&:nth-of-type(2n+1)': {
      backgroundColor: fade(theme.palette.primary.main, 0.03)
    },
    padding: '10px 20px'
  }),
  tCell: {
    textAlign: 'center'
  },
  tCellHover: {
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  candidateImg: {
    margin: 'auto',
    width: '80px',
    height: '80px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  name: {
    marginBottom: '5px'
  },
  share: {
    marginTop: '1%',
    marginBottom: '1%',
    minWidth: '180px !important'
  },
  icon: {
    marginRight: '5px'
  }
}));

const CandidateListItem = ({ candidate, index, handleRemove, toggleShareWidget }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [t] = useTranslation(['campaigns', 'shareWidget', 'snackbar']);
  const { enqueueSnackbar } = useSnackbar();

  const campaignId = useSelector((state) => state.talentAlert.id);
  const { consultancyId } = useSelector((state) => state.session);

  const [candidateStatus, setCandidateStatus] = useState(candidate.status);

  const goToCandidate = (candidateId) => {
    history.push(`/dashboard/talent-alerts/${campaignId}/candidates/${candidateId}`)
  };

  const onStatusChange = (e) => {
    candidateApi.updateCandidateProfileStatus(consultancyId, candidate.id, campaignId, e.target.value)
      .then(() => {
        setCandidateStatus(e.target.value);

        const updatedCandidate = {
          ...candidate,
          status: e.target.value
        };

        dispatch({
          type: talentAlertActions.UPDATE_TALENT_ALERT_CANDIDATE,
          payload: {
            candidate: updatedCandidate
          }
        });

        enqueueSnackbar(t('snackbar:updateCandidateProfile'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('snackbar:failedToUpdateProfile'), { variant: 'error' });
      });
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        key={index}
        tabIndex={-1}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        className={classes.singleCandidate} >
        <TableCell component="th" scope="row" className={classes.tCell}>
          <Avatar
            src={
              candidate.profilePicture ? candidate.profilePicture :
                "https://res.cloudinary.com/dhiwlvpg7/image/upload/v1656671143/placeholder_ukjx7i.png"
            }
            className={classes.candidateImg}
            onClick={() => { goToCandidate(candidate.id) }} />
        </TableCell>

                    
        <TableCell component="th" scope="row" className={classes.tCellHover} onClick={() => { goToCandidate(candidate.id) }}>
          <Typography variant={"body1"} className={classes.name}>
            { candidate.firstName } { candidate.lastName }
          </Typography>

          <Typography variant={"body3"}>
            { candidate.email }
          </Typography>

          {
            candidate.referenceNumber && (
              <Typography variant='body1'>
                ID: { candidate.referenceNumber }
              </Typography>
            )
          }
        </TableCell>

        <TableCell component="th" scope="row" className={classes.tCell}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={ candidateStatus }
              onChange={ onStatusChange }>
              <MenuItem value={ 1 }>
                { t("campaignCandidates:notSubmitted") }
              </MenuItem>

              <MenuItem value={ 2 }>
                { t("campaignCandidates:submitted") }
              </MenuItem>

              <MenuItem value={ 3 }>
                { t("campaignCandidates:live") }
              </MenuItem>

              <MenuItem value={ 8 }>
                { t("campaignCandidates:hidden") }
              </MenuItem>
            </Select>
          </FormControl>
        </TableCell>
                    
        <TableCell component="th" scope="row" className={classes.tCell}>
          <Button color="primary" size="small" type="submit" variant="contained" className={classes.share} disabled={candidateStatus === NOT_SUBMITTED} onClick={ () => { toggleShareWidget(candidate.id); } }>{t("shareWidget:shareWidgetOpenWidgetButton")}</Button>
        </TableCell>
                    
        <TableCell component="th" scope="row" className={classes.tCell}>
          <Button color="error" size="small" type="submit" variant="contained" onClick={() => { handleRemove(candidate.id) }}><DeleteOutlineIcon className={classes.icon} />{t("common:delete")}</Button>
        </TableCell>
      </TableRow>
    </>
  );

};

export default CandidateListItem;
