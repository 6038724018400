import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import McQuaigChart from "src/components/McQuaigChart";
import McQuaigModal from "src/components/McQuaigModal";
import { saveCampaignMcQuaig } from "src/api/campaignApi";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";
import { profileTypes } from "src/constants/mcQuaig";
import { mcQuaigScales } from "src/constants/mcQuaig";
import { makeStyles } from "@material-ui/styles";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import * as CampaignApi from "src/api/campaignApi";
import * as campaignActions from "src/actions/campaignActions";
import { CONTACT } from "src/constants/roles";

import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  graphRow: {
    display: "flex",
    flexWrap: "wrap",
  },
  bold: {
    fontWeight: "bold",
  },
  echarts: {
    width: "100%",
  },
  scales: {
    display: "flex",
    flexDirection: "column",
  },
  bScales: {
    margin: "30px 0",
    "&:before": {
      float: "left",
      content: '""',
      width: "100%",
      height: "20px",
      background: "#000",
      marginRight: "20px",
      margin: "10px 0 20px 0",
    },
  },
  bScalesRed: {
    "&:before": {
      background: "#c44826",
    },
  },
  bScalesGreen: {
    "&:before": {
      background: "#009e4b",
    },
  },
  bScalesBlue: {
    "&:before": {
      background: "#0076bc",
    },
  },
  bScalesYellow: {
    "&:before": {
      background: "#ffd24f",
    },
  },
}));

const CampaignMcQuaigOverview = ({ guestView }) => {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const [mcQuaigModal, setMcQuaigModal] = useState(false);
  const [mcQuaig, setMcQuaig] = useState({});
  const dispatch = useDispatch();
  const roleType = useSelector((state) => state.session.roleType);
  const adminContactPreviewMode = useSelector(
    (state) => state?.campaign?.adminContactPreview
  );
  const { consultancyId } = useSelector((state) => state.session);
  const { id, campaignMcQuaig, campaignTitle } = useSelector(
    (state) => state.campaign
  );
  const [t] = useTranslation([
    "common",
    "validation",
    "campaigns",
    "tooltips",
    "newTranslations",
    "vacancies",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleOpen = () => {
    setMcQuaigModal(true);
  };
  const handleClose = () => {
    setMcQuaigModal(false);
  };

  const renderScales = () => {
    return mcQuaigScales.map((scale) => {
      let color = "bScales" + scale.color;
      return (
        <Grid>
          <Grid
            xs={1}
            sm={1}
            lg={1}
            className={`${classes[color]} ${classes.bScales}`}
          ></Grid>
          <Grid xs>
            <Typography display="inline" variant="h4" className={classes.bold}>
              {t(`newTranslations:${scale.title}`).slice(0, 2)}
            </Typography>
            <Typography display="inline" variant="h4">
              {t(`newTranslations:${scale.title}`).slice(2)} &nbsp;↔&nbsp;&nbsp;
            </Typography>
            <Typography display="inline" variant="h4" className={classes.bold}>
              {t(`newTranslations:${scale.title2}`).slice(0, 2)}
            </Typography>
            <Typography display="inline" variant="h4">
              {t(`newTranslations:${scale.title2}`).slice(2)}
            </Typography>
          </Grid>
          <Typography display="inline" variant="body">
            ({t(`newTranslations:${scale.desc}`)})
          </Typography>
        </Grid>
      );
    });
  };

  const handleSubmit = (updateMcQuaig) => {
    return saveCampaignMcQuaig({
      consultancyId,
      campaignId: id,
      mcQuaigId: campaignMcQuaig?.id,
      mcQuaig: updateMcQuaig,
    }).then((response) => {
      if (response.data && response.status === 200) {
        setMcQuaig(response.data);

        CampaignApi.getCampaignDetails(consultancyId, id)
          .then((response) => {
            dispatch({
              type: campaignActions.UPDATE_CAMPAIGN,
              campaign: response.data,
            });
          })
          .then((response) => {
            enqueueSnackbar("Saved McQuaig", { variant: "success" });
            handleClose();
          });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    setMcQuaig(campaignMcQuaig);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let profile =
    profileTypes.find((type) => type.value === mcQuaig?.profileType) || {};

  return (
    <>
      {(roleType === CONTACT || adminContactPreviewMode) && !mcQuaig ? null : (
        <Card>
          <BlockHeader
            headerTooltip={
              roleType === CONTACT || adminContactPreviewMode
                ? null
                : t("tooltips:inputMcQuaigScores")
            }
            card
            padding
            header={t("campaigns:mcQuaigInstituteJobSurvey")}
          />
          <CardContent>
            {mcQuaig && (
              <>
                <Grid xs={12} style={{ marginBottom: "15px" }}>
                  <Typography variant="h3">
                    {t("campaigns:mcQuaigProfileType")}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {t(`newTranslations:${profile?.name}`)}
                    </span>
                  </Typography>
                  {mcQuaig.summary && (
                    <Typography variant="body">
                      <div class="ql-editor">
                        {parse(mcQuaig.summary) || ""}
                      </div>
                    </Typography>
                  )}
                </Grid>
                <Grid container style={{ margin: "0 auto", alignItems: "center" }}>
                  {(roleType === CONTACT || adminContactPreviewMode) ? (
                    <>
                      <Grid xs={12} md={6}>
                        <McQuaigChart
                          data={{
                            scores: { ...mcQuaig.scores },
                            title: campaignTitle,
                          }}
                        />
                      </Grid>
                      <Grid xs={12} md={6}>
                        <Grid xs={12}>
                          {renderScales()}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid xs={12} md={12}>
                        <McQuaigChart
                          data={{
                            scores: { ...mcQuaig.scores },
                            title: campaignTitle,
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
            {!guestView ? (
              <CardButton
                btnFunc={handleOpen}
                btnLabel={t(`${campaignsPrefix}:mcQuaigEdit`)}
              />
            ) : mcQuaig ? null : (
              <Typography style={{ margin: "15px 0" }} variant="h4">
                {t("campaigns:mcQuaigBehaviouralScoresNotSet")}
              </Typography>
            )}
          </CardContent>
        </Card>
      )
      }
      <McQuaigModal
        onClose={handleClose}
        onSubmit={handleSubmit}
        mcQuaig={mcQuaig}
        open={mcQuaigModal}
        headerTitle={t("campaigns:mcQuaigInstituteJobSurvey")}
        passedTooltip={t("tooltips:insertMcQuaigScores")}
      />
    </>
  );
};

export default CampaignMcQuaigOverview;
