import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";

import CustomFileDropzone from "src/components/CustomFileDropzone";
import CountrySelect from "src/components/CountrySelect";
import { useTranslation } from "react-i18next";
import LocaleSelect from "src/components/LocaleSelect";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import avatarPlaceholder from "src/assets/images/missing_avatar.svg";
import { CardButton } from "src/components/LayoutComponents/CardButton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
}));

function GeneralProfile({
  consultancyId,
  handleLocaleChange,
  formState,
  saveDetails,
  handleCountryChange,
  hasError,
  handleChange,
  handleUploadProfilePicture,
  profileData,
  adminView,
  userId,
  isProfileView,
  ...rest
}) {
  const classes = useStyles();

  const [t] = useTranslation(["common", "validation", "snackbar", "tooltips"]);

  return (
    <Grid container spacing={3} style={{ marginTop: 16 }}>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <BlockHeader
                      reduceMargin
                      header={t("common:manageAvatar")}
                    />
                    <CustomFileDropzone
                      type="single"
                      format="image"
                      uploadFunction={handleUploadProfilePicture}
                      placeHolderImage={
                        formState.values.profilePicture || avatarPlaceholder
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <BlockHeader
                  reduceMargin
                  headerTooltip={t(`tooltips:manageDetails`)}
                  header={t("common:managePersonalInfo")}
                />
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:salutationLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="salutation"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("salutation")}
                          value={formState.values.salutation || ""}
                          helperText={
                            hasError("salutation")
                              ? t(
                                  `validation:${formState.errors.salutation[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:firstNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="firstName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("firstName")}
                          value={formState.values.firstName || ""}
                          helperText={
                            hasError("firstName")
                              ? t(`validation:${formState.errors.firstName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:lastNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="lastName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("lastName")}
                          value={formState.values.lastName || ""}
                          helperText={
                            hasError("lastName")
                              ? t(`validation:${formState.errors.lastName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:telephoneLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="telephone"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("telephone")}
                          value={formState.values.telephone || ""}
                          helperText={
                            hasError("telephone")
                              ? t(`validation:${formState.errors.telephone[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:addressLabel1`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine1"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine1")}
                          value={formState.values.addressLine1 || ""}
                          helperText={
                            hasError("addressLine1")
                              ? t(
                                  `validation:${formState.errors.addressLine1[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:addressLabel2`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine2"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine2")}
                          value={formState.values.addressLine2 || ""}
                          helperText={
                            hasError("addressLine2")
                              ? t(
                                  `validation:${formState.errors.addressLine2[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">{t(`common:City`)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="city"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("city")}
                          value={formState.values.city || ""}
                          helperText={
                            hasError("city")
                              ? t(`validation:${formState.errors.city[0]}`)
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="h5">
                          {t(`common:postcodeLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          name="postalCode"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("postalCode")}
                          value={formState.values.postalCode || ""}
                          helperText={
                            hasError("postalCode")
                              ? t(
                                  `validation:${formState.errors.postalCode[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:countryLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <CountrySelect
                          handleCountryChange={handleCountryChange}
                          value={formState.values.country}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t("common:languageLabel")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <LocaleSelect
                          handleLocaleChange={handleLocaleChange}
                          value={formState.values.localeId}
                          consultancyId={consultancyId}
                          profile={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <CardButton
                  btnFunc={saveDetails}
                  btnDisabled={!formState.isValid}
                  btnHidden={adminView}
                  btnVariant="contained"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GeneralProfile;
