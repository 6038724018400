import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	TextField,
	Button,
	colors,
	FormControl,
	InputLabel,
	Select,
	OutlinedInput,
	MenuItem,
	ListItemText,
	Checkbox,
} from "@material-ui/core";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import CountrySelect from "src/components/CountrySelect";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import * as ConsultancyApi from "src/api/consultancyApi";
import * as ContactApi from "src/api/contactApi";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	container: {
		padding: "0px 10px",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	modalCard: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
			transform: `translate(${0}%, ${10}%)`,
		},
		[theme.breakpoints.up("md")]: {
			width: "50%",
			transform: `translate(${50}%, ${10}%)`,
		},
		"&:focus": {
			outline: "none",
		},
	},
	closeButton: {
		minWidth: "32px",
		backgroundColor: colors.red[600],
		"&:hover": {
			backgroundColor: colors.red[400],
		},
		color: theme.palette.common.white,
		textTransform: "none",
	},
	modalOverflow: {
		overflowY: "scroll",
	},
}));

const roles = [
	{
		id: 4,
		name: "Admin",
	},
	{
		id: 5,
		name: "Consultant",
	},
	{
		id: 6,
		name: "Candidate",
	},
	{
		id: 7,
		name: "Contact",
	},
];

function CreateUserModal({ open, handleClose, consultancies }) {
	const classes = useStyles();

	const [t] = useTranslation(["campaigns", "common", "snackbar"]);
	const { enqueueSnackbar } = useSnackbar();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	const schema = {
		firstName: {
			presence: { allowEmpty: false, message: t("common:required") },
			length: {
				maximum: 32,
			},
		},
		lastName: {
			presence: { allowEmpty: false, message: t("common:required") },
			length: {
				maximum: 32,
			},
		},
		email: {
			presence: { allowEmpty: false, message: t("common:required") },
			email: true,
			length: {
				maximum: 64,
			},
		},
		postalCode: {
			presence: { allowEmpty: false, message: t("common:required") },
		},
		country: {
			presence: { allowEmpty: false, message: t("common:required") },
		},
	};

	const handleChange = (event) => {
		event.persist();
		let employerId = formState.values.employerId;
		if (event.target.name === "consultancyId") {
			employerId = null;
		} else if (event.target.name === "employerId") {
			employerId = event.target.value;
		}
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
				employerId,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleCountryChange = (value) => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				country: value,
			},
			touched: {
				...prevFormState.touched,
				country: true,
			},
		}));
	};

	const hasError = (field) => {
		if (formState.touched[field] && formState.errors[field]) {
			return true;
		} else {
			return false;
		}
	};

	const onSubmit = function(event) {
		event.preventDefault();
		if (formState.values.role === 4 || formState.values.role === 5) {
			let userObject = {
				...formState.values,
				roleType: roles.find((role) => role.id === formState.values.role).name,
				consultancyId: Number(formState.values.consultancyId),
			};
			return SystemAdminApi.createConsultancyUser(userObject)
				.then((response) => {
					enqueueSnackbar(t("snackbar:createdUserSuccess"), {
						variant: "success",
					});
				})
				.catch((error) => {
					enqueueSnackbar(t("snackbar:failedToCreateUser"), {
						variant: "error",
					});
				})
				.then(() => {
					setFormState({
						isValid: false,
						values: {},
						touched: {},
						errors: {},
					});
					handleClose();
				});
		} else if (formState.values.role === 6) {
			let userObject = {
				...formState.values,
				consultancyId: Number(formState.values.consultancyId),
			};
			return ConsultancyApi.createConsultancyCandidate(userObject)
				.then((response) => {
					enqueueSnackbar(t("snackbar:createdUserSuccess"), {
						variant: "success",
					});
				})
				.catch((error) => {
					enqueueSnackbar(t("snackbar:failedToCreateUser"), {
						variant: "error",
					});
				})
				.then(() => {
					setFormState({
						isValid: false,
						values: {},
						touched: {},
						errors: {},
					});
					handleClose();
				});
		} else if (formState.values.role === 7) {
			let userObject = {
				...formState.values,
				consultancyId: Number(formState.values.consultancyId),
			};
			return ContactApi.createContact(userObject)
				.then((response) => {
					enqueueSnackbar(t("snackbar:createdUserSuccess"), {
						variant: "success",
					});
				})
				.catch((error) => {
					enqueueSnackbar(t("snackbar:failedToCreateUser"), {
						variant: "error",
					});
				})
				.then(() => {
					setFormState({
						isValid: false,
						values: {},
						touched: {},
						errors: {},
					});
					handleClose();
				});
		}
	};

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.values]);

	return (
		<ModalFormWrapper
			formTitle={t("common:createUser")}
			handleOpen={open}
			handleClose={handleClose}
		>
			<form onSubmit={onSubmit}>
				<div className={classes.fields}>
					<FormControl
						variant="outlined"
						fullWidth
						className={classes.formControl}
					>
						<InputLabel
							id="consultancyLabel"
							style={{ backgroundColor: "white" }}
						>
							{t("campaigns:selectCandidates")}
						</InputLabel>
						<Select
							labelId="consultancyLabel"
							name={"consultancyId"}
							value={formState.values.consultancyId}
							onChange={handleChange}
							renderValue={(selected) =>
								consultancies.find((consultancy) => consultancy.id === selected)
									.name
							}
							input={<OutlinedInput />}
						>
							{consultancies?.map((consultancy) => (
								<MenuItem key={consultancy.id} value={consultancy.id}>
									<Checkbox
										checked={formState.values.consultancyId === consultancy.id}
									></Checkbox>
									<ListItemText primary={consultancy.name}></ListItemText>
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl
						variant="outlined"
						fullWidth
						className={classes.formControl}
					>
						<InputLabel id="roleLabel" style={{ backgroundColor: "white" }}>
							{t("campaigns:selectRole")}
						</InputLabel>
						<Select
							labelId="roleLabel"
							name={"role"}
							value={formState.values.role}
							onChange={handleChange}
							renderValue={(selected) =>
								roles.find((role) => role.id === selected).name
							}
							input={<OutlinedInput />}
						>
							{roles?.map((role) => (
								<MenuItem key={role.id} value={role.id}>
									<Checkbox
										checked={formState.values.role === role.id}
									></Checkbox>
									<ListItemText primary={role.name}></ListItemText>
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{formState.values.role === 7 ? (
						<>
							<FormControl
								variant="outlined"
								fullWidth
								className={classes.formControl}
							>
								<InputLabel
									id="employerLabel"
									style={{ backgroundColor: "white" }}
								>
									{t("campaigns:selectEmployer")}
								</InputLabel>
								<Select
									labelId="employerLabel"
									name={"employerId"}
									value={formState.values.employerId}
									onChange={handleChange}
									renderValue={(selected) =>
										consultancies
											.find(
												(consultancy) =>
													consultancy.id === formState.values.consultancyId
											)
											.employers.find((employer) => employer.id === selected)
											?.name
									}
									input={<OutlinedInput />}
								>
									{consultancies
										.find(
											(consultancy) =>
												consultancy.id === formState.values.consultancyId
										)
										.employers?.map((employer) => (
											<MenuItem key={employer.id} value={employer.id}>
												<Checkbox
													checked={formState.values.employerId === employer.id}
												></Checkbox>
												<ListItemText primary={employer.name}></ListItemText>
											</MenuItem>
										))}
								</Select>
							</FormControl>
							<TextField
								fullWidth
								label={t("common:jobTitleLabel")}
								name="jobTitle"
								variant="outlined"
								required
								onChange={handleChange}
								error={hasError("jobTitle")}
								value={formState.values.jobTitle || ""}
								helperText={
									hasError("jobTitle") ? formState.errors.jobTitle[0] : null
								}
							/>
						</>
					) : null}
					<TextField
						fullWidth
						label={t("common:firstNameLabel")}
						name="firstName"
						variant="outlined"
						required
						onChange={handleChange}
						error={hasError("firstName")}
						value={formState.values.firstName || ""}
						helperText={
							hasError("firstName") ? formState.errors.firstName[0] : null
						}
					/>
					<TextField
						fullWidth
						label={t("common:lastNameLabel")}
						name="lastName"
						variant="outlined"
						required
						onChange={handleChange}
						error={hasError("lastName")}
						value={formState.values.lastName || ""}
						helperText={
							hasError("lastName") ? formState.errors.lastName[0] : null
						}
					/>
					<TextField
						fullWidth
						label={t("common:emailLabel")}
						name="email"
						variant="outlined"
						required
						onChange={handleChange}
						error={hasError("email")}
						value={formState.values.email || ""}
						helperText={hasError("email") ? formState.errors.email[0] : null}
					/>
					<TextField
						fullWidth
						label={t("common:postcodeLabel")}
						name="postalCode"
						variant="outlined"
						onChange={handleChange}
						required
						error={hasError("postalCode")}
						value={formState.values.postalCode || ""}
						helperText={
							hasError("postalCode") ? formState.errors.postalCode[0] : null
						}
					/>
					<CountrySelect
						handleCountryChange={handleCountryChange}
						value={formState.values.country || null}
					/>
				</div>
				<Button
					className={classes.submitButton}
					color="primary"
					size="large"
					type="submit"
					variant="contained"
					disabled={!formState.isValid}
				>
					{t("common:create")}
				</Button>
			</form>
		</ModalFormWrapper>
	);
}
export default CreateUserModal;
