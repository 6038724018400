import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Button, colors } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAvailableChildrenConsultancies } from 'src/api/consultancyApi';

const useStyles = makeStyles(theme => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
}));

function AddChildrenModal({ onSuccess, consultancyId, open, handleClose }) {
  const classes = useStyles();
  const [t] = useTranslation(['campaign', 'consultancy']);
  const [availableConsultancies, setAvailableConsultancies] = useState([]);
  const [formState, setFormState] = useState([]);

  const handleChange = (event, values) => {
    event.persist();
    let consultancyIds = values.map(consultancy => consultancy.id);
    setFormState([...consultancyIds]);
  };

  const setAvailableChildrenConsultancies = () => {
    return getAvailableChildrenConsultancies(consultancyId).then(response => {
      if (response.status === 200) {
        setAvailableConsultancies(response.data);
      }
    });
  };

  const onSubmit = function(event) {
    event.preventDefault();
    onSuccess(formState);
  };

  useEffect(() => {
    setAvailableChildrenConsultancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalFormWrapper
      formTitle={t('consultancy:addChildrenConsultancies')}
      handleOpen={open}
      handleClose={handleClose}
    >
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <Autocomplete
            multiple
            id="consultancy-select"
            options={availableConsultancies}
            getOptionLabel={option => option.consultancyName}
            onChange={handleChange}
            style={{ width: '100%' }}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  label={t(`consultancy:selectChildren`)}
                  variant="outlined"
                />
              );
            }}
          />
        </div>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.length}
        >
          {t('common:add')}
        </Button>
      </form>
    </ModalFormWrapper>
  );
}
export default AddChildrenModal;
