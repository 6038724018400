import * as actionTypes from 'src/actions';

const today = new Date();
const priorDate = new Date().setDate(today.getDate() - 30);

const initialState = {
  dateFrom: new Date(priorDate),
  dateTo: today,
  selectedConsultancy: {
    consultancyName: 'All Consultancies'
  },
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_DATE_FROM: {
      return {
        ...state,
        dateFrom: action.payload,
      };
    }
    case actionTypes.UPDATE_DATE_TO: {
      return {
        ...state,
        dateTo: action.payload,
      };
    }

    case actionTypes.SELECTED_CONSULTANCY: {
      return {
        ...state,
        selectedConsultancy: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default analyticsReducer;
