import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import {
  Typography,
  Grid,
  Avatar,
  CardContent,
  Box,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as candidateStatusIds from 'src/constants/candidateStatusIds';
import avatarPlaceholder from 'src/assets/images/missing_avatar.svg';

import { DWI_ID } from './../../constants/moduleIds';
import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  candidateList: {
    maxHeight: 930,
    overflowY: 'auto',
  },
  candidateButton: {
    cursor: 'pointer',
  },
  avatar: {
    height: 100,
    width: 100,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function CandidateList({ candidateStatus, contactView }) {
  const classes = useStyles();

  const [t] = useTranslation(['campaigns']);
  const campaignCandidates = useSelector(
    (state) => state.campaign.campaignCandidates
  );
  const { campaignModuleIds } = useSelector((state) => state.campaign);
  const consultancySubscriptionPlanId = useSelector(state => state.session.consultancySubscriptionPlanId);

  const history = useHistory();
  const candidates = campaignCandidates.filter((candidate) => {
    if (contactView && (candidate.status === 1 || candidate.status === 2)) {
      return null;
    }
    if (
      candidateStatus === candidateStatusIds.ALL ||
      candidate.status === candidateStatus
    ) {
      return candidate;
    } else {
      return false;
    }
  });

  const campaign = useSelector((state) => state.campaign);

  const navigateToCandidate = (candidateId) => {
    const url = consultancySubscriptionPlanId === TA_PRO ?
      `/dashboard/vacancies/${campaign.id}/candidates/${candidateId}` :
      `/dashboard/campaigns/${campaign.id}/candidates/${candidateId}`;

    history.push(url);
  };

  return (
    <div>
      {candidates.length > 0 ? (
        <>
          {candidates.map((candidate) => (
            <CardContent className={classes.candidateList}>
              <Grid
                onClick={() => navigateToCandidate(candidate.id)}
                container
                spacing={3}
              >
                <Grid item xs={12} md={2} style={{ textAlign: 'center' }}>
                  <Avatar
                    className={classes.avatar}
                    src={candidate.avatar || avatarPlaceholder}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={campaignModuleIds && campaignModuleIds.includes(DWI_ID) ? 4 : 10}
                  className={classes.candidateButton}
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <div>
                    <Typography variant='h3'>
                      {candidate.firstName} {candidate.lastName}
                    </Typography>
                    <Typography variant={'body1'}>
                      {t(`campaigns:candidateStatus${candidate.status}`)}
                    </Typography>
                  </div>
                </Grid>

                {
                  campaignModuleIds && campaignModuleIds.includes(DWI_ID) && (
                    <>
                      <Grid item md={3} xs={12} style={{ textAlign: 'center' }}>
                        <Box position='relative' display='inline-flex'>
                          <CircularProgress
                            variant='static'
                            value={candidate.contactSuitabilityScore}
                            style={{ height: 80, width: 80 }}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position='absolute'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              component='div'
                              color='textSecondary'
                            >{`${
                              candidate.contactSuitabilityScore
                                ? candidate.contactSuitabilityScore
                                : 0
                            }%`}</Typography>
                          </Box>
                        </Box>
                        <Typography variant='h5'>
                          {t('campaigns:yourSuitabilityScore')}
                        </Typography>
                      </Grid>

                      <Grid item md={3} xs={12} style={{ textAlign: 'center' }}>
                        <Box position='relative' display='inline-flex'>
                          <CircularProgress
                            variant='static'
                            value={candidate.teamSuitabilityScore}
                            style={{ height: 80, width: 80 }}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position='absolute'
                            display='flex'
                            alignItems='center'
                            justifyContent='center'
                          >
                            <Typography
                              variant='body1'
                              component='div'
                              color='textSecondary'
                            >{`${
                              candidate.teamSuitabilityScore
                                ? candidate.teamSuitabilityScore
                                : 0
                            }%`}</Typography>
                          </Box>
                        </Box>
                        <Typography variant='h5'>
                          {t('campaigns:teamSuitabilityScore')}
                        </Typography>
                      </Grid>
                    </>
                  )
                }
              </Grid>
            </CardContent>
          ))}
        </>
      ) : (
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant={'body1'}>
                {t(`campaigns:noCandidateAtStatus${candidateStatus}`)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </div>
  );
}
export default CandidateList;
