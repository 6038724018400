import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import { useSnackbar } from 'notistack';
import { TabPanel } from 'src/components/TabPanel';
import {
  AppBar,
  Grid,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useMediaQuery,
} from '@material-ui/core';
import CandidateSelection from './CandidateSelection';
import DurationSelection from './DurationSelection';
import TimeSlotSelection from './TimeSlotSelection';
import Details from './Details';
import Review from './Review';
import * as CampaignApi from 'src/api/campaignApi';
import { UPDATE_CAMPAIGN_SCHEDULER } from 'src/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridRow: {
    marginTop: '20px',
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

function CreateMultipleEventModal({ onCancel, open, timeZone }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const { enqueueSnackbar } = useSnackbar();
  const campaignId = useSelector((state) => state.campaign.id);
  const [t] = useTranslation(['campaigns', 'common', 'snackbar']);
  moment.tz.setDefault(timeZone);

  const mobile = useMediaQuery('(max-width:599px)');

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      selectedCandidates: [],
      selectedInterviewDuration: 30,
      selectedDates: [],
      selectedTimeSlots: [],
      selectedContacts: [],
      title: '',
      description: '',
    },
    touched: {},
    errors: {},
  });

  const tabs = [
    { value: 0, label: t('campaigns:candidatesLabel'), enabled: true },
    {
      value: 1,
      label: t('campaigns:durationAndDates'),
      enabled: formState.values.selectedCandidates.length,
    },
    {
      value: 2,
      label: t('campaigns:timeSlots'),
      enabled: formState.values.selectedDates.length,
    },
    {
      value: 3,
      label: t('campaigns:details'),
      enabled: formState.values.selectedTimeSlots.length,
    },
    {
      value: 4,
      label: t('campaigns:review'),
      enabled: formState.values.selectedContacts.length,
    },
  ];

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const handleCandidateChange = (ids) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedCandidates: ids,
      },
    }));
  };

  const handleInterviewDurationChange = (event) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedInterviewDuration: event.target.value,
        selectedTimeSlots: [],
      },
    }));
  };

  const handleSelectedDateChange = (eventStartTime) => {
    let index = formState.values.selectedDates.findIndex((selectedDate) =>
      moment(selectedDate.start).isSame(moment(eventStartTime))
    );
    let dates = [...formState.values.selectedDates];
    dates.splice(index, 1);
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedDates: [...dates],
        selectedTimeSlots: [],
      },
    }));
  };

  const handleNewDateSelection = (event) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedDates: [...prevValues.values.selectedDates, event],
      },
    }));
  };

  const selectTimeSlot = (timeSlot) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedTimeSlots: [...prevValues.values.selectedTimeSlots, timeSlot],
      },
    }));
  };

  const deselectTimeSlot = (timeSlot) => {
    let index = formState.values.selectedTimeSlots.findIndex(
      (selectedTimeSlot) => selectedTimeSlot.isSame(timeSlot)
    );
    let tempTimeSlots = [...formState.values.selectedTimeSlots];
    tempTimeSlots.splice(index, 1);
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedTimeSlots: [...tempTimeSlots],
      },
    }));
  };

  const handleTitleChange = (event) => {
    event.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        title: event.target.value,
      },
    }));
  };

  const handleDescriptionChange = (event) => {
    event.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        description: event.target.value,
      },
    }));
  };

  const handleContactChange = (contacts) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedContacts: contacts,
      },
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    return CampaignApi.createMultipleCalendarEntries(
      consultancyId,
      campaignId,
      formState.values
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_SCHEDULER,
          campaignEvents: response.data,
        });
        enqueueSnackbar(t('snackbar:calendarEntryUpdateSuccess'), {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setCurrentTab(0);
        setFormState({
          isValid: false,
          values: {
            selectedCandidates: [],
            selectedInterviewDuration: 30,
            selectedDates: [],
            selectedTimeSlots: [],
            selectedContacts: [],
            title: '',
            description: '',
          },
          touched: {},
          errors: {},
        });
        onCancel();
      });
  };

  return (
    <ModalFormWrapper
      formTitle={t('common:createEvents')}
      handleOpen={open}
      handleClose={onCancel}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridRow}>
          <AppBar position='static' className={classes.appBar}>
            {mobile ? (
              <MobileStepper
                variant='text'
                steps={tabs.length}
                activeStep={currentTab}
              />
            ) : (
              <Stepper activeStep={currentTab}>
                {tabs.map((tab) => (
                  <Step
                    onClick={
                      tab.enabled
                        ? () => {
                            handleTabChange(tab.value);
                          }
                        : null
                    }
                    key={t(`campaigns:${tab.label}`)}
                    style={tab.enabled ? { cursor: 'pointer' } : null}
                  >
                    <StepLabel>{t(`campaigns:${tab.label}`)}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            )}
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={currentTab} index={0}>
            <CandidateSelection
              handleCandidateChange={handleCandidateChange}
              selectedCandidates={formState.values.selectedCandidates}
              setCurrentTab={setCurrentTab}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <DurationSelection
              handleInterviewDurationChange={handleInterviewDurationChange}
              selectedInterviewDuration={
                formState.values.selectedInterviewDuration
              }
              setCurrentTab={setCurrentTab}
              selectedDates={formState.values.selectedDates}
              handleSelectedDateChange={handleSelectedDateChange}
              handleNewDateSelection={handleNewDateSelection}
              timeZone={timeZone}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <TimeSlotSelection
              selectedDates={formState.values.selectedDates}
              setCurrentTab={setCurrentTab}
              selectTimeSlot={selectTimeSlot}
              selectedTimeSlots={formState.values.selectedTimeSlots}
              deselectTimeSlot={deselectTimeSlot}
              selectedInterviewDuration={
                formState.values.selectedInterviewDuration
              }
              timeZone={timeZone}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <Details
              setCurrentTab={setCurrentTab}
              selectedContacts={formState.values.selectedContacts}
              description={formState.values.description}
              title={formState.values.title}
              handleContactChange={handleContactChange}
              handleDescriptionChange={handleDescriptionChange}
              handleTitleChange={handleTitleChange}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={4}>
            <Review
              values={formState.values}
              onSubmit={onSubmit}
              setCurrentTab={setCurrentTab}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={5}></TabPanel>
        </Grid>
      </Grid>
    </ModalFormWrapper>
  );
}
export default CreateMultipleEventModal;
