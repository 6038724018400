import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Box, Card, Typography, Zoom, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		height: "160px",
	},
	label: {
		marginLeft: theme.spacing(1),
	},
	block: {
		border: `1px solid ${theme.palette.primary.main}`,
		borderRadius: "50%",
		height: "120px",
		width: "120px",
		minWidth: "120px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	value: {
		color: theme.palette.primary.main,
	},
}));

function AnalyticsCard({ className, ...rest }) {
	const classes = useStyles();
	const data = rest.data;

	function commaSeparator(num) {
		return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}

	return (
		<Card className={clsx(classes.root, className)} {...rest}>
			<Box flexGrow={1}>
				<Typography component="h3" variant="overline" color="textSecondary">
					{data.name}
				</Typography>
			</Box>
			<Zoom in={data}>
				<Box className={classes.block}>
					<Typography variant="h2" className={classes.value}>
						{commaSeparator(data.value)}
					</Typography>
				</Box>
			</Zoom>
		</Card>
	);
}

AnalyticsCard.propTypes = {
	className: PropTypes.string,
};

export default AnalyticsCard;
