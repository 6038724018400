import React, { Suspense, useState, useEffect } from "react";
import { renderRoutes } from "react-router-config";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { LinearProgress, useMediaQuery } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import MobileTopBar from "./MobileTopBar";
import { OpenDrawerButton } from "./OpenDrawerButton";
import Page from "src/components/Page";
import { useSelector } from "react-redux";

import { CANDIDATE, CONTACT } from './../../constants/roles';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    "@media all and (-ms-high-contrast:none)": {
      height: 0, // IE11 fix
    },
    marginBottom: 75,
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: "100%",
    overflowX: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 56,
    },
    transition: "0.15s all ease",
  },
  contentNoNavBar: {
    paddingLeft: 0
  }
}));

function Dashboard({ route, ...rest }) {
  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [openNavBarDesktop, setOpenNavBarDesktop] = useState(false);

  const consultancyName = useSelector((state) => state.session.consultancyName);
  const { roleType } = useSelector(state => state.session);

  const mobileDevice = useMediaQuery("(max-width:1278px), (orientation: portrait)");
  const isDesktop = useMediaQuery("(min-width:1600px)");

  useEffect(() => {
    setOpenNavBarDesktop(isDesktop);
  }, [isDesktop]);

  const getContentClassName = () => {
    if (roleType !== CANDIDATE && roleType !== CONTACT) {
      return classes.content;
    }

    return `${classes.content} ${classes.contentNoNavBar}`;
  };

  return (
    <Page title={consultancyName}>
      {mobileDevice ? (
        <MobileTopBar
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          openDesktop={openNavBarDesktop}
        />
      ) : (
        <TopBar
          onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
          openDesktop={openNavBarDesktop}
        />
      )}

      {
        roleType !== CANDIDATE && roleType !== CONTACT && (
          <>
            <OpenDrawerButton
              onDesktopOpen={() => setOpenNavBarDesktop(true)}
              openDesktop={openNavBarDesktop} />

            <NavBar
              onMobileClose={() => setOpenNavBarMobile(false)}
              openMobile={openNavBarMobile}
              onDesktopClose={() => setOpenNavBarDesktop(false)}
              openDesktop={openNavBarDesktop} />
          </>
        )
      }

      <div className={classes.container}>
        <div
          className={ getContentClassName() }
          style={!openNavBarDesktop ? { paddingLeft: 0 } : null}>
          <Suspense fallback={<LinearProgress />}>
            {renderRoutes(route.routes)}
          </Suspense>
        </div>
      </div>
    </Page>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;
