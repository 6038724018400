import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  grid: {
    textAlign: 'center',
    marginBottom: '40px'
  },
  typographyBold: {
    fontWeight: 'bold',
  }
}));

const VideoAssessmentProgress = ({ completedQuestion, totalQuestions, startQuestion, completeAssessment }) => {

  const classes = useStyles();
  const [t] = useTranslation(['videoAssessment']);
  const isMobile = useMediaQuery('(max-width:767px)');

  const [isFinalQuestion, setIsFinalQuestion] = useState(false);

  useEffect(() => {
    if (completedQuestion === totalQuestions) {
      setIsFinalQuestion(true);
    }
  }, [completedQuestion, totalQuestions]);

  return (
    <>
      <Grid className={ classes.grid } >
        <Typography variant={ isMobile ? "h3" : "h2" } className={ classes.typographyBold } >
          { t("videoAssessment:videoAssessmentProgressHeader") }
        </Typography>
      </Grid>

      <Grid className={ classes.grid } >
        <Typography>
          { t("videoAssessment:videoAssessmentProgressSubHeader", { completedQuestion, totalQuestions }) }
        </Typography>
      </Grid>

      <Grid className={ classes.grid } >
        <Typography className={ classes.typographyBold } >
          {
            isFinalQuestion ? (
              <>
                { t("videoAssessment:videoAssessmentProgressInfoAssessmentFinished") }
              </>
            ) : (
              <>
                { t("videoAssessment:videoAssessmentProgressInfoNextQuetion") }
              </>
            )
          }
        </Typography>
      </Grid>

      <Grid>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          size="medium"
          onClick={ isFinalQuestion ? completeAssessment : startQuestion } >
          {
            isFinalQuestion ? (
              <>
                { t("videoAssessment:videoAssessmentProgressFinishButton") }
              </>
            ) : (
              <>
                { t("videoAssessment:videoAssessmentProgressStartButton") }
              </>
            )
          }
        </Button>
      </Grid>
    </>
  );

};

export default VideoAssessmentProgress;
