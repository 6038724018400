import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Typography, useMediaQuery } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/styles';

import * as talentAlertApi from './../../api/talentAlertApi';

import playBtnPng from './../../assets/images/icons/play-btn.png';

import { campaignShareInstructionsType } from './index';

const styles = {
    boxPreview: {
        height: '324px',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    playBtn: {
        width: '60px',
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        margin: 'auto'
    }
};

const useStyles = makeStyles((theme) => ({
    boxPreview: styles.boxPreview,
    boxPreviewMobile: {
        ...styles.boxPreview,
        height: '360px',
        width: '100%'
    },
    htmlPreviewWrapper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.primary.contrastText
    },
    htmlPreviewImgWrapper: {
        marginBottom: '10px'
    },
    htmlPreviewImg: {
        maxHeight: '160px',
        maxWidth: '100%'
    },
    htmlPreviewTextWrapper: {
        width: '100%',
        height: '60px',
        textAlign: 'center',
        boxSizing: 'border-box',
        padding: '8px 0',
        backgroundColor: theme.palette.primary.main
    },
    htmlPreviewTextHeader: {
        marginBottom: '5px'
    },
    htmlPreviewTextSubHeader: {
        fontSize: '12px'
    },
    imgContainer: {
        width: '100%',
        height: '300px',
        position: 'relative'
    },
    playBtn: styles.playBtn,
    playBtnMobile: {
        ...styles.playBtn,
        width: '48px'
    },
    imgWrapper: {
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        boxShadow: "inset 2000px 0 0 0 rgba(0, 0, 0, 0.1)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        overflow: 'hidden'
    },
    imgBackground: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        bottom: '0px',
        right: '0px',
        overflow: 'hidden',
        filter: 'blur(10px)'
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        zIndex: '1'
    },
    typography: {
        width: '100%',
        backgroundColor: '#eef3f7',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        padding: '0.8rem 1.2rem'
    },
    h2: {
        fontSize: '20px'
    },
    span: {
        marginTop: '0.8rem'
    },
    boxSupportedPlatformsWrapper: {
        marginTop: '20px'
    },
    boxSupportedPlatformsLabel: {
        marginBottom: '8px'
    },
    boxSupportedPlatform: {
        display: 'flex',
        alignItems: 'center'
    },
    checkCircleIcon: {
        width: '24px',
        height: '24px',
        marginRight: '8px'
    }
}));

const PreviewShare = ({ campaignId, candidateId, isCampaign, selectedShareInstructionsType }) => {

    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:767px)');
    const [t] = useTranslation(['shareWidget']);

    const { consultancyId } = useSelector((state) => state.session);

    const [domain, setDomain] = useState('');
    const [campaignData, setCampaignData] = useState({})
    const [candidateData, setCandidateData] = useState({
        jobTitle: '',
        profilePicture: ''
    });

    useEffect(() => {
        const domain = process.env.REACT_APP_API_URL
            .replace('/api/', '')
            .replace('http://', '')
            .replace('https://', '');
        setDomain(domain);

        if (isCampaign) {
            talentAlertApi.getContactHiddenTalentAlertDataForShare(campaignId, consultancyId)
                .then((response) => {
                    setCampaignData(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });

            return;
        }

        talentAlertApi.getCandidateHiddenTalentAlertForShare(candidateId, campaignId, consultancyId)
            .then((response) => {
                setCandidateData(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSupportedPlatforms = () => {
        const supportedPlatformsStr = t(`shareWidget:shareWidgetPreviewInstructionsTypeSupportedPlatformsOption${selectedShareInstructionsType}`);
        return supportedPlatformsStr.split(',');
    };

    const getPreviewJSX = () => {
        if (isCampaign && selectedShareInstructionsType === campaignShareInstructionsType.HTML) {
            return (
                <>
                    <Box className={ isMobile ? classes.boxPreviewMobile : classes.boxPreview }>
                        <div className={ classes.htmlPreviewWrapper }>
                            <div className={ classes.htmlPreviewImgWrapper }>
                                <img src={ campaignData.headerImg } className={ classes.htmlPreviewImg } alt="" />
                            </div>
                            
                            <div className={ classes.htmlPreviewTextWrapper }>
                                <div className={ classes.htmlPreviewTextHeader }>
                                    { campaignData.introduction?.greetingCaption }
                                </div>
                                
                                <div className={ classes.htmlPreviewTextSubHeader }>
                                    { campaignData.introduction?.introductionText }
                                </div>
                            </div>
                        </div>
                    </Box>
                </>
            );
        }

        return (
            <Box className={ isMobile ? classes.boxPreviewMobile : classes.boxPreview }>
                <div className={ classes.imgContainer }>
                    {
                        (!candidateData.isProfileVideoGifAvailable) && (
                            <>
                                <img className={ isMobile ? classes.playBtnMobile : classes.playBtn } src={ playBtnPng } alt="" />
                            </>
                        )
                    }

                    <div className={ classes.imgWrapper } >
                        <div className={ classes.imgBackground } style={{ backgroundImage: `url(${isCampaign ? campaignData.headerImg : candidateData.profilePicture})` }}></div>
                        <img className={ classes.img } src={ isCampaign ? campaignData.headerImg : candidateData.profilePicture } alt="" />
                    </div>
                </div>
        
                <Typography className={ classes.typography }>
                    <h2 className={ classes.h2 }>
                        { isCampaign ? campaignData.campaignTitle : candidateData.jobTitle }
                    </h2>

                    <span className={ classes.span }>
                        { domain }
                    </span>
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Box>
                { getPreviewJSX() }

                <Box className={ classes.boxSupportedPlatformsWrapper }>
                    <Box className={ classes.boxSupportedPlatformsLabel }>
                        <Typography>
                            { t("shareWidget:shareWidgetPreviewSupportedPlatformsLabel") }
                        </Typography>
                    </Box>

                    <Box>
                        {
                            getSupportedPlatforms().map((platform) => (
                                <>
                                    <Box className={ classes.boxSupportedPlatform }>
                                        <CheckCircleIcon color="primary" className={ classes.checkCircleIcon } />

                                        <Typography>
                                            { platform }
                                        </Typography>
                                    </Box>
                                </>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        </>
    );
    
};

export default PreviewShare;
