import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Avatar, Box, Card, Container, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/styles';

import moment from 'moment';

import * as consultancyApi from './../../../../api/consultancyApi';

import getInitials from './../../../../utils/getInitials';

const useStyles = makeStyles((theme) => ({
	headerContainer: {
		maxWidth: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '12px 0',
		marginTop: '8px'
	},
	typography: {
		width: '49%',
		textAlign: 'left'
	},
	tableContainer: {
		maxWidth: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		padding: '12px 0'
	},
	tableContainerMobile: {
		flexDirection: 'column'
	},
	tableCard: {
		width: '49%',
		height: '400px',
		overflow: 'scroll'
	},
	tableCardMobile: {
		width: '100vw',
		maxWidth: '90%',
		height: '320px'
	},
	tableHeadCell: {
		width: '50%'
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1)
	},
	typographyInfo: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	typographyInfoMobile: {
		textAlign: 'center'
	}
}));

const LoginTracker = () => {

  const classes = useStyles();
	const [t] = useTranslation(['common']);
	const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [consultanciesWithLastLoginDate, setConsultanciesWithLastLoginDate] = useState([]);
	const [consultanciesWithoutRecentLogins, setConsultanciesWithoutRecentLogins] = useState([]);

	useEffect(() => {
		const consultanciesWithLastLoginDatePromise = consultancyApi.getAllConsultanciesLastLoginDate();
		const consultanciesWithoutRecentLoginsPromise = consultancyApi.getConsultanciesWithoutRecentLogins();
		
		Promise.all([consultanciesWithLastLoginDatePromise, consultanciesWithoutRecentLoginsPromise])
			.then(([consultanciesWithLastLoginDateResponse, consultanciesWithoutRecentLoginsResponse]) => {
				setConsultanciesWithLastLoginDate(consultanciesWithLastLoginDateResponse.data);
				setConsultanciesWithoutRecentLogins(consultanciesWithoutRecentLoginsResponse.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const getDate = (date) => {
		if (date) {
			return moment(date).format('DD/MM/YYYY');
		}

		return t('common:consultancyAnalyticsActivityTrackingInvalidDate');
	};

	const consultanciesWithLastLoginDateTable = (
	<Card className={ `${classes.tableCard} ${isMobile ? classes.tableCardMobile : ""}` } >
		<Table stickyHeader aria-label="sticky table" >
			<TableHead>
				<TableRow>
					<TableCell className={ classes.tableHeadCell } >
						{ t("common:consultancy") }
					</TableCell>

					<TableCell align="right" className={ classes.tableHeadCell } >
						{ t("common:consultancyAnalyticsActivityTrackingLabel") }
					</TableCell>
				</TableRow>
			</TableHead>

			<TableBody>
				{
					consultanciesWithLastLoginDate.map((consultancy) => (
						<>
							<TableRow>
								<TableCell>
									<Box display="flex" alignItems="center" >
										<Avatar className={ classes.avatar } >
											{ getInitials(consultancy.consultancyName) }
										</Avatar>

										<Box>
											<Typography variant="body2" color="textSecondary" >
												{ consultancy.consultancyName }
											</Typography>

											<Typography variant="body2" color="textSecondary" >
												{
													consultancy.domain?.customDomain ?
													consultancy.domain.customDomain :
													consultancy.domain?.defaultDomain
												}
											</Typography>
										</Box>
									</Box>
								</TableCell>

								<TableCell align="right">
									{ getDate(consultancy.lastLoginDate) }
								</TableCell>
							</TableRow>
						</>
					))
				}
			</TableBody>
		</Table>
	</Card>
);

	const consultanciesWithoutRecentLoginsTable = (
		<Card className={ `${classes.tableCard} ${isMobile ? classes.tableCardMobile : ""}` } >
		{
			consultanciesWithoutRecentLogins.length < 1 ? (
				<>
				<Typography component="h3" variant="overline" color="textSecondary" className={ `${classes.typographyInfo} ${isMobile ? classes.typographyInfoMobile : ""}` } >
					{ t("common:consultancyAnalyticsActivityTrackingLastRecruiterLoginNoConsultancies") }
				</Typography>
				</>
			) : (
				<>
					<Table stickyHeader aria-label="sticky table" >
						<TableHead>
							<TableRow>
								<TableCell className={ classes.tableHeadCell } >
									{ t("common:consultancy") }
								</TableCell>

								<TableCell align="right" className={ classes.tableHeadCell } >
									{ t("common:consultancyAnalyticsActivityTrackingLabel") }
								</TableCell>
							</TableRow>
						</TableHead>

						<TableBody>
							{
								consultanciesWithoutRecentLogins.map((consultancy) => (
									<>
										<TableRow>
											<TableCell>
												<Box display="flex" alignItems="center" >
													<Avatar className={ classes.avatar } >
														{ getInitials(consultancy.consultancyName) }
													</Avatar>

													<Box>
														<Typography variant="body2" color="textSecondary" >
															{ consultancy.consultancyName }
														</Typography>

														<Typography variant="body2" color="textSecondary" >
															{
																consultancy.domain?.customDomain ?
																consultancy.domain.customDomain :
																consultancy.domain?.defaultDomain
															}
														</Typography>
													</Box>
												</Box>
											</TableCell>

											<TableCell align="right" >
												{ moment(consultancy.lastRecruiterLoginDate).format("DD/MM/YYYY") }
											</TableCell>
										</TableRow>
									</>
								))
							}
						</TableBody>
					</Table>
				</>
			)
		}
	</Card>
);

  return (
    <>
    	<Container className={ classes.headerContainer } >
				<Typography
					className={ classes.typography }
					variant="h4"
					color="textPrimary"
					align="center" >
					{ t("common:consultancyAnalyticsActivityTrackingLastLoginHeader") }
				</Typography>

				{
					!isMobile && (
						<>
							<Typography
								className={ classes.typography }
								variant="h4"
								color="textPrimary"
								align="center" >
								{ t("common:consultancyAnalyticsActivityTrackingLastRecruiterLoginHeader") }
							</Typography>
						</>
					)
				}
			</Container>

		  <Container className={ `${classes.tableContainer} ${isMobile ? classes.tableContainerMobile : ""}` } >
				{
					isMobile ? (
						<>
							<PerfectScrollbar>
								{ consultanciesWithLastLoginDateTable }
							</PerfectScrollbar>
						</>
					) : (
						<>
							{ consultanciesWithLastLoginDateTable }
						</>
					)
				}

				{
					isMobile && (
						<>
							<Container className={ classes.headerContainer } >
								<Typography
									className={ classes.typography }
									variant="h4"
									color="textPrimary"
									align="center" >
									{ t("common:consultancyAnalyticsActivityTrackingLastRecruiterLoginHeader") }
								</Typography>
							</Container>
						</>
					)
				}

				{
					isMobile ? (
						<>
							<PerfectScrollbar>
								{ consultanciesWithoutRecentLoginsTable }
							</PerfectScrollbar>
						</>
					) : (
						<>
							{ consultanciesWithoutRecentLoginsTable }
						</>
					)
				}
			</Container>
    </>
  );

};

export default LoginTracker;
