import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";

const useStyles = makeStyles((theme) => ({
  saveVideo: {
    margin: "0 auto",
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  controlRow: {
    margin: "0 auto",
    width: "50%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    width: "25px",
    height: "25px",
  },
}));

function VideoUploadModal({ video, open, onClose, modalHeader }) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common", "campaigns"]);
  const [rotate] = useState(video.rotation || 0);
  const [playing, setPlaying] = useState(false);
  const [muted, setMute] = useState(false);
  const [time, setTime] = useState(0);
  const [volume, setVolume] = useState(0.5);

  const [formState, setFormState] = useState({
    video: {},
  });

  const playVideo = () => {
    const profileVideo = document.getElementById("profileVideo");
    profileVideo.play();
    setPlaying(true);
  };

  const pauseVideo = () => {
    const profileVideo = document.getElementById("profileVideo");
    profileVideo.pause();
    setPlaying(false);
  };

  const muteVideo = () => {
    const profileVideo = document.getElementById("profileVideo");
    profileVideo.muted = true;
  };

  const unmuteVideo = () => {
    const profileVideo = document.getElementById("profileVideo");
    profileVideo.muted = false;
  };

  const toggleVideo = () => {
    if (playing) pauseVideo();
    else playVideo();
  };

  const toggleMute = () => {
    if (muted) unmuteVideo();
    else muteVideo();
    setMute(!muted);
  };

  const changeVideoTime = () => {
    const profileVideo = document.getElementById("profileVideo");
    const seekBar = document.getElementById("seek-bar");
    const time = profileVideo.duration * (seekBar.value / 100);
    profileVideo.currentTime = time;
  };

  const updateSeekBar = () => {
    const profileVideo = document.getElementById("profileVideo");
    const value = (100 / profileVideo.duration) * profileVideo.currentTime;
    setTime(value);
  };

  const changeVolume = (event) => {
    document.getElementById("profileVideo").volume = event.target.value;
    setVolume(event.target.value);
  };
  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      video,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalFormWrapper
      formTitle={ modalHeader ? modalHeader : t("campaigns:uploadAVideo") }
      handleOpen={open}
      handleClose={onClose}
    >
      <Grid
        container
        alignContant="center"
        alignItems="center"
        spacing={3}
        style={{
          textAlign: "center",
          marginBottom: "15px",
          position: "relative",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            overflow: "hidden",
            margin: "0 auto",
          }}
        >
          <video
            id="profileVideo"
            width="350"
            height="260"
            style={{ transform: `rotate(${rotate * 90}deg)` }}
            onTimeUpdate={updateSeekBar}
            onClick={toggleVideo}
          >
            <source src={formState.video.url} type="video/mp4" />
            <source src={formState.video.url} type="audio/ogg" />
            <source src={formState.video.url} type="video/webm" />

            <p>
              Your browser doesn't support HTML5 video.
              <a href={formState.video.url}>Download</a> the video instead.
            </p>
          </video>
        </Grid>
        <Grid container spacing={3} id="video-controls">
          <Grid container item className={classes.controlRow}>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.controlRow}
              style={{ justifyContent: "flex-start" }}
            >
              {playing ? (
                <PauseIcon className={classes.icon} onClick={toggleVideo} />
              ) : (
                <PlayArrowIcon className={classes.icon} onClick={toggleVideo} />
              )}
              <input
                type="range"
                id="seek-bar"
                value={time}
                onMouseDown={pauseVideo}
                onMouseUp={playVideo}
                onChange={changeVideoTime}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.controlRow}
              style={{
                flexDirection: "row-reverse",
                justifyContent: "flex-start",
              }}
            >
              {muted ? (
                <VolumeOffIcon className={classes.icon} onClick={toggleMute} />
              ) : (
                <VolumeUpIcon className={classes.icon} onClick={toggleMute} />
              )}
              <input
                type="range"
                id="volume-bar"
                min="0"
                max="1"
                step="0.1"
                value={volume}
                onChange={changeVolume}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalFormWrapper>
  );
}
export default VideoUploadModal;
