import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import * as api from "../../api/landingApi";
import ManageCards from "./Components/ManageCards";
import ManageHero from "./Components/ManageHero";
import ManageVideo from "./Components/ManageVideo";
import ManageCarousel from "./Components/ManageCarousel";
import ManageContactForm from "./Components/ManageContactForm";
import ManageFooter from "./Components/ManageFooter";
import { FadeIn } from "src/components/Transitions/FadeIn";

const LandingPageManagement = ({ consultancyId, consultancyColour }) => {
	const [loading, setLoading] = useState(true);
	const [landingPageData, setLandingPageData] = useState({});
	const [assetData, setAssetData] = useState([]);

	const handleAssetDelete = () => {
		setAssetData([]);
	};

	const getLandingPageData = (consultancyId) => {
		let landingPageId;
		return api
			.getPageData(consultancyId)
			.then((response) => {
				setLandingPageData(response.data);
				landingPageId = response.data.id;
			})
			.then(() => {
				return api.getAssetUrls(landingPageId).then((response) => {
					setAssetData(response.data);
				});
			})
			.then(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		getLandingPageData(consultancyId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) {
		return (
			<Box display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<>
			<FadeIn>
				<ManageHero
					landingPageData={landingPageData}
					assetData={assetData.login_image}
					handleDelete={handleAssetDelete}
					getLandingPageData={getLandingPageData}
					consultancyColour={consultancyColour}
				/>
				<ManageVideo
					landingPageData={landingPageData}
					handleDelete={handleAssetDelete}
					getLandingPageData={getLandingPageData}
					assetData={assetData.video}
				/>
				<ManageCards
					landingPageData={landingPageData}
					assetData={[assetData.card_1, assetData.card_2, assetData.card_3]}
					getLandingPageData={getLandingPageData}
				/>
				<ManageCarousel
					landingPageData={landingPageData}
					assetData={[
						assetData.slide_1,
						assetData.slide_2,
						assetData.slide_3,
						assetData.slide_4,
						assetData.slide_5,
					]}
					getLandingPageData={getLandingPageData}
				/>
				<ManageContactForm landingPageData={landingPageData} />
				<ManageFooter landingPageData={landingPageData} />
			</FadeIn>
		</>
	);
};

export default LandingPageManagement;
