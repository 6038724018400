export const availableWordLimits = {
  MIN: 250,
  300: 300,
  350: 350,
  400: 400,
  450: 450,
  DEFAULT: 500,
  550: 550,
  600: 600,
  650: 650,
  700: 700,
  MAX: 750
};
