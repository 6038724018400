import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as modulesApi from "src/api/modulesApi";
import { SET_KCQ_CATEGORIES } from "src/actions/ConsultancyModules/kcq/kcqActions";
import CreateKCQCategoryModal from "src/components/createKcqCategoryModal";
import EditKCQCategoryModal from "src/components/editKcqCategoryModal";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

function KCQSettings({ consultancyId }) {
  const { enqueueSnackbar } = useSnackbar();
  const kcqCategories = useSelector((state) => state.kcq.kcqCategories);
  const [t] = useTranslation([
    "modules",
    "common",
    "snackbar",
    "tooltips",
  ]);
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editingCategory, setEditingCategory] = useState({});

  kcqCategories.sort((a, b) => {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };
  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
  };

  const handleEditOpen = (event, category) => {
    event.preventDefault();
    setEditingCategory(category);
    setOpenEdit(true);
  };

  const deleteCategory = (event, categoryId) => {
    event.preventDefault();
    return modulesApi
      .deleteKCQCategory(consultancyId, categoryId)
      .then((response) => {
        dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
        enqueueSnackbar(t("snackbar:kcqDeleteSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:kcqDeleteFailed"), {
          variant: "error",
        });
      });
  };

  const onEditCategorySuccess = (category) => {
    return modulesApi
      .updateKCQCategory(consultancyId, category)
      .then((response) => {
        dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
        setOpenEdit(false);
        enqueueSnackbar(t("snackbar:kcqUpdated"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:kcqUpdatedFailed"), {
          variant: "error",
        });
      });
  };

  const onCreateCategorySuccess = (newCategory) => {
    return modulesApi
      .createKCQCategory(consultancyId, newCategory)
      .then((response) => {
        dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
        setOpenCreate(false);
        enqueueSnackbar(t("snackbar:kcqUpdated"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:kcqUpdatedFailed"), {
          variant: "error",
        });
      });
  };

  const getKCQCategories = () => {
    return modulesApi.getKCQCategories(consultancyId).then((response) => {
      dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
    });
  };

  useEffect(() => {
    getKCQCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container>
        <BlockHeader
          headerTooltip={t("tooltips:manageKCQCategories")}
          header={t("dwi:categoriesLabel")}
          btnLabel={t("modules:createCategory")}
          btnFunc={handleOpenCreate}
          btnVariant="contained"
        />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {kcqCategories?.map((category, index) => (
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5">{category.name}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={3}>
                  <Grid item lg={10} xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h5">
                          {t("modules:questions")}
                        </Typography>
                      </Grid>
                      {category.questions?.map((subCategory) => (
                        <>
                          <Grid item xs={12}>
                            <Typography variant="h5">{subCategory}</Typography>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                  <Grid item lg={2} xs={12}>
                    <Button
                      color="primary"
                      size="medium"
                      onClick={(event) => handleEditOpen(event, category)}
                      type="submit"
                      variant="contained"
                      fullWidth
                    >
                      {t("common:edit")}
                    </Button>
                    {category.isDefault ? null : (
                      <Button
                        style={{ marginTop: "20px" }}
                        color="error"
                        size="medium"
                        onClick={(event) => deleteCategory(event, category.id)}
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        {category.hasDefault
                          ? `${t("common:restoreToDefault")}`
                          : `${t("common:delete")}`}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Grid>
      </Grid>
      <CreateKCQCategoryModal
        modalTitle={t("modules:kcqCreateModalTitle1")}
        title={t("modules:kcqModalTitle")}
        subtitle={t("modules:kcqModalSubtitle")}
        buttonText={t("modules:kcqModalButton")}
        onSuccess={onCreateCategorySuccess}
        open={openCreate}
        handleClose={handleClose}
      />
      <EditKCQCategoryModal
        modalTitle={t("modules:kcqUpdateModalTitle1")}
        title={t("modules:kcqModalTitle")}
        subtitle={t("modules:kcqModalSubtitle")}
        buttonText={t("modules:kcqUpdateModalButton")}
        open={openEdit}
        category={editingCategory}
        onSuccess={onEditCategorySuccess}
        handleClose={handleClose}
      />
    </>
  );
}

export default KCQSettings;
