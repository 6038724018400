import React, { useState, useEffect } from "react";
import validate from "validate.js";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	TextField,
	colors,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as api from "../../api/index";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		justifyContent: "center",
	},
	container: {
		padding: "0px 10px",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	modalCard: {
		"&:focus": {
			outline: "none",
		},
	},
	closeButton: {
		minWidth: "32px",
		backgroundColor: colors.red[600],
		"&:hover": {
			backgroundColor: colors.red[400],
		},
		color: theme.palette.common.white,
		textTransform: "none",
	},
	modalOverflow: {
		overflowY: "scroll",
	},
}));

function CreateSystemAdminForm({ open, handleClose, className, ...rest }) {
	const classes = useStyles();

	const [t] = useTranslation(["users", "common"]);

	const { enqueueSnackbar } = useSnackbar();

	const initForm = {
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	};
	const [formState, setFormState] = useState(initForm);

	const schema = {
		firstName: {
			presence: { allowEmpty: false, message: t("common:required") },
			length: {
				maximum: 32,
			},
		},
		lastName: {
			presence: { allowEmpty: false, message: t("common:required") },
			length: {
				maximum: 32,
			},
		},
		email: {
			presence: { allowEmpty: false, message: t("common:required") },
			email: true,
			length: {
				maximum: 64,
			},
		},
		password: {
			presence: { allowEmpty: false, message: t("common:required") },
			length: {
				maximum: 128,
			},
		},
	};

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		return api
			.createSystemAdmin(formState.values)
			.then((response) => {
				if (response.status === 200) {
					handleClose();
					enqueueSnackbar(
						`${formState.values.firstName +
							" " +
							formState.values.lastName +
							" " +
							t("users:createdSysAdminSuccess")}.`,
						{
							variant: "success",
						}
					);
					setFormState(initForm);
					return response;
				} else {
					throw new Error(`server responded with ${response.status}`);
				}
			})
			.catch((error) => {
				handleClose();
				enqueueSnackbar(t("users:createdSysAdminError"), { variant: "error" });
				throw error;
			});
	};

	const hasError = (field) =>
		!!(formState.touched[field] && formState.errors[field]);

	useEffect(() => {
		const errors = validate(formState.values, schema);

		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.values]);

	return (
		<ModalFormWrapper
			formTitle={t("users:createSystemAdmin")}
			handleOpen={open}
			handleClose={handleClose}
		>
			<form onSubmit={handleSubmit}>
				<div className={classes.fields}>
					<TextField
						fullWidth
						required
						error={hasError("firstName")}
						helperText={
							hasError("firstName") ? formState.errors.firstName[0] : null
						}
						label={t("common:firstNameLabel")}
						name="firstName"
						onChange={handleChange}
						value={formState.values.firstName || ""}
						variant="outlined"
					/>
					<TextField
						fullWidth
						required
						error={hasError("lastName")}
						helperText={
							hasError("lastName") ? formState.errors.lastName[0] : null
						}
						label={t("common:lastNameLabel")}
						name="lastName"
						onChange={handleChange}
						value={formState.values.lastName || ""}
						variant="outlined"
					/>
					<TextField
						fullWidth
						required
						error={hasError("email")}
						helperText={hasError("email") ? formState.errors.email[0] : null}
						label={t("common:emailLabel")}
						name="email"
						onChange={handleChange}
						value={formState.values.email || ""}
						variant="outlined"
					/>
					<TextField
						fullWidth
						required
						error={hasError("password")}
						helperText={
							hasError("password") ? formState.errors.password[0] : null
						}
						label={t("common:passwordLabel")}
						name="password"
						type="password"
						onChange={handleChange}
						value={formState.values.password || ""}
						variant="outlined"
					/>
				</div>
				<Button
					className={classes.submitButton}
					color="secondary"
					disabled={!formState.isValid}
					size="large"
					type="submit"
					variant="contained"
				>
					{t("common:create")}
				</Button>
			</form>
		</ModalFormWrapper>
	);
}

CreateSystemAdminForm.propTypes = {
	className: PropTypes.string,
};

export default CreateSystemAdminForm;
