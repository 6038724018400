import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Container,
	CircularProgress,
	useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: "1rem",
		marginBottom: "1rem",
		padding: 0,
	},
	container_tight: {
		marginTop: 0,
		marginBottom: 0,
		padding: 0,
	},
	buttonBox: {
		display: "flex",
		flexDirection: "row-reverse",
	},
	buttonBoxMobile: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column-reverse",
	},
	button: {
		minWidth: 227,
	},
	button2: {
		minWidth: 227,
		marginRight: theme.spacing(2),
	},
	mobileButton2: {
		marginBottom: theme.spacing(1),
	}
}));

export const CardButton = ({
	btnLabel,
	btnFunc = () => {},
	btnDisabled,
	btnHidden,
	btnVariant,
	btnLabel2,
	btnFunc2,
	btnVariant2,
	btnDisabled2,
	addMargin,
	defeatMargin,
	save,
	loading,
	loading2,
}) => {
	const classes = useStyles();
	const btn = btnFunc != null;
	const btn2 = btnFunc && btnFunc2 != null ? true : false;
	const [t] = useTranslation(["common"]);

	const mobileDevice = useMediaQuery("(max-width:599px)");

	return (
		<Container
			className={defeatMargin ? classes.container_tight : classes.container}
			maxWidth="xl"
		>
			<Box
				className={mobileDevice ? classes.buttonBoxMobile : classes.buttonBox}
			>
				{(btn || save) && !btnHidden ? (
					<Button
						className={classes.button}
						color="primary"
						size="large"
						type="submit"
						fullWidth={mobileDevice ? true : false}
						variant={
							save ? btnVariant || "contained" : btnVariant || "outlined"
						}
						disabled={btnDisabled || loading2 ? true : false}
						onClick={btnFunc}
						style={addMargin ? { marginRight: 8 } : null}
					>
						{loading ? (
							<CircularProgress size={25} color="textPrimary" />
						) : (
							btnLabel || t("common:save")
						)}
					</Button>
				) : null}
				{btn2 ? (
					<Button
						className={mobileDevice ? classes.mobileButton2 : classes.button2}
						size="large"
						type="submit"
						fullWidth={mobileDevice ? true : false}
						variant={btnVariant2 || "text"}
						disabled={btnDisabled2 || loading ? true : false}
						onClick={btnFunc2}
						color="primary"
					>
						{loading ? (
							<CircularProgress size={25} />
						) : (
							btnLabel2
						)}
					</Button>
				) : null}
			</Box>
		</Container>
	);
};
