import React from 'react';
import { Grid, Breadcrumbs, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginTop: '20px',
    marginBottom: '10px',
    color: theme.palette.primary.main,
  },
}));

const NavigationBreadCrumbs = ({ navBreadcrumbs }) => {
  const classes = useStyles();
  return (
    <Grid xs={12} container>
      <Breadcrumbs
        separator='›'
        aria-label='breadcrumb'
        className={classes.breadcrumbs}
      >
        {navBreadcrumbs?.map((crumb, index) => (
          <Link
            key={crumb.title}
            className={classes.breadcrumbs}
            href={`/dashboard${crumb.link}`}
          >
            <Typography
              variant={navBreadcrumbs.length === index + 1 ? 'h1' : 'h2'}
              className={classes.breadcrumbs}
              style={{
                textDecoration:
                  navBreadcrumbs.length === index + 1 ? 'underline' : '',
              }}
            >
              {crumb.title}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </Grid>
  );
};

export default NavigationBreadCrumbs;
