import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogContent,
  Divider,
  FormLabel,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../api/landingApi";
import { SketchPicker } from "react-color";
import { useSnackbar } from "notistack";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FontDownloadSharpIcon from "@material-ui/icons/FontDownloadSharp";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { InputAndButton } from "src/components/LayoutComponents/InputAndButton";
import LoginHero from "src/views/LandingPage/LoginHero";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  swatchContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  swatch: {
    height: "60px",
    width: "60px",
  },
  button_container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  homepage_label: {},
  modalRoot: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  header: {
    display: "inline",
    width: "100%",
    height: "96px",
    lineHeight: "76px",
    fontSize: "6em",
    zIndex: 200,
    marginBottom: 16,
    marginRight: 64,
  },
  closeBox: {
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 900,
    padding: 16,
    backgroundColor: "rgba(0,0,0,0.6)",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeBoxText: {
    color: "rgba(255, 255, 255, 0.7)",
    fontSize: 20,
    lineHeight: 1.6,
    marginRight: 16,
  },
  fontName: {
    color: theme.palette.text.secondary,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManageHero = ({
  landingPageData,
  assetData,
  handleDelete,
  getLandingPageData,
  consultancyColour,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const [expand, setExpand] = useState(false);
  const [pickerColour, setPickerColour] = useState("");
  const [showColourPicker, setShowColourPicker] = useState(false);
  const [isValid] = useState(true);
  const [currentTarget, setCurrentTarget] = useState("");
  const [headerFont, setHeaderFont] = useState(
    landingPageData.header[0]?.headerFont
  );
  const [overlayColour, setOverlayColour] = useState(
    landingPageData.header[0]?.overlay_colour || consultancyColour || "#000000"
  );
  const [headerColour, setHeaderColour] = useState(
    landingPageData.header[0]?.colour || "#fff"
  );
  const [buttonColour, setButtonColour] = useState(
    landingPageData.header[0]?.button_colour || consultancyColour || "#000000"
  );
  const [learnMoreColour, setLearnMoreColour] = useState(
    landingPageData.header[0]?.learn_more_colour || "#fff"
  );
  const [headerContent, setHeaderContent] = useState({
    en_gb: {},
    de: {},
    es: {},
    fr: {},
    nl: {},
    en_us: {},
  });

  const fonts = [
    {
      label: "Barlow Semi Condensed",
      fontFamily: "'Barlow Semi Condensed', sans-serif",
    },
    { label: "Bodoni Moda", fontFamily: "'Bodoni Moda', serif" },
    { label: "Lora", fontFamily: "'Lora', serif" },
    { label: "Playfair Display", fontFamily: "'Playfair Display', serif" },
    { label: "Roboto", fontFamily: "" },
    { label: "Rubik", fontFamily: "'Rubik', sans-serif" },
  ];

  const [homepage, setHomepage] = useState(
    landingPageData.header[0]?.homepage
      ? landingPageData.header[0]?.homepage
      : ""
  );
  const [percent, setPercent] = useState(0);

  const [preview, setPreview] = useState(false);
  const [fontModal, setFontModal] = useState(false);

  const handleOpenFontModal = () => {
    setFontModal(true);
  };

  const handleCloseFontModal = () => {
    setFontModal(false);
  };

  const handleSelectFont = (value) => {
    setHeaderFont(value);
    setFontModal(false);
  };

  const handlePreview = () => {
    setPreview(true);
  };

  const handleClosePreview = () => {
    setPreview(false);
  };

  const getHeaderString = () => {
    if (!headerContent.en_gb.length) {
      return "The quick brown fox...";
    }

    let headerString = Object.values(headerContent.en_gb);
    let wasConcat = false;

    while (headerString.join(" ").length > 35) {
      headerString = headerString.slice(0, headerString.length - 1);
      if (!wasConcat) {
        wasConcat = true;
      }
    }

    return wasConcat ? headerString.join(" ") + "..." : headerString.join(" ");
  };

  const languageKey = {
    0: "en_gb",
    1: "de",
    2: "es",
    3: "fr",
    4: "nl",
    5: "en_us",
  };

  const currentData = landingPageData.header[0];
  const { consultancyId } = landingPageData;
  const landingPageId = landingPageData.id;

  const exampleData = {
    landing: {
      consultancyId: consultancyId,
      header: [
        {
          ...headerContent,
          colour: headerColour,
          overlay_colour: overlayColour,
          button_colour: buttonColour,
          learn_more_colour: learnMoreColour,
          homepage: homepage,
          headerFont: headerFont,
        },
      ],
    },
  };

  useEffect(() => {
    if (currentData) {
      setHeaderContent(currentData);
    }
  }, [currentData, assetData]);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleContentChange = (event) => {
    const { value, name } = event.target;
    setHeaderContent((prevState) => ({
      ...prevState,
      [languageKey[currentTab]]: {
        ...prevState[languageKey[currentTab]],
        [name]: value,
      },
    }));
  };

  const handleDisplayColorPicker = (props) => {
    const target = props.currentTarget.id;

    if (target === "overlay") {
      setPickerColour(overlayColour);
    } else if (target === "header") {
      setPickerColour(headerColour);
    } else if (target === "button") {
      setPickerColour(buttonColour);
    } else if (target === "more") {
      setPickerColour(learnMoreColour);
    }

    setCurrentTarget(target);
    setShowColourPicker(true);
  };

  const handleChangeColor = ({ hex }) => {
    setPickerColour(hex);
    if (currentTarget === "overlay") {
      setOverlayColour(hex);
    } else if (currentTarget === "header") {
      setHeaderColour(hex);
    } else if (currentTarget === "button") {
      setButtonColour(hex);
    } else if (currentTarget === "more") {
      setLearnMoreColour(hex);
    }
  };

  const uploadOptions = {
    params: {
      consultancyId: consultancyId,
      landingPageId: landingPageId,
      fieldName: "login_image",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;

      if (loaded && total) {
        let newPercent = Math.floor((loaded * 100) / total);

        setPercent(newPercent);
      }
    },
  };

  const handleImage = (value) => {
    setPercent(0);
    return api.uploadAsset(uploadOptions, value).then((response) => {
      if (response) {
        getLandingPageData(consultancyId);
        enqueueSnackbar("Image uploaded", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Upload failed", {
          variant: "error",
        });
      }
    });
  };

  const handleHomepageChange = (event) => {
    const { value } = event.target;
    setHomepage(value);
  };

  const applyChanges = () => {
    const uploadObject = {
      ...headerContent,
      colour: headerColour,
      overlay_colour: overlayColour,
      button_colour: buttonColour,
      learn_more_colour: learnMoreColour,
      homepage: homepage,
      headerFont: headerFont,
    };

    return api
      .updateContentBlock(consultancyId, "header", uploadObject)
      .then((response) => {
        if (response) {
          enqueueSnackbar("Content updated", { variant: "success" });
        }
      })
      .catch(() => {
        enqueueSnackbar("Unable to apply changes", { variant: "error" });
      });
  };

  const deleteAsset = () => {
    return api.deleteAsset(landingPageId, "login_image").then((response) => {
      if (response.status === 200) {
        handleDelete();
        enqueueSnackbar("Image deleted", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed to delete image", {
          variant: "error",
        });
      }
    });
  };

  const textFields = (
    <form>
      <Box display="flex" flexDirection="column" width="90%">
        <FormLabel>Set Homepage Header</FormLabel>
        <TextField
          variant="outlined"
          placeholder="Line 1"
          name="line_1"
          value={headerContent[languageKey[currentTab]]?.line_1}
          onChange={handleContentChange}
          style={{
            marginTop: ".25rem",
            backgroundColor: "white",
          }}
        />
        <TextField
          variant="outlined"
          placeholder="Line 2"
          name="line_2"
          value={headerContent[languageKey[currentTab]]?.line_2}
          multiline
          onChange={handleContentChange}
          style={{
            marginTop: ".25rem",
            backgroundColor: "white",
          }}
        />
        <TextField
          variant="outlined"
          placeholder="Line 3"
          name="line_3"
          value={headerContent[languageKey[currentTab]]?.line_3}
          multiline
          onChange={handleContentChange}
          style={{
            marginTop: ".25rem",
            backgroundColor: "white",
          }}
        />
        <TextField
          variant="outlined"
          placeholder="Line 4"
          name="line_4"
          value={headerContent[languageKey[currentTab]]?.line_4}
          multiline
          onChange={handleContentChange}
          style={{
            marginTop: ".25rem",
            backgroundColor: "white",
          }}
        />
      </Box>
    </form>
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="text"
              endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              Login Hero
            </Button>
            <Tooltip
              enterTouchDelay={1}
              title="Displays a full screen image with a 16:9 ratio. It is recommended that the image is compressed and does not exceed 1920px x 1080px for the best page load speeds."
            >
              <InfoOutlinedIcon />
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expand}>
        <Grid container>
          <InputAndButton
            formLabel="Link to homepage"
            placeholder="https://"
            value={homepage}
            onChange={handleHomepageChange}
            btnLabel="Preview"
            btnFunc={handlePreview}
          />
          <Grid item xs={4} lg={2}>
            <Box display="flex" alignItems="center">
              <Tabs
                orientation="vertical"
                value={currentTab}
                onChange={handleTabChange}
                variant="scrollable"
                className={classes.tabs}
              >
                <Tab label="EN" />
                <Tab label="DE" />
                <Tab label="ES" />
                <Tab label="FR" />
                <Tab label="NL" />
                <Tab label="US" />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={8} lg={7}>
            <TabPanel value={currentTab} index={0}>
              {textFields}
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              {textFields}
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              {textFields}
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              {textFields}
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
              {textFields}
            </TabPanel>
            <TabPanel value={currentTab} index={5}>
              {textFields}
            </TabPanel>
          </Grid>
        </Grid>

        <Box marginTop="2rem">
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Grid item xs={12}>
                <Box
                  className={classes.swatchContainer}
                  id="overlay"
                  onClick={handleOpenFontModal}
                  style={{ marginTop: 32 }}
                >
                  <Box display="inline-block" className={classes.swatch}>
                    <FontDownloadSharpIcon
                      style={{
                        fontSize: 60,
                        boxSizing: "border-box",
                        border: "10px #263238 solid",
                      }}
                    />
                  </Box>
                  <Box display="inline-block">
                    <Button
                      variant="text"
                      onClick={handleOpenFontModal}
                      className={classes.label}
                    >
                      Header font
                      <span
                        className={classes.fontName}
                        style={{ marginLeft: 4 }}
                      >
                        {"(" + headerFont?.label + ")"}
                      </span>
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.swatchContainer}
                  id="overlay"
                  onClick={handleDisplayColorPicker}
                >
                  <Box
                    display="inline-block"
                    className={classes.swatch}
                    bgcolor={overlayColour}
                  ></Box>
                  <Box display="inline-block">
                    <Button variant="text" className={classes.label}>
                      Overlay Colour
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.swatchContainer}
                  id="header"
                  onClick={handleDisplayColorPicker}
                >
                  <Box
                    display="inline-block"
                    className={classes.swatch}
                    bgcolor={headerColour}
                  ></Box>
                  <Box display="inline-block">
                    <Button variant="text" className={classes.label}>
                      Header Colour
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.swatchContainer}
                  id="button"
                  onClick={handleDisplayColorPicker}
                >
                  <Box
                    display="inline-block"
                    className={classes.swatch}
                    bgcolor={buttonColour}
                  ></Box>
                  <Box display="inline-block">
                    <Button variant="text" className={classes.label}>
                      Login Button Colour
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  className={classes.swatchContainer}
                  id="more"
                  onClick={handleDisplayColorPicker}
                >
                  <Box
                    display="inline-block"
                    className={classes.swatch}
                    bgcolor={learnMoreColour}
                  ></Box>
                  <Box display="inline-block">
                    <Button variant="text" className={classes.label}>
                      Learn More Colour
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                marginTop="1rem"
              >
                <Paper style={{ padding: "2rem" }}>
                  <CustomFileDropzone
                    type="single"
                    format="image"
                    uploadFunction={handleImage}
                    placeHolderImage={assetData}
                    percent={percent}
                  />
                  {assetData && (
                    <Box className={classes.button_container}>
                      <Button
                        variant="text"
                        color="primary"
                        endIcon={<DeleteForeverIcon />}
                        onClick={deleteAsset}
                      >
                        Delete Image
                      </Button>
                    </Box>
                  )}
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ButtonWrapper
          btnLabel="Apply Changes"
          btnFunc={applyChanges}
          btnDisabled={!isValid}
        />
      </Collapse>
      <Divider />
      <Dialog
        open={showColourPicker}
        onClose={() => {
          setShowColourPicker(false);
        }}
      >
        <SketchPicker
          color={pickerColour}
          onChange={handleChangeColor}
          disableAlpha={true}
        />
      </Dialog>
      <Dialog open={fontModal} maxWidth={false}>
        <BlockHeader
          header="Select font"
          closeButton
          padding
          btnFunc={handleCloseFontModal}
        />
        <DialogContent>
          {fonts.map((font) => (
            <MenuItem
              key={font.label}
              value={font.label}
              onClick={() => {
                handleSelectFont(font);
              }}
            >
              <div
                className={classes.header}
                style={{ fontFamily: font.fontFamily }}
              >
                {getHeaderString()}
              </div>
              <div className={classes.fontName}>{font.label}</div>
            </MenuItem>
          ))}
        </DialogContent>
      </Dialog>
      <Modal open={preview} onClick={handleClosePreview}>
        <Backdrop open={true} invisible>
          <Box className={classes.closeBox}>
            <Typography className={classes.closeBoxText}>
              <span>
                <InfoOutlinedIcon className={classes.closeBoxText} />
              </span>
              Click screen to exit
            </Typography>
          </Box>
          <LoginHero
            consultancyDetails={exampleData}
            consultancyColour={consultancyColour}
            assetUrl={assetData}
          />
        </Backdrop>
      </Modal>
    </>
  );
};

export default ManageHero;
