import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import validate from "validate.js";
import {
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  CircularProgress,
  Collapse,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import DefaultKCQSelect from "./DefaultKCQSelect";
import InfoIcon from "@material-ui/icons/Info";

import * as modulesApi from './../../../../api/modulesApi';

const useStyles = makeStyles((theme) => ({
  fields: {
    padding: theme.spacing(1),
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
  buttons: {
    textAlign: "center",
    marginTop: "20px",
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

//eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function AddKCQModal({ onSuccess, open, handleClose }) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common", "modues", "tooltips"]);

  const { consultancyId } = useSelector((state) => state.session);
  const campaign = useSelector((state) => state.campaign);
  const { isTalentAlertCampaign } = useSelector((state) => state.talentAlert);

  let defaultState = {
    isValid: false,
    values: {
      inviteContacts: !isTalentAlertCampaign,
      selectedContact: {
        email: "",
      },
      questionSetTitle: "",
      selectedQuestions: [],
      createContact: false,
    },
    touched: {},
    errors: {},
  };
  const [formState, setFormState] = useState(defaultState);

  const [isLoading, setIsLoading] = useState(false);
  const [kcqCategories, setKcqCategories] = useState([]);

  useEffect(() => {
    if (!isTalentAlertCampaign) {
      setKcqCategories(campaign.kcqCategories);
      return;
    }

    modulesApi.getKCQCategories(consultancyId)
      .then((response) => {
        setKcqCategories(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetForm = (event) => {
    setFormState(defaultState);
    handleClose();
  };

  const handleInviteContactChange = () => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        inviteContacts: !prevValues.values.inviteContacts,
      },
    }));
  };

  const autoCompleteContactSelectChange = (event, value) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedContact: {
          ...value,
        },
        createContact: false,
      },
    }));
  };

  const emailInputChange = (event) => {
    event.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedContact: {
          email: event.target.value,
        },
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);
    return onSuccess(formState.values).then(() => {
      setIsLoading(false);
      setFormState({
        isValid: false,
        values: {
          inviteContacts: true,
          useCustomSet: false,
          selectedContact: {
            email: "",
          },
          questionSetTitle: "",
          selectedQuestions: [],
          createContact: true,
        },
        touched: {},
        errors: {},
      });
      resetForm();
    });
  };

  const handleQuestionChange = (selectedQuestionSet) => {
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        selectedQuestions: [...selectedQuestionSet],
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const schema = {
    jobTitle: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 32,
      },
    },
    firstName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 32,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: "is required" },
      length: {
        maximum: 32,
      },
    },
    salutation: {
      length: {
        maximum: 10,
      },
    },
    email: {
      presence: { allowEmpty: false, message: "is required" },
      email: true,
      length: {
        maximum: 64,
      },
    },
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const renderInviteContactsView = () => (
    <Grid item xs={12}>
      <div className={classes.fields}>
        <Autocomplete
          id="contactAutoComplete"
          options={campaign.campaignContacts || []}
          getOptionLabel={(campaignContact) => {
            if (
              campaignContact &&
              campaignContact.jobTitle &&
              campaignContact.firstName &&
              campaignContact.lastName
            ) {
              return `${campaignContact.firstName} ${campaignContact.lastName} ${campaignContact.jobTitle} `;
            } else {
              return `${campaignContact.email}`;
            }
          }}
          value={formState.selectedContact}
          style={{ width: "100%" }}
          onChange={autoCompleteContactSelectChange}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                fullWidth
                label={t("campaigns:selectContact")}
                variant="outlined"
              />
            );
          }}
        />
        <TextField
          fullWidth
          className={classes.emailInput}
          label={t("common:emailLabel")}
          name="email"
          variant="outlined"
          required
          onChange={emailInputChange}
          value={formState.values.selectedContact.email || ""}
        />
      </div>
    </Grid>
  );

  const formHasNoErros = () => {
    if (
      formState.values.firstName &&
      formState.values.lastName &&
      formState.values.email &&
      !hasError("email") &&
      formState.values.jobTitle
    ) {
      return true;
    } else return false;
  };

  useEffect(() => {
    if (
      formState.values.selectedQuestions.length ||
      !formState.values.inviteContacts ||
      (formState.values.inviteContacts &&
        (!formState.values.createContact ||
          (formState.values.createContact && formHasNoErros())) &&
        emailRegex.test(formState.values.selectedContact.email))
    ) {
      setFormState((prevValues) => ({
        ...prevValues,
        isValid: true,
      }));
    } else {
      setFormState((prevValues) => ({
        ...prevValues,
        isValid: false,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  return (
    <ModalFormWrapper
      formTitle={t("campaigns:addKCQTitle")}
      handleOpen={open}
      handleClose={resetForm}
      maxWidth={"sm"}
    >
      <Grid container spacing={3}>
        {
          !isTalentAlertCampaign && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formState.values.inviteContacts}
                    onChange={handleInviteContactChange}
                    name="inviteContactToggle"
                    color="primary"
                  />
                }
                label={
                  <div style={{ display: "flex" }}>
                    <Typography variant="h5">{t("modules:kcqInvite")}</Typography>
                    <Tooltip
                      enterTouchDelay={1}
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      style={{ marginLeft: "10px" }}
                      title={t("tooltips:selectKCQQuestions")}
                    >
                      <InfoIcon />
                    </Tooltip>
                  </div>
                }
              />
            </Grid>
          )
        }

        {formState.values.inviteContacts && (
          <Collapse
            in={formState.values.inviteContacts}
            style={{ width: "100%" }}
          >
            {renderInviteContactsView()}
          </Collapse>
        )}
        {!formState.values.inviteContacts && (
          <Collapse in={!formState.values.inviteContacts}>
            <DefaultKCQSelect
              categories={ kcqCategories }
              handleQuestionChange={handleQuestionChange}
            />
          </Collapse>
        )}
        <Grid item xs={12}>
          <div className={classes.buttons}>
            <Button
              color="primary"
              size="large"
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={!formState.isValid || isLoading}
            >
              {isLoading ? (
                <div style={{ textAlign: "center" }}>
                  <CircularProgress size={16} />
                </div>
              ) : (
                <>
                  {formState.values.inviteContacts
                    ? t("campaigns:sendInvite")
                    : t("campaigns:addToCampaign")}
                </>
              )}
            </Button>
          </div>
        </Grid>
      </Grid>
    </ModalFormWrapper>
  );
}
export default AddKCQModal;
