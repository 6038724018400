import React from 'react';

import { makeStyles } from '@material-ui/styles';
import { Divider } from '@material-ui/core';

import RequiredCandidateTalentAlertDocuments from './RequiredCandidateTalentAlertDocuments';

const useStyles = makeStyles((theme) => ({
  documentList: {
    marginBottom: theme.spacing(4)
  }
}));

const TalentAlertCampaignDocuments = ({ campaignRequiredDocuments }) => {

  const classes = useStyles();

  return (
    <div className={classes.documentList}>
      <RequiredCandidateTalentAlertDocuments campaignRequiredDocuments={campaignRequiredDocuments} />
      
      <Divider />
    </div>
  );

};

export default TalentAlertCampaignDocuments;
