import * as actionTypes from "src/actions";
import { createMuiTheme } from "@material-ui/core/styles";

let theme = localStorage.getItem('theme') ? {...JSON.parse(localStorage.getItem('theme'))} : {
  palette: {
    primary: {
      main: "#006B76",
    },
    nav: {
      main: "#006B76",
    },
    button: {
      main: "#006B76",
    },
  },
  typography: {
    fontWeight: 300,
    fontSize: "6rem",
    lineHeight: 1.167,
    letterSpacing: "-0.01562em",
  }
}

const defaultTheme = createMuiTheme(theme);

// const initialState = {};

const themeReducer = (state = defaultTheme, action) => {
	switch (action.type) {
		case actionTypes.SET_THEME: {
			let activeTheme = action.activeTheme;
			return {
				...state,
				activeTheme,
			};
		}
		default: {
			return state;
		}
	}
};

export default themeReducer;
