import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "flex-end",
    backgroundColor: "#f5f5f5",
    minHeight: 38,
    padding: 0,
    width: "100%",
    marginTop: theme.spacing(1)
  },
  text: {
    flex: 2,
    minWidth: 60,
    textAlign: "left",
    margin: 8
  },
  link: {
    width: "100%",
    color: theme.palette.text.primary
  }
}));

function IconListItem({
  text,
  startIcon,
  endIcon,
  startFunc,
  endFunc,
  startDisabled,
  endDisabled,
  startTip,
  endTip,
  startLoading,
  endLoading,
  href,
  newWindow,
  middleIcon,
  middleTip
}) {
  const classes = useStyles();

  return (
    <span className={classes.root}>
      {startIcon && !startFunc ? (
        <a href={href} target={newWindow ? "_blank" : "_self"}>
          <ThemeIconButton
            noFill
            icon={startIcon}
            btnFunc={startFunc}
            disabled={startDisabled || endLoading}
            loading={startLoading}
            tooltip={startTip}
          />
        </a>
      ) : (
        startIcon && (
          <ThemeIconButton
            noFill
            icon={startIcon}
            btnFunc={startFunc}
            disabled={startDisabled || endLoading}
            loading={startLoading}
            tooltip={startTip}
          />
        )
      )}
      <a
        href={href}
        target={newWindow ? "_blank" : "_self"}
        className={classes.link}
      >
        <Typography className={classes.text}>{text}</Typography>
      </a>
      {middleIcon && (
        <a href={href} target={newWindow ? "_blank" : "_self"}>
          <ThemeIconButton tooltip={middleTip}  noFill icon={middleIcon} />
        </a>
      )}

      {endIcon && (
        <>
          <ThemeIconButton
            icon={endIcon}
            btnFunc={endFunc}
            disabled={endDisabled || startLoading}
            loading={endLoading}
            tooltip={endTip}
          />
        </>
      )}
    </span>
  );
}
export default IconListItem;
