import React, { useState } from "react";
import {
	Button,
	Card,
	CardContent,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import * as CandidateApi from "src/api/candidateApi";
import { useSnackbar } from "notistack";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	question: {
		margin: "10px 0px",
		display: "inline",
	},
	questionsAndAnswers: {
		padding: "10px 5px",
	},
	categoryWrapper: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		borderRadius: 2,
		padding: "10px 5px",
		marginTop: "20px",
	},
	updateButton: {
		textAlign: "center",
	},
}));

const CandidateKCQs = () => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const candidateCampaign = useSelector((state) => state.candidateCampaign);
	const consultancyId = useSelector((state) => state.session.consultancyId);
	const [formState, setFormState] = useState({
		values: {
			questions: candidateCampaign.candidateProfile.candidateKCQ,
		},
	});
	const [t] = useTranslation(["common", "validation", "snackbar"]);

	const updateCandidateKCQAnswers = () => {
		return CandidateApi.updateCandidateKCQAnswers(
			consultancyId,
			candidateCampaign.candidateProfile.candidateId,
			candidateCampaign.candidateProfile.campaignId,
			formState.values.questions
		)
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar(t("snackbar:keyCompetencyAnswersUpdated"), {
						variant: "success",
					});
				}
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:failedToUpdateKeyCompetencyAnswers"), {
					variant: "error",
				});
				console.error(error);
			});
	};

	const handleAnswerChange = (event, categoryIndex, questionIndex) => {
		event.persist();
		let tempCategories = [...formState.values.questions];
		tempCategories[categoryIndex].questions[questionIndex].answer =
			event.target.value;
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				questions: [...tempCategories],
			},
		}));
	};

	return candidateCampaign?.candidateProfile?.candidateKCQ?.length ? (
		<Grid container className={classes.root}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<BlockHeader
							card
							header={t("common:keyCompetencyQuestionsLabel")}
						/>
						{candidateCampaign.candidateProfile.candidateKCQ.map(
							(category, categoryIndex) => (
								<div key={categoryIndex}>
											<Typography variant="h4" className={classes.categoryWrapper}>
												{category.name}
											</Typography>
									<div className={classes.questionsAndAnswers}>
										{category.questions.map((question, questionIndex) => (
											<>
												<Typography variant="h4" style={{ margin: "10px 0px" }}>
													{question.question}
												</Typography>
												<TextField
													variant="outlined"
													multiline
													value={question.answer}
													onChange={(event) =>
														handleAnswerChange(
															event,
															categoryIndex,
															questionIndex
														)
													}
													fullWidth
												/>
											</>
										))}
									</div>
								</div>
							)
						)}
						<div className={classes.updateButton}>
							<Button
								color="primary"
								variant="contained"
								onClick={updateCandidateKCQAnswers}
								size="medium"
							>
								{t("common:save")}
							</Button>
						</div>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	) : null;
};

export default CandidateKCQs;
