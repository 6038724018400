import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

function GroupsContainer({ title, children, handleSearch }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h3'>{title}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <OutlinedInput
                    id={`${title}`}
                    startAdornment={
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={handleSearch}
                  />
                </FormControl>
              </Grid>
              {children}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default GroupsContainer;
