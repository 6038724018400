import React from "react";
import { makeStyles } from "@material-ui/styles";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Divider,
} from "@material-ui/core";
import { NOT_SUBMITTED } from "src/constants/candidateStatusIds";
import CampaignDocumentsCard from "src/views/Campaigns/CampaignDetail/CampaignDocuments/CampaignDocumentsCard";
import moment from "moment";
import CampaignInformationBlocks from "../../Campaigns/CampaignDetail/CampaignInformationBlocks";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CANDIDATE } from 'src/constants/roles';

import { useCampaignOrVacancyTranslationPrefix } from './../../../utils/useSubscriptionPrefix';

import { INFORMATION_BLOCKS_ID } from './../../../constants/moduleIds';

const DATE_TIME_FORMAT = "DD/MM/YYYY";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridRow: {
    marginTop: "20px",
  },
  employerLogo: {
    height: "80px",
    width: "auto",
  },
  campaignDetailContainer: {
    margin: "20px 0px",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  },
  buttonContainer: {
    margin: "40px 0px",
    textAlign: "center",
  },
  campaignDetails: {
    padding: "10px 0px",
  },
}));

function CampaignInformation({ applyToCampaignFunc }) {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const classes = useStyles();
  const [t] = useTranslation(["campaigns", "common", "snackbar", "vacancies"]);

  const candidateCampaign = useSelector((state) => state.candidateCampaign);

  const shouldHideCanidateCampaignDocuments = useSelector(state => {
    return state.session.roleType === CANDIDATE && !state.candidateCampaign?.campaignDocuments?.length;
  });

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent style={{ textAlign: "center" }}>
                  <img
                    src={candidateCampaign.campaignLogo}
                    alt=""
                    className={classes.employerLogo}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <BlockHeader
                  card
                  padding
                  headerTooltip={t(`tooltips:candidateCampaignDetails`)}
                  header={t(`${campaignsPrefix}:campaignDetailLabel`)}
                />
                <CardContent>
                  <Typography variant="h4" className={classes.campaignDetails}>
                    <b>{t(`${campaignsPrefix}:employerLabel`)}: </b>
                    {candidateCampaign.employerDetails
                      ? candidateCampaign.employerDetails.name
                      : ""}
                  </Typography>
                  <Divider />
                  <Typography variant="h4" className={classes.campaignDetails}>
                    <b>{t("common:cityLabel")}: </b>
                    {candidateCampaign.campaignAddress
                      ? candidateCampaign.campaignAddress.city
                      : ""}
                  </Typography>
                  <Divider />
                  <Typography variant="h4" className={classes.campaignDetails}>
                    <b>{t("common:countryLabel")}: </b>
                    {candidateCampaign.campaignAddress
                      ? candidateCampaign.campaignAddress.country.name
                      : ""}
                  </Typography>
                  <Divider />
                  <Typography variant="h4" className={classes.campaignDetails}>
                    <b>{t("common:datePosted")}: </b>
                    {candidateCampaign.createdAt
                      ? moment(candidateCampaign.createdAt).format(
                          DATE_TIME_FORMAT
                        )
                      : ""}
                  </Typography>
                  <Divider />
                  <Typography variant="h4" className={classes.campaignDetails}>
                    <b>{t("common:managedBy")}: </b>{" "}
                    {candidateCampaign.campaignManager
                      ? candidateCampaign.campaignManager.name
                      : ""}
                  </Typography>
                  <Divider />
                </CardContent>
              </Card>
            </Grid>

            {
              candidateCampaign?.campaignDocuments && !shouldHideCanidateCampaignDocuments ? (
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <CampaignDocumentsCard
                        documents={candidateCampaign.campaignDocuments}
                        candidateView={true}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ) : null
            }

          </Grid>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={3}>
            {candidateCampaign?.campaignModuleIds?.includes(INFORMATION_BLOCKS_ID) &&
              candidateCampaign.campaignInformationBlocks.length > 0 && (
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <CampaignInformationBlocks guestView={true} />
                  </Grid>
                </Grid>
              )}
            <Grid item xs={12}>
              <Card>
                <BlockHeader
                  card
                  padding
                  headerTooltip={t(`tooltips:campOverview`)}
                  header={t(`${campaignsPrefix}:campaignOverview`)}
                />
                <CardContent>
                  <div className={classes.campaignDetailContainer}>
                    <div class="ql-editor">
                      {parse(candidateCampaign.campaignOverview || "")}
                    </div>
                  </div>
                  {candidateCampaign.candidateProfile &&
                  candidateCampaign.candidateProfile.candidateStatus ===
                    NOT_SUBMITTED ? (
                    <div className={classes.buttonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={applyToCampaignFunc}
                      >
                        {t("common:fillInYourProfile")}
                      </Button>
                    </div>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CampaignInformation;
