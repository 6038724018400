import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Grid } from "@material-ui/core";
import { CampaignCard } from "src/components/Cards/CampaignCard";
import TermsAndConditionsAcceptanceModal from "./TermsAndConditionAcceptanceModal";
import CreateWelcomeMessagesCard from "../WelcomeMessages/CreateWelcomeMessagesCard";
import * as CandidateApi from "src/api/candidateApi";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import Page from "src/components/Page";

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridRow: {
    marginTop: "20px",
  },
}));

function CandidateDashboard() {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation(["campaigns", "common", "snackbar", "vacancies"]);

  const { consultancyId, consultancySubscriptionPlanId } = useSelector((state) => state.session);

  const [myCampaign, setMyCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const consultancyName = useSelector((state) => state.session.consultancyName);

  const handleTermsAndConditionsModalOpen = () => {
    setTermsAndConditionsOpen(true);
  };

  const handleTermsAndConditionsModalClose = () => {
    setTermsAndConditionsOpen(false);
  };

  const onTermsAndConditionsAcceptance = () => {
    return CandidateApi.acceptCampaignTermsAndConditions(
      Number(consultancyId),
      selectedCampaign.id
    )
      .then(() => {
        enqueueSnackbar(`${t("snackbar:termsAndConditionsAcceptedSuccess")}`, {
          variant: "success",
        });
        if (selectedCampaign.campaignTypeId === 2) {
          history.push(`/dashboard/talent-alerts/${selectedCampaign.id}`);
        } else {
          const url = consultancySubscriptionPlanId === TA_PRO ?
            `/dashboard/vacancies/${selectedCampaign.id}` :
            `/dashboard/campaigns/${selectedCampaign.id}`;

          debugger;

          history.push(url);
        }
      })
      .catch((error) => {
        enqueueSnackbar(`${t("snackbar:termsAndConditionsAcceptedFailure")}`, {
          variant: "error",
        });
      });
  };

  const getCandidateCampaigns = (consultancyId) => {
    setIsLoading(true);
    return CandidateApi.getCandidateCampaigns(consultancyId)
      .then((response) => {
        setMyCampaigns(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const goToCampaign = (event, campaignId) => {
    event.preventDefault();

    const url = consultancySubscriptionPlanId === TA_PRO ?
      `/dashboard/vacancies/${campaignId}` :
      `/dashboard/campaigns/${campaignId}`;

    history.push(url);
  };

  const goToTalentAlert = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/talent-alerts/${campaignId}`);
  };

  const showTermsAndConditions = (event, campaign) => {
    event.preventDefault();
    setSelectedCampaign(campaign);
    handleTermsAndConditionsModalOpen();
  };

  useEffect(() => {
    getCandidateCampaigns(consultancyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <Page title={consultancyName}>
        <PageTitle pageTitle={t(`${campaignsPrefix}:campaigns`)} />

        <ContentWrapper loading={isLoading}>
          <p style={{ paddingLeft: "10px" }}>
            {t(`${campaignsPrefix}:candidateCampaignList`)}
          </p>
          <CreateWelcomeMessagesCard />
          <Grid container spacing={3}>
            <Grid item xs={12} className={classes.gridRow}>
              <Grid container spacing={3}>
                {myCampaign?.map((campaign) => (
                  // if talent alert
                  campaign.campaignTypeId === 2 ? (
                    <Grid item md={4} sm={6} xs={12}>
                      <CampaignCard
                        name={`Talent Alert - ${campaign.campaignTitle}`}
                        btnLabel={t("common:view")}
                        btnFunc={(event) =>
                          goToTalentAlert(event, campaign.id)
                        }
                        campaignLogo={campaign.campaignLogo}
                      />
                    </Grid>
                  ) : (
                    <Grid item md={4} sm={6} xs={12}>
                      <CampaignCard
                        name={campaign.campaignTitle}
                        btnLabel={t("common:view")}
                        btnFunc={(event) =>
                          campaign.acceptedTCs
                            ? goToCampaign(event, campaign.id)
                            : showTermsAndConditions(event, campaign)
                        }
                        campaignLogo={campaign.campaignLogo}
                      />
                    </Grid>
                  )
                ))}
              </Grid>
            </Grid>
          </Grid>
        </ContentWrapper>
        <TermsAndConditionsAcceptanceModal
          onSuccess={onTermsAndConditionsAcceptance}
          open={termsAndConditionsOpen}
          handleClose={handleTermsAndConditionsModalClose}
          campaignTC={selectedCampaign.campaignTC || ""}
        />
      </Page>
    </div>
  );
}

export default CandidateDashboard;
