import React from "react";
import ReactEcharts from "echarts-for-react";
import { makeStyles } from "@material-ui/styles";
import { getChartOptions } from "src/constants/mcQuaig";

const useStyles = makeStyles((theme) => ({
  chartWrapper: {
    position: "relative",
    width: "100%",
  },
  bold: {
    fontWeight: "bold",
  },
  chartTitle: {
    textAlign: "center",
    fontWeight: "black",
    fontSize: 16,
  },
  chartTopTitle: {
    position: "absolute",
    top: "35px",
    width: "82%",
    marginLeft: "9%",
    fontFamily: theme.typography.fontFamily,
  },
  topTitle: {
    display: "inline-block",
    width: "25%",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
  },
}));

const McQuaigChart = ({ title, data = {} }) => {
  const classes = useStyles();

  const capatalise = (title) => {
    return title[0].toUpperCase() + title[1];
  };

  return (
    <div className={classes.chartWrapper}>
      <div className={classes.chartTitle}>{title}</div>
      <div className={classes.chartTopTitle}>
        {Object.keys(data.scores)?.map((title) => (
          <div class={classes.topTitle}>{capatalise(title)}</div>
        ))}
      </div>
      <ReactEcharts
        option={getChartOptions(data)}
        style={{ height: "60vh", width: "100%" }}
      />
    </div>
  );
};

export default McQuaigChart;
