import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTheme, Grid, AppBar } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import DetailsIcon from '@material-ui/icons/Details';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import SettingsIcon from '@material-ui/icons/Settings';
import WeightIcon from '@material-ui/icons/FitnessCenter';
import ExtensionSharpIcon from '@material-ui/icons/ExtensionSharp';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import Swal from 'sweetalert2';

import * as campaignApi from './../../../api/campaignApi';

import * as actionTypes from './../../../actions';
import * as campaignActions from './../../../actions/campaignActions';

import CampaignDetails from './CampaignDetails';
import CandidateDetails from './CandidateDetails';
import { CampaignModuleManagement } from './CampaignModuleManagement';
import CampaignSettings from './CampaignSettings';
import DynamicWeightIndex from './DynamicWeightIndex';
import ContactCampaignDetail from './../../ContactCampaignDetail';
import CalendarView from './../../../components/calendar/CalendarView';
import { ContentWrapper } from './../../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../../components/LayoutComponents/PageTitle';
import { TabPanel } from './../../../components/TabPanel';

import { DWI_ID } from './../../../constants/moduleIds';
import { CONSULTANT } from './../../../constants/roles';
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';
import * as subscriptionPlansIds from './../../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}));

function CampaignDetail({ campaignId, ...rest }) {

  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  
  const [t] = useTranslation(['candidates', 'common', 'campaigns', 'vacancies']);

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const roleType = useSelector((state) => state.session.roleType);
  const campaign = useSelector((state) => state.campaign);
  const consultancyId = useSelector((state) =>rest?.consultancyId ? rest.consultancyId : state.session.consultancyId);

  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [contactView, setContactView] = useState(false);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const campaignTitle = campaign.employerName + ' - ' + campaign.campaignTitle;
  const tabs = [
    { value: 0, label: t(`${campaignsPrefix}:campaignDetailLabel`), icon: <DetailsIcon /> },
    { value: 1, label: t(`${campaignsPrefix}:candidateDetailLabel`), icon: <PersonIcon /> },
  ];

  if (consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
    tabs.push({ value: 2, label: t(`${campaignsPrefix}:bookingScheduler`), icon: <InsertInvitationIcon /> });
  }

  if (campaign && campaign.campaignModuleIds && campaign.campaignModuleIds.includes(DWI_ID)) {
    tabs.push({ value: 3, label: t(`${campaignsPrefix}:dwiLabel`), icon: <WeightIcon /> });
  }

  if (!rest?.guestView) {
    tabs.push(
      { value: 4, label: t(`${campaignsPrefix}:settingsLabel`), icon: <SettingsIcon /> },
      { value: 5, label: t(`${campaignsPrefix}:moduleManagementLabel`), icon: <ExtensionSharpIcon /> }
    );
  }

  useEffect(() => {
    if (rest?.guestView) {
      campaignApi.getChildCampaignDetails(consultancyId, campaignId)
        .then((response) => {
          dispatch({
            type: campaignActions.UPDATE_CAMPAIGN,
            campaign: response.data,
          });
          
          setLoading(false);
        })
        .catch(() => {
          history.push('/dashboard');
          setLoading(false);
        });

      return;
    }

    campaignApi.getCampaignDetails(consultancyId, campaignId)
      .then((response) => {
        dispatch({
          type: campaignActions.UPDATE_CAMPAIGN,
          campaign: response.data,
        });
        
        setLoading(false);
      })
      .catch(() => {
        history.push('/dashboard');
        setLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId, campaignId, dispatch, history]);

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const toggleContactView = () => {
    dispatch({ type: actionTypes.TOGGLE_ADMIN_CONTACT_PREVIEW });
    setContactView(!contactView);
  };

  const deleteCampaign = () => {
    Swal.fire({
      title: `${t('common:areYouSure')}`,
      text: `${t(`${campaignsPrefix}:deleteConfirmation`)}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${t('common:yes')}`,
      cancelButtonText: `${t('common:no')}`,
      confirmButtonColor: theme.palette.warning?.main ?
        theme.palette.warning.main :
        theme.palette.primary.main,
    }).then(resp => {
      if (!resp.isConfirmed) return;

      campaignApi.deleteCampaign(consultancyId, campaignId)
        .then(() => {
          history.push('/dashboard');

          enqueueSnackbar(t('snackbar:campaignDeleteSuccess'), {
            variant: 'success'
          });
        })
        .catch(() => {
          enqueueSnackbar(t('snackbar:campaignDeleteFailure'), {
            variant: 'error'
          });
        });
    });
  };

  if (campaign?.adminContactPreview) {
    return (
      <ContactCampaignDetail
        campaignId={campaignId}
        contactView={{ contactView: true, toggleContactView }} />
    );
  }

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle
        pageTitle={campaignTitle}
        btnLabel={
          rest?.guestView || roleType === CONSULTANT
            ? null
            : t(`${campaignsPrefix}:deleteCampaign`)
        }
        btnFunc={deleteCampaign}
        btnVariant="outlined"
        btnLabel2={t(`${campaignsPrefix}:viewAsClient`)}
        btnFunc2={toggleContactView} />

      {/*//TODO NEEDS BREADCRUMBS !!*/}
      <ContentWrapper loading={loading}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridRow}>
            <AppBar position="static" className={classes.appBar}>
              <CustomTabs tabs={ tabs } onChange={ handleChange } />
            </AppBar>
          </Grid>

          <Grid item xs={12} className={classes.gridRow}>
            <TabPanel value={currentTab} index={0}>
              <CampaignDetails
                guestView={rest?.guestView}
                toggleContactView={toggleContactView} />
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <CandidateDetails
                guestView={rest?.guestView}
                consultancyId={consultancyId}
                campaignId={campaignId} />
            </TabPanel>

            <TabPanel value={currentTab} index={2}>
              <CalendarView
                calendarEntries={campaign.campaignEvents}
                canUpdateCalendar={true}
                guestView={rest?.guestView} />
            </TabPanel>

            {
              campaign && campaign.campaignModuleIds && campaign.campaignModuleIds.includes(DWI_ID) && (
                <TabPanel value={currentTab} index={3}>
                  <DynamicWeightIndex guestView={rest?.guestView} />
                </TabPanel>
              )
            }

            {
              !rest?.guestView && (
                <>
                  <TabPanel value={currentTab} index={4}>
                    <CampaignSettings />
                  </TabPanel>

                  <TabPanel value={currentTab} index={5}>
                    <CampaignModuleManagement />
                  </TabPanel>
                </>
              )
            }
          </Grid>
        </Grid>
      </ContentWrapper>
    </div>
  );

}

export default CampaignDetail;
