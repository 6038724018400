import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  saveDefaultCampaignDocument,
  removeDefaultCampaignDocument,
} from "src/api/consultancyApi";
import { useSnackbar } from "notistack";
import DocumentList from "src/components/DocumentList";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    marginTop: theme.spacing(2),
  },
}));

const DefaultCampaignDocuments = ({ consultancyId, campaignDocuments }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation([
    "common",
    "snackbar",
    "consultancy",
    "tooltips",
  ]);
  const classes = useStyles();
  const [documents, setDocuments] = useState([]);

  const saveDocument = (document, index) => {
    return saveDefaultCampaignDocument(consultancyId, document)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:savedDefaultDocument"), {
            variant: "success",
          });
          let tempDocs = documents;
          tempDocs[index] = response.data;
          setDocuments([...tempDocs]);
        } else
          enqueueSnackbar(t("snackbar:errorDefaultDocument"), {
            variant: "error",
          });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:errorDefaultDocument"), {
          variant: "error",
        });
      });
  };

  const newDocument = () => {
    setDocuments((prevDocuments) => [
      ...prevDocuments,
      {
        name: "",
        visibility: "private",
      },
    ]);
  };

  const removeDocument = (document, index) => {
    if (document.id) {
      removeDefaultCampaignDocument(consultancyId, document.id)
        .then((response) => {
          if (response.status === 200) {
            let tempDocs = documents;
            tempDocs.splice(index, 1);
            setDocuments([...tempDocs]);
            enqueueSnackbar(t("snackbar:deletedDocument"), {
              variant: "success",
            });
          } else
            enqueueSnackbar(t("snackbar:errorDeletingDocument"), {
              variant: "error",
            });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(t("snackbar:errorDeletingDocument"), {
            variant: "error",
          });
        });
    } else {
      let tempDocs = documents;
      tempDocs.splice(index, 1);
      setDocuments([...tempDocs]);
      enqueueSnackbar(t("snackbar:deletedDocument"), {
        variant: "success",
      });
    }
  };

  const handleChange = (event, index) => {
    event.persist();
    let tempDocs = documents;
    tempDocs[index].name = event.target.value;
    setDocuments([...tempDocs]);
  };

  const handleVisibilityChange = (state) => {
    let { id, visibility } = state;
    let index = id?.split("_")[0];
    let tempDocs = documents;
    if (index) tempDocs[index].visibility = visibility;
    setDocuments([...tempDocs]);
  };

  useEffect(() => {
    setDocuments(campaignDocuments);
  }, [campaignDocuments]);

  return (
    <Card>
      <CardContent>
        <BlockHeader
          headerTooltip={t("tooltips:manageDefaultCampaignDocs")}
          reduceMargin
          header={t("consultancy:defaultCampaignDocuments")}
        />
        <Typography className={classes.header} spacing={2} variant="h5">
          {t("consultancy:campaignDocumentsDescription")}
        </Typography>
        <DocumentList
          documents={documents}
          functions={{
            handleChange,
            handleVisibilityChange,
            onNew: newDocument,
            onSave: saveDocument,
            removeDocument
          }}
        />
      </CardContent>
    </Card>
  );
};

export default DefaultCampaignDocuments;
