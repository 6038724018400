import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import * as sessionApi from 'src/api/sessionApi';
import CreateUserModal from './CreateUserModal';
import CreateGroupModal from './CreateGroupModal';
import UserListCard from './UserListCard';
import GroupListCard from './GroupListCard';
import UsersAndGroupsContainer from './UsersAndGroupsContainer';
import { ReturnButton } from 'src/components/LayoutComponents/ReturnButton';

function UsersandGroups({ usersAndGroups, consultancyId }) {
  const [t] = useTranslation(['common', 'users', 'groups']);
  const { enqueueSnackbar } = useSnackbar();
  const [openUser, setOpenUser] = useState(false);
  const [searchUsers, setSearchUsers] = useState();
  const [searchGroup, setSearchGroup] = useState();

  const handleUserSearch = (e) => {
    setSearchUsers(e.target.value);
  };

  const handleGroupSearch = (e) => {
    setSearchGroup(e.target.value);
  };

  const handleOpenUser = () => {
    setOpenUser(true);
  };
  const handleCloseUser = () => {
    setOpenUser(false);
  };
  const [openGroup, setOpenGroup] = useState(false);
  const handleOpenGroup = () => {
    setOpenGroup(true);
  };
  const handleCloseGroup = () => {
    setOpenGroup(false);
  };
  const onUserSuccess = (userDetails) => {
    let { name, roleType, userId, id } = userDetails;
    if (roleType === 'Admin') {
      usersAndGroups.admins.push({
        id: id,
        consultancyId: consultancyId,
        name: name,
        activated: false,
        enabled: true,
        userId,
      });
    } else if (roleType === 'Consultant') {
      usersAndGroups.consultants.push({
        id: id,
        consultancyId: consultancyId,
        name: name,
        activated: false,
        enabled: true,
        userId,
      });
    }
  };
  const onGroupSuccess = (groupDetails) => {
    usersAndGroups.groups.push(groupDetails);
  };

  const onGroupDelete = (groupId) => {
    let index = usersAndGroups.groups.findIndex((x) => x.id === groupId);
    usersAndGroups.groups.splice(index, 1);
  };

  const resendActivationEmail = (event, userId) => {
    event.preventDefault();
    return sessionApi
      .resendActivationEmail(Number(userId), Number(consultancyId))
      .then(() => {
        enqueueSnackbar(`${t('snackbar:resentActiationEmail')}`, {
          variant: 'success',
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${t(`snackbar:${error.response.data.error.code}`)}`, {
          variant: 'error',
        });
      });
  };

  const filterBySearchCriteria = (array, search) =>
    search
      ? array.filter((user) =>
          user.name.toLowerCase().includes(search.toLowerCase())
        )
      : array;

  const adminUsers = usersAndGroups.admins.map((user) => ({
    ...user,
    role: 'Admin',
  }));

  const consultantUsers = usersAndGroups.consultants.map((user) => ({
    ...user,
    role: 'Consultant',
  }));

  return (
    <Grid container spacing={3}>
      <UsersAndGroupsContainer
        title={t('common:allUsers')}
        btnLabel={t('users:createUser')}
        btnFunc={handleOpenUser}
        handleSearch={handleUserSearch}
      >
        {usersAndGroups.admins.length > 0 ? (
          <UserListCard
            userArray={filterBySearchCriteria(adminUsers, searchUsers)}
            listCardAction={resendActivationEmail}
          />
        ) : null}
        {usersAndGroups.consultants.length > 0 ? (
          <UserListCard
            userArray={filterBySearchCriteria(consultantUsers, searchUsers)}
            listCardAction={resendActivationEmail}
          />
        ) : null}
      </UsersAndGroupsContainer>
      <UsersAndGroupsContainer
        handleSearch={handleGroupSearch}
        title={t('groups:groups')}
        btnLabel={t('groups:createGroup')}
        btnFunc={handleOpenGroup}
      >
        {usersAndGroups.groups.length > 0 ? (
          <GroupListCard
            groupArray={filterBySearchCriteria(
              usersAndGroups.groups,
              searchGroup
            )}
            onSuccess={onGroupDelete}
          />
        ) : null}
      </UsersAndGroupsContainer>
      <CreateUserModal
        onSuccess={onUserSuccess}
        consultancyId={consultancyId}
        open={openUser}
        handleClose={handleCloseUser}
      />
      <CreateGroupModal
        onSuccess={onGroupSuccess}
        consultancyId={consultancyId}
        usersAndGroups={usersAndGroups}
        open={openGroup}
        handleClose={handleCloseGroup}
      />
      <ReturnButton />
    </Grid>
  );
}

export default UsersandGroups;
