import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
  Badge,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import avatarPlaceholder from 'src/assets/images/missing_avatar.svg';
import moment from 'moment';

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../utils/useSubscriptionPrefix';

let ejs = require('ejs');

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    marginBottom: '30px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.01)',
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    paddingBottom: '15px',
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  title: {
    fontSize: 14,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  pos: {
    marginBottom: 12,
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 0 0',
  },
});

export default ({ notification, handleDelete, markAsRead }) => {
  let {
    notificationTitle: title,
    notificationText: text,
    id,
    image,
    notificationLink: link,
    createdAt,
    read,
    notificationTags,
  } = notification;
  const classes = useStyles();
  const [t] = useTranslation(['notifications', 'vacancyNotifications']);
  const today = moment();

  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const translatedNotificationString = t(`${notificationsPrefix}:${text}`);

  const parseNotification = () => {
    if (notificationTags) {
      return ejs.render(
        translatedNotificationString,
        JSON.parse(notificationTags)
      );
    } else {
      return translatedNotificationString;
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid item xs={10}>
            <Link
              onClick={() => markAsRead(id)}
              className={classes.link}
              href={link}
            >
              <Grid xs={4} sm={1}>
                <Badge color='primary' badgeContent={!read ? ' ' : null}>
                  <Avatar
                    className={classes.avatar}
                    src={avatarPlaceholder}
                    style={image ? { transform: 'translateY(-70px)' } : null}
                  />
                </Badge>
              </Grid>
              <Grid item xs={6} sm={9}>
                <Grid item xs={12}>
                  <Typography variant='h5'>
                    {t(`${notificationsPrefix}:${title}`)}
                  </Typography>
                  <Typography variant='body'>{parseNotification()}</Typography>
                  <Grid item xs={12} sm={4} className={classes.dates}>
                    {today.diff(moment(createdAt), 'days') > 0 && (
                      <Chip
                        label={
                          <Typography variant='body'>
                            {today.diff(moment(createdAt), 'days') +
                              ' ' +
                              t(`${notificationsPrefix}:daysAgo`)}
                          </Typography>
                        }
                      />
                    )}
                    <Chip
                      label={
                        <Typography variant='body'>
                          {moment(createdAt).format('DD/MM/YYYY HH:mm')}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item xs={2} sm={2} className={classes.actions}>
            <div>
              {!read && (
                <Typography
                  variant='h5'
                  className={classes.link}
                  onClick={() => markAsRead(id)}
                >
                  {t(`${notificationsPrefix}:markAsRead`)}
                </Typography>
              )}
            </div>
            <div>
              <Typography
                variant='h5'
                className={classes.link}
                onClick={() => handleDelete(id)}
              >
                {t(`${notificationsPrefix}:delete`)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
