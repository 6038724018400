import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  colors,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import VideoAssessmentQuestionsAndTimes from "./VideoAssessmentQuestionsAndTimes";
import QuillEditor from "src/components/QuillEditor";

import { ModalFormWrapper } from "src/components/ModalFormWrapper";

import * as videoAssessmentApi from './../../../api/videoAssessmentApi';

import * as xss from './../../../utils/xss';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function SetupVideoAssessmentModal({ open, onClose }) {
  const classes = useStyles();
  const [t] = useTranslation(["common", "organisations"]);

  const { enqueueSnackbar } = useSnackbar();
  const campaign = useSelector((state) => state.campaign);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      questionsAndTimes: [
        {
          question: "",
          time: "",
        },
      ],
      instructions: "",
      prepTime: "",
    },
    touched: {},
    errors: {},
  });

  const addQuestion = (e) => {
    e.preventDefault();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questionsAndTimes: [
          ...prevFormState.values.questionsAndTimes,
          { question: "", time: "" },
        ],
      },
    }));
  };

  const removeQuestionAndTime = (index) => {
    let tempArray = formState.values.questionsAndTimes;
    tempArray.splice(index, 1);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questionsAndTimes: tempArray,
      },
    }));
  };

  const schema = {
    question: {
      presence: { allowEmpty: false, message: 't("common:required")' },
    },
    time: {
      presence: { allowEmpty: false, message: 't("common:required")' },
    },
  };

  const onSubmit = function(event) {
    event.preventDefault();
    
    const { instructions, prepTime } = formState.values;
    const questions = formState.values.questionsAndTimes.map((qnt, i) => ({
      text: qnt.question,
      timeLimit: qnt.time,
      order: i + 1
    }));

    videoAssessmentApi.createVideoAssessmentWithQuestions(campaign.id, prepTime, instructions, questions)
      .then(() => {
        enqueueSnackbar(t('candidates:launchpadUpdatedSuccess'), { variant: 'success' });
        onClose();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('candidates:launcpadUpdateFailed'), { variant: 'error' });
      });
  };

  const handleChange = (event, index) => {
    let timesAndQ = formState.values.questionsAndTimes;

    timesAndQ[index][event.target.name] = event.target.value;
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questionsAndTimes: timesAndQ,
      },
    }));
  };

  const handleUpdate = (event) => {
    let tempInstructions = formState.values.instructions;
    let tempPrepTime = formState.values.prepTime;

    if (event.target?.value) tempPrepTime = event.target.value;
    else tempInstructions = event;
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        prepTime: tempPrepTime,
        instructions: tempInstructions,
      },
    }));
  };

  useEffect(() => {
    let errors = formState.values.questionsAndTimes.map((questionsAndTimes) => {
      return validate(questionsAndTimes, schema);
    });

    const instructions = xss.htmlToPlainText(formState.values.instructions);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid:
        !errors.some((error) => typeof error === "object") &&
        formState.values.prepTime !== "" &&
        instructions,
      errors: errors || [],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  return (
    <ModalFormWrapper
      formTitle={t("launchpad:launchpadInterview")}
      handleOpen={open}
      handleClose={onClose}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form onSubmit={onSubmit}>
            <div className={classes.fields}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("launchpad:preperationTime")}
                    </InputLabel>
                    <div
                      style={{
                        position: "absolute",
                        top: -8,
                        left: 117,
                        zIndex: 100,
                      }}
                    >
                      <Tooltip
                        enterTouchDelay={1}
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                        title={t("tooltips:selectAssessmentTime")}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </div>
                    <Select
                      required
                      name="prepTime"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label={t("launchpad:preperationTime")}
                      onChange={(e) => handleUpdate(e)}
                    >
                      <MenuItem value={15}>
                        15 {t("launchpad:seconds")}
                      </MenuItem>
                      <MenuItem value={30}>
                        30 {t("launchpad:seconds")}
                      </MenuItem>
                      <MenuItem value={45}>
                        45 {t("launchpad:seconds")}
                      </MenuItem>
                      <MenuItem value={60}>
                        60 {t("launchpad:seconds")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">{t("launchpad:questions")}</Typography>
              </Grid>

              {formState.values.questionsAndTimes.map(
                (questionAndTime, index) => (
                  <VideoAssessmentQuestionsAndTimes
                    index={index}
                    count={formState.values.questionsAndTimes.length}
                    removeQuestionAndTime={(e) => removeQuestionAndTime(index)}
                    handleChange={(e) => handleChange(e, index)}
                    question={questionAndTime.question}
                    time={questionAndTime.time}
                  />
                )
              )}

              <Button
                className={classes.submitButton}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                onClick={addQuestion}
                disabled={formState.values.questionsAndTimes.length >= 10}
              >
                {t("launchpad:addQuestion")}
              </Button>

              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h4">
                    {t("launchpad:instructions")}
                  </Typography>
                  <Tooltip
                    enterTouchDelay={1}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    style={{ marginLeft: "10px" }}
                    title={t("tooltips:insertInstructions")}
                  >
                    <InfoIcon />
                  </Tooltip>
                </div>
              </Grid>

              <Grid item xs={12}>
                <QuillEditor
                  value={formState.values.instructions}
                  handleChange={handleUpdate}
                  toolbarOptions={["link"]}
                />
              </Grid>
            </div>
            <Button
              className={classes.submitButton}
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
            >
              {t("launchpad:createLaunchpadInterview")}
            </Button>
          </form>
        </Grid>
      </Grid>
    </ModalFormWrapper>
  );
}
export default SetupVideoAssessmentModal;
