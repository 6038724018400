import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import {
  getConsultancyDefaultDocuments,
  getConsultancyDefaultWelcomeMessages,
} from 'src/api/consultancyApi';
import DefaultCampaignDocuments from './DefaultCampaignDocuments';
import DefaultCandidateDocuments from './DefaultCandidateDocuments';
import DefaultWelcomeMessages from './DefaultWelcomeMessages';
import { useSelector } from 'react-redux';

import * as subscriptionPlansIds from './../../../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
}));

const DefaultDocuments = ({consultancyId}) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [campaignDocuments, setCampaignDocuments] = useState([]);
  const [candidateDocuments, setCandidateDocuments] = useState([]);
  const [candidateTAPDocs, setCandidateTAPDocs] = useState([]);
  const [defaultMessages, setDefaultMessages] = useState([]);
  const roleType = useSelector((state) =>
    state?.session?.roleType?.toLowerCase()
  );
  const isConsultancyWithTAPSubscriptionPlan = useSelector((state) => {
    if (state.session.consultancySubscriptionPlanId === subscriptionPlansIds.TA || state.session.consultancySubscriptionPlanId === subscriptionPlansIds.TA_PRO) {
      return true;
    }

    return false;
  });

  const setDefaultDocuments = () => {
    return getConsultancyDefaultDocuments(consultancyId)
      .then(([campaignDocs, candidateDocs, candidateTAPDocs]) => {
        setCandidateDocuments(candidateDocs.data || []);
        setCampaignDocuments(campaignDocs.data || []);
        setCandidateTAPDocs(candidateTAPDocs.data);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const getMessages = () => {
    return getConsultancyDefaultWelcomeMessages(consultancyId, roleType)
      .then((response) => {
        setDefaultMessages(response.data);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  useEffect(() => {
    setDefaultDocuments();
    getMessages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isConsultancyWithTAPSubscriptionPlan) {
    return (
      <Grid className={classes.root} spacing={3} xs={12}>
        <DefaultCampaignDocuments
          consultancyId={consultancyId}
          campaignDocuments={campaignDocuments}
        />
        <DefaultCandidateDocuments
          consultancyId={consultancyId}
          candidateDocuments={candidateDocuments}
        />
        <DefaultCandidateDocuments
          consultancyId={ consultancyId }
          candidateDocuments={ candidateTAPDocs }
          isForTalentAlert={ true } />
      </Grid>
    );
  }

  return (
    <Grid className={classes.root} spacing={3} xs={12}>
      <DefaultCampaignDocuments
        consultancyId={consultancyId}
        campaignDocuments={campaignDocuments}
      />
      <DefaultCandidateDocuments
        consultancyId={consultancyId}
        candidateDocuments={candidateDocuments}
      />
      <DefaultCandidateDocuments
        consultancyId={ consultancyId }
        candidateDocuments={ candidateTAPDocs }
        isForTalentAlert={ true } />
      <DefaultWelcomeMessages
        consultancyId={consultancyId}
        defaultMessages={defaultMessages}
      />
    </Grid>
  );
};

export default DefaultDocuments;
