import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const DotIndicator = withStyles({
	name: "DotIndicator",
})(({ active, className, classes, consultancyColour, ...props }) => (
	<button type={"button"} tabIndex={0} className={className} {...props}>
		<span
			style={{
				height: "1rem",
				width: "1rem",
				border: active ? "2px solid #bbb" : "1px solid #d5d5d5",
				borderRadius: "50%",
				display: "inline-block",
				cursor: "pointer",
			}}
		></span>
	</button>
));
DotIndicator.propTypes = {
	className: PropTypes.string,
	active: PropTypes.bool,
};
DotIndicator.defaultProps = {
	className: "",
	active: false,
};
DotIndicator.displayName = "DotIndicator";
export default DotIndicator;
