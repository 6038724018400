import React, { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import {
  TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as ConsultancyApi from "src/api/consultancyApi";
import { useTranslation } from "react-i18next";



function UserSelect({handleChange, disabled, value}) {
  const [t,] = useTranslation(["campaigns"]);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [users, setUsers] = useState([]);

  const getConsultancyUsers = function(consultancyId) {
    return ConsultancyApi.getConsultancyUsersNamesAndIds(consultancyId)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let mounted = true;
    if(mounted){
      getConsultancyUsers(consultancyId);
    }
    return () => {
      mounted = false;
    }
  },[consultancyId]);

  return (
    <Autocomplete
      disabled={disabled}
      id="contactAutoComplete"
      options={users}
      getOptionLabel={(option) => option.name}
      value={value}
      onChange={handleChange}
      style={{width:'100%'}}
      renderInput={(params) => {
        return <TextField {...params} fullWidth label={t('campaigns:selectCampaignManager')} variant="outlined"/>
      }}
    />
  );
}
export default UserSelect;
