import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const UserEnableButton = ({ user, handleStatusChange, enable }) => {
  const [t] = useTranslation(["common"]);
  return (
    <Button
      fullWidth
      color="primary"
      size="small"
      type="submit"
      variant="outlined"
      onClick={() => handleStatusChange(!enable, user.userId)}
    >
      {enable ? t("common:disableLabel") : t("common:enableLabel")}
    </Button>
  );
};

export default UserEnableButton;
