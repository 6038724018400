import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid, AppBar } from "@material-ui/core";
import { useSelector } from "react-redux";
import Page from "src/components/Page";
import CampaignDetail from "src/views/Campaigns/CampaignDetail";
import { TabPanel } from "src/components/TabPanel";
import CampaignList from "src/views/Campaigns/CampaignList";
import ConsultancyAdminTalentAlerts from "src/views/TalentAlertConsultant";
import ChildConsultancies from "src/components/ChildConsultancies";
import CandidateProfile from "src/views/CandidateProfile";
import TalentAlertDetail from "src/views/TalentAlertConsultant/TalentAlertDetail";
import HomeIcon from "@material-ui/icons/HomeOutlined";
import NewReleasesOutlinedIcon from "@material-ui/icons/NewReleasesOutlined";
import ChildConsultanciesIcon from "@material-ui/icons/ChildCare";
import ContactDetailViews from "src/views/ContactManagement";
import EmployerDetailViews from "src/views/ConsultancyEmployerDetails";
import CandidateDetails from "src/views/Candidates/CandidateDetails";
import CustomTabs from './../../../components/LayoutComponents/CustomTabs';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0% 5%",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  gridRow: {
    marginTop: "20px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  tab: {
    color: theme.palette.primary.main,
  },
}));

const ChildConsultancyView = ({
  childConsultancyId,
  campaignId,
  employerId,
  contactId,
  candidateId,
}) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState(0);
  const topLevelRoles = ["CoreAdmin", "SystemAdmin", "Admin"];
  const role = useSelector((state) => state.session.roleType);
  const tabs = [
    { value: 0, label: "campaigns", icon: <HomeIcon /> },
    { value: 1, label: "talentAlerts", icon: <NewReleasesOutlinedIcon /> },
    { value: 2, label: "childrenConsultanciesLabel", icon: <ChildConsultanciesIcon /> }
  ];

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  return (
    <Page className={classes.root}>


      <Grid>
        <Switch>
          <Route exact path="/dashboard/consultancy/:childConsultancyId">
            <Grid container spacing={3}>
              <Grid item xs={12} className={classes.gridRow}>
                <AppBar position="static" className={classes.appBar}>
                  <CustomTabs tabs={ tabs } onChange={ handleChange } tabClassName={ classes.tab } />
                </AppBar>
              </Grid>
              <Grid item xs={12} className={classes.gridRow}>
                <TabPanel value={currentTab} index={0}>
                  <CampaignList
                    consultancyId={childConsultancyId}
                    guestView={topLevelRoles.includes(role)}
                  />
                </TabPanel>
                <TabPanel value={currentTab} index={1}>
                  <ConsultancyAdminTalentAlerts
                    consultancyId={childConsultancyId}
                    guestView={topLevelRoles.includes(role)}
                  />
                </TabPanel>
                <TabPanel value={currentTab} index={2}>
                  <ChildConsultancies
                    parentConsultancyId={childConsultancyId}
                    childrenConsultanciesId={childConsultancyId}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/campaigns/:campaignId"
          >
          <CampaignDetail
            consultancyId={childConsultancyId}
            campaignId={campaignId}
            guestView={topLevelRoles.includes(role)}
          />
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/employers/:employerId"
          >
            <EmployerDetailViews
              employerId={employerId}
              consultancyId={childConsultancyId}
            />
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/candidates/:candidateId"
          >
            <CandidateDetails
              candidateId={candidateId}
              consultancyId={childConsultancyId}
            />
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/employers/:employerId/contacts/:contactId"
          >
            <ContactDetailViews
              employerId={employerId}
              consultancyId={childConsultancyId}
              contactId={contactId}
            />
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/campaigns/:campaignId/candidates/:candidateId"
          >
            <CandidateProfile
              consultancyId={childConsultancyId}
              campaignId={campaignId}
              candidateId={candidateId}
              guestView={topLevelRoles.includes(role)}
            />
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/talent-alerts/:campaignId"
          >
            <TalentAlertDetail
              consultancyId={childConsultancyId}
              talentAlertId={campaignId}
              guestView={topLevelRoles.includes(role)}
            />
          </Route>
          <Route
            exact
            path="/dashboard/consultancy/:childConsultancyId/talent-alerts/:campaignId/candidates/:candidateId"
          >
            <CandidateProfile
              consultancyId={childConsultancyId}
              campaignId={campaignId}
              candidateId={candidateId}
              guestView={topLevelRoles.includes(role)}
            />
          </Route>
        </Switch>
      </Grid>
    </Page>
  );
};

export default ChildConsultancyView;
