import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AppBar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as SystemAdminApi from './../../../api/SystemAdminApi';

import DefaultCampaignDocuments from './DefaultCampaignDocuments';
import DefaultCandidateDocuments from './DefaultCandidateDocuments';
import DefaultWelcomeMessages from './DefaultWelcomeMessages';
import EmailSettings from './EmailSettings';
import DWISettings from './../../GlobalModuleSettings/Dwi';
import KCQSettings from './../../GlobalModuleSettings/Kcq';

import { TabPanel } from './../../../components/TabPanel';
import { ContentWrapper } from './../../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../../components/LayoutComponents/PageTitle';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3)
	},
	appBar: {
		boxShadow: 'none',
		backgroundColor: 'transparent'
	},
	settingsHeader: {
		marginBottom: '40px'
	}
}));

const SystemAdminSettings = () => {

	const classes = useStyles();

	const { enqueueSnackbar } = useSnackbar();

	const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'settings']);

	const roleType = useSelector(state => state?.session?.roleType?.toLowerCase());

	const [campaignDocuments, setCampaignDocuments] = useState([]);
	const [candidateDocuments, setCandidateDocuments] = useState([]);
	const [defaultMessages, setDefaultMessages] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);

	const tabs = [
		{ value: 0, label: t("settings:generalSettings") },
		{ value: 1, label: t("settings:emailSettings") },
		{ value: 2, label: t("settings:dwiSettings") },
		{ value: 3, label: t("settings:kcqSettings") }
	];

	useEffect(() => {
		setDefaultDocuments();
		setMessages();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setDefaultDocuments = () => {
		SystemAdminApi.getDefaultDocuments()
			.then(([candidateDocs, campaignDocs]) => {
				setCandidateDocuments(candidateDocs.data);
				setCampaignDocuments(campaignDocs.data);
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const setMessages = () => {
		SystemAdminApi.getDefaultWelcomeMessage(roleType)
			.then((messages) => {
				setDefaultMessages(messages.data);
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(error, { variant: 'error' });
			});
	};

	const handleTabChange = (value) => {
		setCurrentTab(value);
	};

	return (
		<>
			<PageTitle pageTitle={t("common:settings")} />

			<ContentWrapper>
				<Grid item xs={12}>
					<AppBar position="static" className={classes.appBar}>
						<CustomTabs tabs={ tabs } onChange={ handleTabChange } />
					</AppBar>
				</Grid>

				<TabPanel value={currentTab} index={0}>
					<DefaultCampaignDocuments campaignDocuments={campaignDocuments} />
					<DefaultCandidateDocuments candidateDocuments={candidateDocuments} />
					<DefaultWelcomeMessages defaultMessages={defaultMessages} />
				</TabPanel>

				<TabPanel value={currentTab} index={1}>
					<EmailSettings />
				</TabPanel>

				<TabPanel value={currentTab} index={2}>
					<DWISettings />
				</TabPanel>

				<TabPanel value={currentTab} index={3}>
					<KCQSettings />
				</TabPanel>
			</ContentWrapper>
		</>
	);

};

export default SystemAdminSettings;
