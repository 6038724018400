import {
	Button,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as modulesApi from "src/api/modulesApi";
import { SET_KCQ_CATEGORIES, SET_QUESTION_SETS, SET_PERSONAL_QUESTION_SETS } from "src/actions/ConsultancyModules/kcq/kcqActions";
import CreateQuestionSetModal from "src/components/createQuestionSetModal";


const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	createButton: {
		inlineSize: "max-content"
	},
	questionSet: {
		padding: theme.spacing(2),
		margin: theme.spacing(1)
	},
	questionGrid: {
		marginLeft: theme.spacing(1)
	},
	question: {
		margin: theme.spacing(1)
	},
	editButton: {
		paddingRight: "20px",
	},
	list: {
		padding: "0",
		backgroundColor: theme.palette.background.paper,
	},
	listItem: {
		padding: "15px",
	},
	container: {
		padding: "0% 5%",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	topGrid: {
		marginBottom: theme.spacing(1)
	},
	modalCard: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
			transform: `translate(${0}%, ${50}%)`,
		},
		[theme.breakpoints.up("md")]: {
			width: "50%",
			transform: `translate(${50}%, ${50}%)`,
		},
		"&:focus": {
			outline: "none",
		},
	},
	gridRow: {
		marginTop: "20px",
	},
	appBar: {
		boxShadow: "none",
		backgroundColor: "transparent",
	},
	menuBox: {
		marginLeft: "auto",
		maxWidth: "fit-content"
	}
}));

function QuestionSetSettings({ consultancyId }) {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const kcqCategories = useSelector((state) => state.kcq.kcqCategories);
	const userId = useSelector((state) => state.session.userId)
	const [t] = useTranslation([
		"modules",
		"common",
		"snackbar",
		"tooltips",
	]);
	const dispatch = useDispatch();
	const [openCreate, setOpenCreate] = useState(false);
	const [questionSets, setQuestionSets] = useState([]);
	const [personalQuestionSets, setPersonalQuestionSets] = useState([]);
	const [chosenSet, setChosenSet] = useState(null);

	const menu = 'personal';

	kcqCategories.sort((a, b) => {
		var nameA = a.name.toUpperCase();
		var nameB = b.name.toUpperCase();

		if (nameA < nameB) {
			return -1;
		}
		if (nameA > nameB) {
			return 1;
		}

		// names must be equal
		return 0;
	});

	useEffect(() => {
		const questionSetsPromise = modulesApi.getQuestionSets();
		const personalQuestionSetsPromise = modulesApi.getPersonalQuestionSets(Number(userId), consultancyId);

		Promise.all([questionSetsPromise, personalQuestionSetsPromise])
			.then(([questionsSetsResponse, personalQuestionSetsResponse]) => {
				setQuestionSets(questionsSetsResponse.data);
				setPersonalQuestionSets(personalQuestionSetsResponse.data);
			})
			.catch(err => {
				console.log(err);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOpenCreate = () => {

		setOpenCreate(true);
	};
	const handleClose = () => {
		setOpenCreate(false);
	};

	const deleteQuestionSet = (event, questionSetId) => {
		event.preventDefault();
		return modulesApi
			.deletePersonalQuestionSet(consultancyId, questionSetId)
			.then((response) => {
				const afterPersonalQuestionSets = personalQuestionSets.filter((question) => {
					if (question.id !== questionSetId) {
						return true;
					}

					return false;
				});
				dispatch({ type: SET_PERSONAL_QUESTION_SETS, personalQuestionSets: afterPersonalQuestionSets });
				enqueueSnackbar(t("snackbar:dwiCategoryDeleteSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:dwiCategoryDeleteFailed"), {
					variant: "error",
				});
			});
	};

	const onCreateQuestionSetSuccess = (newQuestionSet) => {
		newQuestionSet.consultancyId = consultancyId
		newQuestionSet.userId = userId;
		if (chosenSet) {
			newQuestionSet.id = parseInt(chosenSet.id);
			console.log(newQuestionSet, '   here')
			return modulesApi
				.updatePersonalQuestionSet(consultancyId, newQuestionSet)
				.then((response) => {
					const mutatedArray = personalQuestionSets.map((el) => {
						if (el.id === response.data.id) {
							return response.data
						}
						else {
							return el;
						}
					})
					dispatch({ type: SET_PERSONAL_QUESTION_SETS, personalQuestionSets: mutatedArray });
					setOpenCreate(false);
					enqueueSnackbar("Set updates Successfully", {
						variant: "success",
					});
				})
				.catch((error) => {
					enqueueSnackbar("Error updating the Set", {
						variant: "error",
					});
				});
		}
		else {
			modulesApi
				.createPersonalQuestionSet(consultancyId, newQuestionSet)
				.then((response) => {
					setPersonalQuestionSets(prevState => [...prevState, response.data]);
					setOpenCreate(false);

					enqueueSnackbar(t('snackbar:qsCategoryCreateSuccess'), { variant: 'success' });
				})
				.catch((error) => {
					console.log(error);
					enqueueSnackbar(t('snackbar:qsCategoryCreateFailed'), { variant: 'error' });
				});
		}
	};

	const getKCQCategories = () => {
		return modulesApi.getKCQCategories(consultancyId).then((response) => {
			dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
		});
	};

	const getKCQPersonalSets = () => {
		return modulesApi.getPersonalQuestionSets(userId, consultancyId).then((response) => {
			dispatch({ type: SET_PERSONAL_QUESTION_SETS, personalQuestionSets: response.data });
		});
	};

	const getQuestionSets = () => {
		return modulesApi.getGlobalQuestionSets().then((response) => {
			dispatch({ type: SET_QUESTION_SETS, questionSets: response.data });
		});
	};

	useEffect(() => {
		getKCQCategories();
		getQuestionSets();
		getKCQPersonalSets();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Grid className={classes.topGrid} container spacing={3}>
				<Grid item xs={12} lg={9}></Grid>
				<Grid item xs={12} lg={3}>
					<Button
						className={classes.submitButton}
						color="primary"
						size="medium"
						onClick={() => {
							setChosenSet(null);
							handleOpenCreate()
						}}
						type="submit"
						variant="contained"
						fullWidth
					>
						{t("modules:createQuestionSet")}
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						{/* <Box className={classes.menuBox}>
							<Tabs value={menu} aria-label="menu" textColor="primary">
								<Tab value={'personal'} label="Personal" onClick={() => { setMenu('personal') }} />
								<Tab value={'predefined'} label="Predefined" onClick={() => { setMenu('predefined') }} />
							</Tabs>
						</Box> */}
						{
							menu === 'personal' && <Grid container spacing={3}>
								<Grid item xs={12}>
									{personalQuestionSets?.map((questionSet, index) => (
										<ExpansionPanel className={classes.questionSet} key={'questionSets' + index}>
											<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
												<Typography variant="h5" align="center">{questionSet.name}</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<Grid xs={12}>
													<Grid item lg={2} xs={12} container style={{ marginBottom: "20px", marginLeft: "auto" }}>
														<Button
															color="primary"
															size="medium"
															onClick={() => {
																setChosenSet(questionSet)
																handleOpenCreate()
															}}
															type="submit"
															variant="contained"
															fullWidth
														>
															Edit
														</Button>
													</Grid>
													<Grid item lg={2} xs={12} container style={{ marginBottom: "20px", marginLeft: "auto" }}>
														<Button
															color="error"
															size="medium"
															onClick={(event) =>
																deleteQuestionSet(event, questionSet.id)
															}
															type="submit"
															variant="contained"
															fullWidth
														>
															{questionSet.hasDefault
																? `${t("common:restoreToDefault")}`
																: `${t("common:delete")}`}
														</Button>
													</Grid>

													<Grid xs={12}>
														{questionSet.questions?.map((category, catIndex) => (
															<>
																<ExpansionPanel key={'questionSets' + index + 'cat' + catIndex}>
																	<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
																		<Typography variant="h5" align="center">{category.name}</Typography>
																	</ExpansionPanelSummary>
																	<ExpansionPanelDetails>
																		<Grid className={classes.questionGrid} item xs={12}>
																			{
																				category.questions?.map((question) => {
																					return <Typography className={classes.question} variant="h5">{question}</Typography>
																				})
																			}
																		</Grid>
																	</ExpansionPanelDetails>
																</ExpansionPanel>

															</>
														))}
													</Grid>
												</Grid>
											</ExpansionPanelDetails>
										</ExpansionPanel>
									))}
								</Grid>
							</Grid>
						}

						{
							menu === 'predefined' && <Grid container spacing={3}>
								<Grid item xs={12}>
									{questionSets?.map((questionSet, index) => (
										<ExpansionPanel className={classes.questionSet} key={'questionSets' + index}>
											<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
												<Typography variant="h5" align="center">{questionSet.name}</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<Grid xs={12}>
													<Grid container style={{ marginBottom: "20px" }}>
													</Grid>

													<Grid xs={12}>
														{questionSet.questions?.map((category, catIndex) => (
															<>
																<ExpansionPanel key={'questionSets' + index + 'cat' + catIndex}>
																	<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
																		<Typography variant="h5" align="center">{category.name}</Typography>
																	</ExpansionPanelSummary>
																	<ExpansionPanelDetails>
																		<Grid className={classes.questionGrid} item xs={12}>
																			{
																				category.questions?.map((question) => {
																					return <Typography className={classes.question} variant="h5">{question}</Typography>
																				})
																			}
																		</Grid>
																	</ExpansionPanelDetails>
																</ExpansionPanel>

															</>
														))}
													</Grid>
												</Grid>
											</ExpansionPanelDetails>
										</ExpansionPanel>
									))}
								</Grid>
							</Grid>
						}

					</Grid>
				</Grid>
			</Grid>
			<CreateQuestionSetModal
				modalTitle={t("modules:createQuestionSet")}
				title={t("modules:questionSetModalTitle")}
				subtitle={t("modules:questions")}
				buttonText={chosenSet === null ? t("modules:createQuestionSet"): "Edit set"}
				onSuccess={onCreateQuestionSetSuccess}
				open={openCreate}
				chosenSet={chosenSet}
				handleClose={handleClose}
			/>
		</>
	);
}

export default QuestionSetSettings;
