import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actionTypes from "src/actions";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  TextField,
  Button,
  CircularProgress,
  CardContent,
  Card,
  Divider,
  Tooltip,
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import * as campaignApi from "src/api/campaignApi";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ContactListCard from "./ContactListCard";
import AddCreateCampaignContactModal from "./AddCreateCampaignContactModal";
import InviteUsersModal from "src/components/InviteUsersModal";
import AddIcon from "@material-ui/icons/Add";
import * as CampaignApi from "src/api/campaignApi";
import Swal from "sweetalert2";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

import { useCampaignOrVacancyTranslationPrefix } from './../../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 227,
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  updateButton: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contactListHeader: {
    display: "flex",
    margin: 5,
    marginBottom: 10,
    justifyContent: "space-between",
    alignItems: "center",
  },
  contactListHeaderButtons: {
    justifyContent: "space-between",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  inviteContactButton: {
    marginTop: theme.spacing(2),
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

const ContactsCard = (props) => {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(["common", "validation", "campaigns", "tooltips", "vacancies"]);

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaign = useSelector((state) => state.campaign);
  const theme = useTheme();
  const [contacts, setContacts] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [addableContacts, setAddableContacts] = useState([]);
  const [primaryContact, setPrimaryContact] = useState(null);
  const [primaryLoading, setPrimaryLoading] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [inviteContactModalOpen, setInviteContactModalOpen] = useState(false);

  const handleInviteContactModalOpen = () => {
    if (campaign.status.id !== 2) {
      Swal.fire({
        title: t("snackbar:theCampaignIsNotLive"),
        text: t("snackbar:invitingContact"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("snackbar:proceedAnyway"),
        cancelButtonText: t("snackbar:cancel"),
        confirmButtonColor: theme.palette.warning?.main
          ? theme.palette.warning.main
          : theme.palette.primary.main,
      }).then((result) => {
        if (result.value) {
          setInviteContactModalOpen(true);
        }
      });
    } else {
      setInviteContactModalOpen(true);
    }
  };
  const handleInviteContactModalClose = () => {
    setInviteContactModalOpen(false);
  };

  const onOpenModal = () => {
    setContactModalOpen(true);
  };

  const onCloseModal = () => {
    setContactModalOpen(false);
  };

  const onInviteContactSuccess = (invitationDetails) => {
    return CampaignApi.sendCampaignContactInvitations(
      {
        emailSubject: invitationDetails.emailSubject,
        emailContent: invitationDetails.emailContent,
        selectedContacts: invitationDetails.selectedUsers,
      },
      consultancyId,
      campaign.id
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:contactInviteSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:contactInviteFailure"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      let primaryContactObject =
        campaign &&
        campaign.campaignContacts &&
        campaign.campaignContacts.find((contact) => contact.primary === true);

      setContacts(
        campaign && campaign.campaignContacts ? campaign.campaignContacts : []
      );
      setAddableContacts(
        campaign && campaign.addableContacts ? campaign.addableContacts : []
      );
      setPrimaryContact(primaryContactObject || null);
      setEmployers(campaign && campaign.employers ? campaign.employers : []);
    }

    return () => {
      mounted = false;
    };
  }, [campaign]);

  const onPrimaryContactChange = (event, value) => {
    setPrimaryContact(value);
  };

  const updatePrimaryContact = () => {
    if (primaryContact && primaryContact) {
      setPrimaryLoading(true);
      campaignApi
        .updatePrimaryContact(consultancyId, campaign.id, primaryContact.id)
        .then((success) => {
          if (success) {
            //TODO Re-retrieve Campaign
            enqueueSnackbar(`${t("snackbar:primaryContactSettingSuccess")}`, {
              variant: "success",
            });
          } else {
            throw Error("Failed to update primary contact");
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar(`${t("snackbar:primaryContactSettingFailure")}`, {
            variant: "error",
          });
        })
        .then(() => {
          setPrimaryLoading(false);
        });
    } else {
      enqueueSnackbar(`${t("snackbar:primaryContactCannotBeEmpty")}`, {
        variant: "error",
      });
    }
  };

  const handleRemove = (campaignContactId) => {
    let addableContacts;
    return campaignApi
      .removeContact(campaign.id, campaignContactId)
      .then((success) => {
        if (success) {
          return campaignApi.getContactsConnectedToCampaign(campaign.id);
        } else {
          throw new Error("Failed to add contact");
        }
      })
      .then((contacts) => {
        if (contacts && contacts.addableContacts && contacts.campaignContacts) {
          addableContacts = contacts.addableContacts;
          return contacts.campaignContacts.map((campaignContact) => {
            return {
              id: campaignContact.contactId,
              jobTitle: campaignContact.contact.jobTitle,
              firstName: campaignContact.contact.profile.firstName,
              lastName: campaignContact.contact.profile.lastName,
              email: campaignContact.contact.profile.user.email,
              primary: campaignContact.primary,
              weight: campaignContact.weight,
              invited: campaignContact.invited,
            };
          });
        } else {
          throw new Error("Failed to update added contacts");
        }
      })
      .then((campaignContacts) => {
        dispatch({
          type: actionTypes.UPDATE_CAMPAIGN_CONTACTS,
          campaignContacts: campaignContacts,
          addableContacts: addableContacts,
        });

        const currentUninvitedContacts = campaignContacts.filter(
          (contact) => contact.invited === false
        );
        dispatch({
          type: actionTypes.UPDATE_UNINVITED_CAMPAIGN_CONTACTS,
          uninvitedCampaignContacts: currentUninvitedContacts,
        });
        enqueueSnackbar(t("campaigns:contactRemoveSuccess"), {
          variant: "success",
        });
        return;
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("campaigns:contactRemoveFailure"), {
          variant: "error",
        });
      });
  };

  return (
    <Card className={classes.root}>
      <BlockHeader
        headerTooltip={t("tooltips:campaignSelectPrimaryContact")}
        card
        padding
        header={t("campaigns:primaryContactLabel")}
      />
      <CardContent>
        <div className={classes.fields}>
          {/* <Typography variant="h5">
						{t("campaigns:primaryContactLabel")}
					</Typography> */}
          <Autocomplete
            id="contactAutoComplete"
            options={contacts || []}
            disabled={props.guestView}
            getOptionLabel={(campaignContact) => {
              if (
                campaignContact &&
                campaignContact.jobTitle &&
                campaignContact.firstName &&
                campaignContact.lastName
              ) {
                return `${campaignContact.firstName} ${campaignContact.lastName} ${campaignContact.jobTitle} `;
              } else {
                return `${campaignContact.email}`;
              }
            }}
            value={primaryContact}
            onChange={onPrimaryContactChange}
            style={{ width: "100%" }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  fullWidth
                  label={t("campaigns:selectPrimaryContactLabel")}
                  variant="outlined"
                />
              );
            }}
          />
          {!props.guestView && (
            // <CardButton btnFunc={updatePrimaryContact} loading={primaryLoading} btnLabel={
            <Button
              className={classes.updateButton}
              color="primary"
              size="large"
              onClick={updatePrimaryContact}
              variant="contained"
              fullWidth
            >
              {primaryLoading ? (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                />
              ) : (
                t("common:update")
              )}
            </Button>
          )}
        </div>
        <Divider className={classes.divider} />
        <div>
          <BlockHeader
            card
            header={t("campaigns:contactListLabel")}
            icon={<AddIcon />}
            btnFunc={onOpenModal}
            tooltip={t("campaigns:addContact")}
            headerTooltip={t("tooltips:addContactsToCampaign")}
          />
          {contacts.map((campaignContact, index) => {
            return (
              <ContactListCard
                key={"contact_" + index}
                campaignContact={campaignContact}
                onRemove={handleRemove}
                guestView={props.guestView}
              />
            );
          })}
        </div>
        <Tooltip
          enterTouchDelay={1}
          classes={{
            tooltip: classes.tooltip,
          }}
          title={t("tooltips:inviteAddedContacts")}
        >
          <Button
            className={classes.inviteContactButton}
            color="primary"
            size="large"
            variant="contained"
            onClick={handleInviteContactModalOpen}
            fullWidth
          >
            {t("campaigns:inviteLabel")}
          </Button>
        </Tooltip>
      </CardContent>
      <AddCreateCampaignContactModal
        campaignId={campaign && campaign.id}
        open={contactModalOpen}
        contacts={addableContacts}
        employers={employers}
        handleClose={onCloseModal}
      />
      <InviteUsersModal
        onSuccess={onInviteContactSuccess}
        uninvitedUsers={campaign.uninvitedContacts}
        emailContent={campaign.contactInvitationEmail}
        emailSubject={campaign.contactInvitationEmailSubject}
        emailTags={campaign.contactInvitationEmailTags}
        open={inviteContactModalOpen}
        title={t(`${campaignsPrefix}:inviteCandidatesToCampaignLabel`)}
        buttonText={t(`${campaignsPrefix}:inviteToCampaign`)}
        handleClose={handleInviteContactModalClose}
      />
    </Card>
  );
};

export default ContactsCard;
