import React from "react";
import { Typography } from "@material-ui/core";
import { Grid, Button, Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function WelcomeMessage({ message, dismissWelcomeMessage }) {
  const [t] = useTranslation(['common']);
  return (
    <Card style={{margin: '10px 0px'}}>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h2" style={{marginBottom: '10px'}}>{message.name}</Typography>
            <Typography variant="h4">{message.message}</Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <div style={{textAlign: 'right'}}>
              <Button onClick={dismissWelcomeMessage} color="primary" variant="contained">{t('common:dismissLabel')}</Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
export default WelcomeMessage;
