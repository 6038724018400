import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as videoAssessmentApi from './../../../api/videoAssessmentApi';

import * as xss from './../../../utils/xss';

import { ModalFormWrapper } from './../../ModalFormWrapper';

import VideoAssessmentInstructions from './VideoAssessmentInstructions';
import VideoAssessmentProgress from './VideoAssessmentProgress';
import VideoAssessmentQuestion from './VideoAssessmentQuestion';

const useStyles = makeStyles(() => ({
  loader: {
    minWidth: '200px',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const VideoAssessment = ({ videoAssessment, videoAssessmentInterview, isOpen, handleClose }) => {

  const classes = useStyles();
  const [t] = useTranslation(['videoAssessment']);
  const { enqueueSnackbar } = useSnackbar();

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const { candidateId } = useSelector((state) => state.candidateCampaign.candidateProfile);

  const [isLoading, setIsLoading] = useState(true);
  const [doShowInstructions, setDoShowInstructions] = useState(true);
  const [doShowQuestion, setDoShowQuestion] = useState(false);

  const [preparationTime, setPreparationTime] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [instructions, setInstructions] = useState('');
  const [questions, setQuestions] = useState([]);
  const [videoAssessmentInterviewId, setVideoAssessmentInterviewId] = useState(-1);

  useEffect(() => {
    const parsedInstructions = xss.textToHtml(videoAssessment.instructions);

    setInstructions(parsedInstructions);
    setPreparationTime(videoAssessment.preparationTime);
    setQuestions(videoAssessment.questions);
  }, [videoAssessment]);

  useEffect(() => {
    if (videoAssessmentInterview && videoAssessmentInterview.answers?.length) {
      setCurrentQuestionIndex(videoAssessmentInterview.answers.length);
    }
  }, [videoAssessmentInterview]);
  
  useEffect(() => {
    window.addEventListener('beforeunload', onRefresh);

    return () => {
      window.removeEventListener('beforeunload', onRefresh);
    };
  }, []);

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
        .then((devices) => {
          const hasVideoInput = devices.some(d => d.kind === 'videoinput');
          const hasAudioInput = devices.some(d => d.kind === 'audioinput');

          if (!hasVideoInput || !hasAudioInput) {
            enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorRecordingDevice'), { variant: 'error' });
            handleClose();
            return;
          }

          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorRecordingDevice'), { variant: 'error' });
          handleClose();
        });
    } else {
      enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorRecordingDevice'), { variant: 'error' });
      handleClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRefresh = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const closeAssessment = (doClose = false) => {
    if (doShowInstructions || doClose === true) {
      handleClose();
      return;
    }

    enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorCloseModal'), { variant: 'error' });
  };

  const startAssessment = () => {
    if (videoAssessmentInterview) {
      setVideoAssessmentInterviewId(videoAssessmentInterview.id);

      if (videoAssessmentInterview.answers.length === videoAssessment.questions.length) {
        setDoShowInstructions(false);
      } else {
        setDoShowInstructions(false);
        setDoShowQuestion(true);
      }

      return;
    }

    videoAssessmentApi.createVideoAssessmentInterview(videoAssessment.id, videoAssessment.campaignId, candidateId)
      .then((response) => {
        setVideoAssessmentInterviewId(response.data.id);
        setDoShowInstructions(false);
        setDoShowQuestion(true);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorStartAssessment', { variant: 'error' }));
      });
  };

  const completeAssessment = () => {
    videoAssessmentApi.completeVideoAssessmentInterview(videoAssessmentInterviewId)
      .then(() => {
        closeAssessment(true);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorCompleteAssessment', { variant: 'error' }));
      });
  };

  const submitAnswer = (videoBlob) => {
    const formData = new FormData();

    formData.append('videoAssessmentInterviewId', videoAssessmentInterviewId);
    formData.append('questionId', questions[currentQuestionIndex].id);
    formData.append('timeToComplete', questions[currentQuestionIndex].timeLimit);
    formData.append('video', videoBlob, 'videoBlob.mp4');

    return videoAssessmentApi.createInterviewAnswer(formData, consultancyId, videoAssessment.campaignId);
  };

  const finishQuestion = () => {
    setDoShowQuestion(false);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const startNextQuestion = () => {
    setDoShowQuestion(true);
  };

  return (
    <>
      <ModalFormWrapper
        formTitle={ t("videoAssessment:videoAssessmentModalTitle") }
        formTooltip={ t("videoAssessment:videoAssessmentModalTooltip") }
        handleOpen={ isOpen }
        handleClose={ closeAssessment }
        closeButton={ doShowInstructions } >
        {
          isLoading ? (
            <>
              <Grid container spacing={3} className={classes.loader} >
                <CircularProgress />
              </Grid>
            </>
          ) : (
            <>
              {
                doShowInstructions ? (
                  <>
                    <VideoAssessmentInstructions
                      instructions={ instructions }
                      preparationTime={ preparationTime }
                      questionsCount={ questions.length }
                      startAssessment={ startAssessment } />
                  </>
                ) : doShowQuestion ? (
                  <>
                    <VideoAssessmentQuestion
                      question={ questions[currentQuestionIndex] }
                      questionNum={ currentQuestionIndex + 1 }
                      preparationTime={ preparationTime }
                      isFinalQuestion={ currentQuestionIndex + 1 === questions.length ? true : false }
                      submitAnswer={ submitAnswer }
                      nextQuestion={ finishQuestion } />
                  </>
                ) : (
                  <>
                    <VideoAssessmentProgress
                      completedQuestion={ currentQuestionIndex }
                      totalQuestions={ questions.length }
                      startQuestion={ startNextQuestion }
                      completeAssessment={ completeAssessment } />
                  </>
                )
              }
            </>
          )
        }
      </ModalFormWrapper>
    </>
  );
};

export default VideoAssessment;
