import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  AppBar,
  Button,
  Card,
  CardActions,
  CardContent,
  Tab,
  Tabs,
} from "@material-ui/core";
import { TabPanel } from "src/components/TabPanel";
import * as campaignActions from "src/actions/campaignActions";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import CreateCandidateModel from "src/components/CreateCandidateModal";
import AddExistingCandidateModal from "./AddExistingCandidateModal";
import InviteUsersModal from "src/components/InviteUsersModal";
import * as CampaignApi from "src/api/campaignApi";
import * as candidateStatusIds from "src/constants/candidateStatusIds";
import CandidateListCard from "./CandidateListCard";

import { useCampaignOrVacancyTranslationPrefix, useCandidatesOrVacancyCandidatesTranslationPrefix } from './../../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0% 5%",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  appBar: {
    boxShadow: "none",
  },
  cardContent: {
    padding: 0,
    maxHeight: 930,
    overflowY: "auto",
  },
  button: {
    minWidth: 227,
  },
}));

function CandidateStageView({ ...rest }) {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const candidatesPrefix = useCandidatesOrVacancyCandidatesTranslationPrefix();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(["campaigns", "candidates", "vacancies", "vacancyCandidates"]);

  const consultancyId = useSelector((state) =>
    rest?.consultancyId ? rest.consultancyId : state.session.consultancyId
  );
  const campaign = useSelector((state) => state.campaign);

  const [currentTab, setCurrentTab] = useState(0);
  const [newCandidateModalOpen, setNewCandidateModalOpen] = useState(false);
  const [existingCandidateModalOpen, setExistingCandidateModalOpen] = useState(
    false
  );
  const [inviteCandidateModalOpen, setInviteCandidateModalOpen] = useState(
    false
  );

  const tabs = [
    { value: 0, candidateStatusId: candidateStatusIds.ALL },
    { value: 1, candidateStatusId: candidateStatusIds.SHORTLIST },
    { value: 2, candidateStatusId: candidateStatusIds.FIRST_INTERVIEW },
    { value: 3, candidateStatusId: candidateStatusIds.FURTHER_INTERVIEW },
    { value: 4, candidateStatusId: candidateStatusIds.REJECTED },
    { value: 5, candidateStatusId: candidateStatusIds.OFFER_MADE },
    { value: 6, candidateStatusId: candidateStatusIds.HIDDEN },
  ];

  const handleChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleNewCandidateModalOpen = () => {
    setNewCandidateModalOpen(true);
  };
  const handleNewCandidateModalClose = () => {
    setNewCandidateModalOpen(false);
  };

  const handleExistingCandidateModalOpen = () => {
    setExistingCandidateModalOpen(true);
  };
  const handleExistingCandidateModalClose = () => {
    setExistingCandidateModalOpen(false);
  };

  const handleInviteCandidateModalOpen = () => {
    setInviteCandidateModalOpen(true);
  };
  const handleInviteCandidateModalClose = () => {
    setInviteCandidateModalOpen(false);
  };

  const onNewCandidateSuccess = (candidateDetails) => {
    candidateDetails.campaignId = campaign.id;
    return CampaignApi.createAndAssignConsultancyCandidate(candidateDetails)
      .then((response) => {
        enqueueSnackbar(t("candidates:candidateCreateSuccess"), {
          variant: "success",
        });
        dispatch({
          type: campaignActions.UPDATE_CAMPAIGN_CANDIDATES,
          payload: response.data,
        });
      })
      .catch(() => {
        enqueueSnackbar(t("candidates:candidateCreateError"), {
          variant: "error",
        });
      })
      .then(() => {
        handleNewCandidateModalClose();
      });
  };

  const onExistingCandidateSuccess = (candidateDetails) => {
    candidateDetails.campaignId = campaign.id;
    return CampaignApi.assignExistingCandidateToCampaign(candidateDetails)
      .then((response) => {
        enqueueSnackbar(t("candidates:candidateCreateSuccess"), {
          variant: "success",
        });
        dispatch({
          type: campaignActions.UPDATE_CAMPAIGN_CANDIDATES,
          payload: response.data,
        });
      })
      .catch(() => {
        enqueueSnackbar(t("candidates:candidateCreateError"), {
          variant: "error",
        });
      })
      .then(() => {
        handleExistingCandidateModalClose();
      });
  };

  const onInviteCandidateSuccess = (invitationDetails) => {
    return CampaignApi.sendCampaignCandidatesInvitations(
      {
        emailSubject: invitationDetails.emailSubject,
        emailContent: invitationDetails.emailContent,
        selectedCandidates: invitationDetails.selectedUsers,
      },
      consultancyId,
      campaign.id
    )
      .then((response) => {
        enqueueSnackbar(t("candidates:candidateInviteSuccess"), {
          variant: "success",
        });
        dispatch({
          type: campaignActions.UPDATE_CAMPAIGN_UNINVITED_CANDIDATES,
          uninvitedCandidates: response.data,
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("candidates:candidateInviteError"), {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Card>
        <AppBar
          position="static"
          color="transparent"
          className={classes.appBar}
        >
          <Tabs
            value={currentTab}
            onChange={handleChange}
            variant="scrollable"
            textColor="primary"
            scrollButtons="auto"
          >
            {tabs.map((tab) =>
              tab.value === candidateStatusIds.ALL ? (
                <Tab
                  key={tab.value}
                  label={`${t(
                    `campaigns:candidateStatus${tab.candidateStatusId}`
                  )} (${campaign.campaignCandidates.length}) `}
                  value={tab.value}
                />
              ) : (
                <Tab
                  key={tab.value}
                  label={`${t(
                    `campaigns:candidateStatus${tab.candidateStatusId}`
                  )} `}
                  value={tab.value}
                />
              )
            )}
          </Tabs>
        </AppBar>
        <CardContent className={classes.cardContent}>
          {tabs.map((tab) => (
            <TabPanel value={currentTab} index={tab.value}>
              {tab.candidateStatusId === candidateStatusIds.ALL ? (
                <>
                  {campaign.campaignCandidates
                    .sort((a, b) => {
                      let nameA =
                        `${a?.lastName?.toUpperCase()} ${a?.firstName?.toUpperCase()}` ||
                        "";
                      let nameB =
                        `${b?.lastName?.toUpperCase()} ${b?.firstName?.toUpperCase()}` ||
                        "";

                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      return 0;
                    })
                    ?.map((campaignCandidate) => (
                      <CandidateListCard
                        candidate={campaignCandidate}
                        consultancyId={consultancyId}
                        campaignId={rest?.campaignId}
                        guestView={rest?.guestView}
                      />
                    ))}
                </>
              ) : (
                <>
                  {campaign.campaignCandidates
                    .sort((a, b) => {
                      let nameA =
                        `${a?.lastName?.toUpperCase()} ${a?.firstName?.toUpperCase()}` ||
                        "";
                      let nameB =
                        `${b?.lastName?.toUpperCase()} ${b?.firstName?.toUpperCase()}` ||
                        "";

                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      return 0;
                    })
                    ?.filter(
                      (campaignCandidate) =>
                        campaignCandidate.status === tab.candidateStatusId
                    )
                    .map((campaignCandidate) => (
                      <CandidateListCard
                        candidate={campaignCandidate}
                        consultancyId={consultancyId}
                        campaignId={rest?.campaignId}
                        guestView={rest?.guestView}
                      />
                    ))}
                </>
              )}
            </TabPanel>
          ))}
        </CardContent>
        {!rest?.guestView && (
          <CardActions>
            <div style={{ flexGrow: 1 }} />
            <Button
              className={classes.button}
              color="primary"
              type="submit"
              onClick={handleExistingCandidateModalOpen}
            >
              {t("campaigns:addExistingCandidates")}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              type="submit"
              onClick={handleNewCandidateModalOpen}
            >
              {t("campaigns:createNewCandidate")}
            </Button>
            {campaign.uninvitedCandidates?.length > 0 ? (
              <Button
                className={classes.button}
                color="primary"
                type="submit"
                onClick={handleInviteCandidateModalOpen}
              >
                {t("candidates:inviteCandidates")}
              </Button>
            ) : null}
          </CardActions>
        )}
      </Card>
      <AddExistingCandidateModal
        onSuccess={onExistingCandidateSuccess}
        consultancyId={consultancyId}
        open={existingCandidateModalOpen}
        handleClose={handleExistingCandidateModalClose}
      />
      <CreateCandidateModel
        onSuccess={onNewCandidateSuccess}
        consultancyId={consultancyId}
        open={newCandidateModalOpen}
        handleClose={handleNewCandidateModalClose}
      />
      <InviteUsersModal
        onSuccess={onInviteCandidateSuccess}
        uninvitedUsers={campaign.uninvitedCandidates}
        emailContent={campaign.candidateInvitationEmail}
        emailSubject={campaign.candidateInvitationEmailSubject}
        emailTags={campaign.candidateInvitationEmailTags}
        open={inviteCandidateModalOpen}
        title={t(`${candidatesPrefix}:inviteCandidatesToCampaignLabel`)}
        buttonText={t(`${campaignsPrefix}:inviteToCampaign`)}
        handleClose={handleInviteCandidateModalClose}
      />
    </>
  );
}

export default CandidateStageView;
