import React, { useState } from "react";
import { Card, Divider, Grid, Switch, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AnalyticsCard from "./AnalyticsCard";
import CampaignsPerMonth from "./CampaignsPerMonth";
import Header from "./Header";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

import { useSnackbar } from 'notistack';

import * as consultancyApi from './../../../../api/consultancyApi';

const useStyles = makeStyles(() => ({
  card: {
    width: '33%',
    height: '160px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px'
  }
}));

function Dashboard({
  consultancyId,
  analyticsData,
  employersAndCandidatesData,
  campaignsPerMonth,
  campaignsCompleted,
  activeConsultancies,
  consultanciesTree,
  excludeFromActivityTracking
}) {

  const classes = useStyles();
  const [t] = useTranslation(["common", "tooltips"]);
  const { enqueueSnackbar } = useSnackbar();

  const dateFrom = useSelector((state) => state.analytics.dateFrom);
  const dateTo = useSelector((state) => state.analytics.dateTo);

  const [isExcludedFromActivityTracking, setIsExcludedFromActivityTracking] = useState(excludeFromActivityTracking);

  const displayDateA = new Date(dateFrom).toLocaleDateString("en-gb");
  const displayDateB = new Date(dateTo).toLocaleDateString("en-gb");

  const showConsultanciesFilter = consultanciesTree.length > 1;

  const excludeFromActivityTrackingHandler = () => {
    setIsExcludedFromActivityTracking(!isExcludedFromActivityTracking);

    consultancyApi.toggleExcludeFromActivityTracking(consultancyId)
      .then(() => {
        if (!isExcludedFromActivityTracking) {
          enqueueSnackbar(t('common:consultancyAnalyticsSettingsNotificationsExcludeActivityTrackingIncludeSuccess'), { variant: 'success' });
          return;
        }

        enqueueSnackbar(t('common:consultancyAnalyticsSettingsNotificationsExcludeActivityTrackingExcludeSuccess'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('common:consultancyAnalyticsSettingsNotificationsExcludeActivityTrackingError'), { variant: 'error' });
      });
  };

  return (
    <Grid container spacing={3}>
      <Header 
        defeatMargin
        dateSelect={false}
        activeConsultancies={activeConsultancies}
        consultanciesTree={consultanciesTree}
				consultancySelect={showConsultanciesFilter}
        headerAccent={false}
      />

      <Grid item sm={ 12 } lg={ 12 }>
        <BlockHeader
          headerTooltip={ t("tooltips:consultancyAnalyticsSettingsTooltip") }
          fullWidth
          reduceMargin
          header={ t("common:settings") }
        />
      </Grid>

      <Grid item sm={ 12 } lg={ 12 } >
        <Card className={ classes.card } >

          <Typography component="h3" variant="overline" color="textSecondary" >
            { t("common:consultancyAnalyticsSettingsExcludeActivityTracking") }
          </Typography>

						<Switch
              color="primary"
							checked={ isExcludedFromActivityTracking }
							onChange={ excludeFromActivityTrackingHandler } />
        </Card>
      </Grid>

      <Grid item sm={ 12 } lg={ 12 }>
        <Divider />
      </Grid>

      <Grid item sm={12} lg={12}>
        <BlockHeader
          headerTooltip={t("tooltips:consultancyUsers")}
          fullWidth
          reduceMargin
          header={t("common:consultancyTotalCustomersLabel")}
        />
      </Grid>
      {employersAndCandidatesData &&
        employersAndCandidatesData.map((element, key) => (
          <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
            <AnalyticsCard data={element} />
          </Grid>
        ))}
      <Grid item sm={12} lg={12}>
        <Divider />
      </Grid>
      <Grid item sm={12} lg={12}>
        <Header 
          defeatMargin
          dateSelect={true}
					consultancySelect={false}
          title={`${t(
            "common:analyticsforSelectedDateRangeLabel"
          )} (${displayDateA} - ${displayDateB})`}
          elementType='h3'
          headerAccent={true}
        />
      </Grid>
      <Grid item sm={12} lg={12}>
        <Header
          dateSelect={false}
          title={`${t("common:completionAnalyticsLabel")}`}
          elementType='h5'
          headerAccent={false}
        />
      </Grid>
      {campaignsCompleted &&
        campaignsCompleted.map((element, key) => (
          <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
            <AnalyticsCard data={element} />
          </Grid>
        ))}
      <Grid item sm={12} lg={12}>
        <Header
          dateSelect={false}
          title={`${t("common:campaignsStartedLabel")}`}
          elementType='h5'
          headerAccent={false}
        />
      </Grid>
      {analyticsData &&
        analyticsData.map((element, key) => (
          <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
            <AnalyticsCard data={element} />
          </Grid>
        ))}
      <Grid item lg={12} xs={12}>
        <CampaignsPerMonth campaignsPerMonth={campaignsPerMonth} />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
