import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Typography,
  Checkbox,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
  questionSet: {
    padding: "10px",
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  question: {
    display: "flex",
    alignItems: "center",
  },
  noPadding: {
    width: "100%",
    paddingLeft: 0,
    paddingRight: 0,
    "& > *": {
      marginBottom: theme.spacing(1),
    },
  },
}));

function SetsKCQSelect({
  personalQuestionSets,
  parentPropStateChange,
  padding,
}) {
  const classes = useStyles();
  const [checkboxes, setCheckboxes] = useState({});

  const triggerCheckbox = function (setIndex, catIndex, queIndex) {
    if (checkboxes[setIndex] === undefined) {
      checkboxes[setIndex] = {};
      setCheckboxes(checkboxes);
    }
    if (checkboxes[setIndex][catIndex] === undefined) {
      checkboxes[setIndex][catIndex] = {};
    }
    if (checkboxes[setIndex][catIndex][queIndex] === undefined) {
      checkboxes[setIndex][catIndex][queIndex] = true;
      setCheckboxes(checkboxes);
    } else {
      checkboxes[setIndex][catIndex][queIndex] = !checkboxes[setIndex][catIndex][queIndex];
      setCheckboxes(checkboxes);
    }

    parentPropStateChange(checkboxes);
  };

  const renderDefaultKCQCategories = () => {
    return (
      <div className={!padding ? classes.noPadding : classes.root}>
        <Grid item xs={12}>
          
          {personalQuestionSets?.map((questionSet, index) => (
            <ExpansionPanel
              className={classes.questionSet}
              key={"questionSets" + index}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h5" align="center">
                  
                  {questionSet.name}
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid xs={12}>
                  <Grid container style={{ marginBottom: "20px" }}></Grid>
                  <Grid xs={12}>
                    
                    {questionSet.questions?.map((category, catIndex) => (
                      <>
                        <ExpansionPanel
                          key={"questionSets" + index + "cat" + catIndex}
                        >
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                          >
                            <Typography variant="h5" align="center">
                              
                              {category.name}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <Grid className={classes.questionGrid} item xs={12}>
                              {category.questions?.map(
                                (question, questionIndex) => {
                                  return (
                                    <Grid className={classes.row}>
                                      

                                      <Checkbox
                                        onChange={(e) => {
                                          triggerCheckbox(
                                            index,
                                            catIndex,
                                            questionIndex
                                          );

                                        }}
                                        name={category.name}

                                      >
                                        
                                      </Checkbox>
                                      <Typography
                                        className={classes.question}
                                        variant="h5"
                                      >
                                        {question}
                                      </Typography>
                                    </Grid>
                                  );
                                }
                              )}
                            </Grid>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Grid>
      </div>
    );
  };
  return <> {renderDefaultKCQCategories()} </>;
}
export default SetsKCQSelect;
