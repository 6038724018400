import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Collapse,
	Dialog,
	Divider,
	Grid,
	Slide,
	Switch,
	TextField,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import * as api from "../../../api/landingApi";
import { useSnackbar } from "notistack";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ContactForm from "src/views/LandingPage/ContactForm";

const ManageContactForm = ({ landingPageData }) => {
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const [expand, setExpand] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [contactData, setContactData] = useState({});
	const [showPreview, setShowPreview] = useState(false);

	const currentData = landingPageData.contact[0];
	const consultancyId = landingPageData.consultancyId;

	const exampleData = {
		landing: {
			contact: [contactData],
			block_header_colour: theme.palette.primary.main,
			button_colour: theme.palette.primary.main,
		},
	};

	useEffect(() => {
		if (landingPageData) {
			setContactData(currentData);
			setEnabled(landingPageData.display_contact);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentData]);

	const toggleContentState = (fieldName) => {
		setEnabled(!enabled);
		return api
			.toggleDisplayContent(consultancyId, fieldName)
			.then((response) => {
				if (response.status === 200 && !enabled) {
					enqueueSnackbar("Form enabled", {
						variant: "success",
					});
				} else if (response.status === 200 && enabled) {
					enqueueSnackbar("Form disabled", {
						variant: "info",
					});
				}
			})
			.catch(() => {
				setEnabled(enabled);
				enqueueSnackbar("Could not be enabled at this time", {
					variant: "error",
				});
			});
	};

	const handleContentChange = ({ target }) => {
		const { name, value } = target;
		setContactData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const applyChanges = () => {
		return api
			.updateContentBlock(consultancyId, "contact", contactData)
			.then((response) => {
				if (response) {
					enqueueSnackbar("Content updated", { variant: "success" });
				}
			})
			.catch(() => {
				enqueueSnackbar("Unable to apply changes", { variant: "error" });
			});
	};

	return (
		<>
			<Grid container style={{ paddingTop: ".4rem" }}>
				<Grid item xs={6}>
					<Button
						variant="text"
						endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						onClick={() => {
							setExpand(!expand);
						}}
					>
						Contact Form
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Slide in={expand} direction="left">
						<Box display="flex" flexDirection="row-reverse">
							<Switch
								checked={enabled}
								color="primary"
								style={{ marginLeft: "auto" }}
								onChange={() => toggleContentState("display_contact")}
							/>
							<Button
								variant="text"
								onClick={() => toggleContentState("display_contact")}
							>
								Enable Content Block
							</Button>
						</Box>
					</Slide>
				</Grid>
			</Grid>
			<Collapse in={expand}>
				<ButtonWrapper
					btnLabel="Preview"
					btnFunc={() => {
						setShowPreview(true);
					}}
				/>
				<Grid container>
					<Grid item xs={12} lg={6}>
						<Box display="flex" width="90%">
							<TextField
								name="message"
								variant="outlined"
								placeholder="Custom message"
								value={contactData?.message || ""}
								onChange={handleContentChange}
								style={{
									marginTop: ".25rem",
									backgroundColor: "white",
									width: "100%",
								}}
							/>
						</Box>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Box display="flex" width="90%">
							<TextField
								name="telephone"
								variant="outlined"
								placeholder="Phone Number"
								value={contactData?.telephone || ""}
								onChange={handleContentChange}
								style={{
									marginTop: ".25rem",
									backgroundColor: "white",
									width: "100%",
								}}
							/>
						</Box>
					</Grid>
				</Grid>
				<ButtonWrapper btnLabel="Apply Changes" btnFunc={applyChanges} />
			</Collapse>
			<Divider />
			<Dialog
				fullWidth
				maxWidth="xl"
				open={showPreview}
				onClose={() => setShowPreview(false)}
			>
				<Box display="flex" flexDirection="row-reverse">
					<Button
						variant="text"
						onClick={() => {
							setShowPreview(false);
						}}
					>
						Close
					</Button>
				</Box>
				<ContactForm consultancyDetails={exampleData} />
			</Dialog>
		</>
	);
};

export default ManageContactForm;
