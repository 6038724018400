import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Paper } from "@material-ui/core";
import TimezoneSelect from "react-timezone-select";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
	root: {},
	action: {
		marginBottom: theme.spacing(1),
		"& + &": {
			marginLeft: theme.spacing(1),
		},
	},
	actionIcon: {
		marginRight: theme.spacing(1),
	},
	timeZoneContainer: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		"& div": {
			zIndex: "10",
			maxWidth: 600,
		},
	},
}));

function Header({
	onEventAdd,
	onEventAddMultiple,
	selectedTimezone,
	setSelectedTimezone,
	...rest
}) {
	const classes = useStyles();
	const [t] = useTranslation(["campaigns"]);

	return (
		<Paper style={{ marginBottom: 32, width: "100%", maxWidth: 632 }}>
			<div style={{ margin: 16 }}>
				<BlockHeader card defeatMargin header={t("campaigns:selectTimeZone")} />
			</div>
			<div className={classes.timeZoneContainer}>
				<TimezoneSelect
					value={selectedTimezone}
					onChange={(tz) => setSelectedTimezone(tz)}
				/>
			</div>
		</Paper>
	);
}

Header.propTypes = {
	className: PropTypes.string,
	onEventAdd: PropTypes.func,
};

Header.defaultProps = {
	onEventAdd: () => {},
};

export default Header;
