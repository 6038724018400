import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Menu,
  MenuItem,
  Typography,
  CardContent,
  CircularProgress,
  Card,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@material-ui/icons/Delete";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";

const useStyles = makeStyles((theme) => ({
  roor: {
    paddingBottom: 0,
  },
  contactWrapper: {
    marginTop: 8,
    backgroundColor: "#f5f5f5",
    height: "auto",
    padding: 0,
    paddingLeft: 8,
  },
  popover: {
    width: 200,
  },
  contactContent: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    paddingBottom: "0 !important",
  },
  contactText: {
    flex: 2,
    minWidth: 60,
    textAlign: "left",
  },
  progressIndicator: {
    marginLeft: 5,
  },
}));

function ContactListCard({ campaignContact, onRemove, guestView }) {
  const classes = useStyles();
  const [removeLoading, setRemoveLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const [t] = useTranslation(["common", "validation", "campaigns"]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    setRemoveLoading(true);
    onRemove(campaignContact.id)
      .then(() => setOpen(false))
      .catch()
      .then(() => setRemoveLoading(false));
  };

  return (
    <Card className={classes.contactWrapper} variant="outlined" elevation={0}>
      <CardContent className={classes.contactContent}>
        <Typography
          className={classes.contactText}
          style={guestView ? { marginTop: 8 } : null}
        >
          {campaignContact.jobTitle &&
          campaignContact.firstName &&
          campaignContact.lastName
            ? `${campaignContact.firstName} ${campaignContact.lastName} ${campaignContact.jobTitle}`
            : `${campaignContact.email}`}
        </Typography>
        {!guestView && (
          <>
            <div ref={ref} style={{ marginTop: 0, marginBottom: "auto" }}>
              <ThemeIconButton
                icon={<DeleteIcon />}
                tooltip={t("campaigns:removeContact")}
                btnFunc={handleOpen}
                loading={removeLoading}
              />
            </div>
            <Menu
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              keepMounted
              PaperProps={{ className: classes.popover }}
              getContentAnchorEl={null}
              anchorEl={ref.current}
              open={open}
            >
              <MenuItem onClick={handleRemove}>
                {t("campaigns:removeContactLabel")}
                {removeLoading ? (
                  <CircularProgress
                    className={classes.progressIndicator}
                    size={15}
                  />
                ) : (
                  ""
                )}
              </MenuItem>
            </Menu>
          </>
        )}
      </CardContent>
    </Card>
  );
}
export default ContactListCard;
