import countries_de from "./de/Countries.json";
import common_de from "./de/Common.json";
import login_de from "./de/Login.json";
import validation_de from "./de/Validation.json";
import user_de from "./de/Users.json";
import snackbar_de from "./de/snackbar.json";
import groups_de from "./de/Groups.json";
import candidates_de from "./de/Candidates.json";
import emails_de from "./de/Emails.json";
import languages_de from "./de/Languages.json";
import campaigns_de from "./de/Campaigns.json";
import storage_de from "./de/Storage.json";
import settings_de from "./de/Settings.json";
import dwi_de from "./de/Dwi.json";
import sysadmin_de from "./de/SysAdmin.json";
import consultancy_de from "./de/Consultancy.json";
import launchpad_de from "./de/Launchpad.json";
import notifications_de from "./de/Notifications.json";
import modules_de from "./de/Modules.json";
import labels_de from "./de/Labels.json";
import campaignCandidates_de from "./de/CampaignCandidates.json";
import tooltips_de from "./de/Tooltips.json";
import new_translations_de from "./de/NewTranslations.json";

import countries_en_gb from "./en_gb/Countries.json";
import common_en_gb from "./en_gb/Common.json";
import login_en_gb from "./en_gb/Login.json";
import validation_en_gb from "./en_gb/Validation.json";
import user_en_gb from "./en_gb/Users.json";
import snackbar_en_gb from "./en_gb/snackbar.json";
import groups_en_gb from "./en_gb/Groups.json";
import candidates_en_gb from "./en_gb/Candidates.json";
import vacancyCandidates_en_gb from './en_gb/VacancyCandidates.json';
import emails_en_gb from "./en_gb/Emails.json";
import languages_en_gb from "./en_gb/Languages.json";
import campaigns_en_gb from "./en_gb/Campaigns.json";
import vacancies_en_gb from "./en_gb/Vacancies.json";
import storage_en_gb from "./en_gb/Storage.json";
import settings_en_gb from "./en_gb/Settings.json";
import dwi_en_gb from "./en_gb/Dwi.json";
import sysadmin_en_gb from "./en_gb/SysAdmin.json";
import consultancy_en_gb from "./en_gb/Consultancy.json";
import launchpad_en_gb from "./en_gb/Launchpad.json";
import notifications_en_gb from "./en_gb/Notifications.json";
import vacancyNotifications_en_gb from "./en_gb/VacancyNotifications.json";
import modules_en_gb from "./en_gb/Modules.json";
import labels_en_gb from "./en_gb/Labels.json";
import campaignCandidates_en_gb from "./en_gb/CampaignCandidates.json";
import tooltips_en_gb from "./en_gb/Tooltips.json";
import new_translations_en_gb from "./en_gb/NewTranslations.json";
import cloudinary_en_gb from "./en_gb/NewTranslationsCloudinary.json";
import shareWidget_en_gb from "./en_gb/NewTranslationsShareWidget.json";
import new_translations_launchpad_en_gb from "./en_gb/NewTranslationsLaunchpad.json";
import new_translations_changePassword_en_gb from "./en_gb/NewTranslationsChangePassword.json";
import new_translations_changeEmail_en_gb from "./en_gb/NewTranslationsChangeEmail.json";
import video_assessment_en_gb from './en_gb/VideoAssessment.json';

import countries_en_us from "./en_us/Countries.json";
import common_en_us from "./en_us/Common.json";
import login_en_us from "./en_us/Login.json";
import validation_en_us from "./en_us/Validation.json";
import user_en_us from "./en_us/Users.json";
import snackbar_en_us from "./en_us/snackbar.json";
import groups_en_us from "./en_us/Groups.json";
import candidates_en_us from "./en_us/Candidates.json";
import emails_en_us from "./en_us/Emails.json";
import languages_en_us from "./en_us/Languages.json";
import campaigns_en_us from "./en_us/Campaigns.json";
import storage_en_us from "./en_us/Storage.json";
import settings_en_us from "./en_us/Settings.json";
import dwi_en_us from "./en_us/Dwi.json";
import sysadmin_en_us from "./en_us/SysAdmin.json";
import consultancy_en_us from "./en_us/Consultancy.json";
import launchpad_en_us from "./en_us/Launchpad.json";
import notifications_en_us from "./en_us/Notifications.json";
import modules_en_us from "./en_us/Modules.json";
import labels_en_us from "./en_us/Labels.json";
import campaignCandidates_en_us from "./en_us/CampaignCandidates.json";
import tooltips_en_us from "./en_us/Tooltips.json";
import new_translations_en_us from "./en_us/NewTranslations.json";
import video_assessment_en_us from './en_us/VideoAssessment.json';

import countries_cs from "./cs/Countries.json";
import common_cs from "./cs/Common.json";
import login_cs from "./cs/Login.json";
import validation_cs from "./cs/Validation.json";
import user_cs from "./cs/Users.json";
import snackbar_cs from "./cs/snackbar.json";
import groups_cs from "./cs/Groups.json";
import candidates_cs from "./cs/Candidates.json";
import emails_cs from "./cs/Emails.json";
import languages_cs from "./cs/Languages.json";
import campaigns_cs from "./cs/Campaigns.json";
import storage_cs from "./cs/Storage.json";
import settings_cs from "./cs/Settings.json";
import dwi_cs from "./cs/Dwi.json";
import sysadmin_cs from "./cs/SysAdmin.json";
import consultancy_cs from "./cs/Consultancy.json";
import launchpad_cs from "./cs/Launchpad.json";
import notifications_cs from "./cs/Notifications.json";
import modules_cs from "./cs/Modules.json";
import labels_cs from "./cs/Labels.json";
import campaignCandidates_cs from "./cs/CampaignCandidates.json";
import tooltips_cs from "./cs/Tooltips.json";
import new_translations_cs from "./cs/NewTranslations.json";

import countries_fr from "./fr/Countries.json";
import common_fr from "./fr/Common.json";
import login_fr from "./fr/Login.json";
import validation_fr from "./fr/Validation.json";
import user_fr from "./fr/Users.json";
import snackbar_fr from "./fr/snackbar.json";
import groups_fr from "./fr/Groups.json";
import candidates_fr from "./fr/Candidates.json";
import emails_fr from "./fr/Emails.json";
import languages_fr from "./fr/Languages.json";
import campaigns_fr from "./fr/Campaigns.json";
import storage_fr from "./fr/Storage.json";
import settings_fr from "./fr/Settings.json";
import dwi_fr from "./fr/Dwi.json";
import sysadmin_fr from "./fr/SysAdmin.json";
import consultancy_fr from "./fr/Consultancy.json";
import launchpad_fr from "./fr/Launchpad.json";
import notifications_fr from "./fr/Notifications.json";
import modules_fr from "./fr/Modules.json";
import labels_fr from "./fr/Labels.json";
import campaignCandidates_fr from "./fr/CampaignCandidates.json";
import tooltips_fr from "./fr/Tooltips.json";
import new_translations_fr from "./fr/NewTranslations.json";

import countries_es from "./es/Countries.json";
import common_es from "./es/Common.json";
import login_es from "./es/Login.json";
import validation_es from "./es/Validation.json";
import user_es from "./es/Users.json";
import snackbar_es from "./es/snackbar.json";
import groups_es from "./es/Groups.json";
import candidates_es from "./es/Candidates.json";
import emails_es from "./es/Emails.json";
import languages_es from "./es/Languages.json";
import campaigns_es from "./es/Campaigns.json";
import storage_es from "./es/Storage.json";
import settings_es from "./es/Settings.json";
import dwi_es from "./es/Dwi.json";
import sysadmin_es from "./es/SysAdmin.json";
import consultancy_es from "./es/Consultancy.json";
import launchpad_es from "./es/Launchpad.json";
import notifications_es from "./es/Notifications.json";
import modules_es from "./es/Modules.json";
import labels_es from "./es/Labels.json";
import campaignCandidates_es from "./es/CampaignCandidates.json";
import tooltips_es from "./es/Tooltips.json";
import new_translations_es from "./es/NewTranslations.json";

import countries_nl from "./nl/Countries.json";
import common_nl from "./nl/Common.json";
import login_nl from "./nl/Login.json";
import validation_nl from "./nl/Validation.json";
import user_nl from "./nl/Users.json";
import snackbar_nl from "./nl/snackbar.json";
import groups_nl from "./nl/Groups.json";
import candidates_nl from "./nl/Candidates.json";
import emails_nl from "./nl/Emails.json";
import languages_nl from "./nl/Languages.json";
import campaigns_nl from "./nl/Campaigns.json";
import storage_nl from "./nl/Storage.json";
import settings_nl from "./nl/Settings.json";
import dwi_nl from "./nl/Dwi.json";
import sysadmin_nl from "./nl/SysAdmin.json";
import consultancy_nl from "./nl/Consultancy.json";
import launchpad_nl from "./nl/Launchpad.json";
import notifications_nl from "./nl/Notifications.json";
import modules_nl from "./nl/Modules.json";
import labels_nl from "./nl/Labels.json";
import campaignCandidates_nl from "./nl/CampaignCandidates.json";
import tooltips_nl from "./nl/Tooltips.json";
import new_translations_nl from "./nl/NewTranslations.json";

import countries_ro from "./ro/Countries.json";
import common_ro from "./ro/Common.json";
import login_ro from "./ro/Login.json";
import validation_ro from "./ro/Validation.json";
import user_ro from "./ro/Users.json";
import snackbar_ro from "./ro/snackbar.json";
import groups_ro from "./ro/Groups.json";
import candidates_ro from "./ro/Candidates.json";
import emails_ro from "./ro/Emails.json";
import languages_ro from "./ro/Languages.json";
import campaigns_ro from "./ro/Campaigns.json";
import storage_ro from "./ro/Storage.json";
import settings_ro from "./ro/Settings.json";
import dwi_ro from "./ro/Dwi.json";
import sysadmin_ro from "./ro/SysAdmin.json";
import consultancy_ro from "./ro/Consultancy.json";
import launchpad_ro from "./ro/Launchpad.json";
import notifications_ro from "./ro/Notifications.json";
import modules_ro from "./ro/Modules.json";
import labels_ro from "./ro/Labels.json";
import campaignCandidates_ro from "./ro/CampaignCandidates.json";
import tooltips_ro from "./ro/Tooltips.json";
import new_translations_ro from "./ro/NewTranslations.json";

import countries_no from "./no/Countries.json";
import common_no from "./no/Common.json";
import login_no from "./no/Login.json";
import validation_no from "./no/Validation.json";
import user_no from "./no/Users.json";
import snackbar_no from "./no/snackbar.json";
import groups_no from "./no/Groups.json";
import candidates_no from "./no/Candidates.json";
import emails_no from "./no/Emails.json";
import languages_no from "./no/Languages.json";
import campaigns_no from "./no/Campaigns.json";
import storage_no from "./no/Storage.json";
import settings_no from "./no/Settings.json";
import dwi_no from "./no/Dwi.json";
import sysadmin_no from "./no/SysAdmin.json";
import consultancy_no from "./no/Consultancy.json";
import launchpad_no from "./no/Launchpad.json";
import notifications_no from "./no/Notifications.json";
import modules_no from "./no/Modules.json";
import labels_no from "./no/Labels.json";
import campaignCandidates_no from "./no/CampaignCandidates.json";
import tooltips_no from "./no/Tooltips.json";
import new_translations_no from "./no/NewTranslations.json";
import cloudinary_no from "./no/NewTranslationsCloudinary.json";
import shareWidget_no from "./no/NewTranslationsShareWidget.json";
import new_translations_launchpad_no from "./no/NewTranslationsLaunchpad.json";
import new_translations_changePassword_no from "./no/NewTranslationsChangePassword.json";
import new_translations_changeEmail_no from "./no/NewTranslationsChangeEmail.json";
import video_assessment_no from './no/VideoAssessment.json';

export default {
  en_gb: {
    campaigns: campaigns_en_gb,
    vacancies: vacancies_en_gb,
    candidates: candidates_en_gb,
    vacancyCandidates: vacancyCandidates_en_gb,
    common: common_en_gb,
    countries: countries_en_gb,
    login: login_en_gb,
    validation: validation_en_gb,
    users: user_en_gb,
    snackbar: snackbar_en_gb,
    groups: groups_en_gb,
    emails: emails_en_gb,
    languages: languages_en_gb,
    storage: storage_en_gb,
    settings: settings_en_gb,
    dwi: dwi_en_gb,
    sysadmin: sysadmin_en_gb,
    consultancy: consultancy_en_gb,
    launchpad: launchpad_en_gb,
    notifications: notifications_en_gb,
    vacancyNotifications: vacancyNotifications_en_gb,
    modules: modules_en_gb,
    labels: labels_en_gb,
    campaignCandidates: campaignCandidates_en_gb,
    tooltips: tooltips_en_gb,
    newTranslations: new_translations_en_gb,
    cloudinary: cloudinary_en_gb,
    shareWidget: shareWidget_en_gb,
    newTranslationsLaunchpad: new_translations_launchpad_en_gb,
    changePassword: new_translations_changePassword_en_gb,
    changeEmail: new_translations_changeEmail_en_gb,
    videoAssessment: video_assessment_en_gb
  },
  en_us: {
    campaigns: campaigns_en_us,
    candidates: candidates_en_us,
    common: common_en_us,
    countries: countries_en_us,
    login: login_en_us,
    validation: validation_en_us,
    users: user_en_us,
    snackbar: snackbar_en_us,
    groups: groups_en_us,
    emails: emails_en_us,
    languages: languages_en_us,
    storage: storage_en_us,
    settings: settings_en_us,
    dwi: dwi_en_us,
    sysadmin: sysadmin_en_us,
    consultancy: consultancy_en_us,
    launchpad: launchpad_en_us,
    notifications: notifications_en_us,
    modules: modules_en_us,
    labels: labels_en_us,
    campaignCandidates: campaignCandidates_en_us,
    tooltips: tooltips_en_us,
    newTranslations: new_translations_en_us,
    videoAssessment: video_assessment_en_us
  },
  de: {
    campaigns: campaigns_de,
    candidates: candidates_de,
    common: common_de,
    countries: countries_de,
    login: login_de,
    validation: validation_de,
    users: user_de,
    snackbar: snackbar_de,
    groups: groups_de,
    emails: emails_de,
    languages: languages_de,
    storage: storage_de,
    settings: settings_de,
    dwi: dwi_de,
    sysadmin: sysadmin_de,
    consultancy: consultancy_de,
    launchpad: launchpad_de,
    notifications: notifications_de,
    modules: modules_de,
    labels: labels_de,
    campaignCandidates: campaignCandidates_de,
    tooltips: tooltips_de,
    newTranslations: new_translations_de,
  },
  fr: {
    campaigns: campaigns_fr,
    candidates: candidates_fr,
    common: common_fr,
    countries: countries_fr,
    login: login_fr,
    validation: validation_fr,
    users: user_fr,
    snackbar: snackbar_fr,
    groups: groups_fr,
    emails: emails_fr,
    languages: languages_fr,
    storage: storage_fr,
    settings: settings_fr,
    dwi: dwi_fr,
    sysadmin: sysadmin_fr,
    consultancy: consultancy_fr,
    launchpad: launchpad_fr,
    notifications: notifications_fr,
    modules: modules_fr,
    labels: labels_fr,
    campaignCandidates: campaignCandidates_fr,
    tooltips: tooltips_fr,
    newTranslations: new_translations_fr,
  },
  es: {
    campaigns: campaigns_es,
    candidates: candidates_es,
    common: common_es,
    countries: countries_es,
    login: login_es,
    validation: validation_es,
    users: user_es,
    snackbar: snackbar_es,
    groups: groups_es,
    emails: emails_es,
    languages: languages_es,
    storage: storage_es,
    settings: settings_es,
    dwi: dwi_es,
    sysadmin: sysadmin_es,
    consultancy: consultancy_es,
    launchpad: launchpad_es,
    notifications: notifications_es,
    modules: modules_es,
    labels: labels_es,
    campaignCandidates: campaignCandidates_es,
    tooltips: tooltips_es,
    newTranslations: new_translations_es,
  },
  cs: {
    campaigns: campaigns_cs,
    candidates: candidates_cs,
    common: common_cs,
    countries: countries_cs,
    login: login_cs,
    validation: validation_cs,
    users: user_cs,
    snackbar: snackbar_cs,
    groups: groups_cs,
    emails: emails_cs,
    languages: languages_cs,
    storage: storage_cs,
    settings: settings_cs,
    dwi: dwi_cs,
    sysadmin: sysadmin_cs,
    consultancy: consultancy_cs,
    launchpad: launchpad_cs,
    notifications: notifications_cs,
    modules: modules_cs,
    labels: labels_cs,
    campaignCandidates: campaignCandidates_cs,
    tooltips: tooltips_cs,
    newTranslations: new_translations_cs,
  },
  nl: {
    campaigns: campaigns_nl,
    candidates: candidates_nl,
    common: common_nl,
    countries: countries_nl,
    login: login_nl,
    validation: validation_nl,
    users: user_nl,
    snackbar: snackbar_nl,
    groups: groups_nl,
    emails: emails_nl,
    languages: languages_nl,
    storage: storage_nl,
    settings: settings_nl,
    dwi: dwi_nl,
    sysadmin: sysadmin_nl,
    consultancy: consultancy_nl,
    launchpad: launchpad_nl,
    notifications: notifications_nl,
    modules: modules_nl,
    labels: labels_nl,
    campaignCandidates: campaignCandidates_nl,
    tooltips: tooltips_nl,
    newTranslations: new_translations_nl,
  },
  ro: {
    campaigns: campaigns_ro,
    candidates: candidates_ro,
    common: common_ro,
    countries: countries_ro,
    login: login_ro,
    validation: validation_ro,
    users: user_ro,
    snackbar: snackbar_ro,
    groups: groups_ro,
    emails: emails_ro,
    languages: languages_ro,
    storage: storage_ro,
    settings: settings_ro,
    dwi: dwi_ro,
    sysadmin: sysadmin_ro,
    consultancy: consultancy_ro,
    launchpad: launchpad_ro,
    notifications: notifications_ro,
    modules: modules_ro,
    labels: labels_ro,
    campaignCandidates: campaignCandidates_ro,
    tooltips: tooltips_ro,
    newTranslations: new_translations_ro,
  },
  no: {
    campaigns: campaigns_no,
    candidates: candidates_no,
    common: common_no,
    countries: countries_no,
    login: login_no,
    validation: validation_no,
    users: user_no,
    snackbar: snackbar_no,
    groups: groups_no,
    emails: emails_no,
    languages: languages_no,
    storage: storage_no,
    settings: settings_no,
    dwi: dwi_no,
    sysadmin: sysadmin_no,
    consultancy: consultancy_no,
    launchpad: launchpad_no,
    notifications: notifications_no,
    modules: modules_no,
    labels: labels_no,
    campaignCandidates: campaignCandidates_no,
    tooltips: tooltips_no,
    newTranslations: new_translations_no,
    cloudinary: cloudinary_no,
    shareWidget: shareWidget_no,
    newTranslationsLaunchpad: new_translations_launchpad_no,
    changePassword: new_translations_changePassword_no,
    changeEmail: new_translations_changeEmail_no,
    videoAssessment: video_assessment_no
  },
};
