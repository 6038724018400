import React from "react";
import CKEditor from "ckeditor4-react";

function CustomHtmlEditor({
	value,
	handleChange,
	height,
	headerOnly,
	...rest
}) {
	return (
		<CKEditor
			type="classic"
			config={{
				height: height ? height : "300px",
				width: "100%",
				format_p: { element: "p", name: "Small" },
				format_h3: { element: "h3", name: "Normal" },
				format_tags: headerOnly ? "h1" : "h3;p",
				toolbar: headerOnly
					? [{ name: "basicstyles", items: ["Bold", "Italic", "Underline"] }]
					: [
							{ name: "styles", items: ["Format"] },
							{ name: "basicstyles", items: ["Italic", "Underline"] },
							{
								name: "paragraph",
								items: ["NumberedList", "BulletedList"],
							},
							{ name: "link", items: ["Link", "Unlink"] },
					  ],
			}}
			data={value}
			onChange={handleChange}
			readOnly={rest.disabled}
		/>
	);
}
export default CustomHtmlEditor;
