import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import * as ConsultancyApi from 'src/api/consultancyApi';
import GroupsContainer from './GroupsContainer';
import GroupsList from './GroupsList';

function Groups() {
  const [myGroups, setMyGroups] = useState([]);
  const [searchGroup, setSearchGroup] = useState();

  const consultancyId = useSelector((state) => state?.session?.consultancyId);

  const getMyGroups = (consultancyId) => {
    return ConsultancyApi.getMyGroups(consultancyId).then((response) => {
      setMyGroups(response.data);
    });
  };

  const handleGroupSearch = (e) => {
    setSearchGroup(e.target.value);
  };

  const filterBySearchCriteria = (array, search) =>
    search
      ? array.filter((group) =>
          group.groupName.toLowerCase().includes(search.toLowerCase())
        )
      : array;

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMyGroups(consultancyId);
    }
    return () => {
      mounted = false;
    };
  }, [consultancyId]);

  return myGroups.length ? (
    <Grid container spacing={3} alignContent='space-around'>
      <GroupsContainer handleSearch={handleGroupSearch}>
        <GroupsList
          groupArray={filterBySearchCriteria(myGroups, searchGroup)}
        />
      </GroupsContainer>
    </Grid>
  ) : null;
}

export default Groups;
