import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';
import { Button, CircularProgress, Grid } from '@material-ui/core';

import { ModalFormWrapper } from './../../../../components/ModalFormWrapper';
import QuillEditor from './../../../../components/QuillEditor';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%'
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  },
  buttonContainer: {
    textAlign: 'center'
  },
  resetButton: {
    marginBottom: theme.spacing(2),
    width: 'auto',
    alignSelf: 'right'
  },
  resetButtonWrapper: {
    textAlign: 'right'
  }
}));

const EditTermsAndConditionsModal = ({ handleClose, onSuccess, open, consultancyTC, campaignTC }) => {

  const classes = useStyles();
  const [t] = useTranslation(['campaigns', 'common']);

  const [loading, setLoading] = useState(false);
  const [newTermsAndConditions, setNewTermsAndConditions] = useState(campaignTC);

  const onSubmit = (event) => {
    event.preventDefault();

    setLoading(true);

    onSuccess(newTermsAndConditions);
  };

  const handleTermsAndConditionsChange = (event) => {
    setNewTermsAndConditions(event);
  };

  const resetToDefault = () => {
    setNewTermsAndConditions(consultancyTC);
  };

  return (
    <ModalFormWrapper
      formTitle={t("campaigns:updateTermsAndConditionsLabel")}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth="md" >
      <Grid className={classes.resetButtonWrapper}>
        <Button
          className={classes.resetButton}
          color="primary"
          size="small"
          variant="contained"
          onClick={resetToDefault} >
          { t("campaigns:resetToDefault") }
        </Button>
      </Grid>
      
      <form onSubmit={onSubmit}>
        <QuillEditor value={newTermsAndConditions} handleChange={handleTermsAndConditionsChange} toolbarOptions={["link"]} />
        
        <Grid className={classes.buttonContainer}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained" >
            { loading ? <CircularProgress className={classes.progressCircle} size={25} /> : t("common:update") }
          </Button>
        </Grid>
      </form>
    </ModalFormWrapper>
  );

};

export default EditTermsAndConditionsModal;
