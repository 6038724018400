import * as actionTypes from 'src/actions';

const initialState = {
  notificationSettings: [],
  typeSettings: [],
  emailSettings: [],
};

const notificationSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        notificationSettings: action.payload,
      };
    }

    case actionTypes.UPDATE_TYPE_SETTINGS: {
      return {
        ...state,
        typeSettings: action.payload,
      };
    }
    default: {
      return state;
    }

    case actionTypes.UPDATE_EMAIL_SETTINGS: {
      return {
        ...state,
        emailSettings: action.payload,
      };
    }
  }
};

export default notificationSettingsReducer;
