import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as EmployerApi from "src/api/employerApi";
import { useTranslation } from "react-i18next";

function EmployerSelect({ disabled, handleChange, value }) {
  const [t] = useTranslation(["common"]);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [employer, setEmployers] = useState([]);

  const getEmployers = function(consultancyId) {
    return EmployerApi.getEmployers(consultancyId)
      .then((response) => {
        setEmployers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getEmployers(consultancyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedEmployers =
    employer && employer.length
      ? employer.sort((a, b) => {
          var nameA = a.employerName.toUpperCase(); // ignore upper and lowercase
          var nameB = b.employerName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      : employer;

  return (
    <>
      <Autocomplete
        disabled={disabled}
        id="employerAutoComplete"
        options={sortedEmployers}
        getOptionLabel={(option) => option.employerName}
        value={value}
        onChange={handleChange}
        style={{ width: "100%" }}
        renderInput={(params) => {
          return (
            <TextField
              required
              {...params}
              fullWidth
              label={t(`common:selectEmployer`)}
              variant="outlined"
            />
          );
        }}
      />
    </>
  );
}
export default EmployerSelect;
