import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as modulesApi from "src/api/modulesApi";
import Page from "src/components/Page";
import { SET_DWI_CATEGORIES } from "src/actions/ConsultancyModules/dwi/dwiActions";
import CreateDWICategoryModal from "src/components/createDwiCategoryModal";
import EditDWICategoryModal from "src/components/editDwiCategoryModal";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  editButton: {
    paddingRight: "20px",
  },
  list: {
    padding: "0",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: "15px",
  },
  container: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  gridRow: {
    marginTop: "20px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

function DWIManagement({ consultancyId }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const consultancyCategories = useSelector((state) => state.dwi.dwiCategories);
  const [t] = useTranslation([
    "candidates",
    "common",
    "snackbar",
    "tooltips",
  ]);
  const dispatch = useDispatch();
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [editingCategory, setEditingCategory] = useState({});

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };
  const handleClose = () => {
    setOpenCreate(false);
    setOpenEdit(false);
  };

  const handleEditOpen = (event, category) => {
    event.preventDefault();
    setEditingCategory(category);
    setOpenEdit(true);
  };

  const deleteCategory = (event, categoryId) => {
    event.preventDefault();
    return modulesApi
      .deleteDWICategory(consultancyId, categoryId)
      .then((response) => {
        dispatch({ type: SET_DWI_CATEGORIES, categories: response.data });
        enqueueSnackbar(t("snackbar:dwiCategoryDeleteSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:dwiCategoryDeleteFailed"), {
          variant: "error",
        });
      });
  };

  const onEditCategorySuccess = (category) => {
    return modulesApi
      .updateDWICategory(consultancyId, category)
      .then((response) => {
        dispatch({ type: SET_DWI_CATEGORIES, categories: response.data });
        setOpenEdit(false);
        enqueueSnackbar(t("snackbar:dwiCategoryUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:dwiCategoryUpdateFailed"), {
          variant: "error",
        });
      });
  };

  const onCreateCategorySuccess = (newCategory) => {
    return modulesApi
      .createDWICategory(consultancyId, newCategory)
      .then((response) => {
        dispatch({ type: SET_DWI_CATEGORIES, categories: response.data });
        setOpenCreate(false);
        enqueueSnackbar(t("snackbar:dwiCategoryCreateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:dwiCategoryCreateFailed"), {
          variant: "error",
        });
      });
  };

  const getDWICategories = () => {
    return modulesApi.getDWICategories(consultancyId).then((response) => {
      dispatch({ type: SET_DWI_CATEGORIES, categories: response.data });
    });
  };

  useEffect(() => {
    getDWICategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Page className={classes.root} title={t("common:dwiSettingsTitle")}>
        <Grid container spacing={3} style={{ alignItems: "center" }}>
          <Grid item xs={12}>
            <BlockHeader
              headerTooltip={t("tooltips:manageDWICategories")}
              header={t("dwi:categoriesLabel")}
              btnLabel={t("common:createDWICategory")}
              btnFunc={handleOpenCreate}
              btnVariant="contained"
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {consultancyCategories?.map((category, index) => (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h5">{category.name}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={3}>
                    <Grid item lg={10} xs={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Typography variant="h5">
                            {t("dwi:subCategory")}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="h5">
                            {t("dwi:defaultWeighting")}
                          </Typography>
                        </Grid>
                        {category.subCategories?.map((subCategory) => (
                          <>
                            <Grid item xs={6}>
                              <Typography variant="h5">
                                {subCategory.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="h5">
                                {subCategory.weight}
                              </Typography>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid item lg={2} xs={12}>
                      <Button
                        color="primary"
                        size="medium"
                        onClick={(event) => handleEditOpen(event, category)}
                        type="submit"
                        variant="contained"
                        fullWidth
                      >
                        {t("common:edit")}
                      </Button>
                      {category.isDefault ? null : (
                        <Button
                          color="error"
                          size="medium"
                          style={{ marginTop: "20px" }}
                          onClick={(event) =>
                            deleteCategory(event, category.id)
                          }
                          type="submit"
                          variant="contained"
                          fullWidth
                        >
                          {t("common:delete")}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
          </Grid>
        </Grid>
      </Page>
      <CreateDWICategoryModal
        onSuccess={onCreateCategorySuccess}
        open={openCreate}
        handleClose={handleClose}
      />
      <EditDWICategoryModal
        open={openEdit}
        category={editingCategory}
        onSuccess={onEditCategorySuccess}
        handleClose={handleClose}
      />
    </>
  );
}

export default DWIManagement;
