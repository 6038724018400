import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Box,
  Grid,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import validate from "validate.js";
import * as CandidateApi from "src/api/candidateApi";
import { useSnackbar } from "notistack";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UPDATE_VIDEO_PROCCESSING,
  UPDATE_CANDIDATE_PROFILE_PICTURE,
} from "src/actions/candidateCampaignActions";
import { CANDIDATE } from "src/constants/roles";
import { NOT_SUBMITTED } from "src/constants/candidateStatusIds";
import RequiredCandidateDocuments from "src/views/Campaigns/CampaignDetail/CampaignDocuments/RequiredCandidateDocuments";

import VideoAssessment from './../../../components/VideoAssessment/VideoAssessment';
import VideoAssessmentReview from './../../../components/VideoAssessment/VideoAssessmentReview';
import VideoModal from "./VideoUploadModal";
import SubmissionConfirmationModal from "./SubmisionConfirmationModal";
import Swal from "sweetalert2";
import avatarPlaceholder from "src/assets/images/missing_avatar.svg";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import CloudinaryWidget from './../../../components/CloudinaryWidget';

import * as videoAssessmentApi from './../../../api/videoAssessmentApi';

import { useCampaignOrVacancyTranslationPrefix } from './../../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../../constants/subscriptionPlansIds';
import { VIDEO_INTRODUCTION_ID } from './../../../constants/moduleIds';

//TODO:Translate this page

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
  category: {
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  question: {
    backgroundColor: "#f5f5f5",
    marginTop: 8,
    marginBottom: 8,
    padding: 8,
  },
  updateButton: {
    textAlign: "center",
    paddingTop: "20px",
  },
  cardTitle: {
    marginBottom: "20px",
  },
  videoIcon: {
    fontSize: "40px",
    width: "100%",
  },
  kcqSubtitle: {
    marginBottom: "20px",
  },
  questionsAndAnswers: {
    padding: "10px 5px",
  },
  categoryWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 2,
    padding: "10px 5px",
    marginTop: "20px",
  },
  viewVideoBtn: {
    marginTop: '8px'
  },
}));

const schema = {
  candidateStatus: {
    presence: { allowEmpty: false, message: "is required" },
  },
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  postalCode: {
    presence: { allowEmpty: false, message: "is required" },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

export default function({ getCandidateCampaignDetails }) {
  const classes = useStyles();
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const [t] = useTranslation([
    "v",
    "validation",
    "snackbar",
    "campaigns",
    "vacancies",
    "campaignCandidates",
    "labels",
    "tooltips",
    "newTranslations",
  ]);
  const profileData = useSelector(
    (state) => state.candidateCampaign.candidateProfile
  );
  const candidateCampaign = useSelector((state) => state.candidateCampaign);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { consultancyId, consultancySubscriptionPlanId } = useSelector((state) => state.session);
  const readOnly =
    profileData?.candidateStatus === NOT_SUBMITTED ? false : true;

  const shouldHideRequiredCandidateDocuments = useSelector(state => {
    return state.session.roleType === CANDIDATE && !candidateCampaign.campaignRequiredDocuments.length;
  });

  const [modal, setModal] = useState(false);
  const theme = useTheme();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...profileData,
      questions: candidateCampaign?.campaignKeyCompetencyQuestions?.questions,
    },
    touched: {},
    errors: {},
  });

  const [candidateProfileVideo, setCandidateProfileVideo] = useState(formState.values.candidateProfileVideo ? formState.values.candidateProfileVideo : null);
  const [videoAssessment, setVideoAssessment] = useState();
  const [videoAssessmentInteview, setVideoAssessmentInterview] = useState();
  const [isVideoAssessmentOpen, setIsVideoAssessmentOpen] = useState(false);

  useEffect(() => {
    getVideoAssessment(profileData.campaignId, candidateCampaign.candidateProfile.candidateId);
  }, [profileData.campaignId, candidateCampaign.candidateProfile.candidateId]);

  const getVideoAssessment = (campaignId, candidateId) => {
    videoAssessmentApi.getVideoAssessmentByCampaignId(campaignId)
      .then((response) => {
        setVideoAssessment(response.data);
        return videoAssessmentApi.getVideoAssessmentInterviewByCandidate(candidateId, response.data.id);
      })
      .then((response) => {
        setVideoAssessmentInterview(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleModalOpen = () => setModal(true);
  const handleModalClose = () => setModal(false);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleAnswerChange = (event, categoryIndex, questionIndex) => {
    event.persist();
    let tempCategories = [...formState.values.questions];
    tempCategories[categoryIndex].questions[questionIndex].answer =
      event.target.value;
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questions: [...tempCategories],
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const updateCandidateKCQAnswers = () => {
    return CandidateApi.updateCandidateKCQAnswers(
      consultancyId,
      profileData.candidateId,
      profileData.campaignId,
      formState.values.questions
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:keyCompetencyAnswersUpdated"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:failedToUpdateKeyCompetencyAnswers"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const submitProfile = (event) => {
    event.preventDefault();

    Swal.fire({
      title: `${t("common:areYouSure")}`,
      text: `${t(
        "common:youAreSubmittingYourProfileYouWillNoBeAbleToChangeThis"
      )}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("common:yes")}`,
      cancelButtonText: `${t("common:no")}`,
      confirmButtonColor: theme.palette.warning?.main
        ? theme.palette.warning.main
        : theme.palette.primary.main,
    }).then((result) => {
      if (result.isConfirmed) {
        return CandidateApi.submitProfile(
          consultancyId,
          profileData.candidateId,
          profileData.campaignId
        )
          .then((response) => {
            // dispatch({
            //   type: UPDATE_CANDIDATE_PROFILE,
            //   candidateProfile: response.data,
            // });
            getCandidateCampaignDetails(profileData.campaignId, consultancyId);
            if (
              roleType === CANDIDATE &&
              profileData.candidateStatus === NOT_SUBMITTED
            ) {
              enqueueSnackbar(t("snackbar:profileSubmitted"), {
                variant: "success",
              });
            } else {
              enqueueSnackbar(t("snackbar:updateCandidateProfile"), {
                variant: "success",
              });
            }
          })
          .catch((error) => {
            enqueueSnackbar(t("snackbar:failedToUpdateProfile"), {
              variant: "error",
            });
          });
      }
    });
  };

  const roleType = useSelector((state) => state.session.roleType);

  const saveCandidateProfileDetails = () => {
    return CandidateApi.saveCandidateProfileDetails(
      profileData.campaignId,
      consultancyId,
      formState.values
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("common:successfullyUpdatedDetails"), {
            variant: "success",
          });
        }

        getCandidateCampaignDetails(profileData.campaignId, consultancyId);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleUploadProfilePicture = (value) => {
    return CandidateApi.uploadCandidateProfilePicture(
      candidateCampaign.campaignId,
      candidateCampaign.candidateProfile.candidateId,
      value
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("common:profilePictureUpdated"), {
            variant: "success",
          });
          setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
              ...prevFormState.values,
              candidateProfilePicture: response.data,
            },
          }));
          dispatch({
            type: UPDATE_CANDIDATE_PROFILE_PICTURE,
            profilePicture: response.data,
          });
        } else {
          throw Error("Failed to update profile picture");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("common:profilePictureUpdatedError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const handleUploadVideoProfile = (videoInfo) => {
    const uploadOptions = {
      params: {
        campaignId: profileData.campaignId,
        candidateId: profileData.candidateId
      }
    };

    const data = {
      secure_url: videoInfo.secure_url,
      original_filename: videoInfo.original_filename,
      resource_type: videoInfo.resource_type,
      public_id: videoInfo.public_id,
      duration: videoInfo.duration
    };

    CandidateApi.cloudinaryWidgetUploadProfileVideo(data, uploadOptions)
      .then(response => {
        setCandidateProfileVideo(response.data);
        handleModalClose();
      })
      .catch(() => {
        enqueueSnackbar('Error during upload, please try again!', {
          variant: 'error'
        });

        dispatch({
          type: UPDATE_VIDEO_PROCCESSING,
          videoProcessing: true
        });

        handleModalClose();
      });
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const toggleVideoAssessment = () => {
    if (isVideoAssessmentOpen) {
      getVideoAssessment(profileData.campaignId, candidateCampaign.candidateProfile.candidateId);
    }

    setIsVideoAssessmentOpen(!isVideoAssessmentOpen);
  };

  return (
    <Grid container spacing={3}>
      {!readOnly ? (
        <Grid className="" item xs={12}>
          <CardButton
            btnLabel={t("campaignCandidates:submitApplication")}
            btnFunc={submitProfile}
            btnDisabled={!formState.isValid}
            btnVariant="contained"
          />
        </Grid>
      ) : null}
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    {readOnly ? (
                      <img
                        style={{ height: "100px" }}
                        src={
                          profileData.candidateProfilePicture ||
                          avatarPlaceholder
                        }
                        alt=""
                      />
                    ) : (
                      <CustomFileDropzone
                        type="single"
                        format="image"
                        uploadFunction={handleUploadProfilePicture}
                        placeHolderImage={
                          profileData.candidateProfilePicture ||
                          avatarPlaceholder
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {t("common:firstNameLabel")}
                    </Typography>
                    <TextField
                      fullWidth
                      name="firstName"
                      variant="outlined"
                      onChange={handleChange}
                      error={hasError("firstName")}
                      value={formState.values.firstName || ""}
                      disabled={readOnly}
                      helperText={
                        hasError("firstName")
                          ? t(`validation:${formState.errors.firstName[0]}`)
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {t("common:lastNameLabel")}
                    </Typography>
                    <TextField
                      fullWidth
                      name="lastName"
                      variant="outlined"
                      onChange={handleChange}
                      error={hasError("lastName")}
                      value={formState.values.lastName || ""}
                      disabled={readOnly}
                      helperText={
                        hasError("lastName")
                          ? t(`validation:${formState.errors.lastName[0]}`)
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {t("common:cityLabel")}
                    </Typography>
                    <TextField
                      fullWidth
                      name="city"
                      variant="outlined"
                      onChange={handleChange}
                      error={hasError("city")}
                      value={formState.values.city || ""}
                      disabled={readOnly}
                      helperText={
                        hasError("city")
                          ? t(`validation:${formState.errors.city[0]}`)
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">
                      {t("common:postcodeLabel")}
                    </Typography>
                    <TextField
                      fullWidth
                      name="postalCode"
                      variant="outlined"
                      onChange={handleChange}
                      error={hasError("postalCode")}
                      value={formState.values.postalCode || ""}
                      disabled={readOnly}
                      helperText={
                        hasError("postalCode")
                          ? t(`validation:${formState.errors.postalCode[0]}`)
                          : null
                      }
                    />
                  </Grid>
                </Grid>
                {!readOnly ? (
                  <div className={classes.updateButton}>
                    <Button
                      color="primary"
                      size="medium"
                      type="submit"
                      variant="contained"
                      onClick={saveCandidateProfileDetails}
                      disabled={!formState.isValid}
                    >
                      Update
                    </Button>
                  </div>
                ) : null}
              </CardContent>
            </Card>

            {
              candidateCampaign.campaignCandidateVideo?.video && candidateCampaign.campaignCandidateVideo?.visibility.includes('candidates') && (
                <Card className={ classes.gridRow }>
                  <CardContent>
                    <BlockHeader card header={ t('labels:campaignCandidateVideo') } headerTooltip={ t('tooltips:campaignCandidateVideoInstructions') } />

                    <Box>
                      <video width="100%" src={ candidateCampaign.campaignCandidateVideo.video.url } controls allowfullscreen />
                    </Box>
                  </CardContent>
                </Card>
              )
            }

            {
              !shouldHideRequiredCandidateDocuments && (
                <Card className={classes.gridRow}>
                  <CardContent>
                    <RequiredCandidateDocuments
                      campaignRequiredDocuments={
                        candidateCampaign.campaignRequiredDocuments
                      }
                      candidateView={true}
                    />
                  </CardContent>
                </Card>
              )
            }

            {
              candidateCampaign.campaignModuleIds.includes(VIDEO_INTRODUCTION_ID) && (
                <Card className={classes.gridRow}>
                  <CardContent>
                    <BlockHeader
                      card
                      headerTooltip={t(`tooltips:candidateIntroduction`)}
                      header={t(`${campaignsPrefix}:uploadAVideo`)}
                    />
    
                    {profileData.videoProcessing ? (
                      <>{t("newTranslations:videoProcessing")}</>
                    ) : (
                      <>
                        {profileData.candidateStatus === 1 && (
                          <CloudinaryWidget onSuccess={ handleUploadVideoProfile } onVideoRecorderUpload={CandidateApi.uploadProfileVideo} />
                        )}
                        {candidateProfileVideo.url && (
                          <>
                            <Button
                              color="primary"
                              variant="contained"
                              size="medium"
                              className={ classes.viewVideoBtn }
                              fullWidth
                              onClick={handleModalOpen}
                            >
                              {t("campaigns:viewVideo")}
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </CardContent>
                </Card>   
              )
            }

            {
              videoAssessment && (
                <Card className={classes.gridRow}>
                  <CardContent>
                    {
                      videoAssessmentInteview && videoAssessmentInteview.completedAt ? (
                        <>
                          <VideoAssessmentReview videoAssessmentInteview={ videoAssessmentInteview } />
                        </>
                      ) : (
                        <>
                          <BlockHeader
                            card
                            headerTooltip={t("tooltips:videoAssessmentCandidate")}
                            header={t("campaigns:videoAssement")} />
      
                            <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              size="medium"
                              onClick={ toggleVideoAssessment } >
                              { t("launchpad:takeVideoAssessment") }
                            </Button>
      
                          {
                            isVideoAssessmentOpen && (
                              <>
                                <VideoAssessment videoAssessment={ videoAssessment } videoAssessmentInterview={ videoAssessmentInteview } isOpen={ isVideoAssessmentOpen } handleClose={ toggleVideoAssessment } />
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </CardContent>
                </Card>
              )
            }
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {formState.values.questions ? (
            <Grid item xs={12}>
              <Card>
                <BlockHeader
                  card
                  padding
                  headerTooltip={t("tooltips:candidateKcqCandidate")}
                  header={t("campaigns:kcqs")}
                />
                <CardContent>
                  {!readOnly ? (
                    <Typography variant="h4">
                      {t("campaigns:pleaseAnswerKCQs")}
                    </Typography>
                  ) : null}
                  {formState.values.questions?.map(
                    (category, categoryIndex) => (
                      <div className={classes.questionsAndAnswers}>
                        <Typography
                          variant="h4"
                          className={classes.categoryWrapper}
                        >
                          {category.name}
                        </Typography>
                        {category.questions.map((question, questionIndex) => (
                          <div>
                            <Typography
                              variant="h5"
                              className={classes.question}
                            >
                              {question.question}
                            </Typography>
                            <TextField
                              variant="outlined"
                              multiline
                              value={question.answer}
                              onChange={(event) =>
                                handleAnswerChange(
                                  event,
                                  categoryIndex,
                                  questionIndex
                                )
                              }
                              fullWidth
                              disabled={readOnly}
                            />
                          </div>
                        ))}
                      </div>
                    )
                  )}
                  {!readOnly ? (
                    <div className={classes.updateButton}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={updateCandidateKCQAnswers}
                        size="medium"
                      >
                        Update Answers
                      </Button>
                    </div>
                  ) : null}
                </CardContent>
              </Card>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <VideoModal
        open={modal}
        video={candidateProfileVideo}
        onClose={handleModalClose}
        campaignId={profileData.campaignId}
        candidateStatus={profileData.candidateStatus}
        candidateId={profileData.candidateId}
      />
      <SubmissionConfirmationModal open={open} onClose={handleClose} />
    </Grid>
  );
}
