import React, { useEffect, useState } from "react";
import isValidDomain from "is-valid-domain";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Button,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
} from "@material-ui/core";

import { ModalFormWrapper } from "src/components/ModalFormWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  smallInstructions: {
    paddingTop: "25px",
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "33%",
  },
  formControl: {
    marginBottom: theme.spacing(4),
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  endAdornment: {
    width: 112,
  },
  paddingTopForm: {
    marginTop: 10,
  },
  instructions: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

function CustomDomainModal({
  onSuccess,
  open,
  handleClose,
  customDomain,
  buttonText,
  title,
  isSubDomain,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [subdomain, setIsSubomain] = useState(true);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      customDomain,
      isSubDomain
    },
    touched: {},
  });

  const onSubmit = function(event) {
    event.preventDefault();

    let sysdomain = process.env.REACT_APP_SYSTEM_SUBDOMAIN;
    if (subdomain) {
      if (!formState.values.customDomain.includes(sysdomain)) {
        alert(
          `Your subdomain must contain ${process.env.REACT_APP_SYSTEM_SUBDOMAIN}`
        );
        return;
      }
    }

    onSuccess({
      formValues: formState.values,
    });
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubdomainChange = () => {
    setIsSubomain(formState.values.isSubDomain);

    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        isSubDomain: !prevValues.values.isSubDomain,
      },
    }));
  };

  useEffect(() => {
    if (subdomain) {
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          customDomain: customDomain ? customDomain : `${process.env.REACT_APP_SYSTEM_SUBDOMAIN}`,
        },
      }));
    } else {
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          customDomain: customDomain ? customDomain : "",
        },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdomain]);

  useEffect(() => {
    if (open) {
      let isValid;
      if (subdomain) {
        isValid = isValidDomain(formState.values.customDomain, {
          subdomain: !formState.values.isSubDomain,
        });
      } else {
        isValid = isValidDomain(formState.values.customDomain);
      }

      setFormState((prevFormState) => ({
        ...prevFormState,
        isValid,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  return (
    <ModalFormWrapper
      formTitle={title}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth="md"
    >
      {subdomain ? (
        <>
          <Typography variant="h2"> Subdomain Instructions</Typography>
          <Typography variant="body">
            Your subdomain should always end in "*.
            {`${process.env.REACT_APP_SYSTEM_SUBDOMAIN}`}"
            <p>To reset the custom domain, place one that is on our system e.g "example.{`${process.env.REACT_APP_SYSTEM_SUBDOMAIN}`}"</p>
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="h2">Custom Domain Instructions</Typography>
          <Typography style={{ paddingTop: "20px" }} variant="h4">
            To use a custom domain, you will need access to your domain name's
            DNS records.
          </Typography>
          <Typography variant="body">
            <div className={classes.instructions}>
              Login to your domain name registrar account and locate the DNS
              records.
            </div>
            <div className={classes.instructions}>
              Create a CNAME record for the domain (or sub-domain, if using)
              that points to "i-intro.net". Please note, depending on your
              domain registrar and name servers, DNS record changes can take up
              to 48 hours to propagate.
            </div>
            <div className={classes.instructions}>
              Type in your domain or sub-domain to generate CNAME records.
            </div>
            <div className={classes.instructions}>
              <code>
                {formState.values.customDomain} CNAME {formState.values.customDomain}.i-intro.net.kubocloud.io
              </code>
            </div>
            <div className={classes.instructions}>
              <code>
                _acme-challenge.{formState.values.customDomain} CNAME _acme-challenge.{formState.values.customDomain}.i-intro.net.kubocloud.io
              </code>
            </div>
          </Typography>
        </>
      )}

      <form className={classes.paddingTopForm} onSubmit={onSubmit}>

        <div className={classes.fields}>
          <TextField
            fullWidth
            label="Custom Domain"
            name="customDomain"
            variant="outlined"
            required
            onChange={handleChange}
            value={formState.values.customDomain || ""}
          />
          <FormControlLabel
            style={{ margin: "0px" }}
            control={
              <Switch
                checked={formState.values.isSubDomain}
                onChange={handleSubdomainChange}
                name="createContact"
                color="primary"
              />
            }
            label={<Typography variant="h5">Enable Custom Domain?</Typography>}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            className={classes.submitButton}
            size="large"
            type="submit"
            variant="contained"
            disabled={!formState.isValid}
            style={{
              backgroundColor:
                theme.palette.button?.main || theme.palette.primary.main,
              color: theme.palette.getContrastText(
                theme.palette.button?.main || theme.palette.primary.main
              ),
            }}
          >
            {buttonText}
          </Button>
        </div>
      </form>
    </ModalFormWrapper>
  );
}
export default CustomDomainModal;
