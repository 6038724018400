import React from 'react';

import {
  Table,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/styles';

import { BlockHeader } from '../../../components/LayoutComponents/BlockHeader';

const useStyles = makeStyles((theme) => ({
  singleContact: props => ({
    '&:nth-of-type(2n)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    '&:nth-of-type(2n+1)': {
      backgroundColor: fade(theme.palette.primary.main, 0.03)
    },
    padding: '10px 20px'
  })
}));


function Overview({overviews}) {
  const classes = useStyles();
  
  return (
    <>
      <BlockHeader
        header="Overview"
      />
      <Table stickyHeader aria-label="sticky table">
        <TableBody>
          {overviews?.map((overview, index) => (
            <TableRow
              hover
              role="checkbox"
              key={index}
              tabIndex={-1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              className={classes.singleContact}
            >
              <TableCell>
                <strong>{overview.point}</strong>
              </TableCell>
              <TableCell>
                {overview.pointValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default Overview;