import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Grid,
  Link,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import * as notificationActions from '../../actions/notificationActions';
import * as NotificationApi from '../../api/notificationApi';
import CloseIcon from '@material-ui/icons/Close';
import Notification from './Notification';

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'inherit',
  },
  drawer: {
    pointerEvents: 'none',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
    paddingRight: 16,
  },
  heading: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: '500',
    color: 'white',
    padding: 16,
    flex: 1,
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    maxWidth: '100%',
    pointerEvents: 'all',
    overflow: 'hidden',
  },
  content: {
    flexGrow: 1,
    marginTop: 35,
    height: window.innerHeight - 10,
    overflowY: 'scroll',
  },
  closeButton: {
    color: 'white',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'absolute',
    top: 58,
    width: '100%',
    zIndex: 999,
  },
}));

export default ({ className, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.notifications.open);
  const loggedIn = useSelector((state) => state.session.loggedIn);
  const notificationState = useSelector(
    (state) => state.notifications.notifications
  );

  const [t] = useTranslation([
    'common',
    'validation',
    'campaigns',
    'notifications',
    'vacancyNotifications',
  ]);
  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  let notificationInterval;

  const onClose = () =>
    dispatch({ type: notificationActions.TOGGLE_NOTIFICATION_DRAWER });

  const setNotificationState = () => {
    NotificationApi.fetchUserNotifications().then((data) => {
      dispatch({ type: notificationActions.SET_NOTIFICATIONS_COUNT, data });
    });

    return NotificationApi.fetchUserNotifications(30).then((data) => {
      dispatch({ type: notificationActions.SET_NOTIFICATIONS, data });
    });
  };

  const startNotificationUpdates = () => {
    notificationInterval = setInterval(() => {
      setNotificationState();
    }, 300000);
  };

  useEffect(() => {
    if (loggedIn) {
      setNotificationState();
      startNotificationUpdates();
    } else {
      clearInterval(notificationInterval);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <div className={classes.root}>
      <Drawer
        {...rest}
        anchor={'right'}
        className={classes.drawer}
        classes={{ paper: classes.paper }}
        hideBackdrop
        open={open}
        onClose={onClose}
      >
        <Grid className={classes.header}>
          <Typography className={classes.heading} variant='h2'>
            {t(`${notificationsPrefix}:notifications`)}
          </Typography>
          <IconButton
            className={classes.closeButton}
            color='inherit'
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <div className={classes.buttonGroup}>
          <Link
            style={{ textDecoration: 'none' }}
            href='/dashboard/notifications'
          >
            <Button color='primary' className={classes.refreshButton}>
              {t('common:all')}
            </Button>
          </Link>
          <Button
            color='primary'
            className={classes.refreshButton}
            onClick={setNotificationState}
          >
            {t('common:refreshLabel')}
          </Button>
        </div>
        <Grid className={classes.content}>
          {/* //- Filter is for redux state that was already implemented, to update notifications that read */}
          {notificationState
            .filter((notification) => !notification.read)
            .map((notification) => {
              return (
                <Notification
                  id={notification.id}
                  title={notification.notificationTitle}
                  text={notification.notificationText}
                  // TODO: image={}
                  created={notification.createdAt}
                  link={notification.notificationLink}
                  notificationTags={notification.notificationTags}
                />
              );
            })}
        </Grid>
      </Drawer>
    </div>
  );
};
