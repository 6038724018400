import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
  },
  gridAlign: {
    display: "flex",
    alignItems: "center",
  },
  titleAccent: {
    height: 32,
    width: 6,
    backgroundColor: theme.palette.primary.main,
    marginRight: 10,
  },
  title: {
    fontSize: 32,
    fontWeight: 200,
    letterSpacing: 0,
    visibility: "visible",
    opacity: 1,
    transition: "visibility 0s linear 0s, opacity 400ms",
  },
  breadcrumbs: {
    fontSize: 32,
    fontWeight: 200,
    letterSpacing: 0,
    color: theme.palette.primary.main,
  },
  currentView: {
    fontSize: 28,
    fontWeight: 200,
    letterSpacing: 0,
    color: "#000000",
    visibility: "visible",
    opacity: 1,
    transition: "visibility 0s linear 0s, opacity 400ms",
  },
  awaitingData: {
    height: 48,
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0s linear 0s, opacity 400ms",
  },
}));

export const PageTitle = ({
  pageTitle,
  btnLabel,
  btnFunc,
  btnVariant,
  btnLabel2,
  btnFunc2,
  btnVariant2,
  breadcrumbs,
}) => {
  const classes = useStyles();
  const btn = btnLabel != null;
  const btn2 = btnLabel2 != null ? true : false;
  const [t] = useTranslation(["candidates", "common", "campaigns"]);
  return (
    <Container className={classes.container} maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={btn2 && !btn ? 6 : btn2 ? 12 : 6}
              xl={btn2 && !btn ? 10 : btn2 ? 8 : btn ? 10 : 12}
              lg={btn2 && !btn ? 9 : btn2 ? 6 : btn ? 9 : 12}
              md={btn2 && !btn ? 6 : btn2 ? 12 : btn ? 8 : 12}
            >
              <Box style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.titleAccent} />
                {pageTitle && !breadcrumbs && (
                  <Typography
                    noWrap
                    className={
                      pageTitle.includes(undefined)
                        ? classes.awaitingData
                        : classes.title
                    }
                  >
                    {pageTitle}
                  </Typography>
                )}
                {breadcrumbs && (
                  <Breadcrumbs
                    separator={
                      breadcrumbs[breadcrumbs.length - 1]?.title?.includes(
                        undefined
                      )
                        ? ""
                        : "/"
                    }
                  >
                    {breadcrumbs
                      ?.slice(0, breadcrumbs.length - 1)
                      .map((crumb) => (
                        <Link
                          key={crumb.title}
                          className={classes.breadcrumbs}
                          href={`/dashboard${crumb.link}`}
                        >
                          {t(`campaigns:${crumb.title}`)}
                        </Link>
                      ))}
                    <Typography
                      className={
                        breadcrumbs[breadcrumbs.length - 1]?.title?.includes(
                          undefined
                        )
                          ? classes.awaitingData
                          : classes.currentView
                      }
                    >
                      {breadcrumbs[breadcrumbs.length - 1]?.title}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Box>
            </Grid>
            {btn ? (
              <Grid
                className={classes.gridAlign}
                item
                xs={12}
                xl={2}
                lg={3}
                md={btn2 ? 6 : 4}
                sm={6}
              >
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant={btnVariant || "contained"}
                  fullWidth
                  onClick={btnFunc}
                >
                  {btnLabel || "CTA"}
                </Button>
              </Grid>
            ) : null}
            {btn2 ? (
              <Grid
                className={classes.gridAlign}
                item
                xs={12}
                xl={2}
                lg={3}
                sm={6}
              >
                <Button
                  color="primary"
                  size="large"
                  type="submit"
                  variant={btnVariant2 || "contained"}
                  fullWidth
                  onClick={btnFunc2}
                >
                  {btnLabel2}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
        {breadcrumbs && <Breadcrumbs navBreadcrumbs={breadcrumbs} />}
      </Grid>
    </Container>
  );
};
