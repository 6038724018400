import React, { useEffect, useState } from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  colors,
  TextField,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { ModalFormWrapper } from "src/components/ModalFormWrapper";

import QuillEditor from "src/components/QuillEditor";

import * as videoAssessmentApi from './../../../api/videoAssessmentApi';

import * as xss from './../../../utils/xss';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function EditVideoAssessmentModal({ videoAssessment, open, onClose, onSuccess }) {
  const classes = useStyles();
  const [t] = useTranslation(["common", "launchpad", "tooltips"]);

  const { enqueueSnackbar } = useSnackbar();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      questionsAndTimes: [],
      instructions: "",
      prepTime: "",
    },
    touched: {},
    errors: {},
  });

  const addQuestion = (e) => {
    e.preventDefault();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questionsAndTimes: [
          ...prevFormState.values.questionsAndTimes,
          { text: "", limit: "" },
        ],
      },
    }));
  };

  const removeQuestionAndTime = (index, questionId) => {
    videoAssessmentApi.removeVideoAssessmentQuestion(questionId, videoAssessment.id)
      .then(() => {
        let tempArray = formState.values.questionsAndTimes;
        tempArray.splice(index, 1);
        setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            ...prevFormState.values,
            questionsAndTimes: tempArray,
          },
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const schema = {
    text: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    limit: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const onSubmit = function(event) {
    event.preventDefault();

    const { instructions, prepTime, questionsAndTimes } = formState.values;
    const questions = questionsAndTimes.map((qnt, i) => ({
      videoAssesmentId: videoAssessment.id,
      text: qnt.text,
      timeLimit: qnt.limit,
      order: i + 1,
      id: qnt.id
    }));

    videoAssessmentApi.updateVideoAssessment(videoAssessment.id, prepTime, instructions, questions)
      .then(() => {
        enqueueSnackbar(t('candidates:launchpadUpdateSuccess'), { variant: 'success' });
        onClose();
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('candidates:launchpadUpdateFailed'), { variant: 'error' });
      });
  };

  const handleChange = (event, index) => {
    let timesAndQ = formState.values.questionsAndTimes;

    timesAndQ[index][event.target.name] = event.target.value;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questionsAndTimes: timesAndQ,
      },
    }));
  };

  const handleUpdate = (event) => {
    let tempPrepTime = formState.values.prepTime;

    tempPrepTime = event.target.value;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        prepTime: tempPrepTime,
      },
    }));
  };

  const handleInstructionsChange = (event) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        instructions: event,
      },
    }));
  };

  useEffect(() => {
    const questionsAndTimes = videoAssessment.questions
      .sort((a, b) => {
        if (a.order > b.order) {
          return 1;
        }
  
        if (a.order < b.order) {
          return -1;
        }
  
        return 0;
      })
      .map((q) => ({
        id: q.id,
        text: q.text,
        limit: q.timeLimit
      }));

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        questionsAndTimes,
        prepTime: videoAssessment.preparationTime,
        instructions: xss.textToHtml(videoAssessment.instructions)
      },
    }));
  }, [videoAssessment]);

  useEffect(() => {
    let errors = formState.values.questionsAndTimes.map((questionsAndTimes) => {
      return validate(questionsAndTimes, schema);
    });

    const instructions = xss.htmlToPlainText(formState.values.instructions);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid:
        !errors.some((error) => typeof error === "object") &&
        instructions &&
        formState.values.prepTime !== "",
      errors: errors || [],
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  return (
    <ModalFormWrapper
      formTitle={t("launchpad:editLaunchpadInterview")}
      handleOpen={open}
      handleClose={onClose}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <form onSubmit={onSubmit}>
            <div className={classes.fields}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("launchpad:preperationTime")}
                    </InputLabel>
                    <div
                      style={{
                        position: "absolute",
                        top: -8,
                        left: 117,
                        zIndex: 100,
                      }}
                    >
                      <Tooltip
                        enterTouchDelay={1}
                        classes={{
                          tooltip: classes.tooltip,
                        }}
                        title={t("tooltips:selectAssessmentTime")}
                      >
                        <InfoIcon />
                      </Tooltip>
                    </div>
                    <Select
                      required
                      name="prepTime"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      label="Preperaton Time"
                      value={formState.values.prepTime}
                      onChange={(e) => handleUpdate(e)}
                    >
                      <MenuItem value={15}>
                        15 {t("launchpad:seconds")}
                      </MenuItem>
                      <MenuItem value={30}>
                        30 {t("launchpad:seconds")}
                      </MenuItem>
                      <MenuItem value={45}>
                        45 {t("launchpad:seconds")}
                      </MenuItem>
                      <MenuItem value={60}>
                        60 {t("launchpad:seconds")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h4">{t("launchpad:questions")}</Typography>
              </Grid>

              {formState.values.questionsAndTimes.map(
                (questionAndTime, index) => (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={7}>
                      <div style={{ position: "relative" }}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          onChange={(e) => handleChange(e, index)}
                          label="Question"
                          name="text"
                          value={questionAndTime?.text}
                          variant="outlined"
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: -8,
                            left: 77,
                            zIndex: 100,
                          }}
                        >
                          <Tooltip
                            enterTouchDelay={1}
                            classes={{
                              tooltip: classes.tooltip,
                            }}
                            title={t("tooltips:insertQuestion")}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id="demo-simple-select-outlined-label">
                          {t("launchpad:timeLimit")}
                        </InputLabel>
                        <div
                          style={{
                            position: "absolute",
                            top: -8,
                            left: 83,
                            zIndex: 100,
                          }}
                        >
                          <Tooltip
                            enterTouchDelay={1}
                            classes={{
                              tooltip: classes.tooltip,
                            }}
                            title={t("tooltips:selectTime")}
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Age"
                          name="limit"
                          onChange={(e) => handleChange(e, index)}
                          value={questionAndTime?.limit}
                        >
                          <MenuItem value={15}>
                            15 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={30}>
                            30 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={45}>
                            45 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={60}>
                            60 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={75}>
                            75 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={90}>
                            90 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={105}>
                            105 {t("launchpad:seconds")}
                          </MenuItem>
                          <MenuItem value={120}>
                            120 {t("launchpad:seconds")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {formState.values?.questionsAndTimes?.length > 1 ? (
                      <Grid item xs={12} md={1}>
                        <IconButton
                          onClick={(e) =>
                            removeQuestionAndTime(
                              index,
                              questionAndTime.id
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                )
              )}

              <Button
                className={classes.submitButton}
                color="primary"
                size="medium"
                type="submit"
                variant="contained"
                onClick={addQuestion}
                disabled={formState.values.questionsAndTimes.length >= 10}
              >
                {t("launchpad:addQuestion")}
              </Button>

              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <Typography variant="h4">
                    {t("launchpad:instructions")}
                  </Typography>
                  <Tooltip
                    enterTouchDelay={1}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    style={{ marginLeft: "10px" }}
                    title={t("tooltips:insertInstructions")}
                  >
                    <InfoIcon />
                  </Tooltip>
                </div>
              </Grid>

              <Grid item xs={12}>
                <QuillEditor
                  value={formState.values.instructions}
                  handleChange={handleInstructionsChange}
                  toolbarOptions={["link"]}
                />
              </Grid>
            </div>
            <Button
              className={classes.submitButton}
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              disabled={!formState.isValid}
            >
              {t("launchpad:editLaunchpadInterview")}
            </Button>
          </form>
        </Grid>
      </Grid>
    </ModalFormWrapper>
  );
}
export default EditVideoAssessmentModal;
