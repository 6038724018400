import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { debounce } from 'lodash';

import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Typography, CircularProgress, Box } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

import { loginAndCreateSession } from './../../../actions';

import * as sessionApi from './../../../api/sessionApi';
import * as talentAlertApi from './../../../api/talentAlertApi';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  fieldText: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    },
    marginBottom: '20px'
  },
  submitButton: {
    width: '50%',
    height: '3rem',
    margin: '1rem auto 0.5rem',
    borderRadius: 5,
    '&:hover': {
      cursor: 'pointer'
    },
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(
      theme.palette.primary.main
    )
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  },
  message: {
    textAlign: 'center', 
    marginTop: '10px'
  },
  forgotPassword: {
    cursor: 'pointer',
    color: theme.palette.primary.main
  },
  setPasswordFormFields: {
    marginTop: '16px'
  }
}));

const LoginForm = ({ campaignId, closeForm }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(['common', 'login', 'validation', 'snackbar', 'newTranslations']);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    exists: false,
    activated: false,
    accessToken: '',
    isPartOfCampaign: true
  });

  const schema = Yup.object({
		email: Yup.string().required('validation:emailIsRequired'),
		password: Yup.string().required('validation:passwordIsRequired')
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		validateOnMount: true,
		validationSchema: schema,
		onSubmit: (values) => {
      setLoading(true);

      const { email, password } = values;

      loginAndCreateSession(dispatch, { email, password, host: window.location.hostname })
        .then(() => {
          window.location.href = `/dashboard/talent-alerts/${campaignId}`;
        })
        .catch(error => {
          console.log(error);
          enqueueSnackbar(`${t('validation:loginFailed')}: ${t(`snackbar:${error.message}`)}`, { variant: 'error' });
        })
        .then(() => {
          setLoading(false);
        });
		}
	});

  const schemaSetPassword = Yup.object({
    password: Yup.string().required('validation:passwordIsRequired'),
    passwordConfirmation: Yup.string().required('validation:passwordIsRequired')
  });

  const formikSetPassword = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: ''
    },
    validateOnMount: true,
    validationSchema: schemaSetPassword,
    onSubmit: (values) => {
      if (values.password !== values.passwordConfirmation) {
        enqueueSnackbar(t('newTranslations:taLoginPasswordsDoNotMuchError'), { variant: 'error' });
        return;
      }

      setLoading(true);

      sessionApi.activateAccount(values.password, user.accessToken)
        .then(() => {
          return loginAndCreateSession(dispatch, {
            email: formik.values.email,
            password: values.password,
            host: window.location.hostname
          });
        })
        .then(() => {
          window.location.href = `/dashboard/talent-alerts/${campaignId}`;
        })
        .catch(error => {
          console.log(error);
          enqueueSnackbar(`${t('validation:loginFailed')}: ${t(`snackbar:${error.message}`)}`, { variant: 'error' });
        })
        .then(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    return () => {
      handleEmailChangeDebounce.cancel();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailChange = (email) => {
    if (!hasError(formik, 'email')) {
      talentAlertApi.checkIfRegistered(email, window.location.hostname, campaignId)
        .then((response) => {
          setUser(response.data);
          setLoading(false);
        })
        .catch(() => {
          setUser({
            exists: false,
            activated: false,
            accessToken: '',
            isPartOfCampaign: true
          });
        });
    }
  };

  const handleEmailChangeDebounce = useMemo(
    () => debounce(handleEmailChange, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onEmailChange = (event) => {
    event.persist();

    formik.handleChange(event);
    handleEmailChangeDebounce(event.target.value);
  };

  const hasError = (formikVersion, field) => {
		return formikVersion.touched[field] && formikVersion.errors[field];
	};

  const isLogin = () => {
    return user.isPartOfCampaign && user.activated && user.exists;
  };

  const getFormMessageKey = () => {
    if (user.isPartOfCampaign && user.activated && user.exists) {
      return 'newTranslations:taLoginFormLoginMessage';
    }

    if (user.isPartOfCampaign && user.exists && !user.activated) {
      return 'newTranslations:taLoginFormActivateAccountMessage';
    }

    return 'newTranslations:taLoginFormMessage';
  };

  const getFormSharedJSX = () => {
    return (
      <>
        <div className={classes.fieldText}>
          <Typography>
            { t(getFormMessageKey()) }
          </Typography>
        </div>
        
        <div className={classes.fields}>
          <TextField
            error={hasError(formik, "email")}
            fullWidth
            label={t("common:emailAddressLabel")}
            name="email"
            onChange={onEmailChange}
            value={formik.values.email}
            variant="outlined"
            autoComplete="off" />
          
          {
            user.isPartOfCampaign && user.activated && user.exists && (
              <TextField
                error={hasError(formik, "password")}
                fullWidth
                label={t("common:passwordLabel")}
                name="password"
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
                variant="outlined" />
            )
          }
        </div>
      </>
    );
  }

  if (user.isPartOfCampaign && user.exists && !user.activated) {
    return (
      <form className={ classes.root } onSubmit={formikSetPassword.handleSubmit}>
        { getFormSharedJSX() }

        <div className={classes.fields}>
          <TextField
            error={hasError(formikSetPassword, "password")}
            fullWidth
            className={ classes.setPasswordFormFields }
            label={t("common:passwordLabel")}
            name="password"
            type="password"
            onChange={formikSetPassword.handleChange}
            value={formikSetPassword.values.password}
            variant="outlined"
            autoComplete="off" />

          <TextField
            error={hasError(formikSetPassword, "passwordConfirmation")}
            fullWidth
            label={t("common:confirmPasswordLabel")}
            name="passwordConfirmation"
            type="password"
            onChange={formikSetPassword.handleChange}
            value={formikSetPassword.values.passwordConfirmation}
            variant="outlined"
            autoComplete="off" />

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Button
                className={classes.submitButton}
                size="large"
                type="submit"
                variant="contained" >
                { 
                  loading ? (
                    <CircularProgress className={classes.progressCircle} size={25} />
                  ) : (
                    t("login:login")
                  )
                }
              </Button>
            </Box>
          </div>
        </form>
    );
  }

  return (
    <form className={classes.root} onSubmit={formik.handleSubmit}>
      { getFormSharedJSX() }
      
      <Box display="flex" flexDirection="column" justifyContent="center">
        {
          isLogin() && (
            <Button
              className={classes.submitButton}
              size="large"
              type="submit"
              variant="contained" >
              { 
                loading ? (
                  <CircularProgress className={classes.progressCircle} size={25} />
                ) : (
                  t("login:login")
                )
              }
            </Button>
          )
        }

        {
          !isLogin() && (
            <Button
              className={ classes.submitButton }
              size="large"
              variant="contained"
              disabled={ user.isPartOfCampaign }
              onClick={ () => { closeForm(true); } } >
              { 
                loading ? (
                  <CircularProgress className={classes.progressCircle} size={25} />
                ) : (
                  t("newTranslations:taLoginRequestAccessToCampaignBtn")
                )
              }
            </Button>
          )
        }
      </Box>
    </form>
  );

};

export default LoginForm;
