import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Card,
  CardContent,
  Typography,
  Grid,
  Chip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router";
import * as NotificationApi from "../../api/notificationApi";
import * as notificationActions from "../../actions/notificationActions";
import { useDispatch } from "react-redux";
import avatarPlaceholder from "src/assets/images/missing_avatar.svg";
import moment from "moment";

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../utils/useSubscriptionPrefix';

let ejs = require("ejs");

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.01)",
    },
  },
  header: {
    alignItems: "center",
    display: "flex",
    paddingBottom: "15px",
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
  title: {
    fontSize: 14,
  },
  dates: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 0 0",
  },
  pos: {
    marginBottom: 12,
  },
  closeButton: {
    paddingRight: "25px",
    paddingBottom: "10px",
    paddingTop: "0px",
  },
});

export default ({
  title,
  text,
  id,
  image,
  link,
  created,
  notificationTags,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const today = moment();

  const [t] = useTranslation(["notifications", "vacancyNotifications", "validation", "snackbar"]);
  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const onClickDismiss = () => {
    return NotificationApi.setNotificationRead(id)
      .then((response) => {
        if (response.status === 200)
          dispatch({
            type: notificationActions.SET_NOTIFICATION_READ,
            data: id,
          });

          dispatch({
            type: notificationActions.DECREMENT_NOTIFICATIONS_COUNT
          });
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleLink = () => {
    onClickDismiss().then((response) => {
      if (response.status === 200) {
        dispatch({
          type: notificationActions.TOGGLE_NOTIFICATION_DRAWER,
        });
        history.push(link);
      }
    });
  };

  const translatedNotificationString = t(`${notificationsPrefix}:${text}`);

  const parseNotification = () => {
    if (notificationTags) {
      return ejs.render(
        translatedNotificationString,
        JSON.parse(notificationTags)
      );
    } else {
      return translatedNotificationString;
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Grid container>
          <Grid className={classes.header} item xs={12}>
            <Grid item xs={4} sm={2}>
              <Avatar
                className={classes.avatar}
                src={avatarPlaceholder}
                style={image ? { transform: "translateY(-70px)" } : null}
              />
            </Grid>
            <Grid item xs={6} sm={9}>
              <Grid item xs={12}>
                <Typography variant="h4">
                  {t(`${notificationsPrefix}:${title}`)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={2} sm={1}>
              <CloseIcon className={classes.link} onClick={onClickDismiss} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {link !== "" ? (
              <Typography
                onClick={handleLink}
                className={classes.link}
                variant="body1"
              >
                {parseNotification()}
              </Typography>
            ) : (
              <Typography className={classes.link} variant="body1">
                {parseNotification()}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} className={classes.dates}>
            {today.diff(moment(created), "days") > 0 && (
              <Chip
                label={
                  <Typography variant="body">
                    {today.diff(moment(created), "days") +
                      " " +
                      t(`${notificationsPrefix}:daysAgo`)}
                  </Typography>
                }
              />
            )}
            <Chip
              label={
                <Typography variant="body1">
                  {moment(created).format("DD/MM/YYYY HH:mm")}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
