import React from 'react';

import { Grid, TextField, Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/styles';

const inputTypes = {
    POINT: 'point',
    POINTVALUE: 'pointValue'
};

const useStyles = makeStyles(() => ({
    inputRow: {
        alignItems: 'center',
    },
    overviews: {
        padding: '20px',
        marginTop: '20px',
        marginBottom: '20px'
    },
    button: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px'
    }
}));

const SingleOverview = ({ formik, index }) => {
    const classes = useStyles();
    
    const getError = (inputType) => {
        if (formik.errors.overviews && formik.errors.overviews[index] && formik.errors.overviews[index][inputType] &&
            formik.touched.overviews && formik.touched.overviews[index] && formik.touched.overviews[index][inputType]) {
            return formik.errors.overviews[index][inputType];
        }

        return '';
    };

    const onDelete = (event) => {
        event.preventDefault();

        if (formik.values.overviews.length > 1) {
            const overviews = [...formik.values.overviews];
            overviews.splice(index, 1);

            formik.setValues({
                ...formik.values,
                overviews
            });

            return;
        }

        formik.setValues({
            ...formik.values,
            overviews: [
                {
                    point: '',
                    pointValue: ''
                }
            ]
        });
    };

    return (
        <>
            <div className="row" key={index}>
                <Grid container spacing={3} className={classes.inputRow}>
                    <Grid item xs={2} md={1}>
                        <label htmlFor={`overview.${index}.name`}>Overview {index + 1}</label>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            name={`overviews[${index}].point`}
                            placeholder="Insert Key Selling Point, e.g. experience, location, salary etc."
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={formik.values.overviews[index].point}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={!!getError(inputTypes.POINT)}
                            helperText={getError(inputTypes.POINT)}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField
                            name={`overviews[${index}].pointValue`}
                            placeholder="Insert text"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={formik.values.overviews[index].pointValue}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={!!getError(inputTypes.POINTVALUE)}
                            helperText={getError(inputTypes.POINTVALUE)}
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Button
                            type="button"
                            className="secondary"
                            onClick={e => onDelete(e, index)}
                        >
                            Remove
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    );

};

export default SingleOverview;