import "date-fns";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useSelector, useDispatch } from "react-redux";
import { UPDATE_DATE_TO, UPDATE_DATE_FROM } from "src/actions";
import { useTranslation } from "react-i18next";

export default function DatePicker() {
  const dispatch = useDispatch();
  const dateFrom = useSelector((state) => state.analytics.dateFrom);
  const dateTo = useSelector((state) => state.analytics.dateTo);
  const [dateA, setDateA] = useState(dateFrom);
  const [dateB, setDateB] = useState(dateTo);
  const [t] = useTranslation(["common"]);

  const [warningLabelA, setWrongDateWarningLabelA] = useState(
    t("common:startDateLabel")
  );
  const [warningLabelB, setWrongDateWarningLabelB] = useState(
    t("common:endDateLabel")
  );

  const handleDateChangeFrom = (date) => {
    if (date > dateTo) {
      setWrongDateWarningLabelA(t("common:wrongStartDateWarningLabel"));
    } else {
      setDateA(date);
      setWrongDateWarningLabelA(t("common:startDateLabel"));
      setWrongDateWarningLabelB(t("common:endDateLabel"));
    }
  };

  const handleDateChangeTo = (date) => {
    if (date < dateFrom) {
      setWrongDateWarningLabelB(t("common:wrongEndDateWarningLabel"));
    } else {
      setDateB(date);
      setWrongDateWarningLabelA(t("common:startDateLabel"));
      setWrongDateWarningLabelB(t("common:endDateLabel"));
    }
  };

  const setData = (dateA, dateB) => {
    dispatch({
      type: UPDATE_DATE_TO,
      payload: dateB,
    });
    dispatch({
      type: UPDATE_DATE_FROM,
      payload: dateA,
    });
  };

  useEffect(() => {
    setData(dateA, dateB);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateA, dateB]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around" spacing={1}>
        <Grid item>
          <KeyboardDatePicker
            id="date-picker-from"
            label={warningLabelA}
            format="dd/MM/yyyy"
            value={dateFrom}
            onChange={handleDateChangeFrom}
            KeyboardButtonProps={{
              "aria-label": `${t("common:changeDateLabel")}`,
            }}
            disableFuture={true}
          />
        </Grid>
        <Grid item>
          <KeyboardDatePicker
            id="date-picker-to"
            label={warningLabelB}
            format="dd/MM/yyyy"
            value={dateTo}
            onChange={handleDateChangeTo}
            KeyboardButtonProps={{
              "aria-label": t("common:changeDateLabel"),
            }}
            disableFuture={true}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
