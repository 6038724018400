export default (role = '', isTAP = false) => {
  switch (role) {
    case "SystemAdmin":
      return '';
    case "Admin": 
    case "Consultant":
      if (isTAP) {
        return "https://desk.zoho.eu/portal/api/web/inapp/181065000000244278?orgId=20100523559";
      }

      return "https://desk.zoho.com/portal/api/web/inapp/447151000000149001?orgId=699375862";
    case "Candidate": 
    case "Contact": 
    default: 
      return '';
  }
}

//This script gets the relevant zoho desk link based on the user role.
//TODO: GET THE CANDIDATE AND CONTACDT DESK LINK FROM i-intro