import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Grid, Card, CardContent, Button } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import CloudinaryWidget from './../../../components/CloudinaryWidget';
import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';
import VideoModal from './../../CandidateCampaignDetail/CandidateDetails/VideoUploadModal';
import CampaignCandidateVideoVisibility from './../../../components/CloudinaryWidget/CampaignCandidateVideoVisibility';
import CampaignKeyCompetencyCard from './../../Campaigns/CampaignDetail/CampaignKeyCompetencyQuestions/CampaignKeyCompetencyCard';

import CampaignVideoUpload from './CampaignVideoUpload';
import IntroductionInformation from './IntroductionInformation';
import TalentAlertInformation from './TalentAlertInformation';
import TalentAlertTermsAndConditions from './TermsAndConditions/TalentAlertTermsAndConditions';
import TalentAlertStatus from './TalentAlertStatus';
import TalentAlertCampaignDocuments from './TalentAlertCampaignDocuments';

import * as campaignApi from './../../../api/campaignApi';

const TalentAlertDetails = ({ ...rest }) => {

  const [t] = useTranslation(['labels', 'tooltips', 'campaigns']);
  const { enqueueSnackbar } = useSnackbar();

  const talentAlert = useSelector(state => state.talentAlert);

  const { consultancyId } = useSelector((state) => state.session);

  const [modal, setModal] = useState(false);
  const [ccv, setCCV] = useState(talentAlert.campaignCandidateVideo ? talentAlert.campaignCandidateVideo.video : null);

  const handleModalOpen = () => setModal(true);
  const handleModalClose = () => setModal(false);

  const cloudinaryWidgetUploadCampaignCandidateVideo = (videoInfo) => {
    const uploadOptions = {
      params: {
        campaignId: talentAlert.id,
        consultancyId,
      },
    };

    const data = {
      secure_url: videoInfo.secure_url,
      original_filename: videoInfo.original_filename,
      resource_type: videoInfo.resource_type,
      public_id: videoInfo.public_id,
      duration: videoInfo.duration,
    };

    campaignApi.cloudinaryWidgetUploadCampaignCandidateVideo(data, uploadOptions)
      .then(response => {
        setCCV(response.data.video);
        handleModalClose();
      })
      .catch(() => {
        enqueueSnackbar('Error during upload, please try again!', {
          variant: 'error'
        });

        handleModalClose();
      });
  };

  const handleUploadCampaignCandidateVideo = (body, uploadOptions) => {
    uploadOptions.params.consultancyId = consultancyId;

    return campaignApi.uploadCampaignCandidateVideo(body, uploadOptions)
      .then(response => {
        setCCV(response.data.video);
      })
      .catch(err => {
        throw err;
      });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TalentAlertInformation guestView={rest?.guestView} />
          </Grid>
          
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <TalentAlertStatus guestView={rest?.guestView} />
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <CampaignKeyCompetencyCard guestView={rest?.guestView} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <IntroductionInformation 
              campaignId={talentAlert.id}
              consultancyId={consultancyId}
              introductionDetails={talentAlert.introduction}
              headerImg={talentAlert.headerImg}
              campaignLogoImg={ talentAlert.campaignLogoImg } />  
          </Grid>
          
          <Grid item xs={12}>
            <CampaignVideoUpload 
              video={talentAlert.campaignVideo}
              isVideoProcessing={talentAlert.isProcessing}
              campaignId={talentAlert.id} />
          </Grid>
          
          <Grid item xs={12}>
            <TalentAlertTermsAndConditions guestView={rest?.guestView} />
          </Grid>
        </Grid>
      </Grid>
      
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <BlockHeader card header={t('labels:campaignCandidateVideo')} headerTooltip={t('tooltips:campaignCandidateVideo')} />

                <CloudinaryWidget onSuccess={ cloudinaryWidgetUploadCampaignCandidateVideo } onVideoRecorderUpload={ handleUploadCampaignCandidateVideo } talentAlert campaignCandidateVideo />

                {
                  ccv && (
                    <>
                      <Button
                        style={{ marginTop: 16 }}
                        color="primary"
                        fullWidth
                        variant="outlined"
                        size="large"
                        onClick={handleModalOpen}
                      >
                        {t("campaigns:viewVideo")}
                      </Button>

                      <CampaignCandidateVideoVisibility campaignId={ talentAlert.id } consultancyId={ consultancyId } visibility={ talentAlert.campaignCandidateVideo?.visibility || 'private,contacts,candidates' } talentAlert />
                    </>
                  )
                }
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <TalentAlertCampaignDocuments
                  campaignRequiredDocuments={talentAlert.campaignRequiredDocuments}
                  campaignModules={talentAlert.campaignModules} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {
        ccv && (
          <VideoModal
            open={ modal }
            video={ ccv }
            onClose={ handleModalClose }
            campaignId={ talentAlert.id }
            modalHeader={ t('labels:campaignCandidateVideo') } />
        )
      }
    </Grid>
  );

};

export default TalentAlertDetails;
