/* eslint-disable react/no-multi-comp */
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Fab, Zoom } from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		top: 42,
		left: 16,
		fontSize: "20px",
		zIndex: 10001,
	},
}));

export const OpenDrawerButton = ({ onDesktopOpen, openDesktop }) => {
	const classes = useStyles();

	return (
		<Zoom in={!openDesktop}>
			<Fab onClick={onDesktopOpen} className={classes.root}>
				<ChevronRightIcon />
			</Fab>
		</Zoom>
	);
};
