import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { CustomDragLayer } from './CustomDragLayer';
import { makeStyles } from '@material-ui/styles';
import {
  Drawer,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core';
import * as mediaDrawerActions from '../../actions/mediaDrawerActions';
import * as assetApi from '../../api/assetApi';
import ContextPanel from './ContextPanel';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'inherit'
  },
  drawer: {
    pointerEvents: 'none'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.main,
  },
  heading: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: '500',
    color: "white",
    padding: 21,
    flex: 1
  },
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: 320,
    maxWidth: '100%',
    pointerEvents: 'all'
  },
  content: {
    flexGrow: 1,
  },
  closeButton: {
    color: "white",
  }
}));

function AssetDrawer({className, ...rest}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const open = useSelector(state => state.mediaDrawer.open);
  const contexts = useSelector(state => state.mediaDrawer.contexts);
  const [t] = useTranslation(['common', 'storage']);

  const onClose = () => {
    dispatch({type: mediaDrawerActions.CLOSE_DRAWER })
  };

  return (
    <div className={classes.root}>
      <Drawer {...rest} anchor={'right'} className={classes.drawer} classes={{paper: classes.paper}} hideBackdrop open={open} onClose={onClose}>
        <Grid className={classes.header}>
          <Typography className={classes.heading}>{t("storage:myFilesLabel")}</Typography>
          <IconButton
            className={classes.closeButton}
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid className={classes.content}>
          {
            contexts.map((contextObject, index) => {
              let contextHof;
              switch (contextObject.context) {
                case "Personal":
                  contextHof = assetApi.getPersonalAssets;
                  break;
                case "Candidate":
                case "Contact":
                case "Consultant": 
                case "Campaign":
                default:
                  console.error("Invalid Context Provided");
              };

              return <ContextPanel key={`${contextObject.context}_context_panel`} context={contextObject} getAssets={contextHof} />
            })
          }
          
        </Grid>
      </Drawer>
      <CustomDragLayer />
    </div>
  );
}

AssetDrawer.propTypes = {
};

export default AssetDrawer;
