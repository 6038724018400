/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import { AppBar, Avatar, Badge, Box, ButtonBase, ClickAwayListener, Hidden, IconButton, Toolbar, useMediaQuery } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { fade } from '@material-ui/core/styles';
import LogOutIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, useTheme } from '@material-ui/styles';

import { logoutAndDeleteSession, TOGGLE_DRAWER, TOGGLE_NOTIFICATION_DRAWER } from './../../actions';

import * as searchApi from './../../api/searchApi';

import LanguageDropdown from './../../components/LanguageDropdown';
import SearchResultDropdown from './../../components/SearchResultDropdown';

import avatarPlaceholder from './../../assets/images/missing_avatar.svg';
import logo from './../../assets/images/logos/i-intro_logo_light.png';
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

import { TA, TA_PRO, FULL } from './../../constants/subscriptionPlansIds';
import { CONTACT } from './../../constants/roles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.nav.main
  },
  flexGrow: {
    flexGrow: 1
  },
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  menuButton: {
    marginRight: theme.spacing(3),
    fontSize: '1.4rem'
  },
  badge: {
    fontSize: '.9rem'
  },
  popover: {
    width: 200
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#fff', 0.15),
    '&:hover': {
      backgroundColor: fade('#fff', 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchDropdown: {
    width: 400
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  topBarLogo: {
    maxHeight: '64px',
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
    padding: '6px 0px'
  },
  linkSpacer: {
    transition: '0.15s all ease',
    marginRight: 0
  }
}));

const TopBar = ({ onOpenNavBarMobile, openDesktop, className, ...rest }) => {

  const [t] = useTranslation(['common']);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const mobileDevice = useMediaQuery('(max-width:1278px), (orientation: portrait)');

  const ref = useRef(null);
  const input = useRef(null);
  const search = useRef(null);

  const session = useSelector((state) => state.session);
  const notificationsCount = useSelector((state) => state.notifications.notificationsCount);
  
  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [searchResults, setSearchResults] = useState({});
  const [isSearchActive, setSearchActive] = useState(false);
  const [formState, setFormState] = useState({
    values: {
      search: "",
    },
  });

  const iconColour = theme.palette.getContrastText(theme.palette.nav.main) || theme.palette.getContrastText(theme.palette.primary.main);
  const offsetTop = search?.current?.offsetTop;
  const offsetLeft = search?.current?.offsetLeft;

  const handleLogout = () => {
    history.push('/');

    logoutAndDeleteSession(dispatch)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClick = (event) => {
    if (event.result._index === 'campaigns') {
      history.push(`/dashboard/campaigns/${event.result._id}`);

      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: ''
        }
      }));
    } else if (event.result._index === 'employers') {
      history.push(`/dashboard/employers/${event.result._id}`);

      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: ''
        }
      }));
    } else if (event.result._index === 'contacts') {
      history.push(`/dashboard/employers/${event.result._source.employerId}/contacts/${event.result._id}`);

      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: ''
        }
      }));
    } else if (event.result._index === 'talentalerts') {
      history.push(`/dashboard/talent-alert/${event.result._id}`);

      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: ''
        }
      }));
    } else if (event.result._index === 'candidates') {
      history.push(`/dashboard/candidates/${event.result._id}`);

      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: ''
        }
      }));
    }
  };

  const toggleMediaDrawer = () => {
    dispatch({ type: TOGGLE_DRAWER });
  };

  const toggleNotificationDrawer = () => {
    dispatch({ type: TOGGLE_NOTIFICATION_DRAWER });
  };

  const handleSearch = (e) => {
    e.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        [e.target.name]: e.target.value
      }
    }));

    let timer = setTimeout(() => {}, 1);

    const makeCall = () => {
      searchApi.search(consultancyId, e.target.value).then((result) => {
        setSearchResults(result);
        setSearchActive(true);
        //test
        input.current.focus();
      });
    };

    const callIfPaused = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        makeCall();
      }, 400);
    };

    if (e.target.value !== '') {
      callIfPaused();
    }
  };

  const handleSearchClose = () => {
    setSearchActive(false);
  };

  const getLogo = () => {
    if (session.consultancySecondaryLogo) {
      return session.consultancySecondaryLogo;
    }

    if (session.consultancyLogo) {
      return session.consultancyLogo;
    }

    switch (session.consultancySubscriptionPlanId) {
      case TA:
        return taLogo;
      case TA_PRO:
        return taProLogo;
      default:
        return logo;
    }
  };

  const homePage = () => {
    if (session.consultancySubscriptionPlanId === FULL) {
      return '/dashboard';
    }

    return '/dashboard/talent-alerts';
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            onClick={onOpenNavBarMobile}
            style={{ color: iconColour }}>
            <MenuIcon />
          </IconButton>
        </Hidden>

        <div
          className={classes.linkSpacer}
          style={!openDesktop ? { marginRight: 56 } : null}/>

        <RouterLink to={ homePage() } edge="start">
          <img
            alt="Logo"
            className={classes.topBarLogo}
            src={ getLogo() } />
        </RouterLink>

        <div className={classes.flexGrow} />

        {
          session.roleType !== CONTACT && session.consultancySubscriptionPlanId !== TA &&
          session.consultancySubscriptionPlanId !== TA_PRO && (
            <Box
              display="flex"
              alignItems="center"
              component={ButtonBase}
              ref={search}
              style={{ marginRight: theme.spacing(3) }}>
              <div className={classes.search}>
                <form>
                  <div className={classes.searchIcon}>
                    <SearchIcon
                      style={{ zIndex: 1, color: theme.palette.getContrastText(theme.palette.primary.dark) }}/>
                  </div>

                  <InputBase
                    ref={input}
                    placeholder={`${t("common:search")}...`}
                    name="search"
                    autoComplete="off"
                    style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.getContrastText(theme.palette.primary.dark) }}
                    onChange={(e) => handleSearch(e)}
                    classes={{ root: classes.inputRoot, input: classes.inputInput }}
                    inputProps={{ "aria-label": "search" }}/>
                </form>
              </div>
            </Box>
          )
        }

        <LanguageDropdown />

        {
          session.roleType !== CONTACT && !mobileDevice &&
          session.consultancySubscriptionPlanId !== TA && session.consultancySubscriptionPlanId !== TA_PRO && (
            <IconButton
              className={classes.menuButton}
              id="1"
              onClick={toggleMediaDrawer}
              style={{ color: iconColour }}>
              <PermMediaIcon />
            </IconButton>
          )
        }

        <IconButton
          id="2"
          className={classes.menuButton}
          onClick={toggleNotificationDrawer}
          style={{ color: iconColour }}>
          <Badge
            badgeContent={notificationsCount}
            classes={{ badge: classes.badge }}
            color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>

        {
          !mobileDevice && (
            <Box
              className={classes.menuButton}
              display="flex"
              alignItems="center"
              justifyContent="center"
              ref={ref}
              component={ RouterLink }
              to="/dashboard/my-profile">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={session.profilePicture || avatarPlaceholder}/>
            </Box>
          )
        }

        <IconButton
          onClick={handleLogout}
          style={{ color: iconColour, fontSize: "1.4rem" }}>
          <LogOutIcon />
        </IconButton>
      </Toolbar>

      {
        isSearchActive && formState.values.search && (
          <ClickAwayListener onClickAway={handleSearchClose}>
            <div style={{ position: "relative", zIndex: 10000 }}>
              <div
                style={{
                  position: "absolute",
                  top: offsetTop - 30,
                  left: offsetLeft + 24,
                  zIndex: 10000,
                  boxShadow: "-1px 5px 5px 5px rgba(0,0,0,0.5)",
                }}>
                <SearchResultDropdown
                  searchResults={searchResults}
                  handleClick={handleClick}
                />
              </div>
            </div>
          </ClickAwayListener>
        )
      }
    </AppBar>
  );

}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;
