/* eslint-disable max-len */
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import FilterListIcon from "@material-ui/icons/FilterList";
import GetApp from "@material-ui/icons/GetApp";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import { useTranslation } from "react-i18next";
import {
	Avatar,
	Box,
	Button,
	Grid,
	Card,
	Checkbox,
	Divider,
	InputAdornment,
	OutlinedInput,
	ListItemText,
	Link,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	TextField,
	Typography,
	makeStyles,
	FormControl,
	InputLabel,
	Select,
	IconButton,
	MenuItem,
} from "@material-ui/core";
import {
	ArrowRight as ArrowRightIcon,
	Search as SearchIcon,
} from "react-feather";
import getInitials from "src/utils/getInitials";
import moment from "moment";
import * as SystemAdminApi from "../../api/SystemAdminApi";
import DatePicker from "./DatePicker";
import {useSelector} from "react-redux";

function applyFilters(campaigns, query, filters) {
	return campaigns.filter((campaign) => {
		let matches = true;

		if (query) {
			const properties = ["name"];
			let containsQuery = false;

			properties.forEach((property) => {
				if (campaign[property].toLowerCase().includes(query.toLowerCase())) {
					containsQuery = true;
				}
			});

			if (!containsQuery) {
				matches = false;
			}
		}

		Object.keys(filters).forEach((key) => {
			const value = filters[key];

			if (value && campaign[key] !== value) {
				matches = false;
			}
		});

		return matches;
	});
}

function applyPagination(campaigns, page, limit) {
	return campaigns.slice(page * limit, page * limit + limit);
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}

	if (b[orderBy] > a[orderBy]) {
		return 1;
	}

	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function applySort(campaigns, sort) {
	const [orderBy, order] = sort.split("|");
	const comparator = getComparator(order, orderBy);
	const stabilizedThis = campaigns.map((el, index) => [el, index]);

	stabilizedThis.sort((a, b) => {
		// eslint-disable-next-line no-shadow
		const order = comparator(a[0], b[0]);

		if (order !== 0) return order;

		return a[1] - b[1];
	});

	return stabilizedThis.map((el) => el[0]);
}

function exportToCsv(dateFrom, dateTo) {
	return SystemAdminApi.exportToCsv(dateFrom, dateTo).then(response => {
		const blob = new Blob([response.data], {
			type: 'application/csv'
		});

		let url = window.URL.createObjectURL(blob);
		let a = document.createElement('a');
		a.href = url;
		a.download = 'consultancies.csv';
		a.click();
	});
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	queryField: {
		width: 500,
	},
	bulkOperations: {
		position: "relative",
	},
	bulkActions: {
		paddingLeft: 4,
		paddingRight: 4,
		marginTop: 6,
		position: "absolute",
		width: "100%",
		zIndex: 2,
		backgroundColor: theme.palette.background.default,
	},
	bulkAction: {
		marginLeft: theme.spacing(2),
	},
	avatar: {
		height: 42,
		width: 42,
		marginRight: theme.spacing(1),
	},
	formControl: {
		marginBottom: "10px",
	},
}));

function Results({ className, campaigns, consultancies, ...rest }) {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [limit, setLimit] = useState(12);
	const [query, setQuery] = useState("");
	const [open, setOpen] = useState(false);
	const [openExport, setOpenExport] = useState(false);
	const [t] = useTranslation(["campaigns", "common", "snackbar"]);
	const dateFrom = useSelector((state) => state.consultancy.dateFrom);
	const dateTo = useSelector((state) => state.consultancy.dateTo);

	const selectedUsers = [];

	const sortOptions = [
		{
			value: "createdAt|desc",
			label: `${t("common:createdAt")} (${t("common:latest")})`,
		},
		{
			value: "createdAt|asc",
			label: `${t("common:createdAt")} (${t("common:oldest")})`,
		},
		{
			value: "name|asc",
			label: `${t("common:campaign")} ${t("common:name")} (A - Z)`,
		},
		{
			value: "name|desc",
			label: `${t("common:campaign")} ${t("common:name")} (Z - A)`,
		},
		{
			value: "consultancyName|asc",
			label: `${t("common:consulconsultancyLabeltancy")} ${t(
				"common:name"
			)} (A - Z)`,
		},
		{
			value: "consultancyName|desc",
			label: `${t("common:consulconsultancyLabeltancy")} ${t(
				"common:name"
			)} (Z - A)`,
		},
	];

	const [sort, setSort] = useState(sortOptions[0].value);

	const [filters, setFilters] = useState({
		consultancyId: null,
	});

	const handleFilterChange = (event) => {
		setFilters((prevValues) => ({
			...prevValues,
			[event.target.name]: event.target.value,
		}));
	};
	const handleFilterReset = (event) => {
		setFilters({
			consultancyId: null,
		});
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClickOpenExport = () => {
		setOpenExport(true);
	}

	const handleClose = () => {
		setOpen(false);
	};

	const handleExportClose = () => {
		setOpenExport(false);
	};

	const handleExportToCsv = () => {
		exportToCsv(dateFrom, dateTo);
	}

	const handleQueryChange = (event) => {
		event.persist();
		setQuery(event.target.value);
	};

	const handleSortChange = (event) => {
		event.persist();
		setSort(event.target.value);
	};

	const handlePageChange = (event, newPage) => {
		setPage(newPage);
	};

	const handleLimitChange = (event) => {
		setLimit(event.target.value);
	};

	const goToUserDetails = (consultancyId, campaignId) => {
		return `/dashboard/consultancy/${consultancyId}/campaigns/${campaignId}`;
	};

	const filteredUsers = applyFilters(campaigns, query, filters);
	const sortedUsers = applySort(filteredUsers, sort);
	const paginatedUsers = applyPagination(sortedUsers, page, limit);

	return (
		<>
			<Card className={clsx(classes.root, className)} {...rest}>
				<Divider />
				<Box p={2} minHeight={56} display="flex" alignItems="center">
					<TextField
						className={classes.queryField}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SvgIcon fontSize="small" color="action">
										<SearchIcon />
									</SvgIcon>
								</InputAdornment>
							),
						}}
						onChange={handleQueryChange}
						placeholder={t("common:searchCampaigns")}
						value={query}
						variant="outlined"
					/>

					<Box flexGrow={1} />
					<IconButton
						color="primary"
						style={{ margin: "0px 20px" }}
						onClick={handleClickOpenExport}
					>
						<GetApp style={{ fontSize: "32px" }} />
						<Typography variant="h3" style={{ fontWeight: "500" }}>
							{t("common:exportToCsv")}
						</Typography>
					</IconButton>
					<IconButton
						color="primary"
						style={{ margin: "0px 20px" }}
						onClick={handleClickOpen}
					>
						<FilterListIcon style={{ fontSize: "32px" }} />
						<Typography variant="h3" style={{ fontWeight: "500" }}>
							{t("common:filterCampaigns")}
						</Typography>
					</IconButton>
					<TextField
						label="Sort By"
						name="sort"
						onChange={handleSortChange}
						select
						SelectProps={{ native: true }}
						value={sort}
						variant="outlined"
					>
						{sortOptions.map((option) => (
							<option key={option.value} value={option.value}>
								{option.label}
							</option>
						))}
					</TextField>
				</Box>
				<PerfectScrollbar>
					<Box minWidth={700}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>{t("common:name")}</TableCell>
									<TableCell>{t("common:candidates")}</TableCell>
									<TableCell>{t("common:contacts")}</TableCell>
									<TableCell>{t("common:consultancyLabel")}</TableCell>
									<TableCell>{t("common:dateCreated")}</TableCell>
									<TableCell>{t("common:status")}</TableCell>
									<TableCell align="right">{t("common:actions")}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{paginatedUsers.map((campaign) => {
									const isUserSelected = selectedUsers.includes(campaign.id);
									return (
										<TableRow hover key={campaign.id} selected={isUserSelected}>
											<TableCell>
												<Box display="flex" alignItems="center">
													<Avatar
														className={classes.avatar}
														src={campaign.avatar}
													>
														{getInitials(campaign.name)}
													</Avatar>
													<div>
														<Link
															color="inherit"
															component={RouterLink}
															to={goToUserDetails(
																campaign.consultancyId,
																campaign.id
															)}
															variant="h5"
														>
															{campaign.name}
														</Link>
														<Typography variant="body2" color="textSecondary">
															{campaign.email}
														</Typography>
													</div>
												</Box>
											</TableCell>
											<TableCell>{campaign.candidates}</TableCell>
											<TableCell>{campaign.contacts}</TableCell>
											<TableCell>{campaign.consultancyName}</TableCell>
											<TableCell>
												{moment(campaign.createdAt).format("DD/MM/YYYY")}
											</TableCell>
											<TableCell>{campaign.status}</TableCell>
											<TableCell align="right">
												<IconButton
													component={RouterLink}
													to={goToUserDetails(
														campaign.consultancyId,
														campaign.id
													)}
												>
													<SvgIcon fontSize="small">
														<ArrowRightIcon />
													</SvgIcon>
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
						<TablePagination
							component="div"
							count={filteredUsers.length}
							onChangePage={handlePageChange}
							onChangeRowsPerPage={handleLimitChange}
							page={page}
							rowsPerPage={limit}
							rowsPerPageOptions={[12, 24, 36, 48]}
							labelRowsPerPage={t("common:rowsPerPage")}
							labelDisplayedRows={({ from, to, count }) =>
								`${from}-${to} ${t("common:of")} ${count}`
							}
						/>
					</Box>
				</PerfectScrollbar>
			</Card>

			<ModalFormWrapper
				formTitle={t("common:filterCampaigns")}
				handleOpen={open}
				handleClose={handleClose}
				maxWidth="xs"
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							fullWidth
							className={classes.formControl}
						>
							<InputLabel
								id="contactLabel"
								style={{ backgroundColor: "white" }}
							>
								{t("campaigns:selectConsultancy")}
							</InputLabel>
							<Select
								labelId="contactLabel"
								name={"consultancyId"}
								value={filters.consultancyId}
								onChange={handleFilterChange}
								renderValue={(selected) =>
									consultancies.find(
										(consultancy) => consultancy.id === selected
									).name
								}
								input={<OutlinedInput />}
							>
								{consultancies?.map((consultancy) => (
									<MenuItem key={consultancy.id} value={consultancy.id}>
										<Checkbox
											checked={filters.consultancyId === consultancy.id}
										></Checkbox>
										<ListItemText primary={consultancy.name}></ListItemText>
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<div style={{ textAlign: "center" }}>
							<Button
								color="primary"
								size="large"
								variant="contained"
								onClick={handleFilterReset}
							>
								{t("common:resetFilters")}
							</Button>
						</div>
					</Grid>
				</Grid>
			</ModalFormWrapper>

			<ModalFormWrapper
				formTitle={t("common:exportToCsv")}
				handleOpen={openExport}
				handleClose={handleExportClose}
				maxWidth="xs"
			>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<FormControl
							variant="outlined"
							fullWidth
							className={classes.formControl}
						>
							<DatePicker/>
						</FormControl>
						<div style={{ textAlign: "center" }}>
							<Button
								color="primary"
								size="large"
								variant="contained"
								onClick={handleExportToCsv}
							>
								{t("common:export")}
							</Button>
						</div>
					</Grid>
				</Grid>
			</ModalFormWrapper>
		</>
	);
}

Results.propTypes = {
	className: PropTypes.string,
	campaigns: PropTypes.array,
	consultancies: PropTypes.array,
};

Results.defaultProps = {
	campaigns: [],
	consultancies: [],
};

export default Results;
