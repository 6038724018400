import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Divider,
	makeStyles,
} from "@material-ui/core";
import Chart from "./Chart";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
	root: {},
	chart: {
		height: "100%",
	},
}));

function CampaignsPerMonth({ className, campaignsPerMonth }) {
	const [t] = useTranslation(["campaigns", "common"]);
	const classes = useStyles();
	const performance = {
		dataSet: {
			data: campaignsPerMonth,
			labels: [
				t("common:janShortLabel"),
				t("common:febShortLabel"),
				t("common:marShortLabel"),
				t("common:aprShortLabel"),
				t("common:mayShortLabel"),
				t("common:junShortLabel"),
				t("common:julShortLabel"),
				t("common:augShortLabel"),
				t("common:sepShortLabel"),
				t("common:octShortLabel"),
				t("common:novShortLabel"),
				t("common:decShortLabel"),
			],
		},
	};

	return (
		<Card className={clsx(classes.root, className)}>
			<CardHeader variant="h4" title={t("campaigns:campaignsPerMonthLabel")} />
			<Divider />
			<CardContent>
				<PerfectScrollbar>
					<Box height={375} minWidth={500}>
						<Chart
							className={classes.chart}
							data={performance.dataSet.data}
							labels={performance.dataSet.labels}
						/>
					</Box>
				</PerfectScrollbar>
			</CardContent>
		</Card>
	);
}

CampaignsPerMonth.propTypes = {
	className: PropTypes.string,
};

export default CampaignsPerMonth;
