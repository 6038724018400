import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SaveTextButton = ({ btnFunc, btnDisabled }) => {
  const [t] = useTranslation([
    'sysadmin',
    'common',
    'snackbar',
    'languages',
    'emails',
  ]);
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={btnFunc}
      disabled={btnDisabled ? true : false}
    >
      {t('common:save')}
    </Button>
  );
};

export default SaveTextButton;
