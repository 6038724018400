import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { DraggableTypes } from '../../utils/draggableTypes';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd';
import { makeStyles } from '@material-ui/styles';
import { IconButton, CircularProgress } from '@material-ui/core';
import { deleteAsset } from '../../api/assetApi';
import FileTile from './FileTile';
import DeleteIcon from '@material-ui/icons/Delete';

function getStyles(isDragging) {
  return {
    opacity: isDragging ? 0 : 1,
    height: isDragging ? 0 : '',
  };
}
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    cursor: 'grab',
  },
  dragBox: {
    borderTopColor: 'lightgrey',
    borderTopWidth: 'thin',
    borderTopStyle: 'solid',
  },
  binButton: {
    margin: 'auto',
    padding: 0,
  },
  progressCircle: {
    margin: 'auto',
    padding: 0,
  },
}));

const DraggableFileTile = ({ id, asset, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['common', 'storage', 'validation']);
  const { enqueueSnackbar } = useSnackbar();

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: DraggableTypes.FILE, asset },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const handleDelete = () => {
    setLoading(true);
    return deleteAsset(asset.id)
      .then(success => {
        if (success) {
          enqueueSnackbar(t('validation:assetDeletionSuccess'), {
            variant: 'success',
          });
          rest.setAssets(prevAssets =>
            prevAssets.filter(prevAsset => asset.id !== prevAsset.id)
          );
        } else {
          throw Error('Network Error');
        }
      })
      .catch(error => {
        enqueueSnackbar(
          `${t(`validation:${error.message}`)}${
            error.location ? ': ' + error.location : ''
          }`,
          { variant: 'error' }
        );
        console.error(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} style={getStyles(isDragging)}>
      <div ref={drag} className={classes.dragBox}>
        <FileTile asset={asset} />
      </div>
      {loading ? (
        <CircularProgress className={classes.progressCircle} size={25} />
      ) : (
        <IconButton
          className={classes.binButton}
          color="inherit"
          onClick={handleDelete}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

DraggableFileTile.propTypes = {};

export default DraggableFileTile;
