import { Card, CardContent, Grid, useTheme } from "@material-ui/core";
import React from "react";
import Chart from "react-apexcharts";
import { BlockHeader } from "./LayoutComponents/BlockHeader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CONTACT } from "src/constants/roles";

function BarChart({ title, categories, series }) {
  const [t] = useTranslation(["tooltips"]);
  const theme = useTheme();
  //example series: [{name: 'Reece Courtney', data:[10,10,10]},{name: 'Contact One', data: [2,3,4]}]
  //example categories: ['Behaviour','Video Interview','Key Competency Questions']
  const data = {
    options: {
      chart: {
        background: theme.palette.background.paper,
        toolbar: {
          show: false,
        },
      },
      colors: ["#32a873", "#3250a8", "#a8323e", "#d9a82b"],
      dataLabels: {
        enabled: false,
      },
      grid: {
        borderColor: theme.palette.divider,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: true,
        labels: {
          colors: theme.palette.text.secondary,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      theme: {
        mode: theme.palette.type,
      },
      tooltip: {
        theme: theme.palette.type,
      },
      xaxis: {
        axisBorder: {
          show: true,
          color: theme.palette.divider,
        },
        axisTicks: {
          show: true,
          color: theme.palette.divider,
        },
        categories: categories,
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
      yaxis: {
        max: 100,
        axisBorder: {
          show: true,
          color: theme.palette.divider,
        },
        axisTicks: {
          show: true,
          color: theme.palette.divider,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
    },
    series: series,
  };

  const roleType = useSelector((state) => state.session.roleType);
  const adminContactPreviewMode = useSelector(
    (state) => state?.campaign?.adminContactPreview
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <BlockHeader
              headerTooltip={
                roleType === CONTACT || adminContactPreviewMode
                  ? null
                  : t("tooltips:suitabilityChartOverview")
              }
              card
              header={title}
            />
            <Chart
              options={data.options}
              series={data.series}
              type="bar"
              height="300"
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default BarChart;
