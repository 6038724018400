import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Divider, Grid, Typography } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { makeStyles } from '@material-ui/core/styles';

import { getUserNotificationSettings } from './../../../api/notificationApi';

import ToggleButton from './ToggleButton';

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../../utils/useSubscriptionPrefix';

const notificationTableTypes = {
  EMAIL_NOTIFICATION_IDS: 0,
  NOTIFICATION_IDS: 1
};

const useStyles = makeStyles({
  icons: {
    fontSize: 30
  },
  row: {
    minHeight: '60px',
    padding: '10px'
  }
});

const NotificationsTable = ({ notificationTypes }) => {

  const [t] = useTranslation(['notifications', 'vacancyNotifications']);

  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [emailNotificationIds, setEmailNotificationIds] = useState([]);
  const [notificationIds, setNotificationIds] = useState([]);

  useEffect(() => {
    getUserNotificationSettings()
      .then(response => {
        setEmailNotificationIds(response.data[0].emailNotificationIds);
        setNotificationIds(response.data[0].notificationIds);
        setIsLoading(false);
      });
  }, []);

  const notificationDisabledByUser = (notificationTableType, notificationId) => {
    const notifications = notificationTableType === notificationTableTypes.EMAIL_NOTIFICATION_IDS
      ? [...emailNotificationIds] : [...notificationIds];

    if (notifications?.length) {
      return notifications.includes(notificationId);
    }

    return false;
  };

  return (
    <Grid container spacing={ 1 } >
      {
        isLoading ? (
          <>
            <CircularProgress />
          </>
        ) : (
          <>
            <Grid container spacing={ 1 } alignItems="center" className={ classes.row }>
              <Grid item xs={ 6 } sm={ 8 } md={ 10 } style={{ color: "grey" }}>
                <Typography> { t(`${notificationsPrefix}:notificationLabel`) }</Typography>
              </Grid>

              <Grid item xs={ 3 } sm={ 2 } md={ 1 } align="center">
                <EmailIcon className={ classes.icons } color="disabled" />
              </Grid>

              <Grid item xs={ 3 } sm={ 2 } md={ 1 } align="center">
                <NotificationsActiveIcon className={ classes.icons } color="disabled" />
              </Grid>

              <Grid item xs={ 12 }>
                <Divider light />
              </Grid>
            </Grid>

            {
              notificationTypes.map((notification, index) => (
                <Grid key={ `${index} ${notification.id}` } container>
                  <Grid
                    className={ classes.row }
                    container
                    spacing={ 1 }
                    alignItems="center" >
                    <Grid item xs={ 6 } sm={ 8 } md={ 10 }>
                      <Typography>
                        { t(`${notificationsPrefix}:${notification.translationKey}` )}
                      </Typography>
                    </Grid>

                    <Grid item xs={ 3 } sm={ 2 } md={ 1 } align="center">
                      <ToggleButton
                        settings="emails"
                        notificationId={ notification.id }
                        disabled={ notificationDisabledByUser(notificationTableTypes.EMAIL_NOTIFICATION_IDS, notification.id) } />
                    </Grid>

                    <Grid item xs={ 3 } sm={ 2 } md={ 1 } align="center">
                      <ToggleButton
                        settings="notifications"
                        notificationId={ notification.id }
                        disabled={ notificationDisabledByUser(notificationTableTypes.NOTIFICATION_IDS, notification.id) } />
                    </Grid>
                  </Grid>

                  <Grid key={ index } container spacing={ 1 }>
                    <Grid item xs={ 12 }>
                      <Divider light />
                    </Grid>
                  </Grid>
                </Grid>
              ))
            }
          </>
        )
      }
    </Grid>
  );

};

export default NotificationsTable;
