import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '80px',
    backgroundColor: 'white',
    height: '400px',
  },
}));

function QuillEditor({ value, handleChange, toolbarOptions, disabled }) {
  const [t] = useTranslation(['common']);
  const classes = useStyles();

  const [currentValue, setCurrentValue] = useState(value);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [
        {
          color: [
            '#000000',
            '#e60000',
            '#ff9900',
            '#ffff00',
            '#008a00',
            '#0066cc',
            '#9933ff',
            '#ffffff',
            '#facccc',
            '#ffebcc',
            '#ffffcc',
            '#cce8cc',
            '#cce0f5',
            '#ebd6ff',
            '#bbbbbb',
            '#f06666',
            '#ffc266',
            '#ffff66',
            '#66b966',
            '#66a3e0',
            '#c285ff',
            '#888888',
            '#a10000',
            '#b26b00',
            '#b2b200',
            '#006100',
            '#0047b2',
            '#6b24b2',
            '#444444',
            '#5c0000',
            '#663d00',
            '#666600',
            '#003700',
            '#002966',
            '#3d1466',
            'custom-color',
          ],
        },
      ],
      ['bold', 'italic', 'underline'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-2' },
        { indent: '+2' },
      ],
      [{ align: [] }],
      [...toolbarOptions],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  };
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'align',
    'color',
  ];

  const onChange = (newValue) => {
    setCurrentValue(newValue);
    handleChange(newValue);
  };

  return (
    <>
      <ReactQuill
        value={ currentValue }
        onChange={ onChange }
        modules={modules}
        formats={formats}
        className={classes.root}
        readOnly={disabled}
        placeholder={ t('common:pleaseFillInDetailsHere') }
      />
    </>
  );
}
export default QuillEditor;
