import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

import * as candidateRequiredDocumentTypeIds from './../../../../constants/candidateRequiredDocumentTypeIds';

const DocumentTypeRadioButtons = ({ index, active, documentType, onChange }) => {

  const [t] = useTranslation(['campaigns'])

  const [currentDocumentType, setCurrentDocumentType] = useState(documentType ? documentType : candidateRequiredDocumentTypeIds.DOCUMENT);

  const handleChange = (event) => {
    setCurrentDocumentType(Number(event.target.value));

    if (index > -1) {
      onChange(index, event.target.value);
      return;
    }

    onChange(event.target.value);
  };

  return (
    <>
      <FormControl>
        <RadioGroup
          row
          value={ currentDocumentType }
          onChange={ handleChange } >
          <FormControlLabel
            label={ t(`campaigns:campaignDocumentTypeSelection${candidateRequiredDocumentTypeIds.DOCUMENT}`) }
            value={ candidateRequiredDocumentTypeIds.DOCUMENT }
            control={ <Radio color="primary" /> }
            disabled={ !active } />

          <FormControlLabel
            label={ t(`campaigns:campaignDocumentTypeSelection${candidateRequiredDocumentTypeIds.CV}`) }
            value={ candidateRequiredDocumentTypeIds.CV }
            control={ <Radio color="primary" /> }
            disabled={ !active } />
        </RadioGroup>
      </FormControl>
    </>
  );

};

export default DocumentTypeRadioButtons;
