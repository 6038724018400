import React from 'react';
import RequiredDocumentListCard from './RequiredDocumentListCard';
import { REQUIRED_CANDIDATE_DOCUMENTS_CONTAINER_ID } from 'src/constants/dragDropContainerIds';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  documentList: {
    marginBottom: theme.spacing(4),
  },

  documentsOrderButton: {
    marginTop: 20,
  },
}));
const DraggableRequiredCandidateDocuments = ({
  onDragEnd,
  requiredDocuments,
  handleRemove,
  setEditDocument,
  setAddDocumentModalOpen,
  setTextModal,
  consultancyId,
  candidateView,
  isSubmitted,
  saveDocumentsOrder,
  guestView,
}) => {
  const [t] = useTranslation(['campaigns', 'common', 'snackbar']);
  const classes = useStyles();
  return (
    <div className={classes.documentList}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={REQUIRED_CANDIDATE_DOCUMENTS_CONTAINER_ID}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {requiredDocuments?.length > 0 &&
                requiredDocuments.map((document, index) => {
                  return (
                    <Draggable draggableId={document.id} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <RequiredDocumentListCard
                            key={index.toString()}
                            document={document}
                            onRemove={handleRemove}
                            setMethods={{
                              setEditDocument,
                              setAddDocumentModalOpen,
                              setTextModal,
                            }}
                            consultancyId={consultancyId}
                            candidateView={candidateView}
                            isSubmitted={isSubmitted}
                            guestView={guestView}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={classes.documentsOrderButton}
        color='primary'
        size='large'
        onClick={saveDocumentsOrder}
        variant='contained'
        fullWidth
      >
        {t('campaigns:saveDocumentsOrder')}
      </Button>
    </div>
  );
};

export default DraggableRequiredCandidateDocuments;
