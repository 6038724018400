import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
	saveDefaultCampaignDocument,
	removeDefaultCampaignDocument,
} from "src/api/SystemAdminApi";
import { useSnackbar } from "notistack";
import DocumentList from "src/components/DocumentList";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	subHeader: {
		marginTop: theme.spacing(2),
	},
}));

const DefaultCampaignDocuments = ({ campaignDocuments }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(["sysadmin", "common", "snackbar"]);
	const classes = useStyles();
	const [documents, setDocuments] = useState([]);

	const saveDocument = (document, index) => {
		return saveDefaultCampaignDocument(document)
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar(t("snackbar:savedDefaultDocument"), {
						variant: "success",
					});
					let tempDocs = documents;
					tempDocs[index] = response.data;
					setDocuments([...tempDocs]);
				} else
					enqueueSnackbar(t("snackbar:errorDefaultDocument"), {
						variant: "error",
					});
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(t("snackbar:errorDefaultDocument"), {
					variant: "error",
				});
			});
	};

	const newDocument = () => {
		setDocuments((prevDocuments) => [
			...prevDocuments,
			{
				name: "",
				visibility: "private",
			},
		]);
	};

	const removeDocument = (document, index) => {
		if (document.id) {
			removeDefaultCampaignDocument(document.id)
				.then((response) => {
					if (response.status === 200) {
						let tempDocs = documents;
						tempDocs.splice(index, 1);
						setDocuments([...tempDocs]);
						enqueueSnackbar(t("snackbar:deletedDocument"), {
							variant: "success",
						});
					} else
						enqueueSnackbar(t("snackbar:errorDeletingDocument"), {
							variant: "error",
						});
				})
				.catch((error) => {
					console.error(error);
					enqueueSnackbar(t("snackbar:errorDeletingDocument"), {
						variant: "error",
					});
				});
		} else {
			let tempDocs = documents;
			tempDocs.splice(index, 1);
			setDocuments([...tempDocs]);
			enqueueSnackbar(t("snackbar:deletedDocument"), {
				variant: "success",
			});
		}
	};

	const handleChange = (event, index) => {
		event.persist();
		let tempDocs = documents;
		tempDocs[index].name = event.target.value;
		setDocuments([...tempDocs]);
	};

	const handleVisibilityChange = (state) => {
		let { id, visibility } = state;
		let index = id?.split("_")[0];
		let tempDocs = documents;
		if (index) tempDocs[index].visibility = visibility;
		setDocuments([...tempDocs]);
	};

	useEffect(() => {
		setDocuments(campaignDocuments);
	}, [campaignDocuments]);

	return (
		<Card>
			<CardContent>
				<Typography className={classes.header} spacing={1} variant="h3">
					{t("sysadmin:defaultCampaignDocuments")}
				</Typography>
				<Typography className={classes.header} spacing={2} variant="h5">
					{t("sysadmin:campaignDocumentsDescription")}
				</Typography>
				<DocumentList
					documents={documents}
					functions={{
						handleChange,
						handleVisibilityChange,
						onNew: newDocument,
						onSave: saveDocument,
						removeDocument
					}}
				/>
			</CardContent>
		</Card>
	);
};

export default DefaultCampaignDocuments;
