/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router";
import validate from "validate.js";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Button, TextField, Box, CardActions } from "@material-ui/core";
import * as SessionApi from "src/api/sessionApi";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const schema = {
	email: {
		presence: { allowEmpty: false, message: "is required" },
		email: true,
	},
};

const useStyles = makeStyles((theme) => ({
	root: {},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
	cardTitle: {
		padding: "20px",
	},
	submitButton: {
		width: "50%",
		height: "3rem",
		margin: "1rem auto 0.5rem",
		borderRadius: 5,
	},
}));

function ForgottenPasswordForm({
	toggleForgottenPassword,
	buttonColour,
	consultancyId,
	className,
	...rest
}) {
	const classes = useStyles();
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();
	const [t, i18n] = useTranslation(["snackbar"]);
	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		return SessionApi.sendPasswordResetEmail({
			email: formState.values.email,
			host: window.location.hostname,
		})
			.then(() => {
				enqueueSnackbar(`${t("snackbar:passwordResetEmailSent")}`, {
					variant: "success",
				});
				toggleForgottenPassword();
			})
			.catch((error) => {
				enqueueSnackbar(`${t(`snackbar:${error.response.data.error.code}`)}`, {
					variant: "error",
				});
			});
	};

	const hasError = (field) => {
		if (formState.touched[field] && formState.errors[field]) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			const errors = validate(formState.values, schema);
			setFormState((prevFormState) => ({
				...prevFormState,
				isValid: !errors,
				errors: errors || {},
			}));
		}
		return () => {
			mounted = false;
		};
	}, [formState.values]);

	return (
		<form
			{...rest}
			className={clsx(classes.root, className)}
			onSubmit={handleSubmit}
		>
			<div className={classes.fields}>
				<TextField
					error={hasError("email")}
					fullWidth
					helperText={hasError("email") ? formState.errors.email[0] : null}
					label={t("common:emailAddressLabel")}
					name="email"
					onChange={handleChange}
					value={formState.values.email || ""}
					variant="outlined"
				/>
			</div>
			<Box display="flex" flexDirection="column" justifyContent="center">
				<Button
					className={classes.submitButton}
					disabled={!formState.isValid}
					size="large"
					type="submit"
					variant="contained"
					style={{
						backgroundColor: buttonColour,
						color: theme.palette.getContrastText(
							buttonColour ? buttonColour : theme.palette.primary.main
						),
						marginTop: "88px",
					}}
				>
					{t("common:resetPassword")}
				</Button>
				<Button
					variant="body1"
					color="primary"
					startIcon={<KeyboardBackspaceIcon />}
					onClick={toggleForgottenPassword}
				>
					{t("common:goBackToLogin")}
				</Button>
			</Box>
		</form>
	);
}

ForgottenPasswordForm.propTypes = {
	className: PropTypes.string,
};

export default ForgottenPasswordForm;
