import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Card,
  Grid,
  useMediaQuery,
  AppBar,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import * as CampaignApi from 'src/api/campaignApi';
import CreateCampaignModal from './CreateCampaignModal';
import { useTranslation } from 'react-i18next';
import { ContentWrapper } from 'src/components/LayoutComponents/ContentWrapper';
import { TabPanel } from 'src/components/TabPanel';
import { useSnackbar } from 'notistack';
import { Searchbar, useSearch } from 'src/components/Filter/Search';
import { getConsultancyLogo } from 'src/api/consultancyApi';
import { Paginator, usePagination } from 'src/components/Filter/Pagination';
import { PageTitle } from 'src/components/LayoutComponents/PageTitle';
import { SelectMenu } from 'src/components/Filter/Select';
import { CampaignCard } from 'src/components/Cards/CampaignCard';
import * as actionTypes from 'src/actions';
import Page from 'src/components/Page';
import CustomTabs from './../../../components/LayoutComponents/CustomTabs';

import * as systemAdminApi from './../../../api/SystemAdminApi';
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    zIndex: 1,
  },
  filterCard: {
    marginBottom: theme.spacing(3),
    width: '100%',
    padding: 0,
  },
  filterCard_box: {
    padding: 0,
  },
  sort: {
    width: 260,
    marginRight: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  filterWrapper: {
    marginRight: '20px',
    width: '25%'
  }
}));

function Campaigns({ ...props }) {
  const classes = useStyles();
  const [t] = useTranslation(["campaigns", "common", "vacancies"]);
  const [open, setOpen] = useState(false);
  const [myCampaigns, setMyCampaigns] = useState([]);
  const [groupCampaigns, setGroupCampaigns] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const consultancyId = useSelector(
    (state) => props.consultancyId || state.session.consultancyId
  );
  const userRole = useSelector((state) => state.session.roleType);
  const consultancySubscriptionPlanId = useSelector(state => state.session.consultancySubscriptionPlanId);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [showItems, setShowItems] = useState(10);
  const [consultancyLogo, setConsultancyLogo] = useState("");
  const [countries, setCountries] = useState([]);
  const [consultancyUsers, setConsultancyUsers] = useState([]);
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    country: [],
    consultancyUsers: []
  });

  const consultancyName = useSelector((state) => state.session.consultancyName);
  const desktop = useMediaQuery("(min-width:960px)");
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const sortOptions = [
    {
      value: "createdAt|desc",
      label: `${t("common:createdAt")} (${t("common:latest")})`,
    },
    {
      value: "createdAt|asc",
      label: `${t("common:createdAt")} (${t("common:oldest")})`,
    },
    {
      value: "campaignTitle|asc",
      label: `${t(`${campaignsPrefix}:campaignTitle`)} (A - Z)`,
    },
    {
      value: "campaignTitle|desc",
      label: `${t(`${campaignsPrefix}:campaignTitle`)} (Z - A)`,
    },
  ];

  const [sortOrder, setSortOrder] = useState(sortOptions[0].value);

  const tabs = [
    { value: 0, label: t(`${campaignsPrefix}:myCampaigns`) },
    { value: 1, label: t(`${campaignsPrefix}:groupCampaigns`) }
  ];

  if (userRole === "Admin") {
    tabs.push({ value: 2, label: t(`${campaignsPrefix}:allCampaigns`) });
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  const handleInputChange = (newValue) => {
    setSearchTerm(newValue);
  };

  const handleSortItems = (event) => {
    event.persist();
    setSortOrder(event.target.value);
  };

  const handleShowItems = (newValue) => {
    setShowItems(newValue);
  };

  const handlePageChange = (newValue) => {
    jump(newValue.selected + 1);
  };

  const getCampaigns = (consultancyId) => {
    dispatch({ type: actionTypes.CANCEL_ADMIN_CONTACT_PREVIEW });
    if (props.consultancyId) {
      return CampaignApi.getChildCampaigns(consultancyId).then((response) => {
        setMyCampaigns(response.data);
      });
    } else {
      return CampaignApi.getCampaigns(consultancyId)
        .then((response) => {
          setMyCampaigns(response.data.myCampaigns);
          setGroupCampaigns(response.data.groupCampaigns);
          setAllCampaigns(response.data.allCampaigns);

          let countries = response.data.allCampaigns.map((country) => {
            return country.assignmentCampaign.address?.country.name;
          });

          let filtered = countries.filter(
            (item, index) => countries.indexOf(item) === index
          );
          setCountries(filtered);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const fetchConsultancyLogo = (consultancyId) => {
    return getConsultancyLogo(consultancyId)
      .then((response) => {
        setConsultancyLogo(response.data.consultancyLogo);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getConsultancyUsers = (consultancyId) => {
    systemAdminApi.getConsultancyUsers(consultancyId)
      .then((response) => {
        const sortedConsultancyUsers = [...response.data.usersAndGroups.admins, ...response.data.usersAndGroups.consultants]
          .map((user, index) => {
            if (index < response.data.usersAndGroups.admins.length) {
              return {
                ...user,
                role: 'admin'
              };
            }

            return {
              ...user,
              role: 'consultant'
            };
          })
          .sort((userA, userB) => {
            if (userA.name > userB.name) return 1;
            if (userA.name < userB.name) return -1;
            return 0;
          });

        setConsultancyUsers(sortedConsultancyUsers);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCampaigns(consultancyId);
      fetchConsultancyLogo(consultancyId);
      getConsultancyUsers(consultancyId);
    }
    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId]);

  const onSuccess = (campaignDetails) => {
    campaignDetails.consultancyId = consultancyId;
    return CampaignApi.createAssignmentCampaign(campaignDetails)
      .then((response) => {
        setMyCampaigns((prevMyCampaigns) => [
          ...prevMyCampaigns,
          response.data,
        ]);
        if (!response.data.private) {
          setGroupCampaigns((prevGroupCampaigns) => [
            ...prevGroupCampaigns,
            response.data,
          ]);
        }
        enqueueSnackbar(t(`${campaignsPrefix}:campaignCreated`), {
          variant: "success",
        });

        const url = consultancySubscriptionPlanId === TA_PRO ? `/dashboard/vacancies/${response.data.id}` : `/dashboard/campaigns/${response.data.id}`
        history.push(url);
      })
      .catch((error) => {
        if (error.response.data.error.code === "ERR_CONTACT_EXISTS") {
          enqueueSnackbar(t(`${campaignsPrefix}:contactExsists`), {
            variant: "error",
          });
          throw error;
        } else {
          enqueueSnackbar(t(`${campaignsPrefix}:errorCampaignCreation`), {
            variant: "error",
          });
        }
      });
  };

  const goToCampaignDetails = (event, campaignId) => {
    event.preventDefault();
    if (props.guestView) {
      history.push(
        `/dashboard/consultancy/${consultancyId}/${campaignsPrefix}/${campaignId}`
      );
    } else {
      history.push(`/dashboard/${campaignsPrefix}/${campaignId}`);
    }
  };

  const descendingComparator = (a, b, orderBy) => {
    if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
      if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) return -1;
      if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) return 1;
    } else {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
    }

    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  function applySort(data, sortOrder) {
    const [orderBy, order] = sortOrder.split("|");
    const comparator = getComparator(order, orderBy);
    let stabilizedThis = data?.map((el, index) => [el, index]) || [];

    stabilizedThis.sort((a, b) => {
      // eslint-disable-next-line no-shadow
      const order = comparator(a[0], b[0]);

      if (order !== 0) return order;

      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => el[0]);
  }

  let myCampaignsFiltered = useSearch(
    applySort(myCampaigns, sortOrder, "campaignTitle"),
    searchTerm,
    ["campaignTitle"]
  ).searchResults();

  let groupCampaignsFiltered = useSearch(
    applySort(groupCampaigns, sortOrder),
    searchTerm,
    ["campaignTitle"]
  ).searchResults();

  let allCampaignsFiltered = useSearch(
    applySort(allCampaigns, sortOrder),
    searchTerm,
    ["campaignTitle"]
  ).searchResults();

  const filteredCampaigns = applyFilters(
    currentTab === 0
      ? myCampaignsFiltered
      : currentTab === 1
      ? groupCampaignsFiltered
      : allCampaignsFiltered,
    filters
  );

  const { currentData, jump, maxPage } = usePagination(
    filteredCampaigns,
    showItems
  );

  useEffect(() => {
    jump(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  let sortedData = currentData();

  function createData(name, data) {
    return { name, data };
  }

  const getCampaingOwnerName = (owner) =>
    owner
      ? ` ${owner?.profile?.salutation ? owner.profile.salutation : ""} ${
          owner?.profile?.firstName
        } ${owner?.profile?.lastName} `
      : "TBC";

  const getOwner = (admin, consultant, managedByRole) =>
    consultant && managedByRole === "consultant" ? consultant : admin;

  const getCampaignDetails = (campaign) => {
    const employerName = campaign?.assignmentCampaign?.employer?.employerName
      ? campaign.assignmentCampaign.employer.employerName
      : "";
    const campaignStatus = campaign?.campaignStatus?.status
      ? campaign.campaignStatus.status
      : "draft";
    const date =
      campaignStatus === "Complete"
        ? new Date(campaign.completedAt)
        : new Date(campaign.createdAt);
    const formattedDate =
      campaignStatus === "Complete"
        ? `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
        : `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    const campaignCandidates =
      campaign.candidates && campaign.candidates.length
        ? campaign.candidates.length
        : 0;

    const assignedTo = getCampaingOwnerName(
      getOwner(campaign.admin, campaign.consultant, campaign.managedByRole)
    );
    const campaignDetails = [
      createData(t(`${campaignsPrefix}:employerLabel`), employerName),
      createData(t(`${campaignsPrefix}:campaignStatusLabel`), campaignStatus),
      campaignStatus === "Complete"
        ? createData(t(`${campaignsPrefix}:dateCompleted`), formattedDate)
        : createData(t(`${campaignsPrefix}:dateStarted`), formattedDate),
      createData(t(`${campaignsPrefix}:candidatesLabel`), campaignCandidates),
      createData(t(`${campaignsPrefix}:assignedTo`), assignedTo),
    ];
    return campaignDetails;
  };

  const handleFilterChange = (event) => {
    setFilters((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value,
    }));
  };

  function countryFilter (countries, campaign) {
    return countries.includes(campaign.assignmentCampaign.address.country.name);
  }

  function userFilter (users, campaign) {
    let didMatchUser = false;

    for (const user of users) {
      if (campaign.managedByRole !== user.role) {
        continue;
      }

      if (user.role === 'admin' && user.userId === campaign.admin.userId) {
        didMatchUser = true;
        break;
      }

      if (user.role === 'consultant' && user.userId === campaign.consultant.userId) {
        didMatchUser = true;
        break;
      }
    }

    return didMatchUser;
  }

  function applyFilters (campaigns, filters) {
    return campaigns.filter((campaign) => {
      let matches = true;

      Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if (value.length) {
          switch (key) {
            case 'country':
              matches = countryFilter(value, campaign);
              break;
            case 'consultancyUsers':
              matches = userFilter(value, campaign);
              break;
            default:
              matches = true;
          }
        }
      });

      return matches;
    });
  }
  
  const getConsultancyUsersFilterRenderValue = (selectedUsers) => {
    const result = selectedUsers
      .map((u) => u.name)
      .join(', ');

    return result;
  };

  const getConsultancyUsersFilterItemText = (user) => {
    return `${user.name} (${user.email})`;
  };

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <Page title={consultancyName}>
        <PageTitle
          pageTitle={t(`${campaignsPrefix}:campaigns`)}
          btnLabel={props.guestView ? null : t(`${campaignsPrefix}:createCampaign`)}
          btnFunc={handleOpen}
        />
        <ContentWrapper>
          <Card className={classes.filterCard}>
            <Box
              p={2}
              minHeight={56}
              display="flex"
              alignItems="center"
              className={classes.filterCard_box}
            >
              <Searchbar onChange={handleInputChange} />
              {desktop ? (
                <>
                  <Box flexGrow={1} />
                  <SelectMenu value={showItems} onChange={handleShowItems} />

                  <div className={ classes.filterWrapper }>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth >
                      <InputLabel id="demo-simple-select-autowidth-label">
                        {t("common:filterRecruiter")}
                      </InputLabel>

                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name={"consultancyUsers"}
                        multiple={true}
                        value={filters.consultancyUsers}
                        onChange={handleFilterChange}
                        renderValue={ getConsultancyUsersFilterRenderValue }
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty >
                        {consultancyUsers?.map((user) => (
                          <MenuItem key={user.email} value={user}>
                            <Checkbox checked={filters.consultancyUsers.includes(user)} ></Checkbox>
                            <ListItemText primary={ getConsultancyUsersFilterItemText(user) }></ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <div className={ classes.filterWrapper }>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                    >
                      <InputLabel id="demo-simple-select-autowidth-label">
                        {t("common:filterCountires")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-autowidth-label"
                        id="demo-simple-select-autowidth"
                        name={"country"}
                        multiple={true}
                        value={filters.country}
                        onChange={handleFilterChange}
                        renderValue={(selected) => selected.join(", ")}
                        inputProps={{ "aria-label": "Without label" }}
                        displayEmpty
                      >
                        {countries?.map((country) => (
                          <MenuItem key={country} value={country}>
                            <Checkbox
                              checked={filters.country.includes(country)}
                            ></Checkbox>
                            <ListItemText primary={country}></ListItemText>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <TextField
                    label={t("common:sortBy")}
                    name="sort"
                    onChange={handleSortItems}
                    select
                    SelectProps={{ native: true }}
                    value={sortOrder}
                    variant="outlined"
                    className={classes.sort}
                  >
                    {sortOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </>
              ) : null}
            </Box>
          </Card>
          {!props.guestView && (
            <AppBar position="static" className={classes.appBar}>
              <CustomTabs tabs={ tabs } onChange={ handleChange } />
            </AppBar>
          )}
          <TabPanel value={currentTab} index={0}>
            <Grid container spacing={3}>
              {sortedData.map((campaign) => {
                return (
                  <Grid key={campaign.id} item md={4} sm={6} xs={12}>
                    <CampaignCard
                      campaignDetails={getCampaignDetails(campaign)}
                      name={campaign.campaignTitle}
                      btnLabel={t("common:viewMore")}
                      btnFunc={(event) => {
                        goToCampaignDetails(event, campaign.id);
                      }}
                      campaignLogo={campaign.campaignLogo || consultancyLogo}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Paginator pageCount={maxPage} onPageChange={handlePageChange} />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <Grid container spacing={3}>
              {sortedData.map((groupCampaign) => (
                <Grid key={groupCampaign.id} item md={4} sm={6} xs={12}>
                  <CampaignCard
                    campaignDetails={getCampaignDetails(groupCampaign)}
                    name={groupCampaign.campaignTitle}
                    btnLabel={t("common:viewMore")}
                    btnFunc={(event) => {
                      goToCampaignDetails(event, groupCampaign.id);
                    }}
                    campaignLogo={groupCampaign.campaignLogo || consultancyLogo}
                  />
                </Grid>
              ))}
            </Grid>
            <Paginator pageCount={maxPage} onPageChange={handlePageChange} />
          </TabPanel>
          {userRole === "Admin" ? (
            <TabPanel value={currentTab} index={2}>
              <Grid container spacing={3}>
                {sortedData.map((groupCampaign) => (
                  <Grid key={groupCampaign.id} item md={4} sm={6} xs={12}>
                    <CampaignCard
                      campaignDetails={getCampaignDetails(groupCampaign)}
                      name={groupCampaign.campaignTitle}
                      btnLabel={t("common:viewMore")}
                      btnFunc={(event) => {
                        goToCampaignDetails(event, groupCampaign.id);
                      }}
                      campaignLogo={
                        groupCampaign.campaignLogo || consultancyLogo
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <Paginator pageCount={maxPage} onPageChange={handlePageChange} />
            </TabPanel>
          ) : null}
        </ContentWrapper>

        <CreateCampaignModal
          onSuccess={onSuccess}
          consultancyId={consultancyId}
          open={open}
          handleClose={handleClose}
        />
      </Page>
    </div>
  );
}

export default Campaigns;
