import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  Chip,
  colors,
} from "@material-ui/core";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import * as ConsultancyApi from "src/api/consultancyApi";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "&:focus": {
      outline: "none",
    },
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
      overflow: "visible",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
      overflow: "visible",
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflow: "scroll",
    height: "100vh",
  },
  userChip: {
    backgroundColor: "#009688",
    color: "#ffffff",
  },
}));

const schema = {
  groupName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
};

function CreateGroupModal({
  onSuccess,
  consultancyId,
  usersAndGroups,
  open,
  handleClose,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation(["common", "users", "groups"]);

  const { enqueueSnackbar } = useSnackbar();
  const handleSelector = (event) => {
    setUsers(event.target.value);
    let userValues = event.target.value;
    let selectedUser = [];

    for (const user of userValues) {
      selectedUser.push(
        userList.filter((userObject) => {
          return userObject.name === user;
        })
      );
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        selectedUsers: selectedUser,
      },
    }));
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      consultancyId,
    },
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  let userList = [];

  for (const admin of usersAndGroups.admins) {
    userList.push({
      id: admin.id,
      name: admin.name,
      userType: "admin",
      consultancyId: admin.consultancyId,
    });
  }

  for (const consultant of usersAndGroups.consultants) {
    userList.push({
      id: consultant.id,
      name: consultant.name,
      userType: "consultant",
      consultancyId: consultant.consultancyId,
    });
  }

  const [users, setUsers] = useState([]);
  const roleType = useSelector((state) => {
    return state.session.roleType;
  });

  const onSubmit = function(event) {
    event.preventDefault();
    return ConsultancyApi.createCustomUserGroup(formState.values)
      .then((response) => {
        onSuccess(response.data);
        handleClose();
        if (roleType === "CoreAdmin" || roleType === "SystemAdmin") {
          history.push(
            `/dashboard/consultancies/${response.data.consultancyId}/groups/${response.data.id}`
          );
        } else {
          history.push(`/dashboard/groups/${response.data.id}`);
        }
        enqueueSnackbar(t("groups:groupCreation"), { variant: "success" });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          enqueueSnackbar(t("groups:groupAlreadyExists"), { variant: "error" });
        } else {
          handleClose();
          enqueueSnackbar(t("groups:groupFailure"), { variant: "error" });
        }
      });
  };
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const formTitle = t("groups:createGroup");

  const children = (
    <Grid item xs={12}>
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <TextField
            fullWidth
            label={t("groups:groupName")}
            name="groupName"
            variant="outlined"
            required
            onChange={handleChange}
            error={hasError("groupName")}
            value={formState.values.groupName || ""}
            helperText={
              hasError("lastName") ? formState.errors.groupName[0] : null
            }
          />
          <FormControl
            variant="outlined"
            required="false"
            className={classes.formControl}
          >
            <InputLabel id="group-user-label">
              {t("groups:groupAddUsers")}
            </InputLabel>
            <Select
              multiple
              labelId="group-user-label"
              id="group-User"
              value={users}
              onChange={handleSelector}
              input={<OutlinedInput id="select-group-user" />}
              renderValue={(selected) => (
                <div>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      className={classes.userChip}
                    />
                  ))}
                </div>
              )}
            >
              {userList.map((user) => (
                <MenuItem key={user.name} value={user.name}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          {t("groups:createGroup")}
        </Button>
      </form>
    </Grid>
  );

  return (
    <ModalFormWrapper
      children={children}
      formTitle={formTitle}
      handleOpen={open}
      handleClose={handleClose}
    />
  );
}
export default CreateGroupModal;
