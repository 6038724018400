import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button, CircularProgress, Grid, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import Swal from 'sweetalert2';

import { useSnackbar } from 'notistack';

import * as videoAssessmentApi from './../../../api/videoAssessmentApi';

import { BlockHeader } from './../../LayoutComponents/BlockHeader';
import { ModalFormWrapper } from './../../ModalFormWrapper';

import VideoAssessmentReviewQuestionsAndAnswers from './VideoAssessmentReviewQuestionsAndAnswers';

import * as roles from './../../../constants/roles';

import playBtnPng from './../../../assets/images/icons/play-btn.png';

const useStyles = makeStyles(() => ({
  loader: {
    minWidth: '200px',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  videoWrapper: {
    position: 'relative'
  },
  video: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  playBtn: {
    width: '60px',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    margin: 'auto',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  gridActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px'
  },
  gridActionsBtn: {
    width: '48%'
  },
  gridActionsBtnFont: {
    fontSize: '12px'
  }
}));

const VideoAssessmentReview = ({ videoAssessmentInteview, videoAssessmentReset, isPreview = false }) => {

  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation(['videoAssessment', 'common']);
  const { enqueueSnackbar } = useSnackbar();

  const { roleType, consultancyId } = useSelector((state) => state.session);

  const [isLoading, setIsLoading] = useState(true);
  const [areQuestionsAndAnswersOpened, setAreQuestionsAndAnswersOpened] = useState(false);

  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);

  useEffect(() => {
    setQuestionsAndAnswers(videoAssessmentInteview.answers);
    setIsLoading(false);
  }, [videoAssessmentInteview]);

  const toggleQuestionsAndAnswersModal = (event) => {
    event.preventDefault();
    setAreQuestionsAndAnswersOpened(!areQuestionsAndAnswersOpened);
  };

  const resetVideoAssessment = (event) => {
    event.preventDefault();

    Swal.fire({
      title: t('common:areYouSure'),
      text: t('videoAssessment:videoAssessmentReviewResetConfirmation'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('common:yes'),
      cancelButtonText: t('common:no'),
      confirmButtonColor: theme.palette.warning?.main ?
        theme.palette.warning.main :
        theme.palette.primary.main,
    }).then((resp) => {
      if (!resp.isConfirmed) return;
    
      videoAssessmentApi.resetInterview(videoAssessmentInteview.campaignId, videoAssessmentInteview.candidateId, consultancyId)
        .then(() => {
          enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationSuccessResetInterview'), { variant: 'success' });
          videoAssessmentReset();
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(t('videoAssessment:videoAssessmentNotificationErrorResetInterview'), { variant: 'error' });
        });
    });
  };

  if (isLoading) {
    return (
      <>
        <Grid container spacing={3} className={classes.loader} >
          <CircularProgress />
        </Grid>
      </>
    )
  }

  return (
    <>
      <Grid>
        <Grid>
          <BlockHeader card header={ t("videoAssessment:videoAssessmentReviewCardHeader") } />
        </Grid>

        <Grid className={ classes.videoWrapper } onClick={ toggleQuestionsAndAnswersModal } >
          <video src={ questionsAndAnswers[0].video.value } className={ classes.video } />
          <img src={ playBtnPng } alt="" className={ classes.playBtn } />
        </Grid>

        <Grid className={ classes.gridActions } >
          <Button
            fullWidth
            color="primary"
            variant="contained"
            size="medium"
            className={
              (roleType === roles.ADMIN || roleType === roles.CONSULTANT) && !isPreview ?
              `${classes.gridActionsBtn} ${classes.gridActionsBtnFont}` : classes.gridActionsBtnFont
            }
            onClick={ toggleQuestionsAndAnswersModal } >
            { t("videoAssessment:videoAssessmentReviewCardViewButton") }
          </Button>

          {
            ((roleType === roles.ADMIN || roleType === roles.CONSULTANT) && !isPreview) && (
              <>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  size="medium"
                  className={ `${classes.gridActionsBtn} ${classes.gridActionsBtnFont}` }
                  onClick={ resetVideoAssessment } >
                  { t("videoAssessment:videoAssessmentReviewCardResetButton") }
                </Button>
              </>
            )
          }
        </Grid>

        <ModalFormWrapper
          formTitle={ t("videoAssessment:videoAssessmentReviewModalTitle") }
          formTooltip={ t("videoAssessment:videoAssessmentReviewModalTooltip") }
          handleOpen={ areQuestionsAndAnswersOpened }
          handleClose={ toggleQuestionsAndAnswersModal } >
          <VideoAssessmentReviewQuestionsAndAnswers questionsAndAnswers={ questionsAndAnswers } />
        </ModalFormWrapper>
      </Grid>
    </>
  );

};

export default VideoAssessmentReview;
