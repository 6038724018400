import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { CardContent, Card, AppBar, Tabs, Tab } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import * as CampaignApi from "src/api/campaignApi";
import { TabPanel } from "src/components/TabPanel";
import EditBRModal from "./EditBRModal";

import {
  UPDATE_CAMPAIGN_BACKGROUND_REQUIREMENTS,
  UPDATE_CAMPAIGN_ASSIGNMENT_OVERVIEW,
} from "src/actions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  termsAndConditionsCard: {
    paddingTop: "0px",
    height: "400px",
    fontFamily: "sans-serif",
  },
  contactListHeader: {
    display: "flex",
    marginRight: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  editButton: {
    textAlign: "center",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

export default function({ guestView }) {
  const classes = useStyles();
  const [t] = useTranslation(["common", "validation", "campaigns", "tooltips"]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const campaign = useSelector((state) => state.campaign);
  const [currentTab, setCurrentTab] = useState(0);
  const [backgroundModalOpen, setBackgroundModalOpen] = useState(false);
  const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);

  const tabs = [
    { value: 0, label: t("common:bgLabel") },
    { value: 1, label: t("common:assignmentLabel") },
  ];

  let assignmentOverview =
    campaign.assignmentOverview !== null ? campaign.assignmentOverview : "";
  let bgRequirements = campaign.bgRequirements ? campaign.bgRequirements : "";

  const handleBackgroundModalOpen = () => setBackgroundModalOpen(true);
  const handleBackgroundModalClose = () => setBackgroundModalOpen(false);
  const handleAssignmentModalOpen = () => setAssignmentModalOpen(true);
  const handleAssignmentModalClose = () => setAssignmentModalOpen(false);

  const handleTabChange = (event, value) => setCurrentTab(value);

  const onBackgroundUpdate = (backgroundText) => {
    return CampaignApi.updateCampaignBgRequirements(
      campaign.id,
      backgroundText,
      "BACKGROUND"
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_BACKGROUND_REQUIREMENTS,
          bgRequirements: response.data.backgroundAndRequirements,
        });
        enqueueSnackbar(`${t("snackbar:campaignUpdatedBackgroundSuccess")}`, {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(`${t("snackbar:campaignUpdatedBackgroundError")}`, {
          variant: "error",
        });
        console.error(error);
      });
  };
  const onAssignmentUpdate = (backgroundText) => {
    return CampaignApi.updateCampaignBgRequirements(
      campaign.id,
      backgroundText,
      "ASSIGNMENT"
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_ASSIGNMENT_OVERVIEW,
          assignmentOverview: response.data.campaignOverview,
        });
        enqueueSnackbar(`${t("snackbar:campaignUpdatedAssignmentSuccess")}`, {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(`${t("snackbar:campaignUpdatedAssignmentError")}`, {
          variant: "error",
        });
      });
  };

  return (
    <>
      <Card>
        <BlockHeader
          headerTooltip={t("tooltips:inputBackgroundAndAssignment")}
          card
          padding
          header={t("common:bgRequirements")}
        />
        <CardContent>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              variant="scrollable"
              textColor="primary"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.value}
                  label={t(`common:${tab.label}`)}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            <div className={classes.termsAndConditionsCard}>
              <div class="ql-editor">{parse(bgRequirements || "")}</div>
            </div>
            {!guestView && (
              <CardButton
                btnFunc={handleBackgroundModalOpen}
                btnLabel={t("common:edit")}
              />
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <div className={classes.termsAndConditionsCard}>
              <div class="ql-editor">{parse(assignmentOverview) || ""}</div>
            </div>
            {!guestView && (
              <CardButton
                btnFunc={handleAssignmentModalOpen}
                btnLabel={t("common:edit")}
              />
            )}
          </TabPanel>
        </CardContent>
      </Card>
      <EditBRModal
        onSuccess={onAssignmentUpdate}
        open={assignmentModalOpen}
        handleClose={handleAssignmentModalClose}
        textToChange={campaign.assignmentOverview}
      />
      <EditBRModal
        onSuccess={onBackgroundUpdate}
        open={backgroundModalOpen}
        handleClose={handleBackgroundModalClose}
        textToChange={campaign.bgRequirements}
      />
    </>
  );
}
