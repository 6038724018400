import {
	Button,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
	Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as modulesApi from "src/api/modulesApi";
import { SET_KCQ_CATEGORIES } from "src/actions/ConsultancyModules/kcq/kcqActions";
import CreateKCQCategoryModal from "src/components/createKcqCategoryModal";
import EditKCQCategoryModal from "src/components/editKcqCategoryModal";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	editButton: {
		paddingRight: "20px",
	},
	list: {
		padding: "0",
		backgroundColor: theme.palette.background.paper,
	},
	listItem: {
		padding: "15px",
	},
	container: {
		padding: "0% 5%",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	modalCard: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
			transform: `translate(${0}%, ${50}%)`,
		},
		[theme.breakpoints.up("md")]: {
			width: "50%",
			transform: `translate(${50}%, ${50}%)`,
		},
		"&:focus": {
			outline: "none",
		},
	},
	gridRow: {
		marginTop: "20px",
	},
	appBar: {
		boxShadow: "none",
		backgroundColor: "transparent",
	},
}));

function KCQSettings() {
	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();
	const kcqCategories = useSelector((state) => state.kcq.kcqCategories);
	const [t] = useTranslation(["modules", "common", "snackbar"]);
	const dispatch = useDispatch();
	const [openCreate, setOpenCreate] = useState(false);
	const [openEdit, setOpenEdit] = useState(false);
	const [editingCategory, setEditingCategory] = useState({});

	const handleOpenCreate = () => {
		setOpenCreate(true);
	};
	const handleClose = () => {
		setOpenCreate(false);
		setOpenEdit(false);
	};

	const handleEditOpen = (event, category) => {
		event.preventDefault();
		setEditingCategory(category);
		setOpenEdit(true);
	};

	const deleteCategory = (event, categoryId) => {
		event.preventDefault();
		return modulesApi
			.deleteGlobalKCQCategory(categoryId)
			.then((response) => {
				dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
				enqueueSnackbar(t("snackbar:kcqCategoryDeleteSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:kcqCategoryDeleteFailed"), {
					variant: "error",
				});
			});
	};

	const onEditCategorySuccess = (category) => {
		return modulesApi
			.updateGlobalKCQCategory(category)
			.then((response) => {
				dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
				setOpenEdit(false);
				enqueueSnackbar(t("snackbar:kcqCategoryUpdateSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:kcqCategoryUpdateFailed"), {
					variant: "error",
				});
			});
	};

	const onCreateCategorySuccess = (newCategory) => {
		return modulesApi
			.createGlobalKCQCategory(newCategory)
			.then((response) => {
				dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
				setOpenCreate(false);
				enqueueSnackbar(t("snackbar:kcqCategoryCreateSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:kcqCategoryCreateFailed"), {
					variant: "error",
				});
			});
	};

	const getGlobalKCQCategories = () => {
		return modulesApi.getGlobalKCQCategories().then((response) => {
			dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
		});
	};

	useEffect(() => {
		getGlobalKCQCategories();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} lg={9}></Grid>
				<Grid item xs={12} lg={3}>
					<Button
						className={classes.submitButton}
						color="primary"
						size="medium"
						onClick={handleOpenCreate}
						type="submit"
						variant="contained"
						fullWidth
					>
						{t("modules:createCategory")}
					</Button>
				</Grid>
			</Grid>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					{kcqCategories?.map((category, index) => (
						<ExpansionPanel>
							<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
								<Typography variant="h5">{category.name}</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<Grid container spacing={3}>
									<Grid item lg={10} xs={12}>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Typography variant="h5">
													{t("modules:questions")}
												</Typography>
											</Grid>
											{category.questions?.map((subCategory) => (
												<>
													<Grid item xs={12}>
														<Typography variant="h5">{subCategory}</Typography>
													</Grid>
												</>
											))}
										</Grid>
									</Grid>
									<Grid item lg={2} xs={12}>
										<Button
											color="primary"
											size="medium"
											onClick={(event) => handleEditOpen(event, category)}
											type="submit"
											variant="contained"
											fullWidth
										>
											{t("common:edit")}
										</Button>
										<Button
											style={{ marginTop: "20px" }}
											color="error"
											size="medium"
											onClick={(event) => deleteCategory(event, category.id)}
											type="submit"
											variant="contained"
											fullWidth
										>
											{t("common:delete")}
										</Button>
									</Grid>
								</Grid>
							</ExpansionPanelDetails>
						</ExpansionPanel>
					))}
				</Grid>
			</Grid>
			<CreateKCQCategoryModal
				modalTitle={t("modules:kcqCreateModalTitle1")}
				title={t("modules:kcqModalTitle")}
				subtitle={t("modules:kcqModalSubtitle")}
				buttonText={t("modules:kcqModalButton")}
				onSuccess={onCreateCategorySuccess}
				open={openCreate}
				handleClose={handleClose}
			/>
			<EditKCQCategoryModal
				modalTitle="Update Key Competency Question Category"
				title={t("modules:kcqModalTitle")}
				subtitle={t("modules:kcqModalSubtitle")}
				buttonText={t("modules:kcqUpdateModalButton")}
				open={openEdit}
				category={editingCategory}
				onSuccess={onEditCategorySuccess}
				handleClose={handleClose}
			/>
		</>
	);
}

export default KCQSettings;
