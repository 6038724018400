import React from "react";
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  Grid,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import logoPlaceholder from "src/assets/images/placeholder.png";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  card: {
    transition: "300ms",
    maxWidth: "260px",
    minHeight: "280px",
    overflow: "visible",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(12),
  },
  card_disabled: {
    backgroundColor: "transparent",
    color: "grey",
    transition: "300ms",
  },
  name: {
    transition: "300ms",
    paddingTop: ".5rem",
    width: "100%"
  },
  remove_underline: {
    transition: "300ms",
    paddingBottom: ".5rem",
    minWidth: "326px",
  },
  name_disabled: {
    marginTop: theme.spacing(1),
    backgroundColor: "transparent",
    color: "grey",
    transition: "300ms",
  },
  logo_container: {
    transform: "translateY(-50px)",
    backgroundColor: theme.palette.background.paper,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "-2rem",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    height: "100%",
    width: "100%",
  },
}));

export const Go1LearningObjectCard = ({
  learningObject,
  btnLabel,
  btnFunc,
}) => {
  const classes = useStyles();
  const [t] = useTranslation(["common"]);

  if (learningObject.type === 'course') {
    return (
      <Grid key={learningObject.id} item md={3} sm={4} xs={12}>
        <Card className={classes.card}>
          <Box className={classes.logo_container}>
            <img
              src={learningObject.image ? learningObject.image : logoPlaceholder}
              className={classes.logo}
              alt=""
            />
          </Box>

          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
            >
              <Box>
                <Typography
                  className={
                    !learningObject
                      ? classes.remove_underline
                      : !learningObject.disabled
                      ? classes.name
                      : classes.name_disabled
                  }
                  gutterBottom
                  variant="h3"
                  color="textPrimary"
                >
                  {learningObject.title}
                </Typography>
              </Box>
            </Box>
          </CardContent>
          <CardActions>
            {!learningObject.disabled ? (
              <Button
                fullWidth
                component={Link}
                to={btnFunc}
                variant="text"
                color="primary"
              >
                {btnLabel}
              </Button>
            ) : <Button
                fullWidth
                variant="text"
                color="primary"
                disabled={learningObject.disabled}
              >
                {t("common:comingSoon")}
              </Button>}
          </CardActions>
        </Card>
      </Grid>
    );
  } else {
    return null
  }
};
