import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import CreateEmployerModal from "./CreateEmployerModal";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";

function Header({ className, consultancies, ...rest }) {
	const [t] = useTranslation(["common"]);
	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<PageTitle
				pageTitle={t("common:allEmployers")}
				btnLabel={t("common:newEmployer")}
				btnFunc={handleOpen}
			/>
			<CreateEmployerModal
				open={open}
				handleClose={handleClose}
				consultancies={consultancies}
			/>
		</>
	);
}

Header.propTypes = {
	className: PropTypes.string,
};

export default Header;
