import React from "react";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import {
  ButtonGroup,
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ViewConfigIcon from "@material-ui/icons/ViewComfyOutlined";
import { useTranslation } from "react-i18next";
import ViewWeekIcon from "@material-ui/icons/ViewWeekOutlined";
import ViewDayIcon from "@material-ui/icons/ViewDayOutlined";
import ViewAgendaIcon from "@material-ui/icons/ViewAgendaOutlined";
import TodayIcon from "@material-ui/icons/Today";
import InfoIcon from "@material-ui/icons/Info";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useSelector } from "react-redux";

const viewOptions = [
  {
    label: "month",
    value: "dayGridMonth",
    icon: ViewConfigIcon,
  },
  {
    label: "week",
    value: "timeGridWeek",
    icon: ViewWeekIcon,
  },
  {
    label: "day",
    value: "timeGridDay",
    icon: ViewDayIcon,
  },
  {
    label: "agenda",
    value: "listWeek",
    icon: ViewAgendaIcon,
  },
];

const useStyles = makeStyles((theme) => ({
  monthLabel: {
    color: "#737373",
    marginTop: 14,
  },
  icon: {
    fontSize: 20,
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function Toolbar({
  date,
  view,
  onDatePrev,
  onDateNext,
  onEventAdd,
  onViewChange,
  onDateToday,
  className,
  contactView,
  headerTooltip,
  ...rest
}) {
  const classes = useStyles();

  const roleType = useSelector((state) => {
    return state.session.roleType;
  });
  const [t] = useTranslation(["common", "tooltips"]);

  return (
    <Grid
      className={clsx(classes.root, className)}
      {...rest}
      alignItems="center"
      container
      justify="space-between"
      spacing={3}
    >
      <Hidden smDown>
        <Grid item md={4} />
      </Hidden>
      <Grid item xs={12} md={4}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ButtonGroup size="large">
            <IconButton className={classes.icon} onClick={onDatePrev}>
              <NavigateBeforeIcon />
            </IconButton>
            <Typography
              className={classes.monthLabel}
              variant="h3"
              color="textPrimary"
            >
              {moment(date).format("MMMM YYYY")}
            </Typography>
            <IconButton className={classes.icon} onClick={onDateNext}>
              <NavigateNextIcon />
            </IconButton>
            {roleType !== "Candidate" && headerTooltip && (
              <div style={{ marginTop: "14px" }}>
                <Tooltip
                  enterTouchDelay={1}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    contactView
                      ? t("tooltips:contactInterview")
                      : t("tooltips:selectInterviewSlots")
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </div>
            )}
          </ButtonGroup>
        </div>
      </Grid>
      <Hidden smDown>
        <Grid item md={4}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {onViewChange && (
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={t("common:today")}
              >
                <IconButton className={classes.icon} onClick={onDateToday}>
                  <TodayIcon />
                </IconButton>
              </Tooltip>
            )}
            {onViewChange ? (
              viewOptions.map((viewOption) => {
                const Icon = viewOption.icon;

                return (
                  <Tooltip
                    enterTouchDelay={1}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    key={viewOption.value}
                    title={t(`common:${viewOption.label}`)}
                  >
                    <IconButton
                      className={classes.icon}
                      color={viewOption.value === view ? "primary" : "default"}
                      onClick={() => onViewChange(viewOption.value)}
                    >
                      <Icon />
                    </IconButton>
                  </Tooltip>
                );
              })
            ) : (
              <> </>
            )}
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
}

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  date: PropTypes.any.isRequired,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onEventAdd: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.string.isRequired,
};

export default Toolbar;
