import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Divider, List, ListItem, Typography } from "@material-ui/core";
import SearchResultListItem from "./SearchResultListitem";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  searchResults: {
    maxHeight: "400px",
    overflowY: "scroll",
    width: "400px",
  },
}));

function SearchResultDropdown({ searchResults, handleClick }) {
  const [t] = useTranslation(["newTranslations"]);
  const classes = useStyles();

  const showTranslatedMessage = (result) => {
    const firstTextPart = t(`newTranslations:successfullyFound`);
    const secondTextPartPlural = t("newTranslations:results");
    const secondTextPartNonPlural = t("newTranslations:result");
    let translatedMessage;
    if (result === 0) {
      translatedMessage = `(${result}) ${secondTextPartPlural}`;
    } else if (result === 1) {
      translatedMessage = `${firstTextPart} (${result}) ${secondTextPartNonPlural}`;
    } else {
      translatedMessage = `${firstTextPart} (${result}) ${secondTextPartPlural}`;
    }
    return translatedMessage;
  };

  return (
    <div className={classes.searchResults}>
      <Box boxShadow={1}>
        <List className={classes.root}>
          {searchResults?.hits?.map((hit) => {
            return (
              <>
                <SearchResultListItem
                  handleClick={handleClick}
                  searchResult={hit}
                />
                <Divider />
              </>
            );
          })}
          <ListItem>
            <Typography variant="caption" component="h6" color="textSecondary">
              {showTranslatedMessage(searchResults.total)}
            </Typography>
          </ListItem>
        </List>
      </Box>
    </div>
  );
}

export default SearchResultDropdown;
