import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  Divider,
  Grid,
  Paper,
  Slide,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../api/landingApi";
import { useSnackbar } from "notistack";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import { InputAndButton } from "src/components/LayoutComponents/InputAndButton";
import CardCarousel from "src/views/LandingPage/Components/CardCarousel";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  button_container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ManageCarousel = ({ landingPageData, assetData, getLandingPageData }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const [expand, setExpand] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [carouselData, setCarouselData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [percent, setPercent] = useState(0);
  const currentData = landingPageData?.slides[0];
  const { consultancyId } = landingPageData;
  const landingPageId = landingPageData.id;

  const exampleData = {
    landing: { slides: [carouselData] },
  };

  useEffect(() => {
    setCarouselData(currentData);
    setEnabled(landingPageData.display_slides);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  const toggleContentState = (fieldName) => {
    setEnabled(!enabled);
    return api
      .toggleDisplayContent(consultancyId, fieldName)
      .then((response) => {
        if (response.status === 200 && !enabled) {
          enqueueSnackbar("Carousel block enabled", {
            variant: "success",
          });
        } else if (response.status === 200 && enabled) {
          enqueueSnackbar("Carousel block disabled", {
            variant: "info",
          });
        }
      })
      .catch(() => {
        setEnabled(enabled);
        enqueueSnackbar("Could not be enabled at this time", {
          variant: "error",
        });
      });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleImage = (value, fieldName) => {
    const uploadOptions = {
      params: {
        consultancyId: consultancyId,
        landingPageId: landingPageId,
        fieldName: fieldName,
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;

        if (loaded && total) {
          let newPercent = Math.floor((loaded * 100) / total);

          setPercent(newPercent);
        }
      },
    };
    setPercent(0);
    return api.uploadAsset(uploadOptions, value).then((response) => {
      if (response) {
        getLandingPageData(consultancyId);
        enqueueSnackbar("Image uploaded", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Upload failed", {
          variant: "error",
        });
      }
    });
  };

  const handleContentChange = (event, index) => {
    const { name, value } = event.target;
    setCarouselData((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        id: index,
        [name]: value,
      },
    }));
  };

  const applyChanges = () => {
    return api
      .updateContentBlock(consultancyId, "slides", carouselData)
      .then((response) => {
        if (response) {
          enqueueSnackbar("Content updated", { variant: "success" });
        }
      })
      .catch(() => {
        enqueueSnackbar("Unable to apply changes", { variant: "error" });
      });
  };

  const deleteAsset = (type) => {
    return api.deleteAsset(landingPageId, type).then((response) => {
      if (response.status === 200) {
        getLandingPageData(consultancyId);
        enqueueSnackbar("Image deleted", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed to delete image", {
          variant: "error",
        });
      }
    });
  };

  return (
    <>
      <Grid container style={{ paddingTop: ".4rem" }}>
        <Grid item xs={6}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              variant="text"
              endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={() => {
                setExpand(!expand);
              }}
            >
              Carousel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="row-reverse" alignItems="center">
            <Tooltip
              enterTouchDelay={1}
              title="Designed for landscape images with a ratio between 1.5:1 and 4:1, though works best with images with a ratio between 2:1 and 3:1. For the fastest page load speeds image height should not exceed 500px."
            >
              <InfoOutlinedIcon />
            </Tooltip>
            <Slide in={expand} direction="left">
              <div>
                <Switch
                  checked={enabled}
                  color="primary"
                  style={{ marginLeft: "auto" }}
                  onChange={() => toggleContentState("display_slides")}
                />
                <Button
                  variant="text"
                  onClick={() => toggleContentState("display_slides")}
                >
                  Enable Content Block
                </Button>
              </div>
            </Slide>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expand}>
        <InputAndButton
          value={carouselData[0]?.header}
          onChange={(event) => handleContentChange(event, 0)}
          btnLabel="Preview"
          btnFunc={() => {
            setShowPreview(true);
          }}
        />
        <Grid container>
          <Grid item xs={6} lg={2}>
            <Tabs
              orientation="vertical"
              value={currentTab}
              onChange={handleTabChange}
              variant="scrollable"
              className={classes.tabs}
            >
              <Tab label="Slide One" />
              <Tab label="Slide Two" />
              <Tab label="Slide Three" />
              <Tab label="Slide Four" />
              <Tab label="Slide Five" />
            </Tabs>
          </Grid>
          <Grid item xs={6} lg={10}>
            <TabPanel value={currentTab} index={0}>
              <Box display="flex" flexDirection="row">
                <Grid item xs={12} lg={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Paper style={{ padding: "2rem" }}>
                      <CustomFileDropzone
                        type="single"
                        actionText="Upload Image"
                        format="image"
                        placeHolderImage={assetData[0]}
                        uploadFunction={(value) =>
                          handleImage(value, "slide_1")
                        }
                        percent={percent}
                      />
                      {assetData[0] && (
                        <Box className={classes.button_container}>
                          <Button
                            variant="text"
                            color="primary"
                            endIcon={<DeleteForeverIcon />}
                            onClick={() => deleteAsset("slide_1")}
                          >
                            Delete Image
                          </Button>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <form>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        name="title"
                        variant="outlined"
                        placeholder="Line 1 (bold)"
                        value={carouselData[1]?.title}
                        onChange={(event) => handleContentChange(event, 1)}
                        style={{
                          marginBottom: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                      <TextField
                        name="subtitle"
                        variant="outlined"
                        multiline
                        placeholder="Line 2"
                        value={carouselData[1]?.subtitle}
                        onChange={(event) => handleContentChange(event, 1)}
                        style={{
                          marginTop: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                    </Box>
                  </form>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Box display="flex" flexDirection="row">
                <Grid item xs={12} lg={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Paper style={{ padding: "2rem" }}>
                      <CustomFileDropzone
                        type="single"
                        actionText="Upload Image"
                        format="image"
                        placeHolderImage={assetData[1]}
                        uploadFunction={(value) =>
                          handleImage(value, "slide_2")
                        }
                        percent={percent}
                      />
                      {assetData[1] && (
                        <Box className={classes.button_container}>
                          <Button
                            variant="text"
                            color="primary"
                            endIcon={<DeleteForeverIcon />}
                            onClick={() => deleteAsset("slide_2")}
                          >
                            Delete Image
                          </Button>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <form>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        name="title"
                        variant="outlined"
                        placeholder="Line 1 (bold)"
                        value={carouselData[2]?.title}
                        onChange={(event) => handleContentChange(event, 2)}
                        style={{
                          marginBottom: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                      <TextField
                        name="subtitle"
                        variant="outlined"
                        multiline
                        placeholder="Line 2"
                        value={carouselData[2]?.subtitle}
                        onChange={(event) => handleContentChange(event, 2)}
                        style={{
                          marginTop: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                    </Box>
                  </form>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Box display="flex" flexDirection="row">
                <Grid item xs={12} lg={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Paper style={{ padding: "2rem" }}>
                      <CustomFileDropzone
                        type="single"
                        actionText="Upload Image"
                        format="image"
                        placeHolderImage={assetData[2]}
                        uploadFunction={(value) =>
                          handleImage(value, "slide_3")
                        }
                        percent={percent}
                      />
                      {assetData[2] && (
                        <Box className={classes.button_container}>
                          <Button
                            variant="text"
                            color="primary"
                            endIcon={<DeleteForeverIcon />}
                            onClick={() => deleteAsset("slide_3")}
                          >
                            Delete Image
                          </Button>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <form>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        name="title"
                        variant="outlined"
                        placeholder="Line 1 (bold)"
                        value={carouselData[3]?.title}
                        onChange={(event) => handleContentChange(event, 3)}
                        style={{
                          marginBottom: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                      <TextField
                        name="subtitle"
                        variant="outlined"
                        multiline
                        placeholder="Line 2"
                        value={carouselData[3]?.subtitle}
                        onChange={(event) => handleContentChange(event, 3)}
                        style={{
                          marginTop: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                    </Box>
                  </form>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={3}>
              <Box display="flex" flexDirection="row">
                <Grid item xs={12} lg={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Paper style={{ padding: "2rem" }}>
                      <CustomFileDropzone
                        type="single"
                        actionText="Upload Image"
                        format="image"
                        placeHolderImage={assetData[3]}
                        uploadFunction={(value) =>
                          handleImage(value, "slide_4")
                        }
                        percent={percent}
                      />
                      {assetData[3] && (
                        <Box className={classes.button_container}>
                          <Button
                            variant="text"
                            color="primary"
                            endIcon={<DeleteForeverIcon />}
                            onClick={() => deleteAsset("slide_4")}
                          >
                            Delete Image
                          </Button>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <form>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        name="title"
                        variant="outlined"
                        placeholder="Line 1 (bold)"
                        value={carouselData[4]?.title}
                        onChange={(event) => handleContentChange(event, 4)}
                        style={{
                          marginBottom: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                      <TextField
                        name="subtitle"
                        variant="outlined"
                        multiline
                        placeholder="Line 2"
                        value={carouselData[4]?.subtitle}
                        onChange={(event) => handleContentChange(event, 4)}
                        style={{
                          marginTop: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                    </Box>
                  </form>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={4}>
              <Box display="flex" flexDirection="row">
                <Grid item xs={12} lg={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                  >
                    <Paper style={{ padding: "2rem" }}>
                      <CustomFileDropzone
                        type="single"
                        actionText="Upload Image"
                        format="image"
                        placeHolderImage={assetData[4]}
                        uploadFunction={(value) =>
                          handleImage(value, "slide_5")
                        }
                        percent={percent}
                      />
                      {assetData[4] && (
                        <Box className={classes.button_container}>
                          <Button
                            variant="text"
                            color="primary"
                            endIcon={<DeleteForeverIcon />}
                            onClick={() => deleteAsset("slide_5")}
                          >
                            Delete Image
                          </Button>
                        </Box>
                      )}
                    </Paper>
                  </Box>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <form>
                    <Box display="flex" flexDirection="column">
                      <TextField
                        name="title"
                        variant="outlined"
                        placeholder="Line 1 (bold)"
                        value={carouselData[5]?.title}
                        onChange={(event) => handleContentChange(event, 5)}
                        style={{
                          marginBottom: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                      <TextField
                        name="subtitle"
                        variant="outlined"
                        multiline
                        placeholder="Line 2"
                        value={carouselData[5]?.subtitle}
                        onChange={(event) => handleContentChange(event, 5)}
                        style={{
                          marginTop: ".25rem",
                          backgroundColor: "white",
                        }}
                      />
                    </Box>
                  </form>
                </Grid>
              </Box>
            </TabPanel>
          </Grid>
        </Grid>
        <ButtonWrapper btnLabel="Apply Changes" btnFunc={applyChanges} />
      </Collapse>
      <Divider />
      <Dialog
        maxWidth="xl"
        open={showPreview}
        onClose={() => setShowPreview(false)}
      >
        <Box display="flex" flexDirection="row-reverse">
          <Button
            variant="text"
            onClick={() => {
              setShowPreview(false);
            }}
          >
            Close
          </Button>
        </Box>
        <CardCarousel
          consultancyDetails={exampleData}
          assetUrls={{
            slide_1: assetData[0],
            slide_2: assetData[1],
            slide_3: assetData[2],
            slide_4: assetData[3],
            slide_5: assetData[4],
          }}
        />
      </Dialog>
    </>
  );
};

export default ManageCarousel;
