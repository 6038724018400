import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, FormGroup, Checkbox, makeStyles } from '@material-ui/core'

import { useSnackbar } from 'notistack';

import * as campaignApi from './../../../api/campaignApi';

const useStyles = makeStyles(() => ({
  formGroup: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 16,
  },
}));

const CampaignCandidateVideoVisibility = ({ campaignId, consultancyId, visibility, talentAlert }) => {

  const styles = useStyles();
  const [t] = useTranslation(['campaigns', 'snackbar']);
  const { enqueueSnackbar } = useSnackbar();

  const [disabled, setDisabled] = useState(false);
  const [state, setState] = useState({
    private: visibility.includes('private'),
    contacts: visibility.includes('contacts'),
    candidates: visibility.includes('candidates'),
  });

  const handleChange = (event) => {
    setDisabled(true);

    const updatedState = { ...state };
    updatedState[event.target.name] = event.target.checked;

    const visibilityStr = Object.keys(updatedState)
      .filter(k => updatedState[k])
      .join(',');

    campaignApi.updateCampaignCandidateVideoVisibility(campaignId, consultancyId, visibilityStr)
      .then(() => {
        setState({ ...updatedState });
        enqueueSnackbar(t('snackbar:visibilitySettingsSuccess'), { variant: 'success' });
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar(t('snackbar:visibilitySettingsFailure'), { variant: 'error' });
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <FormGroup row className={ styles.formGroup }>
      {
        !talentAlert && (
          <FormControlLabel
            label={ t("campaigns:campaignDocumentVisibilitySelectionContacts") }
            control={
              <Checkbox
                name="contacts"
                color="primary"
                disabled={ disabled }
                checked={ state.contacts }
                onChange={ handleChange } />
            } />
        )
      }

      <FormControlLabel
        label={ t("campaigns:campaignDocumentVisibilitySelectionCandidates") }
        control={
          <Checkbox
            name="candidates"
            color="primary"
            disabled={ disabled }
            checked={ state.candidates }
            onChange={ handleChange } />
        } />

      <FormControlLabel
        label={ t("campaigns:campaignDocumentVisibilitySelectionPrivate") }
        control={
          <Checkbox
            name="private"
            color="primary"
            disabled={ disabled }
            checked={ state.private }
            onChange={ handleChange } />
        } />
    </FormGroup>
  );

};

export default CampaignCandidateVideoVisibility;
