import React, { useEffect, useState } from "react";
import { Grid, Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import * as IntegrationsApi from "src/api/integrationsApi.js";

const useStyles = makeStyles(() => ({
  gridRow: {
    marginTop: "20px",
  },
  iframeGoone: {
    border: "none",
    marginRight: "50px"
  },
  link_with_pointer: {
    cursor: "pointer",
    marginTop: "64px",
    marginBottom: "4px"
  }
}));

function LearningHubAnon({ loId }) {
  const classes = useStyles();
  const [t] = useTranslation(["common", "snackbar"]);
  const [ playUrl, setPlayUrl ] = useState(null);

  useEffect(() => {
    async function fetchData() {
      let embedObj = await IntegrationsApi.getEmbedUrl(loId)
      setPlayUrl(embedObj.data)
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <ContentWrapper>
        <Grid container>
          <Link
            className={classes.link_with_pointer}
            href="/dashboard/retained-recruiter-academy"
          >
            <Grid container>
              <Grid item xs={6}>
                <ArrowBackIcon />
              </Grid>
              <Grid item xs={6}>
                {t("common:goBack")}
              </Grid>
            </Grid>
          </Link>
        </Grid>
        { playUrl ?
          <iframe
            title="LearningHubAnon"
            className={classes.iframeGoone}
            width="100%"
            height="680px"
            src={playUrl.url}
          /> : null }
      </ContentWrapper>
    </div>
  );
}

export default LearningHubAnon;
