import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, AppBar } from "@material-ui/core";
import * as EmployerApi from "src/api/employerApi";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { TabPanel } from "src/components/TabPanel";
import ContactProfile from "./ContactProfile";
import * as SysApi from "../../api/SystemAdminApi";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import { CampaignCard } from "src/components/Cards/CampaignCard";
import CustomTabs from './../../components/LayoutComponents/CustomTabs';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

function ContactDetailViews({ contactId, employerId, ...rest }) {
  const classes = useStyles();
  const consultancyId = useSelector((state) =>
    rest?.consultancyId ? rest.consultancyId : state.session.consultancyId
  );

  const { enqueueSnackbar } = useSnackbar();
  const [profile, setProfile] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [employer, setEmployer] = useState();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [enabled, setEnabled] = useState();

  const [t] = useTranslation(["common", "snackbar"]);

  const tabs = [
    { value: 0, label: t("common:profileLabel") },
    { value: 1, label: t("common:campaignsLabel") },
  ];

  const schema = {
    firstName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    telephone: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 20,
      },
    },
    addressLine1: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    city: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 32,
      },
    },
    postalCode: {
      presence: { allowEmpty: false, message: t("common:required") },
      format: /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i,
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const saveDetails = (event) => {
    event.preventDefault();
    let updatedContactDetails = formState.values;
    updatedContactDetails["contactId"] = contactId;
    return EmployerApi.updateContactById(
      contactId,
      consultancyId,
      updatedContactDetails
    )
      .then(() => {
        enqueueSnackbar(t("snackbar:contactUpdatedSuccessfully"), {
          variant: "success",
        });
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:failedToUpdateContactDetails"), {
          variant: "error",
        });
      });
  };

  const getContactById = function(contactId, consultancyId) {
    return EmployerApi.getContactById(contactId, consultancyId)
      .then((response) => {
        setProfile(response.data.profile);
        setProfilePicture(response.data.profilePicture);
        setCampaigns(response.data.campaigns);
        setEmployer(response.data.employer);
        let contactData = {
          salutation: response.data.profile.salutation,
          firstName: response.data.profile.firstName,
          lastName: response.data.profile.lastName,
          telephone: response.data.profile.telephone,
          addressLine1: response.data.profile.address.addressLine1,
          addressLine2: response.data.profile.address.addressLine2,
          postalCode: response.data.profile.address.postalCode,
          city: response.data.profile.address.city,
          country: response.data.profile.address.country,
          jobTitle: response.data.profile.jobTitle,
        };
        setFormState((initialState) => ({
          ...initialState,
          values: contactData,
        }));
        setEnabled(response.data.profile.enabled);
      })
      .catch(() => {
        history.push(`/dashboard/employers/${employerId}`);
      });
  };

  const toggleAccount = (event) => {
    SysApi.toggleContactUserStatus(consultancyId, contactId)
      .then((response) => {
        setEnabled(response.data.enabled);

        if (response.data.enabled) {
          enqueueSnackbar(t("snackbar:candidateAccountEnabled"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:candidateAccountDisabled"), {
            variant: "success",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:candidateAccountError"), {
          variant: "error",
        });
      });
  };

  const goToCampaign = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/campaigns/${campaignId}`);
  };

  function createData(name, data) {
    return { name, data };
  }
  const getCampaingOwnerName = (owner) =>
    owner
      ? ` ${owner.profile.salutation ? owner.profile.salutation : ""} ${
          owner.profile.firstName
        } ${owner.profile.lastName} `
      : "TBC";
  const getOwner = (admin, consultant, managedByRole) =>
    consultant && managedByRole === "consultant" ? consultant : admin;

  const getCampaignDetails = (campaign) => {
    const date = new Date(campaign.createdAt);
    const formattedDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}`;
    const employerName = campaign.assignmentCampaign
      ? campaign.assignmentCampaign.employer.employerName
      : "";
    const campaignStatus = campaign.campaignStatus
      ? campaign.campaignStatus.status
      : "draft";
    const campaignCandidates =
      campaign.candidates && campaign.candidates.length
        ? campaign.candidates.length
        : 0;

    const assignedTo = getCampaingOwnerName(
      getOwner(campaign.admin, campaign.consultant, campaign.managedByRole)
    );
    const campaignDetails = [
      createData(t(`campaigns:employerLabel`), employerName),
      createData(t(`campaigns:campaignStatusLabel`), campaignStatus),
      createData(t(`campaigns:dateStarted`), formattedDate),
      createData(t(`campaigns:candidatesLabel`), campaignCandidates),
      createData(t(`campaigns:assignedTo`), assignedTo),
    ];
    return campaignDetails;
  };

  useEffect(() => {
    if (!contactId) {
      history.push(`/dashboard/employers/${employerId}`);
    } else {
      getContactById(contactId, consultancyId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    setBreadcrumbs([
      { title: "Employers", link: "/employers" },
      { title: employer?.employerName, link: `/employers/${employerId}` },
      {
        title: `${profile?.firstName} ${profile?.lastName}`,
        link: `/employers/${employerId}/contacts/${contactId}`,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, employer]);

  return (
    <>
      {profile || employer ? (
        <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
          <PageTitle breadcrumbs={breadcrumbs} />
          <ContentWrapper>
            <AppBar position="static" className={classes.appBar}>
              <CustomTabs tabs={ tabs } onChange={ handleTabChange } />
            </AppBar>
            <TabPanel value={currentTab} index={0}>
              <Grid container spacing={3}>
                <ContactProfile
                  consultancyId={consultancyId}
                  contactId={contactId}
                  employer={employer}
                  profile={profile}
                  profilePicture={profilePicture}
                  saveDetails={saveDetails}
                  formState={formState}
                  toggleAccount={toggleAccount}
                  enabled={enabled}
                  handleChange={handleChange}
                  hasError={hasError}
                  handleCountryChange={handleCountryChange}
                />
              </Grid>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Grid container spacing={3}>
                {campaigns.map((campaign, i) => (
                  <Grid item md={4} sm={6} xs={12}>
                    <CampaignCard
                      campaignDetails={getCampaignDetails(campaign)}
                      name={campaign.campaignTitle}
                      btnLabel={t("common:viewMore")}
                      btnFunc={(event) => {
                        goToCampaign(event, campaign.id);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          </ContentWrapper>
        </div>
      ) : null}
    </>
  );
}

export default ContactDetailViews;
