import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DescriptionIcon from '@material-ui/icons/Description';
import IconListItem from 'src/components/LayoutComponents/IconListItem';
import CheckIcon from "@material-ui/icons/Check";
import {
  ADMIN,
  CONSULTANT,
  CANDIDATE,
  COREADMIN,
  SYSTEMADMIN,
  CONTACT,
} from 'src/constants/roles';

function RequiredDocumentListCard({ document, onRemove, setMethods, ...rest }) {
  const { setEditDocument, setAddDocumentModalOpen } = setMethods;
  const role = useSelector((state) => state.session.roleType);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [, setOpen] = useState(false);
  const [t] = useTranslation(['candidates', 'campaigns']);
  const adminContactPreviewMode = useSelector(
    (state) => state?.campaign?.adminContactPreview
  );

  const openEditModal = (text) => {
    setAddDocumentModalOpen(true);
    if (text) setEditDocument(document, true);
    else setEditDocument(document);
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    setRemoveLoading(true);
    onRemove(document.id).then(() => setRemoveLoading(false));
  };

  return (
    <div>
      {role === CONTACT || adminContactPreviewMode || rest?.guestView ? (
        <IconListItem
          startIcon={<DescriptionIcon />}
          href={document.url}
          text={document.name}
          startTip={t('common:viewDocument')}
          newWindow={true}
        />
      ) : ((role === ADMIN ||
          role === CONSULTANT ||
          role === COREADMIN ||
          role === SYSTEMADMIN) && //this condidtion is for admin, consultant or submitted candidate uploading candidate profile documents
          window.location.href.includes('candidates')) ||
        (role === CANDIDATE && !rest.isSubmitted) ? (
        <IconListItem
          newWindow={true}
          href={document.url}
          text={document.name}
          startIcon={
            document.url ? (
              <DescriptionIcon />
            ) : (
              <DescriptionIcon color='disabled' />
            )
          }
          startTip={document.url ? t('common:viewDocument') : null}
          endIcon={<EditIcon />}
          endFunc={openEditModal}
          endTip={t('common:editDocument')}
          startDisabled={document.url ? false : true}
          middleIcon = {document.url?   <CheckIcon />: null}
          middleTip={t('common:viewDocument')}
        />
      ) : (role === ADMIN ||
          role === CONSULTANT ||
          role === COREADMIN ||
          role === SYSTEMADMIN) && //this condidtion is for admin, consultant adding required document slots for candidate to upload
        !window.location.href.includes('candidates') ? (
        <IconListItem
          href={document.url}
          text={document.name}
          startIcon={<DeleteIcon />}
          startFunc={handleRemove}
          startTip={t('common:removeDocument')}
          startLoading={removeLoading}
          endIcon={<EditIcon />}
          endFunc={openEditModal}
          endTip={t('common:editDocument')}
        />
      ) : (
        <IconListItem
          startIcon={
            document.url ? (
              <DescriptionIcon />
            ) : (
              <DescriptionIcon color='disabled' />
            )
          }
          href={document.url}
          text={document.name}
          startTip={document.url ? t('common:viewDocument') : null}
          newWindow={true}
          startDisabled={document.url ? false : true}
        />
      )}
    </div>
  );
}
export default RequiredDocumentListCard;
