import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, AppBar, Tabs, Tab } from "@material-ui/core";
import { TabPanel } from "src/components/TabPanel";
import * as CandidateApi from "src/api/candidateApi";
import { SET_CANDIDATE_CAMPAIGN } from "src/actions/candidateCampaignActions";
import ContactPreviewCandidateDetails from "./ContactPreviewCandidateDetails";
import CandidateComments from "src/components/CandidateComments";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import * as actionTypes from "src/actions";

import PageBackButton from "src/components/LayoutComponents/PageBackButton";

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridRow: {
    marginTop: "20px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  tab: {
    color: theme.palette.primary.main,
  },
}));

function ContactPreviewCandidateProfile({ candidateId, campaignId }) {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation(["campaigns", "common", "snackbar", "vacancies"]);
  const dispatch = useDispatch();
  const [breadcrumbs, setBreadCrumbs] = useState([]);

  const candidateCampaign = useSelector(
    (state) => state.candidateCampaign.candidateProfile
  );
  const state = useSelector((state) => state);

  const [isLoading, setIsLoading] = useState(true);
  const { consultancyId, consultancySubscriptionPlanId } = useSelector((state) => state.session);
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [{ value: 0, label: t("candidates:candidateProfile") }];

  if (consultancySubscriptionPlanId !== TA_PRO) {
    tabs.push({ value: 1, label: t("campaigns:comments") });
  }

  const handleChange = (_event, value) => {
    setCurrentTab(value);
  };

  const getCandidateProfile = (consultancyId, candidateId, campaignId) => {
    setIsLoading(true);
    return CandidateApi.getCandidateProfile(
      consultancyId,
      candidateId,
      campaignId
    )
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: {
            candidateProfile: response.data,
          },
        });
      })
      .catch((error) => {
        history.push("/dashboard");
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCandidateProfile(consultancyId, candidateId, campaignId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadCrumbs([
      { title: t(`${campaignsPrefix}:campaigns`), link: "" },
      {
        title: candidateCampaign?.campaignTitle,
        link: consultancySubscriptionPlanId === TA_PRO ? `/vacancies/${candidateCampaign?.campaignId}` : `/campaigns/${candidateCampaign?.campaignId}`,
      },
      {
        title: `${candidateCampaign?.firstName} ${candidateCampaign?.lastName}`,
        link: consultancySubscriptionPlanId === TA_PRO ?
          `/vacancies/${candidateCampaign?.campaignId}/candidates/${candidateCampaign?.candidateId}` :
          `/campaigns/${candidateCampaign?.campaignId}/candidates/${candidateCampaign?.candidateId}`
      },
    ]);
  }, [candidateCampaign]);

  const toggleContactPreview = () => {
    dispatch({ type: actionTypes.TOGGLE_ADMIN_CONTACT_PREVIEW });
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <PageTitle
        breadcrumbs={breadcrumbs}
        btnLabel={
          state.campaign.adminContactPreview
            ? t("campaigns:cancelClientView")
            : null
        }
        btnFunc={() => toggleContactPreview()}
      />

      <ContentWrapper loading={isLoading}>
        <PageBackButton />

        <Grid item xs={12} className={classes.gridRow}>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              variant="scrollable"
              textColor="primary"
            >
              {tabs.map((tab) => (
                <Tab
                  className={classes.tab}
                  key={tab.value}
                  label={t(`common:${tab.label}`)}
                  value={tab.value}
                />
              ))}
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12} className={classes.gridRow}>
          <TabPanel value={currentTab} index={0}>
            <ContactPreviewCandidateDetails />
          </TabPanel>

          {
            consultancySubscriptionPlanId !== TA_PRO && (
              <TabPanel value={currentTab} index={1}>
                <CandidateComments
                  candidateCampaignId={candidateCampaign?.candidateId}
                  consultancyId={consultancyId}
                  campaignId={campaignId}
                  comments={candidateCampaign?.comments}
                />
              </TabPanel>
            )
          }
        </Grid>
      </ContentWrapper>
    </div>
  );
}

export default ContactPreviewCandidateProfile;
