import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Typography,
  CircularProgress,
  colors,
  Grid,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import {
  profileTypes,
  mcQauigCategories,
  profileMatches,
} from "src/constants/mcQuaig";
import QuillEditor from "src/components/QuillEditor";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  categories: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  subheadingText: {
    width: "100%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "space-between",
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function McQuaigModal({
  mcQuaig = {},
  onSubmit,
  open,
  onClose,
  candidate,
  headerTitle,
  passedTooltip,
}) {
  const classes = useStyles();
  const [t] = useTranslation(["candidates", "common", "newTranslations"]);
  const [loading, setLoading] = useState(false);
  let [scoresMatch, setScoresMatch] = useState(false);
  let [scoresTotal, setScoresTotal] = useState(0);

  const [formState, setFormState] = useState({
    values: {
      summary: "<p></p>",
    },
  });

  useEffect(() => {
    candidate
      ? setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            id: mcQuaig?.id || null,
            summary: mcQuaig?.summary || "",
            candidateMatch: mcQuaig?.candidateMatch || 0,
            realScores: mcQuaig?.realScores || {},
          },
        }))
      : setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            id: mcQuaig?.id || null,
            summary: mcQuaig?.summary || "",
            profileType: mcQuaig?.profileType || 0,
            scores: mcQuaig?.scores || {},
          },
        }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const checkCampaignScores = () => {
    let total = 0;
    for (let value in formState.values.scores) {
      total += parseInt(formState.values.scores[value]) || 0;
    }
    if (total !== 168) {
      setScoresMatch(false);
      setScoresTotal(total);
    } else {
      setScoresMatch(true);
      setScoresTotal(null);
    }
  };

  const checkCandidateScores = () => {
    let total = 0;

    for (let value in formState.values.realScores) {
      total += parseInt(formState.values.realScores[value]) || 0;
    }

    if (total !== 168) {
      setScoresMatch(false);
      setScoresTotal(total);
    } else {
      setScoresMatch(true);
      setScoresTotal(null);
    }
  };

  useEffect(() => {
    if (candidate) checkCandidateScores();
    else checkCampaignScores();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const handleChange = (event) => {
    let values = formState.values;
    if (!event.target) values.summary = event;
    else {
      let { name, value } = event.target;
      if (name.includes(".")) {
        let [parent, child] = name.split(".");
        if (value < 0) value = 0;
        values[parent][child] = value;
      } else values[name] = value;
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      values,
    }));
  };

  const handleSubmit = (event) => {
    setLoading(true);
    onSubmit(formState.values).then(() => setLoading(false));
  };

  return (
    <ModalFormWrapper
      formTitle={headerTitle}
      handleOpen={open}
      handleClose={onClose}
    >
      <Grid xs={12} className={classes.root}>
        <QuillEditor
          value={formState.values.summary}
          handleChange={handleChange}
          toolbarOptions={["link"]}
        />
      </Grid>
      <div className={classes.fields}>
        {candidate ? (
          <>
            <Typography style={{ fontWeight: "800" }} fullWidth variant="h4">
              {t("campaigns:mcQuaigProfileType")}
            </Typography>
            <TextField
              name="candidateMatch"
              value={formState.values.candidateMatch}
              select
              margin="dense"
              variant="outlined"
              SelectProps={{ native: true }}
              onChange={handleChange}
            >
              <option disabled value="" />
              {profileMatches.map((type) => (
                <option key={type.name} value={type.value}>
                  {t(`newTranslations:${type.name}`)}
                </option>
              ))}
            </TextField>
          </>
        ) : (
          <>
            <Typography fullWidth variant="h4">
              {t("campaigns:mcQuaigProfileType")}
            </Typography>
            <TextField
              name="profileType"
              value={formState.values.profileType}
              select
              margin="dense"
              variant="outlined"
              SelectProps={{ native: true }}
              onChange={handleChange}
            >
              <option disabled value="" />
              {profileTypes.map((type) => (
                <option key={type.name} value={type.value}>
                  {t(`newTranslations:${type.name}`)}
                </option>
              ))}
            </TextField>
          </>
        )}
      </div>
      <div className={classes.root}>
        <div style={{ display: "flex" }}>
          <Typography fullWidth variant="h4">
            {t("campaigns:mcQuaigBehaviouralScores")}
          </Typography>
          <Tooltip
            enterTouchDelay={1}
            classes={{
              tooltip: classes.tooltip,
            }}
            style={{ marginLeft: "10px" }}
            title={passedTooltip}
          >
            <InfoIcon />
          </Tooltip>
        </div>
        {candidate ? (
          <>
            <div className={classes.categories}>
              {mcQauigCategories.map((category) => (
                <div>
                  <Typography
                    fullWidth
                    style={{ padding: "10px 0" }}
                    variant="h5"
                  >
                    {category.toUpperCase()}
                  </Typography>
                  <TextField
                    name={`realScores.${category}`}
                    type="number"
                    defaultValue="0"
                    InputProps={{ inputProps: { min: 0, max: 168 } }}
                    value={formState.values?.realScores?.[category]}
                    variant="outlined"
                    onChange={handleChange}
                    placeholder="0"
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className={classes.categories}>
            {mcQauigCategories.map((category) => (
              <div>
                <Typography
                  fullWidth
                  style={{ padding: "10px 0" }}
                  variant="h5"
                >
                  {category.toUpperCase()}
                </Typography>
                <TextField
                  name={`scores.${category}`}
                  type="number"
                  defaultValue="0"
                  InputProps={{ inputProps: { min: 0, max: 168 } }}
                  value={formState.values?.scores?.[category]}
                  variant="outlined"
                  onChange={handleChange}
                  placeholder="0"
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {!scoresMatch && (
        <Typography variant="h5" fullWidth>
          {t("newTranslations:ensureScoreSet")} {scoresTotal}
        </Typography>
      )}
      <div className={classes.buttonGroup}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSubmit}
          disabled={!scoresMatch || loading}
        >
          {loading ? (
            <CircularProgress className={classes.progressCircle} size={25} />
          ) : (
            t("common:save")
          )}
        </Button>
      </div>
    </ModalFormWrapper>
  );
}
export default McQuaigModal;
