import React, { useState } from 'react';
import { Grid, TextField, Button, Typography } from '@material-ui/core';
import Create from '@material-ui/icons/Create';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import DocumentVisibilityCheckBox from 'src/views/Campaigns/CampaignDetail/CampaignDocuments/DocumentVisibilityCheckBox';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  subHeader: {
    marginTop: theme.spacing(2),
  },
  documentList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'no-wrap',
      flexDirection: 'column',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  buttonGroup: {
    marginLeft: '15px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
      marginTop: '10px',
    },
  },
  controlRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

const DefaultMessagesList = ({ messages, functions }) => {
  const [active, setActive] = useState(null);
  const {
    handleTitleChange,
    handleMessageChange,
    onSave,
    removeMessage,
    onNew,
    handleVisibilityChange,
  } = functions;

  const saveMessage = (message, index) => {
    onSave(message, index);
    setActive(null);
  };

  const newMessage = () => {
    onNew();
    setActive(messages.length);
  };

  const classes = useStyles();
  const [t] = useTranslation(['sysadmin', 'common', 'snackbar']);
  return (
    <Grid className={classes.documentList}>
      {messages?.map((message, index) => (
        <Grid
          item
          key={index.toString()}
          style={{ padding: '30px 0' }}
          xs={12}
          md={10}
          xl={6}
        >
          <Grid container spacing={3} className={classes.row}>
            <Grid item xs={12} md={2}>
              <Typography variant='h5'>
                {t('common:messageTitleLabel')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                id='standard-multiline-flexible'
                multiline
                fullWidth
                variant='outlined'
                value={message.name}
                disabled={active !== index}
                onChange={(event) => handleTitleChange(event, index)}
                autoFocus
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} className={classes.row}>
            <Grid item xs={12} md={2}>
              <Typography variant='h5'> {t('common:messageLabel')}</Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextField
                id='standard-multiline-flexible'
                multiline
                fullWidth
                variant='outlined'
                value={message.message}
                disabled={active !== index}
                onChange={(event) => handleMessageChange(event, index)}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {active !== index && <Create onClick={() => setActive(index)} />}
            </Grid>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography
              className={classes.subHeader}
              color='primary'
              variant='h4'
            >
              {t('campaigns:campaignDocumentVisibilitySelection')}
            </Typography>
            <Grid className={classes.controlRow}>
              <DocumentVisibilityCheckBox
                id={`${index}_visibility`}
                itemId={message.id}
                visibility={message.visibility}
                onChange={handleVisibilityChange}
                active={active !== index}
              />
              {active === index ? (
                <>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => saveMessage(message, index)}
                  >
                    {t('common:save')}
                  </Button>
                  <Button onClick={() => setActive(false)}>
                    {t('common:cancel')}
                  </Button>
                </>
              ) : (
                <Button onClick={() => removeMessage(message, index)}>
                  {t('common:remove')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid xs={12} style={{ marginTop: '15px' }}>
        <Button color='primary' variant='contained' onClick={newMessage}>
          {t('common:add')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default DefaultMessagesList;
