import * as actionTypes from 'src/actions';

const initialState = {
  open: false,
  notifications: [],
  notificationsCount: 0
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_NOTIFICATION_DRAWER: {
      return {
        ...state,
        open: !state.open,
      };
    }
    case actionTypes.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.data,
      };
    }
    case actionTypes.SET_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        notificationsCount: action.data.length,
      };
    }
    case actionTypes.SET_NOTIFICATION_READ: {
      let { notifications } = state;
      const id = action.data;
      const index = notifications
        .map(notification => notification.id)
        .indexOf(id);
      notifications[index].read = true;
      return {
        ...state,
        notifications: [...notifications],
      };
    }
    case actionTypes.DECREMENT_NOTIFICATIONS_COUNT: {
      const updatedNotificationsCount = state.notificationsCount - 1;

      return {
        ...state,
        notificationsCount: updatedNotificationsCount
      };
    }
    case actionTypes.CLEAR_NOTIFICATIONS_COUNT: {
      return {
        ...state,
        notificationsCount: 0
      };
    }
    default: {
      return state;
    }
  }
};

export default notificationsReducer;
