import React, { useEffect } from 'react';

import { useFormik } from 'formik';

import { Button, Card, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next';

import * as Yup from 'yup';

import SingleOverview from './SingleOverview';

import { BlockHeader } from './../../../../components/LayoutComponents/BlockHeader';
import { CardButton } from './../../../../components/LayoutComponents/CardButton';

import { updateCandidateOverviews } from './../../../../api/talentAlertApi';

const useStyles = makeStyles(() => ({
  overviews: {
    padding: '20px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  button: {
    display: 'block',
    margin: 'auto',
    marginTop: '20px'
  }
}));

const CandidateOverview = ({ candidateId, consultancyId, campaignId, overviews }) => {

  const [t] = useTranslation(['validation', 'snackbar', 'newTranslationsTA', 'tooltips']);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const CreateOverviewValidationSchema = Yup.object().shape({
    overviews: Yup.array().of(
      Yup.object().shape({
        point: Yup.string().required(t('newTranslationsTA:talentAlertOverviewInputErrorRequired')),
        pointValue: Yup.string().required(t('newTranslationsTA:talentAlertOverviewInputErrorRequired'))
      })
    ).min(1)
  });

  const formik = useFormik({
    initialValues: {
      overviews: []
    },
    validateOnMount: true,
    validationSchema: CreateOverviewValidationSchema,
    onSubmit: (values) => {
      if (values) {
        updateCandidateOverviews(candidateId, consultancyId, campaignId, values)
          .then(() => {
            enqueueSnackbar(t('snackbar:saveOverviewsNotificationSuccess'), { variant: 'success' });
          })
          .catch(err => {
            console.log(err);
            enqueueSnackbar(t('snackbar:overviewNotificationError'), { variant: 'error' });
          });
      }
    }
  });

  useEffect(() => {
    formik.setValues({
      overviews: overviews
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOverview = (event) => {
    event.preventDefault();

    formik.setValues({
      ...formik.values,
      overviews: [
        ...formik.values.overviews,
        {
          point: '',
          pointValue: ''
        }
      ]
    });
  };

  return (
    <Card className={classes.overviews}>
      <div>
        <BlockHeader
          headerTooltip={t("tooltips:writeOverviewFactsForCandidate")}
          card
          padding
          header={t(`newTranslations:candidateOverview`)} />
        
        <form
          className={classes.form}
          onSubmit={formik.handleSubmit} >
          <Grid xs={12}>
            <Grid xs={12} className={classes.innerGrid} >
              {
                formik.values.overviews.map((overview, index) => (
                  <SingleOverview
                    key={`overview-row_${index}`}
                    formik={formik}
                    index={index} />
                ))
              }
            </Grid>
            
            <Button
              className={classes.button}
              type="button"
              onClick={addOverview} >
              { t("newTranslationsTA:Add Overview") }
            </Button>
          </Grid>
          
          <Grid xs={12} >
            <CardButton
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              disabled={!formik.isValid} >
              { t("newTranslationsTA:talentAlertOverviewSubmitBtn") }
            </CardButton>
          </Grid>
        </form>
      </div>
    </Card>
  );

};

export default CandidateOverview;
