import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const AddToCampaignButton = ({ btnFunc, btnDisabled ,loading ,fullWidth}) => {
  const [t] = useTranslation([
    'campaigns',
  ]);
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={btnFunc}
      disabled={btnDisabled}
      fullWidth={fullWidth}
    >

    {loading ? (
			<div style={{ textAlign: "center" }}>
				<CircularProgress size={16}/>
				</div>
							) : (
								<>
							    {t('campaigns:addToCampaign')}
								</>
							)}
    </Button>
  );
};

export default AddToCampaignButton;
