import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as modulesApi from "src/api/modulesApi";
import Page from "src/components/Page";
import { SET_KCQ_CATEGORIES } from "../../../actions/ConsultancyModules/kcq/kcqActions";
import KCQSettings from "./kcqSettings";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  editButton: {
    paddingRight: "20px",
  },
  list: {
    padding: "0",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: "15px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  gridRow: {
    marginTop: "20px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

function KCQManagement() {
  const classes = useStyles();
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const [t] = useTranslation(["modules", "common", "snackbar"]);
  const dispatch = useDispatch();

  const getGlobalKCQCategories = () => {
    return modulesApi.getGlobalKCQCategories(consultancyId).then((response) => {
      dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
    });
  };

  useEffect(() => {
    getGlobalKCQCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Page className={classes.root} title={t("modules:kcqPageTitle")}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <KCQSettings />
          </Grid>
        </Grid>
      </Page>
    </>
  );
}

export default KCQManagement;
