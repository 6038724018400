import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  CircularProgress
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import QuillEditor from 'src/components/QuillEditor';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "33%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  },
  buttonContainer: {
    textAlign: 'center'
  },
  resetButton: {
    marginBottom: theme.spacing(2),
    width: 'auto',
    alignSelf: 'right'
  },
  resetButtonWrapper: {
    textAlign: 'right'
  }
}));

function EditBRModal({ onSuccess, open, handleClose, textToChange }) {
  const classes = useStyles();
  const [t] = useTranslation(["campaigns", "common"]);

  const [loading, setLoading] = useState(false);

  const [newText, setNewText] = useState(textToChange);

  const onSubmit = function(event) {
    event.preventDefault();
    setLoading(true);
    onSuccess(newText)
    .then(()=>{
      setLoading(false);
      handleClose();
    })
  };

  const handleTextChange = (value) => {
    setNewText(value);
  };

  const resetToDefault = () => {
    setNewText(textToChange);
  }

  return (
    <ModalFormWrapper
      formTitle={t('campaigns:update')}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth="md"
    >
      <div className={classes.resetButtonWrapper}>
        <Button
          className={classes.resetButton}
          color="primary"
          size="small"
          variant="contained"
          onClick={resetToDefault}
        >
          {t('campaigns:resetToDefault')}
        </Button>
      </div>
      <form onSubmit={onSubmit}>
        <QuillEditor value={newText} handleChange={handleTextChange} toolbarOptions={['link']}/>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.submitButton}
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            {
              loading 
                ? <CircularProgress className={classes.progressCircle} size={25}/>
                : t('common:update') 
            }
          </Button>
        </div>
      </form>
    </ModalFormWrapper>
  );
}
export default EditBRModal;
