import axios from '../utils/axios';

export const search = (consultancyId, term, from = 0) => {
  return axios
    .get('/Searches/search',{
        params: {
            consultancyId,
            term,
            from
        }
    })
    .then((response) => {
        return response.data
    })
    .catch((error) => {
      throw error;
    });
};
