import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
  ExpansionPanelSummary,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import * as CampaignApi from "src/api/campaignApi";
import {
  SET_CAMPAIGN_DWI,
  UPDATE_CAMPAIGN_CONTACTS,
} from "src/actions/campaignActions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

//TODO:Translate this page

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  categoryTitle: {
    marginBottom: "20px",
  },
  subCategoryTitle: {
    marginTop: "20px",
  },
  categoryWeightLabel: {
    display: "inline",
  },
  submitButton: {
    marginTop: "40px",
    textAlign: "center",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
}));

function DynamicWeightIndex({ guestView }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation([
    "common",
    "dwi",
    "campaigns",
    "snackbar",
    "tooltips",
    "newTranslations",
  ]);
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const [campaignDWICategories, setCampaignDWICategories] = useState(
    [...campaign.campaignDWICategories?.campaignDWICategories] || []
  );
  const [contactWeighting, setContactWeighting] = useState([
    ...campaign.campaignContacts,
  ]);
  const [isCampaignDWIValid, setIsCampaignDWIValid] = useState(false);
  const [isContactWeightingValid, setIsContactWeightingValid] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const onCategoryChange = (event) => {
    event.persist();
    let tempCategories = [...campaignDWICategories];
    tempCategories[event.target.name].checked = event.target.checked;
    setCampaignDWICategories([...tempCategories]);
  };

  const handleCategoryWeightChange = (event, index) => {
    event.persist();
    let tempCategories = [...campaignDWICategories];
    tempCategories[index].weight = event.target.value;
    setCampaignDWICategories([...tempCategories]);
  };

  const onSubcategoryWeightChange = (
    event,
    categoryIndex,
    subcategoryIndex
  ) => {
    event.persist();
    let tempCategories = [...campaignDWICategories];
    tempCategories[categoryIndex].subcategories[subcategoryIndex].weight =
      event.target.value;
    setCampaignDWICategories([...tempCategories]);
  };

  const onContactWeightChange = (event, contactIndex) => {
    event.persist();
    let tempContacts = [...contactWeighting];
    tempContacts[contactIndex].weight = event.target.value;
    setContactWeighting([...tempContacts]);
  };

  const calculateContactWeight = () => {
    let totalWeight = contactWeighting.reduce(function(total, contact) {
      return total + Number(contact.weight);
    }, 0);
    return totalWeight;
  };

  const calculateSubcategoryWeight = (index) => {
    let totalWeight = campaignDWICategories[index].subcategories?.reduce(
      function(total, subcategory) {
        return total + Number(subcategory.weight);
      },
      0
    );
    return totalWeight;
  };

  const saveCampaignDWICategories = (event) => {
    event.preventDefault();
    setIsSaving(true);
    return CampaignApi.updateCampaignDWICategories(
      campaign.id,
      consultancyId,
      campaignDWICategories
    )
      .then((response) => {
        dispatch({ type: SET_CAMPAIGN_DWI, categories: response.data });
        enqueueSnackbar(t("snackbar:campaignDWIUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:campaignDWIUpdateFailed"), {
          variant: "error",
        });
      })
      .then(() => {
        setIsSaving(false);
      });
  };

  const saveCampaignContactWeights = (event) => {
    event.preventDefault();
    setIsSaving(true);
    return CampaignApi.updateCampaignContactWeights(
      consultancyId,
      campaign.id,
      contactWeighting
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_CONTACTS,
          campaignContacts: contactWeighting,
        });
        enqueueSnackbar(t("snackbar:campaignContactWeightSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:campaignContactWeightFailed"), {
          variant: "error",
        });
      })
      .then(() => {
        setIsSaving(false);
      });
  };

  useEffect(() => {
    let checkedCampaignCategories = campaignDWICategories.filter(
      (category) => category.checked
    );
    let isValid = true;
    checkedCampaignCategories.forEach((category) => {
      let subCategoryTotalWeight = category.subcategories?.reduce(function(
        total,
        subcategory
      ) {
        return total + Number(subcategory.weight);
      },
      0);
      if (subCategoryTotalWeight !== 100) {
        isValid = false;
      }
    });
    let categoryTotalWeight = checkedCampaignCategories.reduce(function(
      total,
      category
    ) {
      return total + Number(category.weight);
    },
    0);
    if (categoryTotalWeight !== 100) {
      isValid = false;
    }
    setIsCampaignDWIValid(isValid);
  }, [campaignDWICategories]);

  useEffect(() => {
    let isContactValid = true;
    let contactTotalWeight = calculateContactWeight();
    if (contactTotalWeight !== 100) {
      isContactValid = false;
    }
    setIsContactWeightingValid(isContactValid);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactWeighting]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <Card>
          <BlockHeader
            card
            padding
            headerTooltip={t("tooltips:contactWeighting")}
            header={t("dwi:contactWeighting")}
          />
          <CardContent>
            <Grid container spacing={3}>
              {contactWeighting?.map((contact, index) => (
                <>
                  <Grid item xs={12} lg={8}>
                    <Typography variant="h5">
                      {contact.firstName && contact.lastName
                        ? `${contact.firstName} ${contact.lastName}`
                        : `${contact.email}`}
                    </Typography>
                    <Typography variant="h5">{contact.email}</Typography>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <TextField
                      name="weight"
                      value={contact.weight}
                      onChange={(event) => onContactWeightChange(event, index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      disabled={guestView}
                    />
                  </Grid>
                </>
              ))}
            </Grid>
            {!guestView && (
              <div style={{ marginTop: 32 }}>
                <CardButton
                  btnLabel={t("common:update")}
                  btnFunc={saveCampaignContactWeights}
                  btnVariant="contained"
                  btnDisabled={!isContactWeightingValid || isSaving}
                  loading={isSaving}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card>
          <BlockHeader
            card
            padding
            headerTooltip={t("tooltips:categoriesLabel")}
            header={t("dwi:categoriesLabel")}
          />
          <CardContent>
            {campaignDWICategories?.map((category, index) => (
              <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                  <FormControlLabel
                    aria-label={"Behaviour"}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    control={
                      <Checkbox
                        color="primary"
                        name={index}
                        checked={category.checked}
                        onClick={onCategoryChange}
                        disabled={guestView}
                      />
                    }
                    label={
                      <Typography variant="h5">
                        {category?.name} - {t("dwi:weight")}: {category.weight}%
                      </Typography>
                    }
                  />
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Typography
                        variant="h5"
                        className={classes.categoryWeightLabel}
                      >
                        {t("dwi:categoryWeight")}:{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        value={category.weight}
                        disabled={!category.checked}
                        name={index}
                        onChange={(event) =>
                          handleCategoryWeightChange(event, index)
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h5">
                        {t("dwi:subCategory")}:{" "}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} lg={8}>
                      <Typography variant="h5">{t("common:name")}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="h5">{t("dwi:weighting")}</Typography>
                    </Grid>
                    {category.subcategories?.map(
                      (subcategory, subcategoryIndex) => (
                        <>
                          <Grid item xs={12} lg={8}>
                            <TextField
                              name="name"
                              fullWidth
                              variant="outlined"
                              disabled={true}
                              value={subcategory.name}
                              required
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <TextField
                              name="weight"
                              fullWidth
                              disabled={!category.checked || guestView}
                              value={subcategory.weight}
                              onChange={(event) =>
                                onSubcategoryWeightChange(
                                  event,
                                  index,
                                  subcategoryIndex
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    %
                                  </InputAdornment>
                                ),
                              }}
                              variant="outlined"
                            />
                          </Grid>
                        </>
                      )
                    )}
                    <Grid item xs={12} lg={6}></Grid>
                    <Grid item xs={12} lg={2}>
                      <Typography variant="h5" style={{ textAlign: "right" }}>
                        {t("common:total")}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography
                        variant="h5"
                        color={
                          calculateSubcategoryWeight(index) !== 100
                            ? "error"
                            : "textPrimary"
                        }
                      >
                        {calculateSubcategoryWeight(index)}%
                      </Typography>
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
            {!guestView && (
              <CardButton
                btnLabel={t("common:update")}
                btnFunc={saveCampaignDWICategories}
                btnVariant="contained"
                btnDisabled={!isCampaignDWIValid || isSaving}
                loading={isSaving}
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default DynamicWeightIndex;
