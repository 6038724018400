import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Typography,
    IconButton,
    TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Page from "src/components/Page";
import ClearIcon from "@material-ui/icons/Clear";
import SaveIcon from "@material-ui/icons/Save";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import * as ContactApi from "src/api/contactApi";
import {
    UPDATE_CAMPAIGN_KCQ,
    CREATE_CAMPAIGN_KCQS,
} from "src/actions/campaignActions";
import DefaultKCQSelect from "src/views/Campaigns/CampaignDetail/CampaignKeyCompetencyQuestions/DefaultKCQSelect";
import AddToCampaignButton from "src/components/LayoutComponents/AddToCampaignButton";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: theme.spacing(6, 2),
        flexGrow: 1,
    },
    card: {
        width: theme.breakpoints.values.md,
        maxWidth: "100%",
        overflow: "visible",
        display: "flex",
        position: "relative",
        "& > *": {
            flexGrow: 1,
            flexBasis: "50%",
            width: "50%",
        },
    },
    content: {
        padding: theme.spacing(4, 4, 3, 4),
    },
    question: {
        margin: "10px 0px",
        display: "inline",
    },
    questionEdit: {
        margin: "10px 0px",
        display: "inline",
    },
    questionWrapper: {
        padding: "10px 5px",
        backgroundColor: "#f5f5f5",
    },
    input: {
        fontSize: "0.83em",
        width: "100%",
        fontWeight: 400,
        lineHeight: 1.7,
        letterSpacing: "0em",
        padding: 0,
    },
    questionButtons: {
        padding: "0px 10px",
    },
    titleHeader: {
        marginBottom: "20px",
    },
    submitButtonContainer: {
        textAlign: "center",
        marginTop: "20px",
    },
    newQuestionWrapper: {
        margin: "20px 5px",
    },
    categoryTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: 2,
        padding: "10px 5px",
        marginTop: "60px",
    },
    icon: {
        height: "20px",
        width: "20px",
    },
    categoryWrapper: {
        padding: "0px 10px",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "5px",
    },
    buttons: {
        textAlign: "center",
        marginTop: "20px",
    },
}));

function ContactCampaignKCQSelection({ contact_access_token }) {
    const classes = useStyles();
    const [t] = useTranslation(["campaigns", "snackbar"]);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();

    const campaign = useSelector((state) => state.campaign);
    const [isLoading, setIsLoading] = useState(false);
    const [kcqCategories, setKcqCategories] = useState([]);
    const [questionEditMode, setQuestionEditMode] = useState(false);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            categories: campaign.currentCampaignKcq?.questions?.map((category) => {
                return {
                    ...category,
                    newQuestion: "",
                    questions: category.questions.map((question) => {
                        return {
                            originalQuestion: question,
                            newQuestion: question,
                            editing: false,
                        };
                    }),
                };
            }),
        },
        touched: {},
        errors: {},
    });

    let addNewTemplatedKCQsDefaultState = {
        selectFromTemplate: false,
        isValid: false,
        values: {
            questionSetTitle: "",
            selectedQuestions: [formState.values.categories],
            inviteContacts: false,
            selectedContact: {
                email: "",
            },
            createContact: false,
        },
        touched: false,
    };

    const [newTemplatedKCQs, setNewTemplatedKCQs] = useState(
        addNewTemplatedKCQsDefaultState
    );

    const handleAddNewTemplatedQuestions = (selectedQuestionSet) => {
        setNewTemplatedKCQs((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                selectedQuestions: [...selectedQuestionSet],
            },
            touched: true,
        }));
    };

    const handleSubmitNewTemplatedQuestions = (event) => {
        event.preventDefault();
        setIsLoading(true);
        return onSuccess(contact_access_token, newTemplatedKCQs.values).then(() => {
            setIsLoading(false);
            setNewTemplatedKCQs({
                isValid: false,
                values: {
                    inviteContacts: false,
                    selectedContact: {
                        email: "",
                    },
                    questionSetTitle: "",
                    selectedQuestions: [],
                    createContact: false,
                },
                touched: {},
            });
            resetForm();
        });
    };

    const updateCampaignKCQ = (questions) => {
        return ContactApi.updateCampaignKCQ(contact_access_token, questions)
            .then(() => {
                history.push("/");
                enqueueSnackbar(t("snackbar:contactKCQSuccess"), {
                    variant: "success",
                });
                dispatch({ type: UPDATE_CAMPAIGN_KCQ, kcqs: questions });
            })
            .catch((error) => {
                enqueueSnackbar(t("snackbar:contactKCQFailed"), { variant: "error" });
            });
    };

    const cancelEditing = (event, categoryIndex, questionIndex) => {
        event.preventDefault();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions[questionIndex].newQuestion =
            tempCategories[categoryIndex].questions[questionIndex].originalQuestion;
        tempCategories[categoryIndex].questions[questionIndex].editing = false;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };

    const handleQuestionChange = (event, categoryIndex, questionIndex) => {
        event.persist();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions[questionIndex].newQuestion =
            event.target.value;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };

    const handleNewQuestionInput = (event, categoryIndex) => {
        event.persist();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].newQuestion = event.target.value;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };

    const saveCampaignKCQ = (event, categoryIndex, questionIndex) => {
        setQuestionEditMode(false);
        event.preventDefault();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions[questionIndex].originalQuestion =
            tempCategories[categoryIndex].questions[questionIndex].newQuestion;
        tempCategories[categoryIndex].questions[questionIndex].editing = false;
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };

    const saveNewCampaignKCQ = (event, categoryIndex) => {
        setQuestionEditMode(false);
        event.preventDefault();
        let tempCategories = [...formState.values.categories];
        tempCategories[categoryIndex].questions.push({
            newQuestion: tempCategories[categoryIndex].newQuestion,
            originalQuestion: tempCategories[categoryIndex].newQuestion,
        });
        tempCategories[categoryIndex].newQuestion = "";
        setFormState((prevValues) => ({
            ...prevValues,
            values: {
                ...prevValues.values,
                categories: [...tempCategories],
            },
        }));
    };

    const saveKCQ = (event) => {
        event.preventDefault();
        let categories = formState.values.categories.map((category) => {
            return {
                ...category,
                questions: category.questions.map((question) => {
                    return question.newQuestion;
                }),
            };
        });
        updateCampaignKCQ(categories);
    };

    const getCampaignKCQS = (contact_access_token) => {
        return ContactApi.getCampaignKCQ(contact_access_token)
            .then((response) => {
                dispatch({
                    type: CREATE_CAMPAIGN_KCQS,
                    currentCampaignKcq: response.data.campaignKCQ,
                });

                setKcqCategories(response.data.kcqCategories);
            })
            .catch(() => {
                history.push("/");
                enqueueSnackbar(t("campaigns:invalidContactAccessToken"), {
                    variant: "error",
                });
            });
    };

    const resetForm = () => {
        setNewTemplatedKCQs(addNewTemplatedKCQsDefaultState);
    };
    const onSuccess = (contact_access_token, kcqData) => {
        return ContactApi.assignKCQToCampaign(contact_access_token, kcqData)
            .then((response) => {
                if (response.status === 200) {
                    enqueueSnackbar(t("snackbar:kcqAddedToCampaign"), {
                        variant: "success",
                    });
                } else {
                    enqueueSnackbar(t("snackbar:kcqUpdateFailed"), { variant: "error" });
                }
                dispatch({
                    type: CREATE_CAMPAIGN_KCQS,
                    currentCampaignKcq: {
                        name: response.data.name,
                        questions: response.data.questions,
                    },
                });
            })
            .catch((error) => {
                enqueueSnackbar(t("snackbar:kcqUpdateFailed"), { variant: "error" });
            });
    };

    useEffect(() => {
        setFormState({
            isValid: false,
            values: {
                categories: campaign.currentCampaignKcq?.questions.map((category) => {
                    return {
                        ...category,
                        newQuestion: "",
                        questions: category.questions.map((question) => {
                            return {
                                originalQuestion: question,
                                newQuestion: question,
                                editing: false,
                            };
                        }),
                    };
                }),
            },
            touched: {},
            errors: {},
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign.currentCampaignKcq?.questions]);

    useEffect(() => {
        getCampaignKCQS(contact_access_token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (!kcqCategories.length) {
        return (
            <div style={{ textAlign: "center", width: "100%", marginTop: "200px" }}>
                <CircularProgress />
            </div>
        );
    }
    return (
        <Page
            className={classes.root}
            title={t("campaigns:keyCompetencyQuestionsSelection")}
        >
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    {!questionEditMode && Object.keys(campaign.currentCampaignKcq).length === 0 && (
                        <>
                            <Typography
                                variant="h3"
                                color="primary"
                                className={classes.titleHeader}
                            >

                                {t("campaigns:keyCompetencyQuestionsSelection")}
                            </Typography>
                            <DefaultKCQSelect
                                categories={kcqCategories}
                                handleQuestionChange={handleAddNewTemplatedQuestions}
                            />
                            <div className={classes.buttons}>

                                <AddToCampaignButton
                                    loading={isLoading}
                                    btnFunc={handleSubmitNewTemplatedQuestions}
                                    btnDisabled={!newTemplatedKCQs.touched || isLoading}
                                    fullWidth
                                />
                            </div>
                        </>
                    )}
                    {formState.values?.categories && (
                        <>
                            <Typography
                                variant="h3"
                                color="primary"
                                className={classes.titleHeader}
                            >

                                {t("campaigns:questionsAddedToCampaign")}
                            </Typography>
                            {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={questionEditMode}
                                            onChange={handleInputModeChange}
                                            name="inputModeToggle"
                                            color="primary"
                                        />
                                    }
                                    label={
                                        <Typography variant="h5">

                                            {t("modules:addNewQuestions")}
                                        </Typography>
                                    }
                                />
                            </Grid> */}
                        </>
                    )}
                    {formState.values?.categories?.map((category, categoryIndex) => (
                        <>
                            <Typography variant="h4" className={classes.categoryTitle}>
                                {category.name}
                            </Typography>
                            {category?.questions.map((question, questionIndex) => (
                                <>
                                    <div className={classes.questionWrapper}>

                                        {!question.editing ? (
                                            <>
                                                <Typography variant="h5" className={classes.question}>

                                                    {question.originalQuestion}
                                                </Typography>
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row-reverse",
                                                    }}
                                                >
                                                </span>
                                            </>
                                        ) : (
                                            <div style={{ background: "white" }}>
                                                <TextField
                                                    multiline
                                                    id={questionIndex}
                                                    value={question.newQuestion}
                                                    className={classes.questionEdit}
                                                    InputProps={{
                                                        className: classes.input,
                                                        disableUnderline: true,
                                                    }}
                                                    onChange={(event) =>
                                                        handleQuestionChange(
                                                            event,
                                                            categoryIndex,
                                                            questionIndex
                                                        )
                                                    }
                                                />
                                                <span
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row-reverse",
                                                    }}
                                                >
                                                    <ThemeIconButton
                                                        icon={<SaveIcon />}
                                                        btnFunc={(event) =>
                                                            saveCampaignKCQ(
                                                                event,
                                                                categoryIndex,
                                                                questionIndex
                                                            )
                                                        }
                                                    />
                                                    <IconButton
                                                        className={classes.questionButtons}
                                                        color="primary"
                                                        onClick={(event) =>
                                                            cancelEditing(event, categoryIndex, questionIndex)
                                                        }
                                                    >
                                                        <ThemeIconButton
                                                            icon={<ClearIcon />}
                                                        ></ThemeIconButton>
                                                    </IconButton>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ))}
                            <div className={classes.newQuestionWrapper}>
                                <div className={classes.newQuestion}>

                                    {questionEditMode && (
                                        <>
                                            <TextField
                                                multiline
                                                fullWidth={true}
                                                variant="outlined"
                                                placeholder={t("campaigns:kcqAddNewQuestion")}
                                                value={category.newQuestion}
                                                className={classes.questionEdit}
                                                InputProps={{
                                                    input: classes.input,
                                                }}
                                                onChange={(event) =>
                                                    handleNewQuestionInput(event, categoryIndex)
                                                }
                                                inputProps={{ disableUnderline: true }}
                                            />
                                            <div style={{ float: "right", marginTop: 8 }}>
                                                <ThemeIconButton
                                                    disabled={!category.newQuestion || isLoading}
                                                    icon={<SaveIcon />}
                                                    btnFunc={(event) =>
                                                        saveNewCampaignKCQ(event, categoryIndex)
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ))}
                    {!questionEditMode && Object.keys(campaign.currentCampaignKcq).length === 0 && formState.values?.categories && (
                        <div style={{ textAlign: "center", marginTop: "40px" }}>
                            <Button
                                disabled={newTemplatedKCQs.touched}
                                fullWidth
                                color="primary"
                                variant="contained"
                                size="medium"
                                onClick={saveKCQ}
                            >

                                {t("campaigns:saveAndExit")}
                            </Button>
                        </div>
                    )}
                </CardContent>
            </Card>
        </Page>
    );
}

export default ContactCampaignKCQSelection;
