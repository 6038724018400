import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as CampaignApi from 'src/api/campaignApi';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import { UPDATE_CAMPAIGN_SCHEDULER } from 'src/actions/candidateCampaignActions';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  buttonGroup: {
    marginTop: theme.spacing(8),
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  confirmButton: {
    marginLeft: '10px',
  },
  rejectButton: {
    marginRight: '10px',
  },
}));

function ViewModal({ event, onCancel, open, timeZone }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(['candidates', 'common', 'snackbar', 'campaigns']);
  const { enqueueSnackbar } = useSnackbar();
  const [loading] = useState(false);
  const campaign = useSelector((state) => state.campaign);
  const candidateCampaign = useSelector((state) => state.candidateCampaign);
  const campaignId = campaign.id || candidateCampaign.campaignId;
  const consultancyId = useSelector((state) => state.session.consultancyId);
  moment.tz.setDefault(timeZone);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      ...event,
    },
    touched: {},
    errors: {},
  });

  const onConfirm = (event) => {
    event.preventDefault();
    return CampaignApi.confirmCandidateCalendarEntry(
      consultancyId,
      campaignId,
      formState.values.id
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_SCHEDULER,
          campaignEvents: response.data,
        });
        enqueueSnackbar(t('snackbar:calendarEntryConfirmSuccess'), {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t('snackbar:calendarEntryConfirmFailure'), {
          variant: 'error',
        });
      })
      .then(() => {
        onCancel();
      });
  };

  const onReject = (event) => {
    event.preventDefault();
    return CampaignApi.rejectCandidateCalendarEntry(
      consultancyId,
      campaignId,
      formState.values.id
    )
      .then((response) => {
        dispatch({
          type: UPDATE_CAMPAIGN_SCHEDULER,
          campaignEvents: response.data,
        });
        enqueueSnackbar(t('snackbar:calendarEntryRejectSuccess'), {
          variant: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t('snackbar:calendarEntryRejectFailure'), {
          variant: 'error',
        });
      })
      .then(() => {
        onCancel();
      });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...event,
        },
      }));
    }
    return () => {
      mounted = false;
    };
  }, [event]);

  return (
    <ModalFormWrapper
      formTitle={formState.values.title}
      handleOpen={open}
      handleClose={onCancel}
      maxWidth='sm'
    >
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant='h4'>{formState.values.description}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h2'>{t('common:interviewers')}:</Typography>
            {formState.values.contacts?.map((contact) => (
              <>
                <Typography variant='h4'>
                  {contact.firstName} {contact.lastName}
                </Typography>
                <Typography variant='h5'>{contact.jobTitle}</Typography>
              </>
            ))}
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h2'>
              {formState.values.candidate ? `${t('common:interviewees')}` : ''}
            </Typography>
            <Typography variant='h4'>
              {formState.values.candidate?.firstName}{' '}
              {formState.values.candidate?.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h2'>{t('common:time')}:</Typography>
            <Typography variant='h4'>
              {moment(formState.values.start)
                .tz(timeZone)
                .format('LT - ')}
              {moment(formState.values.end)
                .tz(timeZone)
                .format('LT, dddd Do MMMM YYYY')}
            </Typography>
          </Grid>
        </Grid>

        <div className={classes.buttonGroup}>
          {formState.values.canReject ? (
            <Button
              className={classes.rejectButton}
              size='medium'
              variant='contained'
              onClick={onReject}
            >
              {loading ? (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                />
              ) : (
                `${t('common:decline')}`
              )}
            </Button>
          ) : null}
          {formState.values.canApprove ? (
            <Button
              className={classes.confirmButton}
              color='primary'
              size='medium'
              variant='contained'
              onClick={onConfirm}
            >
              {loading ? (
                <CircularProgress
                  className={classes.progressCircle}
                  size={25}
                />
              ) : (
                `${t('common:confirm')}`
              )}
            </Button>
          ) : null}
        </div>
      </form>
    </ModalFormWrapper>
  );
}
export default ViewModal;
