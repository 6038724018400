import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, yellow } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  admin: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  consultant: {
    color: '#fff',
    backgroundColor: yellow[500],
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

export default function UserTypeAvatar({ role }) {
  const classes = useStyles();

  return role ? (
    <div className={classes.root}>
      <SmallAvatar
        variant='circle'
        className={
          role.toLowerCase() === 'admin' ? classes.admin : classes.consultant
        }
      >
        {role.toUpperCase().slice(0, 1)}
      </SmallAvatar>
    </div>
  ) : null;
}
