import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
	saveDefaultWelcomeMessage,
	removeDefaultWelcomeMessage,
} from "src/api/SystemAdminApi";
import { useSnackbar } from "notistack";
import DefaultMessagesList from "src/components/DefaultMessagesList";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	header: {
		marginBottom: theme.spacing(2),
	},
	subHeader: {
		marginTop: theme.spacing(2),
	},
}));

const DefaultWelcomeMessages = ({ defaultMessages }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(["sysadmin", "common", "snackbar"]);
	const [messages, setMessages] = useState([]);
	const classes = useStyles();

	const saveWelcomeMessage = (message, index) => {
		return saveDefaultWelcomeMessage(message)
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar(t("snackbar:savedDefaultMessage"), {
						variant: "success",
					});
					let tempMessages = messages;
					tempMessages[index] = response.data;
					setMessages([...tempMessages]);
				} else
					enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
						variant: "error",
					});
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(t("snackbar:errorDefaultMessage"), {
					variant: "error",
				});
			});
	};

	const newMessage = () => {
		setMessages((prevMessages) => [
			...prevMessages,
			{
				name: "",
				message: "",
				visibility: "private",
			},
		]);
	};

	const removeMessage = (message, index) => {
		if (message.id) {
			removeDefaultWelcomeMessage(message.id)
				.then((response) => {
					if (response.status === 200) {
						let tempMessages = messages;
						tempMessages.splice(index, 1);
						setMessages([...tempMessages]);
						enqueueSnackbar(t("snackbar:deletedMessage"), {
							variant: "success",
						});
					} else
						enqueueSnackbar(t("snackbar:errorDeletingMessage"), {
							variant: "error",
						});
				})
				.catch((error) => {
					console.error(error);
					enqueueSnackbar(t("snackbar:errorDeletingMessage"), {
						variant: "error",
					});
				});
		} else {
			let tempMessages = messages;
			tempMessages.splice(index, 1);
			setMessages([...tempMessages]);
			enqueueSnackbar(t("snackbar:deletedMessage"), {
				variant: "success",
			});
		}
	};

	const handleTitleChange = (event, index) => {
		event.persist();
		let tempMessages = messages;
		tempMessages[index].name = event.target.value;
		tempMessages[index].updatedAt = new Date();
		setMessages([...tempMessages]);
	};
	const handleMessageChange = (event, index) => {
		event.persist();
		let tempMessages = messages;
		tempMessages[index].message = event.target.value;
		tempMessages[index].updatedAt = new Date();
		setMessages([...tempMessages]);
	};

	const handleVisibilityChange = (state) => {
		let { id, visibility } = state;
		let index = id?.split("_")[0];
		let tempMessages = messages;
		if (index) {
			tempMessages[index].visibility = visibility;
			tempMessages[index].updatedAt = new Date();
		}

		setMessages([...tempMessages]);
	};

	useEffect(() => {
		setMessages(defaultMessages);
	}, [defaultMessages]);

	return (
		<Card style={{ marginTop: "15px" }}>
			<CardContent>
				<Typography className={classes.header} spacing={1} variant="h3">
					{t("sysadmin:defaultMessagesLabel")}
				</Typography>
				<DefaultMessagesList
					messages={messages}
					functions={{
						handleVisibilityChange,
						onNew: newMessage,
						onSave: saveWelcomeMessage,
						removeMessage,
						handleTitleChange,
						handleMessageChange,
					}}
				/>
			</CardContent>
		</Card>
	);
};

export default DefaultWelcomeMessages;
