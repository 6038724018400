import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
	header: {
		fontSize: "48px",
	},
}));

const SectionHeader = ({ colour, children }) => {
	const classes = useStyles();
	return (
		<Box marginTop="6rem" marginBottom="4rem">
			<Typography
				variant="h1"
				align="center"
				className={classes.header}
				style={{ color: colour }}
			>
				{children}
			</Typography>
		</Box>
	);
};

export default SectionHeader;
