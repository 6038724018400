export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_CANDIDATES = 'UPDATE_CAMPAIGN_CANDIDATES';
export const SET_CAMPAIGN_CANDIDATES = 'SET_CAMPAIGN_CANDIDATES';
export const UPDATE_CAMPAIGN_CONTACTS = 'UPDATE_CAMPAIGN_CONTACTS';
export const UPDATE_CAMPAIGN_TCS = 'UPDATE_CAMPAIGN_TCS';
export const UPDATE_CAMPAIGN_UNINVITED_CANDIDATES =
  'UPDATE_CAMPAIGN_UNINVITED_CANDIDATES';
export const UPDATE_UNINVITED_CAMPAIGN_CONTACTS =
  'UPDATE_UNINVITED_CAMPAIGN_CONTACTS';
export const UPDATE_CAMPAIGN_DOCUMENTS = 'UPDATE_CAMPAIGN_DOCUMENTS';
export const UPDATE_SINGLE_CAMPAIGN_DOCUMENT =
  'UPDATE_SINGLE_CAMPAIGN_DOCUMENT';
export const REMOVE_SINGLE_CAMPAIGN_DOCUMENT =
  'REMOVE_SINGLE_CAMPAIGN_DOCUMENT';
export const UPDATE_CAMPAIGN_KCQ = 'UPDATE_CAMPAIGN_KCQ';
export const DELETE_CAMPAIGN_KCQ = 'DELETE_CAMPAIGN_KCQ';
export const CREATE_CAMPAIGN_KCQS = 'CREATE_CAMPAIGN_KCQS';
export const SET_CAMPAIGN_DWI = 'SET_CAMPAIGN_DWI';
export const UPDATE_CAMPAIGN_SCHEDULER = 'UPDATE_CAMPAIGN_SCHEDULER';
export const UPDATE_SALARY_DETAILS = 'UPDATE_SALARY_DETAILS';
export const UPDATE_CAMPAIGN_STATUS = 'UPDATE_CAMPAIGN_STATUS';
export const UPDATE_CAMPAIGN_DETAILS = 'UPDATE_CAMPAIGN_DETAILS';
export const UPDATE_CAMPAIGN_BACKGROUND_REQUIREMENTS =
  'UPDATE_CAMPAIGN_BACKGROUND_REQUIREMENTS';
export const UPDATE_CAMPAIGN_ASSIGNMENT_OVERVIEW =
  'UPDATE_CAMPAIGN_ASSIGNMENT_OVERVIEW';
export const UPDATE_CAMPAIGN_MANAGER = 'UPDATE_CAMPAIGN_MANAGER';
export const TOGGLE_ADMIN_CONTACT_PREVIEW = 'TOGGLE_ADMIN_CONTACT_PREVIEW';
export const CANCEL_ADMIN_CONTACT_PREVIEW = 'CANCEL_ADMIN_CONTACT_PREVIEW'
export const REMOVE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT =
  'REMOVE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT';
export const UPDATE_CAMPAIGN_REQUIRED_DOCUMENTS_PRIORITY =
  'UPDATE_CAMPAIGN_REQUIRED_DOCUMENTS_PRIORITY';
export const UPDATE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT =
  'UPDATE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT';
export const ADD_CAMPAIGN_REQUIRED_DOCUMENT = 'ADD_CAMPAIGN_REQUIRED_DOCUMENT';
export const UPDATE_CAMPAIGN_DOCUMENTS_PRIORITY =
  'UPDATE_CAMPAIGN_DOCUMENTS_PRIORITY';
export const UPDATE_CAMPAIGN_INFORMATION_BLOCKS = 'UPDATE_CAMPAIGN_INFORMATION_BLOCKS';
export const UPDATE_CAMPAIGN_CANDIDATE = 'UPDATE_CAMPAIGN_CANDIDATE';
