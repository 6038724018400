import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import CampaignVisbilitySettings from './CampaignVisbilitySettings';

//TODO:Translate this page

function CampaignSettings() {
  const isManager = useSelector(state => state.campaign.isManager);

  return (
    <Grid container spacing={3}>
      {isManager ? (
        <Grid item xs={12} lg={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <CampaignVisbilitySettings />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Grid item xs={12} lg={isManager ? 9 : 12}></Grid>
    </Grid>
  );
}

export default CampaignSettings;
