import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Card, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ProfileCard } from "src/components/ProfileCard";
import { Searchbar, useSearch } from "src/components/Filter/Search";
import { SelectMenu } from "src/components/Filter/Select";
import { SortMenu, useSort } from "src/components/Filter/Sort";
import { Paginator, usePagination } from "src/components/Filter/Pagination";
import { useTranslation } from "react-i18next";
import * as api from "../../api/SystemAdminApi";

const useStyles = makeStyles((theme) => ({
	filterCard: {
		marginBottom: theme.spacing(3),
		width: "100%",
		padding: 0,
	},
	filterCard_box: {
		padding: 0,
	},
}));

export const ShowSystemAdmins = () => {
	const classes = useStyles();
	const [t] = useTranslation(["candidates", "common"]);

	const [systemAdmins, setSystemAdmins] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [showItems, setShowItems] = useState(12);
	const [sortOrder, setSortOrder] = useState(t("common:ascending"));
	const session = useSelector((state) => state.session);
	const desktop = useMediaQuery("(min-width:960px)");

	const isCoreAdmin = session.roleType === "CoreAdmin";

	const getSystemAdmins = () => {
		return api
			.getSystemAdmins()
			.then((response) => {
				const data = response.data;
				const systemAdminsArr = data.map((admin) => {
					return {
						firstName: admin.profile.firstName,
						lastName: admin.profile.lastName,
						email: admin.user.email,
						avatar: null,
						enabled: admin.user.enabled,
						id: admin.user.id,
						role: admin.user.roles[0].name,
					};
				});
				setSystemAdmins(systemAdminsArr);
			})
			.catch((error) => {
				console.error(error);
				throw error;
			});
	};

	function handleStatusChange(enabled, id) {
		return api
			.toggleUserEnabled({ enabled, id })
			.then((response) => {
				if (response) {
					getSystemAdmins();
				}
			})
			.catch((error) => {});
	}

	const handleInputChange = (newValue) => {
		setSearchTerm(newValue);
	};

	const handleShowItems = (newValue) => {
		setShowItems(newValue);
	};

	const handleSortItems = (newValue) => {
		setSortOrder(newValue);
	};

	const handlePageChange = (newValue) => {
		jump(newValue.selected + 1);
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getSystemAdmins();
		}
		return () => {
			mounted = false;
		};
	}, []);

	let filteredResults = useSearch(systemAdmins, searchTerm, [
		"firstName",
		"lastName",
		"email",
	]).searchResults();

	const { currentData, jump, maxPage } = usePagination(
		filteredResults,
		showItems
	);

	let paginatedData = currentData();
	useSort(paginatedData, sortOrder, "lastName").sortData();

	return (
		<>
			<Card className={classes.filterCard}>
				<Box
					p={2}
					minHeight={56}
					display="flex"
					alignItems="center"
					className={classes.filterCard_box}
				>
					<Searchbar onChange={handleInputChange} />
					{desktop ? (
						<>
							<Box flexGrow={1} />
							<SelectMenu value={showItems} onChange={handleShowItems} />
							<SortMenu value={sortOrder} onChange={handleSortItems} />
						</>
					) : null}
				</Box>
			</Card>
			{paginatedData.map((admin) => {
				return (
					<Grid item md={4} sm={6} xs={12}>
						<ProfileCard
							name={admin.firstName + " " + admin.lastName}
							email={admin.email}
							btnLabel={
								isCoreAdmin
									? admin.enabled
										? t("common:disableLabel")
										: t("common:enableLabel")
									: null
							}
							btnFunc={() => {
								handleStatusChange(!admin.enabled, admin.id);
							}}
							disableBtn={admin.role === "CoreAdmin" ? true : false}
							enabled={admin.enabled}
						/>
					</Grid>
				);
			})}
			<Grid item xs={12}>
				<Paginator pageCount={maxPage} onPageChange={handlePageChange} />
			</Grid>
		</>
	);
};
