import axios from 'axios';

import axiosUtil from "../utils/axios";

export const login = ({ email, password, host }) => {
  delete axiosUtil.defaults.headers.common["Authorization"];
  return axios
    .post(`${process.env.REACT_APP_API_URL}/BaseUsers/generalLogin`, { email, password, host })
    .then((response) => {
      if (response.data.id) {
        return response;
      }
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const validateToken = (activation_token) => {
  delete axiosUtil.defaults.headers.common["Authorization"];
  return axiosUtil
    .get("/BaseUsers/validateToken", {
      params: {
        token: activation_token,
      },
    })
    .then((response) => {
      return response.data;
    });
};

export const logout = () => {
  return axiosUtil.post("/BaseUsers/logout").catch((error) => {
    console.error(error);
    throw error;
  });
};

export const register = () => { };
export const registerAfterOrder = () => { };

export const activateAccount = (password, activation_token) => {
  axiosUtil.defaults.headers.common["Authorization"] = activation_token;
  return axiosUtil.post("/BaseUsers/activateAccount", { password });
};

export const resendActivationEmail = (userId, consultancyId) => {
  return axiosUtil.post("/BaseUsers/resendActivationEmail", {
    userId,
    consultancyId,
  });
};

export const sendPasswordResetEmail = (userData) => {
  return axiosUtil.post("/BaseUsers/sendPasswordResetEmail", userData);
};

export const resetPassword = (password, reset_password_token) => {
  axiosUtil.defaults.headers.common["Authorization"] = reset_password_token;
  return axiosUtil.post("/BaseUsers/resetPassword", {
    password,
    token: reset_password_token,
  });
};

export const getProfile = () => {
  return axiosUtil.get("/Profiles/getUserProfile");
};

export const uploadProfilePicture = (formData) =>
axiosUtil.post("/Profiles/uploadProfilePicture", formData);

export const updateUserProfile = ({
  salutation,
  firstName,
  lastName,
  telephone,
  addressLine1,
  addressLine2,
  city,
  postalCode,
  country,
  localeId,
}) => {
  return axiosUtil.post("/Profiles/updateUserProfile", {
    salutation,
    firstName,
    lastName,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    countryId: country.id,
    localeId,
  });
};

export const getProfileByUserId = (userId, consultancyId) => {
  return axiosUtil.get("/Profiles/getProfileByUserId", {
    params: {
      userId,
      consultancyId,
    },
  });
};

export const updateProfileByUserId = (
  {
    salutation,
    firstName,
    lastName,
    email,
    telephone,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    country,
    userId,
  },
  consultancyId
) => {
  return axiosUtil.post(
    "/Profiles/updateProfileByUserId",
    {
      salutation,
      firstName,
      lastName,
      email,
      telephone,
      addressLine1,
      addressLine2,
      city,
      postalCode,
      countryId: country.id,
      userId,
    },
    {
      params: { consultancyId },
    }
  );
};
export const validateAccessToken = (token) => {
  return axiosUtil.get("/BaseUsers/isTokenValid", {
    params: {
      token,
    },
  });
};

export const uploadProfilePictureByUserId = (
  consultancyId,
  userId,
  formData
) => {
  return axiosUtil.post("/Profiles/uploadProfilePictureByUserId", formData, {
    params: { consultancyId, userId },
  });
};
