import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Container, Grid, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  container_tight: {
    padding: 0,
    marginTop: "1rem",
    marginBottom: 0,
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

export const ButtonWrapper = ({
  btnLabel,
  btnFunc,
  btnDisabled,
  btnHidden,
  btnVariant,
  btnLabel2,
  btnFunc2,
  btnVariant2,
  btnDisabled2,
  reduceMargin,
  btnHidden2,
  btn1Tooltip,
  btn2Tooltip,
}) => {
  const classes = useStyles();
  const btn = btnLabel != null;
  const btn2 = btn && btnLabel2 != null ? true : false;

  return (
    <Container
      className={reduceMargin ? classes.container_tight : classes.container}
      maxWidth="xl"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              xl={btn2 && !btnHidden2 ? 8 : btn ? 10 : 12}
              lg={btn2 && !btnHidden2 ? 6 : btn ? 9 : 12}
              md={btn2 && !btnHidden2 ? 12 : btn ? 8 : 12}
            ></Grid>
            {btn2 && !btnHidden2 ? (
              <Grid item xs={12} xl={2} lg={3} sm={6}>
                {btn2Tooltip ? (
                  <Tooltip
                    enterTouchDelay={1}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    title={btn2Tooltip}
                  >
                    <Button
                      size="large"
                      type="submit"
                      variant={btnVariant2 || "text"}
                      fullWidth
                      disabled={btnDisabled2 ? true : false}
                      onClick={btnFunc2}
                      color="primary"
                    >
                      {btnLabel2}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    size="large"
                    type="submit"
                    variant={btnVariant2 || "text"}
                    fullWidth
                    disabled={btnDisabled2 ? true : false}
                    onClick={btnFunc2}
                    color="primary"
                  >
                    {btnLabel2}
                  </Button>
                )}
              </Grid>
            ) : null}
            {btn && !btnHidden ? (
              <Grid
                item
                xs={12}
                xl={2}
                lg={3}
                md={btn2 ? 6 : 4}
                sm={btn2 ? 6 : 12}
              >
                {btn1Tooltip ? (
                  <Tooltip
                    enterTouchDelay={1}
                    classes={{
                      tooltip: classes.tooltip,
                    }}
                    title={btn1Tooltip}
                  >
                    <Button
                      color="primary"
                      size="large"
                      type="submit"
                      variant={btnVariant || "outlined"}
                      fullWidth
                      disabled={btnDisabled ? true : false}
                      onClick={btnFunc}
                    >
                      {btnLabel || "CTA"}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant={btnVariant || "outlined"}
                    fullWidth
                    disabled={btnDisabled ? true : false}
                    onClick={btnFunc}
                  >
                    {btnLabel || "CTA"}
                  </Button>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
