import React, { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';

import { colors, Grid, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import VideocamIcon from '@material-ui/icons/Videocam';

import { useSnackbar } from 'notistack';

import PropTypes from 'prop-types';

import * as mediaDrawerActions from './../actions/mediaDrawerActions';

import { LoadingModal } from "./LoadingModal";

import { DraggableTypes } from './../utils/draggableTypes';

import addFileSVG from 'src/assets/deviasAssets/undraw_add_file2_gvbb.svg';

const IMAGE_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];
const VIDEO_FORMATS = ['video/mp4', 'audio/ogg', 'video/webm', 'video/quicktime'];

const useStyles = makeStyles((theme) => ({
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: '18px',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  container: {
    position: 'relative',
    textAlign: 'center',
    '&:hover > div': {
      opacity: 1
    },
    '&:hover > img': {
      opacity: 0.3
    }
  },
  videocamIcon: {
    width: '40px',
    height: '40px'
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  logo: {
    cursor: 'pointer',
    maxHeight: '120px',
    width: 'auto',
    display: 'block',
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '100%',
    objectFit: 'contian',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '80vw'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: "60vw"
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 180
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: 240
    }
  },
  image: {
    cursor: 'pointer',
    maxHeight: '120px',
    width: 'auto',
    maxWidth: 240,
    display: 'block',
    transition: '.5s ease',
    backfaceVisibility: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    objectFit: 'contian'
  },
  info: {
    textAlign: 'center',
    cursor: 'pointer'
  },
  list: {
    maxHeight: 320,
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  cancelButton: {
    flex: 1
  },
  actionButton: {
    flex: 1
  },
  middle: {
    transition: '.5s ease',
    width: '100%',
    opacity: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    msTransform: 'translate(-50%, -50%)',
    backgroundColor: '#eee',
    padding: '10px 5px'
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  },
  fileImage: {
    width: 25
  },
  glowing: {
    borderColor: '#9ecaed',
    boxShadow: '0 0 10px #9ecaed'
  },
  uploadFormatInstructions: {
    marginBottom: 10
  }
}));

const CustomFileDropzone = ({ uploadFunction, placeHolderImage, type, actionText, className, percent, logo, ...rest }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['common']);

  const [files, setFiles] = useState([]);
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (files && files.length) {
      uploadFiles();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handleDrop = useCallback((acceptedFiles) => {
    if (type !== 'single') {
      setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
      return;
    }

    const validFormat = rest?.format ? checkFormat(acceptedFiles[0] || acceptedFiles) : true;

    if (!validFormat) {
      const errorMessage = !VIDEO_FORMATS.includes(acceptedFiles[0].type) && acceptedFiles[0].type === 'video/quicktime' ?
        t('campaigns:videoNotSupported') : t('common:unsupportedFormat');

      enqueueSnackbar(errorMessage, { variant: 'error' });
      return;
    }

    const acceptedFilesValue = acceptedFiles.length ? [acceptedFiles[0]] : [acceptedFiles];
    setFiles(acceptedFilesValue);

    if (rest?.format === 'image' && acceptedFiles[0]) {
      getFileBase64(acceptedFiles[0])
        .then(setImage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [{ isOver }, drop] = useDrop({
    accept: DraggableTypes.FILE,
    drop: handleDrop,
    collect: (mon) => ({
      isOver: !!mon.isOver()
    })
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const checkFormat = (file) => {
    if (rest?.format === 'video') {
      return VIDEO_FORMATS.includes(file.type) || file.asset?.type === 'video';
    }

    return IMAGE_FORMATS.includes(file.type) || file.asset?.type === 'image';
  };

  const getFileBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const afterUploadHandler = () => {
    setLoading(false);
    setFiles([]);
    dispatch({ type: mediaDrawerActions.REFRESH });
  };

  const uploadFiles = () => {
    setLoading(true);

    const formData = new FormData();

    if (type === 'single') {
      if (files[0].type === DraggableTypes.FILE) {
        formData.append('file', JSON.stringify(files[0]));
      } else {
        formData.append('file', files[0]);
      }
    } else {
      for (let i = 0; i < files.length; i++) {
        formData.append(`file_${i}`, files[i]);
      }
    }

    const promise = uploadFunction(formData);

    if (promise) {
      promise
        .then(() => {
          afterUploadHandler();
        });
    } else {
      afterUploadHandler();
    }
  };

  return (
    <div { ...rest } ref={ drop } className={ (clsx({ }, className), isOver ? classes.glowing : "") }>
      <div className={ clsx({ [classes.dragActive]: isDragActive }) } { ...getRootProps() }>
        <input { ...getInputProps() } />

        <div className={ classes.container }>
          {
            rest?.format === "video" ? (
              <VideocamIcon className={ classes.videocamIcon } />
            ) : (
              <img
                alt="Select file"
                className={ logo ? classes.logo : classes.image }
                src={ image ? image : placeHolderImage ? placeHolderImage : addFileSVG } />
            )
          }

          <div>
            <Typography className={ classes.info } color="textPrimary" variant="h5">
              {
                rest?.format === "video" && (
                  <Grid item xs={ 12 }>
                    <Typography className={ classes.uploadFormatInstructions } variant="h5">
                      { t("campaigns:videoSupportedFormatInstructions") }
                    </Typography>
                  </Grid>
                )
              }

              { t("common:dragDrop") + " " }

              <Link underline="always">
                { t("common:upload") }
              </Link>
            </Typography>
          </div>
        </div>
      </div>

      <LoadingModal open={ loading } percent={ percent } />
    </div>
  );

};

CustomFileDropzone.propTypes = { className: PropTypes.string };

export default CustomFileDropzone;
