import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { useFormik } from 'formik';

import { Button, Card, CardContent, colors, Grid, Modal, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

import * as systemAdminApi from 'src/api/SystemAdminApi';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    padding: '0px 10px'
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${50}%)`
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${50}%)`
    },
    '&:focus': {
      outline: 'none'
    }
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400]
    },
    color: theme.palette.common.white,
    textTransform: 'none'
  },
  modalOverflow: {
    overflowY: 'scroll'
  }
}));

const CreateConsultancyModal = ({ open, handleClose }) => {

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['common', 'organisations']);

  const [consultancies, setConsultancies] = useState([]);
  const [consultancySubscriptionPlans, setConsultancySubscriptionPlans] = useState([]);

  const validationSchema = Yup.object({
    consultancyName: Yup.string()
      .required('is required')
      .max(64),
    consultancySubscriptionPlanId: Yup.number()
      .required('is required')
  });

  const formik = useFormik({
    initialValues: {
      consultancyName: '',
      consultancySubscriptionPlanId: null,
      parentConsultancyId: null
    },
    validationSchema,
    onSubmit: (values) => {
      systemAdminApi.createNewConsultancy(values)
        .then((response) => {
          enqueueSnackbar(`${values.consultancyName} created.`, { variant: 'success' });
          history.push(`/dashboard/consultancies/${response.data.consultancyId}`);
        })
        .catch(() => {
          enqueueSnackbar(t('organisations:consultancyCreateError'), { variant: 'error' });
        });
    }
  });

  useEffect(() => {
    getConsultancies();
    getConsultancySubscriptionPlans();
  }, []);

  const getConsultancies = () => {
    systemAdminApi.getAllConsultancies()
      .then((response) => {
        setConsultancies(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getConsultancySubscriptionPlans = () => {
    systemAdminApi.getConsultancySubscriptionPlans()
      .then((response) => {
        setConsultancySubscriptionPlans(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleConsultancyNameChange = (event) => {
    if (!formik.touched.consultancyName) {
      formik.setTouched({ consultancyName: true });
    }

    formik.handleChange(event);
  };

  const handleParentConsultancyChange = (event, value) => {
    event.persist();

    formik.setValues({
      ...formik.values,
      parentConsultancyId: value ? value.id : null
    });
  };

  const handleConsultancySubscriptionPlanChange = (event, value) => {
    event.persist();

    formik.setValues({
      ...formik.values,
      consultancySubscriptionPlanId: value ? value.id : null
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      className={classes.modalOverflow}
    >
      <Card className={classes.modalCard}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <Typography color='textPrimary' variant='h4'>
                    {t('organisations:createConsultancy')}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right' }}>
                  <Button
                    className={classes.closeButton}
                    size='small'
                    variant='contained'
                    onClick={handleClose}
                  >
                    X
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={formik.handleSubmit}>
                <div className={classes.fields}>
                  <TextField
                    fullWidth
                    label='Consultancy Name'
                    name='consultancyName'
                    variant='outlined'
                    required
                    onChange={handleConsultancyNameChange}
                    error={!!formik.errors.consultancyName}
                    value={formik.values.consultancyName}
                    helperText={formik.errors.consultancyName}
                  />

                  <Autocomplete
                    id='combo-box-demo'
                    options={consultancySubscriptionPlans}
                    getOptionLabel={(option) => t(`consultancy:subcriptionPlans${option.id}`)}
                    onChange={handleConsultancySubscriptionPlanChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: '100%' }}
                        label={ t("common:consultancySubscriptionPlanLabel") }
                        variant='outlined' />
                    )} />

                  <Autocomplete
                    id='combo-box-demo'
                    options={consultancies}
                    getOptionLabel={(option) => option.consultancyName}
                    onChange={handleParentConsultancyChange}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          style={{ width: '100%' }}
                          label='Parent Consultancy'
                          variant='outlined'
                        />
                      );
                    }}
                  />
                </div>
                <Button
                  className={classes.submitButton}
                  color='primary'
                  size='large'
                  type='submit'
                  variant='contained'
                  disabled={!formik.isValid || !formik.touched.consultancyName}
                >
                  {t('common:createConsultancy')}
                </Button>
              </form>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Modal>
  );

}

export default CreateConsultancyModal;
