import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import * as employerApi from 'src/api/employerApi';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';
import CreateEmployerForm from './CreateEmployerForm';

function CreateEmployerModal({ onSuccess, consultancyId, open, handleClose }) {
  const history = useHistory();

  const [t] = useTranslation(['snackbar', 'common']);

  const { enqueueSnackbar } = useSnackbar();

  const [employers, setEmployers] = useState([]);

  const getEmployers = function(consultancyId) {
    return employerApi
      .getEmployers(consultancyId)
      .then((response) => {
        const employerNames = response.data.map((employer) =>
          employer.employerName.toLowerCase()
        );
        setEmployers(employerNames);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onSubmit = function(values) {
    if (employers.includes(values.employerName.toLowerCase().trim())) {
      enqueueSnackbar(`${t('snackbar:employerAlreadyExists')}`, {
        variant: 'error',
      });
    } else {
      return employerApi
        .createEmployer(values)
        .then((response) => {
          enqueueSnackbar(`${t('snackbar:createdNewEmployer')}`, {
            variant: 'success',
          });
          history.push(`/dashboard/employers/${response.data.id}`);

          onSuccess(response.data);
        })
        .catch((error) => {
          enqueueSnackbar(`${t('snackbar:failedToCreateNewEmployer')}`, {
            variant: 'error',
          });
        })
        .then(() => {
          handleClose();
        });
    }
  };
  useEffect(() => {
    getEmployers(consultancyId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalFormWrapper
      formTitle={t('common:createEmployer')}
      handleOpen={open}
      handleClose={handleClose}
    >
      <CreateEmployerForm
        onSubmit={onSubmit}
        consultancyId={consultancyId}
        style={{ width: '100%' }}
      />
    </ModalFormWrapper>
  );
}
export default CreateEmployerModal;
