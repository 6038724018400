import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E4EBF1'
  }
}));

const LoadingView = () => {

  const classes = useStyles();

  return (
    <>
      <Box className={ classes.box } >
        <CircularProgress />
      </Box>
    </>
  );

};

export default LoadingView;
