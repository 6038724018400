import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	getChildConsultancies,
	removeChildFromConsultancy,
	saveChildrenToConsultancy,
} from "src/api/consultancyApi";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BusinessIcon from "@material-ui/icons/Business";
import { useSnackbar } from "notistack";
import AddChildrenModal from "src/views/ConsultancyManagement/ChildConsultancyView/AddChildrenModal";
import { ButtonWrapper } from "./LayoutComponents/ButtonWrapper";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	container: {
		padding: "0% 5%",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	modalCard: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
			transform: `translate(${0}%, ${50}%)`,
		},
		[theme.breakpoints.up("md")]: {
			width: "50%",
			transform: `translate(${50}%, ${50}%)`,
		},
		"&:focus": {
			outline: "none",
		},
	},
	gridRow: {
		marginTop: "20px",
	},
	consultancyIcon: {
		height: "50px",
		width: "auto",
	},
	deleteIcon: {
		height: "25px",
		width: "auto",
		cursor: "pointer",
	},
	createConsultancyButtonText: {
		[theme.breakpoints.down("lg")]: {
			fontSize: "12px",
		},
	},
}));


const ChildConsultancies = ({ parentConsultancyId }) => {
	const classes = useStyles();
	const [children, setChildren] = useState([]);
	const [childModal, setChildModal] = useState(false);
	const [t] = useTranslation(["campaigns", "consultancy"]);
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const roleType = useSelector((state) => {
		return state.session.roleType;
	});
	const sysAdmin = roleType === "SystemAdmin" || roleType === "CoreAdmin";
	let nestCounter = 12;

	const getChildren = () => {
		return getChildConsultancies(parentConsultancyId).then((response) => {
			if (response.status === 200) {
				setChildren(response.data);
			}
		});
	};

	const goToConsultancy = (event, consultancyId) => {
		event.preventDefault();
		history.push(`/dashboard/consultancy/${consultancyId}`, {
			consultancyId,
		});
	};

	const openChildModal = () => {
		setChildModal(true);
	};

	function reduceCounter() {
		nestCounter = nestCounter - 1;

		return nestCounter;
	};

	function resetCounter() {
		nestCounter = 12;

		return nestCounter;
	}

	const closeModal = () => {
		setChildModal(false);
	};

	const addChildren = (consultancyId, children) => {
		return saveChildrenToConsultancy(parseInt(consultancyId), children)
			.then((response) => {
				if (response.status === 200) {
					getChildren();
					closeModal();
					enqueueSnackbar(t("consultancy:addedChildrenSuccess"), {
						variant: "success",
					});
				}
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(t("consultancy:addingChildrenFail"), {
					variant: "error",
				});
			});
	};

	const addChildrenConsultancies = useCallback(
		(children) => {
			addChildren(parseInt(parentConsultancyId), children);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[parentConsultancyId]
	);

	const removeChild = (childId) => {
		return removeChildFromConsultancy(parentConsultancyId, childId)
			.then((response) => {
				if (response.status === 200) {
					setChildren(
						[...children, ...response.data].filter(
							(child) => child.id !== childId
						)
					);
					enqueueSnackbar(t("consultancy:removeChildSuccess"), {
						variant: "success",
					});
				}
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(t("consultancy:removeChildFail"), {
					variant: "fail",
				});
			});
	};
	useEffect(() => {
		getChildren();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentConsultancyId]);

	function Consultancy({ consultancy, offset }) {
		if(!offset) {
			resetCounter();
		}
		let nestedconsultancies = [];
		let width = nestCounter;
		if(consultancy.children.length) {
			let childWidth = reduceCounter();
			nestedconsultancies = (consultancy.children).map(consultancy => {
				return <Consultancy key={consultancy.id} consultancy={consultancy} offset={childWidth}/>
		  	});	
		}

		return (
			<>
				<Grid item md={ offset ? 12 - offset : 12 - width}></Grid>
				<Grid item md={ offset ? offset : width }>
					<Card>
						<CardContent>
							<Grid container spacing={3}>
								<Grid item xs={3}>
									<BusinessIcon
										className={classes.consultancyIcon}
										color="primary"
									/>
								</Grid>
								<Grid item xs={9}>
									<Grid container spacing={3}>
										<Grid item xs={10}>
											<Typography
												variant="h4"
												style={{
													textDecoration: "underline",
													cursor: "pointer",
												}}
												onClick={(event) => {
													goToConsultancy(event, consultancy.consultancy.id);
												}}
											>
												{consultancy.consultancy?.consultancyName}
											</Typography>
										</Grid>
										<Grid item xs={2}>
											{!offset && <DeleteIcon
												className={classes.deleteIcon}
												color="primary"
												onClick={() => removeChild(consultancy.consultancy.id)}
											/>}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
				{nestedconsultancies}
			</>
		);
	}

	return (
		<>
			<Grid container spacing={3}>
				{sysAdmin && (
					<ButtonWrapper
						btnLabel={t("campaigns:addChildrenToConsultancy")}
						btnFunc={openChildModal}
						btnVariant="contained"
					/>
				)}
				{children.map((item) => (
					<Consultancy key={item.consultancy.id} consultancy={item} />
				))}
			</Grid>
			<AddChildrenModal
				open={childModal}
				handleClose={closeModal}
				consultancyId={parentConsultancyId}
				onSuccess={addChildrenConsultancies}
			/>
		</>
	);
};

export default ChildConsultancies;
