import * as actionTypes from "src/actions";

const initialState = {
  availableCampaignCandidates: [],
  campaignCandidates: [],
  launchpadDetails: {},
  uninvitedContacts: [],
  adminContactPreview: false,
  adminContactPreviewPrevState: false
};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CAMPAIGN: {
      return {
        ...state,
        campaignCandidates: [
          ...state.campaignCandidates,
          ...action.campaign.campaignCandidates
        ],
        ...action.campaign,
        campaignLogoImg: action.campaign.campaignLogoImg
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_CANDIDATES: {
      let campaignCandidates = [
        ...state.campaignCandidates,
        ...action.payload.campaignCandidates
      ];
      let uninvitedCandidates = campaignCandidates.filter(
        (candidate) => candidate.status === 1
      );
      return {
        ...state,
        campaignCandidates: [...campaignCandidates],
        availableCampaignCandidates: [
          ...action.payload.availableCampaignCandidates
        ],
        uninvitedCandidates: uninvitedCandidates
      };
    }
    case actionTypes.SET_CAMPAIGN_CANDIDATES: {
      return {
        ...state,
        campaignCandidates: [...action.updatedCampaignCandidates]
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_CONTACTS: {
      return {
        ...state,
        campaignContacts: action.campaignContacts
          ? action.campaignContacts
          : [...state.campaignContacts],
        addableContacts: action.addableContacts
          ? action.addableContacts
          : [...state.addableContacts],
        uninvitedContacts: action.uninvitedContacts
          ? action.uninvitedContacts
          : [...state.uninvitedContacts]
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_TCS: {
      return {
        ...state,
        ...action.termsAndConditions
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_UNINVITED_CANDIDATES: {
      let uninvitedCandidates = state.campaignCandidates.filter(
        (candidate) => candidate.status === 1
      );
      return {
        ...state,
        uninvitedCandidates: [...uninvitedCandidates]
      };
    }
    case actionTypes.UPDATE_UNINVITED_CAMPAIGN_CONTACTS: {
      return {
        ...state,
        uninvitedContacts: [...action.uninvitedCampaignContacts]
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_DOCUMENTS: {
      return {
        ...state,
        campaignDocuments: [...state.campaignDocuments, action.document]
      };
    }
    case actionTypes.UPDATE_SINGLE_CAMPAIGN_DOCUMENT: {
      let { document } = action;
      let { campaignDocuments } = state;
      let documentIndex = campaignDocuments.indexOf(
        campaignDocuments.find(
          (campaignDocument) => campaignDocument.id === document.id
        )
      );
      campaignDocuments[documentIndex] = document;
      return {
        ...state,
        campaignDocuments: [...campaignDocuments]
      };
    }
    case actionTypes.REMOVE_SINGLE_CAMPAIGN_DOCUMENT: {
      let { id } = action;
      let { campaignDocuments } = state;
      let campaignIndex = campaignDocuments.indexOf(
        campaignDocuments.find((document) => document.id === id)
      );
      campaignDocuments.splice(campaignIndex, 1);
      return {
        ...state,
        campaignDocuments: [...campaignDocuments]
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_KCQ: {
      let questions = [...action.kcqs];
      return {
        ...state,
        currentCampaignKcq: {
          ...state.currentCampaignKcq,
          questions
        }
      };
    }
    case actionTypes.DELETE_CAMPAIGN_KCQ: {
      return {
        ...state,
        currentCampaignKcq: null
      };
    }
    case actionTypes.CREATE_CAMPAIGN_KCQS: {
      return {
        ...state,
        currentCampaignKcq: action.currentCampaignKcq
      };
    }
    case actionTypes.SET_CAMPAIGN_DWI: {
      return {
        ...state,
        campaignDWICategories: action.categories
      };
    }

    //updating both fields here as part of application uses one and part another, needs updating both for calendar slots to work(appear and refresh on update) for all users. Probably needs rewrite and use just one. To bigger job atm
    case actionTypes.UPDATE_CAMPAIGN_SCHEDULER: {
      return {
        ...state,
        campaignEvents: [...action.campaignEvents],
        calendarEntries: [...action.campaignEvents]
      };
    }
    case actionTypes.UPDATE_SALARY_DETAILS: {
      return {
        ...state,
        salary: action.salaryDetails.salary,
        fee: action.salaryDetails.fee,
        percent: action.salaryDetails.percentage_charged,
        currency: action.salaryDetails.currency
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_STATUS: {
      return {
        ...state,
        status: action.status
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_DETAILS: {
      return {
        ...state,
        campaignTitle: action.campaignTitle,
        reference: action.reference,
        campaignCity: action.campaignCity,
        campaignPostalCode: action.campaignPostalCode,
        campaignCountry: action.campaignCountry
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_BACKGROUND_REQUIREMENTS: {
      return {
        ...state,
        bgRequirements: action.bgRequirements
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_ASSIGNMENT_OVERVIEW: {
      return {
        ...state,
        assignmentOverview: action.assignmentOverview
      };
    }
    case actionTypes.UPDATE_CAMPAIGN_MANAGER: {
      return {
        ...state,
        ...action.visbilitySettings
      };
    }
    case actionTypes.TOGGLE_ADMIN_CONTACT_PREVIEW: {
      return {
        ...state,
        adminContactPreviewPrevState: state.adminContactPreview,
        adminContactPreview: !state.adminContactPreview,
      };
    }
    case actionTypes.CANCEL_ADMIN_CONTACT_PREVIEW: {
      return {
        ...state,
        adminContactPreview: false
      };
    }
    case actionTypes.ADD_CAMPAIGN_REQUIRED_DOCUMENT: {
      return {
        ...state,
        campaignRequiredDocuments: [
          ...state.campaignRequiredDocuments,
          action.document
        ]
      };
    }
    case actionTypes.UPDATE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT: {
      let { document } = action;
      let { campaignRequiredDocuments } = state;
      let documentIndex = campaignRequiredDocuments.indexOf(
        campaignRequiredDocuments.find(
          (campaignRequiredDocument) =>
            campaignRequiredDocument.id === document.id
        )
      );
      campaignRequiredDocuments[documentIndex] = document;
      return {
        ...state,
        campaignRequiredDocuments: [...campaignRequiredDocuments]
      };
    }

    case actionTypes.REMOVE_SINGLE_CAMPAIGN_REQUIRED_DOCUMENT: {
      let { id } = action;
      let { campaignRequiredDocuments } = state;
      let campaignIndex = campaignRequiredDocuments.indexOf(
        campaignRequiredDocuments.find((document) => document.id === id)
      );
      campaignRequiredDocuments.splice(campaignIndex, 1);
      return {
        ...state,
        campaignRequiredDocuments: [...campaignRequiredDocuments]
      };
    }

    case actionTypes.UPDATE_CAMPAIGN_REQUIRED_DOCUMENTS_PRIORITY: {
      return {
        ...state,
        campaignRequiredDocuments: [...action.documents]
      };
    }

    case actionTypes.UPDATE_CAMPAIGN_DOCUMENTS_PRIORITY: {
      return {
        ...state,
        campaignDocuments: [...action.documents]
      };
    }

    case actionTypes.UPDATE_CAMPAIGN_INFORMATION_BLOCKS: {
      return {
        ...state,
        campaignInformationBlocks: action.campaignInformationBlocks
      };
    }

    case actionTypes.UPDATE_CAMPAIGN_CANDIDATE: {
      const campaignCandidates = state.campaignCandidates.map((cc) => {
        if (cc.id === action.payload.candidate.id) {
          return action.payload.candidate;
        }

        return cc;
      });

      return {
        ...state,
        campaignCandidates
      }
    }

    default: {
      return state;
    }
  }
};

export default campaignReducer;
