import React from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, FormControl, InputLabel, Select, MenuItem, TextField, IconButton, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  divWrapper: {
    position: 'relative'
  },
  div: {
    position: 'absolute',
    top: -8,
    left: 77,
    zIndex: 100
  },
  divTooltipWrapper: {
    position: 'absolute',
    top: -8,
    left: 83,
    zIndex: 100
  },
  tooltip: {
    textAlign: 'center',
    padding: 2,
    margin: 0
  }
}));

const VideoAssessmentQuestionsAndTimes = ({ question, time, handleChange, removeQuestionAndTime, count }) => {

  const [t] = useTranslation(['common', 'launchpad', 'tooltips']);
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <div className={ classes.divWrapper } >
            <TextField
              fullWidth
              id="outlined-basic"
              onChange={handleChange}
              label="Question"
              name="question"
              value={question}
              variant="outlined" />

            <div className={ classes.div } >
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={t("tooltips:insertQuestion")} >
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">
              {t("launchpad:timeLimit")}
            </InputLabel>

            <div className={ classes.divTooltipWrapper } >
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={t("tooltips:selectTime")} >
                <InfoIcon />
              </Tooltip>
            </div>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              label="Age"
              name="time"
              value={time}
              onChange={handleChange} >
              <MenuItem value={15}>15 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={30}>30 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={45}>45 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={60}>60 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={75}>75 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={90}>90 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={105}>105 {t("launchpad:seconds")}</MenuItem>
              <MenuItem value={120}>120 {t("launchpad:seconds")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {
          count > 1 && (
            <>
              <Grid item xs={12} md={1}>
                <IconButton onClick={removeQuestionAndTime}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </>
          )
        }
      </Grid>
    </>
  );
}

export default VideoAssessmentQuestionsAndTimes;
