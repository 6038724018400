import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { loremIpsum } from 'lorem-ipsum';

import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
  cardWrapper: { marginBottom: "10px" },
  questionsAndAnswers: {
    padding: 8,
    backgroundColor: "#f5f5f5",
    marginTop: 8,
  },
  expansionPanel: {
    borderBottomColor: '#e0e0e0',
    '&:nth-of-type(2n)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    '&:nth-of-type(2n+1)': {
      backgroundColor: fade(theme.palette.primary.main, 0.03)
    }
  },
  categoryWrapper: {
    // backgroundColor: theme.palette.primary.main,
    // color: theme.palette.primary.main,
    borderRadius: 2,
    padding: 8
  },
  blurredTypography: {
    color: 'transparent',
    textShadow: '0 0 5px rgba(0,0,0,0.9)'
  }
}));

const ContactCandidateKCQ = ({ candidateCampaign, areAnswersHidden = false, expandCollapseClick }) => {
  const [t] = useTranslation(["common"]);
  const classes = useStyles();

  const [candidateKCQ, setCandidateKCQ] = useState([]);

  useEffect(() => {
    if (candidateCampaign?.candidateProfile?.candidateKCQ) {
      setCandidateKCQ(candidateCampaign.candidateProfile.candidateKCQ);
    }

    if (candidateCampaign?.candidateKCQ) {
      setCandidateKCQ(candidateCampaign?.candidateKCQ);
    }
  }, [candidateCampaign]);

  const expansionPanelOnChange = (event) => {
    event.stopPropagation();

    if (expandCollapseClick) {
      expandCollapseClick();
    }
  };

  const shouldLoadKCQS = () => {
    if (!candidateKCQ.length) {
      return false;
    }
    
    for (const category of candidateKCQ) {
      for (const question of category.questions) {
        if (question.answer || question.answerLength) {
          return true;
        }
      }
    }

    return false;
  };

  const getAnswer = (question) => {
    if (areAnswersHidden) {
      const generatedLoremIpsum = loremIpsum({
        count: question.answerLength,
        format: 'plain',
        units: 'words'
      });

      return (
        <Typography className={ classes.blurredTypography }>
          { generatedLoremIpsum }
        </Typography>
      )
    }

    return (
      <Typography>
        {
          question.answer
            .split("\n")
            .map((i, key) => (
                <p key={key}>{i}<br /></p>
              )
            )
        }
      </Typography>
    )
  };

  if (!shouldLoadKCQS()) {
    return null;
  }

  return (
    <>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BlockHeader
                  card
                  header={t("common:keyCompetencyQuestionsLabel")}
                />
              </Grid>
              <Grid item xs={12}>
                {
                  candidateKCQ.map(
                    (category, categoryIndex) => (
                      <div key={categoryIndex} style={{ marginBottom: "2px" }} className={ classes.expansionPanel }>
                        <ExpansionPanel defaultExpanded={ categoryIndex === 0 } onChange={ expansionPanelOnChange } className={ classes.expansionPanel }>
                          <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon style={{ fill: "black" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header" >
                            <Typography
                              variant="h4"
                              className={classes.categoryWrapper}
                            >
                              {category.name}
                            </Typography>
                          </ExpansionPanelSummary>
                          <ExpansionPanelDetails>
                            <div className={classes.questionsAndAnswers}>
                              {category.questions.map((question) => (
                                <div style={{ margin: "16px 0px" }}>
                                  <Typography
                                    variant="h3"
                                    style={{
                                      marginBottom: 18,
                                      lineHeight: 1.5,
                                    }}
                                  >
                                    {question.question}
                                  </Typography>

                                  { getAnswer(question) }
                                </div>
                              ))}
                            </div>
                          </ExpansionPanelDetails>
                        </ExpansionPanel>
                      </div>
                    )
                  )
                }
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

export default ContactCandidateKCQ;
