import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import CandidateStageView from "./CandidatesCard/CandidateStageView";
import CandidatesMap from "./CandidatesMap";

import { CANDIDATES_MAP_ID} from './../../../constants/moduleIds';

//TODO:Translate this page

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	noCandiate: {
		maxWidth: "80%",
		margin: "auto",
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		textAlign: "center",
	},
}));

function CandidateDetails({ ...rest }) {
	const classes = useStyles();
	const [t] = useTranslation(["common", "validation", "campaigns"]);
	const campaign = useSelector((state) => state.campaign);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} lg={campaign.campaignModuleIds.includes(CANDIDATES_MAP_ID) ? 8 : 10}>
				<CandidateStageView
					guestView={rest?.guestView}
					campaignId={rest?.campaignId}
					consultancyId={rest?.consultancyId}
				/>
			</Grid>

			{
				campaign.campaignModuleIds.includes(CANDIDATES_MAP_ID) && (
					<Grid item xs={12} lg={4}>
						<Card>
							<CardContent
								style={
									campaign.campaignPostalCode
										? {
												height: 400,
												width: "100%",
												position: "relative",
												padding: "0",
											}
										: null
								}
							>
		
								{
									campaign.campaignPostalCode ? (
										<CandidatesMap />
									) : (
										<Typography className={classes.noCandiate} variant="h4">
											{t("common:noCandidateMap")}
										</Typography>
									)
								}
							</CardContent>
						</Card>
					</Grid>
				)
			}
		</Grid>
	);
}

export default CandidateDetails;
