import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button,
  Typography,
  colors,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as ConsultancyApi from 'src/api/consultancyApi';
import { useTranslation } from 'react-i18next';
import { ModalFormWrapper } from 'src/components/ModalFormWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    '&:focus': {
      outline: 'none',
    },
  },
  container: {
    padding: '0px 10px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${50}%)`,
      overflow: 'visible',
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${50}%)`,
      overflow: 'visible',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  modalOverflow: {
    overflow: 'scroll',
    height: '100vh',
  },
  userChip: {
    backgroundColor: '#009688',
    color: '#ffffff',
  },
}));

function DeleteUserGroupModal({ onSuccess, userGroup, open, handleClose }) {
  const classes = useStyles();
  const [t] = useTranslation(['common', 'users', 'groups']);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = function(event) {
    event.preventDefault();
    const groupId = userGroup.groupId;
    return ConsultancyApi.deleteUserGroup(userGroup)
      .then((response) => {
        onSuccess(groupId);
        enqueueSnackbar(t('groups:groupDeletion'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('groups:groupDeleteFailure'), { variant: 'error' });
      })
      .then(() => {
        handleClose();
      });
  };

  const children = (
    <Grid item xs={12}>
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <Typography color='textPrimary' variant='h5'>
            {` ${userGroup.name} ${t('groups:confirmation')}`}
          </Typography>
        </div>
        <Button
          className={classes.submitButton}
          color='primary'
          size='large'
          type='submit'
          variant='contained'
        >
          {t('groups:deleteGroup')}
        </Button>
      </form>
    </Grid>
  );

  const formTitle = t('groups:deleteGroup');

  return (
    <ModalFormWrapper
      children={children}
      formTitle={formTitle}
      handleOpen={open}
      handleClose={handleClose}
    />
  );
}
export default DeleteUserGroupModal;
