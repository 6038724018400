import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import axios from 'axios';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import { useTranslation } from 'react-i18next';
import { Typography, CircularProgress, Link } from '@material-ui/core';
import { useSelector } from 'react-redux';

const mapStyles = {
  width: '100%',
  height: '100%',
};

const loadingStyles = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
};

const CandidatesMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API}`,
    loadingElement: (
      <div style={loadingStyles}>
        <CircularProgress />
      </div>
    ),
    containerElement: <div style={{ height: `100%` }}></div>,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const {
    campaignCandidates,
    campaignCity,
    campaignCountry,
    id: campaignId,
    campaignPostalCode,
  } = useSelector((state) => state.campaign);
  const [t] = useTranslation(['candidates', 'common']);

  const contactView = props.contactView;

  let [mapCenter, setCenter] = useState({ lat: 0, lng: 0 });
  let [selectedMarker, setMarker] = useState(false);

  const userGeoLoc = {
    latitude: 0,
    longitude: 0
  };

  const getMapCenter = () => {
    if (userGeoLoc.longitude) {
      setCenter(userGeoLoc);
    } else {
      if (campaignPostalCode && campaignCity) {
        return axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${campaignPostalCode} ${campaignCity}&key=${process.env.REACT_APP_MAPS_API}`
          )
          .then((center) =>
            setCenter(center.data?.results[0]?.geometry?.location)
          );
      }
      if (campaignPostalCode) {
        return axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${campaignPostalCode}&key=${process.env.REACT_APP_MAPS_API}`
          )
          .then((center) =>
            setCenter(center.data?.results[0]?.geometry?.location)
          );
      } else if (campaignCity) {
        return axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${campaignCity}&key=${process.env.REACT_APP_MAPS_API}`
          )
          .then((center) =>
            setCenter(center.data?.results[0]?.geometry?.location)
          );
      } else {
        return axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${campaignCountry.name}&key=${process.env.REACT_APP_MAPS_API}`
          )
          .then((center) => {
            setCenter(center.data?.results[0]?.geometry?.location);
          });
      }
    }
  };

  const zoomToMarkers = (map) => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();

      map.props.children[0].forEach((child) => {
        if (child && child.type === Marker) {
          bounds.extend(
            new window.google.maps.LatLng(
              child.props.position?.lat,
              child.props.position?.lng
            )
          );
        }
      });

      bounds.extend(
        new window.google.maps.LatLng(
          map.props.children[1].props.position?.lat,
          map.props.children[1].props.position?.lng
        )
      );

      map.fitBounds(bounds);
    }
  };

  const onMarkerClick = (marker, event) => {
    window.location = `/dashboard/campaigns/${campaignId}/candidates/${marker.id}`;
  };

  useEffect(() => {
    getMapCenter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (campaignCandidates.length !== 0)
    return (
      <GoogleMap style={mapStyles} center={userGeoLoc} ref={zoomToMarkers}>
        {campaignCandidates
          ?.filter((candidate) => {
            if (contactView) {
              return candidate.status !== 1 && candidate.status !== 2;
            } else return candidate;
          })
          .map((candidate) => {
            const onClick = onMarkerClick.bind(this, candidate);
            let { firstName, lastName, address, avatar } = candidate;
            if (address) {
              let {
                addressLine1,
                addressLine2,
                city,
                postalCode,
                latitude,
                longitude,
              } = address;
              if (latitude && longitude)
                return (
                  <Marker
                    key={candidate.id}
                    icon={{
                      url: avatar
                        ? avatar
                        : 'https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg',
                      shape: 'circle',
                      scaledSize: new window.google.maps.Size(25, 25),
                    }}
                    position={{
                      lat: parseFloat(latitude),
                      lng: parseFloat(longitude),
                    }}
                    onClick={onClick}
                  >
                    {selectedMarker === candidate && (
                      <InfoWindow
                        onCloseClick={() => {
                          setMarker(false);
                        }}
                      >
                        <div>
                          <Typography variant='h3'>{`${firstName} ${lastName}`}</Typography>
                          <Typography display='block' variant='p'>{`${
                            addressLine1 ? addressLine1 : ''
                          }`}</Typography>
                          <Typography display='block' variant='p'>{`${
                            addressLine2 ? addressLine2 : ''
                          }`}</Typography>
                          <Typography display='block' variant='p'>{`${
                            city ? city : ''
                          }`}</Typography>
                          <Typography
                            display='block'
                            variant='p'
                          >{`${postalCode.toUpperCase()}`}</Typography>
                          <Link
                            component={RouterLink}
                            to={`/dashboard/campaigns/${campaignId}/candidates/${candidate.id}`}
                            variant='h5'
                            color='textPrimary'
                            underline='none'
                          >
                            {t('common:viewMore')}
                          </Link>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
            }

            return null;
          })}
        <Marker
          icon='https://www.robotwoods.com/dev/misc/bluecircle.png'
          position={mapCenter}
        />
      </GoogleMap>
    );
  else
    return (
      <GoogleMap
        style={mapStyles}
        defaultZoom={20000}
        center={mapCenter}
      ></GoogleMap>
    );
});

export default CandidatesMap;
