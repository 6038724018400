import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

import * as consultancyApi from './../../../../api/consultancyApi';

import { BlockHeader } from './../../../../components/LayoutComponents/BlockHeader';
import { CardButton } from './../../../../components/LayoutComponents/CardButton';

const useStyles = makeStyles(() => ({
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  typography: {
    marginBottom: '32px'
  },
  textField: {
    marginBottom: '16px'
  }
}));

const HotjarSettings = ({ consultancyId }) => {

  const classes = useStyles();
  const [t] = useTranslation(['settings']);
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(true);

  const schema = Yup.object({
    siteId: Yup.number().required(),
    hotjarVersion: Yup.number().required(),
  });

  const form = useFormik({
    initialValues: {
      siteId: null,
      hotjarVersion: 6
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setIsLoading(true);

      consultancyApi.addHotjarData(consultancyId, values.siteId, values.hotjarVersion)
        .then(() => {
          enqueueSnackbar(t('settings:hotjarSettingsNotificationDataSaved'), { variant: 'success' });
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar(t('settings:hotjarSettingsNotificationSaveDataError'), { variant: 'error' });
          setIsLoading(false);
        });
    }
  });

  useEffect(() => {
    consultancyApi.getHotjarDataByConsultancyId(consultancyId)
      .then((response) => {
        if (response.data) {
          form.setValues({
            siteId: response.data.siteId,
            hotjarVersion: response.data.hotjarVersion
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('settings:hotjarSettingsNotificationGetDataError'), { variant: 'error' });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId]);

  return (
    <>
      <Grid container spacing={ 3 } >
        <Grid item xs={ 12 } md={ 12 } >
          <Card>
            <BlockHeader card padding header={ t("settings:hotjarSettingsHeader") } />

            <CardContent>
              {
                isLoading ? (
                  <>
                    <div className={ classes.loaderWrapper }>
                      <CircularProgress />
                    </div>
                  </>
                ) : (
                  <>
                    <Typography variant="h3" className={ classes.typography } >
                      { t("settings:hotjarSettingsInstructions") }
                    </Typography>
      
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="siteId"
                      type="number"
                      className={ classes.textField }
                      label={ t("settings:hotjarSettingsSiteIdLabel") }
                      value={ form.values.siteId }
                      onChange={ form.handleChange } />
      
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="hotjarVersion"
                      type="number"
                      label={ t("settings:hotjarSettingsHotjarVersionLabel") }
                      value={ form.values.hotjarVersion }
                      onChange={ form.handleChange } />

                    <CardButton
                      btnLabel={t("settings:hotjarSettingsSaveButton")}
                      btnVariant="contained"
                      btnFunc={ form.handleSubmit } />
                  </>
                )
              }
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );

};

export default HotjarSettings;
