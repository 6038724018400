import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QuillEditor from "src/components/QuillEditor";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	subjectInput: {
		marginBottom: "20px",
	},
	updateButton: {
		marginTop: "20px",
	},
	tagContainer: {
		marginBottom: "20px",
	},
}));

const EmailTemplate = ({ email, emailTags, updateEmailTemplate }) => {
	const [t] = useTranslation(["common", "snackbar", "validation"]);
	const classes = useStyles();
	const [emailTemplate, setEmailTemplate] = useState({
		...email,
	});

	const handleEmailSubjectChange = (event) => {
		event.persist();
		setEmailTemplate((prevValues) => ({
			...prevValues,
			subjectHTML: event.target.value,
		}));
	};

	const handleEmailContentChange = (event) => {
		let formattedEmailContent = event
			.replace(/&gt;/g, ">")
			.replace(/&lt;/g, "<");
		setEmailTemplate((prevValues) => ({
			...prevValues,
			formattedEmailContent,
			isValid: true,
		}));
	};

	// const doesEmailContentHaveTags = (formattedEmailContent) => {
	//   let isValid = true;
	//   emailTags.forEach((tag)=>{
	//     if(!formattedEmailContent.includes(tag.tag)){
	//       isValid = false
	//     }
	//   });
	//   return isValid
	// }

	return (
		<>
			<TextField
				fullWidth
				name="subject"
				variant="outlined"
				value={emailTemplate.subjectHTML || " "}
				className={classes.subjectInput}
				onChange={handleEmailSubjectChange}
			/>
			<div className={classes.tagContainer}>
				<Typography variant="h4">{t("common:requiredTags")}: </Typography>
				{emailTags?.map((emailTag) => (
					<Typography variant="h5">{emailTag.tag}</Typography>
				))}
			</div>
			<QuillEditor
				value={emailTemplate.contentHTML || " "}
				handleChange={handleEmailContentChange}
				toolbarOptions={["link"]}
			/>
			<div style={{ textAlign: "center" }}>
				<Button
					color="primary"
					variant="contained"
					size="large"
					disabled={!emailTemplate.isValid}
					onClick={(event) => updateEmailTemplate(event, emailTemplate)}
					className={classes.updateButton}
				>
					{t("common:update")}
				</Button>
				{!emailTemplate.isValid ? (
					<Typography variant="h5" color="error">
						{t("validation:missingEmailTags")}
					</Typography>
				) : null}
			</div>
		</>
	);
};

export default EmailTemplate;
