import React, { useState, useEffect } from "react";
import {
	Box,
	Button,
	Collapse,
	Dialog,
	Divider,
	Grid,
	Paper,
	Slide,
	Switch,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../api/landingApi";
import { useSnackbar } from "notistack";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import { InputAndButton } from "src/components/LayoutComponents/InputAndButton";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import LandingCards from "src/views/LandingPage/LandingCards";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const useStyles = makeStyles((theme) => ({
	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`,
	},
	button_container: {
		display: "flex",
		justifyContent: "center",
		marginTop: theme.spacing(3),
	},
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const ManageCards = ({ landingPageData, assetData, getLandingPageData }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [currentTab, setCurrentTab] = useState(0);
	const [expand, setExpand] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [cardData, setCardData] = useState({});
	const [showPreview, setShowPreview] = useState(false);
	const [percent, setPercent] = useState(0);

	const currentData = landingPageData.cards[0];
	const { consultancyId } = landingPageData;
	const landingPageId = landingPageData.id;

	const exampleData = {
		landing: { cards: [cardData] },
	};

	useEffect(() => {
		setCardData(currentData);
		setEnabled(landingPageData.display_cards);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentData]);

	const toggleContentState = (fieldName) => {
		setEnabled(!enabled);
		return api
			.toggleDisplayContent(consultancyId, fieldName)
			.then((response) => {
				if (response.status === 200 && !enabled) {
					enqueueSnackbar("Card block enabled", {
						variant: "success",
					});
				} else if (response.status === 200 && enabled) {
					enqueueSnackbar("Card block disabled", {
						variant: "info",
					});
				}
			})
			.catch(() => {
				setEnabled(enabled);
				enqueueSnackbar("Could not be enabled at this time", {
					variant: "error",
				});
			});
	};

	const handleTabChange = (event, newValue) => {
		setCurrentTab(newValue);
	};

	const handleCardImage = (value, fieldName) => {
		const uploadOptions = {
			params: {
				consultancyId: consultancyId,
				landingPageId: landingPageId,
				fieldName: fieldName,
			},
			onUploadProgress: (progressEvent) => {
				const { loaded, total } = progressEvent;

				if (loaded && total) {
					let newPercent = Math.floor((loaded * 100) / total);

					setPercent(newPercent);
				}
			},
		};
		setPercent(0);
		return api.uploadAsset(uploadOptions, value).then((response) => {
			getLandingPageData(consultancyId);
			if (response) {
				enqueueSnackbar("Image uploaded", {
					variant: "success",
				});
			} else {
				enqueueSnackbar("Upload failed", {
					variant: "error",
				});
			}
		});
	};

	const handleContentChange = (event, index) => {
		const { name, value } = event.target;
		setCardData((prevState) => ({
			...prevState,
			[index]: {
				...prevState[index],
				[name]: value,
			},
		}));
	};

	const applyChanges = () => {
		return api
			.updateContentBlock(consultancyId, "cards", cardData)
			.then((response) => {
				if (response) {
					enqueueSnackbar("Content updated", { variant: "success" });
				}
			})
			.catch(() => {
				enqueueSnackbar("Unable to apply changes", { variant: "error" });
			});
	};

	const deleteAsset = (type) => {
		return api.deleteAsset(landingPageId, type).then((response) => {
			if (response.status === 200) {
				getLandingPageData(consultancyId);
				enqueueSnackbar("Image deleted", {
					variant: "success",
				});
			} else {
				enqueueSnackbar("Failed to delete image", {
					variant: "error",
				});
			}
		});
	};

	return (
		<>
			<Grid container style={{ paddingTop: ".4rem" }}>
				<Grid item xs={6}>
					<Box
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
						}}
					>
						<Button
							variant="text"
							endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
							onClick={() => {
								setExpand(!expand);
							}}
						>
							Cards
						</Button>
					</Box>
				</Grid>
				<Grid item xs={6}>
					<Box display="flex" flexDirection="row-reverse" alignItems="center">
						<Tooltip title="Designed for landscape images with an approximate 2:1 ratio. Images that are 400px x 200px will provide the best load times and display at full resolution.">
							<InfoOutlinedIcon />
						</Tooltip>
						<Slide in={expand} direction="left">
							<div>
								<Switch
									checked={enabled}
									color="primary"
									style={{ marginLeft: "auto" }}
									onChange={() => toggleContentState("display_cards")}
								/>
								<Button
									variant="text"
									onClick={() => toggleContentState("display_cards")}
								>
									Enable Content Block
								</Button>
							</div>
						</Slide>
					</Box>
				</Grid>
			</Grid>
			<Collapse in={expand}>
				<InputAndButton
					value={cardData[0]?.header}
					onChange={(event) => handleContentChange(event, 0)}
					btnLabel="Preview"
					btnFunc={() => setShowPreview(true)}
				/>
				<Grid container>
					<Grid item xs={6} lg={2}>
						<Tabs
							orientation="vertical"
							value={currentTab}
							onChange={handleTabChange}
							variant="scrollable"
							className={classes.tabs}
						>
							<Tab label="Card One" />
							<Tab label="Card Two" />
							<Tab label="Card Three" />
						</Tabs>
					</Grid>
					<Grid item xs={6} lg={10}>
						<TabPanel value={currentTab} index={0}>
							<Box display="flex" flexDirection="row">
								<Grid item xs={12} lg={4}>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										height="100%"
									>
										<Paper style={{ padding: "2rem" }}>
											<CustomFileDropzone
												type="single"
												actionText="Upload Image"
												format="image"
												placeHolderImage={assetData[0]}
												uploadFunction={(value) =>
													handleCardImage(value, "card_1")
												}
												name="title"
												variant="outlined"
												placeholder="Card title"
												value={cardData[1]?.title}
												onChange={(event) => handleContentChange(event, 1)}
												style={{
													marginBottom: ".25rem",
													backgroundColor: "white",
												}}
												percent={percent}
											/>

											{assetData[0] && (
												<Box className={classes.button_container}>
													<Button
														variant="text"
														color="primary"
														endIcon={<DeleteForeverIcon />}
														onClick={() => deleteAsset("card_1")}
													>
														Delete Image
													</Button>
												</Box>
											)}
										</Paper>
									</Box>
								</Grid>
								<Grid item xs={12} lg={8}>
									<form>
										<Box display="flex" flexDirection="column">
											<TextField
												name="title"
												variant="outlined"
												placeholder="Card title"
												value={cardData[1]?.title}
												onChange={(event) => handleContentChange(event, 1)}
												style={{
													marginBottom: ".25rem",
													backgroundColor: "white",
												}}
											/>

											<TextField
												name="body"
												variant="outlined"
												multiline
												placeholder="Card body"
												value={cardData[1]?.body}
												onChange={(event) => handleContentChange(event, 1)}
												style={{
													marginTop: ".25rem",
													backgroundColor: "white",
												}}
											/>
										</Box>
									</form>
								</Grid>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={1}>
							<Box display="flex" flexDirection="row">
								<Grid item xs={12} lg={4}>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										height="100%"
									>
										<Paper style={{ padding: "2rem" }}>
											<CustomFileDropzone
												type="single"
												actionText="Upload Image"
												format="image"
												placeHolderImage={assetData[1]}
												uploadFunction={(value) =>
													handleCardImage(value, "card_2")
												}
												percent={percent}
											/>
											{assetData[1] && (
												<Box className={classes.button_container}>
													<Button
														variant="text"
														color="primary"
														endIcon={<DeleteForeverIcon />}
														onClick={() => deleteAsset("card_2")}
													>
														Delete Image
													</Button>
												</Box>
											)}
										</Paper>
									</Box>
								</Grid>
								<Grid item xs={12} lg={8}>
									<form>
										<Box display="flex" flexDirection="column">
											<TextField
												name="title"
												variant="outlined"
												placeholder="Card title"
												value={cardData[2]?.title}
												onChange={(event) => handleContentChange(event, 2)}
												style={{
													marginBottom: ".25rem",
													backgroundColor: "white",
												}}
											/>

											<TextField
												name="body"
												variant="outlined"
												multiline
												placeholder="Card body"
												value={cardData[2]?.body}
												onChange={(event) => handleContentChange(event, 2)}
												style={{
													marginTop: ".25rem",
													backgroundColor: "white",
												}}
											/>
										</Box>
									</form>
								</Grid>
							</Box>
						</TabPanel>
						<TabPanel value={currentTab} index={2}>
							<Box display="flex" flexDirection="row">
								<Grid item xs={12} lg={4}>
									<Box
										display="flex"
										alignItems="center"
										justifyContent="center"
										height="100%"
									>
										<Paper style={{ padding: "2rem" }}>
											<CustomFileDropzone
												type="single"
												actionText="Upload Image"
												format="image"
												placeHolderImage={assetData[2]}
												uploadFunction={(value) =>
													handleCardImage(value, "card_3")
												}
												percent={percent}
											/>
											{assetData[2] && (
												<Box className={classes.button_container}>
													<Button
														variant="text"
														color="primary"
														endIcon={<DeleteForeverIcon />}
														onClick={() => deleteAsset("card_3")}
													>
														Delete Image
													</Button>
												</Box>
											)}
										</Paper>
									</Box>
								</Grid>
								<Grid item xs={12} lg={8}>
									<form>
										<Box display="flex" flexDirection="column">
											<TextField
												name="title"
												variant="outlined"
												placeholder="Card title"
												value={cardData[3]?.title}
												onChange={(event) => handleContentChange(event, 3)}
												style={{
													marginBottom: ".35rem",
													backgroundColor: "white",
												}}
											/>

											<TextField
												name="body"
												variant="outlined"
												multiline
												placeholder="Card body"
												value={cardData[3]?.body}
												onChange={(event) => handleContentChange(event, 3)}
												style={{
													marginTop: ".25rem",
													backgroundColor: "white",
												}}
											/>
										</Box>
									</form>
								</Grid>
							</Box>
						</TabPanel>
					</Grid>
				</Grid>
				<ButtonWrapper btnLabel="Apply Changes" btnFunc={applyChanges} />
			</Collapse>
			<Divider />
			<Dialog
				maxWidth="xl"
				open={showPreview}
				onClose={() => setShowPreview(false)}
			>
				<Box display="flex" flexDirection="row-reverse">
					<Button
						variant="text"
						onClick={() => {
							setShowPreview(false);
						}}
					>
						Close
					</Button>
				</Box>
				<LandingCards
					consultancyDetails={exampleData}
					assetUrls={{
						card_1: assetData[0],
						card_2: assetData[1],
						card_3: assetData[2],
					}}
				/>
			</Dialog>
		</>
	);
};

export default ManageCards;
