import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	Card,
	CardContent,
	CircularProgress,
	Grid,
} from "@material-ui/core";
import QuillEditor from "src/components/QuillEditor";
import * as CandidateApi from "src/api/candidateApi";
import { useSnackbar } from "notistack";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";

const useStyles = makeStyles((theme) => ({
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
}));

const DefaultCandidateExecutiveSummary = ({
	candidateId,
	executiveSummary,
	handleSummaryChange,
}) => {
	const classes = useStyles();
	const [t] = useTranslation(["common", "snackbar"]);
	const [loading, setLoading] = useState(false);
	const { enqueueSnackbar } = useSnackbar();

	const consultancyId = useSelector((state) => state.session.consultancyId);

	const onSuccess = (summary) => {
		return CandidateApi.updateDefaultCandidateExecutiveSummary(
			candidateId,
			consultancyId,
			summary
		)
			.then((response) => {
				if (response.status === 200) {
					enqueueSnackbar(
						t("snackbar:succesfullUpdatedDefaultExecutiveSummary"),
						{
							variant: "success",
						}
					);
				}
			})
			.catch(() => {
				enqueueSnackbar(t("snackbar:failedToUpdateDefaultExecutiveSummary"), {
					variant: "error",
				});
			});
	};

	const onSubmit = function(event) {
		event.preventDefault();
		setLoading(true);
		onSuccess(executiveSummary).then(() => {
			setLoading(false);
		});
	};

	return executiveSummary !== undefined ? (
		<Grid container alignItems="baseline" spacing={3}>
			<Grid item xs={12}>
				<form onSubmit={onSubmit}>
					<Card>
						<div style={{ padding: 16 }}>
							<BlockHeader
								defeatMargin
								header={t(`common:defaultExecutiveSummary`)}
							/>
						</div>
						<CardContent>
							<QuillEditor
								value={executiveSummary}
								handleChange={handleSummaryChange}
								toolbarOptions={["link"]}
							/>
							<CardButton defeatMargin save loading={loading} />
						</CardContent>
					</Card>
				</form>
			</Grid>
		</Grid>
	) : (
		<CircularProgress className={classes.progressCircle} size={25} />
	);
};

export default DefaultCandidateExecutiveSummary;
