import { theme } from "src/theme";

export const profileTypes = [
  {
    name: "generalist",
    value: 0,
  },
  {
    name: "pioneer",
    value: 1,
  },
  {
    name: "persuader",
    value: 2,
  },
  {
    name: "administrator",
    value: 3,
  },
  {
    name: "balanced",
    value: 4,
  },
  {
    name: "specialist",
    value: 5,
  },
  {
    name: "coOperator",
    value: 6,
  },
  {
    name: "enthusiast",
    value: 7,
  },
  {
    name: "transition",
    value: 8,
  },
];

export const mcQauigCategories = ["do", "so", "re", "co"];

export const getChartOptions = (data) => {
  let yAxisMax = Object.values(data.scores).map((value) => parseInt(value));
  return {
    title: {
      text: data.title,
      x: "center",
      textStyle: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 20,
      },
    },
    xAxis: {
      data: ["Ac", "An", "Dr", "In"],
      splitLine: { show: false },
      axisTick: { show: false },
      axisLabel: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      max: Math.max(...yAxisMax) > 90 ? Math.max(...yAxisMax) + 10 : 90,
      splitNumber: 10,
      axisTick: { show: false },
      axisLabel: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 16,
      },
    },
    series: [
      {
        type: "candlestick",
        silent: true,
        data: [
          {
            value: [
              Object.values(data.scores)[0],
              42,
              Object.values(data.scores)[0],
              42,
            ],
            itemStyle: { borderWidth: 0, color: "#c44826", color0: "#c44826" },
          },
          {
            value: [
              Object.values(data.scores)[1],
              42,
              Object.values(data.scores)[1],
              42,
            ],
            itemStyle: { borderWidth: 0, color: "#009e4b", color0: "#009e4b" },
          },
          {
            value: [
              Object.values(data.scores)[2],
              42,
              Object.values(data.scores)[2],
              42,
            ],
            itemStyle: { borderWidth: 0, color: "#0076bc", color0: "#0076bc" },
          },
          {
            value: [
              Object.values(data.scores)[3],
              42,
              Object.values(data.scores)[3],
              42,
            ],
            itemStyle: { borderWidth: 0, color: "#ffd24f", color0: "#ffd24f" },
          },
        ],
        itemStyle: {
          borderWidth: 0,
        },
        markLine: {
          label: { show: false },
          lineStyle: {
            color: "#000",
            type: "solid",
            width: 2,
            emphasis: {
              color: "#000",
              type: "solid",
              width: 2,
            },
          },
          animation: false,
          symbol: ["none", "none"],
          data: [
            {
              name: "test",
              yAxis: 42,
            },
          ],
        },
      },
    ],
  };
};

export const mcQuaigScales = [
  {
    color: "Red",
    title: "dominant",
    title2: "accepting ",
    desc: "redScaleMcQuaigDescription",
  },
  {
    color: "Green",
    title: "social",
    title2: "analytical ",
    desc: "greenScaleMcQuaigDescription",
  },
  {
    color: "Blue",
    title: "relaxed",
    title2: "driving ",
    desc: "blueScaleMcQuaigDescription",
  },
  {
    color: "Yellow",
    title: "compliant",
    title2: "independent ",
    desc: "yellowScaleMcQuaigDescription",
  },
];

export const profileMatches = [
  {
    value: 0,
    name: "strongMatch",
  },
  {
    value: 1,
    name: "potentialMatch",
  },
  {
    value: 2,
    name: "doesNotMatch",
  },
];
