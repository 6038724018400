import { useSelector } from 'react-redux';
import * as subscriptionPlansIds from '../constants/subscriptionPlansIds';


const useCampaignOrVacancyTranslationPrefix = () => {
    const { consultancySubscriptionPlanId } = useSelector((state) => state.session);

    if (consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
        return 'campaigns';
    } 

    return 'vacancies';
};

const useCandidatesOrVacancyCandidatesTranslationPrefix = () => {
    const { consultancySubscriptionPlanId } = useSelector((state) => state.session);

    if (consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
        return 'candidates';
    }

    return 'vacancyCandidates';
};

const useNotificationsOrVacancyNotificationsTranslationPrefix = () => {
    const { consultancySubscriptionPlanId } = useSelector((state) => state.session);

    if (consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
        return 'notifications';
    }

    return 'vacancyNotifications';
};

export {
    useCampaignOrVacancyTranslationPrefix,
    useCandidatesOrVacancyCandidatesTranslationPrefix,
    useNotificationsOrVacancyNotificationsTranslationPrefix,
};