import axios from '../utils/axios';

/*-----ADMIN/CONSULTANT--------*/

// All campaigns

export const createTalentAlertCampaign = (consultancyId, campaignTitle) => {
  return axios.post('/TalentAlertCampaign/createTalentAlertCampaign', {
    consultancyId,
    campaignTitle
  }
  );
};

export const getConsultancyLogo = (consultancyId) => {
  return axios.get('/TalentAlertCampaign/getConsultancyLogo', {
    params: { consultancyId }
  });
};

export const getTalentAlertCampaigns = (consultancyId) => {
  return axios.get('/TalentAlertCampaign/getTalentAlertCampaigns', {
    params: {
      consultancyId
    }
  });
};

// Editing a candidate's profile

export const getCandidateProfile = (consultancyId, candidateId, campaignId, isPreview = false) => {
  return axios.get('/TalentAlertCampaign/getCandidateProfile', {
    params: {
      consultancyId,
      candidateId,
      campaignId,
      isPreview
    }
  });
};

export const updateCandidateKeyFacts = (
  candidateId,
  consultancyId,
  campaignId,
  keyFacts
) => {
  return axios.put(
    '/TalentAlertCampaign/updateCandidateKeyFacts',
    { keyFacts },
    {
      params: { candidateId, consultancyId, campaignId }
    }
  );
};

export const updateCandidateOverviews = (
  candidateId,
  consultancyId,
  campaignId,
  values
) => {
  return axios.put(
    '/TalentAlertCampaign/updateCandidateOverviews',
    { values },
    {
      params: { candidateId, consultancyId, campaignId }
    }
  );
};

export const uploadCandidateProfilePicture = (
  campaignId,
  candidateId,
  consultancyId,
  formData
) => {
  return axios.post(
    '/TalentAlertCampaign/uploadCandidateProfilePicture',
    formData,
    {
      params: {
        campaignId,
        candidateId,
        consultancyId
      }
    }
  );
};


// Candidate Pool

export const getAllCandidates = (consultancyId) => {
  return axios.get(`/TalentAlertCampaign/getAllCandidates/${consultancyId}`);
};

export const getAvailableCandidates = (consultancyId, campaignId) => {
  return axios.get('/TalentAlertCampaign/getAvailableCandidates', {
    params: {
      consultancyId,
      campaignId
    }
  });
};

export const createAndAssignConsultancyCandidate = ({
  consultancyId,
  campaignId,
  firstName,
  lastName,
  postalCode,
  email,
  country
}) => {
  return axios.post('/TalentAlertCampaign/createAndAssignConsultancyCandidate', {
    consultancyId,
    campaignId,
    firstName,
    lastName,
    postalCode,
    email,
    countryId: country.id
  });
};

export const assignExistingCandidateToCampaign = (candidateDetails) => {
  return axios.post('/TalentAlertCampaign/assignExistingCandidateToCampaign', {
    candidateDetails
  });
};

export const sendTalentAlertInvitationForCandidate = (invitationData, consultancyId, campaignId) => {
  return axios.post(
    '/TalentAlertCampaign/sendTalentAlertInvitationForCandidate',
    invitationData,
    {
      params: {
        campaignId,
        consultancyId
      }
    }
  );
};

export const removeCandidate = (consultancyId, candidateId, campaignId) => {
  return axios.delete('/TalentAlertCampaign/removeCandidate', {
    params: {
      consultancyId,
      candidateId,
      campaignId
    },
  });
};


// Talent Alert Campaign Management

export const saveRequiredCandidateDocument = (consultancyId, campaignId, document) => {
  return axios.post(
    '/TalentAlertCampaign/saveRequiredCandidateDocument',
    {
      consultancyId,
      campaignId,
      document
    }
  );
};

export const removeCandidateRequiredDocument = (consultancyId, campaignId, documentId) => {
  return axios.delete(
    '/TalentAlertCampaign/removeCandidateRequiredDocument',
    {
      params: {
        consultancyId,
        campaignId,
        documentId
      },
    }
  );
};

export const saveCandidateDocumentsPriorityOrder = (consultancyId, campaignId, newPriorityDocuments) => {
  return axios.post(
    '/TalentAlertCampaign/saveCandidateDocumentsPriorityOrder',
    {
      consultancyId,
      campaignId,
      newPriorityDocuments
    }
  );
};

export const updateTalentAlertCampaignTermsAndConditions = (campaignId, termsAndConditions, termsAndConditionsType) => {
  return axios.patch('/TalentAlertCampaign/updateTalentAlertCampaignTermsAndConditions', {
    campaignId,
    campaignTermsAndConditions: termsAndConditions,
    termsAndConditionsType
  });
};

export const deleteTalentAlertCampaign = (talentAlertCampaignId, consultancyId) => {
  return axios.delete('/TalentAlertCampaign/deleteTalentAlertCampaign', {
    params: {
      talentAlertCampaignId,
      consultancyId
    }
  });
};

export const getTalentAlertCampaign = (consultancyId, campaignId) => {
  return axios.get('/TalentAlertCampaign/getTalentAlertCampaign', {
    params: {
      consultancyId,
      campaignId
    },
  });
};

export const updateIntroduction = (campaignId, consultancyId, introduction) => {
  return axios.put(
    '/TalentAlertCampaign/updateIntroduction',
    { introduction },
    {
      params: { campaignId, consultancyId }
    }
  );
};

export const uploadHeaderImage = (campaignId, image) => {
  return axios.post('/TalentAlertCampaign/uploadHeaderImage', image, {
    params: {
      campaignId
    },
  })
    .then((response) => {
      if (response.data !== null && response.data !== undefined) {
        return response.data;
      } else {
        throw Error('Failed to upload background image');
      }
    });
};

export const getHeaderImg = (campaignId) => {
  return axios.get('/TalentAlertCampaign/getHeaderImg', {
    params: {
      campaignId
    }
  });
};

export const updateTalentAlertCampaignDetails = ({ campaignId, consultancyId, details }) => {
  return axios.patch('/TalentAlertCampaign/updateTalentAlertCampaignDetails', {
    campaignId,
    consultancyId,
    details
  });
};

export const getTalentAlertStatuses = ({ campaignId, consultancyId }) => {
  return axios.get('/TalentAlertCampaign/getTalentAlertStatuses', {
    params: { campaignId, consultancyId }
  });
};

export const updateTalentAlertStatus = ({ consultancyId, campaignId, statusId }) => {
  return axios.patch('/TalentAlertCampaign/updateTalentAlertCampaignStatus', {
    consultancyId,
    campaignId,
    statusId
  });
};

export const uploadCampaignVideo = (data, uploadOptions) => {
  return axios.post('/TalentAlertCampaign/uploadTalentAlertVideo', data, uploadOptions);
};

export const cloudinaryWidgetUploadTalentAlertVideo = (data, uploadOptions) => {
  return axios.post('/TalentAlertCampaign/cloudinaryWidgetUploadTalentAlertVideo', data, uploadOptions);
};

export const assignKCQToCampaign = (kcqData, consultancyId, campaignId) => {
  return axios.post('/TalentAlertCampaign/assignKCQToCampaign',
    kcqData,
    {
      params: {
        consultancyId,
        campaignId
      },
    }
  );
};

export const updateTalentAlertCampaignKCQ = (campaignId, consultancyId, questions) => {
  return axios.post('/TalentAlertCampaign/updateTalentAlertCampaignKCQ',
    {
      questions
    },
    {
      params: {
        campaignId,
        consultancyId
      }
    }
  );
};

export const getTalentAlertCampaignKeyCompetencyQuestionSet = (consultancyId, campaignId) => {
  return axios.get('/TalentAlertCampaign/getTalentAlertCampaignKeyCompetencyQuestionSet', {
    params: {
      consultancyId,
      campaignId
    }
  });
};

export const deleteTalentAlertCampaignKCQ = (campaignId, consultancyId) => {
  return axios.delete('/TalentAlertCampaign/deleteTalentAlertCampaignKCQ', {
    params: {
      campaignId,
      consultancyId
    }
  });
};


// Campaign Settings

export const updateTalentAlertCampaignVisibility = ({ campaignVisibilityData }) => {
  return axios.patch('/TalentAlertCampaign/updateTalentAlertCampaignVisibility', {
    campaignVisibilityData
  });
};


// Target List

export const addContact = (campaignId, contactId) => {
  return axios.post('/TalentAlertCampaign/addContact', null, {
    params: {
      campaignId,
      contactId
    },
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

export const getContacts = (campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getContacts', {
    params: {
      campaignId,
      consultancyId
    }
  })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get contacts');
      }
    });
};

export const sendTalentAlertCampaignContactInvitations = (
  { emailSubject, emailContent, selectedContacts, selectedCandidates },
  consultancyId,
  campaignId
) => {
  return axios.post(
    '/TalentAlertCampaign/sendTalentAlertCampaignContactInvitations',
    { emailSubject, emailContent, selectedContacts, selectedCandidates },
    {
      params: {
        campaignId,
        consultancyId
      },
    }
  );
};

export const getEmployers = (consultancyId) => {
  return axios.get('/TalentAlertCampaign/getEmployers', {
    params: {
      consultancyId
    },
  });
};

export const getContactsConnectedToCampaign = (campaignId) => {
  return axios
    .get('/TalentAlertCampaign/getContactsConnectedToCampaign', {
      params: {
        campaignId
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get all contacts');
      }
    });
};

export const createAndAddContact = (
  id,
  {
    firstName,
    lastName,
    salutation,
    email,
    telephone,
    consultancyId,
    employerId,
    jobTitle
  }
) => {
  return axios
    .post(
      '/TalentAlertCampaign/createAndAddContact',
      {
        firstName,
        lastName,
        salutation,
        email,
        telephone,
        consultancyId,
        employerId,
        jobTitle
      },
      {
        params: {
          id
        }
      }
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

export const createEmployerAndContact = (campaignId, consultancyId, employerDetails, contactDetails) => {
  return axios.post(
    '/TalentAlertCampaign/createEmployerAndContact',
    {
      employerDetails,
      contactDetails
    },
    {
      params: {
        campaignId,
        consultancyId
      }
    }
  );
};

export const removeContact = (campaignId, campaignContactId) => {
  return axios
    .delete('/TalentAlertCampaign/removeContact', {
      params: {
        campaignId,
        campaignContactId
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

/*-------CANDIDATES--------*/

export const getCandidateTalentAlertProfile = (campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getCandidateTalentAlertProfile', {
    params: {
      campaignId,
      consultancyId
    }
  });
};

export const acceptCampaignTermsAndConditions = (consultancyId, campaignId) => {
  return axios.post('/TalentAlertCampaign/acceptCampaignTermsAndConditions', {
    consultancyId,
    campaignId
  });
};

export const getCandidateTalentAlerts = (consultancyId) => {
  return axios.get('/TalentAlertCampaign/getCandidateTalentAlerts', {
    params: {
      consultancyId
    }
  });
};

export const submitCandidateProfile = (consultancyId, candidateId, campaignId) => {
  return axios.post('/TalentAlertCampaign/submitCandidateProfile', {
    consultancyId,
    candidateId,
    campaignId
  });
};


/*-------CONTACTS--------*/

export const contactRecruiter = (formData, campaignId, isForTalentAlert, options) => {
  return axios.post('/TalentAlertCampaign/contactRecruiter', {
    formData,
    campaignId,
    isForTalentAlert,
    options
  });
};

export const getTalentAlertCandidateProfile = (consultancyId, candidateId, campaignId) => {
  return axios.get('/TalentAlertCampaign/getTalentAlertCandidateProfile', {
    params: {
      consultancyId,
      candidateId,
      campaignId
    },
  });
};

export const getContactTalentAlertById = (campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getContactTalentAlertById', {
    params: {
      campaignId,
      consultancyId
    },
  });
};

export const checkIfInvited = (campaignId) => {
  return axios.get('/TalentAlertCampaign/checkIfInvited', {
    params: {
      campaignId
    }
  });
};

export const checkIfAcceptedTCs = (campaignId) => {
  return axios.get('/TalentAlertCampaign/checkIfAcceptedTCs', {
    params: {
      campaignId
    }
  });
};

export const getHiddenTalentAlertCandidateProfile = (consultancyId, candidateId, campaignId, isForPreview = false) => {
  return axios.get('/TalentAlertCampaign/getHiddenTalentAlertCandidateProfile', {
    params: {
      consultancyId,
      candidateId,
      campaignId,
      isForPreview
    }
  });
};

export const getContactHiddenTalentAlertById = (campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getContactHiddenTalentAlertById', {
    params: {
      campaignId,
      consultancyId
    },
  });
};

export const checkIfRegistered = (email, host, campaignId) => {
  return axios.post(
    'TalentAlertCampaign/checkIfRegistered',
    { email, host },
    {
      params: { campaignId }
    }
  );
};

export const getConsultancyId = (host) => {
  return axios.get('/TalentAlertCampaign/getConsultancyId', {
    params: {
      host
    }
  });
};

export const sendTalentAlertActivation = (consultancyId, { email }) => {
  return axios.post('/TalentAlertCampaign/sendTalentAlertActivation',
    { email },
    {
      params: {
        consultancyId
      }
    });
};

export const getCampaignsManagedBySelectedUser = (userId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getCampaignsManagedBySelectedUser', {
    params: {
      userId,
      consultancyId,
    },
  });
};

export const getContactHiddenTalentAlertDataForShare = (campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getContactHiddenTalentAlertDataForShare', {
    params: {
      campaignId,
      consultancyId
    }
  });
};

export const getCampaignHTMLSnippet = (consultancyId, campaignId) => {
  return axios.get('/TalentAlertCampaign/getCampaignHTMLSnippet', {
    params: {
      consultancyId,
      campaignId
    }
  });
};

export const getCandidateHiddenTalentAlertForShare = (candidateId, campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getCandidateHiddenTalentAlertForShare', {
    params: {
      candidateId,
      campaignId,
      consultancyId
    }
  });
};

export const getCandidateHTMLSnippet = (candidateId, campaignId, consultancyId) => {
  return axios.get('/TalentAlertCampaign/getCandidateHTMLSnippet', {
    params: {
      candidateId,
      campaignId,
      consultancyId
    }
  })
};

export const uploadCampaignLogoImage = (campaignId, image) => {
  return axios.post('/TalentAlertCampaign/uploadCampaignLogoImage', image, {
    params: {
      campaignId
    }
  });
};

export const getCandidateTalentAlertForTCs = (consultancyId, campaignId) => {
  return axios.get('/TalentAlertCampaign/getCandidateTalentAlertForTCs', {
    params: {
      consultancyId,
      campaignId
    }
  });
};
