import * as actionTypes from 'src/actions';

const initialState = {
  talentAlerts: [],
  campaignCandidates: [],
  previewingAsAuthenticatedContact: false,
  previewingAsUnauthenticatedUser: false,
  isTalentAlertCampaign: false,
  currentTalentAlertKCQ: []
};


const talentAlertReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_TALENT_ALERT: {
      return {
        ...state,
        campaignCandidates: [
          ...state.campaignCandidates,
          ...action.talentAlert.campaignCandidates,
        ],
        ...action.talentAlert,
      };
    }
    case actionTypes.SET_TALENT_ALERT: {
      return {
        ...state,
        campaignLogoImg: action.talentAlert.campaignLogoImg,
        ...action.talentAlert
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_CONTACTS: {
      return {
        ...state,
        campaignContacts: action.campaignContacts
          ? action.campaignContacts
          : [...state.campaignContacts],
        addableContacts: action.addableContacts
          ? action.addableContacts
          : [...state.addableContacts],
        uninvitedContacts: action.uninvitedContacts
          ? action.uninvitedContacts
          : [...state.uninvitedContacts],
      };

    }
    case actionTypes.SET_CAMPAIGN_CANDIDATES: {
      return {
        ...state,
        campaignCandidates: [...action.updatedCampaignCandidates]
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_CANDIDATES: {
      let campaignCandidates = [...state.campaignCandidates, ...action.payload.campaignCandidates];
      let uninvitedCandidates = campaignCandidates.filter((candidate) => !candidate.invited);
      return {
        ...state,
        campaignCandidates,
        availableCampaignCandidates: [
          ...action.payload.availableCampaignCandidates,
        ],
        uninvitedCandidates: uninvitedCandidates
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_UNINVITED_CANDIDATES: {
      return {
        ...state,
        uninvitedCandidates: [...action.uninvitedCandidates],
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_TCS: {
      return {
        ...state,
        ...action.termsAndConditions
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_STATUS: {
      return {
        ...state,
        status: action.status
      };
    }
    case actionTypes.ADD_TALENT_ALERT_REQUIRED_DOCUMENT: {
      return {
        ...state,
        campaignRequiredDocuments: [
          ...state.campaignRequiredDocuments,
          action.document
        ]
      };
    }
    case actionTypes.UPDATE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT: {
      let { document } = action;
      let { campaignRequiredDocuments } = state;
      let documentIndex = campaignRequiredDocuments.indexOf(
        campaignRequiredDocuments.find(
          (campaignRequiredDocument) =>
            campaignRequiredDocument.id === document.id
        )
      );
      campaignRequiredDocuments[documentIndex] = document;
      return {
        ...state,
        campaignRequiredDocuments: [...campaignRequiredDocuments]
      };
    }
    case actionTypes.REMOVE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT: {
      let { id } = action;
      let { campaignRequiredDocuments } = state;
      let campaignIndex = campaignRequiredDocuments.indexOf(
        campaignRequiredDocuments.find((document) => document.id === id)
      );
      campaignRequiredDocuments.splice(campaignIndex, 1);
      return {
        ...state,
        campaignRequiredDocuments: [...campaignRequiredDocuments]
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_MANAGER: {
      return {
        ...state,
        ...action.visbilitySettings
      };
    }
    case actionTypes.UPDATE_UNINVITED_TALENT_ALERT_CONTACTS: {
      return {
        ...state,
        uninvitedContacts: [...action.uninvitedCampaignContacts]
      };
    }
    case actionTypes.PREVIEW_TALENT_ALERT_AS_AUTHENTICATED_CONTACT: {
      return {
        ...state,
        previewingAsAuthenticatedContact: action.previewingAsAuthenticatedContact
      };
    }
    case actionTypes.PREVIEW_TALENT_ALERT_AS_UNAUTHENTICATED_USER: {
      return {
        ...state,
        previewingAsUnauthenticatedUser: action.previewingAsUnauthenticatedUser
      };
    }
    case actionTypes.SET_TALENT_ALERT_CAMPAIGN_TYPE: {
      return {
        ...state,
        isTalentAlertCampaign: action.isTalentAlertCampaign
      }
    }
    case actionTypes.SET_TALENT_ALERT_KCQ: {
      return {
        ...state,
        currentTalentAlertKCQ: action.currentTalentAlertKCQ
      }
    }
    case actionTypes.UPDATE_TALENT_ALERT_KCQS: {
      const questions = [...action.kcqs];

      return {
        ...state,
        currentTalentAlertKCQ: {
          ...state.currentTalentAlertKCQ,
          questions
        }
      }
    }
    case actionTypes.DELETE_TALENT_ALERT_KCQ: {
      return {
        ...state,
        currentTalentAlertKCQ: null
      }
    }
    case actionTypes.UPDATE_TALENT_ALERT_INTRODUCTION: {
      return {
        ...state,
        introduction: action.introduction
      };
    }
    case actionTypes.UPDATE_TALENT_ALERT_CANDIDATE: {
      const { candidate } = action.payload;
      const updatedCandidates = state.campaignCandidates.map((cc) => {
        if (cc.id === candidate.id) {
          return candidate;
        }

        return cc;
      });

      return {
        ...state,
        campaignCandidates: updatedCandidates
      };
    }
    default: {
      return state;
    }
  }
};

export default talentAlertReducer;