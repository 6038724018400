import { Card, CardContent, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import QuillEditor from "src/components/QuillEditor";
import * as ConsultancyApi from "src/api/consultancyApi";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";
//TODO:Translate this page

function EmailSignature() {
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(["common", "snackbar"]);
	const [emailSignature, setEmailSignature] = useState("");
	const [loading, setLoading] = useState(true);

	const handleEmailSignatureChange = (event) => {
		setEmailSignature(event);
	};

	const getEmailSignature = () => {
		return ConsultancyApi.getEmailSignature()
			.then((response) => {
				setEmailSignature(response.data || "");
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const updateEmailSignature = () => {
		return ConsultancyApi.updateEmailSignature(emailSignature)
			.then((response) => {
				enqueueSnackbar(t("snackbar:updateEmailSignatureSuccess"), {
					variant: "success",
				});
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:updateEmailSignatureFailure"), {
					variant: "error",
				});
				console.error(error);
			});
	};

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getEmailSignature();
		}
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {}, [emailSignature]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Card>
					<CardContent>
						<BlockHeader reduceMargin header={t("common:emailSignature")} />
						{!loading ? (
							<QuillEditor
								value={emailSignature}
								handleChange={handleEmailSignatureChange}
								toolbarOptions={["link", "image"]}
							/>
						) : null}
						<CardButton
							btnLabel={t("common:update")}
							btnFunc={updateEmailSignature}
							btnVariant="contained"
						/>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}

export default EmailSignature;
