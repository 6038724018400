import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	colors,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput,
	CardContent,
	Card,
} from "@material-ui/core";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import * as CampaignApi from "src/api/campaignApi";
import { useTranslation } from "react-i18next";
import UserSelect from "src/components/UserSelect";
import * as campaignActions from "src/actions/campaignActions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	container: {
		padding: "0px 10px",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
	},
	modalCard: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
			transform: `translate(${0}%, ${10}%)`,
		},
		[theme.breakpoints.up("md")]: {
			width: "50%",
			transform: `translate(${50}%, ${10}%)`,
		},
		"&:focus": {
			outline: "none",
		},
	},
	closeButton: {
		minWidth: "32px",
		backgroundColor: colors.red[600],
		"&:hover": {
			backgroundColor: colors.red[400],
		},
		color: theme.palette.common.white,
		textTransform: "none",
	},
	modalOverflow: {
		overflowY: "scroll",
	},
}));

const privateCampaignSchema = {
	privateCampaign: {
		presence: { allowEmpty: false, message: "is required" },
	},
};

const groupCampaignSchema = {
	privateCampaign: {
		presence: { allowEmpty: false, message: "is required" },
	},
	assignedGroups: {
		presence: { allowEmpty: false, message: "is required" },
	},
};

function CampaignVisibilitySettings() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(["common", "validation", "campaigns", "snackbar"]);
	const consultancyId = useSelector((state) => state.session.consultancyId);
	const campaign = useSelector((state) => state.campaign);
	const dispatch = useDispatch();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			privateCampaign: true,
			assignedGroups: [],
			manager: {},
			campaignGroups: [],
		},
		touched: {},
		errors: {},
	});

	const handleSubmit = (event) => {
		event.preventDefault();
		return CampaignApi.updateCampaignVisibility({
			...formState.values,
			consultancyId,
			campaignId: campaign.id,
		})
			.then((response) => {
				dispatch({
					type: campaignActions.UPDATE_CAMPAIGN_MANAGER,
					visbilitySettings: response.data,
				});
				enqueueSnackbar(`${t("snackbar:visibilitySettingsSuccess")}`, {
					variant: "success",
				});
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar(`${t("snackbar:visibilitySettingsError")}`, {
					variant: "error",
				});
			});
	};

	const handleManagerChange = (event, value) => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				manager: value,
			},
			touched: {
				...prevFormState.touched,
				manager: true,
			},
		}));
	};

	const handlePrivateChange = (event) => {
		event.persist();
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				assignedGroups: [],
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleChange = (event) => {
		event.persist();
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	useEffect(() => {
		let schema;
		switch (formState.values.privateCampaign) {
			case true:
				schema = privateCampaignSchema;
				break;
			case false:
				schema = groupCampaignSchema;
				break;
			default:
				break;
		}
		const errors = validate(formState.values, schema);
		setFormState((prevFormState) => ({
			...prevFormState,
			isValid: !errors,
			errors: errors || {},
		}));
	}, [formState.values]);

	useEffect(() => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				manager: campaign.manager,
				privateCampaign: campaign.privateCampaign,
				assignedGroups: [...campaign.assignedGroups],
				campaignGroups: [...campaign.campaignGroups],
			},
		}));
	}, [
		campaign.manager,
		campaign.privateCampaign,
		campaign.assignedGroups,
		campaign.campaignGroups,
	]);

	return (
		<Card>
			<BlockHeader
				card
				padding
				header={t("campaigns:campaignVisbilitySettings")}
			/>
			<CardContent>
				<form onSubmit={handleSubmit}>
					<div className={classes.fields}>
						<UserSelect
							value={formState.values.manager}
							handleChange={handleManagerChange}
						/>
						<FormControl
							variant="outlined"
							fullWidth
							className={classes.formControl}
						>
							<InputLabel id="accessLabel" style={{ backgroundColor: "white" }}>
								{t("campaigns:campaignVisibilitySelection")}
							</InputLabel>
							<Select
								name={"privateCampaign"}
								labelId="accessLabel"
								value={formState.values.privateCampaign}
								onChange={handlePrivateChange}
							>
								<MenuItem value={true}>
									{t("campaigns:campaignVisibilitySelectionPrivate")}
								</MenuItem>
								<MenuItem value={false}>
									{t("campaigns:campaignVisibilitySelectionGroup")}
								</MenuItem>
							</Select>
						</FormControl>
						<FormControl
							variant="outlined"
							fullWidth
							className={classes.formControl}
							disabled={formState.values.privateCampaign}
						>
							<InputLabel id="groupLabel" style={{ backgroundColor: "white" }}>
								{t("campaigns:selectGroupsLabel")}
							</InputLabel>
							<Select
								labelId="groupLabel"
								name={"assignedGroups"}
								multiple
								value={formState.values.assignedGroups}
								onChange={handleChange}
								input={<OutlinedInput />}
							>
								{formState.values.campaignGroups.map((group) => (
									<MenuItem key={group.id} value={group.id}>
										{group.groupName}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
					<div style={{ textAlign: "center" }}>
						<Button
							className={classes.submitButton}
							color="primary"
							size="large"
							type="submit"
							variant="contained"
							fullWidth
							disabled={!formState.isValid}
						>
							{t("common:update")}
						</Button>
					</div>
				</form>
			</CardContent>
		</Card>
	);
}
export default CampaignVisibilitySettings;
