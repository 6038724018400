import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalFormWrapper } from './../../../components/ModalFormWrapper';
import CreateTalentAlertForm from './CreateTalentAlertForm';

const CreateTalentAlertModal = ({ open, handleClose, onTalentAlertSuccess }) => {

  const [t] = useTranslation(['campaigns', 'common']);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const onSubmit = (formValues) => {
    onTalentAlertSuccess({
      formValues: formValues,
      consultancyId: consultancyId
    });
  }

  return (
    <ModalFormWrapper
      formTitle={t("campaigns:createTalentAlert")}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth={"sm"} >
      <CreateTalentAlertForm onSubmit={onSubmit} />
    </ModalFormWrapper>
  );

};

export default CreateTalentAlertModal;
