/* eslint-disable no-unused-vars */
import React, { useState, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";
import {
  AppBar,
  Badge,
  Box,
  ClickAwayListener,
  Collapse,
  Hidden,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import LogOutIcon from "@material-ui/icons/ExitToApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { CANDIDATE, CONTACT } from "src/constants/roles";
import { useTranslation } from "react-i18next";

import {
  logoutAndDeleteSession,
  TOGGLE_NOTIFICATION_DRAWER,
} from "../../actions";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";

import * as SearchApi from "src/api/searchApi";
import SearchResultDropdown from "src/components/SearchResultDropdown";
import LanguageDropdown from "../../components/LanguageDropdown";

import logo from "src/assets/images/logos/i-intro_logo_light.png";
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: theme.palette.nav.main,
  },
  flexGrow: {
    flexGrow: 1,
  },
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(3),
    fontSize: "1.4rem",
  },
  badge: {
    fontSize: ".9rem",
  },
  popover: {
    width: 200,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25),
    },
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  searchDropdown: {
    width: "100%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: "100%",
  },
  topBarLogo: {
    maxHeight: "64px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  linkSpacer: {
    transition: "0.15s all ease",
    marginRight: 0,
  },
  expandIcon: {
    fontSize: "1.4rem",
  },
  expandedMenu: {
    width: "100vw",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
}));

function MobileTopBar({ onOpenNavBarMobile, openDesktop, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const ref = useRef(null);
  const input = useRef(null);
  const search = useRef(null);
  const dispatch = useDispatch();
  const session = useSelector((state) => state.session);

  const [t] = useTranslation(["common"]);

  const notificationsCount = useSelector((state) => state.notifications.notificationsCount);

  const iconColour =
    theme.palette.getContrastText(theme.palette.nav.main) ||
    theme.palette.getContrastText(theme.palette.primary.main);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const [searchResults, setSearchResults] = useState({});
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [isSearchActive, setSearchActive] = useState(false);
  const [expandMenu, setExpandMenu] = useState(false);

  const [formState, setFormState] = useState({
    values: {
      search: "",
    },
  });

  const offsetTop = search?.current?.offsetTop;
  const offsetLeft = search?.current?.offsetLeft;

  const handleLogout = async () => {
    try {
      window.location.href = "/";
      await logoutAndDeleteSession(dispatch);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClick = (event) => {
    if (event.result._index === "campaigns") {
      history.push(`/dashboard/campaigns/${event.result._id}`);
      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: "",
        },
      }));
    } else if (event.result._index === "employers") {
      history.push(`/dashboard/employers/${event.result._id}`);
      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: "",
        },
      }));
    } else if (event.result._index === "contacts") {
      history.push(
        `/dashboard/employers/${event.result._source.employerId}/contacts/${event.result._id}`
      );
      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: "",
        },
      }));
    } else if (event.result._index === "talentalerts") {
      history.push(`/dashboard/talent-alert/${event.result._id}`);
      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: "",
        },
      }));
    } else if (event.result._index === "candidates") {
      history.push(`/dashboard/candidates/${event.result._id}`);
      setSearchActive(false);
      setFormState((prevValues) => ({
        ...prevValues,
        values: {
          ...prevValues.values,
          search: "",
        },
      }));
    }
  };

  const toggleNotificationDrawer = () => {
    dispatch({ type: TOGGLE_NOTIFICATION_DRAWER });
  };

  const handleSearch = (e) => {
    e.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        [e.target.name]: e.target.value,
      },
    }));

    let timer = setTimeout(() => {}, 1);

    const makeCall = () => {
      SearchApi.search(consultancyId, e.target.value)
        .then((result) => {
          setSearchResults(result);
          setSearchActive(true);
        })
        .then(() => {
          input.current.focus();
        });
    };

    const callIfPaused = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        makeCall();
      }, 400);
    };

    if (e.target.value !== "") {
      callIfPaused();
    }
  };

  const handleSearchClose = () => {
    setSearchActive(false);
  };

  const getLogo = () => {
    if (session.consultancySecondaryLogo) {
      return session.consultancySecondaryLogo;
    }

    if (session.consultancyLogo) {
      return session.consultancyLogo;
    }

    switch (session.consultancySubscriptionPlanId) {
      case subscriptionPlansIds.TA:
        return taLogo;
      case subscriptionPlansIds.TA_PRO:
        return taProLogo;
      default:
        return logo;
    }
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        {
          session.roleType !== CANDIDATE && session.roleType !== CONTACT && (
            <Hidden lgUp>
              <IconButton
                className={classes.menuButton}
                onClick={onOpenNavBarMobile}
                style={{ color: iconColour }}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )
        }

        <div
          className={classes.linkSpacer}
          style={!openDesktop ? { marginRight: 56 } : null}
        />
        <RouterLink to="/dashboard" edge="start">
          <img
            alt="Logo"
            className={classes.topBarLogo}
            src={ getLogo() }
          />
        </RouterLink>

        <div className={classes.flexGrow} />
        {/* <Box
					display="flex"
					alignItems="center"
					component={ButtonBase}
					ref={search}
					style={{ marginRight: theme.spacing(3) }}
				>
					<div className={classes.search}>
						<form>
							<div className={classes.searchIcon}>
								<SearchIcon />
							</div>
							<InputBase
								ref={input}
								placeholder="Search…"
								name="search"
								autoComplete="off"
								onChange={(e) => handleSearch(e)}
								classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
								}}
								inputProps={{ "aria-label": "search" }}
							/>
						</form>
					</div>
				</Box> */}
        <IconButton
          id="2"
          className={classes.menuButton}
          onClick={toggleNotificationDrawer}
          style={{ color: iconColour }}
        >
          <Badge
            badgeContent={notificationsCount}
            classes={{ badge: classes.badge }}
            color="secondary"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.expandIcon}
          onClick={
            expandMenu
              ? () => {
                  setExpandMenu(false);
                  setShowSearchInput(false);
                }
              : () => setExpandMenu(true)
          }
          style={{ color: iconColour }}
        >
          {expandMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Toolbar>
      <Collapse in={expandMenu} timeout="auto">
        <Box className={classes.expandedMenu}>
          {session.roleType !== CONTACT && (
            <IconButton
              className={classes.menuButton}
              onClick={() => setShowSearchInput(true)}
              style={{ color: iconColour }}
              ref={search}
            >
              <SearchIcon />
            </IconButton>
          )}
          <LanguageDropdown />
          <IconButton
            onClick={handleLogout}
            style={{ color: iconColour, fontSize: "1.4rem" }}
          >
            <LogOutIcon />
          </IconButton>
        </Box>
      </Collapse>
      {session.roleType !== CONTACT && (
        <Collapse in={showSearchInput} timeout="auto">
          <Box className={classes.expandedMenu}>
            <div className={classes.search}>
              <form>
                <InputBase
                  ref={input}
                  placeholder={t("common:search") + "..."}
                  name="search"
                  autoComplete="off"
                  onChange={(e) => handleSearch(e)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </form>
            </div>
          </Box>
        </Collapse>
      )}

      {isSearchActive && formState.values.search && (
        <ClickAwayListener onClickAway={handleSearchClose}>
          <div
            style={{
              position: "relative",
              zIndex: 10000,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: offsetTop - 60,
                zIndex: 10000,
                boxShadow: "-1px 5px 5px 5px rgba(0,0,0,0.5)",
              }}
            >
              <SearchResultDropdown
                searchResults={searchResults}
                handleClick={handleClick}
              />
            </div>
          </div>
        </ClickAwayListener>
      )}
    </AppBar>
  );
}

MobileTopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default MobileTopBar;
