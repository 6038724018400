import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";
import validate from "validate.js";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import CountrySelect from "src/components/CountrySelect";
import * as CandidateApi from "src/api/candidateApi";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { DEFAULT_CANDIDATE_PROFILE } from "../../actions/candidateActions";
import { UPDATE_PROFILE_PICTURE } from "src/actions/sessionActions";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

//TODO:Translate this page

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  consultancyLogo: {
    height: "50px",
    width: "auto",
  },
  inputRow: {
    alignItems: "center",
  },
  headerRow: {
    textAlign: "right",
    padding: "0px 12px",
  },
}));

export default function() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation([
    "common",
    "validation",
    "snackbar",
    "labels",
    "tooltips",
  ]);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const schema = {
    firstName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    telephone: {
      length: {
        maximum: 20,
      },
    },
    addressLine1: {},
    city: {
      length: {
        maximum: 32,
      },
    },
    postalCode: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const getProfile = () => {
    return CandidateApi.getDefaultCandidateProfile(consultancyId)
      .then((response) => {
        setFormState((prevValues) => ({
          ...prevValues,
          values: {
            ...response.data,
          },
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const dispatch = useDispatch();

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };

  const handleUploadDefProfilePicture = (value) => {
    return CandidateApi.uploadDefaultProfilePicture(
      consultancyId,
      formState.values.candidateId,
      value
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("common:profilePictureUpdated"), {
            variant: "success",
          });
          setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
              ...prevFormState.values,
              candidateProfilePicture: response.data,
            },
          }));
          dispatch({
            type: UPDATE_PROFILE_PICTURE,
            profilePicture: response.data,
          });
        } else {
          throw Error("Failed to update logo");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("common:profilePictureUpdatedError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const saveDetails = (event) => {
    event.preventDefault();
    return CandidateApi.updateDefaultCandidateProfile(
      formState.values,
      consultancyId
    )
      .then((response) => {
        dispatch({
          type: DEFAULT_CANDIDATE_PROFILE,
          defaultCandidateProfile: response.data,
        });
        enqueueSnackbar(t("snackbar:updateDefaultProfile"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:failedToUpdateDefaultProfile"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <BlockHeader card header={t("labels:manageAvatar")} />
                <Grid container spacing={3}>
                  <Grid item xs={12} style={{ textAlign: "left" }}>
                    <CustomFileDropzone
                      type="single"
                      format="image"
                      uploadFunction={handleUploadDefProfilePicture}
                      placeHolderImage={
                        formState.values.profilePicture ||
                        "https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg"
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <BlockHeader
                card
                padding
                headerTooltip={t(`tooltips:manageDetails`)}
                header={t("labels:manageDetails")}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:salutationLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="salutation"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("salutation")}
                          value={formState.values.salutation || ""}
                          helperText={
                            hasError("salutation")
                              ? t(
                                  `validation:${formState.errors.salutation[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:firstNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="firstName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("firstName")}
                          value={formState.values.firstName || ""}
                          helperText={
                            hasError("firstName")
                              ? t(`validation:${formState.errors.firstName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:lastNameLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="lastName"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("lastName")}
                          value={formState.values.lastName || ""}
                          helperText={
                            hasError("lastName")
                              ? t(`validation:${formState.errors.lastName[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:telephoneLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="telephone"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("telephone")}
                          value={formState.values.telephone || ""}
                          helperText={
                            hasError("telephone")
                              ? t(`validation:${formState.errors.telephone[0]}`)
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:addressLabel`)} 1
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine1"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine1")}
                          value={formState.values.addressLine1 || ""}
                          helperText={
                            hasError("addressLine1")
                              ? t(
                                  `validation:${formState.errors.addressLine1[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:addressLabel`)} 2
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <TextField
                          fullWidth
                          name="addressLine2"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("addressLine2")}
                          value={formState.values.addressLine2 || ""}
                          helperText={
                            hasError("addressLine2")
                              ? t(
                                  `validation:${formState.errors.addressLine2[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">{t(`common:City`)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          fullWidth
                          name="city"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("city")}
                          value={formState.values.city || ""}
                          helperText={
                            hasError("city")
                              ? t(`validation:${formState.errors.city[0]}`)
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Typography variant="h5">
                          {t(`common:postcodeLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <TextField
                          fullWidth
                          name="postalCode"
                          variant="outlined"
                          onChange={handleChange}
                          error={hasError("postalCode")}
                          value={formState.values.postalCode || ""}
                          helperText={
                            hasError("postalCode")
                              ? t(
                                  `validation:${formState.errors.postalCode[0]}`
                                )
                              : null
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} className={classes.inputRow}>
                      <Grid item xs={12} md={3}>
                        <Typography variant="h5">
                          {t(`common:countryLabel`)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={9}>
                        <CountrySelect
                          handleCountryChange={handleCountryChange}
                          value={formState.values.country}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <CardButton
                  btnFunc={saveDetails}
                  btnDisabled={!formState.isValid}
                  btnLabel={t("common:save")}
                  btnVariant="contained"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
