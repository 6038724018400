import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  box: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E4EBF1'
  },
  typography: {
    color: theme.palette.button.main,
    fontSize: '16px',
    fontWeight: '600'
  }
}));

const UnsupportedView = () => {

  const classes = useStyles();

  const [t] = useTranslation(['cloudinary']);

  return (
    <>
      <Box className={ classes.box } >
        <Typography className={ classes.typography } >
          { t("cloudinary:cloudinaryRecordNotificationBrowserError") }
        </Typography>
      </Box>
    </>
  );

};

export default UnsupportedView;
