import {
  Box,
  TextField,
  Dialog,
  Grid,
  Typography,
  Card,
  CardContent,
  FormControlLabel,
  FormControl,
  OutlinedInput,
  InputAdornment,
  CircularProgress,
  useTheme,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import * as ConsultancyApi from "src/api/consultancyApi";
import EmailTemplate from "./EmailTemplate";
import { useTranslation } from "react-i18next";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import QuillEditor from "src/components/QuillEditor";
import { SketchPicker } from "react-color";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import Swal from "sweetalert2";

import * as subscriptionPlansIds from './../../../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  emailExpansionPanel: {
    margin: "20px 0px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  emailSettingsCard: {
    height: "100%",
  },
  updateButton: {
    marginTop: "10px",
  },
  dnsTip: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: "5px",
    padding: "10px",
    marginBottom: "10px",
  },
  tipText: {
    color: theme.palette.primary.contrastText,
  },
  changeDomain: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const EmailSettings = ({ consultancyId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation([
    "sysadmin",
    "common",
    "snackbar",
    "languages",
    "emails",
    "settings",
    "tooltips",
  ]);
  const [emailImage, setEmailImage] = useState("");
  const [emailSettings, setEmailSettings] = useState();
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [emailSignature, setEmailSignature] = useState("");
  const [newEmailDomain, setNewEmailDomain] = useState("");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const role = useSelector((state) => state.session.roleType);

  const isConsultancyWithFullSubscriptionPlan = useSelector((state) => {
    if (state.session.consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
      return true;
    }

    return false;
  });

  const isSysAdmin = role === "CoreAdmin" || role === "SystemAdmin";

  const handleEmailSignatureChange = (event) => {
    setEmailSignature(event);
  };

  const handleVerifiedChange = () => {
    setVerified((prevValue) => !prevValue);
  };

  const updateEmailSignature = () => {
    return ConsultancyApi.updateConsultancyEmailSignature(
      consultancyId,
      emailSignature
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:updateEmailSignatureSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:updateEmailSignatureFailure"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const handleEmailChange = (event) => {
    event.persist();
    setEmailSettings((prevValues) => ({
      ...prevValues,
      outboundEmail: event.target.value,
    }));
  };

  const handleInboundEmailChange = (event) => {
    event.persist();
    setEmailSettings((prevValues) => ({
      ...prevValues,
      inboundEmail: event.target.value,
    }));
  };

  const handleDomainChange = (event) => {
    event.persist();

    setEmailSettings((prevValues) => ({
      ...prevValues,
      emailDomainName: event.target.value,
    }));
  };

  const handleDisplayColorPicker = (props) => {
    setShowColorPicker(!showColorPicker);
  };

  const handleChangeColor = ({ hex }) => {
    setEmailSettings((prevValues) => ({
      ...prevValues,
      emailPrimaryColour: hex,
    }));
  };

  const getConsultancyEmailTemplates = () => {
    return ConsultancyApi.getConsultancyEmailTemplates(consultancyId)
      .then((response) => {
        let tempEmailTemplates = response.data.emailTemplates?.map(
          (emailTemplate) => {
            return {
              ...emailTemplate,
              currentTab: 1,
              emailTemplateLocale: emailTemplate.emailTemplateLocale.map(
                (emailTemplateLocale) => {
                  return {
                    ...emailTemplateLocale,
                    isValid: true,
                    formattedEmailContent: emailTemplateLocale.contentHTML,
                    formattedSubject: emailTemplateLocale.subjectHTML,
                  };
                }
              ),
            };
          }
        );
        setEmailImage(response.data.emailImage);
        setEmailSettings(response.data.emailSettings);
        setNewEmailDomain(response.data.emailSettings.emailDomain);
        setEmailSignature(response.data.emailSettings.consultancySignature);
        setEmailTemplates([...tempEmailTemplates]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEmailImageUpload = (value) => {
    return ConsultancyApi.uploadEmailImage(consultancyId, value)
      .then((response) => {
        enqueueSnackbar(t("snackbar:emailLogoUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:emailLogoUpdateFailure"), {
          variant: "error",
        });
      });
  };

  const updateEmailPrimaryColour = () => {
    return ConsultancyApi.updateEmailColour(
      consultancyId,
      emailSettings.emailPrimaryColour
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:emailColourUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:emailColourUpdateFailure"), {
          variant: "error",
        });
      });
  };

  const updateOutboundEmail = () => {
    return ConsultancyApi.updateOutboundEmail(
      consultancyId,
      emailSettings.outboundEmail
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:emailOutboundAddressUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:emailOutboundAddressUpdateFailure"), {
          variant: "error",
        });
      });
  };

  const updateInboundEmail = () => {
    return ConsultancyApi.updateInboundEmail(
      consultancyId,
      emailSettings.inboundEmail
    )
      .then((response) => {
        enqueueSnackbar(t("snackbar:emailInboundAddressUpdateSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:emailInboundAddressUpdateFailure"), {
          variant: "error",
        });
      });
  };

  const updateEmailDomainName = (event) => {
    setLoading(true);
    return ConsultancyApi.updateEmailDomainName(
      consultancyId,
      emailSettings.emailDomainName
    )
      .then((response) => {
        setEmailSettings(response.data);
        enqueueSnackbar(t("snackbar:emailDomainChangeSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:emailDomainChangeFailure"), {
          variant: "error",
        });
      })
      .then(() => {
        setLoading(false);
      });
  };

  const verifyDNSRecords = (event) => {
    setLoading(true);
    return ConsultancyApi.verifyDnsRecords(consultancyId)
      .then((response) => {
        setEmailSettings(response.data);
        enqueueSnackbar(t("snackbar:verifyDomainSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:verifyDomainFailure"), {
          variant: "error",
        });
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleDomainNameChange = (event) => {
    event.preventDefault();
    setEmailSettings((prevValues) => ({
      ...prevValues,
      domainNameStatus: 4,
    }));
  };

  const handleNewEmailDomainChange = (event) => {
    event.persist();
    setNewEmailDomain(event.target.value);
  };

  const changeEmailDomain = (event) => {
    event.preventDefault();
    setLoading(true);
    return ConsultancyApi.changeEmailDomain(consultancyId, newEmailDomain)
      .then((response) => {
        setEmailSettings(response.data);
        enqueueSnackbar(t("snackbar:emailDomainChangeSuccess"), {
          variant: "success",
        });
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("snackbar:emailDomainChangeFailure"), {
          variant: "error",
        });
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleResetDefault = () => {
    Swal.fire({
      title: t("common:resetDefault"),
      showCancelButton: true,
      cancelButtonText: t("common:cancel"),
      confirmButtonText: t("common:ok"),
      text: `${t("common:resetDefaultText")}`,
      confirmButtonColor:
        theme.palette.button.main || theme.palette.primary.main,
    }).then((result) => {
      if (result.isConfirmed) {
        ConsultancyApi.handleResetDefault(consultancyId)
          .then((response) => {
            if (response.status === 200) {
              getConsultancyEmailTemplates();
              enqueueSnackbar(t("snackbar:resetEmailSettings"), {
                variant: "success",
              });
            }
            window.scrollTo(0, 0);
          })
          .catch((error) => {
            enqueueSnackbar(t("snackbar:resetEmailSettingsFailure"), {
              variant: "error",
            });
            console.error(error);
          });
      }
    });
  };

  useEffect(() => {
    getConsultancyEmailTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!emailSettings ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Grid container spacing={3}>
            {
              isConsultancyWithFullSubscriptionPlan && isSysAdmin && (
                <Grid item xs={12} md={12}>
                  <Card className={classes.emailSettingsCard}>
                    <BlockHeader
                      card
                      padding
                      header={t("settings:customEmailDomain")}
                    />
                    <CardContent>
                      {emailSettings.domainNameStatus === 1 ||
                      emailSettings.domainNameStatus === 2 ? (
                        <>
                          <Typography variant="body1">
                            {t("settings:defaultEmailSetTo")}
                            <b>
                              no-reply@{ emailSettings.defaultEmailDomain }
                            </b>
                          </Typography>
                          <br />
                          <Typography variant="body1">
                            {t("settings:useExistingDomain")}
                          </Typography>
                          <br />
                        </>
                      ) : emailSettings.domainNameStatus === 3 ||
                        emailSettings.domainNameStatus === 4 ? (
                        <>
                          <Typography variant="body1">
                            {t("settings:succesfullyAuthenticated")}
                          </Typography>
                          <br />
    
                          <Typography variant="body1">
                            {t("settings:defaultEmailSetTo")}
                            <div onClick={handleDomainNameChange}>
                              <b>{t("settings:clickChangeDomain")}</b>
                            </div>
                          </Typography>
                          <br />
                        </>
                      ) : null}
                      {emailSettings.domainNameStatus === 1 ? (
                        <>
                          {loading ? (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              <Typography variant="body1">
                                {t("settings:domainToAuth")}
                              </Typography>
                              <TextField
                                fullWidth
                                variant="outlined"
                                placeholder={ emailSettings.defaultEmailDomain }
                                onChange={handleDomainChange}
                                value={emailSettings.emailDomainName || ""}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      style={{ width: "8%" }}
                                    >
                                      no-reply@
                                    </InputAdornment>
                                  ),
                                }}
                              />
    
                              <CardButton
                                btnLabel={t("settings:auth")}
                                btnFunc={updateEmailDomainName}
                                btnVariant="contained"
                              />
                            </>
                          )}
                        </>
                      ) : emailSettings.domainNameStatus === 2 &&
                        emailSettings.emailDNSRecords ? (
                        <>
                          {loading ? (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              <Typography variant="body1">
                                {t("settings:installDNS")}
                              </Typography>
    
                              {Object.values(emailSettings.emailDNSRecords).map(
                                (emailDNSRecord) => (
                                  <div style={{ marginTop: "10px" }}>
                                    <Typography variant="body2">
                                      <b>{t("settings:type")} </b>{" "}
                                      {emailDNSRecord.type}
                                    </Typography>
                                    <Typography variant="body2">
                                      <b>{t("settings:type")} </b>{" "}
                                      {emailDNSRecord.host}
                                    </Typography>
                                    <Typography variant="body2">
                                      <b>{t("settings:value")}</b>{" "}
                                      {emailDNSRecord.data}
                                    </Typography>
                                  </div>
                                )
                              )}
                              <br />
                              <div className={classes.dnsTip}>
                                <Typography
                                  variant="body2"
                                  className={classes.tipText}
                                >
                                  <b>Tip:</b>
                                </Typography>
                                <Typography
                                  variant="body1"
                                  className={classes.tipText}
                                >
                                  {t("settings:dnsInfo")} <br />
                                  {t("settings:dnsInfo2")} <br />
                                  em123, s1._domainkey, s2._domainkey
                                </Typography>
                              </div>
    
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={verified}
                                    onChange={handleVerifiedChange}
                                  />
                                }
                                label={t("settings:confirmVerify")}
                              />
                              <CardButton
                                btnLabel={t("settings:verify")}
                                btnFunc={verifyDNSRecords}
                                btnDisabled={!verified}
                                btnVariant="contained"
                              />
                              <CardButton
                                btnLabel={t("settings:resetEmailDefault")}
                                btnFunc={handleResetDefault}
                                btnVariant="contained"
                              />
                            </>
                          )}
                        </>
                      ) : emailSettings.domainNameStatus === 4 ? (
                        <>
                          {loading ? (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <>
                              <Typography variant="body1">
                                {t("settings:whichChangeDomain")}
                              </Typography>
                              <TextField
                                fullWidth
                                variant="outlined"
                                placeholder={ emailSettings.defaultEmailDomain }
                                onChange={handleNewEmailDomainChange}
                                value={newEmailDomain || ""}
                              />
                              <CardButton
                                btnFunc={changeEmailDomain}
                                btnLabel={t("settings:confirm")}
                                btnVariant="contained"
                              />
                            </>
                          )}
                        </>
                      ) : null}
                    </CardContent>
                  </Card>
                </Grid>
              )
            }

            <Grid item xs={12} md={6}>
              <Card className={classes.emailSettingsCard}>
                <BlockHeader
                  headerTooltip={t("tooltips:setEmailLogo")}
                  card
                  padding
                  header={t("emails:emailLogo")}
                />
                <CardContent>
                  <CustomFileDropzone
                    type="single"
                    format="image"
                    actionText={"Upload Email Image"}
                    uploadFunction={handleEmailImageUpload}
                    placeHolderImage={emailImage}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card className={classes.emailSettingsCard}>
                <BlockHeader
                  headerTooltip={t("tooltips:setEmailPrimaryColour")}
                  card
                  padding
                  header={t("emails:emailPrimaryColour")}
                />
                <CardContent>
                  <Box
                    className={classes.clickableContainer}
                    onClick={handleDisplayColorPicker}
                  >
                    <Box
                      display="inline-block"
                      height="50px"
                      width="100%"
                      bgcolor={emailSettings.emailPrimaryColour}
                    ></Box>
                  </Box>
                  <CardButton
                    btnFunc={updateEmailPrimaryColour}
                    btnLabel={t("common:update")}
                    btnVariant="contained"
                  />
                </CardContent>
              </Card>
            </Grid>

            {
              isConsultancyWithFullSubscriptionPlan && (
                <>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Card className={classes.emailSettingsCard}>
                          <BlockHeader
                            headerTooltip={t("tooltips:setInboundEmailAddress")}
                            card
                            padding
                            header={t("emails:emailInboundAddress")}
                          />
                          <CardContent>
                            <TextField
                              fullWidth
                              variant="outlined"
                              onChange={handleInboundEmailChange}
                              value={emailSettings.inboundEmail}
                            />
                            <CardButton
                              btnFunc={updateInboundEmail}
                              btnLabel={t("common:update")}
                              btnVariant="contained"
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Card className={classes.emailSettingsCard}>
                      <BlockHeader
                        headerTooltip={t("tooltips:setOutboundEmailAddress")}
                        card
                        padding
                        header={t("emails:emailOutboundAddress")}
                      />
                      <CardContent>
                        <FormControl variant="outlined" fullWidth>
                          <OutlinedInput
                            fullWidth
                            value={emailSettings.outboundEmail}
                            onChange={handleEmailChange}
                            endAdornment={
                              <InputAdornment position="end" style={{ width: "60%" }}>
                                {emailSettings.domainNameStatus === 3 &&
                                emailSettings.domainNameVerified
                                  ? `@${emailSettings.emailDomain}`
                                  : `@${emailSettings.defaultEmailDomain}`}
                              </InputAdornment>
                            }
                            disabled={
                              emailSettings.domainNameStatus !== 3 &&
                              !emailSettings.domainNameVerified
                            }
                          />
                        </FormControl>
                        <CardButton
                          btnLabel={t("common:update")}
                          btnFunc={updateOutboundEmail}
                          btnDisabled={
                            emailSettings.domainNameStatus !== 3 &&
                            !emailSettings.domainNameVerified
                          }
                          btnVariant="contained"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )
            }

            <Grid item xs={12}>
              <Card>
                <BlockHeader
                  headerTooltip={t("tooltips:setEmailSignature")}
                  card
                  padding
                  header={t("common:emailSignature")}
                />
                <CardContent>
                  {emailSignature ? (
                    <QuillEditor
                      value={emailSignature || ""}
                      handleChange={handleEmailSignatureChange}
                      toolbarOptions={["link"]}
                    />
                  ) : null}
                  <CardButton
                    btnLabel={t("common:update")}
                    btnFunc={updateEmailSignature}
                    btnVariant="contained"
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} style={{ margin: "40px 0px" }}>
              <Card>
                <BlockHeader
                  headerTooltip={t("tooltips:editEmailTemplates")}
                  card
                  padding
                  header={t("emails:emailTemplates")}
                />
                <CardContent>
                  {emailTemplates?.map((emailTemplate, emailTemplateIndex) => (
                    <EmailTemplate
                      consultancyId={consultancyId}
                      emailTemplate={emailTemplate}
                    />
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            open={showColorPicker}
            onClose={() => {
              setShowColorPicker(false);
            }}
          >
            <SketchPicker
              color={emailSettings.emailPrimaryColour}
              onChange={handleChangeColor}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default EmailSettings;
