import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as videoAssessmentApi from './../../../api/videoAssessmentApi';

import { BlockHeader } from '../../LayoutComponents/BlockHeader';

import SetupVideoAssessmentModal from './SetupVideoAssessmentModal';
import EditVideoAssessmentModal from './EditVideoAssessmentModal';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 227
  }
}));

const VideoAssessmentSetup = () => {

  const classes = useStyles();
  const [t] = useTranslation(['launchpad', 'tooltips']);

  const campaign = useSelector((state) => state.campaign);

  const [open, setOpen] = useState(false);
  const [videoAssessment, setVideoAssessment] = useState();

  useEffect(() => {
    videoAssessmentApi.getVideoAssessmentByCampaignId(campaign.id)
      .then((response) => {
        setVideoAssessment(response.data);
      });
  }, [campaign]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const launchpadSuccess = () => {};

  return (
    <>
      <Card className={classes.root}>
        <BlockHeader
          headerTooltip={t("tooltips:setUpAndConfigureVideoAssessment")}
          card
          padding
          header={t("launchpad:candidateVideoAssessment")} />

        <CardContent>
          <Button
            color="primary"
            size="large"
            onClick={handleOpen}
            variant="contained"
            fullWidth >
            {t("launchpad:configure")}
          </Button>
        </CardContent>

        {
          videoAssessment ? (
            <>
              <EditVideoAssessmentModal
                videoAssessment={ videoAssessment }
                onSuccess={launchpadSuccess}
                open={open}
                onClose={handleClose} />
            </>
          ) : (
            <>
              <SetupVideoAssessmentModal
                onSuccess={launchpadSuccess}
                open={open}
                onClose={handleClose} />
            </>
          )
        }
      </Card>
    </>
  );
};

export default VideoAssessmentSetup;
