import React from 'react';
import { useHistory } from 'react-router-dom';

import { useFormik } from 'formik';

import { makeStyles, useTheme } from '@material-ui/styles';
import { Button, CardContent, Card, Grid } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';

import * as Yup from 'yup';

import * as talentAlertApi from './../../../api/talentAlertApi';

import { UPDATE_TALENT_ALERT_MANAGER } from './../../../actions/talentAlertActions';

import UserSelect from './../../../components/UserSelect';
import { BlockHeader } from './../../../components/LayoutComponents/BlockHeader';

const useStyles = makeStyles((theme) => ({
	fields: {
		margin: theme.spacing(-1),
		display: 'flex',
		flexWrap: 'wrap',
		'& > *': {
			flexGrow: 1,
			margin: theme.spacing(1)
		}
	},
	submitButton: {
		marginTop: theme.spacing(2)
	}
}));

const TalentAlertVisibilitySettings = ({ campaignId }) => {

	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(['common', 'validation', 'campaigns', 'snackbar']);
	const dispatch = useDispatch();
  const theme = useTheme();
  const history = useHistory();

	const consultancyId = useSelector((state) => state.session.consultancyId);
	const talentAlert = useSelector((state) => state.talentAlert);

  const schema = Yup.object({
		manager: Yup.string().required(t('newTranslations:talentAlertManagerIsRequired'))
	});

  const formik = useFormik({
		initialValues: {
			manager: talentAlert.manager
		},
		validateOnMount: true,
		validationSchema: schema,
		onSubmit: (values) => {
      const manager = values.manager;

      const campaignVisibilityData = {
        manager,
        consultancyId,
        campaignId: talentAlert.id
      };
  
      talentAlertApi.updateTalentAlertCampaignVisibility({ campaignVisibilityData })
        .then((response) => {
          dispatch({
            type: UPDATE_TALENT_ALERT_MANAGER,
            visbilitySettings: response.data
          });
  
          enqueueSnackbar(`${t('snackbar:visibilitySettingsSuccess')}`, { variant: 'success' });
        })
        .catch((error) => {
          console.log(error);
  
          enqueueSnackbar(`${t('snackbar:visibilitySettingsError')}`, { variant: 'error' });
        });
		}
	});

  const userSelectChangeHandler = (event, value) => {
    formik.setValues({
      ...formik.values,
      manager: value
    });
  };

  const deleteCampaign = async () => {
    try {
      const fireOptions = {
        title: `${t('common:areYouSure')}`,
        text: `${t('campaigns:deleteConfirmation')}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `${t('common:yes')}`,
        cancelButtonText: `${t('common:no')}`,
        confirmButtonColor: theme.palette.warning?.main ? theme.palette.warning.main : theme.palette.primary.main
      };

      const fireResponse = await Swal.fire(fireOptions);

      if (!fireResponse.isConfirmed) {
        return;
      }

      await talentAlertApi.deleteTalentAlertCampaign(campaignId, consultancyId);

      enqueueSnackbar(t('snackbar:campaignDeleteSuccess'), { variant: 'success' });
      history.push('/dashboard/talent-alerts');
    } catch (error) {
      console.log(error);
      enqueueSnackbar(t('snackbar:campaignDeleteFailure'), { variant: 'error' });
    }
  };

	return (
    <>
      <Grid container md={ 4 } spacing={ 3 } >
        <Grid item xs={ 12 } >
          <Card>
            <BlockHeader
              card
              padding
              header={t("newTranslations:talentAlertVisibilitySettings")} />
            <CardContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid className={classes.fields}>
                  <UserSelect
                    value={formik.values.manager}
                    handleChange={userSelectChangeHandler} />
                </Grid>

                <Grid>
                  <Button
                    className={classes.submitButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!schema.isValid} >
                    { t("common:update") }
                  </Button>
                </Grid>
              </form>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={ 12 } >
          <Card>
            <BlockHeader card padding defeatMarginBottom header={ t("campaigns:deleteCampaign") } />

            <CardContent>
              <Button
                className={ classes.submitButton }
                color="secondary"
                size="large"
                variant="contained"
                fullWidth
                onClick={ deleteCampaign } >
                { t("common:delete") }
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
	);
  
};

export default TalentAlertVisibilitySettings;
