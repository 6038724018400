import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListSubheader,
  Zoom,
} from "@material-ui/core";
import { FwdBackButtons } from "src/components/LayoutComponents/FwdBackButtons";
import { useTranslation } from "react-i18next";
import { Searchbar, useSearch } from "src/components/Filter/Search";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "50vh",
    overflowY: "auto",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
}));

function CandidateSelection({ handleCandidateChange, setCurrentTab }) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [showSearchButton, setShowSearchButton] = useState(true);
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  const campaign = useSelector((state) => state.campaign);
  const [t] = useTranslation(["campaigns", "common"]);

  const handleEnableSearch = () => {
    setShowSearchInput(true);
    setShowSearchButton(false);
    setTimeout(() => {
      setShowCloseButton(true);
    }, 250);
  };

  const handleCloseSearch = () => {
    setShowSearchInput(false);
    setShowCloseButton(false);
    setTimeout(() => {
      setShowSearchButton(true);
    }, 250);
  };

  const handleSearchInput = (newValue) => {
    setSearchTerm(newValue);
  };

  let filteredResults = useSearch(campaign.campaignCandidates, searchTerm, [
    "firstName",
    "lastName",
  ]).searchResults();

  const handleToggle = (candidate) => () => {
    const currentIndex = checked.indexOf(candidate);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(candidate);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    const selectedIds = newChecked
      .map((e) => e.id)
      .filter((x) => {
        return x != null;
      });

    setSelectedCandidates(Object.values(selectedIds));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCandidateChange(selectedCandidates);
    setCurrentTab(1);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <List
            subheader={
              <div style={{ display: "inline", alignItems: "center" }}>
                <ListSubheader>
                  {t("campaigns:selectCandidates")}
                  <span>
                    <Zoom in={showSearchButton} mountOnEnter unmountOnExit>
                      <Button
                        style={{
                          float: "right",
                          marginTop: 6,
                        }}
                        color="primary"
                        startIcon={<SearchIcon />}
                        onClick={handleEnableSearch}
                      >
                        {t("common:search")}
                      </Button>
                    </Zoom>
                    <Zoom in={showCloseButton} mountOnEnter unmountOnExit>
                      <Button
                        style={{
                          float: "right",
                          marginTop: 6,
                        }}
                        color="primary"
                        startIcon={<CloseIcon />}
                        onClick={handleCloseSearch}
                      >
                        {t("common:close")}
                      </Button>
                    </Zoom>
                  </span>
                </ListSubheader>
              </div>
            }
          >
            <Collapse in={showSearchInput}>
              <Searchbar onChange={handleSearchInput} />
            </Collapse>
            {filteredResults
              ?.sort((a, b) => {
                let nameA =
                  `${a?.lastName?.toUpperCase()} ${a?.firstName?.toUpperCase()}` ||
                  "";
                let nameB =
                  `${b?.lastName?.toUpperCase()} ${b?.firstName?.toUpperCase()}` ||
                  "";

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }

                return 0;
              })
              ?.map((candidate) => {
                const labelId = `checkbox-list-label-${candidate.id}`;

                return (
                  <ListItem
                    key={candidate}
                    dense
                    button
                    onClick={handleToggle(candidate)}
                  >
                    <ListItemAvatar>
                      <Avatar className={classes.avatar} src={candidate.avatar}>
                        {candidate.avatar
                          ? null
                          : (
                              candidate.firstName[0] + candidate.lastName[0]
                            ).toUpperCase()}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      id={labelId}
                      primary={`${candidate.firstName} ${candidate.lastName}`}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        checked={checked.indexOf(candidate) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={handleToggle(candidate)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </FormControl>
      </div>
      <FwdBackButtons fwd />
    </form>
  );
}
export default CandidateSelection;
