import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import consultancyReducer from './consultancyReducer';
import candidateCampaignReducer from './candidateCampaignReducer';
import campaignReducer from './campaignReducer';
import mediaDrawerReducer from './mediaDrawerReducer';
import candidateReducer from './candidateReducer';
import employerReducer from './employerReducer';
import dwiReducer from './ConsultancyModules/dwi/dwiReducer';
import kcqReducer from './ConsultancyModules/kcq/kcqReducer';
import talentAlertReducer from './talentAlertReducer';
import notificationsReducer from './notificationsReducer';
import analyticsReducer from './analyticsReducer';
import notificationSettingsReducer from './notificationSettingsReducer';
import themeReducer from './themeReducer';
import { SESSION_LOGOUT } from 'src/actions/sessionActions';

const rootReducer = combineReducers({
  session: sessionReducer,
  consultancy: consultancyReducer,
  campaign: campaignReducer,
  mediaDrawer: mediaDrawerReducer,
  candidateCampaign: candidateCampaignReducer,
  candidate: candidateReducer,
  dwi: dwiReducer,
  kcq: kcqReducer,
  employer: employerReducer,
  talentAlert: talentAlertReducer,
  notifications: notificationsReducer,
  analytics: analyticsReducer,
  notificationSettings: notificationSettingsReducer,
  activeTheme: themeReducer,
});

export default (state, action) =>
  rootReducer(action.type === SESSION_LOGOUT ? {} : state, action);
