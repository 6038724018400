// TOP BAR FOR LOGIN PAGE ONLY

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, useMediaQuery } from "@material-ui/core";
import LanguageDropdown from "../../components/LanguageDropdown";
import { HomeButton } from "src/components/HomeButton";

const useStyles = makeStyles(() => ({
	appbar: {
		width: "100%",
		position: "absolute",
		top: 0,
	},
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		flexDirection: "row",
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		marginRight: 10,
	},
	sectionMobile: {
		display: "flex",
		marginRight: window.innerWidth - 228,
		alignItems: "center",
	},
}));

const TopBar = ({ logo, ...rest }) => {
	const classes = useStyles();
	const mobile = useMediaQuery("(max-width:600px)");

	return (
		<AppBar
			{...rest}
			className={`${classes.grow} ${classes.appbar}`}
			style={{
				backgroundColor: "transparent",
				boxShadow: "none",
			}}
		>
			<Toolbar style={{ backgroundColor: "transparent" }}>
				<div className={mobile ? null : classes.grow} />
				<div
					className={mobile ? classes.sectionMobile : classes.sectionDesktop}
				>
					<HomeButton />
				</div>
				<LanguageDropdown />
			</Toolbar>
		</AppBar>
	);
};

TopBar.propTypes = {};

export default TopBar;
