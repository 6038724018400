import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Button,
  FormControl,
  MenuItem,
  Select,
  colors,
  InputLabel,
  Typography,
  Tooltip
} from "@material-ui/core";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import ContactSelect from "src/components/ContactSelect";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "33%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
  formControl: {
    width: "100%",
  },
  contactExists: {
    color: "red",
    paddingLeft: "2px",
  },
}));

const existingContactSchema = {
  contact: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

const newContactSchema = {
  jobTitle: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64,
    },
  },
  telephone: {
    length: {
      maximum: 20,
    },
  },
};

const noContactSchema = {};

function SelectOrCreateContact({
  onSubmit,
  employerDetails,
  contactExists,
  handleSetContactExists,
}) {
  const classes = useStyles();
  const [t] = useTranslation(["snackbar", "common", "campaigns", "tooltips"]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      contactAssignment: "PleaseSelect",
    },
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    setIsSubmitting(false);
    handleSetContactExists(false);
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleContactChange = (event, value) => {
    setIsSubmitting(false);
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        contact: value,
      },
      touched: {
        ...prevFormState.touched,
        contact: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    onSubmit(formState.values);
  };
  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsSubmitting(false);
    let schema;
    switch (formState.values.contactAssignment) {
      case "ExistingContact":
        schema = existingContactSchema;
        break;
      case "NewContact":
        schema = newContactSchema;
        break;
      case "NoContact":
        schema = noContactSchema;
        break;
      default:
        break;
    }
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  return (
    <form onSubmit={handleSubmit} name="assignContactForm" noValidate>
      <div className={classes.fields}>
        <FormControl variant="outlined" className={classes.formControl}>
          <div style={{ position: "relative" }}>
            <InputLabel
              required
              id="contactSelectionLabel"
              style={{ backgroundColor: "white" }}
            >
              {t("campaigns:newOrExistingContact")}
            </InputLabel>
            <div
              style={{ position: "absolute", top: -8, left: 170, zIndex: 100 }}
            >
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={t("tooltips:campaignAssignContact")}
              >
                <InfoIcon
                  style={{ marginbottom: "10px", marginRight: "10px" }}
                />
              </Tooltip>
            </div>
          </div>
          <Select
            required
            labelId="contactSelectionLabel"
            name={"contactAssignment"}
            value={formState.values.contactAssignment}
            onChange={handleChange}
          >
            <MenuItem value={"PleaseSelect"}>
              {`${t("campaigns:pleaseSelect")} *`}
            </MenuItem>
            <MenuItem value={"ExistingContact"}>
              {t("campaigns:contactSelectionExisting")}
            </MenuItem>
            <MenuItem value={"NewContact"}>
              {t("campaigns:contactSelectionNew")}
            </MenuItem>
            <MenuItem value={"NoContact"}>
              {t("campaigns:contactSelectionNone")}
            </MenuItem>
          </Select>
        </FormControl>
        {formState.values.contactAssignment === "ExistingContact" && (
          <ContactSelect
            handleChange={handleContactChange}
            disabled={formState.values.contactAssignment !== "ExistingContact"}
            value={formState.values.contact}
            employerId={
              employerDetails && employerDetails.id ? employerDetails.id : null
            }
          />
        )}
        {formState.values.contactAssignment === "NewContact" && (
          <>
            <TextField
              fullWidth
              disabled={formState.values.contactAssignment !== "NewContact"}
              label={t("common:jobTitleLabel")}
              name="jobTitle"
              variant="outlined"
              required
              onChange={handleChange}
              error={hasError("jobTitle")}
              value={formState.values.jobTitle || ""}
              helperText={
                hasError("jobTitle")
                  ? t(`validation:${formState.errors.jobTitle[0]}`)
                  : null
              }
            />

            <TextField
              fullWidth
              disabled={formState.values.contactAssignment !== "NewContact"}
              label={t("common:firstNameLabel")}
              name="firstName"
              variant="outlined"
              required
              onChange={handleChange}
              error={hasError("firstName")}
              value={formState.values.firstName || ""}
              helperText={
                hasError("firstName")
                  ? t(`validation:${formState.errors.firstName[0]}`)
                  : null
              }
            />
            <TextField
              fullWidth
              disabled={formState.values.contactAssignment !== "NewContact"}
              label={t("common:lastNameLabel")}
              name="lastName"
              variant="outlined"
              required
              onChange={handleChange}
              error={hasError("lastName")}
              value={formState.values.lastName || ""}
              helperText={
                hasError("lastName")
                  ? t(`validation:${formState.errors.lastName[0]}`)
                  : null
              }
            />
            <TextField
              fullWidth
              disabled={formState.values.contactAssignment !== "NewContact"}
              label={t("common:emailLabel")}
              name="email"
              variant="outlined"
              required
              onChange={handleChange}
              error={hasError("email")}
              value={formState.values.email || ""}
              helperText={
                hasError("email")
                  ? t(`validation:${formState.errors.email[0]}`)
                  : null
              }
            />
            {contactExists ? (
              <Typography className={classes.contactExists}>
                A user with this email already exists
              </Typography>
            ) : null}
            <TextField
              fullWidth
              disabled={formState.values.contactAssignment !== "NewContact"}
              label={t("common:telephoneLabel")}
              name="telephone"
              variant="outlined"
              onChange={handleChange}
              error={hasError("telephone")}
              value={formState.values.telephone || ""}
              helperText={
                hasError("telephone")
                  ? t(`validation:${formState.errors.telephone[0]}`)
                  : null
              }
            />
          </>
        )}
      </div>

      <div style={{ textAlign: "right" }}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          fullWidth
          disabled={
            !formState.isValid ||
            isSubmitting ||
            formState?.values?.contactAssignment === "PleaseSelect"
          }
        >
          {t("campaigns:createCampaign")}
        </Button>
      </div>
    </form>
  );
}
export default SelectOrCreateContact;
