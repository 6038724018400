import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Switch,
	Typography,
} from "@material-ui/core";

import ExtensionSharpIcon from "@material-ui/icons/ExtensionSharp";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
	card: {
		display: "flex",
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(2),
	},
	content: {
		flex: "1 0 auto",
		width: "16rem",
	},
	iconContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	icon: {
		fontSize: 16,
	},
}));

export const ToggleCard = ({
	name,
	enabled,
	handleSwitch,
	hideControls,
	addOptionsBtn,
	optionsBtnClick,
	// detail,
	// icon,
	// sysAdmin,
}) => {
	const classes = useStyles();

	//* removed icon conditional as not currently being used.. could be utilised for tooltips if client wants them adding to cards
	return (
		<Grid item xl={4} lg={6} xs={12}>
			<Card className={classes.card}>
				{/* {icon && (
					<CardMedia className={classes.iconContainer}>
						{icon} || <ExtensionSharpIcon className={classes.icon} />
					</CardMedia>
				)} */}
				<CardMedia className={classes.iconContainer}>
					<ExtensionSharpIcon className={classes.icon} />
				</CardMedia>
				<CardContent className={classes.content}>
					<Typography component="h4" variant="h5">
						{name || ""}
						{/* use component="h5" variant="h4" if re-enabling typography component for 'detail'  */}
					</Typography>
					{/* <Typography variant="h5" color="textSecondary">
						{detail || ""} //* REMOVED WHILE NOT CURRENTLY USED
					</Typography> */}
				</CardContent>
				<CardActions className={classes.actions}>
					<Box display="flex" flexDirection="row-reverse">
						{/* {sysAdmin && (
							//- TODO: System Admin Settings Cog functionality? //* REMOVED. NOT PRESENTLY USED.
							<Button>
								<SettingsIcon />
							</Button>
						)} */}
						{!hideControls && (
							<Switch
								checked={enabled}
								onChange={handleSwitch}
								color="primary"
							/>
						)}

						{addOptionsBtn && (
							<Button onClick={() => {
								optionsBtnClick();
							}}>
								<SettingsIcon />
							</Button>
						)}
					</Box>
				</CardActions>
			</Card>
		</Grid>
	);
};
