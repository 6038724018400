import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Grid, AppBar } from "@material-ui/core";
import * as EmployerApi from "src/api/employerApi";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { ProfileCard } from "src/components/ProfileCard";
import { TabPanel } from "src/components/TabPanel";
import CreateContactModal from "./CreateContactModal";
import EmployerProfile from "../EmployerProfile";
import { getConsultancyLogo } from "src/api/consultancyApi";
import Swal from "sweetalert2";
import validate from "validate.js";
import { uploadEmployerLogo } from "src/api/employerApi";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import { CampaignCard } from "src/components/Cards/CampaignCard";
import CustomTabs from './../../components/LayoutComponents/CustomTabs';

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "0% 5%",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  createCampaignButtonText: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  companyLogo: {
    height: "80px",
    width: "auto",
    objectFit: "contain",
  },
  candidateTitle: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  candidateEmail: {
    textAlign: "center",
    marginTop: "10px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    zIndex: 1,
  },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
  },
}));

function EmployersDetails({ employerId, consultancyId }) {
  const classes = useStyles();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const sessionConsultancyId = useSelector(
    (state) => state.session.consultancyId
  );
  const role = useSelector((state) => state.session.roleType);
  const doShowCampagins = useSelector((state) => {
    if (state.session.consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
      return true;
    }

    return false;
  });

  const [employer, setEmployer] = useState();
  const [contacts, setContacts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [consultancyLogo, setConsultancyLogo] = useState("");
  const [employerLogo, setEmployerLogo] = useState([]);
  const [assignTo, setAssignTo] = useState(false);
  const [defaultUser, setDefaultUser] = useState();
  const [managedByUser, setManagedByUser] = useState();
  const [loading, setloading] = useState(false);
  const [details, setDetails] = useState({
    consultancyLogo: null,
    active: false,
    detailsForm: {
      isValid: false,
      values: {
        employerName: null,
        telephone: null,
        addressLine1: null,
        addressLine2: null,
        city: null,
        postalCode: null,
        country: null,
      },
      touched: {},
      errors: {},
    },
  });

  const isSysAdmin = role === "CoreAdmin" || role === "SystemAdmin";

  const history = useHistory();
  const [t] = useTranslation(["common"]);
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    { value: 0, label: t("common:profileLabel") },
    { value: 1, label: t("common:Contacts") }
  ];

  if (doShowCampagins) {
    tabs.push({ value: 2, label: t('common:campaignsLabel') });
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const getEmployerById = function(consultancyId, employerId) {
    return EmployerApi.getEmployerById(
      consultancyId || sessionConsultancyId,
      employerId
    )
      .then((response) => {
        setContacts(response.data.contacts);
        setEmployer(response.data);
        setCampaigns(response.data.campaigns);
        setEmployerLogo(response.data.employerLogo);

        let user = {
          id: response.data.managedByUser.id,
          name:
            response.data.managedByUser.profile.firstName +
            " " +
            response.data.managedByUser.profile.lastName,
        };
        setAssignTo(() => ({
          touched: false,
        }));
        setManagedByUser(user);
        setDefaultUser(user);
        setDetails((prevFormState) => ({
          ...prevFormState,
          employerLogo: response.data.employerLogo,
          active: true, //For launch button
          details: {
            ...response.data,
          },
          detailsForm: {
            ...prevFormState.detailsForm,
            values: {
              employerName: response.data.employerName,
              telephone: response.data.telephoneNumber,
              addressLine1: response.data.address.addressLine1,
              addressLine2: response.data.address.addressLine2,
              city: response.data.address.city,
              postalCode: response.data.address.postalCode,
              country: response.data.address.countryId,
            },
          },
        }));
      })
      .catch((error) => {
        history.push("/dashboard/employers");
      });
  };

  const fetchConsultancyLogo = (consultancyId) => {
    return getConsultancyLogo(consultancyId)
      .then((response) => {
        setConsultancyLogo(response.data.consultancyLogo);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleManagedByChange = (newUser) => {
    setManagedByUser(newUser);
    setAssignTo(() => ({
      touched: true,
    }));
  };

  const saveManagedBy = (event) => {
    event.preventDefault();
    let updatedManagedByUser = managedByUser;
    Swal.fire({
      title: `${t("common:areYouSure")}`,
      text: `${t("common:youAreChangingTheEmployerManagedBy")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `${t("common:yesChangeIt")}`,
      cancelButtonText: `${t("common:noKeepIt")}`,
      confirmButtonColor: theme.palette.warning?.main
        ? theme.palette.warning.main
        : theme.palette.primary.main,
    }).then((result) => {
      if (result.value) {
        EmployerApi.updateManagedById(
          consultancyId,
          employerId,
          updatedManagedByUser.userId
        )
          .then((response) => {
            if (response.status === 200) {
              enqueueSnackbar(t("common:employerUpdated"), {
                variant: "success",
              });
              setAssignTo(() => ({
                touched: false,
              }));
            }
          })
          .catch((error) => {
            enqueueSnackbar(t("common:employerError"), { variant: "error" });
            console.error(error);
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setManagedByUser(defaultUser);
        setAssignTo(() => ({
          touched: false,
        }));
      }
    });
  };

  const handleChange = (event) => {
    event.persist();

    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value,
        },
        touched: {
          ...prevFormState.touched,
          [event.target.name]: true,
        },
      },
    }));
  };

  const hasError = (field) => {
    if (
      details.detailsForm.touched[field] &&
      details.detailsForm.errors[field]
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCountryChange = (value) => {
    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        values: {
          ...prevFormState.detailsForm.values,
          country: value,
        },
        touched: {
          ...prevFormState.touched,
          country: true,
        },
      },
    }));
  };

  const saveDetails = (event) => {
    event.preventDefault();
    let updatedConsultancyDetails = details.detailsForm.values;
    updatedConsultancyDetails["consultancyId"] = consultancyId;
    return EmployerApi.updateEmployerById(
      consultancyId,
      employerId,
      updatedConsultancyDetails
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("common:employerUpdated"), { variant: "success" });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("common:consultanctUpdateError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const handleUploadEmployerLogo = (value) => {
    return uploadEmployerLogo(consultancyId, employerId, value)
      .then((success) => {
        if (success) {
          enqueueSnackbar(t("common:consultancyLogoUpdated"), {
            variant: "success",
          });
          getEmployerById(consultancyId, employerId);
        } else {
          throw Error("Failed to update logo");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("common:consultancyLogoUpdatedError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  // Validate form when changes are made
  useEffect(() => {
    const errors = validate(details.detailsForm.values, schema);

    setDetails((prevFormState) => ({
      ...prevFormState,
      detailsForm: {
        ...prevFormState.detailsForm,
        isValid: !errors,
        errors: errors || {},
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details.detailsForm.values]);

  useEffect(() => {
    setloading(true);
    fetchConsultancyLogo(consultancyId);
    if (!employerId) {
      history.push("/dashboard/employers");
    } else {
      getEmployerById(consultancyId || sessionConsultancyId, employerId).then(
        () => {
          setloading(false);
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      {
        title: t("common:employersLabel"),
        link: isSysAdmin ? "/all-employers" : "/employers",
      },
      { title: employer?.employerName, link: `/employers/${employerId}` },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employer]);

  const onSuccess = (contactDetails) => {
    contacts.push(contactDetails);
  };

  const goToContact = (event, contact) => {
    event.preventDefault();
    history.push(
      `/dashboard/employers/${employerId}/contacts/${contact.contactId}`
    );
  };

  const goToCampaign = (event, id) => {
    event.preventDefault();
    history.push(`/dashboard/campaigns/${id}`);
  };

  function createData(name, data) {
    return { name, data };
  }

  const getCampaignDetails = (campaign) => {
    const campaignDetails = [
      createData(t(`campaigns:employerLabel`), campaign.employerName),
      createData(t(`campaigns:campaignStatusLabel`), campaign.campaignStatus),
      createData(t(`campaigns:dateStarted`), campaign.dateStarted),
      createData(t(`campaigns:candidatesLabel`), campaign.numberOfCandidates),
      createData(t(`campaigns:assignedTo`), campaign.assignedTo),
    ];
    return campaignDetails;
  };
  const schema = {
    employerName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    telephone: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 20,
      },
    },
    addressLine1: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
    city: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 32,
      },
    },
    postalCode: {
      presence: { allowEmpty: false, message: t("common:required") },
      format: /^[A-Z]{1,2}[0-9]{1,2}[A-Z]{0,1} ?[0-9][A-Z]{2}$/i,
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle
        btnLabel={t("common:createContact")}
        btnFunc={handleOpen}
        breadcrumbs={breadcrumbs}
      />
      <ContentWrapper loading={loading}>
        <AppBar position="static" className={classes.appBar}>
          <CustomTabs tabs={ tabs } onChange={ handleTabChange } tabClassName={ classes.tab } />
        </AppBar>
        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={3}>
            <EmployerProfile
              consultancyId={consultancyId}
              handleManagedByChange={handleManagedByChange}
              managedByUser={managedByUser}
              saveManagedBy={saveManagedBy}
              assignTo={assignTo}
              details={details}
              saveDetails={saveDetails}h
              handleUploadEmployerLogo={handleUploadEmployerLogo}
              handleChange={handleChange}
              hasError={hasError}
              handleCountryChange={handleCountryChange}
            />
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={3}>
            {contacts.map((contact, i) => (
              <Grid key={i} item md={4} sm={6} xs={12}>
                <ProfileCard
                  name={contact.name}
                  email={contact.email}
                  btnLabel={t("common:viewMore")}
                  btnFunc={(event) => {
                    goToContact(event, contact);
                  }}
                  profileImage={contact.profilePicture}
                />
              </Grid>
            ))}
          </Grid>
        </TabPanel>

        {
          doShowCampagins && (
            <TabPanel value={currentTab} index={2}>
              <Grid container spacing={3}>
                {campaigns.map((campaign) => (
                  <Grid key={campaign.id} item md={4} sm={6} xs={12}>
                    <CampaignCard
                      campaignLogo={
                        campaign.campaignLogo
                          ? campaign.campaignLogo
                          : employerLogo
                          ? employerLogo
                          : consultancyLogo
                      }
                      campaignDetails={getCampaignDetails(campaign)}
                      name={campaign.campaignTitle}
                      btnLabel={t("common:viewMore")}
                      btnFunc={(event) => {
                        goToCampaign(event, campaign.id);
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </TabPanel>
          )
        }
      </ContentWrapper>
      <CreateContactModal
        onSuccess={onSuccess}
        consultancyId={consultancyId}
        employerId={employerId}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
}

export default EmployersDetails;
