import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Box,
	Button,
	FormGroup,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import SectionHeader from "./SectionHeader";
import * as Index from "src/api/index";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
	input: {
		width: "100%",
		padding: "8px",
	},
}));

const ContactForm = ({ consultancyDetails, consultancyColour }) => {
	const classes = useStyles();
	const theme = useTheme();

	const [isSubmitted, setIsSubmitted] = useState(false);

	const formInit = {
		name: "",
		email: "",
		message: "",
		checked: false,
	};

	const [t] = useTranslation(["common"]);

	const [form, setForm] = useState(formInit);

	const data = consultancyDetails?.landing?.contact[0];

	const handleChange = (event) => {
		const value = event.target.value;
		setForm({
			...form,
			[event.target.name]: value,
		});
	};

	//? consent gdrp needed ?
	// const handleCheck = (event) => {
	// 	const value = event.target.checked;
	// 	setForm({
	// 		...form,
	// 		checked: [value],
	// 	});
	// };

	const handleSubmit = (event) => {
		//* Reece to hook up when email b/end in place
		event.preventDefault();
		Index.contact(form)
			.then((response) => {
				setIsSubmitted(true);
			})
			.catch((error) => {});
	};

	return (
		<Box>
			<SectionHeader
				colour={
					consultancyDetails
						? consultancyDetails.theme.heading
						: consultancyColour
				}
			>
				Contact Us
			</SectionHeader>
			<Box marginBottom={"1.5rem"}>
				<Typography gutterBottom variant="h4" align="center">
					{data?.message
						? data.message
						: "Do you have questions? Would you welcome a FREE online demo?"}
				</Typography>
				<Typography variant="h4" align="center">
					{data?.telephone
						? `Complete the form below or call us direct on ${data.telephone}.`
						: "Complete the form below and we'll be in touch."}
				</Typography>
			</Box>
			<Box display="flex" justifyContent="center">
				<Box width={"100%"} maxWidth={"1000px"}>
					{!isSubmitted ? (
						<form onSubmit={handleSubmit}>
							<FormGroup>
								<Grid container>
									<Grid item xs={12} lg={6}>
										<TextField
											className={classes.input}
											label="Name"
											variant="outlined"
											name="name"
											value={form.name}
											onChange={handleChange}
											input={{ color: "red" }}
										></TextField>
									</Grid>
									<Grid item xs={12} lg={6}>
										<TextField
											className={classes.input}
											label="Email"
											variant="outlined"
											name="email"
											value={form.email}
											onChange={handleChange}
										></TextField>
									</Grid>
									<Grid xs={12}>
										<TextField
											multiline
											className={classes.input}
											label="Message"
											variant="outlined"
											name="message"
											value={form.message}
											onChange={handleChange}
										></TextField>
									</Grid>
								</Grid>
								<Box display="flex" justifyContent="center" paddingY="2rem">
									<Button
										type="submit"
										variant="contained"
										style={{
											width: "8rem",
											height: "3.5rem",
											backgroundColor: consultancyDetails
												? consultancyDetails.theme?.button
												: consultancyColour
												? consultancyColour
												: theme.palette.primary.main,
											color: theme.palette.getContrastText(
												consultancyDetails
													? consultancyDetails.theme?.button
													: consultancyColour
													? consultancyColour
													: theme.palette.primary.main
											),
										}}
									>
										{t("common:submit")}
									</Button>
								</Box>
							</FormGroup>
						</form>
					) : (
						<>
							<Typography gutterBottom variant="h4" align="center">
								{t("common:contactSubmitted")}
							</Typography>
						</>
						//TODO needs to get height of previous container and assign so content does not jump on submission.
						//TODO give submit button load state.
						//TODO add transition.
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default ContactForm;
