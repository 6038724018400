import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/styles";
import { Select, MenuItem } from "@material-ui/core";
import { LANGUAGE_CHANGE } from "../actions/sessionActions";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@material-ui/icons/Language";
import * as CookieActions from "../actions/cookieActions";

const useStyles = makeStyles((theme) => ({
  select: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderBottom: "none",
    padding: 5,
  },
  menuItem: {
    width: 200,
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.nav.main),
    fontSize: "18px",
    fontWeight: 100,
    cursor: "pointer",
    marginRight: theme.spacing(3),
  },
}));

const LanguageDropdown = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const currentLanguage = useSelector((state) => state.session.language);
  const landing = window.location.pathname === "/";
  const [t, i18n] = useTranslation(["languages"]); // eslint-disable-line no-unused-vars
  const iconColour = landing
    ? theme.palette.landing?.overlay
      ? theme.palette.getContrastText(theme.palette.landing?.overlay)
      : theme.palette.getContrastText(theme.palette.primary.main)
    : theme.palette.getContrastText(theme.palette.nav?.main);

  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    CookieActions.setCookie("language", event.target.value);
    i18n.changeLanguage(event.target.value);
    dispatch({ type: LANGUAGE_CHANGE, language: event.target.value });
  };

  useEffect(() => {
    i18n.changeLanguage(currentLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Select
      disableUnderline
      open={open}
      onClick={() => setOpen(!open)}
      onClose={() => setOpen(false)}
      id="language_select"
      value={currentLanguage || "en_gb"} //TODO: Base off current consultancy
      onChange={handleChange}
      className={classes.select}
      classes={{ icon: classes.icon }}
      SelectDisplayProps={{
        style: {
          padding: "5px 10px",
          fontWeight: 200,
          color: iconColour,
        },
      }}
      IconComponent={() => (
        <LanguageIcon onClick={() => setOpen(true)} className={classes.icon} />
      )}
    >
      <MenuItem value={"en_gb"} className={classes.menuItem}>
        English
      </MenuItem>
      {/* // TODO: Fix translations */}
      {/* <MenuItem value={"cs"} className={classes.menuItem}>
        Čeština
      </MenuItem> */}
      <MenuItem value={"de"} className={classes.menuItem}>
        Deutsch
      </MenuItem>
      <MenuItem value={"en_us"} className={classes.menuItem}>
        English US
      </MenuItem>
      <MenuItem value={"es"} className={classes.menuItem}>
        Español
      </MenuItem>
      <MenuItem value={"fr"} className={classes.menuItem}>
        Français
      </MenuItem>
      <MenuItem value="no" className={ classes.menuItem }>
        Norsk
      </MenuItem>
      {/* // TODO: Fix translations */}
      {/* <MenuItem value={"nl"} className={classes.menuItem}>
        Nederlands
      </MenuItem> */}
      {/* // TODO: Fix translations */}
      {/* <MenuItem value={"ro"} className={classes.menuItem}>
        Romȃneşte
      </MenuItem> */}
    </Select>
  );
};

LanguageDropdown.propTypes = {};

export default LanguageDropdown;
