import React from 'react';
import DocumentListCard from './DocumentListCard';
import { CAMPAIGN_DOCUMENTS_CONTAINER_ID } from 'src/constants/dragDropContainerIds';
import { Droppable, DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  documentList: {
    marginBottom: theme.spacing(4),
  },

  documentsOrderButton: {
    marginTop: 20,
  },
}));
const DraggableCampaignDocuments = ({
  onDragEnd,
  handleRemove,
  consultancyId,
  saveDocumentsOrder,
  setMethods,
  documents,
}) => {
  const [t] = useTranslation(['campaigns', 'common', 'snackbar']);
  const classes = useStyles();
  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={CAMPAIGN_DOCUMENTS_CONTAINER_ID}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {documents?.map((document, index) => {
                return (
                  <Draggable draggableId={document.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DocumentListCard
                          key={document.id}
                          document={document}
                          onRemove={handleRemove}
                          setMethods={setMethods}
                          consultancyId={consultancyId}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        className={classes.documentsOrderButton}
        color='primary'
        size='large'
        onClick={saveDocumentsOrder}
        variant='contained'
        fullWidth
      >
        {t('campaigns:saveDocumentsOrder')}
      </Button>
    </>
  );
};

export default DraggableCampaignDocuments;
