import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { Avatar, Badge, Grid, Button, Typography } from "@material-ui/core";
import UserTypeAvatar from "./UserTypeAvatar";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	userRow: {
		border: `1px solid ${theme.palette.primary.light}`,
		borderRadius: 2,
		margin: "12px",
	},
	userRowContainer: {
		alignItems: "center",
	},
	buttonText: {
		color: "white",
	},
}));

function GroupUserListCard({ onSuccess, groupArray }) {
	const classes = useStyles();
	const [t] = useTranslation(["common", "users", "groups"]);
	const [check, setCheck] = useState(false);

	return (
		<>
			{groupArray?.map((user, index) => (
				<Grid key={index} item xs={12} className={classes.userRow}>
					<Grid container spacing={1} className={classes.userRowContainer}>
						<Grid item xs={3} sm={2} md={2} lg={2}>
							{/* change to cardInfo.avatarSrc when available */}
							<Badge
								overlap="circle"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								badgeContent={<UserTypeAvatar role={user?.userType} />}
							>
								<Avatar
									src={
										user.profilePicture
											? user.profilePicture
											: "https://www.dovercourt.org/wp-content/uploads/2019/11/610-6104451_image-placeholder-png-user-profile-placeholder-image-png.jpg"
									}
								/>
							</Badge>
						</Grid>
						<Grid item xs={9} sm={7} md={7} lg={7}>
							<Typography variant="h5">{user.name}</Typography>
						</Grid>
						<Grid item xs={12} sm={2} md={2} lg={2}>
							{user.groupId ? (
								<Button
									fullWidth
									color="primary"
									size="small"
									type="submit"
									variant="contained"
									onClick={() => {
										onSuccess(user, "remove");
										setCheck(!check);
									}}
								>
									<Typography variant="body2" className={classes.buttonText}>
										{t("common:remove")}
									</Typography>
								</Button>
							) : (
								<Button
									fullWidth
									color="primary"
									size="small"
									type="submit"
									variant="contained"
									onClick={() => {
										onSuccess(user, "add");
										setCheck(!check);
									}}
								>
									<Typography variant="body2" className={classes.buttonText}>
										{t("common:add")}
									</Typography>
								</Button>
							)}
						</Grid>
					</Grid>
				</Grid>
			))}
		</>
	);
}

export default GroupUserListCard;
