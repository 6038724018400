import axios from "../utils/axios";

//requests both admin and consultant call
export const createContact = ({
  firstName,
  lastName,
  salutation,
  email,
  telephone,
  consultancyId,
  employerId,
  jobTitle,
}) => {
  return axios.post("/Contacts/createContact", {
    firstName,
    lastName,
    salutation,
    email,
    telephone,
    consultancyId,
    employerId,
    jobTitle,
  });
};

export const getContacts = (consultancyId, employerId) => {
  return axios.get("/Contacts/getContacts", {
    params: {
      consultancyId,
      employerId,
    },
  });
};

export const acceptCampaignTermsAndConditions = (consultancyId, campaignId) => {
  return axios.post("/Contacts/acceptCampaignTermsAndConditions", {
    consultancyId,
    campaignId,
  });
};

export const updateCampaignKCQ = (accessToken, questions) => {
  return axios.post("/Contacts/updateCampaignKCQ", {
    accessToken,
    questions,
  });
};

export const getCampaignKCQ = (accessToken) => {
  return axios.get("/Contacts/getCampaignKCQ", {
    params: {
      accessToken,
    },
  });
};

export const getCampaignSets = (accessToken) => {
  return axios.get("/Contacts/getCampaignSets", {
    params: {
      accessToken,
    },
  });
};

export const getContactCampaigns = (consultancyId) => {
  return axios.get("/Contacts/getContactCampaigns", {
    params: {
      consultancyId,
    },
  });
};

export const getContactCampaignById = (campaignId, consultancyId) => {
  return axios.get("/Contacts/getContactCampaignById", {
    params: {
      campaignId,
      consultancyId,
    },
  });
};

export const getCandidateProfile = (consultancyId, candidateId, campaignId) => {
  return axios.get("/Contacts/getCandidateProfile", {
    params: {
      consultancyId,
      candidateId,
      campaignId,
    },
  });
};

export const changeCandidateStatus = (
  campaignId,
  consultancyId,
  candidateId,
  candidateStatusId
) => {
  return axios.post("/Contacts/changeCandidateStatus", {
    campaignId,
    consultancyId,
    candidateId,
    candidateStatusId,
  });
};

export const submitCandidateDWIScores = (
  candidateDWI,
  consultancyId,
  campaignId,
  candidateId
) => {
  return axios.post(
    "/Contacts/submitCandidateDWIScores",
    { ...candidateDWI },
    {
      params: {
        consultancyId,
        campaignId,
        candidateId,
      },
    }
  );
};

export const setContactWelcomeMessagesStatusToRead = () => {
  return axios.post("/Contacts/updateWelcomeMessageStatusRead", {});
};

export const setContactWelcomeMessagesStatusToNotRead = (consultancyId) => {
  return axios.post("/Contacts/updateWelcomeMessageStatusNotRead", {
    consultancyId,
  });
};

export const getContactWelcomeMessagesStatus = () => {
  return axios.get("/Contacts/getWelcomeMessagesStatus", {});
};

export const uploadContactImage = (consultancyId, contactId, formData) => {
  return axios
    .post("/Contacts/uploadContactImage", formData, {
      params: {
        consultancyId,
        contactId,
      },
    })
    .then((response) => {
      if (response.data !== null && response.data !== undefined) {
        return response.data;
      } else {
        throw Error("Failed to retrieve consultancy details");
      }
    });
};

export const getContactWelcomeMessages = (consultancyId) => {
  return axios.get("/DefaultWelcomeMessages/getContactWelcomeMessages", {
    params: {
      consultancyId,
    },
  });
};

export const dismissContactWelcomeMessage = (consultancyId, messageId) => {
  return axios.post(
    "/DefaultWelcomeMessages/dismissContactWelcomeMessage",
    null,
    {
      params: {
        consultancyId,
        messageId,
      },
    }
  );
};

export const deleteContact = (contactId, consultancyId) => {
  return axios.delete("/Contacts/deleteContact", {
    params: {
      contactId,
      consultancyId
    },
  });
};

export const assignKCQToCampaign = (
  contactAccessToken,
  {
    inviteContacts,
    useCustomSet,
    createContact,
    selectedContact,
    selectedCustomQuestionSet,
    selectedQuestions,
    questionSetTitle,
  }
) => {
  return axios.post("/Contacts/assignKCQToCampaign", {
    inviteContacts,
    useCustomSet,
    createContact,
    selectedContact,
    selectedCustomQuestionSet,
    selectedQuestions,
    questionSetTitle,
    contactAccessToken,
  });
};

export const isPrimaryContact = (consultancyId, campaignId) => {
  return axios.get('/Contacts/isPrimaryContact', {
    params: {
      consultancyId,
      campaignId
    },
  });
};

export const changePassword = (contactId, consultancyId, password) => {
  return axios.post(
    '/Contacts/changePassword',
    { password },
    {
      params: {
        contactId,
        consultancyId
      }
    }
  );
};

export const isContactActivated = (contactId) => {
  return axios.get(
    '/Contacts/isContactActivated',
    {
      params: { contactId }
    }
  );
};

export const changeEmail = (contactId, email) => {
  return axios.post(
    '/Contacts/changeEmail',
    { email },
    {
      params: { contactId }
    }
  );
};

export const getContactById = (contactId, consultancyId) => {
  return axios.get(
    '/Contacts/getContactById',
    {
      params: { contactId, consultancyId }
    }
  );
};
