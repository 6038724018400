import React, { useState, useEffect } from "react";
import Header from "./Header";
import Results from "./Results";
import * as SystemAdminApi from "src/api/SystemAdminApi";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";

function UserListView() {
  const [users, setUsers] = useState(null);
  const [consultancies, setConsultancies] = useState([]);

  const getAllUsers = () => {
    return SystemAdminApi.getAllUsers()
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getConsultancies = () => {
    return SystemAdminApi.getConsultancies()
      .then((response) => {
        setConsultancies(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllUsers();
    getConsultancies();
  }, []);

  return (
    <>
      <Header consultancies={consultancies} />
      <ContentWrapper>
        {users ? <Results users={users} consultancies={consultancies} /> : null}
      </ContentWrapper>
    </>
  );
}

export default UserListView;
