import * as actionTypes from 'src/actions';

const initialState = {};

const candidateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DEFAULT_CANDIDATE_PROFILE: {
      return {
        ...state,
        defaultCandidateProfile: action.defaultCandidateProfile,
      };
    }
    default: {
      return state;
    }
  }
};

export default candidateReducer;
