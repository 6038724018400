import React, { useEffect, useState } from 'react';
import { FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function DocumentListCard({ visibility, onChange, ...rest }) {
  const [t] = useTranslation(['common', 'validation', 'campaigns']);
  const [state, setState] = useState({
    private: visibility.includes('private'),
    contacts: visibility.includes('contacts'),
    candidates: visibility.includes('candidates'),
  });

  const handleChange = (event) => {
    setState((prevFormState) => ({
      ...prevFormState,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value,
    }));
  };

  //- Handles array of component in parent
  useEffect(() => {
    if (rest.id) {
      setState((prevFormState) => ({
        ...prevFormState,
        id: rest.id,
      }));
    }
  }, [rest.id]);

  useEffect(() => {
    if (rest && rest.id) {
      let { id, ...rest } = state;
      let filteredState = {
        id,
        visibility: Object.keys(rest).filter((access) => rest[access]),
      };
      onChange(filteredState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    //Get the keys of state where visibility is enabled
    if (!rest.id) {
      onChange(Object.keys(state).filter((access) => state[access]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange, state]);

  //Rerenders when itemId changes. Fix to tick boxes not updating after line deletion
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setState((prevState) => ({
        ...prevState,
        private: visibility.includes('private'),
        contacts: visibility.includes('contacts'),
        candidates: visibility.includes('candidates'),
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rest?.itemId]);

  return (
    <FormGroup row>
      {/* //- TODO: Visible to all */}
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={state.all}
            onChange={handleChange}
            name="private"
            color="all"
          />
        }
        label={t('campaigns:campaignDocumentVisibilitySelectionAll')}
      /> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={state.private}
            onChange={handleChange}
            name='private'
            disabled={true}
            color='primary'
          />
        }
        label={t('campaigns:campaignDocumentVisibilitySelectionPrivate')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.contacts}
            onChange={handleChange}
            name='contacts'
            color='primary'
            disabled={rest.active}
          />
        }
        label={t('campaigns:campaignDocumentVisibilitySelectionContacts')}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.candidates}
            onChange={handleChange}
            name='candidates'
            color='primary'
            disabled={rest.active}
          />
        }
        label={t('campaigns:campaignDocumentVisibilitySelectionCandidates')}
      />
    </FormGroup>
  );
}
export default DocumentListCard;
