import * as actionTypes from "src/actions";

const initialState = {};

const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANDIDATE_CAMPAIGN: {
      return {
        ...state,
        ...action.candidateCampaign,
      };
    }
    case actionTypes.UPDATE_CANDIDATE_PROFILE: {
      return {
        ...state,
        candidateProfile: { ...action.candidateProfile },
      };
    }
    case actionTypes.UPDATE_CANDIDATE_STATUS: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          candidateStatus: action.status.candidateStatus,
          status: action.status.statusName,
        },
      };
    }
    //updating both fields here as part of application uses one and part another, needs updating both for calendar slots to work(appear and refresh on update) for all users. Probably needs rewrite and use just one. To bigger job atm
    case actionTypes.UPDATE_CAMPAIGN_SCHEDULER: {
      return {
        ...state,
        campaignEvents: [...action.campaignEvents],
        calendarEntries: [...action.campaignEvents],
      };
    }
    case actionTypes.UPDATE_EXECUTIVE_SUMMARY: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          executiveSummary: action.executiveSummary,
        },
      };
    }
    case actionTypes.UPDATE_CAREER_HIGHLIGHTS: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          careerHighlights: action.careerHighlights,
        },
      };
    }

    case actionTypes.UPDATE_PROFILE_VIDEO: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          candidateProfileVideo: action.candidateProfileVideo,
        },
      };
    }

    case actionTypes.UPDATE_VIDEO_PROCCESSING: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          videoProcessing: action.videoProcessing,
        },
      };
    }

    //update required for both profilePicture and candidateProfilePicture for candidate view to refresh photo after upload. Some views use profilePicture and others candidateProfilePicture but same action is used. Not ideal fix but it works.
    case actionTypes.UPDATE_CANDIDATE_PROFILE_PICTURE: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          profilePicture: action.profilePicture,
          candidateProfilePicture: action.profilePicture,
        },
      };
    }

    case actionTypes.UPDATE_CANDIDATE_DWI: {
      return {
        ...state,
        candidateProfile: {
          ...state.candidateProfile,
          teamSuitabilityScores: action.teamSuitabilityScores,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default campaignReducer;
