import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as campaignActions from './../../../actions/campaignActions';

import * as talentAlertApi from './../../../api/talentAlertApi';

import SingleCandidate from './SingleCandidate';
import { ContentWrapper } from './../../../components/LayoutComponents/ContentWrapper';
import { PageTitle } from './../../../components/LayoutComponents/PageTitle';
import TalentAlertBackButton from './../../../components/LayoutComponents/TalentAlertBackButton';

import { SUBMITTED, HIDDEN, REJECTED } from './../../../constants/candidateStatusIds';

const styles = {
  headerImg: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '45vh',
    // boxShadow: 'inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center'
  }
};


const useStyles = makeStyles((theme) => ({
  headerImg: styles.headerImg,
  headerImgMobile: {
    ...styles.headerImg,
    height: '160px'
  },
  video: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    marginTop: '-75px'
  },
  introTextWrapper: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginTop: '30px',
    marginBottom: '30px',
    padding: '50px',
    textAlign: 'center'
  },
  introHeader: {
    fontSize: '2rem',
    marginBottom: '20px'
  },
  introText: {
    margin: 'auto'
  },
  wrapper: {
    display: 'table',
    width: '70%',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  candidate: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px'
  },
  name: {
    fontSize: '1.5rem'
  }
}));

const TalentAlertContactDashboard = ({ loggedIn, campaignId, consultancyId, isPreview, cancelPreview }) => {

  const classes = useStyles();

  const dispatch = useDispatch();

  const history = useHistory();

  const [t] = useTranslation('newTranslations');

  const isMobile = useMediaQuery('(max-width:767px)');

  const contactCampaign = useSelector((state) => state.campaign);

  const [isLoading, setIsLoading] = useState(false);
  const [campaignTitle, setCampaignTitle] = useState('');

  const campaignCandidates = contactCampaign.campaignCandidates;

  useEffect(() => {
    if (!loggedIn) {
      history.push(`/talent-alert/${campaignId}`);
      return;
    }

    if (isPreview) {
      setIsLoading(false);
      getContactCampaignById();
      return;
    }

    talentAlertApi.checkIfInvited(campaignId)
      .then((response) => {
        if (!response.data) {
          history.push(`/talent-alert/${campaignId}`);
          return;
        }

        setIsLoading(false);
        getContactCampaignById();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (contactCampaign.campaignTitle) {
      setCampaignTitle(contactCampaign.campaignTitle);
    }
  }, [contactCampaign]);

  const getContactCampaignById = async () => {
    try {
      if (!isPreview) {
        const acceptedTCs = await talentAlertApi.checkIfAcceptedTCs(campaignId);
  
        if (!acceptedTCs.data.acceptedTCs) {
          history.push(`/terms-and-conditions/${campaignId}`);
          return;
        }
      }

      const talentAlert = await talentAlertApi.getContactTalentAlertById(campaignId, consultancyId);

      dispatch({
        type: campaignActions.UPDATE_CAMPAIGN,
        campaign: talentAlert.data
      });

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ContentWrapper loading={isLoading} removeGutters>
      <TalentAlertBackButton />

      {
        isPreview && (
          <PageTitle
            pageTitle={ `${campaignTitle} - ${t("newTranslations:recruiterPreviewTalentAlertAsContactPreviewHeaderAdditionalLabel")}` }
            btnLabel={ t("newTranslations:recruiterPreviewTalentAlertAsContactBtnCancelPreview") }
            btnFunc={ cancelPreview }
            btnVariant="outlined" />
        )
      }

      <Grid
        container
        className={classes.root} >
        <Grid
          item
          className={ isMobile ? classes.headerImgMobile : classes.headerImg }
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ backgroundImage: `url(${contactCampaign?.headerImg})` }} >
        </Grid>
        
        {
          contactCampaign?.campaignVideo && (
            <Grid item xs={11} md={6} className={classes.video}>
              <video height={ isMobile ? "200px" : "400px" } poster={ isMobile ? contactCampaign?.headerImg : "" } src={ contactCampaign.campaignVideo } controls allowfullscreen />
            </Grid>
          )
        }

        <Grid container className={classes.introTextWrapper}>
          <Grid xs={12} md={6} className={classes.introText}>
            <Typography className={classes.introHeader}>
              { contactCampaign.introduction?.greetingCaption }
            </Typography>

            <Typography>
              { contactCampaign.introduction?.introductionText }
            </Typography>
          </Grid>
        </Grid>
        
        {
          campaignCandidates.map((candidate) => {
            // if candidate status is anything but visible do not show
            if (candidate.status !== SUBMITTED && candidate.status !== HIDDEN && candidate.status !== REJECTED) {
              return (
                <SingleCandidate
                  candidateData={candidate}
                  campaignId={campaignId}
                  consultancyId={consultancyId}
                  key={candidate?.id}
                  loggedIn={loggedIn} />
              );
            }

            return null;
          })
        }
      </Grid>
    </ContentWrapper>
  );

};

export default TalentAlertContactDashboard;
