import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useTrail, animated } from 'react-spring';

const defaultHeader = ['Recruitment', 'Process', "at it's", 'Best'];
const config = { mass: 5, tension: 2000, friction: 200 };

const useStyles = makeStyles((theme) => ({
  flipText: {
    position: 'relative',
    width: '100%',
    height: '96px',
    lineHeight: '76px',
    fontSize: '6em',
    willChange: 'transform, opacity',
    overflow: 'hidden',
    zIndex: 2,
  },
  'flipText > div': {
    overflow: 'hidden',
    zIndex: 3,
  },
}));

export function Reveal({ consultancyDetails }) {
  const classes = useStyles();
  const locale = consultancyDetails?.locale;
  const { language } = useSelector((state) => state.session);
  const [trigger, setTrigger] = useState(false);
  const [header, setHeader] = useState(defaultHeader);

  const trail = useTrail(header.length, {
    config,
    opacity: trigger ? 1 : 0,
    x: trigger ? 0 : 20,
    height: trigger ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  useEffect(() => {
    if (consultancyDetails?.landing?.header[0]) {
      let headerArr = [];
      if (consultancyDetails?.landing?.header[0][language]) {
        headerArr = Object.values(
          consultancyDetails?.landing?.header[0][language]
        );
      } else if (consultancyDetails?.landing?.header[0][locale]) {
        headerArr = Object.values(
          consultancyDetails?.landing?.header[0][locale]
        );
      }

      setHeader(headerArr);
    }

    setTimeout(() => {
      setTrigger(true);
    }, 600);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div>
      <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <animated.div
            key={header[index]}
            className={classes.flipText}
            style={{
              ...rest,
              transform: x.interpolate((x) => `translate3d(0,${x}px,0)`),
              color:
                consultancyDetails?.landing?.header?.[0]?.colour || '#ffffff',
              fontFamily:
                consultancyDetails?.landing?.header[0]?.headerFont
                  ?.fontFamily || null,
            }}
          >
            <animated.div style={{ height }}>{header[index]}</animated.div>
          </animated.div>
        ))}
      </div>
    </div>
  );
}
