import axios from '../utils/axios';

export const getPageData = (consultancyId) => {
  return axios.get('/LandingPages/getPageData', {
    params: {
      consultancyId,
    },
  });
};

export const toggleDisplayContent = (consultancyId, fieldName) => {
  return axios.put('/LandingPages/toggleDisplayContent', {
    consultancyId,
    fieldName,
  });
};

export const updateContentBlock = (consultancyId, fieldName, data) => {
  return axios.put('/LandingPages/updateContentBlock', {
    consultancyId,
    fieldName,
    data,
  });
};

export const uploadAsset = (uploadOptions, asset) => {
  return axios
    .post('/LandingPageAssets/uploadAsset', asset, uploadOptions)
    .then((response) => {
      if (
        response?.data !== null &&
        response?.data !== undefined &&
        response.status === 200
      ) {
        return response.data;
      } else {
        throw Error('Failed to upload asset');
      }
    });
};

export const getAssetUrls = (landingPageId) => {
  return axios.get('LandingPageAssets/getAssetUrls', {
    params: {
      landingPageId,
    },
  });
};

export const deleteAsset = (landingPageId, fieldName) => {
  return axios.delete('/LandingPageAssets/deleteAsset', {
    params: {
      landingPageId,
      fieldName,
    },
  });
};
