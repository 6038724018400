import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Box,
	Button,
	Container,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import cx from "clsx";
import SectionHeader from "../SectionHeader";
import ParallaxSlide from "./ParallaxSlide";
import DotIndicator from "./DotIndicator";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SlideImg from "../../../assets/images/slide.png";

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
	slideContainer: {
		position: "relative",
		width: "100%",
	},
	slide: {
		perspective: 1000,
		overflow: "hidden",
		position: "relative",
		paddingTop: spacing(8),
		[breakpoints.up("sm")]: {
			paddingTop: spacing(10),
		},
		[breakpoints.up("md")]: {
			paddingTop: spacing(14),
		},
	},
	imageContainer: {
		display: "block",
		position: "relative",
		zIndex: 2,
		paddingBottom: "56.25%",
	},
	image: {
		display: "block",
		position: "absolute",
		zIndex: 10,
		width: "100%",
		height: "100%",
		objectFit: "cover",
		marginLeft: "12%",
		[breakpoints.up("sm")]: {
			marginLeft: "4%",
		},
	},
	arrow: {
		display: "inline-flex",
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
		[breakpoints.down("lg")]: {
			display: "none",
		},
	},
	arrowLeft: {
		left: 0,
	},
	arrowRight: {
		right: 0,
	},
	dot: {
		border: "none",
		backgroundColor: "transparent",
		margin: "1.5rem .6rem 0",

		"&:focus": {
			outline: "none",
		},
	},
	title: {
		lineHeight: "normal",
	},
}));

const CardCarousel = ({ consultancyDetails, consultancyColour, assetUrls }) => {
	const classes = useStyles();
	const mobile = useMediaQuery("(max-width:1280px)");

	const data = consultancyDetails.landing?.slides[0];
	const content = data ? Object.values(data) : null;

	const [images, setImages] = useState([
		{
			1: "",
		},
		{
			2: "",
		},
		{
			3: "",
		},
		{
			4: "",
		},
		{
			5: "",
		},
	]);

	useEffect(() => {
		if (assetUrls) {
			setImages([
				{
					1: assetUrls.slide_1,
				},
				{
					2: assetUrls.slide_2,
				},
				{
					3: assetUrls.slide_3,
				},
				{
					4: assetUrls.slide_4,
				},
				{
					5: assetUrls.slide_5,
				},
			]);
		}
	}, [assetUrls]);

	const defaultData = [
		{
			id: 1,
			title: "No messy print-outs or endless email threads",
			subtitle:
				"View your entire recruitment campaign and the shortlisted candidate profiles from virtually any internet-enabled device.",
			image: SlideImg,
		},
		{
			id: 2,
			title: "No messy print-outs or endless email threads",
			subtitle:
				"View your entire recruitment campaign and the shortlisted candidate profiles from virtually any internet-enabled device.",
			image: SlideImg,
		},
		{
			id: 3,
			title: "No messy print-outs or endless email threads",
			subtitle:
				"View your entire recruitment campaign and the shortlisted candidate profiles from virtually any internet-enabled device.",
			image: SlideImg,
		},
	];

	const renderElements = ({ index, onChangeIndex }) => (
		<>
			<Button
				className={cx(classes.arrow, classes.arrowLeft)}
				disabled={index === 0}
				onClick={() => onChangeIndex(index - 1)}
			>
				<KeyboardArrowLeft style={{ fontSize: "96px" }} />
			</Button>
			<Button
				className={cx(classes.arrow, classes.arrowRight)}
				disabled={index === content.length - 2}
				onClick={() => onChangeIndex(index + 1)}
			>
				<KeyboardArrowRight style={{ fontSize: "96px" }} />
			</Button>
			<div style={{ textAlign: "center" }}>
				{content.slice(0, content.length - 1).map(({ id }, i) => (
					<DotIndicator
						key={id}
						active={i === index}
						onClick={() => onChangeIndex(i)}
						className={classes.dot}
						consultancyColour={consultancyColour}
					/>
				))}
			</div>
		</>
	);

	return (
		<Box
			width="100%"
			style={{
				backgroundColor: "white",
				paddingBottom: "4rem",
			}}
		>
			<Container maxWidth="xl" disableGutters>
				<SectionHeader
					colour={
						consultancyDetails
							? consultancyDetails.theme.heading
							: consultancyColour
					}
				>
					{content ? content[0].header : "Benefits"}
				</SectionHeader>
				{consultancyDetails ? (
					<div className={classes.slideContainer}>
						<ParallaxSlide renderElements={renderElements}>
							{({ injectStyle }) =>
								content.slice(1).map(({ title, subtitle, id }, i) => (
									<Box
										key={id}
										display="flex"
										flexDirection="column"
										width="100%"
									>
										<Box>
											<Box
												width="100%"
												height="auto"
												maxHeight={mobile ? 300 : null}
											>
												<img
													src={images[i][id]}
													alt=""
													style={{
														maxWidth: mobile ? "90vw" : "100%",
														height: mobile ? "auto" : 500,
														maxHeight: "100%",
														display: "block",
														margin: "auto",
													}}
												/>
											</Box>
										</Box>
										<Box marginY="1rem">
											<Typography
												className={classes.title}
												noWrap={mobile ? false : true}
												align={"center"}
												variant={mobile ? "h2" : "h1"}
												style={mobile ? null : injectStyle(i, 400)}
											>
												{title}
											</Typography>
										</Box>
										<Typography
											noWrap={mobile ? false : true}
											align={"center"}
											variant={mobile ? "body1" : "h4"}
											style={mobile ? null : injectStyle(i, 160)}
										>
											{subtitle}
										</Typography>
									</Box>
								))
							}
						</ParallaxSlide>
					</div>
				) : null}
				{!consultancyDetails ? (
					<ParallaxSlide>
						{({ injectStyle }) =>
							defaultData.map(({ boxProps, title, subtitle, id, image }, i) => (
								<Box
									key={id}
									display="flex"
									flexDirection="column"
									width="100%"
								>
									<Box>
										<Box
											width="100%"
											height={"460px"}
											maxHeight={mobile ? "300px" : null}
										>
											<img
												src={image}
												alt=""
												style={{
													maxHeight: "100%",
													maxWidth: "100%",
													height: "auto",
													display: "block",
													margin: "auto",
												}}
											/>
										</Box>
									</Box>
									<Box marginY="1rem">
										<Typography
											noWrap={mobile ? false : true}
											align={"center"}
											variant={mobile ? "h2" : "h1"}
											style={mobile ? null : injectStyle(i, 400)}
										>
											{title}
										</Typography>
									</Box>
									<Typography
										noWrap={mobile ? false : true}
										align={"center"}
										variant={mobile ? "body1" : "h4"}
										style={mobile ? null : injectStyle(i, 160)}
									>
										{subtitle}
									</Typography>
								</Box>
							))
						}
					</ParallaxSlide>
				) : null}
			</Container>
		</Box>
	);
};

export default CardCarousel;
