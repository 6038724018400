import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  Button,
  FormControl,
  colors,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import validate from "validate.js";
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import { useSelector } from "react-redux";
import { useSort } from "src/components/Filter/Sort";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
}));

function AddExistingCandidateModal({
  onSuccess,
  consultancyId,
  open,
  handleClose,
}) {
  const classes = useStyles();

  const [t] = useTranslation(["candidates", "common"]);

  const schema = {
    selectedCandidates: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const availableCandidates = useSelector(
    (state) => state.campaign.availableCampaignCandidates
  );

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      consultancyId,
      selectedCandidates: [],
    },
    touched: {},
    errors: {},
  });

  const handleCandidateChange = (event, value) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        selectedCandidates: [value],
      },
      touched: {
        ...prevFormState.touched,
        selectedCandidates: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const onSubmit = function(event) {
    event.preventDefault();
    onSuccess(formState.values).then(() => {
      setFormState({
        isValid: false,
        values: {
          consultancyId,
          selectedCandidates: [],
        },
        touched: {},
        errors: {},
      });
    });
  };

  useSort(
    availableCandidates,
    "Ascending",
    "name",
    true,
    "candidates"
  ).sortData();

  return (
    <ModalFormWrapper
      formTitle={t("candidates:addCandidateToCampaign")}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth="md"
    >
      <form onSubmit={onSubmit}>
        <div className={classes.fields}>
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.formControl}
          >
            <Autocomplete
              id="candidateAutoComplete"
              options={availableCandidates}
              getOptionLabel={(option) =>
                option.name ? option.name + " - " + option.email : ""
              }
              value={formState?.values?.selectedCandidates[0]}
              onChange={handleCandidateChange}
              style={{ width: "100%" }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    fullWidth
                    label={t("candidates:selectCandidates")}
                    variant="outlined"
                  />
                );
              }}
            />
          </FormControl>
        </div>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          {t("candidates:addToCampaign")}
        </Button>
      </form>
    </ModalFormWrapper>
  );
}
export default AddExistingCandidateModal;
