import hotjar from '@hotjar/browser';

import * as consultancyApi from './api/consultancyApi';

export const intializeHotjar = () => new Promise((resolve) => {
  consultancyApi.getHotjarDataByConsultancyHost(window.location.hostname)
    .then((response) => {
      if (!response.data) {
        resolve();
        return;
      }

      hotjar.init(response.data.siteId, response.data.hotjarVersion);
      resolve();
    })
    .catch((error) => {
      console.log(error);
      resolve();
    });
});
