import * as actionTypes from "src/actions";

const initialState = {
};

const employerReducer = (state = initialState, action) => {
  switch (action.type) {
      case actionTypes.UPDATE_CONTACT_DETAILS: {
        return {
            ...state, 
        };
      }
    default: {
      return state;
    }
  }
};

export default employerReducer;
 