import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as ContactApi from 'src/api/contactApi';
import * as ConsultancyApi from './../../api/consultancyApi';
import CreateWelcomeMessagesCard from '../WelcomeMessages/CreateWelcomeMessagesCard';
import TermsAndConditionsAcceptanceModal from '../CandidateDashboard/TermsAndConditionAcceptanceModal';
import { PageTitle } from 'src/components/LayoutComponents/PageTitle';
import { ContentWrapper } from 'src/components/LayoutComponents/ContentWrapper';
import { ContactCampaignCard } from 'src/components/Cards/ContactCampaignCard';
import logoPlaceholder from 'src/assets/images/placeholder.png';

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  gridRow: {
    marginTop: "20px",
  },
}));

function ContactDashboard() {
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation(["campaigns", "common", "snackbar", "vacancies"]);
  const { consultancyId, consultancySubscriptionPlanId } = useSelector((state) => state.session);
  const [campaigns, setCampaigns] = useState();
  const [consultancyLogo, setConsultancyLogo] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});

  const handleTermsAndConditionsModalOpen = () => {
    setTermsAndConditionsOpen(true);
  };

  const handleTermsAndConditionsModalClose = () => {
    setTermsAndConditionsOpen(false);
  };

  const getContactCampaigns = (consultancyId) => {
    return ContactApi.getContactCampaigns(consultancyId)
      .then((response) => {
        setCampaigns(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const goToCampaign = (event, campaign) => {
    event.preventDefault();
    if(campaign.campaignTypeId === 1) {
      if(campaign.acceptedTCs) {
        const url = consultancySubscriptionPlanId === TA_PRO ?
          `/dashboard/vacancies/${campaign.campaignId}` :
          `/dashboard/campaigns/${campaign.campaignId}`;

        history.push(url);
        return;
      }
      showTermsAndConditions(event, campaign);
    } else {
      if(campaign.acceptedTCs) {
        history.push({
          pathname: `/dashboard/talent-alerts/${campaign.campaignId}`,
            state: {
              previousPath: history.location.pathname
            }
        });
        return;
      }
      history.push(`/terms-and-conditions/${campaign.campaignId}`);
    }
  };

  const onTermsAndConditionsAcceptance = () => {
    return ContactApi.acceptCampaignTermsAndConditions(
      Number(consultancyId),
      selectedCampaign.campaignId
    )
      .then(() => {
        enqueueSnackbar(`${t("snackbar:termsAndConditionsAcceptedSuccess")}`, {
          variant: "success",
        });

        const url = consultancySubscriptionPlanId === TA_PRO ?
          `/dashboard/vacancies/${selectedCampaign.campaignId}` :
          `/dashboard/campaigns/${selectedCampaign.campaignId}`;

        history.push(url);
      })
      .catch((error) => {
        enqueueSnackbar(`${t("snackbar:termsAndConditionsAcceptedFailure")}`, {
          variant: "error",
        });
      });
  };

  const showTermsAndConditions = (event, campaign) => {
    event.preventDefault();
    setSelectedCampaign(campaign);
    handleTermsAndConditionsModalOpen();
  };

  useEffect(() => {
    getContactCampaigns(consultancyId);
    
    ConsultancyApi.getConsultancyLogo(consultancyId)
      .then((response) => {
        setConsultancyLogo(() => {
          if (response.data.consultancyLogo) {
            return response.data.consultancyLogo;
          }

          return logoPlaceholder;
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle pageTitle={t(`${campaignsPrefix}:campaigns`)} />

      <ContentWrapper>
        <p style={{ paddingLeft: "10px" }}>
          {t(`${campaignsPrefix}:contactCampaignList`)}
        </p>
        <CreateWelcomeMessagesCard />
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridRow}>
            <Grid container spacing={3}>
              {campaigns?.map((campaign) => (
                campaign.campaignTypeId && (
                  <Grid item xs={12} sm={6} md={4} key={campaign.id}>
                    <ContactCampaignCard
                      campaignDetails={campaign}
                      btnFunc={(event) =>
                        goToCampaign(event, campaign)
                      }
                      consultancyLogo={consultancyLogo}
                    />
                  </Grid>
                )
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>
      <TermsAndConditionsAcceptanceModal
        onSuccess={onTermsAndConditionsAcceptance}
        open={termsAndConditionsOpen}
        handleClose={handleTermsAndConditionsModalClose}
        campaignTC={selectedCampaign.campaignTC || ""}
      />
    </div>
  );
}

export default ContactDashboard;
