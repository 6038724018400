import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import * as ContactApi from "src/api/contactApi";
import { useHistory } from 'react-router';
import { useTranslation } from "react-i18next";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import CreateContactForm from '../../components/CreateContactForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%'
  }
}));

function CreateContactModal({ onSuccess, consultancyId, employerId, open, handleClose }) {
  const classes = useStyles();
  const history = useHistory();

  const [t,] = useTranslation(["candidates", "common"]);

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = function(data) {
    const { contactDetails } = data;
    contactDetails.consultancyId = consultancyId;

    return ContactApi.createContact(contactDetails)
    .then((response)=>{
      history.push(`/dashboard/employers/${employerId}/contacts/${response.data.id}`);
      enqueueSnackbar(`${t('snackbar:createNewContact')}`, { variant: "success" });
      onSuccess(response.data);
    })
    .catch((error)=>{
      enqueueSnackbar(`${t('snackbar:failedToCreateNewContact')}`, { variant: "error" });
    })
    .then(() => {
      handleClose();
    });
  };


  return (
    <ModalFormWrapper
      formTitle={t('common:createContact')}
      handleOpen={open}
      handleClose={handleClose}
    >
      <CreateContactForm
        onSubmit={onSubmit}
        consultancyId={consultancyId}
        employerId={employerId}
        className={classes.form}
      />
    </ModalFormWrapper>
  );
}
export default CreateContactModal;
