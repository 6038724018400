import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import parse from 'html-react-parser';

import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, Grid, useMediaQuery } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from './../../api/talentAlertApi';

import { CANDIDATE } from './../../constants/roles';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    margin: theme.spacing(2),
    width: '33%'
  },
  progressCircle: {
    color: theme.palette.primary.contrastText
  },
  buttonContainer: {
    textAlign: 'center'
  },
  cardContent: {
    height: '500px',
    overflowY: 'auto'
  },
  header: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '45vh',
    // boxShadow: 'inset 2000px 0 0 0 rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center'
  },
  terms: {
    marginTop: '-200px',
    marginBottom: '150px',
    margin: 'auto'
  },
  logo: {
    maxWidth: '270px'
  }
}));

const TalentAlertContactTermsAndConditions = ({ campaignId, consultancyId }) => {

  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation(['common']);
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width:767px)');

  const [termsAndConditions, setTermsAndConditions] = useState();
  const [header, setHeader] = useState();
  const [logo, setLogo] = useState('');
  const [campaignLogo, setCampaignLogo] = useState('');

  const { roleType } = useSelector((state) => state.session);

  useEffect(() => {
    if (roleType === CANDIDATE) {
      talentAlertApi.getCandidateTalentAlertForTCs(consultancyId, campaignId)
        .then((response) => {
          setTermsAndConditions(response.data.termsAndConditions);
          setHeader(response.data.headerImg);
          setLogo(response.data.consultancyLogo);
          setCampaignLogo(response.data.campaignLogo);
        })
        .catch((error) => {
          console.log(error);
        });

      return;
    }

    talentAlertApi.getContactTalentAlertById(campaignId, consultancyId)
      .then((response) => {
        setTermsAndConditions(response.data.termsAndConditions);
        setHeader(response.data.headerImg);
        setLogo(response.data.consultancyLogo);
        setCampaignLogo(response.data.campaignLogo);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTermsAndConditionsAcceptance = () => {
    talentAlertApi.acceptCampaignTermsAndConditions(consultancyId, campaignId)
      .then(() => {
        history.push(`/dashboard/talent-alerts/${campaignId}`);
      })
      .catch(() => {
        enqueueSnackbar(`${t('snackbar:termsAndConditionsAcceptedFailure')}`, { variant: 'error' });
      });
  };

  const getHeaderLogoSrc = () => {
    if (campaignLogo) {
      return campaignLogo;
    }
    
    return logo;
  };

  const renderTermsAndConditions = () => {
    return (
      <Card>
        <CardContent className={ classes.cardContent }>
          <div class="ql-editor">
            { parse(termsAndConditions || "") }
          </div>
        </CardContent>
  
        <div className={ classes.buttonContainer }>
          <Button
            className={ classes.submitButton }
            color="primary"
            size="large"
            onClick={ onTermsAndConditionsAcceptance }
            variant="contained" >
            { t("common:accept") }
          </Button>
        </div>
      </Card>
    );
  };

  return (
    <Grid container>
      <Grid
        item
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.header}
        style={{ backgroundImage: `url(${header})` }} >
        <img src={ getHeaderLogoSrc() } alt="" className={classes.logo} />
      </Grid>

      {
        isMobile ? (
          renderTermsAndConditions()
        ) : (
          <Grid item xs={ 6 } className={ classes.terms }>
            { renderTermsAndConditions() }
          </Grid>
        )
      }
    </Grid >
  );

};

export default TalentAlertContactTermsAndConditions;
