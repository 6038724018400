import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppBar, Box, Card, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as systemAdminApi from './../../api/SystemAdminApi';
import * as talentAlertApi from './../../api/talentAlertApi';

import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import { TabPanel } from './../../components/TabPanel';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';
import { CampaignCard } from './../../components/Cards/CampaignCard';
import CreateTalentAlertModal from './../Campaigns/CampaignList/CreateTalentAlertModal';

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

import iintroLogo from './../../assets/images/logos/i-intro_logo.png';
import taLogo from './../../assets/images/logos/i-intro_ta_subscription_logo.png';
import taProLogo from './../../assets/images/logos/i-intro_ta_pro_subscription_logo.png';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0% 5%'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  filterCard: {
    width: '25%',
    padding: 0,
    marginBottom: theme.spacing(3)
  },
  filterCardBox: {
    minHeight: '56px',
    display: 'flex',
    alignItems: 'center'
  },
  filterWrapper: {
    width: '100%'
  }
}));

const TalentAlertConsultant = ({ ...props }) => {

  const classes = useStyles();
  const [t] = useTranslation(['campaigns', 'common', 'newTranslations']);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [campaignLogo, setCampaignLogo] = useState('');
  const [currentTab, setCurrentTab] = useState(0);
  const [talentAlertOpen, setTalentAlertOpen] = useState(false);

  const [myCampaigns, setMyCampaigns] = useState([]);
  const [filteredMyCampaigns, setFilteredMyCampaigns] = useState([]);
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [filteredAllCampaigns, setFilteredAllCampaigns] = useState([]);

  const [consultancyUsers, setConsultancyUsers] = useState([]);
  const [filters, setFilters] = useState({
    consultancyUsers: []
  });

  const consultancyId = useSelector((state) => props?.consultancyId ? props.consultancyId : state.session.consultancyId);
  const userRole = useSelector((state) => state.session.roleType);
  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const consultantTabs = [
    { value: 0, label: `${t('newTranslations:myTalentAlerts')}` }
  ];

  const adminTabs = [
    { value: 0, label: `${t('newTranslations:myTalentAlerts')}` },
    { value: 1, label: `${t('newTranslations:allTalentAlerts')}` }
  ];

  useEffect(() => {
    talentAlertApi.getTalentAlertCampaigns(consultancyId)
      .then((response) => {
        const myCampaignsCorrectOrder = response.data.personalTalentAlertCampaigns.reverse();
        const allCampaignsCorrectOrder = response.data.allTalentAlertCampaigns.reverse();

        setMyCampaigns(myCampaignsCorrectOrder);
        setFilteredMyCampaigns(myCampaignsCorrectOrder);
        setAllCampaigns(allCampaignsCorrectOrder);
        setFilteredAllCampaigns(allCampaignsCorrectOrder);
      })
      .catch((error) => {
        console.log(error);
      });
    
    talentAlertApi.getConsultancyLogo(consultancyId)
      .then((response) => {
        setCampaignLogo(() => {
          if (response.data.consultancyLogo) {
            return response.data.consultancyLogo;
          }

          switch (consultancySubscriptionPlanId) {
            case subscriptionPlansIds.TA:
              return taLogo;
            case subscriptionPlansIds.TA_PRO:
              return taProLogo;
            default:
              return iintroLogo;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });

    systemAdminApi.getConsultancyUsers(consultancyId)
      .then((response) => {
        const sortedConsultancyUsers = [...response.data.usersAndGroups.admins, ...response.data.usersAndGroups.consultants]
          .map((user, index) => {
            if (index < response.data.usersAndGroups.admins.length) {
              return {
                ...user,
                role: 'admin'
              };
            }

            return {
              ...user,
              role: 'consultant'
            };
          })
          .sort((userA, userB) => {
            if (userA.name > userB.name) return 1;
            if (userA.name < userB.name) return -1;
            return 0;
          });

        setConsultancyUsers(sortedConsultancyUsers);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [consultancyId, consultancySubscriptionPlanId]);

  const toggleTalentAlert = () => {
    setTalentAlertOpen((prevState) => !prevState);
  };

  const handleChange = (event, value) => {
    setCurrentTab(value);
  };

  const onTalentAlertSuccess = (talentAlert) => {
    talentAlertApi.createTalentAlertCampaign(talentAlert.consultancyId, talentAlert.formValues.campaignTitle)
      .then((response) => {
        setMyCampaigns((prevMyCampaigns) => [
          ...prevMyCampaigns,
          response.data
        ]);

        setTalentAlertOpen(false);
        enqueueSnackbar(t('campaigns:talentAlertCreationSuccess'), { variant: 'success' });
        history.push(`/dashboard/talent-alerts/${response.data.id}`);
      })
      .catch(() => {
        enqueueSnackbar(t('campaigns:talentAlertCreationFailed'), { variant: 'error' });
      });
  };

  const goToCampaignDetails = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/talent-alerts/${campaignId}`);
  };

  const getCampaignDetails = (campaign) => {
    const campaignCreatedAt = new Date(campaign.createdAt);
    const formattedDate = `${campaignCreatedAt.getDate()}/${campaignCreatedAt.getMonth() + 1}/${campaignCreatedAt.getFullYear()}`;
    const campaignStatus = campaign.campaignStatus ? campaign.campaignStatus.status : 'Draft';
    const campaignCandidates = campaign.candidates ? campaign.candidates.length : 0;
    const status = t('campaigns:campaignStatusLabel');
    const dateStarted = t('campaigns:dateStarted');
    const candidateLabel = t('campaigns:candidatesLabel');

    const campaignDetails = [
      { name: status, data: campaignStatus },
      { name: dateStarted, data: formattedDate },
      { name: candidateLabel, data: campaignCandidates }
    ];

    return campaignDetails;
  };

  const userFilter = (users, campaign) => {
    let didMatchUser = false;

    for (const user of users) {
      if (campaign.managedByRole !== user.role) {
        continue;
      }

      if (user.role === 'admin' && user.userId === campaign.admin.userId) {
        didMatchUser = true;
        break;
      }

      if (user.role === 'consultant' && user.userId === campaign.consultant.userId) {
        didMatchUser = true;
        break;
      }
    }

    return didMatchUser;
  };

  const applyFilters = (filters) => {
    const tempMyCampaigns = myCampaigns.filter((campaign) => {
      let matches = true;

      for (const key of Object.keys(filters)) {
        const value = filters[key];

        if (value.length) {
          switch (key) {
            case 'consultancyUsers':
              matches = userFilter(value, campaign);
              break;
            default:
              matches = true;
          }
        }
      }

      return matches;
    });

    setFilteredMyCampaigns([ ...tempMyCampaigns ]);

    const tempAllCampaigns = allCampaigns.filter((campaign) => {
      let matches = true;

      for (const key of Object.keys(filters)) {
        const value = filters[key];

        if (value.length) {
          switch (key) {
            case 'consultancyUsers':
              matches = userFilter(value, campaign);
              break;
            default:
              matches = true;
          }
        }
      }

      return matches;
    });

    setFilteredAllCampaigns([ ...tempAllCampaigns ]);
  };

  const handleFilterChange = (event) => {
    let updatedFilters = { };

    setFilters((prevValues) => {
      updatedFilters = {
        ...prevValues,
        [event.target.name]: event.target.value
      };

      return { ...updatedFilters };
    });

    applyFilters(updatedFilters);
  };
  
  const getConsultancyUsersFilterRenderValue = (selectedUsers) => {
    const result = selectedUsers
      .map((u) => u.name)
      .join(', ');

    return result;
  };

  const getConsultancyUsersFilterItemText = (user) => {
    return `${user.name} (${user.email})`;
  };

  const renderAdminAppBar = () =>  (
    <Tabs value={currentTab} onChange={handleChange} variant="scrollable" textColor="primary" >
        
      {
        adminTabs.map((tab) => (
          <Tab
            key={tab.value}
            label={t(`campaigns:${tab.label}`)}
            value={tab.value}
          />
        ))
      }

    </Tabs>
  );

  const renderConsultantAppBar = () => (
    <Tabs
      value={currentTab}
      onChange={handleChange}
      variant="scrollable"
      textColor="primary"
      className={classes.tabBar} >
      
      {
        consultantTabs.map((tab) => (
          <Tab
            key={tab.value}
            label={t(`campaigns:${tab.label}`)}
            value={tab.value} />
        ))
      }

    </Tabs>
  );

  const renderCampaignCard = (campaign) => (
    <Grid key={campaign.id} item md={4} sm={6} xs={12}>
      <CampaignCard
        campaignDetails={getCampaignDetails(campaign)}
        name={campaign.campaignTitle}
        btnLabel={t("common:viewMore")}
        btnFunc={(event) => { goToCampaignDetails(event, campaign.id); }}
        campaignLogo={campaignLogo} />
    </Grid>
  );

  return (
    <>
      <PageTitle
        pageTitle={t("campaigns:talentAlerts")}
        btnLabel={t("campaigns:createTalentAlert")}
        btnFunc={toggleTalentAlert} />

      <ContentWrapper>
        <Card className={ classes.filterCard }>
          <Box
            p={ 2 }
            className={ classes.filterCardBox }>
            <div className={ classes.filterWrapper }>
              <FormControl
                variant="outlined"
                className={ classes.formControl }
                fullWidth >
                <InputLabel id="demo-simple-select-autowidth-label">
                  { t("common:filterRecruiter") }
                </InputLabel>
  
                <Select
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  name={ "consultancyUsers" }
                  multiple={ true }
                  value={ filters.consultancyUsers }
                  onChange={ handleFilterChange }
                  renderValue={ getConsultancyUsersFilterRenderValue }
                  inputProps={{ "aria-label": "Without label" }}
                  displayEmpty >
                  {
                    consultancyUsers?.map((user) => (
                      <MenuItem key={ user.email } value={ user }>
                        <Checkbox checked={ filters.consultancyUsers.includes(user) } ></Checkbox>
                        <ListItemText primary={ getConsultancyUsersFilterItemText(user) }></ListItemText>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </Box>
        </Card>

        <AppBar position="static" className={classes.appBar}>
          {
            userRole === "Admin" ? renderAdminAppBar() : renderConsultantAppBar()
          }
        </AppBar>

        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={3}>
            {
              filteredMyCampaigns.map((campaign) => renderCampaignCard(campaign))
            }
          </Grid>
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={3}>
            {
              filteredAllCampaigns.map((campaign) => renderCampaignCard(campaign))
            }
          </Grid>
        </TabPanel>
      </ContentWrapper>
      
      <CreateTalentAlertModal
        onTalentAlertSuccess={onTalentAlertSuccess}
        consultancyId={consultancyId}
        open={talentAlertOpen}
        handleClose={toggleTalentAlert} />
    </>
  );

};

export default TalentAlertConsultant;
