import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveIcon from "@material-ui/icons/Save";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";
import { SET_KCQ_CATEGORIES } from "src/actions/ConsultancyModules/kcq/kcqActions";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import {
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Typography,
    TextField,
    Checkbox
} from "@material-ui/core";

import validate from "validate.js";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        padding: 10,
    },
    newQuestion: {
        padding: "36px"
    },
    textCenter: {
        display: 'flex',
        alignItems: 'center'
    },
    innerRow: {
        display: 'flex',
        paddingLeft: '24px'
    },
    column: {
        flexDirection: 'column'
    },
    fields: {
        margin: theme.spacing(-1),
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            flexGrow: 1,
            margin: theme.spacing(1),
        },
    },
    addButton: {
        flex: 1,
        margin: 5,
    },
    header: {
        padding: 24,
    },
    submitButton: {
        marginTop: theme.spacing(2),
        width: "100%",
    },
    categoriesTitle: {
        marginTop: "10px",
        marginBottom: "20px",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        marginBottom: theme.spacing(2)
    },
    addCategoryButton: {
        marginLeft: "10px"
    }
}));
const schema = {
    name: {
        presence: { allowEmpty: false, message: "is required" },
    },
};

const nameInput = React.createRef();
const categoryNameInput = React.createRef();


function CreateQuestionSetModal({
    onSuccess,
    open,
    handleClose,
    modalTitle,
    title,
    subtitle,
    buttonText,
    chosenSet
}) {
    const [t] = useTranslation(["snackbar"]);

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const kcqCategories = useSelector((state) => state.kcq.kcqCategories);
    const categoriesFlags = {};
    const [customCategoriesFlags, setCustomCategoriesFlags] = useState([]);
    const [formState, setFormState] = useState({
        isValid: false,
        values: {
        },
        touched: {},
        errors: {},
    });

    const [checkedCategories, setCheckedCategories] = useState({})
    const [checkedQuestions, setCheckedQuestions] = useState({})
    const [newQuestions, setNewQuestions] = useState({})

    const [customCategories, setNewCustomCategories] = useState([])
    const addQuestion = (category, inputItem) => {
        category.questions.push(inputItem.current.value)
        const cleanKcqCategories = kcqCategories.filter((el) => {
            return el.id !== category.id
        })
        dispatch({ type: SET_KCQ_CATEGORIES, categories: [...cleanKcqCategories, { ...category }] });
        enqueueSnackbar(t("snackbar:kcqCategoryUpdateSuccess"), {
            variant: "success",
        });
        inputItem.current.value = ""
    }





    kcqCategories.forEach((category, index) => {
        categoriesFlags[index] = React.createRef();
    })


    const addCategory = () => {
        const categoryName = categoryNameInput.current.value;
        categoryNameInput.current.value = "";
        customCategoriesFlags[customCategoriesFlags.length] = React.createRef();
        setCustomCategoriesFlags(customCategoriesFlags)

        setNewCustomCategories([...customCategories, {
            hasDefault: false,
            isDefault: false,
            name: categoryName,
            questions: []
        }])
    }

    const hasError = (field) => {
        if (formState.touched[field] && formState.errors[field]) {
            return true;
        } else {
            return false;
        }
    };

    const onSubmit = (event) => {
        event.preventDefault();
        const selectedQuestions = [];
        Object.keys(checkedCategories).forEach((cat, catIndex) => {
            selectedQuestions.push({ ...kcqCategories[parseInt(cat)] })
            if (checkedCategories[cat]) {
                selectedQuestions[catIndex].questions = []
                if (checkedQuestions[parseInt(cat)]) {
                    Object.keys(checkedQuestions[parseInt(cat)]).forEach((qu, quIndex) => {
                        if (checkedQuestions[parseInt(cat)][parseInt(qu)]) {
                            const correctObject = selectedQuestions[catIndex]
                            correctObject.questions.push(kcqCategories[parseInt(cat)].questions[parseInt(qu)])
                        }
                    })
                }
            }
        })

        customCategories.forEach((cat) => {
            selectedQuestions.push(cat)
        })

        const name = nameInput.current.value;
        setCustomCategoriesFlags([])
        setFormState({
            isValid: false,
            values: {
            },
            touched: {},
            errors: {},
        })
        setCheckedCategories({})
        setCheckedQuestions({})
        setNewQuestions({})

        setNewCustomCategories([])
        onSuccess({
            name: name,
            questions: selectedQuestions,
        });
    };

    const addCustomQuestion = (category, categoryIndex) => {
        const newCustomCategories = customCategories.map((innerCat) => {
            if (innerCat === category) {
                return { ...innerCat, questions: [...innerCat.questions, customCategoriesFlags[categoryIndex].current.value] }
            }
            else {
                return innerCat;
            }
        })
        customCategoriesFlags[categoryIndex].current.value = "";
        setNewCustomCategories(newCustomCategories)

    }


    const handleCategoryCheckbox = (category, categoryIndex) => {
        if (checkedCategories[categoryIndex]) {
            setCheckedCategories((prevCats) => {
                const mutatedObj = { ...prevCats };
                if (mutatedObj[categoryIndex]) {
                    delete mutatedObj[categoryIndex]
                }
                return {
                    ...mutatedObj
                }
            })
            setCheckedQuestions((prevQue) => {
                const mutatedQuestionObj = { ...prevQue };
                mutatedQuestionObj[categoryIndex] = undefined;

                return {
                    ...mutatedQuestionObj
                }
            })

        }
        else {
            setCheckedCategories((prevCats) => {
                const mutatedObj = { ...prevCats };
                mutatedObj[categoryIndex] = true;
                return {
                    ...mutatedObj
                }
            })

            setCheckedQuestions((prevQue) => {
                const mutatedQuestionObj = { ...prevQue };
                category.questions.forEach((que, queIndex) => {
                    if (mutatedQuestionObj[categoryIndex] === undefined) {
                        mutatedQuestionObj[categoryIndex] = {};
                    }
                    mutatedQuestionObj[categoryIndex][queIndex] = true;
                })

                return {
                    ...mutatedQuestionObj
                }
            })
        }
    }

    const handleQuestionCheckbox = (categoryIndex, questionIndex) => {
        if (checkedQuestions[categoryIndex] && checkedQuestions[categoryIndex][questionIndex]) {
            setCheckedQuestions((prevQue) => {
                const mutatedObj = { ...prevQue };
                mutatedObj[categoryIndex][questionIndex] = false;
                return {
                    ...mutatedObj
                }
            })
        }
        else if (checkedQuestions[categoryIndex]) {
            setCheckedQuestions((prevQue) => {
                const mutatedObj = { ...prevQue };
                if (mutatedObj[categoryIndex] === undefined) {
                    mutatedObj[categoryIndex] = {}
                }
                mutatedObj[categoryIndex][questionIndex] = true;
                return {
                    ...mutatedObj
                }
            })
        }
        else {
            setCheckedCategories((prevCats) => {
                const mutatedObj = { ...prevCats };
                mutatedObj[categoryIndex] = true;
                return {
                    ...mutatedObj
                }
            })
            setCheckedQuestions((prevQue) => {
                const mutatedQuestionObj = { ...prevQue };
                if (mutatedQuestionObj[categoryIndex] === undefined) {
                    mutatedQuestionObj[categoryIndex] = {};
                }
                mutatedQuestionObj[categoryIndex][questionIndex] = true;

                return {
                    ...mutatedQuestionObj
                }
            })
        }
    }

    useEffect(() => {
        if (open) {
            if (chosenSet !== null) {
                const tempSet = [];
                const tempFlags = [];
                let tempSetCount = 0;

                chosenSet.questions.forEach((cat, catIndex) => {
                    if (!cat.id) {
                        tempSet.push(cat);
                        tempFlags[tempSetCount] = React.createRef();
                        tempSetCount +=1;
                    }
                    else {
                        kcqCategories.forEach((category, categoryIndex) => {
                            if (category.id === cat.id) {
                                category.questions.forEach((que, queIndex) => {
                                    if (cat.questions.indexOf(que) !== -1) {
                                        handleQuestionCheckbox(categoryIndex, queIndex)
                                    }
                                })
                            }
                        })
                    }
                })
                setCustomCategoriesFlags(tempFlags);
                setNewCustomCategories(tempSet);
            }
            else{
                setNewCustomCategories([]);
                setCheckedCategories({});
                setCheckedQuestions({});
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chosenSet])

    useEffect(() => {
        if (open) {
            const errors = validate(formState.values, schema);
            setFormState((prevFormState) => ({
                ...prevFormState,
                isValid:
                    !errors &&
                    formState.values.categories.length >= 1,
                errors: errors || {},
            }));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formState.values]);

    return (
        <ModalFormWrapper
            formTitle={modalTitle}
            handleOpen={open}
            handleClose={handleClose}
        >
            <form onSubmit={onSubmit}>
                <Typography variant="h4" className={classes.categoriesTitle}>
                    {title}
                </Typography>
                <TextField
                    fullWidth
                    name="name"
                    variant="outlined"
                    required
                    error={hasError("name")}
                    inputRef={nameInput}
                    defaultValue={chosenSet?.name || ""}
                    helperText={hasError("name") ? formState.errors.name[0] : null}
                />
                <Typography variant="h5" className={classes.categoriesTitle}>
                    {subtitle}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {kcqCategories?.map((category, categoryIndex) => (
                            <ExpansionPanel key={"kcqCategory" + categoryIndex}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Checkbox onChange={(e) => { handleCategoryCheckbox(category, categoryIndex) }} name={category.name} checked={checkedCategories[categoryIndex] === true}></Checkbox>

                                    <Typography className={classes.textCenter} variant="h5">{category.name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.column}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={10} xs={12}>
                                            <Grid>
                                                {category.questions?.map((question, questionIndex) => (
                                                    <Grid key={"kcqCategory" + categoryIndex + "questionIndex" + questionIndex} item xs={12} className={classes.innerRow}>
                                                        <Checkbox
                                                            onChange={() => { handleQuestionCheckbox(categoryIndex, questionIndex) }}
                                                            checked={checkedQuestions[categoryIndex] !== undefined && checkedQuestions[categoryIndex][questionIndex] === true}></Checkbox>
                                                        <Typography variant="h5" className={classes.textCenter}>{question}</Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.newQuestion}>
                                        <Grid item xs={11}>
                                            <TextField
                                                name="name"
                                                fullWidth
                                                variant="outlined"
                                                inputRef={categoriesFlags[categoryIndex]}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value) {
                                                        if (!newQuestions[categoryIndex]) {
                                                            setNewQuestions((prev) => {
                                                                return { ...prev, [categoryIndex]: true }
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        if (newQuestions[categoryIndex]) {
                                                            if (newQuestions[categoryIndex]) {
                                                                setNewQuestions((prev) => {
                                                                    return { ...prev, [categoryIndex]: false }
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}

                                            />
                                        </Grid>

                                        <Grid item xs={1}>
                                            <span onClick={() => { addQuestion(category, categoriesFlags[categoryIndex]) }} style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <ThemeIconButton
                                                    disabled={!newQuestions[categoryIndex]}
                                                    icon={<SaveIcon />}
                                                />
                                            </span>
                                        </Grid>
                                    </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                    </Grid>
                </Grid>

                <Typography variant="h4" className={classes.categoriesTitle}>
                    New category name
                </Typography>

                <div className={classes.row}>
                    <TextField
                        fullWidth
                        name="name"
                        variant="outlined"
                        inputRef={categoryNameInput}
                    />
                    <Button
                        className={classes.addCategoryButton}
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={addCategory}
                    >
                        Add
                    </ Button>
                </div>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {customCategories?.map((category, categoryIndex) => (
                            <ExpansionPanel key={"kcqCategory" + categoryIndex}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography className={classes.textCenter} variant="h5">{category.name}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.column}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={10} xs={12}>
                                            <Grid>
                                                {category.questions?.map((question, questionIndex) => (
                                                    <Grid key={"kcqCategory" + categoryIndex + "questionIndex" + questionIndex} item xs={12} className={classes.innerRow}>
                                                        <Typography variant="h5" className={classes.textCenter}>{question}</Typography>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.newQuestion}>
                                        <Grid item xs={11}>
                                            <TextField
                                                name="name"
                                                fullWidth
                                                variant="outlined"
                                                inputRef={customCategoriesFlags[categoryIndex]}
                                                onChange={(e) => {
                                                    if (e.currentTarget.value) {
                                                        if (!newQuestions[categoryIndex]) {
                                                            setNewQuestions((prev) => {
                                                                return { ...prev, [categoryIndex]: true }
                                                            })
                                                        }
                                                    }
                                                    else {
                                                        if (newQuestions[categoryIndex]) {
                                                            if (newQuestions[categoryIndex]) {
                                                                setNewQuestions((prev) => {
                                                                    return { ...prev, [categoryIndex]: false }
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}

                                            />
                                        </Grid>

                                        <Grid item xs={1}>
                                            <span onClick={() => { addCustomQuestion(category, categoryIndex) }} style={{ display: "flex", flexDirection: "row-reverse" }}>
                                                <ThemeIconButton
                                                    disabled={!newQuestions[categoryIndex]}
                                                    icon={<SaveIcon />}
                                                />
                                            </span>
                                        </Grid>
                                    </Grid>

                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        ))}
                    </Grid>
                </Grid>

                <Button
                    className={classes.submitButton}
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                    // disabled={!formState.isValid}
                    disabled={Object.keys(checkedCategories).length === 0 && customCategories.length === 0}
                >
                    {buttonText}
                </Button>
            </form>
        </ModalFormWrapper >
    );
}
export default CreateQuestionSetModal;
