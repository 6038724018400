import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@material-ui/core";

import * as IntegrationsApi from "./../../api/integrationsApi";

import { Go1LearningObjectCard } from "./../../components/Cards/Go1LearningObjectCard";
import GooneLoginButton from "./../../components/Integragions/GooneLoginButton.js";
import { ContentWrapper } from "./../../components/LayoutComponents/ContentWrapper";
import { PageTitle } from "./../../components/LayoutComponents/PageTitle";

function LearningHub() {
  const [t] = useTranslation(["common", "snackbar"]);

  const [enrollments, setEnrollments] = useState();
  const [didLoadEnrollments, setDidLoadEnrollments] = useState(false);

  useEffect(() => {
    IntegrationsApi.getEnrollments()
      .then(response => {
        if (response.data.hits && response.data.hits.length > 0) {
          response.data.hits.reverse();
        }

        setEnrollments(response.data);
        setDidLoadEnrollments(true);
      }).catch(() => {
        setDidLoadEnrollments(true);
      });
  }, []);

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle pageTitle={t(`newTranslations:retainedrecruiteracademy`)} />
      <ContentWrapper>
        {
          didLoadEnrollments && enrollments?.hits?.length > 0 ?
          <Grid container spacing={3}>
            { enrollments.hits.map(enrollment => (
              <Go1LearningObjectCard
                learningObject={enrollment.lo}
                btnLabel={t("common:play")}
                btnFunc={`/dashboard/retained-recruiter-academy/${enrollment.lo.id}/`}
              />
            ))}
          </Grid>
          : didLoadEnrollments &&
          <GooneLoginButton />
        }
      </ContentWrapper>
    </div>
  );
}

export default LearningHub;
