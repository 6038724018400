import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { ProfileCard } from "src/components/ProfileCard";

function GroupsList({ groupArray }) {
	const history = useHistory();
	const [t] = useTranslation(["common", "users", "groups"]);

	const roleType = useSelector((state) => {
		return state.session.roleType;
	});

	const goToGroupDetails = (event, consultancyId, groupId) => {
		event.preventDefault();
		if (roleType === "CoreAdmin" || roleType === "SystemAdmin") {
			history.push(
				`/dashboard/consultancies/${consultancyId}/groups/${groupId}/details`
			);
		} else {
			history.push(`/dashboard/groups/${groupId}/details`);
		}
	};

	return (
		<>
			{groupArray.map((group, key) => (
				<Grid key={key} item md={4} sm={6} xs={12}>
					<ProfileCard
						name={group.groupName}
						btnFunc={(event) =>
							goToGroupDetails(event, group.consultancyId, group.id)
						}
						btnLabel={t("common:view")}
					/>
				</Grid>
			))}
		</>
	);
}

export default GroupsList;
