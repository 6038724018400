import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Typography,
	Checkbox,
	FormControlLabel,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		padding: theme.spacing(1),
		"& > *": {
			marginBottom: theme.spacing(1),
		},
	},
	noPadding:{
		width: '100%',
		paddingLeft: 0,
		paddingRight: 0,
		"& > *": {
			marginBottom: theme.spacing(1),
		},
	}
}));

function DefaultKCQSelect({ categories, handleQuestionChange , padding }) {
	const classes = useStyles();

	const [selectedQuestions, setSelectedQuestions] = useState([]);

	const onQuestionClick = (event, categoryId) => {
		let selectedQuestionsTemp = [...selectedQuestions];
		let selectedCategoryIndex = selectedQuestionsTemp.findIndex(
			(selectedCategory) => selectedCategory.id === categoryId
		);
		let selectedQuestion;
		
		if (selectedCategoryIndex <= -1) {
			let selectedQuestionTemp = {
				...categories.find((category) => category.id === categoryId),
			};
			selectedQuestionTemp.questions = [];
			selectedQuestionsTemp.push(selectedQuestionTemp);
			selectedQuestion = selectedQuestionTemp;
		} else {
			selectedQuestion = selectedQuestionsTemp[selectedCategoryIndex];
		}

		let questions = [...selectedQuestion.questions];

		let questionIndex = questions.findIndex(
			(question) => question === event.target.name
		);
		
		if (questionIndex > -1) {
			questions.splice(questionIndex, 1);
			selectedQuestion.questions = [...questions];
			selectedQuestionsTemp[selectedCategoryIndex] = selectedQuestion;
			setSelectedQuestions((prevValues) => [...selectedQuestionsTemp]);
		} else {
			questions.push(event.target.name);
			selectedQuestion.questions = [...questions];
			selectedQuestionsTemp[selectedCategoryIndex] = selectedQuestion;
			setSelectedQuestions((prevValues) => [...selectedQuestionsTemp]);
		}
		
		handleQuestionChange(selectedQuestionsTemp);
	};

	const renderDefaultKCQCategories = () => {
		return (
			<div className={!padding? classes.noPadding: classes.root}>
				{categories.map((kcqCategory, categoryIndex) => (
					<>
						{kcqCategory.questions.length ? (
							<ExpansionPanel
								style={{
									boxShadow: "none",
									border: "1px solid #BDBDBD",
								}}
							>
								<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
									<Typography variant="h5">{kcqCategory.name}</Typography>
								</ExpansionPanelSummary>
								<ExpansionPanelDetails>
									<Grid container spacing={3}>
										{kcqCategory.questions?.map((question, index) => (
											<Grid item xs={12}>
												<FormControlLabel
													onClick={(event) => event.stopPropagation()}
													onFocus={(event) => event.stopPropagation()}
													control={
														<Checkbox
															color="primary"
															name={question}
															onClick={(event) =>
																onQuestionClick(event, kcqCategory.id)
															}
															checked={
																selectedQuestions.find(
																	(selectedQuestion) =>
																		selectedQuestion.id === kcqCategory.id
																)
																	? selectedQuestions
																			.find(
																				(selectedQuestion) =>
																					selectedQuestion.id === kcqCategory.id
																			)
																			.questions.includes(question)
																	: false
															}
														/>
													}
													label={
														<Typography variant="h5">{question}</Typography>
													}
												/>
											</Grid>
										))}
									</Grid>
								</ExpansionPanelDetails>
							</ExpansionPanel>
						) : null}
					</>
				))}
			</div>
		);
	};
	return <>{renderDefaultKCQCategories()}</>;
}
export default DefaultKCQSelect;
