import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/styles';

import { ModalFormWrapper } from './../../../components/ModalFormWrapper';
import CreateContactForm from './../../../components/CreateContactForm';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%',
    padding: 10
  }
}));

const AddNewContactModal = ({ employers, open, handleClose, createNewContact }) => {

  const classes = useStyles();
  const [t] = useTranslation(['tooltips', 'newTranslations']);

  let sortedEmployers = employers;

  if (employers?.length) {
    sortedEmployers = employers.sort((a, b) => {
      const nameA = a.employerName.toLowerCase();
      const nameB = b.employerName.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }

  return (
    <ModalFormWrapper
      formTitle={t("newTranslations:addNewContacts")}
      handleOpen={open}
      handleClose={handleClose}
      formTooltip={t("tooltips:addNewContacts")} >
      <CreateContactForm
        employerId={employers.length === 1 && employers[0].id}
        employerOptions={sortedEmployers}
        onSubmit={createNewContact}
        className={classes.form} />
    </ModalFormWrapper>
  );

};

export default AddNewContactModal;
