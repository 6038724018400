import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import {
  Button,
  TextField,
  Typography,
  Grid,
} from "@material-ui/core";
import validate from "validate.js";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import { ThemeIconButton } from "src/components/LayoutComponents/ThemeIconButton";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    padding: 10,
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  addButton: {
    flex: 1,
    margin: 5,
  },
  header: {
    padding: 24,
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  categoriesTitle: {
    margin: "20px 0px",
  },
}));
const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function EditKcqCategoryModal({
  category,
  onSuccess,
  open,
  handleClose,
  modalTitle,
  title,
  subtitle,
  buttonText,
}) {
  const [t] = useTranslation(["dwi", "common"]);
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      newCategory: "",
    },
    touched: {},
    errors: {},
  });

  const [kcqSaveRequired, setKcqSaveRequired] = useState(false);

  const handleChange = (event) => {
    event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const deleteQuestion = (event, index) => {
    event.persist();
    let tempQuestions = [...formState.values.questions];
    tempQuestions.splice(index, 1);
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        questions: tempQuestions,
      },
    }));
  };

  const handleNewQuestionChange = (event) => {
    setKcqSaveRequired(true);
    event.persist();
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        newCategory: event.target.value,
      },
    }));
  };

  const addNewQuestion = (event) => {
    event.preventDefault();
    let newCategory = formState.values.newCategory;
    let tempQuestions = [...formState.values.questions];
    tempQuestions.push(newCategory);
    setFormState((prevValues) => ({
      ...prevValues,
      values: {
        ...prevValues.values,
        questions: tempQuestions,
        newCategory: "",
      },
    }));
    setKcqSaveRequired(false);
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    onSuccess({
      id: formState.values.id,
      name: formState.values.name,
      questions: formState.values.questions,
    });
  };

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...category,
        newCategory: "",
      },
    }));
  }, [category]);

  useEffect(() => {
    if (open) {
      const errors = validate(formState.values, schema);
      setFormState((prevFormState) => ({
        ...prevFormState,
        isValid:
          !errors &&
          formState.values.questions.length >= 1 &&
          !kcqSaveRequired &&
          !formState.values.newCategory,
        errors: errors || {},
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  return (
    <ModalFormWrapper
      formTitle={modalTitle}
      handleOpen={open}
      handleClose={handleClose}
    >
      <form onSubmit={onSubmit}>
        <Typography variant="h4" className={classes.categoriesTitle}>
          {title}
        </Typography>
        <TextField
          fullWidth
          name="name"
          variant="outlined"
          required
          onChange={handleChange}
          error={hasError("name")}
          value={formState.values.name || ""}
          helperText={hasError("name") ? formState.errors.name[0] : null}
        />
        <Typography variant="h5" className={classes.categoriesTitle}>
          {subtitle}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={11}>
            <Typography variant="h6">{t("common:name")}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography align="right" variant="h6">
              {t("common:actions")}
            </Typography>
          </Grid>
          {formState.values.questions?.map((question, index) => (
            <>
              <Grid item xs={11}>
                <TextField
                  name="name"
                  fullWidth
                  variant="outlined"
                  required
                  disabled={true}
                  value={question}
                />
              </Grid>
              <Grid item xs={1}>
                <span style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <ThemeIconButton
                    btnFunc={(event) => deleteQuestion(event, index)}
                    icon={<DeleteIcon />}
                  />
                </span>
              </Grid>
            </>
          ))}
          <>
            <Grid item xs={11}>
              <TextField
                name="name"
                fullWidth
                variant="outlined"
                value={formState.values.newCategory}
                onChange={handleNewQuestionChange}
              />
            </Grid>

            <Grid item xs={1}>
              <span style={{ display: "flex", flexDirection: "row-reverse" }}>
                <ThemeIconButton
                  disabled={!kcqSaveRequired}
                  icon={<SaveIcon />}
                  btnFunc={addNewQuestion}
                />
              </span>
            </Grid>
          </>
        </Grid>
        <Button
          className={classes.submitButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={!formState.isValid}
        >
          {buttonText}
        </Button>
      </form>
    </ModalFormWrapper>
  );
}
export default EditKcqCategoryModal;
