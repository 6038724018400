import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import { Box, colors } from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    listStyle: "none",
    userSelect: "none",
    display: "flex",
    alignItems: "center",
  },
  active: {},
  activeLink: {},
  break: {},
  breakLink: {},
  disabled: {},
  next: {
    marginLeft: theme.spacing(1),
  },
  nextLink: {
    padding: "6px 16px",
    outline: "none",
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
    },
  },
  page: {},
  pageLink: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    outline: "none",
    cursor: "pointer",
    width: 40,
    height: 40,
    borderRadius: "50%",
    display: "block",
    textAlign: "center",
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
    "&$activeLink": {
      backgroundColor: colors.blueGrey[50],
      color: theme.palette.text.primary,
    },
  },
  previous: {
    marginRight: theme.spacing(1),
  },
  previousLink: {
    padding: "6px 16px",
    outline: "none",
    cursor: "pointer",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: colors.blueGrey[50],
    },
  },
}));

export const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  };

  const jump = (pageNumber) => {
    setCurrentPage(() => pageNumber);
  };

  return {
    jump,
    currentData,
    currentPage,
    maxPage,
    Paginator,
  };
};

export const Paginator = ({
  itemsPerPage,
  totalItems,
  pageCount,
  pageRangeDisplayed,
  onPageChange,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const [t] = useTranslation(["newTranslations"]);
  return (
    <Box display="flex" justifyContent="center" mt={4}>
      <ReactPaginate
        activeClassName={classes.active}
        activeLinkClassName={classes.activeLink}
        breakClassName={classes.break}
        breakLabel="..."
        breakLinkClassName={classes.breakLink}
        containerClassName={clsx(classes.root, className)}
        disabledClassName={classes.disabled}
        marginPagesDisplayed={2}
        nextClassName={classes.next}
        nextLabel={t("newTranslations:next")}
        nextLinkClassName={classes.nextLink}
        onPageChange={onPageChange}
        pageClassName={classes.page}
        pageCount={pageCount}
        pageLinkClassName={classes.pageLink}
        pageRangeDisplayed={pageRangeDisplayed}
        previousClassName={classes.previous}
        previousLabel={t("newTranslations:previous")}
        previousLinkClassName={classes.previousLink}
        subContainerClassName="pages pagination"
        {...rest}
      />
    </Box>
  );
};

Paginator.propTypes = {
  className: PropTypes.string,
  onPageChange: PropTypes.func,
  pageCount: PropTypes.number,
  pageRangeDisplayed: PropTypes.number,
};

Paginator.defaultProps = {
  onPageChange: () => {},
  pageCount: 3,
  pageRangeDisplayed: 5,
};
