import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { AppBar, Grid } from "@material-ui/core";
import * as CandidateApi from "src/api/candidateApi";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { ProfileCard } from "src/components/ProfileCard";
import { TabPanel } from "src/components/TabPanel";
import DefaultCandidateProfile from "./DefaultCandidateProfile";
import DefaultCandidateExecutiveSummary from "./DefaultCandidateExecutiveSummary";
import DefaultCandidateCareerHighlights from "./DefaultCandidateCareerHighlights";
import SignalHireData from "./SignalHireData";
import * as SysApi from "src/api/SystemAdminApi";
import validate from "validate.js";
import { PageTitle } from "src/components/LayoutComponents/PageTitle";
import { ReturnButton } from "src/components/LayoutComponents/ReturnButton";
import PageBackButton from "src/components/LayoutComponents/PageBackButton";
import CustomTabs from './../../../components/LayoutComponents/CustomTabs';

import * as signalHireApi from "../../../api/signalHireApi";
import * as consultancyApi from "../../../api/consultancyApi";

import { SIGNAL_HIRE_ID } from '../../../constants/moduleIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  gridRow: {
    marginTop: "20px",
  },
  createCampaignButtonText: {
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
    },
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  companyLogo: {
    height: "80px",
    width: "auto",
    objectFit: "contain",
  },
  candidateTitle: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  candidateEmail: {
    textAlign: "center",
    marginTop: "10px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
  formContainer: { marginTop: "12px" },
  loadingIndicator: {
    display: "flex",
    justifyContent: "center",
  },
}));

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 64,
    },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function CandidateDetails({ candidateId, ...rest }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const consultancyId = useSelector((state) =>
    rest?.consultancyId ? rest.consultancyId : state.session.consultancyId
  );

  const [campaigns, setCampaigns] = useState([]);
  const [defaultExecutiveSummary, setDefaultExecutiveSummary] = useState();
  const [defaultCareerHighlights, setDefaultCareerHighlights] = useState();
  const [currentTab, setCurrentTab] = useState(0);
  const [breadcrumbs, setBreadCrumbs] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [enabled, setEnabled] = useState();
  const timer = useRef(null);
  const history = useHistory();

  const [t] = useTranslation(["common", "campaigns", "candidates"]);

  const signalHireTab = { value: 2, label: 'Signal Hire' };

  const [tabs, setTabs] = useState([
    { value: 0, label: t("common:profileLabel") },
    { value: 1, label: t("common:campaignsLabel") },
  ]);

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const userId = formState?.values?.userId;

  const handleSummaryChange = (event) => {
    // prevents from updating state with each typed letter. After user stops typing it waits for half second and updates state.
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => setDefaultExecutiveSummary(event), 500);
  };

  const handleCareerHighlightsChange = (event) => {
    timer.current && clearTimeout(timer.current);
    timer.current = setTimeout(() => setDefaultCareerHighlights(event), 500);
  };

  const getCandidateById = function(consultancyId, candidateId) {
    return CandidateApi.getCandidate({ consultancyId, candidateId })
      .then((response) => {
        let { campaigns, defaultProfile, user } = response.data;
        setCampaigns(campaigns);

        const candidateData = {
          salutation: defaultProfile?.salutation,
          firstName: defaultProfile?.firstName,
          lastName: defaultProfile?.lastName,
          telephone: defaultProfile?.telephone,
          addressLine1: defaultProfile?.address.addressLine1,
          addressLine2: defaultProfile?.address.addressLine2,
          city: defaultProfile?.address.city,
          postalCode: defaultProfile?.address.postalCode,
          country: defaultProfile?.address.countryId,
          enabled: user.enabled,
          userId: defaultProfile?.userId,
          profilePhoto: defaultProfile?.profilePhoto,
        };

        setFormState((initialState) => ({
          ...initialState,
          values: candidateData,
        }));
        setDefaultExecutiveSummary(defaultProfile.executiveSummary);
        setDefaultCareerHighlights(defaultProfile.careerHighlights);
        setEnabled(user.enabled);
      })
      .catch(() => {
        history.push("/dashboard/candidates");
      });
  };

  const goToCampaign = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/campaigns/${campaignId}`);
  };

  const goToTalentAlert = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/talent-alerts/${campaignId}`);
  };

  function createData(name, data) {
    return { name, data };
  }
  const getCampaingOwnerName = (owner) =>
    owner
      ? ` ${owner.profile.salutation ? owner.profile.salutation : ""} ${
          owner.profile.firstName
        } ${owner.profile.lastName} `
      : t("campaigns:tbc");
  const getOwner = (admin, consultant, managedByRole) =>
    consultant && managedByRole === "consultant" ? consultant : admin;

  const getCampaignDetails = (campaign) => {
    const date = new Date(campaign.createdAt);
    const formattedDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}`;
    const employerName = campaign.assignmentCampaign
      ? campaign.assignmentCampaign.employer.employerName
      : "";
    const campaignStatus = campaign.campaignStatus
      ? campaign.campaignStatus.status
      : t("campaings:draft");
    const campaignCandidates =
      campaign.candidates && campaign.candidates.length
        ? campaign.candidates.length
        : 0;

    const assignedTo = getCampaingOwnerName(
      getOwner(campaign.admin, campaign.consultant, campaign.managedByRole)
    );
    const campaignDetails = [
      createData(t(`campaigns:employerLabel`), employerName),
      createData(t(`campaigns:campaignStatusLabel`), campaignStatus),
      createData(t(`campaigns:dateStarted`), formattedDate),
      createData(t(`campaigns:candidatesLabel`), campaignCandidates),
      createData(t(`campaigns:assignedTo`), assignedTo),
    ];
    return campaignDetails;
  };

  const handleUploadProfilePicture = (value) => {
    return CandidateApi.uploadDefaultCandidateProfilePictureByCandidateId(
      consultancyId,
      candidateId,
      value
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:profilePictureUpdated"), {
            variant: "success",
          });
          getCandidateById(consultancyId, candidateId);
        } else {
          throw Error("Failed to update logo");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:profilePictureUpdatedError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const toggleAccount = (value) => {
    SysApi.toggleCandidateUserStatus(consultancyId, userId)
      .then((response) => {
        setEnabled(response.data.enabled);

        if (response.data.enabled) {
          enqueueSnackbar(t("snackbar:candidateAccountEnabled"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:candidateAccountDisabled"), {
            variant: "success",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:candidateAccountError"), {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };
  const saveDetails = (event) => {
    event.preventDefault();

    return CandidateApi.updateDefaultCandidateProfileByUserId(
      formState.values,
      consultancyId,
      userId
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:updateUserProfile"), {
            variant: "success",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:failedToUpdateProfile"), {
          variant: "error",
        });
      });
  };

  const handleLocaleChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        localeId: value.props.value,
      },
      touched: {
        ...prevFormState.touched,
        localeId: true,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!candidateId) {
      history.push("/dashboard/candidates");
    } else {
      getCandidateById(consultancyId, candidateId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadCrumbs([
      { title: t("candidates:candidates"), link: "/candidates" },
      {
        title: `${formState.values.firstName} ${formState.values.lastName}`,
        link: `/candidates/${candidateId}`,
      },
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  useEffect(() => {
    const modulesPromise = consultancyApi.getModulesForConsultancy(consultancyId);
    const signalHireApiKeyPromise = signalHireApi.getAccessTokenByConsultancyId(consultancyId);

    Promise.all([modulesPromise, signalHireApiKeyPromise])
      .then(([modulesJson = {}, singnalHireApiKeyJson = {}]) => [modulesJson.data, singnalHireApiKeyJson.data])
      .then(([modulesData, signalHireApiKeyData]) => {
        const signalHireModule = modulesData.find(m => m.moduleId === SIGNAL_HIRE_ID);

        if (signalHireModule && signalHireModule.activeByDefault && signalHireApiKeyData) {
          setTabs(prevState => ([
            ...prevState,
            signalHireTab
          ]));
        }
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* Overflow hidden required on this component to stop nested scrollbar
  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle breadcrumbs={breadcrumbs} />
      <div style={{ height: "40px", display: "flex" }}>
        <PageBackButton />
      </div>

      <ContentWrapper loading={!formState?.values?.country}>
        <AppBar position="static" className={classes.appBar}>
          <CustomTabs tabs={ tabs } onChange={ handleTabChange } />
        </AppBar>
        <TabPanel value={currentTab} index={0}>
          <Grid container spacing={3}>
            {formState?.values ? (
              <DefaultCandidateProfile
                userId={formState?.values?.userId}
                handleUploadProfilePicture={handleUploadProfilePicture}
                toggleAccount={toggleAccount}
                enabled={enabled}
                saveDetails={saveDetails}
                handleChange={handleChange}
                hasError={hasError}
                handleCountryChange={handleCountryChange}
                handleLocaleChange={handleLocaleChange}
                formState={formState}
                candidateId={ candidateId }
                consultancyId={ consultancyId }
              />
            ) : null}
            <Grid
              container
              justify="flex-end"
              spacing={3}
              className={classes.formContainer}
            >
              <Grid item xs={12} lg={9}>
                <DefaultCandidateExecutiveSummary
                  candidateId={candidateId}
                  executiveSummary={defaultExecutiveSummary}
                  handleSummaryChange={handleSummaryChange}
                />
              </Grid>
              <Grid item xs={12} lg={9}>
                <DefaultCandidateCareerHighlights
                  candidateId={candidateId}
                  careerHighlights={defaultCareerHighlights}
                  handleCareerHighlightsChange={handleCareerHighlightsChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={3}>
            {campaigns.map((campaign, i) => (
              campaign.campaignTypeId === 1 ? (
                <Grid key={i} item md={4} sm={6} xs={12}>
                  <ProfileCard
                    campaignDetails={getCampaignDetails(campaign)}
                    name={campaign.campaignTitle}
                    btnLabel={t("common:viewMore")}
                    btnFunc={(event) => {
                      goToCampaign(event, campaign.id);
                    }}
                  />
                </Grid>
              ) : (
                <Grid key={i} item md={4} sm={6} xs={12}>
                  <ProfileCard
                    campaignDetails={getCampaignDetails(campaign)}
                    name={campaign.campaignTitle}
                    btnLabel={t("common:viewMore")}
                    btnFunc={(event) => {
                      goToTalentAlert(event, campaign.id);
                    }}
                  />
                </Grid>
              )
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <Grid container spacing={3}>
            <SignalHireData
              consultancyId={consultancyId}
              userId={userId}
            />
          </Grid>
        </TabPanel>
        <ReturnButton />
      </ContentWrapper>
    </div>
  );
}

export default CandidateDetails;
