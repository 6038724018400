import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Table,
	Typography,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	card: {
		transition: "300ms",
		maxWidth: "360px",
		overflow: "visible",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: theme.spacing(12),
	},
	card_disabled: {
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	name: {
		transition: "300ms",
		paddingBottom: ".5rem",
		borderBottom: `1px solid ${theme.palette.text.primary}`,
		minWidth: "326px",
	},
	remove_underline: {
		transition: "300ms",
		paddingBottom: ".5rem",
		minWidth: "326px",
	},
	name_disabled: {
		marginTop: theme.spacing(1),
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	logo_container: {
		width: 300,
		boxShadow: "0 0.5rem 1rem #C2C2C2",
		transform: "translateY(-50px)",
		backgroundColor: theme.palette.background.paper,
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "-2rem",
		display: "flex",
		justifyContent: "center",
	},
	logo: {
		height: 68,
		width: "auto",
		maxWidth: 268,
		margin: "1rem",
	},
}));

export const ContactCampaignCard = ({ btnFunc, campaignDetails, consultancyLogo }) => {
	const classes = useStyles();

	const [t] = useTranslation(["labels"]);

	const campaignTitle = (campaign) => {
		if(campaign.campaignTypeId === 2) {
			return `Talent Alert - ${campaign.campaignTitle}`
		}

		return campaign.campaignTitle;
	};

	return (
		<Card className={classes.card}>
			<Box className={classes.logo_container}>
				<img
					src={
						campaignDetails?.campaignLogo.length
							? campaignDetails.campaignLogo
							: consultancyLogo
					}
					alt=""
					className={classes.logo}
				/>
			</Box>

			<CardContent>
				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					textAlign="center"
				>
					<Box>
						<Typography
							className={classes.name}
							gutterBottom
							variant="h3"
							color="textPrimary"
						>
							{campaignTitle(campaignDetails)}
						</Typography>
					</Box>
				</Box>
				<Table className={classes.table} aria-label="simple table">
					<TableBody>
						<TableRow key={campaignDetails.status}>
							<TableCell component="th" scope="row">
								{t("labels:status")}
							</TableCell>
							<TableCell align="left">{campaignDetails.status}</TableCell>
						</TableRow>

						<TableRow key={campaignDetails.name}>
							<TableCell component="th" scope="row">
								{t("labels:candidates")}
							</TableCell>
							<TableCell align="left">
								{campaignDetails.numberOfCandidates}
							</TableCell>
						</TableRow>

						<TableRow key={campaignDetails.name}>
							<TableCell component="th" scope="row">
								{t("labels:managedBy")}
							</TableCell>
							<TableCell align="left">{campaignDetails.managedBy}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</CardContent>
			<CardActions>
				<Button fullWidth variant="text" color="primary" onClick={btnFunc}>
					{t("labels:view")}
				</Button>
			</CardActions>
		</Card>
	);
};
