
export const ALL = 0;
export const NOT_SUBMITTED = 1;
export const SUBMITTED = 2;
export const SHORTLIST = 3;
export const FIRST_INTERVIEW = 4;
export const FURTHER_INTERVIEW = 5;
export const REJECTED = 6;
export const OFFER_MADE = 7;
export const HIDDEN = 8;
export const TALENT_ALERT_REJECTED = 10;
export const TALENT_ALERT_INTERESTED = 9;