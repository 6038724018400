import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Collapse,
	createMuiTheme,
	Dialog,
	Divider,
	Fade,
	FormControl,
	Grid,
	IconButton,
	InputBase,
	Menu,
	MenuItem,
	Paper,
	Select,
	Tooltip,
	Zoom,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import * as api from "../../api/themeApi";
import StyleIcon from "@material-ui/icons/Style";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import CustomFileDropzone from "src/components/CustomFileDropzone";
import { SketchPicker } from "react-color";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import { SET_THEME } from "src/actions/themeActions";
import Swal from "sweetalert2";
import LandingPageManagement from "./LandingPageManagement";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		maxWidth: 800,
		backgroundColor: theme.palette.background.paper,
	},
	clickableContainer: {
		display: "flex",
		alignItems: "center",
	},
	swatch: {
		height: "60px",
		width: "60px",
		"&:hover": {
			background: "lightgrey",
			cursor: "pointer",
		},
	},
	label: {
		marginLeft: "1rem",
		fontWeight: "fontWeightLight",
		"&:hover": {
			backgroundColor: "transparent",
			cursor: "inherit",
		},
	},
	themeActionsContainer: {
		padding: "2px 4px",
		display: "flex",
		alignItems: "center",
		width: 400,
		maxWidth: "100%",
		marginBottom: "2rem",
	},
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},
	iconButton: {
		padding: 10,
		fontSize: "1.2rem",
	},
	divider: {
		height: 28,
		margin: 4,
	},
	select: {
		borderBottom: "none",
	},
	fileInput: {
		display: "none",
	},
}));

//! currently getting theme from api AND redux

//* COMPONENT START

const ThemeManagement = ({ consultancyId }) => {
	const inputRef = React.useRef();
	const theme = useTheme();

	const blankTheme = {
		id: "",
		primary: "",
		nav: "",
		accent: "",
		title: "#111111",
		heading: "#111111",
		button: "",
		success: "#4caf50",
		warning: "#ff9800",
		error: "#f44336",
		info: "#2196f3",
	};

	//* STATES & VARIABLES
	// #region
	const classes = useStyles();
	const dispatch = useDispatch();

	const [themeInView, setThemeInView] = useState(blankTheme);
	const [themeBackUp, setThemeBackUp] = useState({});
	const [activeThemeName, setActiveThemeName] = useState("");
	const [consultancyThemes, setConsultancyThemes] = useState([]);
	const [themeList, setThemeList] = useState([]);
	const [selectThemeMenu, setSelectThemeMenu] = useState(false);
	const [protectName, setProtectName] = useState(true);
	const [showColorPicker, setShowColorPicker] = useState(false);
	const [pickerColour, setPickerColour] = useState("#fff");
	const [targetedProperty, setTargetedProperty] = useState("");
	const [advanced, setAdvanced] = useState(false);

	//#endregion

	//* FUNCTIONS
	//#region

	const createTheme = (themeInfo) => {
		const customTheme = createMuiTheme({
			palette: {
				primary: {
					main: themeInfo.primary,
				},
				secondary: {
					main: themeInfo.accent || themeInfo.primary,
				},
				nav: {
					main: themeInfo.nav || themeInfo.primary,
				},
				success: {
					main: themeInfo.success,
				},
				warning: {
					main: themeInfo.warning,
				},
				error: {
					main: themeInfo.error,
				},
				info: {
					main: themeInfo.info,
				},
				button: {
					main: themeInfo.button,
				},
			},
			typography: {
				fontWeight: 300,
				fontSize: "6rem",
				lineHeight: 1.167,
				letterSpacing: "-0.01562em",
				h1: {
					color: themeInfo.title || "#111111", // add defaults to blank "themeInfo" instance
				},
				h3: {
					color: themeInfo.heading || "#111111",
				},
				h4: {
					color: themeInfo.heading || "#111111",
				},
			},
			shape: {
				borderRadius: 2,
			},
		});

		const themeOverrides = {
			...customTheme,
			overrides: {
				MuiButton: {
					containedPrimary: {
						backgroundColor: themeInfo.button || themeInfo.primary,
						color: theme.palette.getContrastText(themeInfo.button),
					},
					outlinedPrimary: {
						color: themeInfo.button || themeInfo.primary,
						borderColor: themeInfo.button || themeInfo.primary,
					},
					textPrimary: {
						color: themeInfo.button || themeInfo.primary,
					},
				},
				MuiTabs: {
					textColor: themeInfo.primary,
					indicator: {
						backgroundColor: themeInfo.primary,
					},
				},
				MuiTooltip: {
					tooltip: {
						backgroundColor: themeInfo.info || blankTheme.info,
					},
				},
				MuiSnackbarContent: {
					root: {
						'&[class*="variantInfo"]': {
							backgroundColor: themeInfo.info,
							color: theme.palette.getContrastText(themeInfo.info),
						},
						'&[class*="variantSuccess"]': {
							backgroundColor: themeInfo.success,
							color: theme.palette.getContrastText(themeInfo.success),
						},
						'&[class*="variantError"]': {
							backgroundColor: themeInfo.error,
							color: theme.palette.getContrastText(themeInfo.error),
						},
						'&[class*="variantWarning"]': {
							backgroundColor: themeInfo.warning,
							color: theme.palette.getContrastText(themeInfo.warning),
						},
					},
				},
			},
		};

		const themeWithObject = {
			...themeInfo,
			themeObject: themeOverrides,
		};

		if (themeList.includes(activeThemeName)) {
			return api
				.updateThemeForConsultancy(
					consultancyId,
					themeBackUp.id.value,
					themeWithObject
				)
				.then((response) => {
					getThemesForConsultancy(consultancyId);
					parseAndSetThemeInView(themeInfo);
					swalSuccess();
					if (response.data.isActive) {
						dispatch({
							type: SET_THEME,
							activeTheme: themeOverrides,
						});
					}
				})
				.catch((err) => {
					swalError();
				});
		} else {
			return api
				.createNewTheme(themeWithObject, consultancyId)
				.then((response) => {
					Swal.fire({
						icon: "success",
						title: "Set as active theme?",
						showCancelButton: true,
						cancelButtonText: "Keep current theme",
						confirmButtonText: `Set as active`,
						confirmButtonColor: theme.palette.success?.main
							? theme.palette.success.main
							: theme.palette.primary.main,
					}).then((result) => {
						if (result.isConfirmed) {
							handleSetActiveTheme(response.data.id);
						} else {
							getActiveTheme(consultancyId);
						}
					});
					getThemesForConsultancy(consultancyId);
					parseAndSetThemeInView(themeInfo);
				});
		}
	};

	const parseThemeObject = (theme) => {
		const themeInfo = {
			id: {
				label: theme.name,
				value: theme.id,
			},
			primary: {
				label: "Primary",
				value: theme.themeObject.palette.primary.main,
			},
			nav: {
				label: "Navigation Bar",
				value: theme.themeObject.palette.nav.main,
			},
			accent: {
				label: "Accent",
				value: theme.themeObject.palette.secondary.main,
			},
			title: {
				label: "Title",
				value: theme.themeObject.typography.h1.color,
			},
			heading: {
				label: "Heading",
				value: theme.themeObject.typography.h3.color,
			},
			button: {
				label: "Button",
				value:
					theme.themeObject.overrides.MuiButton.containedPrimary
						.backgroundColor || theme.themeObject.palette.primary.main,
			},
			success: {
				label: "Success",
				value: theme.themeObject.palette.success?.main || blankTheme.success,
			},
			warning: {
				label: "Warning",
				value: theme.themeObject.palette.warning?.main || blankTheme.warning,
			},
			error: {
				label: "Error",
				value: theme.themeObject.palette.error?.main || blankTheme.error,
			},
			info: {
				label: "Info",
				value: theme.themeObject.palette.info?.main || blankTheme.info,
			},
		};
		setThemeInView(themeInfo);
		setThemeBackUp(themeInfo);
		setActiveThemeName(theme.name);
	};

	const parseAndSetThemeInView = (theme) => {
		const themeObject = {
			id: {
				label: theme.name,
				value: theme.id,
			},
			primary: {
				label: "Primary",
				value: theme.primary,
			},
			nav: {
				label: "Navigation Bar",
				value: theme.nav,
			},
			accent: {
				label: "Accent",
				value: theme.accent,
			},
			title: {
				label: "Title",
				value: theme.title,
			},
			heading: {
				label: "Heading",
				value: theme.heading,
			},
			button: {
				label: "Button",
				value: theme.button,
			},
			success: {
				label: "Success",
				value: theme.success,
			},
			warning: {
				label: "Warning",
				value: theme.warning,
			},
			error: {
				label: "Error",
				value: theme.error,
			},
			info: {
				label: "Info",
				value: theme.info,
			},
		};
		setThemeInView(themeObject);
		setThemeBackUp(themeObject);
		setActiveThemeName(themeObject.id.label);
	};

	const swalSuccess = () => {
		Swal.fire({
			icon: "success",
			title: "Saved",
			showCancelButton: true,
			showConfirmButton: false,
			cancelButtonText: `OK`,
			cancelButtonColor: theme.palette.success?.main
				? theme.palette.success.main
				: theme.palette.primary.main,
		});
	};

	const swalError = () => {
		Swal.fire({
			icon: "error",
			title: "Oops...",
			text: "Something went wrong!",
			showCancelButton: true,
			showConfirmButton: false,
			cancelButtonText: `OK`,
			cancelButtonColor: theme.palette.error?.main
				? theme.palette.error.main
				: theme.palette.primary.main,
		});
	};

	//* API REQUESTS

	const getThemesForConsultancy = (consultancyId) => {
		return api.getThemesForConsultancy(consultancyId).then((response) => {
			const themes = response.data;
			const themeNames = themes.map((theme) => theme.name);
			setThemeList(themeNames);
			setConsultancyThemes(themes);
		});
	};

	const getActiveTheme = (consultancyId) => {
		return api
			.getActiveTheme(consultancyId)
			.then((response) => {
				const data = response.data;
				parseThemeObject(data);
			})
			.catch((err) => {
				throw err;
			});
	};

	const setActiveThemeForConsultancy = (consultancyId, id, customTheme) => {
		return api
			.setActiveThemeForConsultancy(consultancyId, id)
			.then((response) => {
				const themeForDispatch = {
					...customTheme,
					overrides: {
						MuiButton: {
							containedPrimary: {
								backgroundColor:
									themeInView.button.value || themeInView.primary.value,
							},
							outlinedPrimary: {
								color: themeInView.button.value || themeInView.primary.value,
								borderColor:
									themeInView.button.value || themeInView.primary.value,
							},
							textPrimary: {
								color: themeInView.button.value || themeInView.primary.value,
							},
						},
						MuiTabs: {
							indicator: {
								backgroundColor: themeInView.accent.value,
							},
						},
					},
				};

				dispatch({
					type: SET_THEME,
					consultancyDetails: themeForDispatch,
				});

				swalSuccess();
			});
	};

	useEffect(() => {
		getActiveTheme(consultancyId);
		getThemesForConsultancy(consultancyId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	//#endregion

	//* CHANGE HANDLERS
	//#region
	const handleDisplayColorPicker = (props) => {
		const target = props.currentTarget.id;
		setTargetedProperty(target);
		setPickerColour(themeInView[target].value);
		setShowColorPicker(!showColorPicker);
	};

	const handleChangeColor = ({ hex }) => {
		setPickerColour(hex);
		const label = themeInView[targetedProperty].label;
		const updatedObj = {
			...themeInView,
			[targetedProperty]: { label: label, value: hex },
		};
		setThemeInView(updatedObj);
		if (label === "Primary") {
			autofillColours(hex);
		}
	};

	const handleChangeTheme = (event) => {
		const selectedTheme = consultancyThemes.find(
			(theme) => theme.name === event.target.value
		);
		parseAndSetThemeInView(selectedTheme);
	};

	const handleSetActiveTheme = (passedId) => {
		const checkSaved = themeList.includes(activeThemeName);

		if (!checkSaved && !passedId) {
			Swal.fire({
				title: "Please save theme and try again",
				icon: "error",
				showCancelButton: true,
				confirmButtonText: `Save theme`,
				confirmButtonColor: theme.palette.success?.main
					? theme.palette.success.main
					: theme.palette.primary.main,
			}).then((result) => {
				if (result.isConfirmed) {
					validateSaveChanges();
				}
			});
		} else {
			const id = passedId || themeInView.id.value;

			const customTheme = createMuiTheme({
				palette: {
					primary: {
						main: themeInView.primary.value,
					},
				},
				typography: {
					fontWeight: 300,
					fontSize: "6rem",
					lineHeight: 1.167,
					letterSpacing: "-0.01562em",
					h1: {
						color: themeInView.title.value || blankTheme.title,
					},
					h3: {
						color: themeInView.heading.value || blankTheme.heading,
					},
					h4: {
						color: themeInView.heading.value || blankTheme.heading,
					},
				},
			});
			setActiveThemeForConsultancy(consultancyId, id, customTheme);
		}
	};

	const autofillColours = (hex) => {
		if (themeInView.nav.value === "") {
			setThemeInView((prevState) => ({
				...prevState,
				nav: {
					label: "Navigation Bar",
					value: hex,
				},
			}));
		}
		if (themeInView.button.value === "") {
			setThemeInView((prevState) => ({
				...prevState,
				button: {
					label: "Button",
					value: hex,
				},
			}));
		}
		if (themeInView.accent.value === "") {
			setThemeInView((prevState) => ({
				...prevState,
				accent: {
					label: "Accent",
					value: provideAccent(hex),
				},
			}));
		}
	};

	const provideAccent = (hex) => {
		const num = parseInt(hex.substring(1), 16);

		let r = (num >> 16) + 80;

		if (r > 255) r = 255;
		else if (r < 0) r = 0;

		let b = ((num >> 8) & 0x00ff) + 80;

		if (b > 255) b = 255;
		else if (b < 0) b = 0;

		let g = (num & 0x0000ff) + 80;

		if (g > 255) g = 255;
		else if (g < 0) g = 0;

		return "#" + (g | (b << 8) | (r << 16)).toString(16);
	};

	const handleShowMenu = () => {
		setSelectThemeMenu(!selectThemeMenu);
	};

	const handleClose = () => {
		setSelectThemeMenu(false);
	};

	const handleEnableEditName = () => {
		setProtectName(!protectName);
		inputRef.current.focus();
	};

	const handleChangeName = (event) => {
		setActiveThemeName(event.target.value);
	};

	const handleCreateTheme = () => {
		parseAndSetThemeInView(blankTheme);
		setProtectName(false);
		setActiveThemeName("");
		inputRef.current.focus();
	};

	const handleResetChanges = () => {
		setThemeInView(themeBackUp);
	};

	const handleDeleteTheme = () => {
		return api
			.deleteThemeForConsultancy(consultancyId, themeInView.id.value)
			.then((response) => {
				Swal.fire({
					title: "Deleted",
					icon: "success",
					showCancelButton: true,
					showConfirmButton: false,
					cancelButtonText: `OK`,
				});
				getActiveTheme(consultancyId);
				getThemesForConsultancy(consultancyId);
			})
			.catch((err) => {
				swalError();
				throw err;
			});
	};

	const handleUploadFav = (value) => {
		return api
			.uploadConsultancyFavicon(consultancyId, value)
			.then((response) => {
				if (response) {
					swalSuccess();
				} else {
					swalError();
				}
			});
	};

	const saveTheme = () => {
		const newTheme = {
			name: activeThemeName,
			id: themeInView.id.value,
			primary: themeInView.primary.value,
			nav: themeInView.nav.value,
			accent: themeInView.accent.value,
			title: themeInView.title.value,
			heading: themeInView.heading.value,
			button: themeInView.button.value,
			success: themeInView.success.value,
			warning: themeInView.warning.value,
			error: themeInView.error.value,
			info: themeInView.info.value,
		};
		createTheme(newTheme);
	};

	const validateSaveChanges = () => {
		if (!activeThemeName) {
			Swal.fire({
				title: "Please enter a theme name",
				showCancelButton: true,
				showConfirmButton: false,
				cancelButtonText: `OK`,
				onClose: inputRef.current.focus(),
			});
		} else if (!themeInView.primary.value) {
			Swal.fire({
				title: "Please select a primary colour",
				icon: "error",
				showCancelButton: true,
				showConfirmButton: false,
				cancelButtonText: `OK`,
			});
		} else if (themeList.includes(activeThemeName)) {
			confirmOverwrite();
		} else {
			saveTheme();
		}
	};

	const confirmOverwrite = () => {
		Swal.fire({
			title: "You are about to make changes to an existing theme",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: `Proceed`,
			confirmButtonColor: theme.palette.warning?.main
				? theme.palette.warning.main
				: theme.palette.primary.main,
		}).then((result) => {
			if (result.isConfirmed) {
				saveTheme();
			}
		});
	};

	const confirmDelete = () => {
		Swal.fire({
			title: "You are about to permanently delete this theme",
			icon: "warning",
			confirmButtonColor: theme.palette.warning?.main
				? theme.palette.warning.main
				: theme.palette.primary.main,
			showCancelButton: true,
			confirmButtonText: `Delete`,
			denyButtonText: `Go Back`,
		}).then((result) => {
			if (result.isConfirmed) {
				handleDeleteTheme();
			}
		});
	};

	//#endregion

	//////////////////
	////* RENDER *////
	//////////////////

	return (
		<>
			<BlockHeader
				header="Application Theme"
				btnLabel="Set As Active Theme"
				btnFunc={() => {
					handleSetActiveTheme();
				}}
				btnLabel2="Create New Theme"
				btnFunc2={handleCreateTheme}
			/>
			<Grid container style={{ textAlign: "center" }}>
				<Grid item xs={12} md={4}>
					<Paper className={classes.themeActionsContainer}>
						<Tooltip title="Select Theme">
							<IconButton
								color="primary"
								className={classes.iconButton}
								onClick={handleShowMenu}
								id="selectTheme"
								disabled={themeList.length <= 1}
							>
								<StyleIcon />
							</IconButton>
						</Tooltip>
						<InputBase
							className={classes.input}
							placeholder={themeInView.id.label}
							value={activeThemeName}
							readOnly={protectName}
							onChange={handleChangeName}
							inputRef={inputRef}
							required
						/>
						<Tooltip title="Edit Theme Name">
							<IconButton
								color="primary"
								className={classes.iconButton}
								onClick={handleEnableEditName}
								disabled={themeList.length === 0}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>
						<Divider className={classes.divider} orientation="vertical" />
						<Tooltip title="Delete Theme">
							<IconButton
								color="primary"
								className={classes.iconButton}
								onClick={confirmDelete}
								disabled={themeList.length <= 1}
							>
								<DeleteForeverIcon />
							</IconButton>
						</Tooltip>
					</Paper>
				</Grid>
				<Fade in={!advanced} mountOnEnter unmountOnExit>
					<Grid item xs={12} md={2} style={{ alignContent: "center" }}>
						<Box
							className={classes.clickableContainer}
							id="primary"
							onClick={handleDisplayColorPicker}
							style={{ marginLeft: "2rem" }}
						>
							<Box
								display="inline-block"
								height="20px"
								width="60px"
								// id="primary"
								bgcolor={themeInView.primary.value}
							></Box>
							<Box display="inline-block">
								<Button variant="text">Theme Colour</Button>
							</Box>
						</Box>
					</Grid>
				</Fade>
				<Grid item xs={12} md={2} style={{ transition: "200ms" }}>
					<Button
						variant="text"
						endIcon={advanced ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						onClick={() => {
							setAdvanced(!advanced);
						}}
					>
						Advanced
					</Button>
				</Grid>
				{/* </Slide> */}
			</Grid>
			<Collapse in={advanced}>
				<Grid container>
					{Object.keys(themeInView)
						.slice(1)
						.map((property) => {
							return (
								<>
									<Zoom
										in={advanced}
										style={{ transitionDelay: advanced ? "200ms" : "0ms" }}
									>
										<Grid item xs={12} md={6}>
											<Box
												className={classes.clickableContainer}
												id={property}
												onClick={handleDisplayColorPicker}
											>
												<Box
													display="inline-block"
													className={classes.swatch}
													bgcolor={themeInView[property].value}
												></Box>
												<Box display="inline-block">
													<Button variant="text" className={classes.label}>
														{themeInView[property].label} Colour
													</Button>
												</Box>
											</Box>
										</Grid>
									</Zoom>
								</>
							);
						})}
				</Grid>
				<Fade in={advanced}>
					<Grid container marginTop={theme.spacing(3)}>
						<Grid item xs={12}>
							<Box marginTop={theme.spacing(1)} width="320px">
								<Card>
									<CardContent>
										<CustomFileDropzone
											type="single"
											format="image"
											actionText="Upload Image" // add translation
											uploadFunction={handleUploadFav}
											// placeHolderImage={details.consultancyLogo || iintroLogo}
										/>
									</CardContent>
									<CardActions>
										<Button
											variant="text"
											style={{
												"&:hover": {
													backgroundColor: "transparent",
												},
											}}
										>
											Add a Favicon
										</Button>
									</CardActions>
								</Card>
							</Box>
						</Grid>
					</Grid>
				</Fade>
			</Collapse>
			<ButtonWrapper
				btnLabel="Save Theme Changes"
				btnFunc={validateSaveChanges}
				btnLabel2="Reset Changes"
				btnFunc2={handleResetChanges}
			/>
			<Divider />

			<BlockHeader
				header="Landing Page Customisation"
				// btnLabel="Create New Theme"
				// btnFunc={handleCreateTheme}
			/>
			<LandingPageManagement
				consultancyId={consultancyId}
				consultancyColour={themeInView.primary.value}
			/>

			<Dialog
				open={showColorPicker}
				onClose={() => {
					setShowColorPicker(false);
				}}
			>
				<SketchPicker
					color={pickerColour}
					onChange={handleChangeColor}
					disableAlpha={true}
				/>
			</Dialog>
			<Dialog open={selectThemeMenu}>
				<Menu
					onClose={handleClose}
					keepMounted
					anchorEl={document.getElementById("selectTheme")}
					open={selectThemeMenu}
				>
					<FormControl>
						<Select
							open={selectThemeMenu}
							onClose={handleClose}
							onChange={handleChangeTheme}
							className={classes.select}
						>
							{themeList.map((theme) => (
								<MenuItem value={theme}>{theme}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Menu>
			</Dialog>
		</>
	);
};

export default ThemeManagement;
