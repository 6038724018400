import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import QuillEditor from "src/components/QuillEditor";
import * as ConsultancyApi from "src/api/consultancyApi";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	subjectInput: {
		marginBottom: "20px",
	},
	updateButton: {
		marginTop: "20px",
	},
	tagContainer: {
		marginBottom: "20px",
	},
	restoreButton: {
		marginTop: "20px",
		marginLeft: "20px",
	},
}));

const EmailTemplateLocale = ({ email, emailTags, consultancyId }) => {
	const { enqueueSnackbar } = useSnackbar();
	const [t] = useTranslation(["common", "snackbar", "validation"]);
	const classes = useStyles();
	const [emailTemplate, setEmailTemplate] = useState({
		...email,
	});
	const [updating, setUpdating] = useState(false);

	const handleEmailSubjectChange = (event) => {
		event.persist();
		setEmailTemplate((prevValues) => ({
			...prevValues,
			subjectHTML: event.target.value,
		}));
	};
	const handleEmailContentChange = (event) => {
		let formattedEmailContent = event
			.replace(/&gt;/g, ">")
			.replace(/&lt;/g, "<");
		setEmailTemplate((prevValues) => ({
			...prevValues,
			formattedEmailContent,
			isValid: true,
		}));
	};

	// const doesEmailContentHaveTags = (formattedEmailContent) => {
	//   let isValid = true;
	//   emailTags.forEach((tag)=>{
	//     if(!formattedEmailContent.includes(tag.tag)){
	//       isValid = false
	//     }
	//   });
	//   return isValid
	// }
	const updateEmailTemplate = (event, emailTemplate) => {
		event.preventDefault();
		return ConsultancyApi.updateConsultancyEmailTemplate(
			consultancyId,
			emailTemplate
		)
			.then((response) => {
				setUpdating(true);
				setEmailTemplate({
					...response.data,
					formattedEmailContent: response.data.contentHTML,
					formattedSubject: response.data.subjectHTML,
					isValid: true,
				});
				enqueueSnackbar(t("snackbar:emailTemplateUpdateSuccess"), {
					variant: "success",
				});
			})
			.then(() => {
				setUpdating(false);
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:emailTemplateUpdateFailure"), {
					variant: "error",
				});
				console.error(error);
			});
	};

	const restoreToDefault = (event, emailTemplate) => {
		return ConsultancyApi.restoreConsultancyEmailTemplateToDefault(
			consultancyId,
			emailTemplate
		)
			.then((response) => {
				setUpdating(true);
				setEmailTemplate({
					...response.data,
					formattedEmailContent: response.data.contentHTML,
					formattedSubject: response.data.subjectHTML,
					isValid: true,
				});
				enqueueSnackbar(t("snackbar:emailTemplateRestoreSuccess"), {
					variant: "success",
				});
			})
			.then(() => {
				setUpdating(false);
			})
			.catch((error) => {
				enqueueSnackbar(t("snackbar:emailTemplateRestoreFailure"), {
					variant: "error",
				});
				console.error(error);
			});
	};

	return (
		<>
			<TextField
				fullWidth
				name="subject"
				variant="outlined"
				value={emailTemplate.subjectHTML || " "}
				className={classes.subjectInput}
				onChange={handleEmailSubjectChange}
			/>
			<div className={classes.tagContainer}>
				<Typography variant="h4">{t("common:requiredTags")}: </Typography>
				{emailTags?.map((emailTag) => (
					<Typography variant="h5">{emailTag.tag}</Typography>
				))}
			</div>
			{updating ? null : (
				<QuillEditor
					value={emailTemplate.contentHTML || ""}
					handleChange={handleEmailContentChange}
					toolbarOptions={["link"]}
				/>
			)}
			<div style={{ textAlign: "center" }}>
				<Button
					color="primary"
					variant="contained"
					size="large"
					disabled={!emailTemplate.isValid}
					onClick={(event) => updateEmailTemplate(event, emailTemplate)}
					className={classes.updateButton}
				>
					{t("common:update")}
				</Button>
				{emailTemplate.custom ? (
					<Button
						color="primary"
						variant="contained"
						size="large"
						onClick={(event) => restoreToDefault(event, emailTemplate)}
						className={classes.restoreButton}
					>
						{t("common:restoreToDefault")}
					</Button>
				) : null}
				{!emailTemplate.isValid ? (
					<Typography variant="h5" color="error">
						{t("validation:missingEmailTags")}
					</Typography>
				) : null}
			</div>
		</>
	);
};

export default EmailTemplateLocale;
