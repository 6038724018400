import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid, AppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as candidateApi from './../../api/candidateApi';

import { SET_CANDIDATE_CAMPAIGN } from './../../actions/candidateCampaignActions';

import CampaignInformation from './CampaignInformation';
import CandidateDetails from './CandidateDetails/';
import { TabPanel } from './../../components/TabPanel';
import CalendarView from './../../components/calendar/CalendarView';
import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';

import { useCampaignOrVacancyTranslationPrefix } from './../../utils/useSubscriptionPrefix';

import { TA_PRO } from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  gridRow: {
    marginTop: '20px'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  },
  tab: {
    color: theme.palette.primary.main
  }
}));

const CandidateCampaignDetail = ({ campaignId }) => {

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const classes = useStyles();

  const history = useHistory();

  const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'vacancies']);

  const dispatch = useDispatch();

  const candidateCampaign = useSelector((state) => state.candidateCampaign);
  const { consultancyId, consultancySubscriptionPlanId } = useSelector((state) => state.session);

  const [isLoading, setIsLoading] = useState(true);
  const [breadcrumbs, setBreadCrumbs] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);

  const tabs = [
    { value: 0, label: t(`${campaignsPrefix}:campaignDetailLabel`) },
    { value: 1, label: t(`${campaignsPrefix}:application`) }
  ];

  if (candidateCampaign.calendarEntries && candidateCampaign.calendarEntries.length > 0) {
    tabs.push({ value: 2, label: t(`${campaignsPrefix}:campaignInterviewScheduler`) });
  }

  useEffect(() => {
    getCandidateCampaignDetails(campaignId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBreadCrumbs([
      { title: t(`${campaignsPrefix}:campaigns`), link: '' },
      {
        title: candidateCampaign?.campaignTitle,
        link: consultancySubscriptionPlanId === TA_PRO ?
          `/vacancies/${candidateCampaign?.campaignId}` :
          `/campaigns/${candidateCampaign?.campaignId}`
      }
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateCampaign]);

  const getCandidateCampaignDetails = (campaignId) => {
    candidateApi.getCandidateCampaignDetails(campaignId, consultancyId)
      .then((response) => {
        dispatch({
          type: SET_CANDIDATE_CAMPAIGN,
          candidateCampaign: response.data
        });
      })
      .catch(() => {
        history.push('/dashboard');
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const applyToCampaignFunc = () => {
    setCurrentTab(1);
  };

  const handleChange = (value) => {
    setCurrentTab(value);
  };

  return (
    <div style={{ overflow: "hidden", paddingBottom: "20px" }}>
      <PageTitle breadcrumbs={breadcrumbs} />

      <ContentWrapper loading={ isLoading }>
        <Grid item xs={8} className={classes.gridRow}>
          <AppBar position="static" className={classes.appBar}>
            <CustomTabs tabs={ tabs } onChange={ handleChange } tabClassName={ classes.tab } />
          </AppBar>
        </Grid>

        <Grid item xs={12} className={classes.gridRow}>
          <TabPanel value={currentTab} index={0}>
            <CampaignInformation applyToCampaignFunc={applyToCampaignFunc} />
          </TabPanel>

          <TabPanel value={currentTab} index={1}>
            <CandidateDetails getCandidateCampaignDetails={getCandidateCampaignDetails} />
          </TabPanel>

          {
            candidateCampaign.calendarEntries && candidateCampaign.calendarEntries.length > 0 && (
              <TabPanel value={currentTab} index={2}>
                <CalendarView
                  calendarEntries={candidateCampaign.calendarEntries}
                  canUpdateCalendar={false} />
              </TabPanel>
            )
          }
        </Grid>
      </ContentWrapper>
    </div>
  );

}

export default CandidateCampaignDetail;
