import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import {
	Button,
	TextField,
	Typography,
	Grid,
	IconButton,
	InputAdornment,
} from "@material-ui/core";
import validate from "validate.js";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

const useStyles = makeStyles((theme) => ({
	form: {
		width: "100%",
		padding: 10,
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	addButton: {
		flex: 1,
		margin: 5,
	},
	header: {
		padding: 24,
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	categoriesTitle: {
		margin: "20px 0px",
	},
}));
const schema = {
	name: {
		presence: { allowEmpty: false, message: "is required" },
		length: {
			maximum: 32,
		},
	},
};

function EditDWICategoryModal({ category, onSuccess, open, handleClose }) {
	const [t] = useTranslation(["dwi", "common"]);
	const classes = useStyles();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			newCategory: {
				name: "",
				weight: "",
			},
		},
		touched: {},
		errors: {},
	});

	const handleChange = (event) => {
		event.persist();

		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));
	};

	const handleSubCategoryChange = (event, index) => {
		event.persist();
		let tempSubcategories = [...formState.values.subCategories];
		tempSubcategories[index][event.target.name] = event.target.value;
		setFormState((prevValues) => ({
			...prevValues,
			values: {
				...prevValues.values,
				subCategories: tempSubcategories,
			},
		}));
	};

	const deleteSubCategory = (event, index) => {
		event.persist();
		let tempSubcategories = [...formState.values.subCategories];
		tempSubcategories.splice(index, 1);
		setFormState((prevValues) => ({
			...prevValues,
			values: {
				...prevValues.values,
				subCategories: tempSubcategories,
			},
		}));
	};

	const handleNewSubCategoryChange = (event) => {
		event.persist();
		setFormState((prevValues) => ({
			...prevValues,
			values: {
				...prevValues.values,
				newCategory: {
					...prevValues.values.newCategory,
					[event.target.name]: event.target.value,
				},
			},
		}));
	};

	const addNewSubCategory = (event) => {
		event.preventDefault();
		let newCategory = formState.values.newCategory;
		let tempSubCategories = [...formState.values.subCategories];
		tempSubCategories.push(newCategory);
		setFormState((prevValues) => ({
			...prevValues,
			values: {
				...prevValues.values,
				subCategories: tempSubCategories,
				newCategory: {
					name: "",
					weight: "",
				},
			},
		}));
	};

	const hasError = (field) => {
		if (formState.touched[field] && formState.errors[field]) {
			return true;
		} else {
			return false;
		}
	};

	const onSubmit = (event) => {
		event.preventDefault();
		onSuccess({
			id: formState.values.id,
			name: formState.values.name,
			subCategories: formState.values.subCategories,
		});
	};

	useEffect(() => {
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...category,
				newCategory: {
					name: "",
					weight: "",
				},
			},
		}));
	}, [category]);

	useEffect(() => {
		if (open) {
			let subCategoryWeightingTotal = formState.values.subCategories.reduce(
				function(total, subCategory) {
					return total + Number(subCategory.weight);
				},
				0
			);
			const errors = validate(formState.values, schema);
			setFormState((prevFormState) => ({
				...prevFormState,
				isValid: !errors && subCategoryWeightingTotal === 100,
				errors: errors || {},
			}));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState.values]);

	return (
		<ModalFormWrapper
			formTitle={t("dwi:updateCategory")}
			handleOpen={open}
			handleClose={handleClose}
		>
			<form onSubmit={onSubmit}>
				<Typography variant="h4" className={classes.categoriesTitle}>
					{t("dwi:categoryName")}
				</Typography>
				<TextField
					fullWidth
					name="name"
					variant="outlined"
					required
					onChange={handleChange}
					error={hasError("name")}
					value={formState.values.name || ""}
					helperText={hasError("name") ? formState.errors.name[0] : null}
				/>
				<Typography variant="h5" className={classes.categoriesTitle}>
					{t("dwi:subCategory")}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={8}>
						<Typography variant="h5">{t("common:name")}</Typography>
					</Grid>
					<Grid item xs={3}>
						<Typography variant="h5">{t("dwi:defaultWeighting")}</Typography>
					</Grid>
					<Grid item xs={1}>
						<Typography variant="h5">{t("common:actions")}</Typography>
					</Grid>
					{formState.values.subCategories?.map((subCategory, index) => (
						<>
							<Grid item xs={8}>
								<TextField
									name="name"
									fullWidth
									variant="outlined"
									required
									value={subCategory.name}
									onChange={(event) => handleSubCategoryChange(event, index)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextField
									name="weight"
									fullWidth
									value={subCategory.weight}
									onChange={(event) => handleSubCategoryChange(event, index)}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">%</InputAdornment>
										),
									}}
									variant="outlined"
								/>
							</Grid>
							<Grid item xs={1}>
								<IconButton
									onClick={(event) => deleteSubCategory(event, index)}
									color="error"
								>
									<DeleteIcon />
								</IconButton>
							</Grid>
						</>
					))}
					<>
						<Grid item xs={8}>
							<TextField
								name="name"
								fullWidth
								variant="outlined"
								value={formState.values.newCategory.name}
								onChange={handleNewSubCategoryChange}
							/>
						</Grid>
						<Grid item xs={3}>
							<TextField
								name="weight"
								fullWidth
								value={formState.values.newCategory.weight}
								onChange={handleNewSubCategoryChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">%</InputAdornment>
									),
								}}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={1}>
							<IconButton onClick={addNewSubCategory} color="error">
								<SaveIcon />
							</IconButton>
						</Grid>
					</>
				</Grid>
				<Button
					className={classes.submitButton}
					color="primary"
					size="large"
					type="submit"
					variant="contained"
					disabled={!formState.isValid}
				>
					{t("dwi:updateCategory")}
				</Button>
			</form>
		</ModalFormWrapper>
	);
}
export default EditDWICategoryModal;
