import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Checkbox,
  FormControl,
  TextField,
  ListItemText,
  List,
  ListSubheader,
  ListItem,
  ListItemSecondaryAction,
  Tooltip,
} from "@material-ui/core";
import { FwdBackButtons } from "src/components/LayoutComponents/FwdBackButtons";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  fields: {
    position: "relative",
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  clientList: {
    maxHeight: "25vh",
    overflowY: "auto",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  titleTooltip: { display: "flex", justifyContent: "flex-start" },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  },
}));

function Details({
  setCurrentTab,
  handleContactChange,
  description,
  handleDescriptionChange,
  handleTitleChange,
  title,
}) {
  const classes = useStyles();
  const [t] = useTranslation(["campaigns", "common", "tooltips"]);

  const [checked, setChecked] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const campaign = useSelector((state) => state.campaign);

  const handleToggle = (contact) => () => {
    const currentIndex = checked.indexOf(contact);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(contact);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    const selectedIds = newChecked
      .map((e) => e.id)
      .filter((x) => {
        return x != null;
      });

    setSelectedContacts(Object.values(selectedIds));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleContactChange(selectedContacts);
    setCurrentTab(4);
  };

  const goBack = (event) => {
    event.preventDefault();
    setCurrentTab(2);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <div className={classes.titleTooltip}>
          <Tooltip
            enterTouchDelay={1}
            classes={{
              tooltip: classes.tooltip,
            }}
            title={t("tooltips:inputTitleAndDescriptionForInterview")}
          >
            <InfoIcon />
          </Tooltip>
        </div>

        <TextField
          fullWidth
          label={t("common:titleLabel")}
          name="title"
          value={title}
          onChange={handleTitleChange}
          variant="outlined"
        />
        <TextField
          fullWidth
          label={t("common:descriptionLabel")}
          name="description"
          value={description}
          onChange={handleDescriptionChange}
          multiline
          rows={4}
          variant="outlined"
        />
        <FormControl variant="outlined" fullWidth>
          <List
            subheader={
              <ListSubheader>
                {t("campaigns:selectClients")}
                <Tooltip
                  enterTouchDelay={1}
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  style={{ marginLeft: "10px" }}
                  title={t("tooltips:selectContactsForInterview")}
                >
                  <InfoIcon />
                </Tooltip>
              </ListSubheader>
            }
          >
            <div className={classes.clientList}>
              {campaign.campaignContacts.map((contact) => {
                const labelId = `checkbox-list-label-${contact.id}`;

                return (
                  <ListItem
                    key={contact}
                    dense
                    button
                    onClick={handleToggle(contact)}
                  >
                    <ListItemText
                      id={labelId}
                      primary={`${contact.firstName} ${contact.lastName}`}
                      secondary={contact.jobTitle}
                    />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        checked={checked.indexOf(contact) !== -1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                        onChange={handleToggle(contact)}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
            </div>
          </List>
        </FormControl>
      </div>
      <FwdBackButtons fwd backFunc={goBack} />
    </form>
  );
}
export default Details;
