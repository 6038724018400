import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Paper,
  Typography,
  Button,
  useTheme,
  useMediaQuery
} from "@material-ui/core";

import * as CookieActions from "./../../actions/cookieActions";
import rraLogo from "./../../assets/images/rralogofinal_green.png";

const useStyles = makeStyles(() => ({
  box: {
    width: '720px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '60px'
  },
  boxMobile: {
    flexDirection: 'column',
    width: '60%',
    margin: '60px 20% 0'
  },
  paper: {
    width: '100%',
    height: '100%',
    boxShadow: '0 0 3px rgb(0 0 0 / 24%)'
  },
  paperRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: '20% 0 60px'
  },
  logo: {
    height: "100%",
    width: "100%",
  },
  typography: {
    fontWeight: '500',
    marginBottom: '36px'
  }
}));

function GooneLoginButton() {

  const classes = useStyles();

  const [t] = useTranslation(["newTranslations"]);

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [goOneLoginUrl, setGoOneLoginUrl] = useState('');

  useEffect(() => {
    const stateStr = Buffer.from(
      JSON.stringify({
        userId: CookieActions.getCookie('userId'),
        domain: window.location.host,
        pathname: window.location.pathname
      })
    ).toString('base64')

    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('response_type', 'code');
    urlSearchParams.append('client_id', process.env.REACT_APP_GOONE_CLIENT_ID);
    urlSearchParams.append('scope', 'user.read account.read enrollment.read lo.read user.login user.login.me');
    urlSearchParams.append('redirect_uri', process.env.REACT_APP_GOONE_REDIRECT_URI);
    urlSearchParams.append('state', stateStr);

    setGoOneLoginUrl(`https://auth.go1.com/oauth/authorize?${urlSearchParams.toString()}`);
  }, []);

  const goToLoginPage = () => {
    window.location = goOneLoginUrl
  }

  return (
    <>
      <Box className={ `${classes.box} ${isMobileDevice ? classes.boxMobile : ''}` }>
        <Paper className={ classes.paper }>
          <img
            src={rraLogo}
            className={ classes.logo }
            alt="" />
        </Paper>

        <Paper className={ `${classes.paper} ${classes.paperRight}` }>
          <Typography
            className={ classes.typography }
            gutterBottom
            variant="h4"
            color="textPrimary"
            dangerouslySetInnerHTML={{ "__html": t("newTranslations:loginWithGo1Title") }}>
          </Typography>

          <Button
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            onClick={ goToLoginPage }>
            { t("newTranslations:gooneLogin") }
          </Button>
        </Paper>
      </Box>
    </>
  );
}

export default GooneLoginButton;
