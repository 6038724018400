import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  MenuItem,
  Typography,
  TextField,
  Grid,
  Button,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getCampaignStatuses, updateCampaignStatus } from "src/api/campaignApi";
import { useSnackbar } from "notistack";
import moment from "moment";
import Create from "@material-ui/icons/Create";
import { updateCampaignCompletion } from "src/api/campaignApi";
import { UPDATE_CAMPAIGN_STATUS } from "src/actions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

//- TODO: Translations

const CampaignStatus = ({ guestView }) => {
  let [statuses, setStatuses] = useState([]);
  const [t, i18n] = useTranslation([
    "campaigns",
    "common",
    "tooltips",
    "newTranslations",
    "vacancies"
  ]);
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const dispatch = useDispatch();
  let [campaignStatus, setCampaignStatus] = useState({});
  const sessionLanguage = useSelector((state) => state.session.language);
  const consultancyId = useSelector((state) =>
    guestView ? state.campaign.consultancyId : state.session.consultancyId
  );

  const { id: campaignId, completedAt, status } = useSelector(
    (state) => state.campaign
  );
  const { enqueueSnackbar } = useSnackbar();
  const [campaignCompleted, setCompleted] = useState({
    date: completedAt,
    touched: false,
  });
  const [defaultCompleted, setDefaultCompleted] = useState(completedAt);
  const [editComplete, setEditComplete] = useState(false);

  const getStatuses = () => {
    getCampaignStatuses({ campaignId, consultancyId })
      .then((response) => {
        if (response.status === 200) {
          setStatuses(response.data);
          setCampaignStatus(response.data.find((st) => st.id === status.id));
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Failed to get campaign statuses", {
          variant: "error",
        });
      });
  };

  const handleChange = (event) => {
    setCampaignStatus(event.target.value);
    dispatch({
      type: UPDATE_CAMPAIGN_STATUS,
      status: event.target.value,
    });
    updateCampaignStatus({
      campaignId,
      consultancyId,
      statusId: event.target.value.id,
    })
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Campaign Status Updated", {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Status Update Failed", {
          variant: "error",
        });
      });
  };

  const handleDateChange = (event) => {
    setCompleted({
      date: moment(event.target.value).format("YYYY-MM-DD"),
      touched: true,
    });
  };

  const cancelDateChange = () => {
    setCompleted({
      date: moment(defaultCompleted).format("YYYY-MM-DD"),
      touched: false,
    });
    setEditComplete(false);
  };

  const saveCompletionDate = () => {
    return updateCampaignCompletion(
      consultancyId,
      campaignId,
      campaignCompleted.date
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar("Campaign Status Updated", {
            variant: "success",
          });
          setCompleted({
            date: response.data,
            touched: true,
          });
          setDefaultCompleted(response.data);
          setEditComplete(false);
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Status Update Failed", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    getStatuses();
    i18n.changeLanguage(sessionLanguage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BlockHeader
        headerTooltip={t("tooltips:campaignStatusSelection")}
        card
        header={t(`${campaignsPrefix}:campaignStatus`)}
      />
      <Select
        fullWidth
        style={{ marginTop: "15px" }}
        variant="outlined"
        name={t(`${campaignsPrefix}:campaignStatus`)}
        onChange={handleChange}
        value={campaignStatus}
        disabled={guestView}
      >
        {statuses.map((status) => (
          <MenuItem key={status.id} value={status}>
            {t(`newTranslations:${(status?.status).toLowerCase()}`)}
          </MenuItem>
        ))}
      </Select>
      {campaignStatus.id === 4 && (
        <Grid
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "5px",
          }}
        >
          {editComplete ? (
            <>
              <TextField
                type="date"
                defaultValue={moment(campaignCompleted.date)
                  .format("YYYY-MM-DD")
                  .toString()}
                onChange={handleDateChange}
              />
              <Button
                color="primary"
                variant="contained"
                disabled={!campaignCompleted.touched}
                onClick={saveCompletionDate}
              >
                {t("common:save")}
              </Button>
              <Button onClick={cancelDateChange}>{t("common:cancel")}</Button>
            </>
          ) : (
            <>
              <Typography variant="h5" display="inline">
                {t(`${campaignsPrefix}:campaignCompletedOn`)}{" "}
                {moment(campaignCompleted.date).format("DD/MM/YYYY")}
              </Typography>
              <Create onClick={() => setEditComplete(true)} />
            </>
          )}
        </Grid>
      )}
    </div>
  );
};

export default CampaignStatus;
