import axios from '../utils/axios';

//requests both admin and consultant call
export const createAssignmentCampaign = (campaignData) => {
  return axios.post('/Campaigns/createAssignmentCampaign', campaignData);
};

export const getCampaigns = (consultancyId, isTalentAlert = false) => {
  return axios.get('/Campaigns/getCampaigns', {
    params: {
      consultancyId,
      isTalentAlert,
    },
  });
};

export const getCampaignTitle = (consultancyId, campaignId) => {
  return axios.get('/Campaigns/getCampaignTitle', {
    params: { consultancyId, campaignId },
  });
};

export const getCampaignDetails = (consultancyId, campaignId) => {
  return axios.get('/Campaigns/getCampaignDetails', {
    params: {
      consultancyId,
      campaignId,
    },
  });
};

export const updateCampaignDetails = ({
  campaignId,
  consultancyId,
  details,
}) => {
  return axios.put('/Campaigns/updateCampaignDetails', {
    campaignId,
    consultancyId,
    details,
  });
};

export const updateCampaignVisibility = ({
  manager,
  privateCampaign,
  assignedGroups,
  consultancyId,
  campaignId,
}) => {
  return axios.post('/Campaigns/updateCampaignVisibility', {
    manager,
    privateCampaign,
    assignedGroups,
    consultancyId,
    campaignId,
  });
};

export const saveCampaignDocument = ({
  consultancyId,
  campaignId,
  documentId,
  formData,
}) => {
  return axios
    .post('/CampaignDocuments/saveCampaignDocument', formData, {
      params: {
        consultancyId,
        documentId,
        campaignId,
      },
    })
    .then((response) => {
      if (response.data) return response.data;
      else throw Error('Failed to update Campaign Document');
    });
};

export const updateCampaignDocument = ({
  documentId,
  consultancyId,
  documentTitle,
  visibility,
}) => {
  return axios
    .put('/CampaignDocuments/updateCampaignDocument', {
      documentId,
      consultancyId,
      documentTitle,
      visibility,
    })
    .then((response) => {
      if (response.data) return response.data;
      else throw Error('Failed to update Campaign Document');
    });
};

export const deleteCampaignDocument = ({
  campaignId,
  documentId,
  consultancyId,
}) => {
  return axios.delete('/CampaignDocuments/deleteCampaignDocument', {
    params: {
      campaignId,
      documentId,
      consultancyId,
    },
  });
};

export const updatePrimaryContact = (consultancyId, campaignId, contactId) => {
  return axios
    .put('/Campaigns/updatePrimaryContact', null, {
      params: {
        consultancyId,
        campaignId,
        contactId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to update primary contact');
      }
    });
};

export const updateCampaignInformationBlocks = (
  consultancyId,
  campaignId,
  campaignBlocks
) => {
  return axios.put(
    '/CampaignInformationBlocks/updateCampaignInformationBlocks',
    null,
    {
      params: {
        consultancyId,
        campaignId,
        campaignBlocks,
      },
    }
  );
};

export const createAndAssignConsultancyCandidate = ({
  consultancyId,
  campaignId,
  firstName,
  lastName,
  postalCode,
  email,
  country,
}) => {
  return axios.post('/Campaigns/createAndAssignConsultancyCandidate', {
    consultancyId,
    campaignId,
    firstName,
    lastName,
    postalCode,
    email,
    countryId: country.id,
  });
};

export const assignExistingCandidateToCampaign = ({
  consultancyId,
  campaignId,
  selectedCandidates,
}) => {
  return axios.post('/Campaigns/assignExistingCandidateToCampaign', {
    consultancyId,
    campaignId,
    selectedCandidates,
  });
};

export const getCampaignInvitationDetails = (consultancyId, campaignId) => {
  return axios.get('/Campaigns/getCampaignInvitationDetails', {
    params: {
      consultancyId,
      campaignId,
    },
  });
};

export const sendCampaignCandidatesInvitations = (
  { emailSubject, emailContent, selectedCandidates },
  consultancyId,
  campaignId
) => {
  return axios.post(
    '/Campaigns/sendCampaignCandidatesInvitations',
    { emailSubject, emailContent, selectedCandidates },
    {
      params: {
        campaignId,
        consultancyId,
      },
    }
  );
};

export const getContacts = (campaignId) => {
  return axios
    .get('/Campaigns/getContacts', {
      params: {
        campaignId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get contacts');
      }
    });
};

export const getContactsConnectedToCampaign = (campaignId) => {
  return axios
    .get('/Campaigns/getContactsConnectedToCampaign', {
      params: {
        campaignId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get all contacts');
      }
    });
};

export const getContactsToAdd = (campaignId) => {
  return axios
    .get('/Campaigns/getContactsToAdd', {
      params: {
        campaignId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

export const addContact = (campaignId, contactId) => {
  return axios
    .post('/Campaigns/addContact', null, {
      params: {
        campaignId,
        contactId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

export const createAndAddContact = (
  campaignId,
  {
    firstName,
    lastName,
    salutation,
    email,
    telephone,
    consultancyId,
    employerId,
    jobTitle,
  }
) => {
  return axios
    .post(
      '/Campaigns/createAndAddContact',
      {
        firstName,
        lastName,
        salutation,
        email,
        telephone,
        consultancyId,
        employerId,
        jobTitle,
      },
      {
        params: {
          campaignId,
        },
      }
    )
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

export const removeContact = (campaignId, campaignContactId) => {
  return axios
    .delete('/Campaigns/removeContact', {
      params: {
        campaignId,
        campaignContactId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error('Failed to get addable contacts');
      }
    });
};

export const updateCampaignTermsAndConditions = (
  campaignId,
  termsAndConditions,
  termsAndConditionsType
) => {
  return axios.post('/Campaigns/updateCampaignTermsAndConditions', {
    campaignId,
    campaignTermsAndConditions: termsAndConditions,
    termsAndConditionsType,
  });
};

export const updateCampaignBgRequirements = (
  campaignId,
  campaignAssignment,
  assignmentType
) => {
  return axios.post('/Campaigns/updateCampaignBgRequirements', {
    campaignId,
    campaignAssignment,
    assignmentType,
  });
};

export const assignKCQToCampaign = (
  {
    inviteContacts,
    useCustomSet,
    createContact,
    selectedContact,
    selectedCustomQuestionSet,
    selectedQuestions,
    questionSetTitle,
  },
  consultancyId,
  campaignId
) => {
  return axios.post(
    '/Campaigns/assignKCQToCampaign',
    {
      inviteContacts,
      useCustomSet,
      createContact,
      selectedContact,
      selectedCustomQuestionSet,
      selectedQuestions,
      questionSetTitle,
    },
    {
      params: {
        campaignId,
        consultancyId,
      },
    }
  );
};

export const sendCampaignContactInvitations = (
  { emailSubject, emailContent, selectedContacts },
  consultancyId,
  campaignId
) => {
  return axios.post(
    '/Campaigns/sendCampaignContactInvitations',
    { emailSubject, emailContent, selectedContacts },
    {
      params: {
        campaignId,
        consultancyId,
      },
    }
  );
};

export const updateCampaignKCQ = (campaignId, consultancyId, questions) => {
  return axios.post('/Campaigns/updateCampaignKCQ', {
    campaignId,
    consultancyId,
    questions,
  });
};

export const deleteCampaignKCQ = (campaignId, consultancyId) => {
  return axios.delete('/Campaigns/deleteCampaignKCQ', {
    params: {
      campaignId,
      consultancyId,
    },
  });
};

export const updateCampaignDWICategories = (
  campaignId,
  consultancyId,
  dwiCategories
) => {
  return axios.post('/CampaignDWIs/updateCampaignDWICategories', {
    campaignId,
    consultancyId,
    dwiCategories,
  });
};

export const updateCampaignContactWeights = (
  consultancyId,
  campaignId,
  contacts
) => {
  return axios.put('/CampaignContacts/updateCampaignContactWeights', contacts, {
    params: {
      consultancyId,
      campaignId,
    },
  });
};

export const saveRequiredCandidateDocument = (
  consultancyId,
  campaignId,
  document
) => {
  return axios.post(
    '/CampaignRequiredDocuments/saveRequiredCandidateDocument',
    {
      consultancyId,
      campaignId,
      document,
    }
  );
};

export const removeRequiredCandidateDocument = (
  consultancyId,
  campaignId,
  documentId
) => {
  return axios.delete(
    '/CampaignRequiredDocuments/removeRequiredCandidateDocument',
    {
      params: {
        consultancyId,
        campaignId,
        documentId,
      },
    }
  );
};

export const fetchDocumentTypes = (consultancyId) => {
  return axios.get('/DocumentTypes/fetchDocumentTypes', {
    params: {
      consultancyId,
    },
  });
};

export const getModulesForCampaign = (consultancyId, campaignId) => {
  return axios.get('/CampaignModules/getModulesForCampaign', {
    params: {
      consultancyId,
      campaignId,
    },
  });
};

export const toggleCampaignModule = (
  id,
  consultancyId,
  campaignId,
  isActive
) => {
  return axios.put('/CampaignModules/toggleModuleState', {
    id,
    consultancyId,
    campaignId,
    isActive,
  });
};

export const saveCampaignMcQuaig = ({
  campaignId,
  consultancyId,
  mcQuaigId,
  mcQuaig,
}) => {
  return axios.post('/CampaignMcQuaigs/saveCampaignMcQuaig', {
    campaignId,
    consultancyId,
    mcQuaigId,
    mcQuaig,
  });
};

export const getCampaignStatuses = ({ campaignId, consultancyId }) => {
  return axios.get('/CampaignStatuses/getCampaignStatuses', {
    params: { campaignId, consultancyId },
  });
};

export const updateCampaignStatus = ({
  campaignId,
  consultancyId,
  statusId,
}) => {
  return axios.put('/CampaignStatuses/updateCampaignStatus', {
    consultancyId,
    campaignId,
    statusId,
  });
};

export const updateCampaignCompletion = (consultancyId, campaignId, date) => {
  return axios.put('/CampaignStatuses/updateCampaignCompletion', {
    consultancyId,
    campaignId,
    date,
  });
};


export const getCampaignCalendarEntries = (consultancyId, campaignId) => {
  return axios.get('/CalendarEntries/getCampaignCalendarEntries', {
    params: {
      consultancyId,
      campaignId,
    },
  });
};

export const updateCalendarEntry = (
  consultancyId,
  campaignId,
  calendarEntryValues
) => {
  return axios.post(
    '/CalendarEntries/updateCalendarEntry',
    {
      ...calendarEntryValues,
    },
    {
      params: {
        consultancyId,
        campaignId,
      },
    }
  );
};

export const confirmContactCalendarEntry = (
  consultancyId,
  campaignId,
  eventId
) => {
  return axios.post('/CalendarEntries/confirmContactCalendarEntry', {
    consultancyId,
    campaignId,
    eventId,
  });
};

export const confirmCandidateCalendarEntry = (
  consultancyId,
  campaignId,
  eventId
) => {
  return axios.post('/CalendarEntries/confirmCandidateCalendarEntry', {
    consultancyId,
    campaignId,
    eventId,
  });
};

export const rejectCandidateCalendarEntry = (
  consultancyId,
  campaignId,
  eventId
) => {
  return axios.post('/CalendarEntries/rejectCandidateCalendarEntry', {
    consultancyId,
    campaignId,
    eventId,
  });
};

export const cancelCalendarEntry = (consultancyId, campaignId, eventId) => {
  return axios.post('/CalendarEntries/cancelCalendarEntry', {
    consultancyId,
    campaignId,
    eventId,
  });
};

export const createSingleCalendarEntry = (
  consultancyId,
  campaignId,
  calendarEntryValues
) => {
  return axios.post(
    '/CalendarEntries/createSingleCalendarEntry',
    {
      ...calendarEntryValues,
    },
    {
      params: {
        consultancyId,
        campaignId,
      },
    }
  );
};

export const removeCandidateFromEntry = (
  consultancyId,
  campaignId,
  eventId
) => {
  return axios.post('/CalendarEntries/removeCandidateFromEntry', {
    consultancyId,
    campaignId,
    eventId,
  });
};

export const uploadCampaignLogo = (consultancyId, campaignId, formData) => {
  return axios.post('/Campaigns/uploadCampaignLogo', formData, {
    params: { consultancyId, campaignId },
  });
};

//- Consultancy Relations

export const getChildCampaigns = (consultancyId, isTalentAlert = false) => {
  return axios.get('/ConsultancyRelations/getChildCampaigns', {
    params: { consultancyId, isTalentAlert },
  });
};

export const getChildCampaignDetails = (consultancyId, campaignId) => {
  return axios.get('/ConsultancyRelations/getChildCampaignDetails', {
    params: {
      consultancyId,
      campaignId,
    },
  });
};

export const createMultipleCalendarEntries = (
  consultancyId,
  campaignId,
  calendarEntryDetails
) => {
  return axios.post(
    '/CalendarEntries/createMultipleCalendarEntries',
    {
      ...calendarEntryDetails,
    },
    {
      params: {
        consultancyId,
        campaignId,
      },
    }
  );
};

export const getCampaignsManagedBySelectedUser = (userId, consultancyId) => {
  return axios.get('/Campaigns/getCampaignsManagedBySelectedUser', {
    params: {
      userId,
      consultancyId,
    },
  });
};

export const renumerations = (campaignId, consultancyId, renumerationData) => {
  return axios.put('/Campaigns/renumerations', renumerationData, {
    params: {
      campaignId,
      consultancyId,
    },
  });
};

export const deleteCampaign = (consultancyId, campaignId) => {
  return axios.delete('/Campaigns/deleteCampaign', {
    params: {
      campaignId,
      consultancyId,
    },
  });
};

export const saveCandidateDocumentsPriorityOrder = (
  consultancyId,
  campaignId,
  newPriorityDocuments
) => {
  return axios.post(
    '/CampaignRequiredDocuments/saveCandidateDocumentsPriorityOrder',
    {
      consultancyId,
      campaignId,
      newPriorityDocuments,
    }
  );
};

export const saveCampaignDocumentsPriorityOrder = (
  consultancyId,
  campaignId,
  newPriorityDocuments
) => {
  return axios.post('/CampaignDocuments/saveCampaignDocumentsPriorityOrder', {
    consultancyId,
    campaignId,
    newPriorityDocuments,
  });
};

export const inviteContactToSelectKCQs = (
  {
    inviteContacts,
    useCustomSet,
    createContact,
    selectedContact,
    selectedCustomQuestionSet,
    selectedQuestions,
    questionSetTitle,
    firstName,
    lastName,
    salutation,
    jobTitle,
    email,
    telephone
  },
  consultancyId,
  campaignId
) => {
  return axios.post(
    '/Campaigns/inviteContactToSelectKCQs',
    {
      inviteContacts,
      useCustomSet,
      createContact,
      selectedContact,
      selectedCustomQuestionSet,
      selectedQuestions,
      questionSetTitle,
      firstName,
      lastName,
      salutation,
      jobTitle,
      email,
      telephone
    },
    {
      params: {
        campaignId,
        consultancyId,
      },
    }
  );
};

export const inviteContactToSelectKCQSets = (
  {
    inviteContacts,
    useCustomSet,
    createContact,
    selectedContact,
    selectedCustomQuestionSet,
    selectedQuestions,
    questionSetTitle,
    firstName,
    lastName,
    salutation,
    jobTitle,
    email,
    telephone,
    contactPreselection
  },
  consultancyId,
  campaignId
) => {
  return axios.post(
    '/Campaigns/inviteContactToSelectKCQSets',
    {
      inviteContacts,
      useCustomSet,
      createContact,
      selectedContact,
      selectedCustomQuestionSet,
      selectedQuestions,
      questionSetTitle,
      firstName,
      lastName,
      salutation,
      jobTitle,
      email,
      telephone,
      contactPreselection
    },
    {
      params: {
        campaignId,
        consultancyId,
      },
    }
  );
};

// Campaign Candidate Video
export const uploadCampaignCandidateVideo = (body, uploadOptions) => {
  return axios
    .post('/CampaignCandidateVideos/uploadCampaignCandidateVideo', body, uploadOptions)
    .then(response => {
      return response;
    })
    .catch(error => {
      console.error(error);
    });
};

export const cloudinaryWidgetUploadCampaignCandidateVideo = (data, uploadOptions) => {
  return axios.post('/CampaignCandidateVideos/cloudinaryWidgetUploadCampaignCandidateVideo', data, uploadOptions);
};

export const updateCampaignCandidateVideoVisibility = (campaignId, consultancyId, visibility) => {
  return axios.post(
    '/CampaignCandidateVideos/updateVisibility',
    { visibility },
    {
      params: {
        campaignId,
        consultancyId,
      },
    },
  );
};
