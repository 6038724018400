import React, { useState, useRef, useEffect } from "react";
import moment from "moment-timezone";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timelinePlugin from "@fullcalendar/timeline";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import Header from "./Header";
import Toolbar from "./Toolbar";
import ViewEventModal from "./ViewEventModal";
import EditModal from "./EditModal";
import CreateMultipleEventModal from "./CreateMultipleEventModal";
import { ButtonWrapper } from "src/components/LayoutComponents/ButtonWrapper";
import { CONTACT } from "src/constants/roles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
  },

  calendar: {
    "& .fc-unthemed th": {
      borderColor: theme.palette.divider,
    },
    "& .fc-unthemed td": {
      borderColor: theme.palette.divider,
    },
    "& .fc-unthemed td.fc-today": {
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-head": {
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-body": {
      backgroundColor: theme.palette.background.default,
    },
    "& .fc-axis": {
      ...theme.typography.body2,
    },
    "& .fc-list-item-time": {
      ...theme.typography.body2,
    },
    "& .fc-list-item-title": {
      ...theme.typography.body1,
    },
    "& .fc-list-heading-main": {
      ...theme.typography.h6,
    },
    "& .fc-list-heading-alt": {
      ...theme.typography.h6,
    },
    "& .fc th": {
      borderColor: theme.palette.divider,
    },
    "& .fc-day-header": {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.text.secondary,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-day-top": {
      ...theme.typography.body2,
    },
    "& .fc-highlight": {
      backgroundColor: theme.palette.background.dark,
    },
    "& .fc-event": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 2,
      padding: 8,
      opacity: 0.9,
      cursor: "pointer",
      "& .fc-time": {
        ...theme.typography.h6,
        color: "inherit",
      },
      "& .fc-title": {
        ...theme.typography.body1,
        color: "inherit",
      },
    },
    "& .fc-list-empty": {
      ...theme.typography.subtitle1,
    },
  },
}));

function CalendarView({ calendarEntries, canUpdateCalendar, ...rest }) {
  const classes = useStyles();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const sessionLanguage = useSelector((state) => state.session.language);
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [view, setView] = useState(mobileDevice ? "listWeek" : "dayGridMonth");
  const [date, setDate] = useState(moment().toDate());
  const [events, setEvents] = useState([...calendarEntries]);
  const [t, i18n] = useTranslation(["campaigns"]);
  const [selectedTimezone, setSelectedTimezone] = useState(
    JSON.parse(localStorage.getItem("timeZone")) || {
      value: "GMT",
      label: "(GMT+0:00) Dublin, Edinburgh, Lisbon, London",
    }
  );
  const roleType = useSelector((state) => state.session.roleType);

  const handleTimeZoneChange = (value) => {
    localStorage.setItem("timeZone", JSON.stringify(value));
    setSelectedTimezone(value);
  };

  const [viewModal, setViewModal] = useState({
    event: null,
    open: false,
  });
  const [editModal, setEditModal] = useState({
    event: null,
    open: false,
  });

  const [createMultipleEventModal, setCreateMultipleEventModal] = useState(
    false
  );

  const resetViewModal = () => {
    setViewModal({
      event: null,
      open: false,
    });
  };
  const resetEditModal = () => {
    setEditModal({
      event: null,
      open: false,
    });
  };

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleEventAddClick = () => {
    setEditModal({
      event: {
        allDay: false,
        description: "",
        end: moment()
          .add(30, "minutes")
          .toDate(),
        start: moment().toDate(),
        title: "",
        contacts: [],
        candidate: null,
      },
      open: true,
      mode: "create",
    });
  };

  const handleEventAddMultipleClick = () => {
    setCreateMultipleEventModal(true);
  };

  const handleSlotsSelect = (arg) => {
    if (roleType !== CONTACT) {
      const calendarEl = calendarRef.current;

      if (calendarEl) {
        const calendarApi = calendarEl.getApi();

        calendarApi.unselect();
      }

      let todaysDate = new Date().getTime();
      let eventStartDate = new Date(arg.start).getTime();

      if (canUpdateCalendar && todaysDate < eventStartDate) {
        setEditModal({
          event: {
            allDay: arg.allDay,
            description: "",
            end: arg.end,
            start: arg.start,
            title: "",
            contacts: [],
            candidate: null,
          },
          open: true,
          mode: "create",
        });
      }
    }
  };

  const handleEventSelect = (arg) => {
    const event = events.find((e) => e.id === Number(arg.event.id));
    let todaysDate = new Date().getTime();
    let eventStartDate = new Date(event.start).getTime();

    if (canUpdateCalendar && todaysDate < eventStartDate && !event.cancelled) {
      setEditModal({
        event: { ...event },
        open: true,
        mode: "edit",
      });
    } else {
      setViewModal({
        event: { ...event },
        open: true,
      });
    }
  };

  const handleModalClose = () => {
    resetViewModal();
    resetEditModal();
    setCreateMultipleEventModal(false);
  };

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = mobileDevice ? "listWeek" : "dayGridMonth";

      calendarApi.changeView(newView);
      setView(newView);
      i18n.changeLanguage(sessionLanguage);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileDevice]);

  useEffect(() => {
    let tempEvents = calendarEntries?.map((event) => {
      return {
        ...event,
        meridiem: "short",
        start: moment(event.start)
          .tz(selectedTimezone.value)
          .format(),
        end: moment(event.end)
          .tz(selectedTimezone.value)
          .format(),
        color:
          event.color === "disabled"
            ? theme.palette.action.disabledBackground
            : theme.palette[event.color].main,
        textColor:
          event.color === "disabled"
            ? theme.palette.text.disabled
            : theme.palette[event.color].contrastText,
      };
    });
    i18n.changeLanguage(sessionLanguage);
    setEvents([...tempEvents]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarEntries, selectedTimezone]);

  if (!events) {
    return null;
  }

  return (
    <>
      {canUpdateCalendar && (
        <ButtonWrapper
          btnLabel={t("campaigns:createMultipleEvents")}
          btnFunc={handleEventAddMultipleClick}
          btnVariant="contained"
          btnLabel2={t("campaigns:createEvent")}
          btnFunc2={roleType === CONTACT ? null : handleEventAddClick}
          btnVariant2="contained"
          btnHidden2={roleType === CONTACT}
        />
      )}
      <Header
        onEventAdd={handleEventAddClick}
        onEventAddMultiple={handleEventAddMultipleClick}
        selectedTimezone={selectedTimezone}
        setSelectedTimezone={handleTimeZoneChange}
        guestView={rest?.guestView}
      />
      {!mobileDevice && (
        <Toolbar
          date={date}
          onDateNext={handleDateNext}
          onDatePrev={handleDatePrev}
          onDateToday={handleDateToday}
          onViewChange={handleViewChange}
          view={view}
          headerTooltip={false}
        />
      )}
      <Paper className={classes.calendar} component={Box} mt={3} p={2}>
        <FullCalendar
          timeZone={selectedTimezone.value}
          allDayMaintainDuration
          defaultDate={date}
          defaultView={view}
          eventClick={rest?.guestView ?? handleEventSelect}
          eventLimit
          events={events}
          header={false}
          height={800}
          ref={calendarRef}
          rerenderDelay={10}
          select={rest?.guestView ?? handleSlotsSelect}
          selectable
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPlugin,
            timelinePlugin,
            momentTimezonePlugin,
          ]}
        />
      </Paper>
      <CreateMultipleEventModal
        open={createMultipleEventModal}
        onCancel={handleModalClose}
        timeZone={selectedTimezone.value}
      />
      <EditModal
        open={editModal.open}
        onCancel={handleModalClose}
        event={editModal.event}
        mode={editModal.mode}
        timeZone={selectedTimezone.value}
      />
      <ViewEventModal
        open={viewModal.open}
        onCancel={handleModalClose}
        event={viewModal.event}
        timeZone={selectedTimezone.value}
      />
    </>
  );
}

export default CalendarView;
