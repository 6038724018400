import React, { useEffect } from 'react';

const Actions = ({ props }) => {

  useEffect(() => {
    props.onTurnOnCamera();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    props.onTurnOffCamera();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <></>
  );

};

export default Actions;
