import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { AppBar, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as systemAdminApi from './../../api/SystemAdminApi';

import { ToggleCard } from './../../components/Cards/ToggleCard';
import { useSearch } from './../../components/Filter/Search';
import { ContentWrapper } from './../../components/LayoutComponents/ContentWrapper';
import CustomTabs from './../../components/LayoutComponents/CustomTabs';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';
import { TabPanel } from './../../components/TabPanel';

const useStyles = makeStyles(() => ({
	appBar: {
		boxShadow: 'none',
		backgroundColor: 'transparent'
	}
}));

function CampaignComponents() {

	const classes = useStyles();

	const [t] = useTranslation(['settings', 'campaigns']);

	const [availableModules, setAvailableModules] = useState([]);
	const [activeModules, setActiveModules] = useState([]);
	const [inactiveModules, setInactiveModules] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [searchTerm] = useState('');

	const moduleTabs = [
		{ value: 0, label: t('settings:availableModules') },
		{ value: 1, label: t('settings:activeModules') },
		{ value: 2, label: t('settings:inactiveModules') }
	];

	useEffect(() => {
		getAvailableModules();
	}, []);

	const getSearchData = () => {
		if (currentTab === 0) return availableModules;
		if (currentTab === 1) return activeModules;

		return inactiveModules;
	};

	const filteredResults = useSearch(getSearchData(), searchTerm, ["moduleName"]).searchResults();

	const getAvailableModules = () => {
		systemAdminApi.getAvailableModules()
			.then((response) => {
				const data = response.data;
				const active = data.filter((e) => e.isEnabled === true);
				const inactive = data.filter((e) => e.isEnabled === false);

				setAvailableModules(data);
				setActiveModules(active);
				setInactiveModules(inactive);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleStatusChange = (id, isEnabled) => {
		systemAdminApi.toggleModuleIsActive(id, isEnabled)
			.then((response) => {
				if (response) {
					getAvailableModules();
				}
			});
	}

	const handleTabChange = (value) => {
		setCurrentTab(value);
	};

	return (
		<>
			<PageTitle pageTitle={t("campaigns:moduleManagementLabel")} />

			<ContentWrapper loading={!availableModules ? true : false}>
				<Grid container xs={12} display="flex" alignItems="center">
					{/* <Searchbar onChange={handleInputChange} /> //? Remove filter functionality from this view or leavbe in for possible future use ?? */}
				</Grid>

				<AppBar position="static" className={classes.appBar}>
					<CustomTabs tabs={ moduleTabs } onChange={ handleTabChange } tabClassName={ classes.tab } />
				</AppBar>

				<TabPanel value={currentTab} index={0}>
					<Grid container spacing={3}>
						{filteredResults.map((module) => (
							<ToggleCard
								sysAdmin={true}
								name={module.moduleName}
								detail={module.detail}
								enabled={module.isEnabled}
								handleSwitch={() => { handleStatusChange(module.id, !module.isEnabled); }} />
						))}
					</Grid>
				</TabPanel>

				<TabPanel value={currentTab} index={1}>
					<Grid container spacing={3}>
						{filteredResults.map((module) => (
							<ToggleCard
								sysAdmin={true}
								name={module.moduleName}
								detail={module.detail}
								enabled={module.isEnabled}
								handleSwitch={() => { handleStatusChange(module.id, !module.isEnabled); }} />
						))}
					</Grid>
				</TabPanel>

				<TabPanel value={currentTab} index={2}>
					<Grid container spacing={3}>
						{filteredResults.map((module) => (
							<ToggleCard
								sysAdmin={true}
								name={module.moduleName}
								detail={module.detail}
								enabled={module.isEnabled}
								handleSwitch={() => { handleStatusChange(module.id, !module.isEnabled); }} />
						))}
					</Grid>
				</TabPanel>
			</ContentWrapper>
		</>
	);

}

export default CampaignComponents;
