import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import {
  TextField,
  Button,
  colors,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Tooltip,
} from '@material-ui/core';
import validate from 'validate.js';
import * as ConsultancyApi from 'src/api/consultancyApi';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@material-ui/icons/Info';
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: '0px 10px',
  },
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%',
  },
  modalCard: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up('md')]: {
      width: '50%',
      transform: `translate(${50}%, ${10}%)`,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  closeButton: {
    minWidth: '32px',
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  modalOverflow: {
    overflowY: 'scroll',
  },
  tooltip: {
    textAlign: 'center',
    padding: 2,
    margin: 0,
  },
}));

const privateCampaignSchema = {
  campaignTitle: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  selected: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

const groupCampaignSchema = {
  campaignTitle: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  selected: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  groups: {
    presence: { allowEmpty: false, message: 'is required' },
  },
};

function CreateCampaignForm({ onSubmit }) {
  const classes = useStyles();

  const [t] = useTranslation(['common', 'validation', 'campaigns', 'tooltips', 'vacancies']);
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const [myGroups, setMyGroups] = useState([]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      groups: [],
      documents: [],
      selected: 'PleaseSelect',
      private: true,
    },
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePrivateChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        groups: [],
        private: event.target.value === 'PrivateCampaign' ? true : false,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let schema;
    switch (formState.values.selected) {
      case 'PrivateCampaign':
        schema = privateCampaignSchema;
        break;
      case 'GroupCampaign':
        schema = groupCampaignSchema;
        break;
      default:
        break;
    }
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(formState.values);
  };

  const getMyGroups = (consultancyId) => {
    return ConsultancyApi.getMyGroups(consultancyId).then((response) => {
      setMyGroups(response.data);
    });
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getMyGroups(consultancyId);
    }
    return () => {
      mounted = false;
    };
  }, [consultancyId]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.fields}>
        <TextField
          fullWidth
          label={t('common:jobPositionLabel')}
          name='campaignTitle'
          variant='outlined'
          required
          onChange={handleChange}
          error={hasError('campaignTitle')}
          value={formState.values.campaignTitle || ''}
          helperText={
            hasError('campaignTitle')
              ? t(`validation:${formState.errors.campaignTitle[0]}`)
              : null
          }
        />
        <TextField
          fullWidth
          label={t('common:referenceLabel')}
          name='reference'
          variant='outlined'
          onChange={handleChange}
          error={hasError('reference')}
          value={formState.values.reference || ''}
          helperText={
            hasError('reference')
              ? t(`validation:${formState.errors.reference[0]}`)
              : null
          }
        />
        <FormControl
          variant='outlined'
          fullWidth
          className={classes.formControl}
        >
          <div style={{ position: 'relative' }}>
            <InputLabel
              required
              id='accessLabel'
              style={{ backgroundColor: 'white' }}
            >
              {t(`${campaignsPrefix}:campaignVisibilitySelection`)}
            </InputLabel>
            <div
              style={{ position: 'absolute', top: -8, left: 190, zIndex: 100 }}
            >
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                title={t('tooltips:campaignVisibility')}
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </div>
          <Select
            name={'selected'}
            labelId='accessLabel'
            value={formState.values.selected}
            onChange={handlePrivateChange}
          >
            <MenuItem value={'PleaseSelect'}>
              {`${t(`${campaignsPrefix}:pleaseSelect`)} *`}
            </MenuItem>

            <MenuItem value={'PrivateCampaign'}>
              {t(`${campaignsPrefix}:campaignVisibilitySelectionPrivate`)}
            </MenuItem>
            <MenuItem value={'GroupCampaign'}>
              {t(`${campaignsPrefix}:campaignVisibilitySelectionGroup`)}
            </MenuItem>
          </Select>
        </FormControl>
        {formState.values.selected === 'GroupCampaign' ? (
          <FormControl
            variant='outlined'
            fullWidth
            className={classes.formControl}
          >
            <InputLabel
              required
              id='groupLabel'
              style={{ backgroundColor: 'white' }}
            >
              {t(`${campaignsPrefix}:selectGroupsLabel`)}
            </InputLabel>
            <Select
              labelId='groupLabel'
              name={'groups'}
              multiple
              value={formState.values.groups}
              onChange={handleChange}
              input={<OutlinedInput />}
            >
              {myGroups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.groupName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button
          className={classes.submitButton}
          color='primary'
          size='medium'
          type='submit'
          variant='contained'
          fullWidth
          disabled={
            !formState.isValid || formState?.values?.selected === 'PleaseSelect'
          }
        >
          {t('common:next')}
        </Button>
      </div>
    </form>
  );
}
export default CreateCampaignForm;
