import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import SaveTextButton from "src/components/LayoutComponents/SaveTextButton";
import { ThemeIconButton } from "./ThemeIconButton";
import { useTranslation } from "react-i18next";
import InfoIcon from "@material-ui/icons/Info";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  headerAccent: {
    height: 20,
    width: 4,
    backgroundColor: theme.palette.primary.main,
    marginRight: 6,
  },
  header: {
    fontSize: 20,
    letterSpacing: 0,
  },
  headerGridPadding: {
    padding: 16
  },
  headerGridDisplay: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  saveIcon: {
    fontSize: "38px",
  },
  headerTooltip: { marginLeft: "10px" },
  tooltip: {
    textAlign: "center",
    padding: 2,
    margin: 0,
  }
}));

export const BlockHeader = ({
  header,
  btnLabel,
  btnFunc,
  btnDisabled,
  btnHidden,
  btnVariant,
  btnLabel2,
  btnFunc2,
  btnDisabled2,
  btnVariant2,
  reduceMargin,
  card,
  defeatMargin,
  defeatMarginBottom,
  saveButton,
  fullWidth,
  icon,
  tooltip,
  headerTooltip,
  closeButton,
  padding,
}) => {
  const classes = useStyles();
  const btn = btnLabel != null;
  const btn2 = btn && btnLabel2 != null ? true : false;

  const [t] = useTranslation(["common", "validation", "campaigns"]);

  if (icon || closeButton) {
    return (
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        className={`${padding ? classes.headerGridPadding : null} ${closeButton ? classes.headerGridDisplay : null}`}
      >
        <Grid item>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div className={classes.headerAccent} />

            <Typography className={classes.header} variant="h3">
              {header}
            </Typography>

            {headerTooltip && (
              <Tooltip
                enterTouchDelay={1}
                classes={{
                  tooltip: classes.tooltip,
                }}
                className={classes.headerTooltip}
                title={headerTooltip}
              >
                <InfoIcon />
              </Tooltip>
            )}
          </Box>
        </Grid>
        {btn && !btnHidden ? (
          <Grid item>
            <Button
              color="primary"
              size="large"
              type="submit"
              variant={btnVariant || "outlined"}
              fullWidth
              disabled={btnDisabled ? true : false}
              onClick={btnFunc}
            >
              {btnLabel || t("common:save")}
            </Button>
          </Grid>
        ) : null}

        {
          icon && !btnLabel && !btnHidden && (
            <Grid item>
              <ThemeIconButton
                icon={icon}
                btnFunc={btnFunc}
                disabled={btnDisabled}
                tooltip={tooltip}
              />
            </Grid>
          )
        }

        {
          closeButton && !btnLabel && !btnHidden && (
            <Grid item>
              <ThemeIconButton
                icon={icon}
                btnFunc={btnFunc}
                disabled={btnDisabled}
                closeButton={true}
              />
            </Grid>
          )
        }
      </Grid>
    );
  } else {
    return (
      <Container
        className={classes.container}
        maxWidth="xl"
        style={
          defeatMargin ?
          { marginTop: 0, marginBottom: 0 } :
          defeatMarginBottom && card && padding ?
          { marginTop: 0, marginBottom: 0, padding: 16 } :
          defeatMarginBottom ?
          { marginBottom: 0 } :
          card && padding ?
          { marginTop: 0, marginBottom: 24, padding: 16 } :
          reduceMargin || card ?
          { marginTop: 0, marginBottom: 24 } :
          null
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={saveButton ? 10 : 12}
                xl={saveButton ? 10 : btn2 ? 8 : btn ? 10 : 12}
                lg={saveButton ? 10 : btn2 ? 6 : btn ? 9 : 12}
                md={saveButton ? 10 : btn2 ? 12 : btn ? 8 : 12}
                sm={saveButton ? 10 : btn2 ? 12 : btn ? 6 : 12}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <div className={classes.headerAccent} />
                  <Typography className={classes.header} variant="h3">
                    {header}
                  </Typography>
                  {headerTooltip && (
                    <Tooltip
                      enterTouchDelay={1}
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      className={classes.headerTooltip}
                      title={headerTooltip}
                    >
                      <InfoIcon />
                    </Tooltip>
                  )}
                </Box>
              </Grid>
              {saveButton && !btn2 && !btnHidden ? (
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", flexDirection: "row-reverse" }}
                >
                  <SaveTextButton btnFunc={btnFunc} btnDisabled={btnDisabled} />
                </Grid>
              ) : null}
              {btn && !btnHidden ? (
                <Grid item xs={12} xl={2} lg={3} md={btn2 ? 6 : 4} sm={6}>
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant={btnVariant || "outlined"}
                    fullWidth
                    disabled={btnDisabled ? true : false}
                    onClick={btnFunc}
                  >
                    {btnLabel || "CTA"}
                  </Button>
                </Grid>
              ) : null}
              {btn2 ? (
                <Grid item xs={12} xl={2} lg={3} sm={6}>
                  <Button
                    color="primary"
                    size="large"
                    type="submit"
                    variant={btnVariant2 || "outlined"}
                    fullWidth
                    disabled={btnDisabled2 ? true : false}
                    onClick={btnFunc2}
                  >
                    {btnLabel2}
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
};
