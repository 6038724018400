import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import uuid from "uuid/v1";

import { useDropzone } from "react-dropzone";

import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
	Button,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	colors,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import bytesToSize from "src/utils/bytesToSize";
import addFileSVG from "../assets/deviasAssets/undraw_add_file2_gvbb.svg";

const useStyles = makeStyles((theme) => ({
	root: {},
	dropZone: {
		border: `1px dashed ${theme.palette.divider}`,
		padding: "18px",
		outline: "none",
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		alignItems: "center",
		"&:hover": {
			backgroundColor: colors.grey[50],
			opacity: 0.5,
			cursor: "pointer",
		},
	},
	container: {
		position: "relative",
		textAlign: "center",
		"&:hover > div": {
			opacity: 1,
		},
		"&:hover > img": {
			opacity: 0.3,
		},
	},
	dragActive: {
		backgroundColor: colors.grey[50],
		opacity: 0.5,
	},
	image: {
		cursor: "pointer",
		maxHeight: "60px",
		width: "auto",
		display: "block",
		transition: ".5s ease",
		backfaceVisibility: "hidden",
		marginLeft: "auto",
		marginRight: "auto",
		objectFit: "contian",
	},
	info: {
		textAlign: "center",
		cursor: "pointer",
	},
	list: {
		maxHeight: 320,
	},
	actions: {
		marginTop: theme.spacing(2),
		display: "flex",
		justifyContent: "space-between",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
	cancelButton: {
		flex: 1,
	},
	actionButton: {
		flex: 1,
	},
	middle: {
		transition: ".5s ease",
		width: "100%",
		opacity: 0,
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%,-50%)",
		msTransform: "translate(-50%, -50%)",
		backgroundColor: "#eee",
		padding: "10px 5px",
	},
	progressCircle: {
		color: theme.palette.primary.contrastText,
	},
	fileImage: {
		width: 25,
	},
	glowing: {
		borderColor: "#9ecaed",
		boxShadow: "0 0 10px #9ecaed",
	},
}));

function CustomFileDropzone({ value, type, onChange, className, ...rest }) {
	const classes = useStyles();
	const [files, setFiles] = useState(value);
	const [t] = useTranslation(["common"]);
	let isInactive = rest.disabled;

	const handleDrop = useCallback(
		(acceptedFiles) => {
			if (type === "single") {
				if (acceptedFiles.length) {
					setFiles([acceptedFiles[0]]);
				} else {
					setFiles([acceptedFiles]);
				}
			} else {
				setFiles((prevFiles) => [...prevFiles].concat(acceptedFiles));
			}
		},
		[type]
	);

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			onChange(files);
		}
		return () => {
			mounted = false;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [files]);

	const handleRemoveAll = () => {
		setFiles([]);
	};

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: handleDrop,
	});

	return (
		<div {...rest} className={clsx(classes.root, className)}>
			{!isInactive && (
				<div
					className={clsx({
						[classes.dragActive]: isDragActive,
					})}
					{...getRootProps()}
				>
					<input {...getInputProps()} />
					<div className={classes.container}>
						<img alt="Select file" className={classes.image} src={addFileSVG} />
						<div className={classes.middle}>
							<Typography
								className={classes.info}
								color="textPrimary"
								variant="h5"
							>
								{t("common:dragDrop")}{" "}
								<Link underline="always">{t("common:upload")}</Link>{" "}
							</Typography>
						</div>
					</div>
				</div>
			)}
			{files.length > 0 && (
				<>
					<PerfectScrollbar options={{ suppressScrollX: true }}>
						<List className={classes.list}>
							{files.map((file, i) => {
								return (
									<ListItem divider={i < files.length - 1} key={uuid()}>
										<ListItemIcon>
											<FileCopyIcon />
										</ListItemIcon>
										<ListItemText
											primary={file.name}
											primaryTypographyProps={{ variant: "h5" }}
											secondary={bytesToSize(file.size)}
										/>
									</ListItem>
								);
							})}
						</List>
					</PerfectScrollbar>
					<div className={classes.actions}>
						<Button
							onClick={handleRemoveAll}
							className={classes.cancelButton}
							size="small"
							disabled={isInactive}
						>
							{t(`common:cancel`)}
						</Button>
					</div>
				</>
			)}
		</div>
	);
}

CustomFileDropzone.propTypes = {
	className: PropTypes.string,
};

export default CustomFileDropzone;
