import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  UPDATE_NOTIFICATION_SETTINGS,
  UPDATE_TYPE_SETTINGS,
  UPDATE_EMAIL_SETTINGS,
  SET_NOTIFICATIONS,
} from "src/actions";
import NotificationsTable from "./NotificationsTable";
import {
  getUserNotificationSettings,
  getNotificationTypes,
} from "src/api/notificationApi";
import { saveUserNotificationSettings } from "src/api/notificationApi";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

import { useNotificationsOrVacancyNotificationsTranslationPrefix } from './../../../utils/useSubscriptionPrefix';

function EmailsAndNotifications() {
  const [t] = useTranslation([
    "common",
    "notifications",
    "vacancyNotifications",
    "settings",
    "newTranslations",
  ]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const notificationsPrefix = useNotificationsOrVacancyNotificationsTranslationPrefix();

  const notificationIds = useSelector(
    (state) => state?.notificationSettings?.notificationSettings
  );

  const notificationTypes = useSelector(
    (state) => state?.notificationSettings?.typeSettings
  );

  const emailNotificationIds = useSelector(
    (state) => state?.notificationSettings?.emailSettings
  );

  const roleType = useSelector((state) => state?.session?.roleType);

  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  const consultancySubscriptionPlanId = useSelector((state) => state.session.consultancySubscriptionPlanId);

  const setTypes = () => {
    return getNotificationTypes(roleType, consultancySubscriptionPlanId)
      .then((types) => {
        if (types) {
          return setNotificationTypesArray(types.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setNotificationSettings = () => {
    return getUserNotificationSettings()
      .then((settings) => {
        if (settings) {
          return setNotificationAndEmailSettings(settings);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setNotificationSettingsArray = (settings) => {
    return dispatch({
      type: UPDATE_NOTIFICATION_SETTINGS,
      payload: settings,
    });
  };

  const setEmailSettingsArray = (settings) => {
    return dispatch({
      type: UPDATE_EMAIL_SETTINGS,
      payload: settings,
    });
  };

  const setNotificationAndEmailSettings = (settings) => {
    if (settings?.data[0]) {
      return (
        setNotificationSettingsArray(settings.data[0].notificationIds) &&
        setEmailSettingsArray(settings.data[0].emailNotificationIds)
      );
    } else {
      return setNotificationSettingsArray([0]) && setEmailSettingsArray([0]);
    }
  };

  const setNotificationTypesArray = (types) => {
    return dispatch({
      type: UPDATE_TYPE_SETTINGS,
      payload: types,
    });
  };

  useEffect(() => {
    setTypes();
    setNotificationSettings();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userSettings = {
    notificationSettings: notificationIds,
    emailNotificationSettings: emailNotificationIds,
  };

  const updateUserSettings = (settings) => {
    return saveUserNotificationSettings(settings)
      .then((response) => {
        let updatedNotifications = notifications.filter(
          (notification) =>
            !userSettings.notificationSettings.includes(
              notification.notificationType.id
            )
        );
        dispatch({
          type: SET_NOTIFICATIONS,
          data: [...updatedNotifications],
        });

        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:settingsUpdated"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:couldNotUpdateSettings"), {
            variant: "error",
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return notificationTypes.length ? (
    <Grid container spacing={3} alignContent="space-around">
      <Grid item xs={12}>
        <Card>
          <BlockHeader
            card
            padding
            header={t("settings:notificationSettings")}
            headerTooltip={t("newTranslations:enableDisableNotifications")}
          />

          <CardContent>
            <NotificationsTable notificationTypes={notificationTypes} />
          </CardContent>
          <CardActions>
            <CardButton
              addMargin
              btnVariant="contained"
              btnFunc={() => updateUserSettings(userSettings)}
            />
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={3} align="center">
      <Typography>{t(`${notificationsPrefix}:noNotificationSettings`)}</Typography>
    </Grid>
  );
}

export default EmailsAndNotifications;
