import React from "react";
import { Box, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const useSort = (data, order, sortBy, fullname, candidates) => {
  const [t] = useTranslation(["common"]);
  const ascending = order === t("common:ascending");
  const sortData = () => {
    if (!data) return [];
    if (candidates) {
      if (ascending) {
        const sortedResults = data.sort((a, b) => {
          const nameA = a[sortBy]
            .split(" ")
            .reverse()
            .join(" ")
            .toLowerCase();
          const nameB = b[sortBy]
            .split(" ")
            .reverse()
            .join(" ")
            .toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        return sortedResults;
      } else {
        const sortedResults = data.sort((a, b) => {
          const nameA = a[sortBy]
            .split(" ")
            .reverse()
            .join(" ")
            .toLowerCase();
          const nameB = b[sortBy]
            .split(" ")
            .reverse()
            .join(" ")
            .toLowerCase();
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });
        return sortedResults;
      }
    } else {
      if (ascending) {
        const sortedResults = data.sort((a, b) => {
          const splitNameA = a[sortBy].split(" ");
          const splitNameB = b[sortBy].split(" ");
          const nameA = fullname
            ? splitNameA[splitNameA.length - 1]
            : a[sortBy].toLowerCase();
          const nameB = fullname
            ? splitNameB[splitNameB.length - 1]
            : b[sortBy].toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        return sortedResults;
      } else {
        const sortedResults = data.sort((a, b) => {
          const nameA = a[sortBy].toLowerCase();
          const nameB = b[sortBy].toLowerCase();
          if (nameA > nameB) {
            return -1;
          }
          if (nameA < nameB) {
            return 1;
          }
          return 0;
        });

        return sortedResults;
      }
    }
  };
  return { sortData };
};

export const SortMenu = (props) => {
  const [t] = useTranslation(["common"]);

  //defaults if no arguments passed
  const label = t("common:sortBy");
  const values = [t("common:ascending"), t("common:descending")];

  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <Box style={{ padding: "16px" }}>
      <TextField
        label={label}
        name="sort"
        onChange={handleChange}
        select
        SelectProps={{ native: true, style: { width: "220px" } }}
        value={props.value}
        variant="outlined"
      >
        {props.items
          ? props.items.map((item) => (
              <option
                key={item.key ? item.key : item}
                value={item.key ? item.key : item}
              >
                {item.label ? item.label : item}
              </option>
            ))
          : values.map((item, index) => (
              <option
                key={item.key ? item.key : item}
                value={item.key ? item.key : item}
              >
                {`Name (${item.label ? item.label : item})`}
              </option>
            ))}
      </TextField>
    </Box>
  );
};
