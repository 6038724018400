import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import parse from 'html-react-parser';

import { Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from './../../../../api/talentAlertApi';

import { ADD_TALENT_ALERT_REQUIRED_DOCUMENT, UPDATE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT } from './../../../../actions/talentAlertActions';

import { CardButton } from './../../../../components/LayoutComponents/CardButton';
import { ModalFormWrapper } from './../../../../components/ModalFormWrapper';
import DocumentTypeRadioButtons from './../../../Campaigns/CampaignDetail/CampaignDocuments/DocumentTypeRadioButtons';

import * as candidateRequiredDocumentTypeIds from './../../../../constants/candidateRequiredDocumentTypeIds';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  subheadingText: {
    width: '100%'
  }
}));

const CandidateTalentAlertDocumentModal = ({
    campaignId,
    document = {},
    consultancyId,
    onSubmit,
    open,
    onClose,
    text,
    onRemove
  }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(['candidates', 'campaigns', 'common']);
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const formTitle = text ? `${t('campaigns:editCampaignDocumentLabel')} ${document.name}` : t('campaigns:addCampaignDocumentLabel');

  const formik = useFormik({
    initialValues: {
      id: document.id || '',
      name: document.name || '',
      priority: document.priority || '',
      visibility: document.visibility?.split(',') || ['private'],
      documentType: document.documentType || candidateRequiredDocumentTypeIds.DOCUMENT
    },
    onSubmit: (values) => {
      talentAlertApi.saveRequiredCandidateDocument(consultancyId, campaignId, values)
        .then((response) => {
          if (response.data) {
            const { data } = response;

            const dispatchType = values.id ? UPDATE_SINGLE_TALENT_ALERT_REQUIRED_DOCUMENT : ADD_TALENT_ALERT_REQUIRED_DOCUMENT;
            const snackbarNotificationText = values.id ? t('candidates:documentUploaded') : t('candidates:documentAdded');
            const onSubmitData = {
              id: data.id,
              name: data.name,
              priority: data.priority
            };

            if (values.id) {
              onSubmitData.update = true;
            }

            onSubmit(onSubmitData);
            dispatch({
              type: dispatchType,
              document: data
            });
            enqueueSnackbar(snackbarNotificationText, { variant: 'success' });

            formik.setValues({
              id: '',
              name: '',
              priority: ''
            });

            onClose();
          }
        })
        .catch(() => {
          enqueueSnackbar(t('candidates:documentError'), { variant: 'error' });
        })
        .then(() => {
          setLoading(false);
        });
      }
  });

  useEffect(() => {
    formik.setValues({
      id: document.id,
      name: document.name,
      visibility: document.visibility?.split(',') || ['private'],
      documentType: document.documentType
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  const handleRemove = () => {
    onRemove(document.id);
  };

  const handleCheckboxChange = (event) => {
    const checkboxValue = event.target.name;
    const visibility = [...formik.values.visibility];

    if (visibility.includes(checkboxValue)) {
      const index = visibility.indexOf(checkboxValue);
      visibility.splice(index, 1);
    } else {
      visibility.push(checkboxValue);
    }

    formik.setValues({
      ...formik.values,
      visibility
    })
  };

  const handleDocumentTypeChange = (documentType) => {
    formik.setValues({
      ...formik.values,
      documentType
    });
  };

  return (
    <ModalFormWrapper
      formTitle={formTitle}
      handleOpen={open}
      handleClose={onClose}
      editDocument={true}>
      {
        text ? (
          <Typography>
            { parse(text || "") }
          </Typography>
        ) : (
          <>
            <form>
              <Grid className={classes.fields}>
                  <TextField
                    fullWidth
                    label={t("campaigns:documentNameLabel")}
                    variant="outlined"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name} />

                  <Typography className={classes.subheadingText} variant="h5">
                    { t("campaigns:campaignDocumentVisibilitySelection") }
                  </Typography>

                  <FormGroup row >
                    <FormControlLabel
                      label={ t("campaigns:campaignDocumentVisibilitySelectionPrivate") }
                      control={
                        <Checkbox
                          disabled
                          color="primary"
                          name="private"
                          checked={ formik.values.visibility?.includes("private") }
                          onChange={ handleCheckboxChange } />
                      } />

                    <FormControlLabel
                      label={ t("campaigns:campaignDocumentVisibilitySelectionContacts") }
                      control={
                        <Checkbox
                          color="primary"
                          name="contact"
                          checked={ formik.values.visibility?.includes("contact") }
                          onChange={ handleCheckboxChange } />
                      } />

                    <FormControlLabel
                      label={ t("campaigns:campaignDocumentVisibilitySelectionCandidates") }
                      control={
                        <Checkbox
                          color="primary"
                          name="candidate"
                          checked={ formik.values.visibility?.includes("candidate") }
                          onChange={ handleCheckboxChange } />
                      } />
                  </FormGroup>

                  <DocumentTypeRadioButtons
                    active={ true }
                    documentType={ formik.values.documentType }
                    onChange={ handleDocumentTypeChange } />
              </Grid>
            </form>

            <CardButton
              btnLabel={document.name ? t("campaigns:saveCampaignDocumentLabel") : t("campaigns:addDocumentLabel")}
              btnFunc={formik.handleSubmit}
              btnVariant="contained"
              btnLabel2={t("campaigns:removeDocumentLabel")}
              btnFunc2={handleRemove}
              btnVariant2="outlined"
              loading={loading}
              loading2={loading}
              btnDisabled2={!document.id} />
          </>
        )
      }
    </ModalFormWrapper>
  );

};

export default CandidateTalentAlertDocumentModal;
