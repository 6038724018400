import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as ConsultancyApi from "src/api/consultancyApi";
import { useTranslation } from "react-i18next";

function CountrySelect({
	disabled,
	handleCountryChange,
	consultancyId,
	value,
}) {
	const [t] = useTranslation(["countries", "common"]);
	const usersConsultancyId = useSelector(
		(state) => state.session.consultancyId
	);
	const [countries, setCountries] = useState([]);
	const [country, setCountry] = useState(null);

	const getConsultancyCountries = () => {
		return ConsultancyApi.getCountries(
			consultancyId ? consultancyId : usersConsultancyId
		)
			.then((response) => {
				setCountries(response.data.countries);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const getCountryById = () => {
		return ConsultancyApi.getCountryById(value)
			.then((response) => {
				setCountry(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		getConsultancyCountries();

		if (typeof value == "number") {
			getCountryById();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			value &&
			Object.keys(value).length === 0 && value.constructor === Object
		) {
			return;
		} else {
			setCountry(value);
		}
	}, [value]);

	const handleChange = (event, newValue) => {
		event.preventDefault();
		handleCountryChange(newValue);
	};

	return (
		<>
			<Autocomplete
				disabled={disabled}
				id="combo-box-demo"
				options={countries}
				getOptionLabel={(option) => t(`countries:${option.name}`)}
				value={country || null}
				onChange={handleChange}
				style={{ width: "100%" }}
				renderInput={(params) => {
					return (
						<TextField
							required
							{...params}
							fullWidth
							label={t("common:selectCountry")}
							variant="outlined"
						/>
					);
				}}
			/>
		</>
	);
}
export default CountrySelect;
