import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Typography,
} from "@material-ui/core";

import logoPlaceholder from "src/assets/images/placeholder.png";

const useStyles = makeStyles((theme) => ({
	card: {
		transition: "300ms",
		maxWidth: "360px",
		overflow: "visible",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: theme.spacing(12),
	},
	card_disabled: {
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
		maxWidth: "360px",
		overflow: "visible",
		marginLeft: "auto",
		marginRight: "auto",
	},
	name: {
		transition: "300ms",
	},
	name_disabled: {
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	logo_container: {
		width: 300,
		height: 85,
		boxShadow: "0 0.5rem 1rem #C2C2C2",
		transform: "translateY(-50px)",
		backgroundColor: theme.palette.background.paper,
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "-2rem",
		display: "flex",
		justifyContent: "center",
	},
	logo: {
		height: "auto",
		width: "auto",
		maxWidth: 268,
		maxHeight: 68,
		margin: "1rem",
	},
	email: {
		textDecoration: "underline",
		textDecorationColor: theme.palette.text.secondary,
		color: theme.palette.text.secondary,
		fontSize: ".9rem",
	},
}));

export const EmployerCard = ({
	name,
	email,
	btnLabel,
	btnFunc,
	disableBtn,
	enabled = true,
	logo,
}) => {
	const classes = useStyles();
	return (
		<Card className={enabled ? classes.card : classes.card_disabled}>
			<Box className={classes.logo_container}>
				<img src={logo ? logo : logoPlaceholder} alt="" className={classes.logo} />
			</Box>
			<CardContent>
				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					textAlign="center"
				>
					<Box>
						<Typography
							className={enabled ? classes.name : classes.name_disabled}
							gutterBottom
							variant="h3"
							color="textPrimary"
						>
							{name}
						</Typography>
						<a href={`mailto:`} className={classes.email}>
							{email}
						</a>
					</Box>
				</Box>
			</CardContent>
			<CardActions>
				{btnLabel ? (
					<Button
						fullWidth
						variant="text"
						color="primary"
						onClick={btnFunc}
						disabled={disableBtn ? true : false}
					>
						{btnLabel}
					</Button>
				) : null}
			</CardActions>
		</Card>
	);
};
