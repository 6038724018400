import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
	Grid,
	Button,
	Typography,
	Container,
	TextField,
} from "@material-ui/core";
import Page from "src/components/Page";
import UsersAndGroupsContainer from "./UsersAndGroupsContainer";
import GroupUserListCard from "./GroupUserListCard";
import * as ConsultancyApi from "src/api/consultancyApi";
import CreateUserModal from "./CreateUserModal";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
	},
	container: {
		padding: "0% 5%",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	createButton: {
		margin: "12px",
		marginRight: "0px",
	},
	gridRow: {
		marginTop: "20px",
	},
	consultancyLogo: {
		height: "50px",
		width: "auto",
	},
	userRow: {
		border: `1px solid ${theme.palette.primary.light}`,
		borderRadius: 2,
		margin: "12px",
	},
	userRowContainer: {
		alignItems: "center",
	},
	headerRow: {
		textAlign: "right",
		padding: "0px 12px",
	},
	alignItemsCenter: {
		alignItems: "center",
	},
	noTopBottomPadding: {
		padding: "0px 12px",
	},
	textAlignRight: {
		textAlign: "right",
	},
	resendEmailButtonText: {
		color: "white",
	},
	input: {
		display: "inline-block",
	},
	inputButton: {
		margin: "10px",
	},
	topBar: {
		marginBottom: theme.spacing(2),
	},
}));

function GroupDetailView({ consultancyId, groupId }) {
	const classes = useStyles();
	const [t] = useTranslation(["common", "users", "groups"]);
	const { enqueueSnackbar } = useSnackbar();

	const [details, setDetails] = useState({
		groupUserList: [],
		userList: [],
		group: {},
	});

	const [searchUsers, setSearchUsers] = useState();
	const [searchGroupMembers, setSearchGroupMembers] = useState();
	const [open, setOpen] = useState(false);
	//added this to keep original group name in case of unsuccesful name change
	const [originalGroupName, setOriginalGroupName] = useState();

	const handleUserSearch = (e) => {
		setSearchUsers(e.target.value);
	};

	const handleGroupMembersSearch = (e) => {
		setSearchGroupMembers(e.target.value);
	};

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const onSuccess = (userDetails) => {
		let currentUserList = details.userList.slice();
		currentUserList.push({
			userTypeId: userDetails.id,
			userType: userDetails.roleType,
			consultancyId: Number(consultancyId),
			name: userDetails.name,
		});

		setDetails((prevDetails) => ({
			...prevDetails,
			userList: currentUserList,
		}));

		getUserGroup(consultancyId, groupId);
	};

	const updateGroup = (user, action) => {
		if (action === "add") {
			details.groupUserList.push(user);
			details.userList.splice(
				details.userList
					.map((groupUser) => {
						return groupUser;
					})
					.indexOf(user),
				1
			);
		} else if (action === "remove") {
			details.userList.push(user);
			details.groupUserList.splice(
				details.groupUserList
					.map((removedUser) => {
						return removedUser;
					})
					.indexOf(user),
				1
			);
		} else {
			throw new Error("Invalid action");
		}
		return ConsultancyApi.editUserGroup(details).then((response) => {
			if (response.status === 200) {
				return getUserGroup(consultancyId, groupId);
			}
		});
	};

	const updateGroupName = (groupDetails) => {
		return ConsultancyApi.editUserGroup(groupDetails)
			.then((response) => {
				enqueueSnackbar(t("groups:groupNameUpdated"), { variant: "success" });
			})
			.catch((error) => {
				if (error.response.status === 409) {
					enqueueSnackbar(
						`${groupDetails.group.groupName} ${t("groups:groupAlreadyExists")}`,
						{ variant: "error" }
					);

					onUnsuccefulGroupNameChange();
				} else {
					enqueueSnackbar(t("groups:groupNameFailure"), { variant: "error" });
					console.error(error);
				}
			});
	};

	const onGroupNameChange = (event) => {
		event.persist();

		setDetails((prevFormState) => ({
			...prevFormState,
			group: {
				...prevFormState.group,
				groupName: event.target.value,
			},
		}));
	};

	const onUnsuccefulGroupNameChange = () => {
		setDetails((prevFormState) => ({
			...prevFormState,
			group: {
				...prevFormState.group,
				groupName: originalGroupName,
			},
		}));
	};

	const getUserGroup = function(consultancyId, groupId) {
		return ConsultancyApi.getGroupAndUnassignedUsers(
			consultancyId,
			groupId
		).then((response) => {
			setDetails({
				groupUserList: response.data.groupUserList,
				userList: response.data.userList,
				group: response.data.group,
			});
			setOriginalGroupName(response.data.group.groupName);
		});
	};

	const filterUsersBySearchCriteria = (array, search) =>
		search
			? array.filter((user) =>
					user.name.toLowerCase().includes(search.toLowerCase())
			  )
			: array;

	useEffect(() => {
		getUserGroup(consultancyId, groupId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Page className={classes.root} title={t("common:consultancyLabel")}>
			<Container className={classes.container} maxWidth="xl">
				<Grid container spacing={3} className={classes.topBar}>
					<Grid item xs={5} md={8}>
						<Typography variant="h3" className={classes.noTopBottomPadding}>
							{t("groups:userGroup")}
						</Typography>
					</Grid>
					<Grid item xs={12} md={7} className={classes.input}>
						<TextField
							fullWidth
							name="groupName"
							variant="outlined"
							onChange={onGroupNameChange}
							value={details.group.groupName || ""}
						/>
					</Grid>
					<Grid item xs={12} md={4} className={classes.inputButton}>
						<Button
							color="primary"
							size="small"
							type="submit"
							variant="contained"
							onClick={() => updateGroupName(details)}
						>
							{t("groups:editUserGroup")}
						</Button>
					</Grid>
				</Grid>
				<Grid container spacing={3}>
					<UsersAndGroupsContainer
						handleSearch={handleGroupMembersSearch}
						title={t("groups:groupMembers")}
					>
						{details.groupUserList.length > 0 ? (
							<GroupUserListCard
								groupArray={filterUsersBySearchCriteria(
									details.groupUserList,
									searchGroupMembers
								)}
								onSuccess={updateGroup}
								groupDetails={details}
							/>
						) : null}
					</UsersAndGroupsContainer>
					<UsersAndGroupsContainer
						handleSearch={handleUserSearch}
						title={t("common:allUsers")}
						btnLabel={t("users:createUser")}
						btnFunc={handleOpen}
					>
						{details.userList.length > 0 ? (
							<GroupUserListCard
								groupArray={filterUsersBySearchCriteria(
									details.userList,
									searchUsers
								)}
								onSuccess={updateGroup}
								groupDetails={details}
							/>
						) : null}
					</UsersAndGroupsContainer>
					<CreateUserModal
						onSuccess={onSuccess}
						consultancyId={consultancyId}
						open={open}
						handleClose={handleClose}
					/>
				</Grid>
			</Container>
		</Page>
	);
}

export default GroupDetailView;
