import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as modulesApi from "src/api/modulesApi";
import Page from "src/components/Page";
import { SET_KCQ_CATEGORIES } from "src/actions/ConsultancyModules/kcq/kcqActions";
import KCQSettings from "./kcqSettings";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
  },
  editButton: {
    paddingRight: "20px",
  },
  list: {
    padding: "0",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: "15px",
  },
  container: {},
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${50}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${50}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  gridRow: {
    marginTop: "20px",
  },
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
  },
}));

function KCQManagement({ consultancyId }) {
  const classes = useStyles();
  const [t] = useTranslation(["modules", "common", "snackbar"]);
  const dispatch = useDispatch();
  const getKCQCategories = () => {
    return modulesApi.getKCQCategories(consultancyId).then((response) => {
      dispatch({ type: SET_KCQ_CATEGORIES, categories: response.data });
    });
  };
  

  useEffect(() => {
    getKCQCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <Page className={classes.root} title={t("modules:kcqPageTitle")}>
          <KCQSettings consultancyId={consultancyId} />
      </Page>
    </>
  );
}

export default KCQManagement;
