import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Dashboard from "./Dashboard";
import * as ConsultancyApi from "src/api/consultancyApi";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Analytics = ({ consultancyId }) => {
	const [analytics, setAnalytics] = useState({});
	const [t] = useTranslation(["campaigns"]);
	const dateFrom = useSelector((state) => state.analytics.dateFrom);
	const dateTo = useSelector((state) => state.analytics.dateTo);
	const selectedConsultancyId = useSelector(
		(state) => state.analytics.selectedConsultancy.id
	);

	const getAnalytics = (consultancyId, selectedConsultancyId) => {
		return ConsultancyApi.getConsultancyAnalytics(
			consultancyId,
			selectedConsultancyId,
			dateFrom,
			dateTo
		)
			.then((response) => {
				if (response) {
					const data = response.data;
					setAnalytics(data);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const employersAndCandidatesData = [
		{
			name: t("common:usersLabel"),
			value: analytics.totalUsers,
		},
		{
			name: t("campaigns:numberOfEmployersLabel"),
			value: analytics.totalEmployers,
		},
		{
			name: t("campaigns:numberOfCandidatesLabel"),
			value: analytics.totalCandidates,
		},
	];

	const campaignsCompleted = [
		{
			name: t("campaigns:numberOfCompletedCampaignsLabel"),
			value: analytics.completedCampaigns,
		},
		{
			name: t("campaigns:averageDaysToCompletionLabel"),
			value: analytics.averageDaysToCompletion,
		},
	];

	const analyticsData = [
		{
			name: t("campaigns:numberOfCampaignsLabel"),
			value: analytics.totalCampaigns,
		},
		{
			name: t("campaigns:numberOfLiveCampaignsLabel"),
			value: analytics.liveCampaigns,
		},
		{
			name: t("campaigns:talentAlerts"),
			value: analytics.talentAlerts,
		},
		{
			name: t("campaigns:averageCampaignSalaryLabel"),
			value: analytics.averageSalary,
		},
		{
			name: t("campaigns:averageCampaignFeeLabel"),
			value: analytics.averageFee,
		},
	];

	const campaignsPerMonth = analytics.campaignsPerMonth;
	useEffect(() => {
		getAnalytics(consultancyId, selectedConsultancyId);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [consultancyId, selectedConsultancyId, dateFrom, dateTo]);
	return (
		<Grid container>
			{analytics && campaignsPerMonth && (
				<Dashboard
					consultancyId={ consultancyId }
					campaignsPerMonth={campaignsPerMonth}
					analyticsData={analyticsData}
					employersAndCandidatesData={employersAndCandidatesData}
					campaignsCompleted={campaignsCompleted}
          activeConsultancies={analytics.activeConsultanciesNamesAndIds}
					consultanciesTree={analytics.consultanciesTree}
					excludeFromActivityTracking={ analytics.excludeFromActivityTracking }
				/>
			)}
		</Grid>
	);
};

export default Analytics;
