import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Card,
  CardContent,
  Box,
  Button,
  Grid,
  CircularProgress,
  Slider,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import QuillEditor from "src/components/QuillEditor";
import * as CandidateApi from "src/api/candidateApi";
import { useSnackbar } from "notistack";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";

import { availableWordLimits } from './../../../constants/aiExecutiveSummaryAvailableWordLimits';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  actionsMobile: {
    flexDirection: 'column',
    marginTop: '24px'
  },
  sliderBox: {
    minWidth: '400px',
    paddingLeft: theme.spacing(2),
    marginRight: theme.spacing(4)
  },
  sliderBoxMobile: {
    minWidth: '0',
    width: '100%',
    padding: '0 8px',
    marginRight: '0'
  },
  buttonsBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonsBoxMobile: {
    flexDirection: 'column'
  },
	button: {
		minWidth: 227,
	},
	buttonMargin: {
		marginRight: theme.spacing(2),
	},
  buttonMarginMobile: {
    marginBottom: theme.spacing(1)
  },
  box: {
    height: '20px',
    display: 'flex',
    justifyContent: 'center'
  },
  paragraph: {
    marginRight: '8px'
  },
  boxLoader: {
    height: '400px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingBottom: '80px'
  },
  loadignMessage: {
    fontSize: '24px',
    fontWeight: '800'
  }
}));

const CampaignCandidateExecutiveSummary = ({
  candidateId,
  campaignId,
  executiveSummary,
  handleSummaryChange,
  isTalentAlert = false,
}) => {

  const classes = useStyles();
  const [t] = useTranslation(["common", "snackbar", "tooltips", "candidates"]);
  const { enqueueSnackbar } = useSnackbar();
  const isMobileDevice = useMediaQuery('(max-width:599px)');

  const [loading, setLoading] = useState(false);
  const [quillExecutiveSummary, setQuillExecutiveSummary] = useState(executiveSummary);
  const [isGeneratingExecutiveSummary, setIsGeneratingExecutiveSummary] = useState(false);
  const [didGenerateExecutiveSummary, setDidGenerateExecutiveSummary] = useState(false);
  const [doShowWordLimitSlider, setDoShowWordLimitSlider] = useState(false);
  const [wordLimit, setWordLimit] = useState(availableWordLimits.DEFAULT);

  const consultancyId = useSelector((state) => state.session.consultancyId);

  const sliderMarks = [
    {
      value: availableWordLimits.MIN,
      label: availableWordLimits.MIN
    },
    {
      value: availableWordLimits.DEFAULT,
      label: availableWordLimits.DEFAULT
    },
    {
      value: availableWordLimits.MAX,
      label: availableWordLimits.MAX
    }
  ];

  const sliderChangeHandler = (event, value) => {
    setWordLimit(value);
  };

  const onSuccess = (summary) => {
    return CandidateApi.updateCandidateExecutiveSummary(
      candidateId,
      consultancyId,
      campaignId,
      summary
    )
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:succesfullUpdatedExecutiveSummary"), {
            variant: "success",
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(t("snackbar:failedToUpdateExecutiveSummary"), {
          variant: "error",
        });
      });
  };

  const generateExecutiveSummary = (event) => {
    event.preventDefault();
    
    if (!doShowWordLimitSlider) {
      setDoShowWordLimitSlider(true);
      return;
    }

    setLoading(true);
    setIsGeneratingExecutiveSummary(true);
    setDoShowWordLimitSlider(false);

    CandidateApi.generateAIExecutiveSummary(candidateId, campaignId, consultancyId, didGenerateExecutiveSummary, wordLimit, isTalentAlert)
      .then((response) => {
        handleSummaryChange(response.data);
        setQuillExecutiveSummary(response.data);
        setWordLimit(availableWordLimits.DEFAULT);
        setLoading(false);
        setIsGeneratingExecutiveSummary(false);
        setDidGenerateExecutiveSummary(true);
      })
      .catch((error) => {
        if (error.response.status === 404) {
          enqueueSnackbar(t('snackbar:generateAIExecutiveSummaryMissingCV'), { variant: 'error' });
        } else {
          enqueueSnackbar(t('snackbar:generateAIExecutiveSummaryServerError'), { variant: 'error' });
        }

        setWordLimit(availableWordLimits.DEFAULT);
        setLoading(false);
        setIsGeneratingExecutiveSummary(false);
      });
  };

  const onSubmit = function(event) {
    event.preventDefault();
    setLoading(true);
    onSuccess(executiveSummary).then(() => {
      setLoading(false);
    });
  };

  return executiveSummary !== undefined ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <form onSubmit={ onSubmit }>
          <Card>
            <BlockHeader
              headerTooltip={t("tooltips:writeExecutiveSummaryForCandidate")}
              card
              padding
              header={t(`common:executiveSummary`)}
            />
            <CardContent>
              {
                isGeneratingExecutiveSummary ? (
                  <>
                    <Box className={ classes.boxLoader }>
                      <Typography className={ classes.loadignMessage }>
                        { t("candidates:generatingExecutiveSummaryLoadingMessage") }
                      </Typography>
                      
                      <Box>
                        <CircularProgress />
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <QuillEditor
                      value={quillExecutiveSummary}
                      handleChange={handleSummaryChange}
                      toolbarOptions={["link"]}
                      disabled={ isGeneratingExecutiveSummary } />
                  </>
                )
              }

              <Grid className={ `${classes.actions} ${isMobileDevice ? classes.actionsMobile : ""}` }>
                <Box className={ `${classes.sliderBox} ${isMobileDevice ? classes.sliderBoxMobile : ""}` } >
                  {
                    doShowWordLimitSlider && (
                      <>
                        <Typography gutterBottom >
                          { t("candidates:generateExecutiveSummaryWordLimitLabel", { wordLimit }) }
                        </Typography>
                        
                        <Slider
                          defaultValue={ availableWordLimits.DEFAULT }
                          getAriaValueText={ (value) => value }
                          min={ availableWordLimits.MIN }
                          step={ 50 }
                          max={ availableWordLimits.MAX }
                          valueLabelDisplay="auto"
                          marks={ sliderMarks }
                          onChange={ sliderChangeHandler } />
                      </>
                    )
                  }
                </Box>

                <Box className={ `${classes.buttonsBox} ${isMobileDevice ? classes.buttonsBoxMobile : ""}` } >
                  <Button
                    color="primary"
                    size="large"
                    variant="outlined"
                    fullWidth={ isMobileDevice }
                    className={ `${classes.button} ${isMobileDevice ? classes.buttonMarginMobile : classes.buttonMargin}` }
                    disabled={ loading }
                    onClick={ generateExecutiveSummary } >
                    {
                      loading ? (
                        <>
                          <CircularProgress size={ 25 } />
                        </>
                      ) : (
                        <>
                          <Box className={ classes.box } >
                            <p className={ classes.paragraph } >
                              {
                                doShowWordLimitSlider ?
                                  t("candidates:generateExecutiveSummaryStartGeneratingButton") :
                                  didGenerateExecutiveSummary ?
                                    t("candidates:generateExecutiveSummaryRegenerateButton") :
                                    t("candidates:generateExecutiveSummaryButton") }
                            </p>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24"><path fill="currentColor" d="M7.5 5.6L10 7L8.6 4.5L10 2L7.5 3.4L5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.996.996 0 0 0-1.41 0L1.29 18.96a.996.996 0 0 0 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05a.996.996 0 0 0 0-1.41l-2.33-2.35zm-1.03 5.49l-2.12-2.12l2.44-2.44l2.12 2.12l-2.44 2.44z"/></svg>
                          </Box>
                        </>
                      )
                    }
                  </Button>

                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    type="submit"
                    fullWidth={ isMobileDevice }
                    className={ classes.button }
                    disabled={ loading } >
                    {
                      loading ? (
                        <>
                          <CircularProgress size={ 25 } color="textPrimary" />
                        </>
                      ) : (
                        <>
                          { t("common:save") }
                        </>
                      )
                    }
                  </Button>
                </Box>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Grid>
    </Grid>
  ) : (
    <CircularProgress className={classes.progressCircle} size={25} />
  );
};

export default CampaignCandidateExecutiveSummary;
