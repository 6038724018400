import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { useFormik } from 'formik';

import { makeStyles } from '@material-ui/styles';
import { TextField, Button, CardContent, Card, Grid } from '@material-ui/core';

import { useSnackbar } from 'notistack';

import * as Yup from 'yup';

import { UPDATE_CAMPAIGN_DETAILS } from '../../../actions';

import * as talentAlertApi from '../../../api/talentAlertApi';

import { BlockHeader } from '../../../components/LayoutComponents/BlockHeader';

const useStyles = makeStyles((theme) => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%'
  }
}));

const TalentAlertInformation = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(['common', 'validation']);
  const { enqueueSnackbar } = useSnackbar();

  const { consultancyId } = useSelector((state) => state.session);
  const { talentAlert } = useSelector((state) => state);

  const validationSchema = Yup.object({
    campaignTitle: Yup.string()
      .required(t('validation:required'))
      .max(80)
  });

  const formik = useFormik({
    initialValues: {
      campaignTitle: ''
    },
    validationSchema,
    onSubmit: (values) => {
      const talentAlertCampaignDetailsData = {
        campaignId: talentAlert.id,
        consultancyId: consultancyId,
        details: values
      };

      talentAlertApi.updateTalentAlertCampaignDetails(talentAlertCampaignDetailsData)
        .then(() => {
          dispatch({
            type: UPDATE_CAMPAIGN_DETAILS,
            campaignTitle: values.campaignTitle,
          });
  
          enqueueSnackbar('Updated Details', { variant: 'success' });
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Error Updating Details', { variant: 'error' });
        });
    }
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      campaignTitle: talentAlert.campaignTitle
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [talentAlert.campaignTitle]);

  const hasError = (field) => {
    return formik.touched[field] && formik.errors[field]
  };

  return (
    <Card>
      <CardContent>
        <BlockHeader card header={t("newTranslations:talentAlertName")} />
        
        <form onSubmit={formik.handleSubmit}>
          <Grid className={classes.fields}>
            <TextField
              fullWidth
              label={t("common:jobPositionLabel")}
              name="campaignTitle"
              variant="outlined"
              required
              onChange={formik.handleChange}
              error={hasError("campaignTitle")}
              value={formik.values.campaignTitle}
              helperText={formik.errors.campaignTitle} />
          </Grid>
          
          <Grid>
            <Button
              className={classes.submitButton}
              color="primary"
              size="small"
              type="submit"
              variant="contained"
              fullWidth
              disabled={!formik.isValid} >
              { t("common:update") }
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );

};

export default TalentAlertInformation;
