import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import DraggableFileTile from './DraggableFileTile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {},
  assetList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: 0,
  },
}));

const ContextPanel = ({ context, getAssets = () => {}, ...rest }) => {
  const classes = useStyles();
  const [t] = useTranslation(['common', 'storage', 'validation']);
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const retrieveContextAssets = () => {
    return getAssets()
      .then(assets => {
        setAssets(assets);
      })
      .catch(error => {
        setError(t('validation:assetRetrievalFailLabel'));
      })
      .then(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setLoading(true);
      retrieveContextAssets();
    }

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, getAssets]);

  return (
    <ExpansionPanel>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>{`${t(
          `storage:${context.context}`
        )} ${t('storage:files')}`}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.assetList}>
        {error ? (
          <Typography align={'center'} className={classes.errorText}>
            {error}
          </Typography>
        ) : loading ? (
          <CircularProgress className={classes.progressCircle} size={25} />
        ) : assets.length === 0 ? (
          <Typography align={'center'}>{t('storage:noAssetsLabel')}</Typography>
        ) : (
          <div style={{ overflowY: 'scroll', height: '100vh' }}>
            {assets.map((asset, index) => (
              <>
                <DraggableFileTile
                  key={`${context.context}_asset_${index}`}
                  setAssets={setAssets}
                  asset={asset}
                />
                <Divider />
              </>
            ))}
          </div>
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

ContextPanel.propTypes = {};

export default ContextPanel;
