import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';

import { AppBar, CardContent, Card, Grid, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import * as talentAlertApi from './../../../../api/talentAlertApi';

import { UPDATE_TALENT_ALERT_TCS } from './../../../../actions/talentAlertActions';

import { TabPanel } from './../../../../components/TabPanel';
import { BlockHeader } from './../../../../components/LayoutComponents/BlockHeader';
import { CardButton } from './../../../../components/LayoutComponents/CardButton';

import EditTermsAndConditionsModal from './EditTermsAndConditionsModal';

import { CANDIDATE, CONTACT } from './../../../../constants/roles';

const useStyles = makeStyles((theme) => ({
  termsAndConditionsCard: {
    paddingTop: '0px',
    overflowY: 'auto',
    height: '400px',
    fontFamily: 'sans-serif'
  },
  editButton: {
    textAlign: 'center'
  },
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent'
  }
}));

const TalentAlertTermsAndConditions = () => {

  const classes = useStyles();
  const [t] = useTranslation(['common', 'campaigns', 'tooltips']);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const talentAlert = useSelector((state) => state.talentAlert);

  const [currentCampaignTCTab, setCurrentCampaignTCTab] = useState(0);
  const [candidateTermsAndConditionsOpen, setCandidateTermsAndConditionsOpen] = useState(false);
  const [contactTermsAndConditionsOpen, setContactTermsAndConditionsOpen] = useState(false);

  const tabs = [
    { value: 0, label: t('common:candidate') },
    { value: 1, label: t('common:contact') }
  ];

  const handleCampaignTCTabChange = (event, value) => {
    setCurrentCampaignTCTab(value);
  };

  const toggleCandidateTermsAndConditionsModal = () => {
    setCandidateTermsAndConditionsOpen((prevState) => !prevState);
  };

  const toggleContactTermsAndConditionsModal = () => {
    setContactTermsAndConditionsOpen((prevState) => !prevState);
  };

  const onTermsAndConditionsUpdate = (termsAndConditions, role) => {
    talentAlertApi.updateTalentAlertCampaignTermsAndConditions(talentAlert.id, termsAndConditions, role)
      .then((response) => {
        dispatch({
          type: UPDATE_TALENT_ALERT_TCS,
          termsAndConditions: response.data
        });

        enqueueSnackbar(`${t('snackbar:termsAndConditionSuccess')}`, { variant: 'success' });
      })
      .then(() => {
        if(role === CANDIDATE) {
          toggleCandidateTermsAndConditionsModal();

          return;
        }

        toggleContactTermsAndConditionsModal();
      })
      .catch(() => {
        enqueueSnackbar(`${t('snackbar:termsAndConditionFailure')}`, { variant: 'error' });
      });
  };

  return (
    <>
      <Card>
        <BlockHeader
          card
          padding
          header={t("campaigns:termsAndConditionsLabel")}
          headerTooltip={t("tooltips:editTermsAndConditions")} />

        <CardContent>
          <AppBar position="static" className={classes.appBar}>
            <Tabs
              value={currentCampaignTCTab}
              onChange={handleCampaignTCTabChange}
              variant="scrollable"
              textColor="primary"
              className={classes.tabBar} >
              {
                tabs.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={t(`common:${tab.label}`)}
                    value={tab.value}
                    className={classes.tab} />
                ))
              }
            </Tabs>
          </AppBar>
          
          <TabPanel value={currentCampaignTCTab} index={0}>
            <Grid className={classes.termsAndConditionsCard}>
              <Grid>
                { parse(talentAlert?.campaignCandidateTCs?.termsAndConditions || '') }
              </Grid>
            </Grid>
            
            <Grid className={classes.editButton}>
              <CardButton
                btnFunc={toggleCandidateTermsAndConditionsModal}
                btnLabel={t("common:edit")} />
            </Grid>
          </TabPanel>

          <TabPanel value={currentCampaignTCTab} index={1}>
            <Grid className={classes.termsAndConditionsCard}>
              <Grid>
                { parse(talentAlert.campaignContactTCs.termsAndConditions || "") }
              </Grid>
            </Grid>
            
            <Grid className={classes.editButton}>
              <CardButton btnFunc={toggleContactTermsAndConditionsModal} btnLabel={t("common:edit")} />
            </Grid>
          </TabPanel>
        </CardContent>
      </Card>
      
      <EditTermsAndConditionsModal
        onSuccess={ (tc) => { onTermsAndConditionsUpdate(tc, CANDIDATE) } }
        open={candidateTermsAndConditionsOpen}
        handleClose={toggleCandidateTermsAndConditionsModal}
        campaignTC={talentAlert.campaignCandidateTCs?.termsAndConditions || ""}
        consultancyTC={talentAlert.defaultCampaignCandidateTCs?.termsAndConditions || ""} />
      
      <EditTermsAndConditionsModal
        onSuccess={ (tc) => { onTermsAndConditionsUpdate(tc, CONTACT) } }
        open={contactTermsAndConditionsOpen}
        handleClose={toggleContactTermsAndConditionsModal}
        campaignTC={talentAlert.campaignContactTCs?.termsAndConditions || ""}
        consultancyTC={talentAlert.defaultCampaignContactTCs?.termsAndConditions || ""} />
    </>
  );

};

export default TalentAlertTermsAndConditions;
