import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Paper,
  Slide,
  Switch,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import * as api from "../../../api/landingApi";
import { useSnackbar } from "notistack";
import CustomFileDropzone from "src/components/CustomFileDropzone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import VideoBlock from "src/views/LandingPage/VideoBlock";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

const useStyles = makeStyles((theme) => ({
  button_container: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
}));

const ManageVideo = ({
  landingPageData,
  handleDelete,
  getLandingPageData,
  assetData,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { consultancyId } = landingPageData;
  const landingPageId = landingPageData.id;
  const [expand, setExpand] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [percent, setPercent] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setEnabled(landingPageData.display_video);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleContentState = (fieldName) => {
    setEnabled(!enabled);
    return api
      .toggleDisplayContent(consultancyId, fieldName)
      .then((response) => {
        if (response.status === 200 && !enabled) {
          enqueueSnackbar("Video enabled", {
            variant: "success",
          });
        } else if (response.status === 200 && enabled) {
          enqueueSnackbar("Video disabled", {
            variant: "info",
          });
        }
      })
      .catch(() => {
        setEnabled(enabled);
        enqueueSnackbar("Could not be enabled at this time", {
          variant: "error",
        });
      });
  };

  const uploadOptions = {
    params: {
      consultancyId: consultancyId,
      landingPageId: landingPageId,
      fieldName: "video",
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;

      if (loaded && total) {
        let newPercent = Math.floor((loaded * 100) / total);

        setPercent(newPercent);
      }
    },
  };

  const handleVideo = (value) => {
    setPercent(0);
    return api.uploadAsset(uploadOptions, value).then((response) => {
      if (response) {
        getLandingPageData(consultancyId);
        enqueueSnackbar("Video uploaded", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Upload failed", {
          variant: "error",
        });
      }
    });
  };

  const deleteAsset = () => {
    return api.deleteAsset(landingPageId, "video").then((response) => {
      if (response.status === 200) {
        handleDelete();
        enqueueSnackbar("video deleted", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Failed to delete video", {
          variant: "error",
        });
      }
    });
  };

  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function(p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  return (
    <>
      <Grid container style={{ paddingTop: ".4rem" }}>
        <Grid item xs={6}>
          <Button
            variant="text"
            endIcon={expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => {
              setExpand(!expand);
            }}
          >
            Video
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="row-reverse" alignItems="center">
            <Tooltip
              enterTouchDelay={1}
              title="The video player is 16:9 format. Large video files will negatively impact page load times."
            >
              <InfoOutlinedIcon />
            </Tooltip>
            <Slide in={expand} direction="left">
              <div>
                <Switch
                  checked={enabled}
                  color="primary"
                  style={{ marginLeft: "auto" }}
                  onChange={() => toggleContentState("display_video")}
                />
                <Button
                  variant="text"
                  onClick={() => toggleContentState("display_video")}
                >
                  Display Video
                </Button>
              </div>
            </Slide>
          </Box>
        </Grid>
      </Grid>
      <Collapse in={expand}>
        <Grid container>
          <Grid item xs={12}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <Paper style={{ margin: "2rem", padding: "2rem" }}>
                {!assetData && (
                  <CustomFileDropzone
                    type="single"
                    format="video"
                    actionText="Upload Video"
                    uploadFunction={handleVideo}
                    style={{ width: "200px" }}
                    percent={percent}
                  />
                )}
                {assetData && (
                  <>
                    <div style={{ position: "relative" }}>
                      {!playing && !isSafari && (
                        <PlayCircleFilledWhiteIcon
                          onClick={() => setPlaying(true)}
                          style={{
                            position: "absolute",
                            left: `50%`,
                            top: `50%`,
                            width: "50px",
                            height: "50px",
                            color: "white",
                            zIndex: "1000",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )}
                      <VideoBlock
                        play={{ playing, setPlaying }}
                        assetUrl={assetData}
                      />
                    </div>

                    <Box className={classes.button_container}>
                      <Button
                        variant="text"
                        color="primary"
                        endIcon={<DeleteForeverIcon />}
                        onClick={deleteAsset}
                      >
                        Delete Video
                      </Button>
                    </Box>
                  </>
                )}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Collapse>
      <Divider />
    </>
  );
};

export default ManageVideo;
