import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import Page from "src/components/Page";
import { useSelector } from "react-redux";
import {
	Container,
	Grid,
	Avatar,
	ListItemText,
	Divider,
	CircularProgress,
	Typography,
	List,
	ListItem,
	Box,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	OutlinedInput,
	Chip,
} from "@material-ui/core";
import * as SearchApi from "src/api/searchApi";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import PeopleIcon from "@material-ui/icons/People";
import FilterListIcon from "@material-ui/icons/FilterList";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import moment from "moment";
import FaceIcon from "@material-ui/icons/Face";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		margin: theme.spacing(3),
	},
	container: {
		padding: "0% 5%",
	},
	fields: {
		margin: theme.spacing(-1),
		display: "flex",
		flexWrap: "wrap",
		"& > *": {
			flexGrow: 1,
			margin: theme.spacing(1),
		},
	},
	submitButton: {
		marginTop: theme.spacing(2),
		width: "100%",
	},
	modalCard: {
		[theme.breakpoints.down("md")]: {
			width: "100%",
			transform: `translate(${0}%, ${50}%)`,
		},
		[theme.breakpoints.up("md")]: {
			width: "50%",
			transform: `translate(${50}%, ${50}%)`,
		},
		"&:focus": {
			outline: "none",
		},
	},
	gridRow: {
		marginTop: "20px",
	},
	appBar: {
		boxShadow: "none",
		backgroundColor: "transparent",
	},
	tabBar: {},
	tab: {
		fontSize: "18px",
		[theme.breakpoints.down("sm")]: {
			fontSize: "12px",
		},
		textTransform: "none",
	},
	clickableCard: {
		cursor: "pointer",
		margin: 10,
	},
	listItem: {
		marginBottom: 10,
		backgroundColor: theme.palette.common.white,
		color: "black",
	},
}));

function SearchResults() {
	const classes = useStyles();
	const [loading, setLoading] = useState(true);
	const [searchResults, setSearchResults] = useState({});
	const history = useHistory();
	const consultancyId = useSelector((state) => state.session.consultancyId);
	const [sort, setSort] = useState("");
	const [originalResults, setOriginalResults] = useState({});
	const [t] = useTranslation(["common", "menu"]);

	var myParam = window.location.search.split("search=")[1];

	const filterTypes = ["campaigns", "employers", "talentalerts", "contacts"];

	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			consultancyId,
			selectedCandidates: [],
		},
		touched: {},
		errors: {},
	});

	const handleFilterChange = (event) => {
		event.persist();
		setFormState((prevFormState) => ({
			...prevFormState,
			values: {
				...prevFormState.values,
				[event.target.name]:
					event.target.type === "checkbox"
						? event.target.checked
						: event.target.value,
			},
			touched: {
				...prevFormState.touched,
				[event.target.name]: true,
			},
		}));

		if (event.target.value.length > 0) {
			setSearchResults((prevResults) => ({
				...prevResults,
				hits: originalResults.hits.filter((result) =>
					event.target.value.includes(result._index)
				),
			}));
		} else {
			setSearchResults((prevResults) => ({
				...prevResults,
				hits: originalResults.hits,
			}));
		}
	};

	const handleSortChange = (event) => {
		sortSearchData(event.target.value);
	};

	const sortSearchData = (sortType) => {
		setSort(sortType);

		switch (sortType) {
			case "aToz":
				setSearchResults((prevResults) => ({
					...prevResults,
					hits: searchResults.hits.sort((a, b) =>
						a._source.title.localeCompare(b._source.title)
					),
				}));
				break;
			case "zToa":
				setSearchResults((prevResults) => ({
					...prevResults,
					hits: searchResults.hits.sort((a, b) =>
						b._source.title.localeCompare(a._source.title)
					),
				}));
				break;
			case "all":
				setSearchResults((prevResults) => ({
					...prevResults,
					hits: searchResults.hits.sort((a, b) =>
						a._source.title.localeCompare(b._source.title)
					),
				}));
				break;
			case "createdAsc":
				setSearchResults((prevResults) => ({
					...prevResults,
					hits: searchResults.hits.sort(
						(a, b) => moment(a._source.createdAt) - moment(b._source.createdAt)
					),
				}));
				break;
			case "createdDsc":
				setSearchResults((prevResults) => ({
					...prevResults,
					hits: searchResults.hits.sort(
						(a, b) => moment(b._source.createdAt) - moment(a._source.createdAt)
					),
				}));
				break;
			default:
		}
	};

	useEffect(() => {
		const getSearchResults = (myParam) => {
			return SearchApi.search(consultancyId, myParam).then((result) => {
				setLoading(false);
				setSearchResults(result);
				setOriginalResults(result);
			});
		};

		getSearchResults(myParam);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myParam]);

	const handleClick = (result) => {
		if (result._index === "campaigns") {
			history.push(`/dashboard/campaigns/${result._id}`);
		} else if (result._index === "employers") {
			history.push(`/dashboard/employers/${result._id}`);
		} else if (result._index === "contacts") {
			history.push(`/dashboard/contacts/${result._id}`);
		} else if (result._index === "talentalerts") {
			history.push(`/dashboard/talent-alert/${result._id}`);
		}
	};

	return (
		<Page className={classes.root} title={`Search Results | ${myParam} `}>
			<Container className={classes.container} maxWidth="xl">
				{loading ? (
					<div style={{ textAlign: "center" }}>
						<CircularProgress />
					</div>
				) : (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12} className={classes.gridRow}>
								<Grid container spacing={3}>
									<Grid item xs={8} className={classes.gridRow}>
										<Typography variant="h3">
											{t("common:searchTerm")} : {myParam}
										</Typography>
									</Grid>
									<Grid item xs={2} className={classes.gridRow}>
										<FormControl
											fullWidth
											variant="outlined"
											className={classes.formControl}
										>
											<InputLabel id="demo-mutiple-chip-label">
												<FilterListIcon /> Filter By
											</InputLabel>
											<Select
												labelId="groupLabel"
												name={"selectedCandidates"}
												multiple
												value={formState.values.selectedCandidates}
												onChange={handleFilterChange}
												input={<OutlinedInput />}
												renderValue={(selected) => (
													<div className={classes.chips}>
														{selected.map((value) => (
															<Chip
																key={value}
																label={value}
																className={classes.chip}
															/>
														))}
													</div>
												)}
											>
												{filterTypes.map((filterType) => (
													<MenuItem key={filterType} value={filterType}>
														{filterType}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={2} className={classes.gridRow}>
										<FormControl
											fullWidth
											variant="outlined"
											className={classes.formControl}
										>
											<InputLabel id="demo-simple-select-sort">
												<SwapVertIcon />
												{t("common:sortBy")}
											</InputLabel>
											<Select
												labelId="demo-simple-select-sort"
												id="demo-simple-select-outlined"
												value={sort}
												onChange={handleSortChange}
												label="Sorty By"
											>
												<MenuItem value="all">{t("common:all")}</MenuItem>
												<MenuItem value="aToz">A - Z</MenuItem>
												<MenuItem value="zToa">Z - A</MenuItem>
												<MenuItem value="createdAsc">
													{t("common:latest")}
												</MenuItem>
												<MenuItem value="createdDsc">
													{t("common:oldest")}
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid item xs={12} className={classes.gridRow}>
									<Typography variant="h4">
										{t("common:totalSearchResults")}:{" "}
										{searchResults?.hits?.length}
									</Typography>
								</Grid>
								<Grid item xs={12} className={classes.gridRow}>
									<List className={classes.root}>
										{searchResults?.hits?.map((searchResult) => {
											return (
												<Box boxShadow={1}>
													<ListItem
														className={classes.listItem}
														button
														onClick={(event) => handleClick(searchResult)}
													>
														<ListItemAvatar>
															<Avatar>
																{searchResult._index === "campaigns" ? (
																	<AssignmentIndIcon />
																) : searchResult._index === "employers" ? (
																	<BusinessIcon />
																) : searchResult._index === "contacts" ? (
																	<PersonIcon />
																) : searchResult._index === "talentalerts" ? (
																	<PeopleIcon />
																) : searchResult._index === "candidates" ? (
																	<FaceIcon />
																) : (
																	""
																)}
															</Avatar>
														</ListItemAvatar>
														<ListItemText
															primary={
																<Typography variant="h4">
																	{searchResult._source.title}
																</Typography>
															}
															secondary={
																searchResult._index === "campaigns" ? (
																	<Typography
																		className={classes.blackH5}
																		variant="h5"
																	>
																		{t("common:campaign")}
																	</Typography>
																) : searchResult._index === "employers" ? (
																	<Typography
																		className={classes.blackH5}
																		variant="h5"
																	>
																		{t("common:employer")}
																	</Typography>
																) : searchResult._index === "contacts" ? (
																	<Typography
																		className={classes.blackH5}
																		variant="h5"
																	>
																		{t("common:contact")}
																	</Typography>
																) : searchResult._index === "talentalerts" ? (
																	<Typography
																		className={classes.blackH5}
																		variant="h5"
																	>
																		{t("common:talentAlerts")}
																	</Typography>
																) : searchResult._index === "candidates" ? (
																	<Typography
																		className={classes.blackH5}
																		variant="h5"
																	>
																		{t("common:candidate")}
																	</Typography>
																) : (
																	""
																)
															}
														/>
														<Divider />
													</ListItem>
												</Box>
											);
										})}
									</List>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}
			</Container>
		</Page>
	);
}

export default SearchResults;
