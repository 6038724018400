import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import { CampaignCard } from './../../components/Cards/CampaignCard';
import TermsAndConditionsAcceptanceModal from './TermsAndConditionAcceptanceModal';
import { PageTitle } from './../../components/LayoutComponents/PageTitle';
import { ContentWrapper } from '../../components/LayoutComponents/ContentWrapper';
import Page from './../../components/Page';

import * as talentAlertApi from './../../api/talentAlertApi';

const useStyles = makeStyles(() => ({
  gridRow: {
    marginTop: '20px'
  }
}));

const TalentAlertCandidateDashboard = () => {

  const classes = useStyles();
  const history = useHistory();
  const [t] = useTranslation(['campaigns', 'common', 'snackbar', 'newTranslations']);
  const { enqueueSnackbar } = useSnackbar();

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const consultancyName = useSelector((state) => state.session.consultancyName);

  const [myCampaign, setMyCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState({});

  useEffect(() => {
    setIsLoading(true);

    talentAlertApi.getCandidateTalentAlerts(consultancyId)
      .then((response) => {
        setMyCampaigns(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setIsLoading(false);
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTermsAndConditionsModalOpen = () => {
    setTermsAndConditionsOpen(true);
  };

  const handleTermsAndConditionsModalClose = () => {
    setTermsAndConditionsOpen(false);
  };

  const onTermsAndConditionsAcceptance = () => {
    talentAlertApi.acceptCampaignTermsAndConditions(Number(consultancyId), selectedCampaign.id)
      .then(() => {
        enqueueSnackbar(`${t('snackbar:termsAndConditionsAcceptedSuccess')}`, { variant: 'success' });
        history.push(`/dashboard/talent-alerts/${selectedCampaign.id}`);
      })
      .catch((error) => {
        console.log(error);
        
        enqueueSnackbar(`${t('snackbar:termsAndConditionsAcceptedFailure')}`, { variant: 'error' });
      });
  };

  const goToCampaign = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/talent-alerts/${campaignId}`);
  };

  const showTermsAndConditions = (event, campaign) => {
    event.preventDefault();

    setSelectedCampaign(campaign);
    handleTermsAndConditionsModalOpen();
  };

  const btnFunc = (event, campaign) => {
    if (campaign.acceptedTCs){
      goToCampaign(event, campaign.id);
      return;
    }

    showTermsAndConditions(event, campaign);
  };

  return (
    <Page title={consultancyName}>
      <PageTitle pageTitle={t("newTranslations:talentAlerts")} />

      <ContentWrapper loading={isLoading}>
        <Typography>
          { t("campaigns:candidateCampaignList") }
        </ Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.gridRow}>
            <Grid container spacing={3}>
              {
                myCampaign?.map((campaign) => (
                  <Grid item md={4} sm={6} xs={12}>
                    <CampaignCard
                      name={campaign.campaignTitle}
                      btnLabel={t("common:view")}
                      btnFunc={(e) => { btnFunc(e, campaign) }}
                      campaignLogo={campaign.campaignLogo} />
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </ContentWrapper>

      <TermsAndConditionsAcceptanceModal
        onSuccess={onTermsAndConditionsAcceptance}
        open={termsAndConditionsOpen}
        handleClose={handleTermsAndConditionsModalClose}
        campaignTC={selectedCampaign.campaignTC || ""} />
    </Page>
  );

};

export default TalentAlertCandidateDashboard;
