import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Hidden, Typography } from '@material-ui/core';
import { ThemeIconButton } from 'src/components/LayoutComponents/ThemeIconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  listCard: {
    marginTop: 8,
    backgroundColor: '#f5f5f5',
    height: 39,
    padding: 0,
    paddingLeft: 8,
  },
  contactListCard: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  contactText: {
    flex: 2,
    minWidth: 60,
    textAlign: 'left',
    fontWeight: 400,
  },
  email: {
    textDecoration: 'underline',
    textDecorationColor: theme.palette.text.secondary,
    color: theme.palette.text.secondary,
    fontSize: '.9rem',
  },
  addButton: {
    marginLeft: 16,
  },
  progressCircle: {
    color: theme.palette.primary.contrastText,
  },
}));

function ContactListCard({ contact, onAdd, ...rest }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const addContact = () => {
    setLoading(true);
    onAdd(contact.id).then(() => {
      setLoading(false);
    });
  };

  return (
    <Card
      className={classes.listCard}
      {...rest}
      variant='outlined'
      elevation={0}
    >
      <CardContent className={classes.contactListCard}>
        <Typography
          className={classes.contactText}
        >{`${contact.profile.firstName} ${contact.profile.lastName} ${contact.jobTitle}`}</Typography>
        <Hidden smDown>
          <a
            href={`mailto:${contact.profile.user.email}`}
            className={classes.email}
          >
            {contact.profile.user.email}
          </a>
        </Hidden>
        <div className={classes.addButton}>
          <ThemeIconButton
            icon={<AddIcon />}
            btnFunc={addContact}
            loading={loading}
          />
        </div>
      </CardContent>
    </Card>
  );
}
export default ContactListCard;
