import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Table,
	Typography,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core";
import logoPlaceholder from "src/assets/images/placeholder.png";

const useStyles = makeStyles((theme) => ({
	card: {
		transition: "300ms",
		maxWidth: "360px",
		overflow: "visible",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: theme.spacing(12),
	},
	card_disabled: {
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	name: {
		transition: "300ms",
		paddingBottom: ".5rem",
		borderBottom: `1px solid ${theme.palette.text.primary}`,
		minWidth: "326px",
	},
	remove_underline: {
		transition: "300ms",
		paddingBottom: ".5rem",
		minWidth: "326px",
	},
	name_disabled: {
		marginTop: theme.spacing(1),
		backgroundColor: "transparent",
		color: "grey",
		transition: "300ms",
	},
	logo_container: {
		width: 300,
		boxShadow: "0 0.5rem 1rem #C2C2C2",
		transform: "translateY(-50px)",
		backgroundColor: theme.palette.background.paper,
		marginLeft: "auto",
		marginRight: "auto",
		marginBottom: "-2rem",
		display: "flex",
		justifyContent: "center",
    "&:hover": {
      cursor: "pointer"
    }
	},
	logo: {
		height: 68,
		width: "auto",
		maxWidth: 268,
		margin: "1rem",
	},
}));

export const CampaignCard = ({
	name,
	btnLabel,
	btnFunc,
	disableBtn,
	enabled = true,
	campaignDetails,
	campaignLogo,
}) => {
	const classes = useStyles();
	return (
		<Card className={enabled ? classes.card : classes.card_disabled}>
			<Box className={classes.logo_container}>
				<a onClick={btnFunc} > {/* eslint-disable-line jsx-a11y/anchor-is-valid */}
					<img
						src={campaignLogo ? campaignLogo : logoPlaceholder}
						className={classes.logo}
						alt=""
					/>
				</a>
			</Box>

			<CardContent>
				<Box
					display="flex"
					alignItems="center"
					flexDirection="column"
					textAlign="center"
				>
					<Box>
						<Typography
							className={
								!campaignDetails
									? classes.remove_underline
									: enabled
										? classes.name
										: classes.name_disabled
							}
							gutterBottom
							variant="h3"
							color="textPrimary"
						>
							{name}
						</Typography>
					</Box>
				</Box>
				<Table className={classes.table} aria-label="simple table">
					<TableBody>
						{campaignDetails &&
							campaignDetails.map((detail) => (
								<TableRow key={detail.name}>
									<TableCell component="th" scope="row">
										{detail.name}
									</TableCell>
									<TableCell align="left">{detail.data}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</CardContent>
			<CardActions>
				{btnLabel ? (
					<Button
						fullWidth
						variant="text"
						color="primary"
						onClick={btnFunc}
						disabled={disableBtn ? true : false}
					>
						{btnLabel}
					</Button>
				) : null}
			</CardActions>
		</Card>
	);
};
