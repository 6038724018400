import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useTheme, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useSnackbar } from 'notistack';

import Swal from 'sweetalert2';

import { SET_CANDIDATE_CAMPAIGN } from '../../actions/candidateCampaignActions';

import * as talentAlertApi from '../../api/talentAlertApi';

import Page from '../../components/Page';
import { PageTitle } from '../../components/LayoutComponents/PageTitle';
import ProfileDetails from '../TalentAlertConsultant/TalentAlertCandidatePool/TalentAlertCandidateProfile/ProfileDetails';
import { CardButton } from '../../components/LayoutComponents/CardButton';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  grid: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const CandidateProfileTalentAlert = ({ campaignId, consultancyId }) => {

  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [t] = useTranslation(['common', 'snackbar', 'newTranslations']);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const candidateCampaign = useSelector((state) => state.candidateCampaign.candidateProfile);

  const [isLoading, setIsLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const candidateId = candidateCampaign?.candidateId;

  useEffect(() => {
    getCandidateProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultancyId, campaignId]);

  const getCandidateProfile = async () => {
    try {
      setIsLoading(true);

      const acceptedTCsData = await talentAlertApi.checkIfAcceptedTCs(campaignId);

      if (!acceptedTCsData.data.acceptedTCs) {
        history.push(`/terms-and-conditions/${campaignId}`);
        return;
      }

      const candidateTalentAlertProfileResponse = await talentAlertApi.getCandidateTalentAlertProfile(campaignId, consultancyId);

      dispatch({
        type: SET_CANDIDATE_CAMPAIGN,
        candidateCampaign: {
          candidateProfile: candidateTalentAlertProfileResponse.data
        }
      });

      setSubmitted(candidateTalentAlertProfileResponse.data.status === 'Submitted');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      history.push('/dashboard');
    }
  };

  const submitProfile = (event) => {
    event.preventDefault();

    const color = theme.palette.warning?.main ? theme.palette.warning.main : theme.palette.primary.main;

    const fireOptions = {
      title: `${t('common:areYouSure')}`,
      text: `${t('common:youAreSubmittingYourProfileYouWillNoBeAbleToChangeThis')}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `${t('common:yes')}`,
      cancelButtonText: `${t('common:no')}`,
      confirmButtonColor: color
    };

    Swal.fire(fireOptions)
      .then((result) => {
        if (result.isConfirmed) {
          talentAlertApi.submitCandidateProfile(consultancyId, candidateId, campaignId)
            .then(() => {
              return talentAlertApi.getCandidateTalentAlertProfile(campaignId, consultancyId);
            })
            .then((response) => {
              if (response.data.status === 'Submitted') {
                setSubmitted(true);
                enqueueSnackbar(t('snackbar:profileSubmitted'), { variant: 'success' });
                return;
              }

              setSubmitted(false);
              enqueueSnackbar(t('snackbar:failedToUpdateProfile'), { variant: 'error' });
            });
        }
      });
  };

  return (
    <Page className={classes.root} >
      <PageTitle pageTitle={`Talent Alert - ${candidateCampaign?.firstName}  ${candidateCampaign?.lastName}`} />

      {
        submitted ? (
          <Grid className={classes.grid}>{t("newTranslations:profileSubmitted")}</Grid>
        ) : (
          <>
            <CardButton
              btnFunc={submitProfile}
              btnDisabled={submitted}
              className={classes.button}
              btnLabel={t("newTranslations:submitProfile")}
              addMargin
              save />

            <ProfileDetails
              campaignId={campaignId}
              candidateId={candidateId}
              consultancyId={consultancyId}
              candidateCampaign={candidateCampaign}
              isLoading={isLoading} />
          </>
        )
      }
    </Page>
  );

};

export default CandidateProfileTalentAlert;
