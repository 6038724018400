import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { Grid, AppBar, Typography } from "@material-ui/core";
import * as SessionApi from "src/api/sessionApi";
import * as CampaignApi from "src/api/campaignApi";
import * as EmployerApi from "src/api/employerApi";
import * as SysApi from "src/api/SystemAdminApi";
import { useTranslation } from "react-i18next";
import { ContentWrapper } from "src/components/LayoutComponents/ContentWrapper";
import { TabPanel } from "src/components/TabPanel";
import UserProfile from "./UserProfile";
import { ProfileCard } from "src/components/ProfileCard";
import { useSnackbar } from "notistack";
import validate from "validate.js";
import CustomTabs from './../../components/LayoutComponents/CustomTabs';

import { useSelector } from 'react-redux';

import * as talentAlertApi from './../../api/talentAlertApi';

import * as subscriptionPlansIds from './../../constants/subscriptionPlansIds';

const useStyles = makeStyles(() => ({
  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    marginTop: 40,
  },
}));

function UserDetails({ userId, consultancyId }) {
  const [t] = useTranslation(["common", "users"]);
  const classes = useStyles();
  const [user, setUser] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [campaigns, setCampaigns] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [enabled, setEnabled] = useState();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const tabs = [
    { value: 0, label: t("common:profileLabel") },
    { value: 1, label: t("common:campaignsLabel") },
    { value: 3, label: t("common:employersLabel") },
  ];

  const { consultancySubscriptionPlanId } = useSelector((state) => state.session);

  const schema = {
    firstName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    lastName: {
      presence: { allowEmpty: false, message: t("common:required") },
      length: {
        maximum: 64,
      },
    },
    email: {
      presence: { allowEmpty: false, message: t("common:required") },
      email: true
    },
    country: {
      presence: { allowEmpty: false, message: t("common:required") },
    },
  };

  const handleTabChange = (value) => {
    setCurrentTab(value);
  };

  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };

  const handleUploadProfilePicture = (value) => {
    return SessionApi.uploadProfilePictureByUserId(consultancyId, userId, value)
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(t("snackbar:profilePictureUpdated"), {
            variant: "success",
          });
          getProfile(userId, consultancyId);
        } else {
          throw Error("Failed to update profile picture");
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:profilePictureUpdatedError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const saveDetails = (event) => {
    event.preventDefault();

    return SessionApi.updateProfileByUserId(formState.values, consultancyId)
      .then(() => {
        if (formState.values.userId) {
          enqueueSnackbar(t("snackbar:updateUserProfile"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:updateProfile"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:failedToUpdateProfile"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const handleLocaleChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        localeId: value.props.value,
      },
      touched: {
        ...prevFormState.touched,
        localeId: true,
      },
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  const toggleAccount = (value) => {
    SysApi.toggleCandidateUserStatus(consultancyId, userId)
      .then((response) => {
        setEnabled(response.data.enabled);

        if (response.data.enabled) {
          enqueueSnackbar(t("snackbar:candidateAccountEnabled"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("snackbar:candidateAccountDisabled"), {
            variant: "success",
          });
        }
      })
      .catch((error) => {
        enqueueSnackbar(t("snackbar:candidateAccountError"), {
          variant: "error",
        });
        console.error(error);
      });
  };

  const getProfile = function(userId, consultancyId) {
    return SessionApi.getProfileByUserId(userId, consultancyId)
      .then((response) => {
        setUser(response.data);
        const userData = {
          salutation: response.data.salutation,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          telephone: response.data.telephone,
          addressLine1: response.data.addressLine1,
          addressLine2: response.data.addressLine2,
          city: response.data.city,
          postalCode: response.data.postalCode,
          country: response.data.country?.id,
          enabled: response.data.enabled,
          userId: userId,
          profilePhoto: response.data.profilePicture,
        };

        setFormState((initialState) => ({
          ...initialState,
          values: userData,
        }));

        setEnabled(response.data.enabled);
      })
      .catch((error) => {
        history.push("/dashboard");
        console.error(error);
      });
  };

  const getCampaigns = function(userId, consultancyId) {
    let campaignsPromise;

    if (consultancySubscriptionPlanId === subscriptionPlansIds.FULL) {
      campaignsPromise = CampaignApi.getCampaignsManagedBySelectedUser(userId, consultancyId);
    } else {
      campaignsPromise = talentAlertApi.getCampaignsManagedBySelectedUser(userId, consultancyId);
    }

    return campaignsPromise
      .then((response) => {
        setCampaigns(response.data);
      })
      .catch((error) => {
        history.push("/dashboard");
        console.error(error);
      });
  };

  const getEmployers = function(userId, consultancyId) {
    return EmployerApi.getUserEmployers(userId, consultancyId)
      .then((response) => {
        setEmployers(response.data);
      })
      .catch((error) => {
        history.push("/dashboard");
        console.error(error);
      });
  };

  useEffect(() => {
    if (!userId) {
      history.push("/dashboard");
    } else {
      getProfile(userId, consultancyId);
      getCampaigns(userId, consultancyId);
      getEmployers(userId, consultancyId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const goToCampaignDetails = (event, campaignId) => {
    event.preventDefault();
    history.push(`/dashboard/campaigns/${campaignId}`);
  };

  const goToEmployerDetails = (event, employerId) => {
    event.preventDefault();
    history.push(`/dashboard/employers/${employerId}`);
  };

  function createData(name, data) {
    return { name, data };
  }

  const getCampaingOwnerName = (owner) =>
    owner
      ? ` ${owner.profile.salutation ? owner.profile.salutation : ""} ${
          owner.profile.firstName
        } ${owner.profile.lastName} `
      : "TBC";

  const getOwner = (admin, consultant, managedByRole) =>
    consultant && managedByRole === "consultant" ? consultant : admin;

  const getCampaignDetails = (campaign) => {
    const date = new Date(campaign.createdAt);
    const formattedDate = `${date.getDate()}/${date.getMonth() +
      1}/${date.getFullYear()}`;
    const employerName = campaign.assignmentCampaign
      ? campaign.assignmentCampaign.employer.employerName
      : "";
    const campaignStatus = campaign.campaignStatus
      ? campaign.campaignStatus.status
      : "draft";
    const campaignCandidates =
      campaign.candidates && campaign.candidates.length
        ? campaign.candidates.length
        : 0;

    const assignedTo = getCampaingOwnerName(
      getOwner(campaign.admin, campaign.consultant, campaign.managedByRole)
    );
    const campaignDetails = [
      createData(t(`campaigns:employerLabel`), employerName),
      createData(t(`campaigns:campaignStatusLabel`), campaignStatus),
      createData(t(`campaigns:dateStarted`), formattedDate),
      createData(t(`campaigns:candidatesLabel`), campaignCandidates),
      createData(t(`campaigns:assignedTo`), assignedTo),
    ];
    return campaignDetails;
  };

  return userId ? (
    <div style={{ overflow: "hidden", paddingBottom: "40px" }}>
      <ContentWrapper>
        <AppBar position="static" className={classes.appBar}>
          <CustomTabs tabs={ tabs } onChange={ handleTabChange } tabClassName={ classes.tab } />
        </AppBar>
        <TabPanel value={currentTab} index={0}>
          {user && (
            <Grid container spacing={3}>
              <UserProfile
                userId={userId}
                consultancyId={consultancyId}
                toggleAccount={toggleAccount}
                enabled={enabled}
                saveDetails={saveDetails}
                handleChange={handleChange}
                hasError={hasError}
                handleCountryChange={handleCountryChange}
                handleLocaleChange={handleLocaleChange}
                formState={formState}
                handleUploadProfilePicture={handleUploadProfilePicture}
              />
            </Grid>
          )}
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Grid container spacing={3}>
            {campaigns.length ? (
              campaigns.map((campaign, index) => (
                <Grid key={index} item md={4} sm={6} xs={12}>
                  <ProfileCard
                    campaignDetails={getCampaignDetails(campaign)}
                    name={campaign.campaignTitle}
                    btnLabel={t("common:viewMore")}
                    btnFunc={(event) => {
                      goToCampaignDetails(event, campaign.id);
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography align="center">
                    {t("users:userHasNoCampaigns")}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </TabPanel>
        <TabPanel value={currentTab} index={3}>
          <Grid container spacing={3}>
            {employers.length ? (
              employers.map((employer, index) => (
                <Grid key={index} item md={4} sm={6} xs={12}>
                  <ProfileCard
                    name={employer.employerName}
                    email={employer.managedBy}
                    btnLabel={t("common:viewMore")}
                    btnFunc={(event) => {
                      goToEmployerDetails(event, employer.id);
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography align="center">
                    {t("users:userHasNoEmployers")}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </TabPanel>
      </ContentWrapper>
    </div>
  ) : null;
}

export default UserDetails;
