import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import {
  TextField,
  colors,
  CardContent,
  Card,
} from "@material-ui/core";
import validate from "validate.js";
import { useSnackbar } from "notistack";
import { updateCampaignDetails } from "src/api/campaignApi";
import { useTranslation } from "react-i18next";
import CountrySelect from "src/components/CountrySelect";
import { UPDATE_CAMPAIGN_DETAILS } from "src/actions";
import { BlockHeader } from "src/components/LayoutComponents/BlockHeader";
import { CardButton } from "src/components/LayoutComponents/CardButton";
import { useCampaignOrVacancyTranslationPrefix } from '../../../utils/useSubscriptionPrefix';

//-TODO: Translations

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    padding: "0px 10px",
  },
  fields: {
    margin: theme.spacing(-1),
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: "33%",
  },
  modalCard: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      transform: `translate(${0}%, ${10}%)`,
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      transform: `translate(${50}%, ${10}%)`,
    },
    "&:focus": {
      outline: "none",
    },
  },
  closeButton: {
    minWidth: "32px",
    backgroundColor: colors.red[600],
    "&:hover": {
      backgroundColor: colors.red[400],
    },
    color: theme.palette.common.white,
    textTransform: "none",
  },
  modalOverflow: {
    overflowY: "scroll",
  },
}));

const schema = {
  campaignTitle: {
    presence: { allowEmpty: false, message: "is required" },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
    },
  },
  postalCode: {
    presence: { allowEmpty: false, message: "is required" },
  },
  country: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

function CampaignInformation({ guestView }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation([
    "common",
    "validation",
    "campaigns",
    "tooltips",
    "vacancies"
  ]);
  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();
  const consultancyId = useSelector((state) => state.session.consultancyId);
  const campaign = useSelector((state) => state.campaign);
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      country: {},
    },
    touched: {},
    errors: {},
  });

  const handleChange = (event) => {
    event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) => {
    if (formState.touched[field] && formState.errors[field]) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    return updateCampaignDetails({
      campaignId: campaign.id,
      consultancyId,
      details: formState.values,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: UPDATE_CAMPAIGN_DETAILS,
            campaignTitle: formState.values.campaignTitle,
            reference: formState.values.reference,
            campaignCity: formState.values.city,
            campaignPostalCode: formState.values.postalCode,
            campaignCountry: formState.values.country,
          });
          enqueueSnackbar("Updated Details", { variant: "success" });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Error Updating Details", { variant: "error" });
      });
  };
  const handleCountryChange = (value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        country: value,
      },
      touched: {
        ...prevFormState.touched,
        country: true,
      },
    }));
  };
  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        employerName: campaign.employerName,
        campaignTitle: campaign.campaignTitle,
        reference: campaign.reference,
        city: campaign.campaignCity,
        postalCode: campaign.campaignPostalCode,
        country: campaign.campaignCountry,
      },
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaign.campaignTitle,
    campaign.campaignCity,
    campaign.campaignPostalCode,
    campaign.campaignCountry,
  ]);

  return (
    <Card>
      <BlockHeader
        headerTooltip={t("tooltips:campaignRoleDetails")}
        card
        padding
        header={t(`${campaignsPrefix}:campaignDetailLabel`)}
      />
      <CardContent>
        <form onSubmit={handleSubmit}>
          <div className={classes.fields}>
            <TextField
              fullWidth
              label={t("common:employerName")}
              name="employerName"
              variant="outlined"
              value={formState.values.employerName}
              disabled={true}
            />
            <TextField
              fullWidth
              label={t("common:jobPositionLabel")}
              name="campaignTitle"
              variant="outlined"
              required
              onChange={handleChange}
              error={hasError("campaignTitle")}
              value={formState.values.campaignTitle || ""}
              helperText={
                hasError("campaignTitle")
                  ? t(`validation:${formState.errors.campaignTitle[0]}`)
                  : null
              }
              disabled={guestView}
            />
            <TextField
              fullWidth
              label={t("common:referenceLabel")}
              name="reference"
              variant="outlined"
              onChange={handleChange}
              error={hasError("reference")}
              value={formState.values.reference || ""}
              helperText={
                hasError("reference")
                  ? t(`validation:${formState.errors.reference[0]}`)
                  : null
              }
              disabled={guestView}
            />
            <TextField
              fullWidth
              name="city"
              label={t("common:cityLabel")}
              variant="outlined"
              onChange={handleChange}
              error={hasError("city")}
              value={formState.values.city || ""}
              helperText={
                hasError("city")
                  ? t(`validation:${formState.errors.city[0]}`)
                  : null
              }
              disabled={guestView}
            />
            <TextField
              fullWidth
              name="postalCode"
              label={t("common:postcodeLabel")}
              variant="outlined"
              onChange={handleChange}
              error={hasError("postalCode")}
              value={formState.values.postalCode || ""}
              helperText={
                hasError("postalCode")
                  ? t(`validation:${formState.errors.postalCode[0]}`)
                  : null
              }
              disabled={guestView}
            />
            <CountrySelect
              handleCountryChange={handleCountryChange}
              value={formState.values.country}
              disabled={guestView}
            />
          </div>
          {!guestView && (
            <CardButton
              btnDisabled={!formState.isValid}
              loading={loading}
              btnLabel={t("common:update")}
            />
          )}
        </form>
      </CardContent>
    </Card>
  );
}
export default CampaignInformation;
