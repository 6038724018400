import React from 'react';
import { useHistory, useLocation } from 'react-router';

import { Fab } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles(() => ({
    fab: {
        position: 'fixed',
        top: 42,
        left: 16,
        fontSize: '20px',
        zIndex: 9999
    }
}));

const TalentAlertBackButton = () => {

    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();

    const backButtonClick = () => {
        if (location.state?.previousPath) {
            history.goBack();
            return;
        }

        history.push('/dashboard');
    };

    return (
        <>
            <Fab className={ classes.fab } onClick={ backButtonClick } >
                <ChevronLeftIcon />
            </Fab>
        </>
    );

};

export default TalentAlertBackButton;
