import axios from '../utils/axios';

//requests both admin and consultant call
export const createEmployer = ({ employerName, country, consultancyId }) => {
  return axios.post('/Employers/createEmployer', {
    employerName,
    countryId: country.id,
    consultancyId,
  });
};

export const getEmployers = (consultancyId) => {
  return axios.get('/Employers/getEmployers', {
    params: {
      consultancyId,
    },
  });
};

export const getMyEmployers = (consultancyId) => {
  return axios.get('/Employers/getMyEmployers', {
    params: {
      consultancyId,
    },
  });
};

export const getEmployerById = (consultancyId, employerId) => {
  return axios.get('/Employers/getEmployerById', {
    params: {
      consultancyId,
      employerId,
    },
  });
};

export const updateEmployerById = (
  consultancyId,
  employerId,
  updatedDetails
) => {
  return axios.post(
    '/Employers/updateEmployerById',
    { ...updatedDetails },
    {
      params: {
        consultancyId,
        employerId,
      },
    }
  );
};

export const getContactById = (contactId, consultancyId) => {
  return axios.get('/Contacts/getContactById', {
    params: {
      contactId,
      consultancyId,
    },
  });
};

export const updateContactById = (contactId, consultancyId, updatedDetails) => {
  return axios.post(
    '/Contacts/updateContactById',
    { ...updatedDetails },
    {
      params: {
        contactId,
        consultancyId,
      },
    }
  );
};

export const updateManagedById = (consultancyId, employerId, userId) => {
  return axios.post(
    '/Employers/updateManagedById',
    { consultancyId },
    {
      params: {
        consultancyId,
        employerId,
        userId,
      },
    }
  );
};

export const uploadEmployerLogo = (consultancyId, employerId, formData) => {
  return axios
    .post('/Employers/uploadEmployerLogo', formData, {
      params: {
        consultancyId,
        employerId,
      },
    })
    .then((response) => {
      if (response.data !== null && response.data !== undefined) {
        return response.data;
      } else {
        throw Error('Failed to retrieve consultancy details');
      }
    });
};

export const getUserEmployers = (userId, consultancyId) => {
  return axios.get('/Employers/getUserEmployers', {
    params: {
      userId,
      consultancyId,
    },
  });
};
