export const replace = (text) => {
  return text
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/=/g, '&equals;')
    .replace(/"/g, '&quot;')
    .replace(/`/g, '&grave;');
};

export const textToHtml = (text) => {
  return text
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>');
};

export const htmlToPlainText = (html) => {
  const pattern = /<[^>]+>/g;
  return html.replace(pattern, '');
};
