import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import * as CandidateApi from 'src/api/candidateApi';
import { useSnackbar } from 'notistack';
import { SET_CAMPAIGN_CANDIDATES, UPDATE_CAMPAIGN_CANDIDATE } from 'src/actions/campaignActions';
import avatarPlaceholder from 'src/assets/images/missing_avatar.svg';
import { useCampaignOrVacancyTranslationPrefix } from '../../../../utils/useSubscriptionPrefix';

import { DWI_ID } from './../../../../constants/moduleIds';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  candidateList: {
    height: '500px',
    overflowY: 'auto',
  },
  candidateButton: {
    cursor: 'pointer',
  },
  popover: {
    width: 200,
    top: '0px',
    left: '0px',
  },
  contactListCard: {
    display: 'flex',
    alignItems: 'center',
  },
  contactText: {
    flex: 2,
    minWidth: 60,
    textAlign: 'left',
  },
  inviteButton: {
    flex: 1,
    margin: 5,
    fontSize: 12,
  },
  moreOptionsButton: {
    padding: 3,
  },
  progressIndicator: {
    marginLeft: 5,
  },
  card: {
    marginTop: '5px',
    marginBottom: '5px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    height: 100,
    width: 100,
    cursor: 'pointer',
  },
  email: {
    color: theme.palette.text.secondary,
    textDecoration: 'underline',
    marginTop: theme.spacing(2),
  },
  candidateStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

function CandidateListCard({ candidates, candidate, consultancyId, campaignId, ...rest }) {

  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [t] = useTranslation(['campaigns', 'campaignCandidates', 'common', 'snackbar', 'vacancies']);

  const { campaignModuleIds } = useSelector(state => state.campaign);

  const campaignsPrefix = useCampaignOrVacancyTranslationPrefix();

  const [open, setOpen] = useState(false);

  const candidateStatusOptions = [
    {
      key: 'notSubmitted',
      value: 1
    },
    {
      key: 'submitted',
      value: 2
    },
    {
      key: 'shortlisted',
      value: 3
    },
    {
      key: 'firstInterview',
      value: 4
    },
    {
      key: 'furtherInterview',
      value: 5
    },
    {
      key: 'rejected',
      value: 6
    },
    {
      key: 'offerMade',
      value: 7
    },
    {
      key: 'hidden',
      value: 8
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onStatusChange = (event) => {
    CandidateApi.updateCandidateProfileStatus(consultancyId, candidate.id, campaignId, event.target.value)
      .then(() => {
        const updatedCandidate = {
          ...candidate,
          status: event.target.value
        };

        dispatch({
          type: UPDATE_CAMPAIGN_CANDIDATE,
          payload: {
            candidate: updatedCandidate
          }
        });

        enqueueSnackbar(t('snackbar:updateCandidateProfile'), { variant: 'success' });
      })
      .catch((error) => {
        console.log(error);
        enqueueSnackbar(t('snackbar:failedToUpdateProfile'), { variant: 'error' });
      });
  };

  const handleRemove = (event) => {
    event.preventDefault();

    return CandidateApi.removeCandidateFromCampaign(
      candidate.id,
      campaignId,
      consultancyId
    )
      .then((response) => {
        enqueueSnackbar(t('snackbar:candidateRemoveSuccess'), {
          variant: 'success',
        });

        dispatch({
          type: SET_CAMPAIGN_CANDIDATES,
          updatedCampaignCandidates: response.data,
        });

        handleClose();
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t('snackbar:candidateRemoveFailure'), {
          variant: 'error',
        });
      });
  };
  const history = useHistory();

  return (
    <>
      <div className={classes.card}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Avatar
                className={classes.avatar}
                src={
                  candidate.avatar !== null
                    ? candidate.avatar
                    : avatarPlaceholder
                }
                onClick={() => {
                  rest?.guestView
                    ? history.push(
                        `/dashboard/consultancy/${consultancyId}/${campaignsPrefix}/${campaignId}/candidates/${candidate.id}`
                      )
                    : history.push(
                        `/dashboard/${campaignsPrefix}/${campaignId}/candidates/${candidate.id}`
                      );
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={campaignModuleIds && campaignModuleIds.includes(DWI_ID) ? 2 : 7}
            alignItems='center'
            className={classes.candidateButton}
          >
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <div>
                <div
                  onClick={() => {
                    rest?.guestView
                      ? history.push(
                          `/dashboard/consultancy/${consultancyId}/${campaignsPrefix}/${campaignId}/candidates/${candidate.id}`
                        )
                      : history.push(
                          `/dashboard/${campaignsPrefix}/${campaignId}/candidates/${candidate.id}`
                        );
                  }}
                >
                  <Typography variant='h3'>
                    {candidate.firstName} {candidate.lastName}
                  </Typography>
                  <Typography variant={'body1'}>
                    {t(`campaigns:candidateStatus${candidate.status}`)}
                  </Typography>
                </div>

                <Button
                  color='primary'
                  startIcon={<MailOutlineIcon />}
                  style={{ marginLeft: -6, fontSize: 14 }}
                  href={`mailto:${candidate.email}`}
                >
                  {t('common:emailLabel')}
                </Button>
              </div>
            </div>
          </Grid>

          <Grid item md={ campaignModuleIds && campaignModuleIds.includes(DWI_ID) ? 4 : 2 } className={ classes.candidateStatus } >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small" >
              <Select
                value={ candidate.status }
                onChange={ onStatusChange } >
                {
                  candidateStatusOptions.map((cso) => (
                    <MenuItem value={ cso.value } >
                      { t(`campaignCandidates:${cso.key}`) }
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          {
            campaignModuleIds && campaignModuleIds.includes(DWI_ID) && (
              <Grid item md={3} xs={12} style={{ textAlign: 'center' }}>
                <Box position='relative' display='inline-flex'>
                  <CircularProgress
                    variant='static'
                    value={candidate.suitabilityScore}
                    style={{ height: 80, width: 80 }}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position='absolute'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                  >
                    <Typography
                      variant='body1'
                      component='div'
                      color='textSecondary'
                    >{`${candidate.suitabilityScore}%`}</Typography>
                  </Box>
                </Box>
                <Typography variant='h5'>
                  {t('campaigns:teamSuitabilityScore')}b
                </Typography>
              </Grid>
            )
          }

          <Grid item md={1} xs={12} alignItems='center'>
            <div
              style={{ height: '100%', display: 'flex', alignItems: 'center' }}
            >
              <IconButton
                style={{ fontSize: 20 }}
                onClick={handleOpen}
                ref={ref}
              >
                <DeleteOutlinedIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider />
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={open}
      >
        <MenuItem onClick={handleRemove}>
          {t('campaigns:removeContactLabel')}
        </MenuItem>
      </Menu>
    </>
  );
}
export default CandidateListCard;
