import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ModalFormWrapper } from "src/components/ModalFormWrapper";

function GuestViewModal({ open, handleClose}) {
  const [t] = useTranslation(["candidates", "common"]);

  const campaign = useSelector(state => state.campaign);

  return (
    <ModalFormWrapper
      formTitle={t('campaigns:campaignKcqTitle')}
      handleOpen={open}
      handleClose={handleClose}
      maxWidth={"sm"}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h3">{campaign.currentCampaignKcq?.name}</Typography>
        </Grid>
        {campaign.currentCampaignKcq?.questions?.map((question) => (
          <Grid item xs={12}>
            <Typography variant="h4">{question}</Typography>
          </Grid>
        ))}
        <Grid></Grid>
      </Grid>

    </ModalFormWrapper>
  );
}
export default GuestViewModal;
