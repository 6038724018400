import axios from "../utils/axios";

//requests both admin and consultant call
export const createConsultancyCandidate = ({
  consultancyId,
  firstName,
  lastName,
  email,
  postalCode,
  country,
}) => {
  return axios.post("/Consultancies/createConsultancyCandidate", {
    consultancyId,
    firstName,
    lastName,
    email,
    postalCode,
    countryId: country.id,
  });
};

export const getConsultancyTitle = (consultancyId) => {
  return axios.get("/Consultancies/getConsultancyTitle", {
    params: { consultancyId },
  });
};

export const getCandidates = (consultancyId) => {
  return axios.get("/Candidates/getCandidates", {
    params: {
      consultancyId,
    },
  });
};

export const batchCreateCandidate = ({ candidates, consultancyId }) => {
  return axios.post("Candidates/batchCreateCandidate", {
    candidates,
    consultancyId,
  });
};

export const createCustomUserGroup = (groupDetails) => {
  return axios.post("/Consultancies/createCustomUserGroup", groupDetails);
};

export const getGroupAndUnassignedUsers = (consultancyId, groupId) => {
  return axios.get("/Groups/getGroupAndUnassignedUsers", {
    params: {
      consultancyId,
      groupId,
    },
  });
};

export const editUserGroup = (groupDetails) => {
  return axios.patch("/Consultancies/editUserGroup", groupDetails);
};

export const deleteUserGroup = (groupDetails) => {
  return axios.delete("/Consultancies/deleteUserGroup", { data: groupDetails });
};

export const getCountries = (consultancyId) => {
  return axios.get("/Countries/getCountries", {
    params: {
      consultancyId,
    },
  });
};

export const getCountryById = (countryId) => {
  return axios.get("/Countries/getCountryById", {
    params: {
      countryId,
    },
  });
};

export const getLocales = (consultancyId) => {
  return axios.get("/Locales/getLocales", {
    params: {
      consultancyId,
    },
  });
};

export const getMyGroups = (consultancyId) => {
  return axios.get("/Groups/getMyGroups", {
    params: {
      consultancyId,
    },
  });
};
export const getConsultancyDetails = (consultancyId) => {
  return axios
    .get("/Consultancies/getConsultancyDetails", {
      params: {
        consultancyId,
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      } else {
        throw Error("Failed to retrieve consultancy details");
      }
    });
};

export const uploadConsultancyLogo = (consultancyId, formData, consultancyLogoType) => {
  const path = `/Consultancies/uploadConsultancyLogo/${consultancyId}/${consultancyLogoType}`

  return axios
    .post(path, formData)
    .then((response) => {
      if (response.data !== null && response.data !== undefined) {
        return response.data;
      } else {
        throw Error("Failed to retrieve consultancy details");
      }
    });
};

export const getConsultancyUsersNamesAndIds = (consultancyId) => {
  return axios.get("/Consultancies/getConsultancyUsersNamesAndIds", {
    params: {
      consultancyId,
    },
  });
};

export const getConsultancyDefaultDocuments = (consultancyId) => {
  return Promise.all([
    axios.get("/DefaultCampaignDocuments/getConsultancyDefaults", {
      params: {
        consultancyId,
      },
    }),
    axios.get("/DefaultCandidateDocuments/getConsultancyDefaults", {
      params: {
        consultancyId,
      },
    }),
    axios.get("/DefaultCandidateDocuments/getConsultancyDefaults", {
      params: {
        consultancyId,
        isForTalentAlert: true
      }
    })
  ]);
};

export const saveDefaultCampaignDocument = (consultancyId, document) => {
  return axios.post("/DefaultCampaignDocuments/saveConsultancyDefault", {
    document,
    consultancyId,
  });
};

export const removeDefaultCampaignDocument = (consultancyId, documentId) => {
  return axios.delete("/DefaultCampaignDocuments/deleteConsultancyDefault", {
    params: {
      consultancyId,
      documentId,
    },
  });
};

export const saveDefaultCandidateDocument = (consultancyId, document) => {
  return axios.post("/DefaultCandidateDocuments/saveConsultancyDefault", {
    document,
    consultancyId,
  });
};

export const removeDefaultCandidateDocument = (consultancyId, documentId) => {
  return axios.delete("/DefaultCandidateDocuments/deleteConsultancyDefault", {
    params: {
      consultancyId,
      documentId,
    },
  });
};

export const getModulesForConsultancy = (consultancyId) => {
  return axios.get("/ConsultancyModules/getModulesForConsultancy", {
    params: {
      consultancyId,
    },
  });
};

export const toggleDefaultModuleState = (
  id,
  activeByDefault,
  consultancyId
) => {
  return axios.put("/ConsultancyModules/toggleDefaultState", {
    id,
    activeByDefault,
    consultancyId,
  });
};

export const getConsultancyAnalytics = (consultancyId, selectedConsultancyId, dateFrom, dateTo) => {
  return axios.get("/Consultancies/getConsultancyAnalytics", {
    params: {
      consultancyId,
      selectedConsultancyId,
      dateFrom,
      dateTo,
    },
  });
};

export const getSystemAdminAnalytics = (dateFrom, dateTo, consultancyId) => {
  return axios.get("/Consultancies/getSystemAdminAnalytics", {
    params: {
      dateFrom,
      dateTo,
      consultancyId,
    },
  });
};

export const getConsultancyDefaultWelcomeMessages = (
  consultancyId,
  roleType
) => {
  return axios.get("/DefaultWelcomeMessages/getConsultancyDefaults", {
    params: { consultancyId, roleType },
  });
};

export const saveConsultancyDefaultWelcomeMessage = (
  consultancyId,
  message
) => {
  return axios.post("/DefaultWelcomeMessages/saveConsultancyDefault", {
    message,
    consultancyId,
  });
};

export const removeConsultancyDefaultWelcomeMessage = (
  consultancyId,
  messageId
) => {
  return axios.delete("/DefaultWelcomeMessages/deleteConsultancyDefault", {
    params: {
      consultancyId,
      messageId,
    },
  });
};

export const getGroupCampaigns = (groupId, consultancyId) => {
  return axios.get("/Groups/getGroupCampaigns", {
    params: {
      groupId,
      consultancyId,
    },
  });
};
export const getConsultancyEmailTemplates = (consultancyId) => {
  return axios.get("/Admins/getConsultancyEmailTemplates", {
    params: {
      consultancyId,
    },
  });
};

export const updateConsultancyEmailTemplate = (
  consultancyId,
  emailTemplate
) => {
  return axios.post("/Admins/updateConsultancyEmailTemplate", emailTemplate, {
    params: {
      consultancyId: consultancyId,
    },
  });
};

export const uploadEmailImage = (consultancyId, formData) => {
  return axios.post("/EmailSettings/uploadEmailImage", formData, {
    params: {
      consultancyId,
    },
  });
};

//- Consultancy Relations end points

export const saveChildrenToConsultancy = (consultancyId, childrenId) => {
  return axios.post("/ConsultancyRelations/addChildConsultancy", {
    consultancyId,
    childrenId,
  });
};

export const removeChildFromConsultancy = (consultancyId, childId) => {
  return axios.delete("/ConsultancyRelations/removeChildConsultancy", {
    params: {
      consultancyId,
      childId,
    },
  });
};

export const getChildConsultancies = (consultancyId) => {
  return axios.get("/ConsultancyRelations/getConsultancyChildren", {
    params: {
      consultancyId,
    },
  });
};

export const getFamilyTree = (consultancyId) => {
  return axios.get("/ConsultancyRelations/getFamilyTree", {
    params: { consultancyId },
  });
};

export const getAvailableChildrenConsultancies = (consultancyId) => {
  return axios.get("/ConsultancyRelations/getAvailableChildrenConsultancies", {
    params: { consultancyId },
  });
};

export const setOrUpdateCustomDomain = (
  consultancyId,
  customDomain,
  isSubDomain
) => {
  return axios.post("/Consultancies/setOrUpdateCustomDomain", customDomain, {
    params: { consultancyId, customDomain, isSubDomain },
  });
};
export const updateOutboundEmail = (consultancyId, outboundEmail) => {
  return axios.post("/EmailSettings/updateOutboundEmail", {
    consultancyId,
    outboundEmail,
  });
};

export const updateInboundEmail = (consultancyId, inboundEmail) => {
  return axios.post("/EmailSettings/updateInboundEmail", {
    consultancyId,
    inboundEmail,
  });
};

export const updateEmailDomainName = (consultancyId, emailDomainName) => {
  return axios.post("/EmailSettings/updateEmailDomainName", {
    consultancyId,
    emailDomainName,
  });
};

export const updateEmailColour = (consultancyId, emailPrimaryColour) => {
  return axios.post("/EmailSettings/updateEmailColour", {
    consultancyId,
    emailPrimaryColour,
  });
};

export const getEmailSignature = () => {
  return axios.get("/Profiles/getEmailSignature");
};

export const updateEmailSignature = (emailSignature) => {
  return axios.post("/Profiles/updateEmailSignature", {
    emailSignature,
  });
};

export const updateConsultancyEmailSignature = (
  consultancyId,
  emailSignature
) => {
  return axios.post("/EmailSettings/updateConsultancyEmailSignature", {
    consultancyId,
    emailSignature,
  });
};

export const getConsultancyLogo = (consultancyId) => {
  return axios.get("/Consultancies/getConsultancyLogo", {
    params: { consultancyId },
  });
};

export const restoreConsultancyEmailTemplateToDefault = (
  consultancyId,
  emailTemplate
) => {
  return axios.post(
    "/Admins/restoreConsultancyEmailTemplateToDefault",
    emailTemplate,
    {
      params: {
        consultancyId,
      },
    }
  );
};

export const verifyDnsRecords = (consultancyId) => {
  return axios.post("/EmailSettings/verifyDnsRecords", {
    consultancyId,
  });
};

export const changeEmailDomain = (consultancyId, emailDomain) => {
  return axios.post("/EmailSettings/changeEmailDomain", {
    consultancyId,
    emailDomain,
  });
};

export const handleResetDefault = (consultancyId) => {
  return axios.put("/EmailSettings/resetEmailDomainDefault", {
    consultancyId,
  });
};

export const getHotjarDataByConsultancyId = (consultancyId) => {
  return axios.get('/ConsultancyHotjar/getHotjarDataByConsultancyId', {
    params: {
      consultancyId
    }
  });
};

export const getHotjarDataByConsultancyHost = (host) => {
  return axios.get('/ConsultancyHotjar/getHotjarDataByConsultancyHost', {
    params: {
      host
    }
  });
};

export const addHotjarData = (consultancyId, siteId, hotjarVersion) => {
  return axios.post(
    '/ConsultancyHotjar/addHotjarData',
    {
      siteId,
      hotjarVersion
    },
    {
      params: {
        consultancyId
      }
    }
  );
};

export const uploadConsultancyHeaderImage = (consultancyId, formData) => {
  return axios.post(
    '/Consultancies/uploadConsultancyHeaderImage',
    formData,
    {
      params: {
        consultancyId
      }
    }
  );
};

export const toggleExcludeFromActivityTracking = (consultancyId) => {
  return axios.patch(
    '/Consultancies/toggleExcludeFromActivityTracking',
    { },
    {
      params: {
        consultancyId
      }
    }
  );
};

export const getConsultanciesWithoutRecentLogins = () => {
  return axios.get('/Consultancies/getConsultanciesWithoutRecentLogins');
};

export const getAllConsultanciesLastLoginDate = () => {
  return axios.get('/Consultancies/getAllConsultanciesLastLoginDate');
};
