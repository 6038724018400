import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import * as Yup from 'yup';

const useStyles = makeStyles(theme => ({
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '33%'
  }
}));

const CreateTalentAlertForm = ({ onSubmit }) => {

  const classes = useStyles();
  const [t] = useTranslation(['common', 'validation', 'campaigns']);

  const schema = Yup.object({
		campaignTitle: Yup.string().required(t('newTranslations:talentAlertTitleIsRequired'))
	});

  const formik = useFormik({
		initialValues: {
			campaignTitle: ''
		},
		validateOnMount: true,
		validationSchema: schema,
		onSubmit: (values) => {
      onSubmit(values);
		}
	});

  const hasError = (field) => {
		return formik.touched[field] && formik.errors[field];
	};

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid className={classes.fields}>
        <TextField
          fullWidth
          label={t('common:title')}
          name="campaignTitle"
          variant="outlined"
          onChange={formik.handleChange}
          error={hasError('campaignTitle')}
          value={formik.values.campaignTitle} />
      </Grid>
      
      <Grid style={{ textAlign: 'right' }}>
        <Button
          className={classes.submitButton}
          color="primary"
          size="medium"
          type="submit"
          variant="contained"
          fullWidth
          disabled={!schema.isValid}>
          {t('common:submit')}
        </Button>
      </Grid>
    </form>
  );

};

export default CreateTalentAlertForm;
