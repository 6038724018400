import React from "react";
import { useDispatch } from "react-redux";
import * as actionTypes from "src/actions";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Button,
} from "@material-ui/core";
import * as campaignApi from "src/api/campaignApi";
import { ModalFormWrapper } from "src/components/ModalFormWrapper";
import CreateContactForm from "../../../../components/CreateContactForm";
import AddableContactCard from "./AddableContactCard";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    padding: 10,
  },
  listCard: {
    borderRadius: 0,
  },
  contactListCard: {
    display: "flex",
    alignItems: "center",
  },
  contactText: {
    flex: 2,
    textAlign: "center",
    color: theme.palette.primary.contrastText,
  },
  addableContactList: {
    width: "100%",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  expansionDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    padding: 0,
  },
  emptyText: {
    textAlign: "center",
    margin: 15,
  },
  summaryPanel: {
    height: 39,
    margin: 0,
  },
}));

function AddCreateCampaignContactModal({
  campaignId,
  contacts = [],
  employers = [],
  open,
  handleClose,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [t] = useTranslation(["candidates", "common", "campaigns"]);

  const { enqueueSnackbar } = useSnackbar();

  const addContact = async (id) => {
    return campaignApi
      .addContact(campaignId, id)
      .then((contact) => {
        if (contact) {
          return campaignApi.getContactsConnectedToCampaign(campaignId);
        } else {
          throw new Error("Failed to add contact");
        }
      })
      .then((contacts) => {
        if (contacts && contacts.addableContacts && contacts.campaignContacts) {
          let campaignContacts = contacts.campaignContacts.map(
            (campaignContact) => {
              return {
                id: campaignContact.contact.id,
                invited: campaignContact.invited,
                jobTitle: campaignContact.contact.jobTitle,
                firstName: campaignContact.contact.profile.firstName,
                lastName: campaignContact.contact.profile.lastName,
                email: campaignContact.contact.profile.user.email,
                weight: campaignContact.weight,
                primary: campaignContact.primary,
              };
            }
          );
          dispatch({
            type: actionTypes.UPDATE_CAMPAIGN_CONTACTS,
            campaignContacts,
            addableContacts: contacts.addableContacts,
          });

          const currentUninvitedContacts = campaignContacts.filter(
            (contact) => contact.invited === false
          );
          dispatch({
            type: actionTypes.UPDATE_UNINVITED_CAMPAIGN_CONTACTS,
            uninvitedCampaignContacts: currentUninvitedContacts,
          });
          enqueueSnackbar(t("campaigns:contactAddSuccess"), {
            variant: "success",
          });
          return;
        } else {
          throw new Error("Failed to update added contacts");
        }
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar(t("campaigns:contactUpdateFailure"), {
          variant: "error",
        });
      });
  };

  const onCreate = async (data) => {
    return campaignApi
      .createAndAddContact(campaignId, data.contactDetails)
      .then((contact) => {
        if (contact) {
          return campaignApi.getContacts(campaignId);
        } else {
          throw new Error("Failed to add contact");
        }
      })
      .then((newContacts) => {
        let campaignContacts = newContacts.map((campaignContact) => {
          return {
            id: campaignContact.contact.id,
            invited: campaignContact.invited,
            jobTitle: campaignContact.contact.jobTitle,
            firstName: campaignContact.contact.profile.firstName,
            lastName: campaignContact.contact.profile.lastName,
            email: campaignContact.contact.profile.user.email,
            weight: campaignContact.weight,
            primary: campaignContact.primary,
          };
        });
        dispatch({
          type: actionTypes.UPDATE_CAMPAIGN_CONTACTS,
          campaignContacts,
        });

        const currentUninvitedContacts = campaignContacts.filter(
          (contact) => contact.invited === false
        );
        dispatch({
          type: actionTypes.UPDATE_UNINVITED_CAMPAIGN_CONTACTS,
          uninvitedCampaignContacts: currentUninvitedContacts,
        });
        enqueueSnackbar(t("campaigns:contactAddSuccess"), {
          variant: "success",
        });
        handleClose();
        return;
      })
      .catch((error) => {
        enqueueSnackbar(t("campaigns:contactUpdateFailure"), {
          variant: "error",
        });
      });
  };

  const sortedContacts =
    contacts && contacts.length
      ? contacts.sort((a, b) => {
          var nameA =
            a.profile.firstName.toUpperCase() +
            a.profile.lastName.toUpperCase(); // ignore upper and lowercase
          var nameB =
            b.profile.firstName.toUpperCase() +
            b.profile.lastName.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          // names must be equal
          return 0;
        })
      : contacts;

  return (
    <ModalFormWrapper
      formTitle={t("campaigns:addContacts")}
      handleOpen={open}
      handleClose={handleClose}
    >
      <ExpansionPanel elevation={0}>
        <ExpansionPanelSummary
          className={classes.summaryPanel}
          expandIcon={<ExpandMoreIcon />}
        >
          <Button variant="text" size="large" color="primary">
            {t("campaigns:addExistingContact")}
          </Button>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <div className={classes.addableContactList}>
            {sortedContacts.length > 0 ? (
              sortedContacts.map((contact, index) => (
                <AddableContactCard
                  key={contact.id}
                  contact={contact}
                  onAdd={addContact}
                />
              ))
            ) : (
              <Typography className={classes.emptyText} variant="h5">
                {t("campaigns:noContactsToAdd")}
              </Typography>
            )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel elevation={0}>
        <ExpansionPanelSummary
          className={classes.summaryPanel}
          expandIcon={<ExpandMoreIcon />}
        >
          <Button variant="text" size="large" color="primary">
            {t("campaigns:addNewContact")}
          </Button>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionDetails}>
          <CreateContactForm
            employerId={employers.length === 1 ? employers[0].id : null}
            employerOptions={employers}
            onSubmit={onCreate}
            className={classes.form}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </ModalFormWrapper>
  );
}
export default AddCreateCampaignContactModal;
