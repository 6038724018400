import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button, Card, CircularProgress, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles, useTheme } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { useSnackbar } from 'notistack';

import Swal from 'sweetalert2';

import AddExistingContactModal from './AddExistingContactModal';
import AddNewContactModal from './AddNewContactModal';
import InviteUsersModal from './../../../components/InviteUsersModal';

import * as talentAlertApi from './../../../api/talentAlertApi';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    textAlign: 'center',
    padding: 2,
    margin: 0
  },
  tableContainer: {
    padding: '10px',
    paddingTop: '0px !important',
    height: '65vh',
    overflow: 'auto'
  },
  tHead: {
    backgroundColor: '#ffffff',
    textAlign: 'center'
  },
  tCell: {
    textAlign: 'center'
  },
  singleContact: () => ({
    '&:nth-of-type(2n)': {
      backgroundColor: fade(theme.palette.primary.main, 0.1)
    },
    '&:nth-of-type(2n+1)': {
      backgroundColor: fade(theme.palette.primary.main, 0.03)
    },
    padding: '10px 20px'
  }),
  button: {
    width: '70%',
    marginBottom: '15px',
    textAlign: 'left !important'
  },
  icon: {
    marginRight: '5px'
  },
  container: {
    padding: '10px'
  },
  buttons: {
    textAlign: 'center',
    paddingTop: '20px',
    paddingBottom: '20px'
  }
}));

const TalentAlertTargetList = () => {

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation(['campaigns', 'newTranslations', 'common', 'snackbar']);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true)
  const [employers, setEmployers] = useState([])
  const [contacts, setContacts] = useState([]);
  const [assignedContacts, setAssignedContacts] = useState([]);
  const [uninvitedContacts, setUninvitedContacts] = useState([]);
  const [existingContactModalOpen, setExistingContactModalOpen] = useState(false);
  const [newContactModalOpen, setNewContactModalOpen] = useState(false);
  const [inviteContactModalOpen, setInviteContactModalOpen] = useState(false);

  const consultancyId = useSelector((state) => state.session.consultancyId);
  const talentAlert = useSelector((state) => state.talentAlert);

  const campaignId = talentAlert.id;
  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    { id: 'action', label: 'Action' }
  ];

  useEffect(() => {
    setIsLoading(true);

    const talentAlertContactsPromise = getTalentAlertContacts(campaignId, consultancyId);
    const talentAlertAssignedContactsPromise = getTalentAlertAssignedContacts(campaignId);
    const talentAlertEmployersPromise = getTalentAlertEmployers(consultancyId);

    Promise.all([talentAlertContactsPromise, talentAlertAssignedContactsPromise, talentAlertEmployersPromise])
      .then(() => {
        setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleExistingContactModalOpen = () => {
    setExistingContactModalOpen(true);
  };

  const handleNewContactModalOpen = () => {
    setNewContactModalOpen(true);
  };

  const handleExistingContactModalClose = () => {
    setExistingContactModalOpen(false);
  };

  const handleNewContactModalClose = () => {
    setNewContactModalOpen(false);
  };

  const handleInviteContactModalOpen = () => {
    if (talentAlert.campaignStatusId === 2) {
      setInviteContactModalOpen(true);
      return;
    }
    
    const fireOptions = {
      title: t('snackbar:theCampaignIsNotLive'),
      text: t('snackbar:invitingContact'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('snackbar:proceedAnyway'),
      cancelButtonText: t('snackbar:cancel'),
      confirmButtonColor: theme.palette.warning?.main ? theme.palette.warning.main : theme.palette.primary.main
    };
    
    Swal.fire(fireOptions)
      .then((result) => {
        if (result.value) {
          setInviteContactModalOpen(true);
        }
      });
  };

  const handleInviteContactModalClose = () => {
    setInviteContactModalOpen(false);
  };

  const getTalentAlertContacts = (campaignId, consultancyId) => {
    talentAlertApi.getContacts(campaignId, consultancyId)
      .then((response) => {
        setContacts(response);
      });
  };

  const getTalentAlertEmployers = (consultancyId) => {
    talentAlertApi.getEmployers(consultancyId)
      .then((response) => {
        setEmployers(response.data);
      });
  };

  const getTalentAlertAssignedContacts = (campaignId) => {
    talentAlertApi.getContactsConnectedToCampaign(campaignId)
      .then((response) => {
        setAssignedContacts(response.campaignContacts);

        const currentUninvitedContacts = response.campaignContacts.filter((contact) => contact.invited === false);

        setUninvitedContacts(currentUninvitedContacts);
      });
  };

  const handleRemove = (campaignContactId) => {
    talentAlertApi.removeContact(campaignId, campaignContactId)
      .then(() => {
        return Promise.all([getTalentAlertAssignedContacts(campaignId), getTalentAlertContacts(campaignId, consultancyId)]);
      })
      .then(() => {
        enqueueSnackbar(t('snackbar:successfullyRemovedContact'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('campaigns:contactRemoveFailure'), { variant: 'error' });
      });
  };

  const createNewContact = (data) => {
    const { shouldCreateEmployer, employerDetails, contactDetails } = data;
    
    let createContactPromise;

    if (shouldCreateEmployer) {
      createContactPromise = talentAlertApi.createEmployerAndContact(campaignId, consultancyId, employerDetails, contactDetails);
    } else {
      createContactPromise = talentAlertApi.createAndAddContact(campaignId, contactDetails);
    }

    createContactPromise
      .then((contact) => {
        if (!contact) {
          throw new Error('Failed to add contact');
        }

        return getTalentAlertAssignedContacts(campaignId, consultancyId);
      })
      .then(() => {
        return handleNewContactModalClose();
      })
      .then(() => {
        enqueueSnackbar(t('campaigns:contactAddSuccess'), { variant: 'success' });
      })
      .catch(() => {
        enqueueSnackbar(t('snackbar:failedToCreateNewContact'), { variant: 'error' });
      });
  };

  const onInviteContactSuccess = (invitationDetails) => new Promise((resolve, reject) => {
    setIsLoading(true);

    const talentAlertCampaignContactInvitationsData = {
      emailSubject: invitationDetails.emailSubject,
      emailContent: invitationDetails.emailContent,
      selectedContacts: invitationDetails.selectedUsers,
      selectedCandidates: invitationDetails.selectedCandidates
    };

    return talentAlertApi.sendTalentAlertCampaignContactInvitations(talentAlertCampaignContactInvitationsData, consultancyId, campaignId )
      .then(() => {
        const talentAlertContactsPromise = getTalentAlertContacts(campaignId, consultancyId);
        const talentAlertAssignedContactsPromise = getTalentAlertAssignedContacts(campaignId);
        const talentAlertEmployersPromise = getTalentAlertEmployers(consultancyId);

        return Promise.all([talentAlertContactsPromise, talentAlertAssignedContactsPromise, talentAlertEmployersPromise]);
      })
      .then(() => {
        enqueueSnackbar(t('snackbar:contactInviteSuccess'), { variant: 'success' });
        setIsLoading(false);
        resolve();
      })
      .catch((err) => {
        enqueueSnackbar(t('snackbar:contactInviteFailure'), { variant: 'error' });
        setIsLoading(false);
        reject(err);
      });
  });

  if (isLoading) {
    return (
      <Grid container>
        <CircularProgress size={25} />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Grid item lg={9}>
        <Card className={classes.tableContainer}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {
                  columns.map((column) => (
                    <TableCell
                      className={classes.tHead}
                      key={column.id} >
                      { column.label }
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
              
            <TableBody className={classes.contactList}>
              {
                assignedContacts.map((assignedContact, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      key={index}
                      tabIndex={-1}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      className={classes.singleContact} >
                      <TableCell component="th" scope="row" className={classes.tCell}>
                        { assignedContact.contact.profile.firstName } { assignedContact.contact.profile.lastName }
                      </TableCell>
                      
                      <TableCell component="th" scope="row" className={classes.tCell}>
                        { assignedContact.contact.profile.user.email }
                      </TableCell>
                      
                      <TableCell component="th" scope="row" className={classes.tCell}>
                        { !assignedContact.invited ? `${t("newTranslations:notInvited")}` : `${t("newTranslations:invited")}` }
                      </TableCell>
                      
                      <TableCell component="th" scope="row" className={classes.tCell}>
                        <Button color="primary" size="small" type="submit" variant="contained" onClick={ () => { handleRemove(assignedContact.contactId); } }><DeleteOutlineIcon className={classes.icon} />
                          {t("common:delete")}
                        </Button>
                      </TableCell>
                    </TableRow>
                ))
              }
            </TableBody>
          </Table>

          <AddExistingContactModal
            consultancyId={consultancyId}
            campaignId={campaignId}
            open={existingContactModalOpen}
            getTalentAlertContacts={getTalentAlertContacts}
            handleClose={handleExistingContactModalClose}
            contacts={contacts}
            getTalentAlertAssignedContacts={getTalentAlertAssignedContacts} />
            
          <AddNewContactModal
            consultancyId={consultancyId}
            open={newContactModalOpen}
            handleClose={handleNewContactModalClose}
            campaignId={campaignId}
            employers={employers}
            createNewContact={createNewContact} />
            
          <InviteUsersModal
            onSuccess={onInviteContactSuccess}
            uninvitedUsers={uninvitedContacts}
            availableCandidates={ talentAlert.campaignCandidates }
            emailContent={talentAlert.contactInvitationEmail}
            emailSubject={talentAlert.contactInvitationEmailSubject}
            emailTags={talentAlert.contactInvitationEmailTags}
            open={inviteContactModalOpen}
            title={t('newTranslations:inviteToTalentAlert')}
            buttonText={'Invite Contacts'}
            handleClose={handleInviteContactModalClose}
            talentAlert />
        </Card>
      </Grid>
        
      <Grid item lg={3}>
        <Card className={classes.buttons}>
          <Button
            color='primary'
            type='submit'
            variant='contained'
            onClick={handleExistingContactModalOpen}
            className={classes.button} >
            <AddCircleOutlineIcon className={classes.icon} />
            { t('campaigns:addExistingContact') }
          </Button>

          <Button
            color='primary'
            type='submit'
            variant='contained'
            onClick={handleNewContactModalOpen}
            className={classes.button} >
            <CreateNewFolderOutlinedIcon className={classes.icon} />
            { t('campaigns:create New Contact') }
          </Button>

          <Button
            className={classes.button}
            color='primary'
            variant='contained'
            onClick={handleInviteContactModalOpen}
            fullWidth >
            { t('campaigns:inviteLabel') }
          </Button>
        </Card>
      </Grid>
    </Grid>
  );

};

export default TalentAlertTargetList;
