import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControl, FormGroup } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';

import AddableContactCard from './AddableContactCard';
import { ModalFormWrapper } from './../../../components/ModalFormWrapper';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding: 10
  },
  listCard: {
    borderRadius: 0
  },
  addableContactList: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxHeight: '60vh',
    overflowY: 'auto'
  },
  emptyText: {
    textAlign: 'center',
    margin: 15
  },
  singleCard: {
    margin: '16px 0px',
    width: '100%'
  }
}));

const AddExistingContactModal = ({
  campaignId,
  contacts,
  open,
  handleClose,
  getTalentAlertContacts,
  consultancyId,
  getTalentAlertAssignedContacts
}) => {

  const classes = useStyles();
  const [t] = useTranslation(['campaigns', 'newTranslations', 'tooltips']);

  const addTalentAlertContact = () => {
    getTalentAlertContacts(campaignId, consultancyId);
    getTalentAlertAssignedContacts(campaignId);
  };

  return (
    <ModalFormWrapper
      formTitle={t("newTranslations:addExistingContacts")}
      formTooltip={t("tooltips:addExistingContacts")}
      handleOpen={open}
      handleClose={handleClose} >
      <div className={classes.addableContactList}>
        {
          contacts?.length > 0 ? (
            contacts.map((contact, index) => (
              <div key={index}>
                <FormControl style={{ width: "100%" }}>
                  <FormGroup>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={ <ExpandMoreIcon style={{ fill: 'white' }} /> }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={classes.categoryWrapper} >
                        <Typography variant="h4" className={classes.question} >
                          { contact.employerName }
                        </Typography>
                      </ExpansionPanelSummary>
                      
                      <ExpansionPanelDetails>
                        <div className={classes.singleCard}>
                          {
                            contact.contacts.map((singleContact, index) => (
                              <AddableContactCard
                                contact={singleContact}
                                campaignId={campaignId}
                                key={index}
                                onAdd={addTalentAlertContact} />
                            ))
                          }
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </FormGroup>
                </FormControl>
              </div>
            ))
          ) : (
            <Typography className={classes.emptyText} variant="h5">
              { t("campaigns:noContactsToAdd") }
            </Typography>
          )
        }
      </div>
    </ModalFormWrapper>
  );

};

export default AddExistingContactModal;
